const base64ToBlob = (base64: string, type: string) => {
  const binaryString = window.atob(base64);
  const { length } = binaryString;

  const bytes = new Uint8Array(length);
  for (let i = 0; i < length; i += 1) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return new Blob([bytes], { type });
};

export default base64ToBlob;
