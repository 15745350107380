/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-return */
/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useDialogContext from '../hooks/useDialogContext';
import useWhiteLabel from '../hooks/useWhiteLabel';

import { CostCenter } from '../types/CostCenter';
import { UserInfo } from '../types/UserInfo';

import api from '../services/api';

interface ContextProps {
  isLogged: boolean;
  errorMsg: string;
  setErrorMsg: (errorMsg: string) => void;
  userInfo: UserInfo | null;
  setUserInfo: (userInfo: UserInfo) => void;
  loginRequest: (data: {
    email: string;
    password: string;
  }) => Promise<UserInfo | null>;
  login: (userData: UserInfo, role: string) => void;
  costCenter: CostCenter | null | undefined;
  setCostCenter: (costCenter: CostCenter | null) => void;

  handleServerErrors(error: string): void;
  handleLogout: () => void;
  loginWithTokenFromUrl: (
    token: string,
    setOpen: (open: boolean) => void,
  ) => Promise<void>;
}

type Props = {
  children: React.ReactNode;
};

const AuthContext = createContext<ContextProps>({} as ContextProps);

function AuthProvider({ children }: Props) {
  const navigate = useNavigate();

  const { whiteLabel, setWhiteLabel } = useWhiteLabel();
  const { showDialog } = useDialogContext();

  const [isLogged, setIsLogged] = useState<boolean>(
    !!JSON.parse(localStorage.getItem('isLogged') || 'null'),
  );

  const [userInfo, setUserInfo] = useState<UserInfo | null>(
    JSON.parse(localStorage.getItem('user-data') || 'null'),
  );

  const [costCenter, setCostCenter] = useState<CostCenter | null | undefined>(
    JSON.parse(localStorage.getItem('costCenter') || 'null'),
  );

  const [errorMsg, setErrorMsg] = useState('');

  function handleServerErrors(error: string) {
    setErrorMsg(error);
  }

  const loginRequest = async (data: { email: string; password: string }) => {
    try {
      const response = await api.post('/login', data);

      setUserInfo(response.data);

      return response.data as UserInfo;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      handleServerErrors(
        error.response && error.response.data.message
          ? error.response.data.message
          : 'Falha na conexão com o servidor. Tente novamente mais tarde.',
      );
      return null;
    }
  };

  const login = (userData: UserInfo, role: string) => {
    if (!userData) {
      return;
    }

    if (role === 'user') {
      setCostCenter(userData.centros[0]);
      localStorage.setItem('costCenter', JSON.stringify(userData.centros[0]));
    }

    setIsLogged(true);

    localStorage.setItem(
      '@dattasign-whiteLabel',
      userData.user.cliente.token_origem || '',
    );
    setWhiteLabel(userData.user.cliente.token_origem);

    localStorage.setItem('user-data', JSON.stringify(userData));
    localStorage.setItem('user-token', JSON.stringify(userData.token));
    localStorage.setItem('isLogged', 'true');
    localStorage.setItem('isOpen', 'true');

    navigate('/inicio');
  };

  const loginWithTokenFromUrl = async (
    token: string,
    setOpen: (open: boolean) => void,
  ) => {
    try {
      const response = await api.post('/login', { token });
      setUserInfo(response.data);
      setIsLogged(true);

      const role = response.data.user.roles[0].nome;
      const localUserData = response.data as UserInfo;

      if (role === 'user') {
        setCostCenter(localUserData.centros[0]);

        localStorage.setItem(
          'costCenter',
          JSON.stringify(localUserData.centros[0]),
        );
      }

      localStorage.setItem(
        '@dattasign-whiteLabel',
        localUserData.user.cliente.token_origem || '',
      );
      setWhiteLabel(localUserData.user.cliente.token_origem);

      localStorage.setItem('user-data', JSON.stringify(response.data));
      localStorage.setItem('user-token', JSON.stringify(response.data.token));
      localStorage.setItem('isLogged', 'true');
      localStorage.setItem('isOpen', 'true');
      navigate('/inicio');
    } catch (error: any) {
      setOpen(false);
      if (error.response && error.response.data.message) {
        showDialog('default', error.response.data.message);
      }
      if (!error.response || typeof error.response.data.message !== 'string') {
        return;
      }
    }
  };

  const handleLogout = () => {
    const whiteLabelCache = whiteLabel;

    localStorage.clear();
    localStorage.setItem('@dattasign-whiteLabel', whiteLabelCache || '');

    setIsLogged(false);
    setUserInfo(null);
    setCostCenter(null);
  };

  return (
    <AuthContext.Provider
      value={{
        isLogged,
        loginRequest,
        login,
        costCenter,
        setCostCenter,
        userInfo,
        setUserInfo,
        setErrorMsg,
        errorMsg,
        handleServerErrors,
        loginWithTokenFromUrl,
        handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
