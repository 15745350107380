/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import TablePagination from '@mui/material/TablePagination';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import RoleBasedMainBox from '../../components/RoleBasedMainBox';
import Folder from './Folder';
import FolderDocument from './FolderDocument';
import SearchBar from '../../components/SearchBar';

import { Subfolder } from '../../types/Document';

import useCostCenterContext from '../../hooks/useCostCenterContext';

import COLORS from '../../utils/colors';

const useStyles = makeStyles({
  filesContainer: {
    flexGrow: 1,
    backgroundColor: COLORS.white,
    border: `1px solid rgba(0, 0, 0, 0.12)`,
    borderRadius: '10px',
    overflow: 'auto',
    width: '100%',
    height: '100%',

    padding: '1.5rem',
    minHeight: '82vh',
    maxHeight: '82vh',
  },

  subfolderContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: '1.2rem',
    marginBottom: '1.5rem',
  },

  filesInnerContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    gap: '1.2rem',
  },

  folderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: '18rem',
    minHeight: '70px',
    maxHeight: '70px',
    height: '100%',
    padding: '1rem',
    borderRadius: '10px',
    gap: '10px',
    border: `1px solid rgba(0, 0, 0, 0.12)`,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: COLORS.background,
    },
  },

  fileContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    height: '100%',
    minWidth: '220px',
    maxWidth: '220px',
    minHeight: '240px',
    maxHeight: '240px',
    padding: '1rem',
    borderRadius: '10px',
    gap: '14px',
    border: `1px solid rgba(0, 0, 0, 0.12)`,
    cursor: 'pointer',

    '& img': {
      width: '164px',
      height: '100%',
      minHeight: '175px',
      maxHeight: '175px',
    },

    '&:hover': {
      backgroundColor: COLORS.background,
    },
  },

  subtitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },

  goBackArrow: {
    cursor: 'pointer',
  },

  pagination: {
    '& p': {
      marginBottom: '0 !important',
    },
  },
});

export default function SearchQueryFilesPage() {
  const {
    setSelectedFoldersIds,
    selectedDocuments,
    setSelectedDocuments,
    handleFolderSelection,
    handleDocumentSelection,
    innerDocuments,
    innerSubfolders,
    setIsSubfolderPage,
    loadingDocuments,
    totalDocuments,
    rowsPerPage,
    page,
    setPage,
    displayedSearchedFiles,
    setDisplayedSearchedFiles,
  } = useCostCenterContext();

  const classes = useStyles();

  interface LocationState {
    searchQueryName: string;
    searchedContentFromApi: any;
  }

  const location = useLocation();
  const locationState = location.state as LocationState;

  const [searchParams, setSearchParams] = useSearchParams({});

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const [fileOptions, setFileOptions] = useState([
    ...innerSubfolders,
    ...innerDocuments,
  ]);

  const [isSearching] = useState(false);

  const removeQueryParams = () => {
    const param = searchParams.get('query');
    if (param) {
      searchParams.delete('query');
      setSearchParams(searchParams);
      window.location.href = window.location.href.split('/search')[0];
    }
  };

  useEffect(() => {
    setSelectedFoldersIds([]);
    setSelectedDocuments([]);
    setIsSubfolderPage(true);
    setDisplayedSearchedFiles(locationState.searchedContentFromApi);

    return () => {
      removeQueryParams();
    };
  }, []);

  useEffect(() => {
    /* change document key 'documento' to 'name' as in the folder */
    const arr = innerDocuments.map(({ documento: name, ...rest }) => ({
      name,
      ...rest,
    }));
    /* create array with folders and documents for the autocomplete options */
    setFileOptions([...innerSubfolders, ...arr]);

    return () => {
      setFileOptions([]);
    };
  }, [innerSubfolders.length, innerDocuments.length]);

  return (
    <RoleBasedMainBox gap="0" overflow="unset">
      {isSearching || loadingDocuments ? (
        <Box
          sx={{
            height: '100%',
            width: '100%',
            minHeight: '67vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {fileOptions && (
            <SearchBar
              options={fileOptions}
              page={page}
              rowsPerPage={rowsPerPage}
              displayedSearchedFiles={displayedSearchedFiles}
              setDisplayedSearchedFiles={setDisplayedSearchedFiles}
              type="myfolders"
            />
          )}

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              gap: '2rem',
            }}
          >
            <Typography
              sx={{
                fontSize: '.925rem',
                fontWeight: '600',
                margin: '1rem 0',
                color: COLORS.mediumGray5,
              }}
            >
              Resultados da pesquisa
            </Typography>
            <Tooltip title="Voltar para a página anterior">
              <Button
                disableRipple
                onClick={removeQueryParams}
                className={classes.goBackArrow}
                sx={{
                  minWidth: 'unset',
                  textTransform: 'capitalize',
                  '& span': {
                    marginRight: '.2rem',
                  },
                  '&:hover': {
                    backgroundColor: 'transparent',
                    textDecoration: 'underline',
                  },
                }}
                aria-label="Voltar para as pastas"
                startIcon={<ArrowBackIcon />}
              >
                Voltar
              </Button>
            </Tooltip>
          </Box>

          <Box
            className={classes.filesContainer}
            sx={{
              '::-webkit-scrollbar': {
                width: '10px',
              },
              '::-webkit-scrollbar-track': {
                background: COLORS.lightGray3,
                borderRadius: '50px',
              },
              '::-webkit-scrollbar-thumb': {
                background: COLORS.mediumGray2,
                borderRadius: '50px',
              },
              '::-webkit-scrollbar-thumb:hover': {
                background: COLORS.mediumGray4,
              },
            }}
          >
            <>
              <Typography
                sx={{
                  fontSize: '.925rem',
                  fontWeight: '600',
                  margin: '0 0 1rem',
                  color: COLORS.mediumGray5,
                }}
              >
                Pastas encontradas
              </Typography>

              <Box className={classes.subfolderContainer}>
                {displayedSearchedFiles && displayedSearchedFiles.folders ? (
                  displayedSearchedFiles.folders.length > 0 ? (
                    displayedSearchedFiles.folders.map(
                      (subfolder: Subfolder, index: number) => (
                        <Folder
                          key={subfolder.name + index}
                          folderName={subfolder.name}
                          folderId={subfolder.id}
                          folderType={subfolder.type}
                          action={event => {
                            event.stopPropagation();
                            handleFolderSelection(
                              event,
                              subfolder,
                              innerSubfolders,
                            );
                          }}
                        />
                      ),
                    )
                  ) : (
                    <Typography
                      sx={{
                        fontSize: '.925rem',
                        fontWeight: '600',
                        color: COLORS.mediumGray5,
                        textAlign: 'center',
                        width: '100%',
                        margin: '2rem 0',
                      }}
                    >
                      Não foram encontradas pastas com este nome.
                    </Typography>
                  )
                ) : (
                  <CircularProgress size={24} />
                )}
              </Box>
            </>

            <Typography
              sx={{
                fontSize: '.925rem',
                fontWeight: '600',
                margin: '0 0 1rem',
                color: COLORS.mediumGray5,
              }}
            >
              Arquivos encontrados
            </Typography>
            <Box className={classes.filesInnerContainer}>
              {displayedSearchedFiles && displayedSearchedFiles.documents ? (
                displayedSearchedFiles.documents.length > 0 ? (
                  displayedSearchedFiles.documents.map(document => (
                    <FolderDocument
                      key={document.id}
                      document={document}
                      selectedDocuments={selectedDocuments}
                      action={event => {
                        event.stopPropagation();
                        handleDocumentSelection(
                          event,
                          document,
                          innerDocuments,
                        );
                      }}
                    />
                  ))
                ) : (
                  <Typography
                    sx={{
                      fontSize: '.925rem',
                      fontWeight: '600',
                      color: COLORS.mediumGray5,
                      textAlign: 'center',
                      width: '100%',
                      margin: '2rem 0',
                    }}
                  >
                    Não foram encontrados documentos finalizados com este nome.
                  </Typography>
                )
              ) : (
                <CircularProgress size={24} />
              )}
            </Box>
          </Box>

          {!loadingDocuments &&
            displayedSearchedFiles &&
            displayedSearchedFiles.documents && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <div style={{ alignSelf: 'center' }}>
                  <TablePagination
                    className={classes.pagination}
                    component="div"
                    count={totalDocuments}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[]}
                  />
                </div>
              </Box>
            )}
        </>
      )}
    </RoleBasedMainBox>
  );
}
