const fortifyHelpers = {
  toHex(buffer: Buffer) {
    const buf = new Uint8Array(buffer);
    const response = [];

    for (let i = 0; i < buf.length; i += 1) {
      const char = buf[i].toString(16);
      response.push(char.padStart(2, '0'));
    }

    return response.join('');
  },

  fromHex(hexString: string) {
    const response = new Uint8Array(hexString.length / 2);

    for (let i = 0; i < hexString.length; i += 2) {
      const c = hexString.slice(i, i + 2);
      response[i / 2] = parseInt(c, 16);
    }

    return response.buffer;
  },
};

export default fortifyHelpers;
