/* eslint-disable no-nested-ternary */
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import COLORS from '../utils/colors';

const stepStyles = (isSelected: boolean) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: '.9rem',
  fontWeight: isSelected ? 'bold' : 'medium',
  color: isSelected ? 'primary.main' : COLORS.mediumGray2,
  columnGap: '.5rem',
});

interface Props {
  steps: {
    title: string;
    path: string;
    icon: React.ReactNode;
  }[];
}

const PageNextSteps: React.FC<Props> = ({ steps }) => {
  return (
    <Breadcrumbs>
      {steps.map(step => (
        <Typography
          key={step.title}
          sx={stepStyles(window.location.pathname === step.path)}
        >
          {step.icon} {step.title}
        </Typography>
      ))}
    </Breadcrumbs>
  );
};

export default PageNextSteps;
