import { SxProps, Theme } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import COLORS from '../utils/colors';

const styles = {
  textTransform: 'capitalize',
  backgroundColor: 'primary.light',
  color: COLORS.white,
  fontWeight: 'bold',
  margin: '1rem auto',
  fontSize: {
    mobile: '.8rem',
    tablet: '.9rem',
  },
  width: {
    mobile: '30%',
    tablet: '50%',
  },

  '&:hover': {
    backgroundColor: 'primary.light',
  },
} as SxProps<Theme>;

interface Props {
  text: string;
}

const LoadingBtn: React.FC<Props> = ({ text }) => (
  <LoadingButton loading loadingPosition="center" sx={styles}>
    {text}
  </LoadingButton>
);

export default LoadingBtn;
