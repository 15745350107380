/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import makeStyles from '@mui/styles/makeStyles';
import WebViewer from '@pdftron/pdfjs-express';
import { Theme, useTheme } from '@mui/material';

import ModalFortify from '../../components/ModalFortify';
import LoadingBackdrop from '../../components/LoadingBackdrop';
import CustomWebViewer from '../../components/CustomWebViewer';
import TermsOfConditionsToSign from '../../components/TermsOfConditionsToSign';

import useDialogContext from '../../hooks/useDialogContext';

import { DigitalSignatureType } from '../../types/DigitalSignatureType';
import { SignDataLoggedOff } from './Sign';

import api from '../../services/api';

import COLORS from '../../utils/colors';
import base64ToBlob from '../../utils/base64ToBlob';
import isAuthenticationTypeValid from '../../utils/isAuthenticationTypeValid';
import {
  distinguishSignatureWidgets,
  hideAnnotationsFromOtherUsers,
  associateSignatureWithAnnotation,
  desassociateSignatureWithAnnotation,
  freeHandAnnotationToStampAnnotation,
} from '../../utils/pdfExpressHelpers';

import { ReactComponent as Sign } from '../../assets/images/sign.svg';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    rowGap: '2rem',
    width: '100%',
    height: '93%',
    padding: '2rem',
  },

  buttonSign: {
    '&.MuiButtonBase-root': {
      backgroundColor: theme.palette.primary.main,
      color: `${COLORS.white} !important`,
      fontSize: '0.875rem',
      height: '2.25rem',
      width: '11rem !important',
      padding: 0,
      fontWeight: 700,
      textTransform: 'none',

      '& .MuiSvgIcon-root ': {
        fontSize: '1.5rem',
      },

      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        transition: '0.3s',
      },
    },
  },
}));

type OutletContext = {
  signData: SignDataLoggedOff | null;
  setSignData: React.Dispatch<React.SetStateAction<SignDataLoggedOff | null>>;
  buttonSignIsDisabled: boolean;
};

export default function ViewDocument() {
  const classes = useStyles();
  const navigate = useNavigate();
  const viewer = useRef(null);
  const theme = useTheme();

  const { showDialog } = useDialogContext();

  const [isSigning, setIsSigning] = useState(false);
  const [isLoadingFile, setIsLoadingFile] = useState(true);
  const [isDocumentFinished, setIsDocumentFinished] = useState(false);

  const [webviewInstance, setWebviewInstance] = useState<any | null>(null);
  const [, forceUpdate] = useState({});

  const { token } = useParams();

  const [isOpenTermOfConditionsToSign, setIsOpenTermOfConditionsToSign] =
    useState(false);

  const { signData, setSignData, buttonSignIsDisabled } =
    useOutletContext<OutletContext>();

  const handleSignWithToken = () => {
    navigate(`/sign/${token}/confirmation`, {
      state: {
        name: signData?.signee.name,
        authenticationType: signData?.signee.signatureType,
        sendTokenTo:
          signData?.signee.signatureType === 'sms' ||
          signData?.signee.signatureType === 'whatsapp'
            ? signData?.signee.number
            : signData?.signee.email,
      },
    });
  };

  const activeDigitalSignature = async (type: DigitalSignatureType) => {
    if (
      signData?.signee.signatureType === 'assinatura_digital' &&
      !signData?.verified_token
    ) {
      navigate(`/sign/${token}/confirmation`, {
        state: {
          name: signData.signee.name,
          authenticationType: signData.signee.signatureType,
          sendTokenTo: signData.signee.email,
        },
      });
      return;
    }

    if (!webviewInstance) return;

    const { annotationManager, Annotations, documentViewer } =
      webviewInstance.Core;

    const signatureWidgetAnnotationNotSigned = annotationManager
      .getAnnotationsList()
      .find(
        (annot: any) =>
          annot instanceof Annotations.SignatureWidgetAnnotation &&
          annot.getCustomData('email') === signData?.signee.email &&
          annot.getCustomData('type') === type &&
          (!annot.getAssociatedSignatureAnnotation() ||
            !annot.getAssociatedSignatureAnnotation().getCustomData('field')),
      );

    if (!signatureWidgetAnnotationNotSigned) return;

    annotationManager.jumpToAnnotation(signatureWidgetAnnotationNotSigned, {
      isSmoothScroll: true,
    });

    const signatureTool = documentViewer.getTool('AnnotationCreateSignature');

    const signatureReadyCallback = async (annotation: any) => {
      const signatureWidgetRect = signatureWidgetAnnotationNotSigned.getRect();

      const hScale = signatureWidgetRect.getHeight() / annotation.Height;
      const wScale = signatureWidgetRect.getWidth() / annotation.Width;
      const scale = Math.min(hScale, wScale);
      const resizeRect = new webviewInstance.Core.Math.Rect(
        signatureWidgetRect.x1,
        signatureWidgetRect.y1,
        signatureWidgetRect.x1 + annotation.Width * scale,
        signatureWidgetRect.y1 + annotation.Height * scale,
      );

      annotation.resize(resizeRect);

      signatureWidgetAnnotationNotSigned.setAssociatedSignatureAnnotation(
        annotation,
      );

      signatureTool.removeEventListener(
        'signatureReady',
        signatureReadyCallback,
      );
    };

    signatureTool.addEventListener('signatureReady', signatureReadyCallback);

    const location = {
      x: signatureWidgetAnnotationNotSigned.getX(),
      y: signatureWidgetAnnotationNotSigned.getY(),
      pageNumber: signatureWidgetAnnotationNotSigned.PageNumber,
    };

    signatureTool.location = location;

    signatureTool.trigger('locationSelected', [
      location,
      signatureWidgetAnnotationNotSigned,
    ]);
  };

  const hasWidgetToFinalize = (type: DigitalSignatureType) => {
    if (
      signData?.signee.signatureType === 'assinatura_digital' &&
      !signData?.verified_token
    ) {
      return true;
    }

    if (!webviewInstance) return false;

    const { annotationManager, Annotations } = webviewInstance.Core;

    const signatureWidgetAnnotationsNotSigned = annotationManager
      .getAnnotationsList()
      .filter(
        (annot: any) =>
          annot instanceof Annotations.SignatureWidgetAnnotation &&
          annot.getCustomData('email') === signData?.signee.email &&
          annot.getCustomData('type') === type &&
          (!annot.getAssociatedSignatureAnnotation() ||
            !annot.getAssociatedSignatureAnnotation().getCustomData('field')),
      );

    return Boolean(signatureWidgetAnnotationsNotSigned.length);
  };

  const finalizeDigitalSignature = async () => {
    if (hasWidgetToFinalize('signature') || hasWidgetToFinalize('initials'))
      return;

    setIsSigning(true);

    const freeHandAnnotations = webviewInstance.Core.annotationManager
      .getAnnotationsList()
      .filter(
        (currentAnnotation: any) =>
          currentAnnotation instanceof
          webviewInstance.Core.Annotations.FreeHandAnnotation,
      );

    freeHandAnnotations.forEach((freeHandAnnotation: any) => {
      freeHandAnnotationToStampAnnotation(webviewInstance, freeHandAnnotation);
    });

    try {
      const xfdfString =
        await webviewInstance.Core.annotationManager.exportAnnotations({
          widgets: false,
          fields: false,
          links: false,
          useDisplayAuthor: false,
          generateInlineAppearances: false,
        });

      await api.post(`/digitally_sign_off`, {
        xfdf: xfdfString,
        token,
      });

      navigate(`/sign/${token}/success`);

      setIsDocumentFinished(true);
    } catch (error: any) {
      console.log(error);

      if (error.response && typeof error.response.data.message === 'string') {
        showDialog('default', error.response.data.message);
      }
    }

    setIsSigning(false);
  };

  useEffect(() => {
    if (signData) {
      setIsOpenTermOfConditionsToSign(!signData.accept);
    }
  }, [signData]);

  useEffect(() => {
    WebViewer(
      {
        path: '/webviewer/lib',
        disabledElements: ['annotationStyleEditButton', 'toolsHeader'],
        licenseKey: process.env.REACT_APP_PDF_EXPRESS_JS_KEY_VIEWER,
      },
      viewer.current,
    )
      .then((instance: any) => {
        setWebviewInstance(instance);

        instance.UI.disableFeatures([
          instance.UI.Feature.Print,
          instance.UI.Feature.Annotations,
        ]);

        instance.Core.documentViewer.addEventListener('pageComplete', () => {
          setIsLoadingFile(false);
        });

        instance.UI.setLanguage('pt_br');

        instance.Core.annotationManager.setRotationOptions({
          isEnabled: false,
        });

        distinguishSignatureWidgets(instance, theme.palette.primary.main);

        instance.Core.documentViewer
          .getTool('AnnotationCreateSignature')
          .addEventListener(
            'locationSelected',
            (pageCoordinates: any, signatureWidget: any) => {
              const isRubric =
                signatureWidget.getCustomData('type') === 'initials';

              const iframeDoc = instance.UI.iframeWindow.document;

              const visibilityChangedCallback = (e: any) => {
                const { element, isVisible } = e.detail;

                if (element === 'signatureModal' && isVisible) {
                  iframeDoc.querySelector('.header p').innerText = isRubric
                    ? 'Criar nova rubrica'
                    : 'Criar nova assinatura';

                  const ink = iframeDoc.querySelector(
                    '.ink-signature-sign-here',
                  );

                  ink.innerText = isRubric ? 'Rubrique aqui' : 'Assine aqui';

                  iframeDoc.querySelector('.pick-image-button').innerText =
                    isRubric
                      ? 'Escolher imagem da rubrica'
                      : 'Escolher imagem da assinatura';

                  iframeDoc.querySelector(
                    '[data-element="textSignaturePanelButton"]',
                  ).innerText = 'Digitar';
                  iframeDoc.querySelector(
                    '[data-element="imageSignaturePanelButton"]',
                  ).innerText = 'Enviar imagem';
                }

                instance.UI.removeEventListener(
                  'visibilityChanged',
                  visibilityChangedCallback,
                );
              };

              instance.UI.addEventListener(
                'visibilityChanged',
                visibilityChangedCallback,
              );
            },
          );
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (!webviewInstance || !signData) {
      return;
    }

    const fileBlob = base64ToBlob(signData.document.data, 'application/pdf');
    webviewInstance.UI.loadDocument(fileBlob, {
      filename: signData.document.nome,
      extension: 'pdf',
    });

    webviewInstance.Core.annotationManager.addEventListener(
      'annotationChanged',
      (
        annotations: any,
        action: string,
        { imported }: { imported: boolean },
      ) => {
        if (imported && action === 'add') {
          hideAnnotationsFromOtherUsers(
            webviewInstance,
            annotations,
            signData.signee.email,
          );

          return;
        }

        if (action === 'add') {
          associateSignatureWithAnnotation(webviewInstance, annotations[0]);
          forceUpdate({});
          return;
        }

        if (action === 'delete') {
          desassociateSignatureWithAnnotation(
            webviewInstance,
            annotations[0].Id,
          );
          forceUpdate({});
        }
      },
    );

    if (
      signData?.signee.signatureType === 'assinatura_digital' &&
      !signData?.verified_token
    ) {
      return;
    }

    if (signData?.document.xfdf) {
      webviewInstance.Core.documentViewer.addEventListener(
        'documentLoaded',
        async () => {
          await webviewInstance.Core.annotationManager.importAnnotations(
            signData?.document.xfdf,
          );
        },
      );
      return;
    }

    setIsDocumentFinished(true);
  }, [webviewInstance, signData]);

  return (
    <div className={classes.container}>
      <CustomWebViewer
        isLoading={isLoadingFile}
        viewer={viewer}
        width={window.screen.width < 640 ? '100%' : '80%'}
        height="100%"
      />

      {isAuthenticationTypeValid(signData?.signee.signatureType || '') &&
        (signData?.signee.signatureType === 'certificado_digital' ? (
          <ModalFortify
            fileId={signData?.document.id}
            token={token}
            disabled={buttonSignIsDisabled}
            updateDocument={() => {
              // do nothing
            }}
            setIsSigning={setIsSigning}
            isLogged={false}
          />
        ) : signData?.signee.signatureType === 'assinatura_digital' ? (
          <Button
            variant="contained"
            disableElevation
            endIcon={
              !isLoadingFile && (
                <SvgIcon component={Sign} sx={{ fontSize: '1.2rem' }} />
              )
            }
            onClick={
              hasWidgetToFinalize('signature')
                ? () => activeDigitalSignature('signature')
                : hasWidgetToFinalize('initials')
                ? () => activeDigitalSignature('initials')
                : finalizeDigitalSignature
            }
            disabled={isSigning || isLoadingFile || isDocumentFinished}
            className={classes.buttonSign}
          >
            {!signData.verified_token
              ? 'Assinar'
              : hasWidgetToFinalize('signature')
              ? 'Assine aqui'
              : hasWidgetToFinalize('initials')
              ? 'Rubrique aqui'
              : isLoadingFile
              ? 'Carregando'
              : 'Finalizar'}
          </Button>
        ) : (
          <Tooltip title="Assinar documento" placement="right">
            <Button
              className={classes.buttonSign}
              type="button"
              endIcon={
                !buttonSignIsDisabled && (
                  <SvgIcon component={Sign} sx={{ fontSize: '1.2rem' }} />
                )
              }
              disabled={buttonSignIsDisabled}
              onClick={handleSignWithToken}
            >
              Assinar
            </Button>
          </Tooltip>
        ))}

      <LoadingBackdrop color="primary.main" open={isSigning} />

      <TermsOfConditionsToSign
        open={isOpenTermOfConditionsToSign}
        setOpen={setIsOpenTermOfConditionsToSign}
        setSignData={setSignData}
      />
    </div>
  );
}
