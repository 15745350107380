/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

import useDialogContext from '../hooks/useDialogContext';

import { Document } from '../types/Document';
import { Signee } from '../types/Signee';
import { FileSettings } from '../types/FileSettings';
import { LocationDocumentContext } from '../types/LocationDocumentContext';

import api from '../services/api';

import { changeStampAnnotationsToSignatureWidget } from '../utils/pdfExpressHelpers';

type CustomFile = File | string | null;

interface FilePayload {
  signatarios: (Omit<Signee, 'order'> | Signee)[];
  configuracoes: FileSettings;
  mensagem: string;
  documento: {
    nome: string | null;
    arquivo: CustomFile;
  };
}

interface ContextProps {
  documents: Document[] | null | undefined;
  setDocuments: (documents: Document[] | null | undefined) => void;
  totalDocuments: number;
  setTotalDocuments: (totalDocuments: number) => void;
  currentFile: CustomFile;
  setCurrentFile: (currentFile: CustomFile) => void;
  fileName: string | null;
  setFileName: (fileName: string) => void;
  loadingDocument: boolean;
  setLoadingDocument: (sending: boolean) => void;
  sendingDocument: boolean;
  setSendingDocument: (sendingDocument: boolean) => void;
  hasUploaded: boolean;
  setHasUploaded: (hasUploaded: boolean) => void;
  handleFileInput: (file: File | null) => void;
  sendFilePayload: (bundle: FilePayload) => void;
  clearDocumentInfo: () => void;
  webviewInstance: any;
  setWebviewInstance: React.Dispatch<any>;
  xfdf: string;
  setXfdf: React.Dispatch<React.SetStateAction<string>>;
}

type Props = {
  children: React.ReactNode;
};

const DocumentContext = createContext<ContextProps>({} as ContextProps);

function DocumentProvider({ children }: Props) {
  const navigate = useNavigate();
  const { showDialog } = useDialogContext();

  const location = useLocation();
  const stateLocation = location.state as LocationDocumentContext;

  const [currentFile, setCurrentFile] = useState<CustomFile>(null);
  const [webviewInstance, setWebviewInstance] = useState<any>(null);
  const [xfdf, setXfdf] = useState(
    localStorage.getItem('user-file-xfdf') || '',
  );

  const [fileName, setFileName] = useState<string | null>(
    localStorage.getItem('file-name') || null,
  );

  const [documents, setDocuments] = useState<Document[] | null | undefined>(
    null,
  );
  const [totalDocuments, setTotalDocuments] = useState(0);

  const [loadingDocument, setLoadingDocument] = useState(false);
  const [sendingDocument, setSendingDocument] = useState(false);

  // are documents uploaded?
  const [hasUploaded, setHasUploaded] = useState(false);

  const validateFile = (file: File | null) => {
    if (!file) return false;

    const splittedName = file.name.split('.');
    const fileFormat = splittedName[splittedName.length - 1];

    if (fileFormat !== 'pdf') {
      showDialog('mustBePdf', 'O arquivo deve estar em formato pdf');
      return false;
    }

    const maxFileSize = 100 * 1024 * 1024; // 100 MiB
    if (file.size > maxFileSize) {
      showDialog(
        'default',
        'O arquivo é muito grande para ser enviado. Tamanho Máximo: 100 MB',
      );
      return false;
    }

    return true;
  };

  const handleFileInput = async (file: File | null) => {
    if (!file) return;

    try {
      if (!validateFile(file)) {
        return;
      }

      setLoadingDocument(true);

      const formData = new FormData();
      formData.append('documento', file);

      const response = await api.post('/documento', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      localStorage.setItem('file-name', file.name.split('.')[0]);
      setCurrentFile(response.data.arquivo);

      setLoadingDocument(false);
      navigate('/documentos/signatarios', {
        state: {
          rawFile: file,
        },
      });
      setHasUploaded(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoadingDocument(false);

      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : 'Erro de servidor. Por favor, tente novamente mais tarde.';

      showDialog('default', message);
    }
  };

  const clearDocumentInfo = () => {
    localStorage.removeItem('user-file-signees');
    localStorage.removeItem('user-file-settings');
    localStorage.removeItem('user-file-message');
    localStorage.removeItem('settings-period');
    localStorage.removeItem('file-name');
    localStorage.removeItem('user-file-xfdf');
    localStorage.removeItem('sort-settings');

    setCurrentFile(null);
    setWebviewInstance(null);
    setXfdf('');
  };

  const sendFilePayload = async (bundle: FilePayload) => {
    try {
      changeStampAnnotationsToSignatureWidget(webviewInstance);

      const xfdfString =
        await webviewInstance.Core.annotationManager.exportAnnotations({
          widgets: true,
          fields: true,
          links: false,
          useDisplayAuthor: false,
          generateInlineAppearances: false,
        });

      setSendingDocument(true);
      setWebviewInstance(null);

      const response = await api.post('/finalizar_envio', {
        ...bundle,
        configuracoes: {
          ...bundle.configuracoes,
          data_limite: moment(bundle.configuracoes.data_limite).format(
            'yyyy-MM-DD LT',
          ),
        },
        documento: {
          ...bundle.documento,
          ...(xfdf ? { xfdf: xfdfString } : {}),
        },
      });

      setSendingDocument(false);

      navigate('/documentos/documento_enviado', {
        state: {
          documentId: response.data.documento_id,
        },
      });

      clearDocumentInfo();
    } catch (error: any) {
      console.log('error', error);
      setSendingDocument(false);

      const message =
        error.response && error.response.data.message
          ? error.response.data.message === '[]'
            ? 'Erro no envio. Por favor, tente novamente.'
            : error.response.data.message
          : 'Erro no envio. Por favor, tente novamente.';

      showDialog('default', message);
    }
  };

  useEffect(() => {
    async function loadFile() {
      const formData = new FormData();
      formData.append('documento', stateLocation.rawFile || '');

      const response = await api.post('/documento', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setCurrentFile(response.data.arquivo);
    }

    if (stateLocation && stateLocation.rawFile) {
      loadFile();
    }
  }, []);

  useEffect(() => {
    if (
      window.location.pathname !== '/documentos/signatarios' &&
      window.location.pathname !== '/documentos/configuracoes' &&
      window.location.pathname !== '/documentos/mensagem'
    ) {
      clearDocumentInfo();
    }
  }, [window.location.pathname]);

  return (
    <DocumentContext.Provider
      value={{
        documents,
        setDocuments,
        totalDocuments,
        setTotalDocuments,
        currentFile,
        setCurrentFile,
        fileName,
        setFileName,
        loadingDocument,
        setLoadingDocument,
        sendingDocument,
        setSendingDocument,
        hasUploaded,
        setHasUploaded,
        handleFileInput,
        sendFilePayload,
        clearDocumentInfo,
        webviewInstance,
        setWebviewInstance,
        xfdf,
        setXfdf,
      }}
    >
      {children}
    </DocumentContext.Provider>
  );
}

export { DocumentContext, DocumentProvider };
