/* eslint-disable no-nested-ternary */
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import styled from '@mui/styles/styled';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import ContentSubTitle from './ContentSubTitle';

import DeniedAccessImage from './Images/DeniedAccessImage';

const ContentGrid = styled(Grid)({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
});

export default function DeniedAccess() {
  const theme = useTheme();

  return (
    <ContentGrid
      sx={{
        flexDirection: 'column',
        gap: '8rem',
        overflow: 'hidden',
        padding: '4rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '2rem',
        }}
      >
        <DeniedAccessImage style={{ width: '20rem', height: '18rem' }} />

        <ContentSubTitle
          text="Você não tem permissão para acessar esta página!"
          fontWeight="bold"
          fontSize="1.2rem"
          margin="1rem auto .3rem auto"
          alignSelf="center"
        />
      </Box>

      <Link
        to="/inicio"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 4,
          textDecoration: 'underline',
          color: theme.palette.primary.main,
          marginTop: '3rem',
          fontSize: '1rem',
        }}
      >
        <ArrowBackIcon
          sx={{
            textDecoration: 'underline',
            color: 'primary.main',
            fontSize: '20px',
          }}
        />
        Voltar à página inicial
      </Link>
    </ContentGrid>
  );
}
