/* eslint-disable react/jsx-props-no-spreading */
import { useTheme } from '@mui/material';

export default function SoEmptyImage(
  props: React.SVGAttributes<SVGSVGElement>,
) {
  const theme = useTheme();

  return (
    <svg
      width="944"
      height="441"
      viewBox="0 0 944 441"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M633.688 90H355.688V22.5442C355.697 16.5676 358.649 10.8377 363.897 6.61156C369.145 2.38542 376.261 0.00804242 383.683 0.000906152H532.346C536.594 -0.0300362 540.794 0.731852 544.616 2.22686C548.438 3.72187 551.778 5.90943 554.374 8.61793L633.688 90Z"
        fill="#CCCCCC"
      />
      <path
        d="M883.064 432.96C890.285 433.596 897.558 432.612 904.35 430.078C911.142 427.544 917.283 423.525 922.323 418.315C928.914 411.366 933.436 402.422 937.81 393.773C939.083 391.256 940.399 388.653 941.738 386.159C943.231 383.439 943.875 380.335 943.588 377.246C943.301 374.157 942.095 371.225 940.127 368.827C938.24 366.43 935.679 364.653 932.773 363.724C929.868 362.796 926.751 362.759 923.824 363.618L923.185 363.803L917.441 386.217C916.584 382.874 914.745 375.341 913.583 367.961L913.486 367.342L912.914 367.597C906.916 370.251 901.276 373.65 896.128 377.713C887.007 385.029 879.588 394.243 874.388 404.716C869.188 415.188 866.331 426.667 866.015 438.356L866 439.053L883.064 432.96Z"
        fill="#F2F2F2"
      />
      <path
        d="M615.167 44.4508H585.043C583.619 44.4508 582.208 44.7314 580.892 45.2765C579.576 45.8217 578.381 46.6207 577.373 47.6279C576.366 48.6352 575.567 49.8309 575.022 51.147C574.477 52.463 574.196 53.8735 574.196 55.298C574.196 56.7225 573.916 58.133 573.371 59.4491C572.825 60.7651 572.026 61.9609 571.019 62.9682C570.012 63.9754 568.816 64.7744 567.5 65.3195C566.184 65.8647 564.774 66.1452 563.349 66.1452H462.919C460.043 66.1452 457.284 65.0024 455.249 62.9682C453.215 60.9339 452.072 58.1749 452.072 55.298L450.086 44.3019L450.216 44.4508L450.086 44.3019L431.882 21.0934C431.286 20.321 430.519 19.6971 429.641 19.2708C428.764 18.8445 427.799 18.6272 426.823 18.636H392.683C390.979 18.638 389.345 19.316 388.14 20.5212C386.934 21.7264 386.256 23.3605 386.254 25.0649L385.549 44.3019H372.643C367.168 44.3019 361.917 46.4767 358.046 50.348C354.175 54.2192 352 59.4697 352 64.9445V199.389C352 204.248 353.93 208.907 357.365 212.343C360.801 215.778 365.46 217.708 370.319 217.708H615.167C620.023 217.702 624.679 215.77 628.112 212.336C631.545 208.902 633.476 204.246 633.481 199.389V62.7645C633.481 57.9074 631.551 53.2492 628.117 49.8148C624.683 46.3803 620.024 44.4508 615.167 44.4508V44.4508Z"
        fill="#BABABA"
      />
      <path
        d="M850.503 429.634H841.122L836.659 393.448L850.505 393.449L850.503 429.634Z"
        fill="#9E616A"
      />
      <path
        d="M850.834 439.42L821.985 439.419V439.055C821.985 436.077 823.168 433.221 825.274 431.115C827.38 429.009 830.236 427.826 833.214 427.826H833.214L838.484 423.828L848.316 427.827L850.834 427.827L850.834 439.42Z"
        fill="#2F2E41"
      />
      <path
        d="M821.533 397.115L812.551 399.822L797.833 366.465L811.09 362.469L821.533 397.115Z"
        fill="#9E616A"
      />
      <path
        d="M824.674 406.389L797.053 414.715L796.948 414.366C796.089 411.515 796.397 408.439 797.805 405.815C799.214 403.191 801.607 401.234 804.458 400.374L804.458 400.374L808.35 395.025L818.917 396.016L821.328 395.289L824.674 406.389Z"
        fill="#2F2E41"
      />
      <path
        d="M808.65 200.368C808.65 200.368 798.614 215.689 795.063 226.112C786.362 251.656 782.2 245.527 772.18 307.635C785.776 353.509 799.24 383.752 799.24 383.752C799.24 383.752 819.475 378.061 819.475 374.267C819.475 370.473 806.565 324.208 804.668 318.833C802.771 313.458 799.609 311.561 802.455 307.135C805.3 302.709 828.674 264.013 828.674 264.013C828.674 264.013 828.041 329.969 828.674 331.233C829.306 332.498 832.347 408.45 832.347 408.45C832.347 408.45 853.156 419.102 854.418 407.75C854.734 404.905 855.133 329.638 855.133 328.373C855.133 327.109 874.396 209.267 865.228 204.524C856.059 199.782 808.65 200.368 808.65 200.368Z"
        fill="#2F2E41"
      />
      <path
        d="M766.597 211.287C766.739 210.009 767.159 208.778 767.828 207.681C768.497 206.583 769.398 205.646 770.469 204.934C771.539 204.223 772.753 203.754 774.024 203.562C775.295 203.371 776.592 203.46 777.825 203.824L796.004 178.413L801.827 193.569L783.671 215.376C782.922 217.406 781.455 219.092 779.547 220.112C777.639 221.133 775.423 221.419 773.318 220.915C771.214 220.411 769.367 219.152 768.128 217.378C766.89 215.603 766.345 213.436 766.597 211.287L766.597 211.287Z"
        fill="#9E616A"
      />
      <path
        d="M791.67 207.348L779.582 198.182L804.958 159.108L825.341 80.5859L841.478 95.6123C842.86 96.0383 844.14 96.7441 845.237 97.6858C846.335 98.6275 847.227 99.7849 847.858 101.086C849.156 103.736 851.913 99.2988 850.882 102.064L828.182 163.356L828.15 163.398L791.67 207.348Z"
        fill="#3F3D56"
      />
      <path
        d="M834.395 77.3682L817.202 84.0487L810.137 93.8093L793.007 181.663L793.689 184.629L791.983 186.915L791.313 190.351L792.974 193.21L790.058 196.792C790.058 196.792 790.829 199.646 793.689 223.96C795.454 238.962 824.008 229.721 824.008 229.721L836.971 96.4828L834.395 77.3682Z"
        fill="#3F3D56"
      />
      <path
        d="M871.091 88.7202L856.259 75.4521L834.326 76.7742L821.699 92.1292L797.209 218.961L848.697 223.251L871.091 88.7202Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M858.09 77.5859L871.581 87.9429L876.755 95.4931C881.155 98.0084 884.555 101.961 886.385 106.688C888.214 111.414 888.362 116.626 886.802 121.448L870.417 179.208L870.034 180.388C869.644 181.532 871.511 190.589 870.582 200.344C870.34 202.886 873.288 205.719 873.067 208.227C872.894 210.19 869.544 211.859 869.396 213.662C869.263 215.289 871.025 216.836 870.922 218.239C870.656 220.972 869.911 223.637 868.72 226.111C854.762 231.885 827.807 227.697 827.807 227.697L852.231 96.0761L858.09 77.5859Z"
        fill="#3F3D56"
      />
      <path
        d="M843.01 70.3472C856.419 70.3472 867.29 59.4767 867.29 46.0672C867.29 32.6577 856.419 21.7871 843.01 21.7871C829.6 21.7871 818.729 32.6577 818.729 46.0672C818.729 59.4767 829.6 70.3472 843.01 70.3472Z"
        fill="#9E616A"
      />
      <path
        d="M874.491 47.1046C873.082 55.0852 867.383 62.9801 859.424 64.4961L859.273 64.2959C859.716 60.8365 859.91 57.3496 859.853 53.8624C859.84 52.9189 859.498 52.0094 858.886 51.2913C858.274 50.5732 857.43 50.0915 856.5 49.9295C855.571 49.7675 854.614 49.9354 853.795 50.4041C852.976 50.8728 852.346 51.6129 852.015 52.4965C852.158 49.4573 850.871 46.3537 850.041 43.4217C849.212 40.4969 848.647 37.7223 845.758 36.764C843.348 35.9703 840.723 36.5209 838.249 37.0859C834.831 37.8582 833.093 37.6794 832.786 36.8141C831.521 37.277 830.256 37.7391 828.99 38.2004C828.512 38.3743 827.99 38.3875 827.504 38.238C827.018 38.0885 826.594 37.7846 826.296 37.3722L826.285 37.3576C824.076 37.9297 821.866 38.497 819.656 39.0596C819.237 37.6444 818.896 36.2072 818.634 34.7546C820.526 33.2092 821.881 31.1037 822.502 28.7405C821.178 29.5026 819.742 30.0528 818.247 30.3709C818.196 28.6994 818.529 27.0384 819.221 25.5159C819.913 23.9934 820.945 22.65 822.238 21.5894C827.465 17.3917 832.707 14.8173 839.4 15.1534C847.496 15.561 849.92 24.2711 857.278 20.8743C866.575 16.5836 875.9 39.1239 874.491 47.1046Z"
        fill="#2F2E41"
      />
      <path
        d="M714.188 23.3672C712.908 22.3393 711.273 21.8603 709.64 22.0354C708.008 22.2105 706.511 23.0253 705.48 24.301L637.369 108.699C636.34 109.977 635.86 111.611 636.035 113.241C636.211 114.872 637.026 116.367 638.303 117.398L757.812 213.633C759.092 214.661 760.727 215.14 762.36 214.965C763.992 214.79 765.489 213.975 766.52 212.699L834.631 128.301C835.66 127.023 836.14 125.389 835.965 123.759C835.789 122.128 834.974 120.633 833.697 119.602L714.188 23.3672Z"
        fill="#CCCCCC"
      />
      <path
        d="M717.689 175.298L644.481 116.39C643.105 115.28 642.227 113.672 642.038 111.916C641.849 110.161 642.366 108.403 643.474 107.027L705.297 30.4767C706.409 29.1035 708.021 28.2265 709.779 28.0381C711.537 27.8497 713.299 28.3652 714.677 29.4715L827.519 120.272C828.895 121.381 829.773 122.99 829.962 124.745C830.151 126.5 829.634 128.259 828.526 129.634L798.653 166.623C789.059 178.475 775.147 186.045 759.968 187.671C744.789 189.297 729.584 184.848 717.689 175.298Z"
        fill="white"
      />
      <path
        d="M742.475 86.5103L724.81 71.9447C724.588 71.7622 724.403 71.5368 724.267 71.2812C724.13 71.0256 724.043 70.7448 724.013 70.4551C723.982 70.1654 724.007 69.8723 724.088 69.5926C724.168 69.313 724.301 69.0522 724.48 68.8253C724.659 68.5984 724.88 68.4098 725.13 68.2703C725.38 68.1308 725.654 68.0432 725.938 68.0124C726.221 67.9816 726.508 68.0082 726.781 68.0908C727.054 68.1734 727.309 68.3103 727.53 68.4937L745.194 83.0594C745.641 83.4285 745.927 83.9639 745.988 84.5481C746.049 85.1322 745.881 85.7173 745.52 86.1747C745.16 86.632 744.636 86.9243 744.065 86.9872C743.494 87.0502 742.922 86.8786 742.475 86.5103Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M775.566 124.526L719.787 78.8169C719.571 78.6402 719.392 78.4219 719.259 78.1745C719.126 77.9272 719.042 77.6556 719.012 77.3753C718.982 77.0951 719.007 76.8116 719.085 76.5411C719.164 76.2706 719.293 76.0184 719.467 75.7989C719.641 75.5795 719.856 75.397 720.1 75.262C720.343 75.127 720.611 75.0421 720.886 75.0122C721.162 74.9822 721.441 75.0078 721.707 75.0874C721.973 75.167 722.221 75.2991 722.437 75.4762L778.215 121.185C778.651 121.543 778.929 122.061 778.988 122.626C779.048 123.192 778.884 123.758 778.533 124.201C778.181 124.644 777.672 124.927 777.116 124.988C776.559 125.049 776.002 124.882 775.566 124.526V124.526Z"
        fill="#E6E6E6"
      />
      <path
        d="M769.509 132.526L712.8 86.817C712.581 86.6402 712.398 86.4219 712.263 86.1745C712.128 85.9272 712.043 85.6556 712.012 85.3753C711.982 85.0951 712.007 84.8116 712.087 84.5411C712.166 84.2706 712.298 84.0185 712.475 83.799C712.652 83.5795 712.871 83.397 713.118 83.262C713.366 83.127 713.637 83.0421 713.918 83.0122C714.198 82.9822 714.481 83.0078 714.752 83.0874C715.022 83.167 715.274 83.2991 715.494 83.4762L772.202 129.185C772.645 129.543 772.927 130.061 772.988 130.626C773.048 131.192 772.882 131.758 772.525 132.201C772.168 132.644 771.65 132.927 771.084 132.988C770.519 133.049 769.952 132.883 769.509 132.526V132.526Z"
        fill="#E6E6E6"
      />
      <path
        d="M763.511 139.537L706.803 94.7423C706.582 94.5692 706.399 94.3553 706.264 94.1128C706.128 93.8704 706.043 93.604 706.012 93.3292C705.982 93.0543 706.007 92.7762 706.087 92.5109C706.166 92.2456 706.298 91.9982 706.475 91.783C706.652 91.5677 706.871 91.3888 707.119 91.2565C707.366 91.1241 707.638 91.041 707.919 91.0118C708.2 90.9826 708.483 91.0078 708.754 91.0862C709.024 91.1645 709.277 91.2944 709.496 91.4684L766.204 136.263C766.646 136.614 766.928 137.121 766.988 137.675C767.048 138.229 766.881 138.783 766.525 139.217C766.168 139.651 765.65 139.928 765.085 139.988C764.52 140.048 763.954 139.886 763.511 139.537H763.511Z"
        fill="#E6E6E6"
      />
      <path
        d="M756.568 147.528L700.787 101.817C700.571 101.64 700.392 101.422 700.259 101.175C700.126 100.927 700.042 100.656 700.012 100.375C699.982 100.095 700.007 99.8117 700.085 99.5412C700.164 99.2707 700.293 99.0185 700.467 98.799C700.642 98.5795 700.856 98.3971 701.1 98.262C701.343 98.127 701.611 98.0421 701.886 98.0122C702.162 97.9822 702.441 98.0078 702.707 98.0874C702.973 98.167 703.221 98.2991 703.437 98.4763L759.218 144.187C759.652 144.545 759.929 145.063 759.988 145.628C760.047 146.193 759.883 146.759 759.533 147.201C759.182 147.643 758.673 147.926 758.117 147.987C757.561 148.049 757.004 147.883 756.568 147.528V147.528Z"
        fill="#E6E6E6"
      />
      <path
        d="M762.899 159.982C763.724 158.997 764.756 158.205 765.921 157.662C767.086 157.119 768.355 156.837 769.641 156.838C770.926 156.838 772.196 157.121 773.36 157.665C774.525 158.209 775.555 159.002 776.38 159.988L805.591 148.902L802.044 164.746L774.848 172.844C773.1 174.12 770.945 174.711 768.791 174.504C766.637 174.297 764.633 173.307 763.161 171.721C761.688 170.136 760.847 168.065 760.799 165.902C760.751 163.738 761.498 161.632 762.899 159.983V159.982Z"
        fill="#9E616A"
      />
      <path
        d="M785.954 171.306L780.968 156.979L823.739 138.503L876.887 99.0176L883.596 107.299C884.506 108.423 885.177 109.719 885.569 111.111C885.961 112.502 886.065 113.958 885.875 115.391C885.686 116.824 885.206 118.203 884.466 119.444C883.725 120.686 882.739 121.763 881.569 122.611L840.722 154.905L840.671 154.922L827.993 158.719L825.154 158.885L822.125 160.476L819.433 159.6L815.708 162.397L811.429 163.678L785.954 171.306Z"
        fill="#3F3D56"
      />
      <path
        d="M28.7912 385.603L3.9213 378.948C0.264544 365.931 -0.159431 352.218 2.68622 339C13.5362 360.448 42.676 364.492 59.9385 381.217C65.2825 386.475 69.3749 392.868 71.9124 399.922C74.4498 406.977 75.3673 414.511 74.5968 421.969L80.5544 439.229C68.4616 438.75 56.6162 435.656 45.8344 430.159C35.0526 424.662 25.5907 416.893 18.1006 407.387C13.9515 402.012 10.4957 396.137 7.81473 389.899C18.2213 388.374 28.7912 385.603 28.7912 385.603Z"
        fill="#F2F2F2"
      />
      <path
        d="M158.44 82.4518C159.817 72.351 161.544 61.5062 168.668 54.2146C170.929 51.901 173.635 50.0691 176.622 48.8294C179.61 47.5897 182.818 46.9678 186.052 47.0013C189.287 47.0348 192.481 47.7229 195.442 49.0242C198.404 50.3255 201.071 52.213 203.283 54.5729C208.469 60.1052 210.759 67.8736 210.969 75.4536C211.178 83.0337 209.49 90.5296 207.617 97.8777C197.706 98.0756 187.869 96.1393 178.772 92.2001C176.543 91.1119 174.23 90.204 171.856 89.4849C169.453 88.8733 167.74 90.4124 165.505 91.4857L164.277 89.136C163.273 91.0055 160.162 90.4513 159.034 88.6539C157.906 86.8565 158.153 84.5545 158.44 82.4518Z"
        fill="#2F2E41"
      />
      <path
        d="M199.835 125.101L196.965 110.442L218.482 101.127L256.949 82.6976C257.217 80.9876 257.998 79.3992 259.189 78.1427C260.379 76.8861 261.923 76.0206 263.616 75.6606C265.309 75.3006 267.071 75.463 268.67 76.1264C270.269 76.7899 271.629 77.923 272.569 79.376C273.51 80.829 273.987 82.5334 273.938 84.2636C273.889 85.9938 273.316 87.6684 272.295 89.0658C271.274 90.4633 269.852 91.5177 268.218 92.0896C266.584 92.6615 264.816 92.724 263.146 92.2687L226.52 114.872L199.835 125.101Z"
        fill="#FFB8B8"
      />
      <path
        d="M214.279 122.621L198.695 106.689L198.386 106.783C198.193 106.842 178.83 112.745 166.486 118.743C165.091 119.369 163.849 120.293 162.848 121.448C161.846 122.604 161.108 123.964 160.686 125.434C160.072 127.492 160.072 129.684 160.686 131.742C161.301 133.8 162.503 135.634 164.145 137.019C165.833 138.53 167.92 139.523 170.157 139.879C172.395 140.235 174.687 139.939 176.761 139.027L214.279 122.621Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M214.327 192.661L170.505 204.371L161.655 162.973L201.878 150.523L214.327 192.661Z"
        fill="#FFB8B8"
      />
      <path
        d="M165.6 181.425L208.408 171.58L216.606 151.712C217.273 148.876 217.111 145.908 216.139 143.161C215.167 140.414 213.427 138.005 211.124 136.22L195.484 123.015L187.099 112.304L188.012 106.628C188.175 105.493 187.937 104.338 187.339 103.36C186.74 102.382 185.819 101.645 184.735 101.274L173.56 97.2669C172.324 96.8049 170.96 96.8297 169.742 97.3361C168.524 97.8425 167.544 98.7928 167.001 99.9945L165.816 102.735C165.816 102.735 160.763 105.113 157.914 106.451C156.448 107.117 155.14 108.086 154.074 109.293C153.008 110.5 152.209 111.919 151.73 113.457C147.37 127.549 148.545 142.773 155.017 156.029L165.6 181.425Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M278.67 195.76C245.989 195.76 219.496 169.267 219.496 136.586C219.496 103.905 245.989 77.4121 278.67 77.4121C311.351 77.4121 337.844 103.905 337.844 136.586C337.844 169.267 311.351 195.76 278.67 195.76Z"
        fill={theme.palette.secondary.main}
      />
      <path
        opacity="0.16"
        d="M278.67 187.562C293.641 187.56 308.054 181.883 319.005 171.675C329.956 161.467 336.63 147.487 337.682 132.554C337.778 133.931 337.844 135.317 337.844 136.719C337.838 152.409 331.601 167.455 320.505 178.547C309.408 189.639 294.36 195.871 278.67 195.871C262.98 195.871 247.933 189.639 236.836 178.547C225.739 167.455 219.502 152.409 219.496 136.719C219.496 135.317 219.563 133.931 219.659 132.554C220.711 147.487 227.385 161.467 238.335 171.675C249.286 181.883 263.7 187.56 278.67 187.562Z"
        fill="#231F20"
      />
      <path
        d="M278.67 165.502C280.635 165.5 282.518 164.719 283.907 163.329C285.297 161.94 286.078 160.056 286.081 158.092V115.212C286.082 114.239 285.891 113.274 285.519 112.374C285.147 111.474 284.601 110.656 283.913 109.967C283.225 109.278 282.407 108.732 281.508 108.359C280.608 107.986 279.644 107.794 278.67 107.794C277.696 107.794 276.732 107.986 275.832 108.359C274.933 108.732 274.115 109.278 273.427 109.967C272.739 110.656 272.193 111.474 271.821 112.374C271.449 113.274 271.258 114.239 271.259 115.212V158.092C271.262 160.056 272.043 161.94 273.432 163.329C274.822 164.719 276.705 165.5 278.67 165.502V165.502Z"
        fill="white"
      />
      <path
        d="M307.521 136.652C307.518 134.687 306.737 132.804 305.347 131.414C303.958 130.025 302.075 129.243 300.11 129.241H257.231C256.257 129.24 255.292 129.431 254.392 129.803C253.492 130.175 252.674 130.721 251.985 131.409C251.296 132.097 250.75 132.914 250.377 133.814C250.004 134.714 249.812 135.678 249.812 136.652C249.812 137.626 250.004 138.59 250.377 139.489C250.75 140.389 251.296 141.206 251.985 141.895C252.674 142.583 253.492 143.129 254.392 143.5C255.292 143.872 256.257 144.063 257.231 144.062H300.11C302.074 144.06 303.958 143.279 305.347 141.889C306.737 140.5 307.518 138.617 307.521 136.652V136.652Z"
        fill="white"
      />
      <path
        d="M189.717 149.226L202.309 141.19L218.853 157.805L250.081 186.859C251.771 186.482 253.535 186.628 255.14 187.276C256.745 187.924 258.115 189.044 259.07 190.487C260.025 191.931 260.519 193.631 260.486 195.362C260.454 197.092 259.897 198.772 258.889 200.179C257.881 201.587 256.47 202.655 254.842 203.242C253.214 203.83 251.446 203.91 249.771 203.47C248.097 203.031 246.595 202.094 245.465 200.783C244.335 199.472 243.63 197.849 243.442 196.128L209.003 170.316L189.717 149.226Z"
        fill="#FFB8B8"
      />
      <path
        d="M197.311 161.76L206.434 141.427L206.234 141.175C206.108 141.016 193.528 125.157 183.429 115.865C182.335 114.796 181.022 113.978 179.58 113.469C178.138 112.959 176.602 112.77 175.08 112.916C172.94 113.098 170.9 113.901 169.209 115.226C167.519 116.55 166.252 118.34 165.564 120.374C164.776 122.498 164.616 124.804 165.103 127.016C165.59 129.228 166.704 131.253 168.312 132.849L197.311 161.76Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M189.07 94.927C178.137 94.927 169.274 86.064 169.274 75.131C169.274 64.1979 178.137 55.335 189.07 55.335C200.003 55.335 208.866 64.1979 208.866 75.131C208.866 86.064 200.003 94.927 189.07 94.927Z"
        fill="#FFB8B8"
      />
      <path
        d="M219.826 426.821H228.009L231.904 395.256H219.826V426.821Z"
        fill="#FFB8B8"
      />
      <path
        d="M215.507 441H221.376L222.423 435.459L225.106 441H240.672C241.418 441 242.144 440.762 242.745 440.32C243.346 439.878 243.79 439.255 244.012 438.543C244.234 437.83 244.223 437.066 243.98 436.36C243.736 435.655 243.274 435.046 242.66 434.622L230.23 426.037V420.436L217.155 421.216L215.507 441Z"
        fill="#2F2E41"
      />
      <path
        d="M117.257 419.868L124.861 422.895L140.154 395.009L128.932 390.541L117.257 419.868Z"
        fill="#FFB8B8"
      />
      <path
        d="M108 431.445L113.453 433.615L116.475 428.855L116.919 434.995L131.381 440.752C132.074 441.028 132.837 441.075 133.559 440.886C134.281 440.698 134.924 440.284 135.393 439.704C135.863 439.124 136.135 438.409 136.17 437.664C136.205 436.919 136 436.182 135.587 435.561L127.213 422.988L129.285 417.783L116.849 413.673L108 431.445Z"
        fill="#2F2E41"
      />
      <path
        d="M216.669 412.32H233.597L228.919 332.789L232.339 278.882L232.332 278.839L219.576 201.565C218.573 195.471 215.861 189.788 211.756 185.174L211.601 184.999L168.913 195.542L164.621 205.329C149.965 214.045 145.337 263.198 145.147 265.292L138.942 326.567L123.765 401.665L134.453 407.42L134.632 407.064L168.374 339.967L189.684 269.209L203.33 332.893L216.669 412.32Z"
        fill="#2F2E41"
      />
      <path
        d="M164.553 76.0633C165.254 71.4619 165.985 66.8219 167.631 62.4892C169.277 58.1565 171.929 54.0983 175.735 51.6439C181.759 47.7579 189.56 48.5074 196.315 50.7427C201.538 52.4714 206.638 55.0988 210.321 59.3229C214.005 63.547 216.075 69.5744 214.704 75.0975C207.499 73.3951 200.293 71.6928 193.088 69.9904L193.877 70.5477C191.511 71.7274 189.445 73.4306 187.836 75.528C186.226 77.6254 185.116 80.0619 184.589 82.6525C184.074 85.2746 184.148 87.9785 184.806 90.5685C185.463 93.1585 186.689 95.5698 188.393 97.6282C183.479 95.885 178.565 94.1417 173.651 92.3985C170.617 91.3226 167.371 90.0828 165.572 87.3229C163.487 84.1231 163.972 79.8819 164.553 76.0633Z"
        fill="#2F2E41"
      />
      <path
        d="M23.7285 439H928.271C928.73 439 929.17 439.105 929.494 439.293C929.818 439.48 930 439.735 930 440C930 440.265 929.818 440.52 929.494 440.707C929.17 440.895 928.73 441 928.271 441H23.7285C23.2701 441 22.8304 440.895 22.5063 440.707C22.1821 440.52 22 440.265 22 440C22 439.735 22.1821 439.48 22.5063 439.293C22.8304 439.105 23.2701 439 23.7285 439V439Z"
        fill="#CCCCCC"
      />
    </svg>
  );
}
