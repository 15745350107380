/* eslint-disable no-nested-ternary */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import PageMainContentBox from '../../components/PageMainContentBox';
import DeniedAccess from '../../components/DeniedAccess';
import UserSubscriptionPlans from './UserSubscriptionPlans/UserSubscriptionPlans';
import AdminSubscriptionPlans from './AdminSubscriptionPlans';
import SubscriptionView from './SubscriptionView';

import useAuthContext from '../../hooks/useAuthContext';
import useSubscriptionContext from '../../hooks/useSubscriptionContext';

import roleBasedRender from '../../utils/roleBasedRender';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },

  containerCicularProgress: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

// role based render: user | admin
export default function SubscriptionPlans() {
  const { userInfo, costCenter } = useAuthContext();
  const role = userInfo ? userInfo.user.roles[0].nome : '';
  const { stateSubscription, subscription } = useSubscriptionContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (role === 'user' && userInfo?.user.id !== costCenter?.proprietario.id) {
      navigate(-1);
    }
  }, []);

  return (
    <Box sx={styles.container}>
      <PageMainContentBox title="Planos" page="planos">
        {roleBasedRender(
          role,
          stateSubscription !== 'loading' ? (
            stateSubscription === 'purchased' &&
            subscription?.plano.tipo !== 'cortesia' ? (
              <SubscriptionView />
            ) : (
              <UserSubscriptionPlans />
            )
          ) : (
            <div style={styles.containerCicularProgress}>
              <CircularProgress />
            </div>
          ),
          <DeniedAccess />,
          <AdminSubscriptionPlans />,
          null,
        )}
      </PageMainContentBox>
    </Box>
  );
}
