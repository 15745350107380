/* eslint-disable no-nested-ternary */
import { CSSProperties } from 'react';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';

import SignatureSuccessImage from '../../components/Images/SignatureSuccessImage';

import COLORS from '../../utils/colors';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    rowGap: '2rem',
    width: '100%',
    height: '100%',
    minHeight: '70vh',
    padding: '2rem',
  } as CSSProperties,

  imageSignatureSuccess: {
    width: '18rem',
    marginBottom: '1rem',
  } as CSSProperties,
};

export default function Success() {
  const theme = useTheme();

  return (
    <div style={styles.container}>
      <SignatureSuccessImage style={styles.imageSignatureSuccess} />

      <h1 style={{ fontSize: '1.5rem' }}>Documento assinado com sucesso!</h1>

      <Typography
        sx={{
          color: COLORS.mediumGray3,
          fontSize: '1.25rem',
          lineHeight: '2rem',
          maxWidth: '40%',
          textAlign: 'center',
          [theme.breakpoints.down(640)]: {
            maxWidth: '92%',
          },
          [theme.breakpoints.between(640, 1002)]: {
            maxWidth: '60%',
          },
          [theme.breakpoints.down(1002)]: {
            textAlign: 'justify',
          },
        }}
      >
        Depois de todos assinarem, você receberá um e-mail para poder baixar o
        documento assinado e o relatório das assinaturas.
      </Typography>
    </div>
  );
}
