/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material';
import styled from '@mui/styles/styled';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import ActionButton from '../../components/ActionButton';
import LoadingBtn from '../../components/LoadingButton';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const OuterGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  overflow: 'hidden',
  height: '100vh',
  width: '100%',
});

const ContentGrid = styled(Grid)({
  margin: '8rem auto',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '40%',
});

interface LoginRecovery {
  email: string;
}

export default function ForgotPassword() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginRecovery>();

  const onSubmit: SubmitHandler<LoginRecovery> = async data => {
    setIsLoading(true);
    try {
      await api.post('/forgot_password', data);

      navigate('/forgot_password/sent', { state: data.email });
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <OuterGrid>
      <ContentGrid sx={{ flexDirection: 'column', gap: 2, overflow: 'hidden' }}>
        <Typography
          style={{
            fontSize: '1.4rem',
            margin: '.5rem 0',
            fontWeight: 'bold',
            color: COLORS.gray1,
          }}
        >
          Esqueceu sua senha?
        </Typography>

        <Typography
          sx={{
            fontSize: '.9rem',
            margin: '1.5rem 0 1rem 0',
            color: COLORS.gray1,
            textAlign: 'center',
            width: '60%',
          }}
        >
          Digite seu e-mail abaixo para redefinir sua senha através do link que
          enviaremos.
        </Typography>
        <form
          id="formulario-login"
          noValidate
          method="post"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '50%',
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormControl>
            <TextField
              id="recovery_email"
              label="Email"
              variant="standard"
              style={{ fontSize: '1rem', margin: '1rem auto', width: '100%' }}
              {...register('email', {
                required: 'Por favor, informe o email',
                pattern: {
                  value:
                    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                  message: 'Por favor, informe um email válido',
                },
              })}
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
            />
          </FormControl>

          <Box
            sx={{
              marginTop: '1rem',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {isLoading ? (
              <LoadingBtn text="Enviar" />
            ) : (
              <ActionButton text="Enviar" type="submit" />
            )}
          </Box>
        </form>

        <Link
          to="/"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 4,
            textDecoration: 'underline',
            color: theme.palette.primary.main,
            marginTop: '3rem',
            fontSize: '.9rem',
          }}
        >
          <ArrowBackIcon
            sx={{
              textDecoration: 'underline',
              color: theme.palette.primary.main,
              fontSize: '.9rem',
            }}
          />
          Voltar ao login
        </Link>
      </ContentGrid>
    </OuterGrid>
  );
}
