/* eslint-disable no-nested-ternary */
/* eslint-disable no-useless-return */
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from '@mui/styles/styled';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';

import LoginCard from './LoginCard';
import GoBackPageArrow from '../../components/GoBackPageArrow';
import BackgroundImage from '../../components/BackgroundImage';

import useAuthContext from '../../hooks/useAuthContext';
import useWhiteLabel from '../../hooks/useWhiteLabel';

import logoParceria from '../../assets/images/logo_parceria.png';

import COLORS from '../../utils/colors';

const useStyles = makeStyles({
  dialog: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
  },

  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30rem',
    gap: '1.5rem',
  },

  text: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '0.925rem',
    marginTop: '.5rem',
  },
});

const OuterGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  overflow: 'auto !important',
  height: '100vh',
}));

export default function Login() {
  const classes = useStyles();

  // conlicitacao token from url
  const { token } = useParams();

  const { loginWithTokenFromUrl } = useAuthContext();
  const { whiteLabel } = useWhiteLabel();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);

    if (token) {
      setOpen(true);
      loginWithTokenFromUrl(token, setOpen);
    }
  }, []);

  return (
    <OuterGrid sx={{ width: '100%' }}>
      <GoBackPageArrow title="Voltar à página inicial" link="/landing" />

      <BackgroundImage />

      <LoginCard />

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        className={classes.dialog}
        sx={{ width: '30rem' }}
      >
        <DialogTitle>Carregando...</DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <img
            src={logoParceria}
            alt="Logo parceria Dattasign"
            style={{
              width: '6rem',
              margin: '0 auto',
            }}
          />
          <Typography
            className={classes.text}
            color={COLORS.mediumGray4}
            sx={{ fontWeight: 'bold' }}
            variant="subtitle2"
          >
            &
          </Typography>

          <Typography
            className={classes.text}
            color={COLORS.black}
            sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}
            variant="subtitle2"
          >
            {whiteLabel === 'conlicitacao' && 'Conlicitação'}
            {whiteLabel === 'prevLaw' && 'PrevLaw'}
            {whiteLabel === 'codata' && 'CODATA'}
          </Typography>
          <Box sx={{ width: '80%' }}>
            <LinearProgress color="primary" />
          </Box>

          <Typography
            className={classes.text}
            color={COLORS.black}
            variant="subtitle2"
          >
            Estamos carregando suas informações :)
          </Typography>
        </DialogContent>
      </Dialog>
    </OuterGrid>
  );
}
