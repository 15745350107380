import Box from '@mui/material/Box';

import PageMainContentBox from '../../components/PageMainContentBox';
import OperatorAndAdminUsers from './OperatorAndAdminUsers';
import DeniedAccess from '../../components/DeniedAccess';

import useAuthContext from '../../hooks/useAuthContext';

import roleBasedRender from '../../utils/roleBasedRender';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
};

// role based render: operator | admin
export default function Users() {
  const { userInfo } = useAuthContext();
  const role = userInfo ? userInfo.user.roles[0].nome : '';

  return (
    <Box style={styles.container}>
      <PageMainContentBox title="Usuários" page="usuarios">
        {roleBasedRender(
          role,
          <DeniedAccess />,
          <OperatorAndAdminUsers />,
          <OperatorAndAdminUsers />,
          null,
        )}
      </PageMainContentBox>
    </Box>
  );
}
