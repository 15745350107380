/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef, useEffect } from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material';
import styled from '@mui/styles/styled';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import ActionButton from '../../components/ActionButton';
import LoadingBtn from '../../components/LoadingButton';
import DeniedAccess from '../../components/DeniedAccess';

import useDialogContext from '../../hooks/useDialogContext';
import useWhiteLabel from '../../hooks/useWhiteLabel';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const OuterGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  overflow: 'hidden',
  height: '100vh',
  width: '100%',
});

const ContentGrid = styled(Grid)({
  margin: '8rem auto',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '40%',
});

const ContainerCentralized = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
});

interface LoginRecovery {
  password: string;
  password_confirmation: string;
}

export default function RedefinePassword() {
  const { showDialog } = useDialogContext();
  const navigate = useNavigate();
  const theme = useTheme();
  const { whiteLabel } = useWhiteLabel();


  const [isLoading, setIsLoading] = useState(true);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownPasswordRepeat = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const [isAuthorized, setIsAuthorized] = useState(false);

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<LoginRecovery>();

  /* for repeat password */
  const password_confirmation = useRef({});
  password_confirmation.current = watch('password', '');

  const onSubmit: SubmitHandler<LoginRecovery> = async data => {
    try {
      await api.post('/reset_password', { ...data, token });
      showDialog(
        'resetPassword',
        `Agora você já pode usar o ${
          whiteLabel === 'codata' ? 'CodataSign' : 'DattaSign'
        }! :)`,
      );
      navigate('/');
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    async function verifyToken() {
      try {
        await api.post('/verificar/token', { token });

        setIsAuthorized(true);
      } catch (error) {
        setIsAuthorized(false);
      }

      setIsLoading(false);
    }
    verifyToken();
  }, [token]);

  if (isLoading) {
    return (
      <ContainerCentralized>
        <CircularProgress />
      </ContainerCentralized>
    );
  }

  if (!isAuthorized) {
    return (
      <ContainerCentralized>
        <DeniedAccess />,
      </ContainerCentralized>
    );
  }

  return (
    <OuterGrid>
      <ContentGrid sx={{ flexDirection: 'column', gap: 2, overflow: 'hidden' }}>
        <Typography
          style={{
            fontSize: '1.4rem',
            margin: '.5rem 0',
            fontWeight: 'bold',
            color: COLORS.gray1,
          }}
        >
          Nova Senha
        </Typography>

        <Typography
          sx={{
            fontSize: '.9rem',
            margin: '1.5rem 0 1rem 0',
            color: COLORS.gray1,
            textAlign: 'center',
            width: '60%',
          }}
        >
          Digite uma nova senha nos campos abaixo.
        </Typography>

        <form
          id="formulario-login"
          noValidate
          method="post"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '50%',
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormControl>
            <TextField
              id="register_newPassword"
              label="Senha"
              type={showPassword ? 'text' : 'password'}
              variant="standard"
              style={{ fontSize: '.9rem', width: '100%' }}
              {...register('password', {
                required: 'Por favor, forneça uma senha',
                pattern: {
                  value: /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                  message:
                    'A senha deve ter ao menos 8 caracteres, dentre eles uma letra maiúscula e outra minúscula',
                },
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon sx={{ color: 'primary.main' }} />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
            />
          </FormControl>

          <FormControl>
            <TextField
              id="newPasswordInput"
              variant="standard"
              type={showConfirmPassword ? 'text' : 'password'}
              {...register('password_confirmation', {
                required: 'Por favor, confirme sua senha',
                validate: value =>
                  value === password_confirmation.current ||
                  'As senhas não coincidem',
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPasswordRepeat}
                      edge="end"
                    >
                      {showConfirmPassword ? (
                        <VisibilityIcon sx={{ color: 'primary.main' }} />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              label="Confirme a senha"
              error={Boolean(errors.password_confirmation)}
              helperText={errors.password_confirmation?.message}
            />
          </FormControl>
          <Box
            sx={{
              marginTop: '1rem',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {isLoading ? (
              <LoadingBtn text="Enviar" />
            ) : (
              <ActionButton type="submit" text="Redefinir senha" />
            )}
          </Box>
        </form>

        <Link
          to="/"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 4,
            textDecoration: 'underline',
            color: theme.palette.primary.main,
            marginTop: '3rem',
            fontSize: '.9rem',
          }}
        >
          <ArrowBackIcon
            sx={{
              textDecoration: 'underline',
              color: theme.palette.primary.main,
              fontSize: '.9rem',
            }}
          />
          Voltar ao login
        </Link>
      </ContentGrid>
    </OuterGrid>
  );
}
