/* eslint-disable no-nested-ternary */
import { CSSProperties, useEffect } from 'react';
import { SxProps, Theme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import ContentTitle from '../../components/ContentTitle';
import ContentSubTitle from '../../components/ContentSubTitle';
import SubscriptionPlan from '../../components/SubscriptionPlan';
import Plan1Image from '../../components/Images/Plan1Image';

import useSubscriptionContext from '../../hooks/useSubscriptionContext';

import { Plan } from '../../types/Plan';

import COLORS from '../../utils/colors';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  } as CSSProperties,

  planDetails: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '3rem',
    columnGap: '3rem',
  },

  check: {
    fontSize: '1rem',
    display: 'flex',
  },

  description: {
    fontSize: '1rem',
    fontWeight: 700,
    width: '65%',
    textAlign: 'center',
  } as SxProps<Theme>,

  actions: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '2rem',
  } as CSSProperties,
};

const SubscriptionView = () => {
  const {
    subscription,
    subscriptionDetailsLink,
    getSubscriptionDetailsLink,
    subscriptionLinkLoading,
  } = useSubscriptionContext();

  const selectedPlan = { ...subscription?.plano } as Plan;

  useEffect(() => {
    getSubscriptionDetailsLink();
  }, []);

  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '77%',
      }}
    >
      <ContentTitle text="O seu plano está ativo no momento" />
      <ContentSubTitle text="Visualize os detalhes do seu plano ou faça um upgrade" />

      <div style={styles.planDetails}>
        <SubscriptionPlan
          title={selectedPlan.nome || ''}
          ImageComponent={<Plan1Image />}
          price={selectedPlan.valor}
          selectedPlan={selectedPlan}
          action={() => {
            // do nothing
          }}
        >
          <CheckIcon sx={{ ...styles.check, color: 'secondary.main' }} />

          <Typography sx={{ ...styles.description, color: 'secondary.main' }}>
            {selectedPlan?.descricao}
          </Typography>
        </SubscriptionPlan>

        <div style={styles.actions}>
          <Button
            sx={{
              backgroundColor: 'primary.main',
              color: `${COLORS.white} !important`,
              fontSize: {
                mobile: '.8rem',
                tablet: '.9rem',
              },
              width: '16rem',
              padding: '.5rem',
              fontWeight: 700,
              textTransform: 'none',

              '&:hover': {
                transition: '0.3s',
                backgroundColor: 'primary.light',
              },
            }}
            href={subscriptionDetailsLink}
            target="_blank"
          >
            {!subscriptionLinkLoading ? (
              <>
                Gerencie seu plano
                <AttachMoneyIcon
                  sx={{ color: COLORS.white, fontSize: '1.3rem' }}
                />
              </>
            ) : (
              <CircularProgress sx={{ color: COLORS.white }} size={22} />
            )}
          </Button>
        </div>
      </div>
    </Grid>
  );
};

export default SubscriptionView;
