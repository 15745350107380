/* eslint-disable react/jsx-props-no-spreading */
import { useTheme } from '@mui/material';

export default function TokenImage(
  props: React.SVGAttributes<SVGSVGElement>,
) {
  const theme = useTheme();

  return (
    <svg
      width="1000"
      height="575"
      viewBox="0 0 1000 575"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M443.559 457.561C429.647 390.295 412.257 367.868 412.257 367.868L405.614 362.716L397.624 356.51L397.663 355.712L395.768 355.068L395.323 354.722L394.594 354.162L394.476 354.288L394.229 354.545L358.076 342.256L312.211 326.677L290.972 299.071C287.513 294.575 283.06 290.941 277.962 288.454C272.863 285.967 267.258 284.695 261.586 284.737L193.756 285.239C184.585 285.307 175.771 288.803 169.047 295.04L124.464 336.394L54.9946 369.572L54.8773 369.455L54.1478 369.976L43.4142 375.108L43.8572 377.414L37.2137 382.207C37.2137 382.207 19.8237 403.075 5.91169 465.666C2.37262 481.592 0.630557 515.756 0.00176703 557.855C-0.029817 559.984 0.362285 562.099 1.15526 564.076C1.94824 566.053 3.12628 567.852 4.62088 569.369C6.11549 570.887 7.89683 572.092 9.86135 572.915C11.8259 573.737 13.9343 574.161 16.0642 574.162H387.112H433.423C435.552 574.161 437.659 573.737 439.622 572.915C441.585 572.094 443.366 570.89 444.86 569.374C446.354 567.859 447.533 566.062 448.327 564.087C449.121 562.112 449.515 560 449.486 557.871C448.872 512.062 447.13 474.833 443.559 457.561Z"
        fill="#3F3D56"
      />
      <path
        d="M550.415 322.678C538.901 322.678 527.858 327.252 519.716 335.394C511.574 343.536 507 354.579 507 366.093C507 377.607 511.574 388.65 519.716 396.792C527.858 404.934 538.901 409.508 550.415 409.508H956.054C967.568 409.508 978.611 404.934 986.753 396.792C994.894 388.65 999.469 377.607 999.469 366.093C999.469 354.579 994.894 343.536 986.753 335.394C978.611 327.252 967.568 322.678 956.054 322.678H550.415Z"
        fill="white"
      />
      <path
        d="M253.157 270.794C302.234 270.794 342.019 231.009 342.019 181.931C342.019 132.854 302.234 93.0684 253.157 93.0684C204.079 93.0684 164.294 132.854 164.294 181.931C164.294 231.009 204.079 270.794 253.157 270.794Z"
        fill="#9E616A"
      />
      <path
        d="M215.59 254.904C215.21 254.068 214.832 253.229 214.454 252.387C214.6 252.392 214.744 252.413 214.889 252.417L215.59 254.904Z"
        fill="#2F2E41"
      />
      <path
        d="M161.823 97.1093C166.317 93.524 171.57 90.2251 177.316 90.4106C183.062 90.5958 188.89 95.7857 187.699 101.41C198.254 82.7223 215.054 68.3491 235.15 60.8135C255.246 53.278 277.354 53.0617 297.594 60.2025C311.876 65.2413 325.882 75.3217 329.162 90.1067C330.005 93.902 330.185 98.0636 332.555 101.146C335.543 105.031 341.26 105.91 345.967 104.543C346.014 104.53 346.061 104.516 346.108 104.502C346.903 104.23 347.762 104.204 348.572 104.425C349.383 104.647 350.108 105.106 350.654 105.745C351.201 106.383 351.543 107.171 351.637 108.006C351.731 108.841 351.572 109.685 351.181 110.429L347.14 117.965C352.234 119.101 357.527 118.988 362.568 117.637C363.406 117.417 364.29 117.462 365.101 117.765C365.912 118.069 366.61 118.615 367.098 119.33C367.586 120.045 367.84 120.894 367.827 121.76C367.814 122.625 367.533 123.466 367.023 124.165C353.698 142.459 331.437 154.273 308.72 154.134C292.574 154.036 276.261 148.471 260.548 152.184C254.469 153.62 248.788 156.399 243.924 160.317C239.059 164.236 235.133 169.193 232.434 174.826C229.735 180.459 228.331 186.625 228.326 192.872C228.321 199.118 229.714 205.286 232.404 210.924C227.577 205.644 218.245 206.894 213.307 212.071C208.369 217.248 207.091 224.975 207.588 232.112C208.349 243.028 212.626 253.313 217.135 263.324C179.334 262.134 143.58 235.617 131.545 199.752C119.46 163.737 132.125 120.8 161.823 97.1093Z"
        fill="#2F2E41"
      />
      <path
        opacity="0.2"
        d="M87.4639 437.254L112.388 572.861L127.343 574.163L87.4639 437.254Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M362.007 427.025L337.083 572.761L322.128 574.161L362.007 427.025Z"
        fill="black"
      />
      <path
        d="M640.015 392.052H559.308C559.052 392.053 558.798 392.004 558.56 391.908C558.323 391.811 558.107 391.668 557.925 391.487C557.743 391.306 557.599 391.092 557.5 390.855C557.402 390.618 557.351 390.364 557.351 390.108C557.351 389.851 557.402 389.598 557.5 389.361C557.599 389.124 557.743 388.909 557.925 388.729C558.107 388.548 558.323 388.405 558.56 388.308C558.798 388.211 559.052 388.162 559.308 388.164H640.015C640.529 388.167 641.02 388.374 641.381 388.738C641.743 389.102 641.946 389.594 641.946 390.108C641.946 390.621 641.743 391.114 641.381 391.478C641.02 391.842 640.529 392.048 640.015 392.052Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M742.397 392.701H661.69C661.433 392.702 661.179 392.653 660.942 392.557C660.705 392.46 660.489 392.317 660.307 392.136C660.125 391.955 659.98 391.74 659.882 391.504C659.783 391.267 659.733 391.013 659.733 390.757C659.733 390.5 659.783 390.246 659.882 390.01C659.98 389.773 660.125 389.558 660.307 389.377C660.489 389.197 660.705 389.054 660.942 388.957C661.179 388.86 661.433 388.811 661.69 388.813H742.397C742.653 388.811 742.908 388.86 743.145 388.957C743.382 389.054 743.598 389.197 743.78 389.377C743.962 389.558 744.106 389.773 744.205 390.01C744.303 390.246 744.354 390.5 744.354 390.757C744.354 391.013 744.303 391.267 744.205 391.504C744.106 391.74 743.962 391.955 743.78 392.136C743.598 392.317 743.382 392.46 743.145 392.557C742.908 392.653 742.653 392.702 742.397 392.701Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M844.779 393.349H764.072C763.558 393.345 763.067 393.139 762.706 392.775C762.344 392.41 762.141 391.918 762.141 391.405C762.141 390.891 762.344 390.399 762.706 390.035C763.067 389.671 763.558 389.464 764.072 389.461H844.779C845.035 389.459 845.289 389.508 845.527 389.605C845.764 389.702 845.98 389.845 846.162 390.025C846.344 390.206 846.488 390.421 846.587 390.658C846.685 390.894 846.736 391.148 846.736 391.405C846.736 391.661 846.685 391.915 846.587 392.152C846.488 392.388 846.344 392.603 846.162 392.784C845.98 392.965 845.764 393.108 845.527 393.204C845.289 393.301 845.035 393.35 844.779 393.349Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M947.16 393.997H866.453C865.94 393.993 865.449 393.787 865.087 393.423C864.726 393.058 864.522 392.566 864.522 392.053C864.522 391.539 864.726 391.047 865.087 390.683C865.449 390.318 865.94 390.112 866.453 390.109H947.16C947.673 390.112 948.165 390.318 948.526 390.683C948.888 391.047 949.091 391.539 949.091 392.053C949.091 392.566 948.888 393.058 948.526 393.423C948.165 393.787 947.673 393.993 947.16 393.997Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M599.484 374.162C607.768 374.162 614.484 367.447 614.484 359.162C614.484 350.878 607.768 344.162 599.484 344.162C591.2 344.162 584.484 350.878 584.484 359.162C584.484 367.447 591.2 374.162 599.484 374.162Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M702.484 374.162C710.768 374.162 717.484 367.447 717.484 359.162C717.484 350.878 710.768 344.162 702.484 344.162C694.2 344.162 687.484 350.878 687.484 359.162C687.484 367.447 694.2 374.162 702.484 374.162Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M805.484 374.162C813.768 374.162 820.484 367.447 820.484 359.162C820.484 350.878 813.768 344.162 805.484 344.162C797.2 344.162 790.484 350.878 790.484 359.162C790.484 367.447 797.2 374.162 805.484 374.162Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M908.484 374.162C916.768 374.162 923.484 367.447 923.484 359.162C923.484 350.878 916.768 344.162 908.484 344.162C900.2 344.162 893.484 350.878 893.484 359.162C893.484 367.447 900.2 374.162 908.484 374.162Z"
        fill={theme.palette.secondary.main}
      />
    </svg>
  );
}
