/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import CircularProgress from '@mui/material/CircularProgress';
import { Theme, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import DoneIcon from '@mui/icons-material/Done';
import FileSaver from 'file-saver';
import moment from 'moment';
import WebViewer from '@pdftron/pdfjs-express';

import ContentTitle from '../../../components/ContentTitle';
import PageMainContentBox from '../../../components/PageMainContentBox';
import ModalFortify from '../../../components/ModalFortify';
import LoadingBackdrop from '../../../components/LoadingBackdrop';
import ModalSignWithToken from './ModalSignWithToken';
import ModalResendEmailToSignee from './ModalResendEmailToSignee';
import ModalDeleteDocument from './ModalDeleteDocument';
import CustomWebViewer from '../../../components/CustomWebViewer';
import TermsOfConditionsToSign from '../../../components/TermsOfConditionsToSign';
import ModalManageDocumentParticipants from './ModalManageDocumentParticipants';

import useAuthContext from '../../../hooks/useAuthContext';
import useDialogContext from '../../../hooks/useDialogContext';
import useCostCenterContext from '../../../hooks/useCostCenterContext';
import useWhiteLabel from '../../../hooks/useWhiteLabel';

import { DigitalSignatureType } from '../../../types/DigitalSignatureType';
import { SignData } from '../../../types/Sign';

import api from '../../../services/api';

import COLORS from '../../../utils/colors';
import base64ToBlob from '../../../utils/base64ToBlob';
import isAuthenticationTypeValid from '../../../utils/isAuthenticationTypeValid';
import {
  distinguishSignatureWidgets,
  hideAnnotationsFromOtherUsers,
  associateSignatureWithAnnotation,
  desassociateSignatureWithAnnotation,
  freeHandAnnotationToStampAnnotation,
} from '../../../utils/pdfExpressHelpers';
import downloadReportFile from './downloadReportFile';
import downloadSignedAndReportFile from './downloadSignedAndReportFile';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  },

  close: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: '.5rem',
  },

  gridContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '75vh',
    gap: '2rem',
  },

  aside: {
    width: '45%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',

    alignItems: 'center',
  },

  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  actions: {
    marginBottom: '1.875rem',
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    width: '100%',
    marginTop: '1.875rem',
    padding: '0 2rem 0 4.5rem',

    '& .MuiButtonBase-root': {
      color: COLORS.white,
      fontSize: '0.875rem',
      height: '2.25rem',
      width: '11rem',
      padding: 0,
      fontWeight: 700,
      textTransform: 'none',

      '& .MuiSvgIcon-root ': {
        fontSize: '1.2rem',
      },

      '&:hover': {
        transition: '0.3s',
      },
    },
  },

  buttonDownloadSignedDocument: {
    '&.MuiButtonBase-root': {
      backgroundColor: theme.palette.secondary.main,

      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
  },

  buttonFinishDocument: {
    '&.MuiButtonBase-root': {
      backgroundColor: COLORS.mediumGray3,

      '&:hover': {
        backgroundColor: COLORS.mediumGray5,
      },
    },
  },

  buttonDownloadReport: {
    '&.MuiButtonBase-root': {
      backgroundColor: COLORS.mediumGray2,

      '&:hover': {
        backgroundColor: COLORS.mediumGray4,
      },
    },
  },

  listActivity: {
    padding: 0,
    width: '80%',

    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1.5rem',

    minHeight: '30vh',
    maxHeight: '50vh',
    height: '50vh',
  },

  elementList: {
    borderBottom: `1px solid ${COLORS.lightGray1}`,

    '& h1': {
      color: COLORS.gray2,
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '1.125rem',
    },

    '& p': {
      fontSize: '1rem',
      color: COLORS.mediumGray3,
      margin: '0.625rem 0 1.5rem 0',
    },
  },

  loadingBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '18rem',
  },
}));

interface Activity {
  id: number;
  descricao: string;
  created_at: string;
}

const UserDocumentsSign = () => {
  const classes = useStyles();

  const navigate = useNavigate();
  const viewer = useRef(null);
  const theme = useTheme();

  const { costCenter, userInfo } = useAuthContext();
  const { showDialog } = useDialogContext();
  const { isFromFolderPage } = useCostCenterContext();
  const { whiteLabel } = useWhiteLabel();

  const [signData, setSignData] = useState({} as SignData);
  const [activities, setActivities] = useState([] as Activity[]);
  const [buttonSignIsDisabled, setButtonSignIsDisabled] = useState(true);

  const [isLoadingDownloadSignedFile, setIsLoadingDownloadSignedFile] =
    useState(false);
  const [isLoadingDownloadReport, setIsLoadingDownloadReport] = useState(false);
  const [isFinalizingDocument, setIsFinalizingDocument] = useState(false);
  const [isSigning, setIsSigning] = useState(false);
  const [isLoadingFile, setIsLoadingFile] = useState(true);
  const [isDocumentFinished, setIsDocumentFinished] = useState(false);

  const [webviewInstance, setWebviewInstance] = useState<any | null>(null);
  const [, forceUpdate] = useState({});

  const [isOpenTermOfConditionsToSign, setIsOpenTermOfConditionsToSign] =
    useState(false);

  const { id } = useParams();

  const loadDocument = async () => {
    try {
      const response = await api.get(`documento/inicio/assinatura/${id}`);
      setSignData(response.data);

      setButtonSignIsDisabled(
        response.data.status_signatario !== 'aguardando_assinatura',
      );
      setIsOpenTermOfConditionsToSign(!response.data.accept);
    } catch (error) {
      console.log(error);
    }
  };

  const loadActivities = async () => {
    try {
      const response = await api.get(
        `documento/atividades/${costCenter?.id}/${id}`,
      );
      setActivities(response.data.atividades);
    } catch (error) {
      console.log(error);
    }
  };

  const loadDocumentData = () => {
    loadDocument();
    loadActivities();
  };

  const finalizeDocumentManually = async () => {
    setIsFinalizingDocument(true);
    try {
      await api.put(`finalize_document/${costCenter?.id}/${id}`);

      showDialog('success', 'Documento foi finalizado!');

      setIsDocumentFinished(true);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error);
      if (error.response && typeof error.response.data.message === 'string') {
        showDialog('default', error.response.data.message);
      }
    }
    setIsFinalizingDocument(false);
  };

  const isDocumentOwner = () => userInfo?.user.id === signData?.proprietario.id;

  const downloadSignedFile = async () => {
    setIsLoadingDownloadSignedFile(true);
    const [fileName, formatFile] = signData.nome.split('.');

    FileSaver.saveAs(
      `data:application/pdf;base64,${signData.arquivo}`,
      `${fileName}_assinado.${formatFile}`,
    );

    setIsLoadingDownloadSignedFile(false);
  };

  const handleDownloadReportFile = async () => {
    setIsLoadingDownloadReport(true);
    await downloadReportFile(costCenter?.id, id || '', signData.nome);
    setIsLoadingDownloadReport(false);
  };

  const handleDownloadSignedAndReportFile = async () => {
    setIsLoadingDownloadSignedFile(true);

    await downloadSignedAndReportFile(id || '', signData.nome);

    setIsLoadingDownloadSignedFile(false);
  };

  const activeDigitalSignature = async (type: DigitalSignatureType) => {
    if (!webviewInstance) return;

    const { annotationManager, Annotations, documentViewer } =
      webviewInstance.Core;

    const signatureWidgetAnnotationNotSigned = annotationManager
      .getAnnotationsList()
      .find(
        (annotation: any) =>
          annotation instanceof Annotations.SignatureWidgetAnnotation &&
          annotation.getCustomData('email') === userInfo?.user.email &&
          annotation.getCustomData('type') === type &&
          (!annotation.getAssociatedSignatureAnnotation() ||
            !annotation
              .getAssociatedSignatureAnnotation()
              .getCustomData('field')),
      );

    if (!signatureWidgetAnnotationNotSigned) return;

    annotationManager.jumpToAnnotation(signatureWidgetAnnotationNotSigned, {
      isSmoothScroll: true,
    });

    const signatureTool = documentViewer.getTool('AnnotationCreateSignature');

    const signatureReadyCallback = async (annotation: any) => {
      const signatureWidgetRect = signatureWidgetAnnotationNotSigned.getRect();

      const hScale = signatureWidgetRect.getHeight() / annotation.Height;
      const wScale = signatureWidgetRect.getWidth() / annotation.Width;
      const scale = Math.min(hScale, wScale);
      const resizeRect = new webviewInstance.Core.Math.Rect(
        signatureWidgetRect.x1,
        signatureWidgetRect.y1,
        signatureWidgetRect.x1 + annotation.Width * scale,
        signatureWidgetRect.y1 + annotation.Height * scale,
      );

      annotation.resize(resizeRect);

      signatureWidgetAnnotationNotSigned.setAssociatedSignatureAnnotation(
        annotation,
      );

      signatureTool.removeEventListener(
        'signatureReady',
        signatureReadyCallback,
      );
    };

    signatureTool.addEventListener('signatureReady', signatureReadyCallback);

    const location = {
      x: signatureWidgetAnnotationNotSigned.getX(),
      y: signatureWidgetAnnotationNotSigned.getY(),
      pageNumber: signatureWidgetAnnotationNotSigned.PageNumber,
    };

    signatureTool.location = location;

    signatureTool.trigger('locationSelected', [
      location,
      signatureWidgetAnnotationNotSigned,
    ]);
  };

  const hasWidgetToFinalize = (type: DigitalSignatureType) => {
    if (!webviewInstance) return false;

    const { annotationManager, Annotations } = webviewInstance.Core;

    const signaturesNotFinalized = annotationManager
      .getAnnotationsList()
      .filter(
        (annotation: any) =>
          annotation instanceof Annotations.SignatureWidgetAnnotation &&
          annotation.getCustomData('email') === userInfo?.user.email &&
          annotation.getCustomData('type') === type &&
          (!annotation.getAssociatedSignatureAnnotation() ||
            !annotation
              .getAssociatedSignatureAnnotation()
              .getCustomData('field')),
      );

    return Boolean(signaturesNotFinalized.length);
  };

  const finalizeDigitalSignature = async () => {
    if (hasWidgetToFinalize('signature') || hasWidgetToFinalize('initials'))
      return;

    setIsSigning(true);

    const freeHandAnnotations = webviewInstance.Core.annotationManager
      .getAnnotationsList()
      .filter(
        (currentAnnotation: any) =>
          currentAnnotation instanceof
          webviewInstance.Core.Annotations.FreeHandAnnotation,
      );

    freeHandAnnotations.forEach((freeHandAnnotation: any) => {
      freeHandAnnotationToStampAnnotation(webviewInstance, freeHandAnnotation);
    });

    try {
      const xfdfString =
        await webviewInstance.Core.annotationManager.exportAnnotations({
          widgets: false,
          fields: false,
          links: false,
          useDisplayAuthor: false,
          generateInlineAppearances: false,
        });

      const response = await api.post(
        `/digitally_sign_logged/${costCenter?.id}/${id}`,
        {
          xfdf: xfdfString,
        },
      );

      showDialog('success', 'Documento foi assinado!');

      loadDocumentData();

      if (response.data.isFinished) {
        setIsDocumentFinished(true);
      }
    } catch (error: any) {
      console.log(error);

      if (error.response && typeof error.response.data.message === 'string') {
        showDialog('default', error.response.data.message);
      }
    }

    setIsSigning(false);
  };

  useEffect(() => {
    loadDocumentData();
  }, [id]);

  useEffect(() => {
    WebViewer(
      {
        path: '/webviewer/lib',
        disabledElements: ['annotationStyleEditButton', 'toolsHeader'],
        licenseKey: process.env.REACT_APP_PDF_EXPRESS_JS_KEY_VIEWER,
      },
      viewer.current,
    )
      .then((instance: any) => {
        setWebviewInstance(instance);

        instance.UI.disableFeatures([
          instance.UI.Feature.Print,
          instance.UI.Feature.Annotations,
        ]);

        instance.Core.documentViewer.addEventListener('pageComplete', () => {
          setIsLoadingFile(false);
        });

        instance.UI.setLanguage('pt_br');

        instance.Core.annotationManager.setRotationOptions({
          isEnabled: false,
        });

        distinguishSignatureWidgets(instance, theme.palette.primary.main);

        instance.Core.annotationManager.addEventListener(
          'annotationChanged',
          (
            annotations: any,
            action: string,
            { imported }: { imported: boolean },
          ) => {
            if (imported && action === 'add') {
              hideAnnotationsFromOtherUsers(
                instance,
                annotations,
                userInfo ? userInfo.user.email : '',
              );

              return;
            }

            if (action === 'add') {
              associateSignatureWithAnnotation(instance, annotations[0]);
              forceUpdate({});
              return;
            }

            if (action === 'delete') {
              desassociateSignatureWithAnnotation(instance, annotations[0].Id);
              forceUpdate({});
            }
          },
        );

        instance.Core.documentViewer
          .getTool('AnnotationCreateSignature')
          .addEventListener(
            'locationSelected',
            (pageCoordinates: any, signatureWidget: any) => {
              const isInitials =
                signatureWidget.getCustomData('type') === 'initials';

              const iframeDoc = instance.UI.iframeWindow.document;

              const visibilityChangedCallback = (e: any) => {
                const { element, isVisible } = e.detail;

                if (element === 'signatureModal' && isVisible) {
                  iframeDoc.querySelector('.header p').innerText = isInitials
                    ? 'Criar nova rubrica'
                    : 'Criar nova assinatura';

                  const ink = iframeDoc.querySelector(
                    '.ink-signature-sign-here',
                  );

                  ink.innerText = isInitials ? 'Rubrique aqui' : 'Assine aqui';

                  iframeDoc.querySelector('.pick-image-button').innerText =
                    isInitials
                      ? 'Escolher imagem da rubrica'
                      : 'Escolher imagem da assinatura';

                  iframeDoc.querySelector(
                    '[data-element="textSignaturePanelButton"]',
                  ).innerText = 'Digitar';
                  iframeDoc.querySelector(
                    '[data-element="imageSignaturePanelButton"]',
                  ).innerText = 'Enviar imagem';
                }

                instance.UI.removeEventListener(
                  'visibilityChanged',
                  visibilityChangedCallback,
                );
              };

              instance.UI.addEventListener(
                'visibilityChanged',
                visibilityChangedCallback,
              );
            },
          );
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (!webviewInstance || !signData.arquivo) {
      return;
    }

    const fileBlob = base64ToBlob(signData.arquivo, 'application/pdf');
    webviewInstance.UI.loadDocument(fileBlob, {
      filename: signData.nome,
      extension: 'pdf',
    });
  }, [webviewInstance, signData.arquivo]);

  useEffect(() => {
    if (!webviewInstance || !signData.xfdf) {
      return;
    }

    webviewInstance.Core.documentViewer.addEventListener(
      'documentLoaded',
      async () => {
        await webviewInstance.Core.annotationManager.importAnnotations(
          signData.xfdf,
        );
      },
    );
  }, [webviewInstance, signData.xfdf]);

  useEffect(() => {
    if (!webviewInstance) {
      return;
    }

    if (signData.status_signatario === 'assinado') {
      webviewInstance.Core.documentViewer.addEventListener(
        'annotationsLoaded',
        () => {
          webviewInstance.Core.annotationManager.disableReadOnlyMode();

          webviewInstance.Core.annotationManager.deleteAnnotations(
            webviewInstance.Core.annotationManager.getAnnotationsList(),
          );

          webviewInstance.Core.annotationManager.enableReadOnlyMode();
        },
      );
    }
  }, [webviewInstance, signData.status_signatario]);

  useEffect(() => {
    if (!webviewInstance) {
      return;
    }

    if (signData.verified_token) {
      webviewInstance.Core.annotationManager.disableReadOnlyMode();

      return;
    }

    webviewInstance.Core.annotationManager.enableReadOnlyMode();
  }, [webviewInstance, signData.verified_token]);

  useEffect(() => {
    console.log('is? ', isFromFolderPage);
  }, []);

  return (
    <Box className={classes.container}>
      <PageMainContentBox title="Documentos" page="documentos">
        <div
          className={classes.close}
          style={{
            justifyContent:
              signData && signData.nome ? 'space-between' : 'flex-end',
            padding: '0 1.5rem 1rem',
          }}
        >
          {signData ? (
            <ContentTitle
              text={signData.nome && signData?.nome.split('.')[0]}
              justify="flex-start"
              margin="0"
              align="start"
            />
          ) : null}
          <Tooltip title="Fechar documento">
            <CloseIcon
              sx={{
                color: 'primary.main',
                cursor: 'pointer',
                marginRight: '4.3rem',
              }}
              fontSize="large"
              onClick={() => navigate(-1)}
            />
          </Tooltip>
        </div>
        <Grid className={classes.gridContainer}>
          <CustomWebViewer
            isLoading={isLoadingFile}
            viewer={viewer}
            width="50%"
            height="100%"
          />

          <div className={classes.aside}>
            {signData?.arquivo && (
              <div className={classes.column}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    padding: '0 4rem',
                  }}
                >
                  <ContentTitle
                    text="Ações"
                    justify="flex-start"
                    margin="0"
                    align="start"
                  />
                </Box>
                <Box className={classes.actions} style={{ flexWrap: 'wrap' }}>
                  {!buttonSignIsDisabled &&
                    signData.status_documento !== 'finalizado' &&
                    isAuthenticationTypeValid(signData.tipo_autenticacao) &&
                    (signData.tipo_autenticacao === 'certificado_digital' ? (
                      <ModalFortify
                        fileId={id}
                        token=""
                        disabled={false}
                        updateDocument={loadDocumentData}
                        setIsSigning={setIsSigning}
                        isLogged
                      />
                    ) : signData.tipo_autenticacao === 'assinatura_digital' ? (
                      signData.verified_token ? (
                        <Button
                          variant="contained"
                          disableElevation
                          onClick={
                            hasWidgetToFinalize('signature')
                              ? () => activeDigitalSignature('signature')
                              : hasWidgetToFinalize('initials')
                              ? () => activeDigitalSignature('initials')
                              : finalizeDigitalSignature
                          }
                          disabled={
                            isSigning || isLoadingFile || isDocumentFinished
                          }
                        >
                          {hasWidgetToFinalize('signature')
                            ? 'Assine aqui'
                            : hasWidgetToFinalize('initials')
                            ? 'Rubrique aqui'
                            : isLoadingFile
                            ? 'Carregando'
                            : 'Finalizar'}
                        </Button>
                      ) : (
                        <ModalSignWithToken
                          fileId={id || ''}
                          updateDocument={loadDocumentData}
                          informedName={signData.nome}
                          authenticationType={signData.tipo_autenticacao}
                          sendTokenTo={signData.email}
                        />
                      )
                    ) : (
                      <ModalSignWithToken
                        fileId={id || ''}
                        updateDocument={loadDocumentData}
                        informedName={signData.nome}
                        authenticationType={signData.tipo_autenticacao}
                        sendTokenTo={
                          signData.tipo_autenticacao === 'whatsapp' ||
                          signData.tipo_autenticacao === 'sms'
                            ? signData.number || ''
                            : signData.email
                        }
                      />
                    ))}

                  {signData?.status_documento === 'finalizado' && (
                    <Tooltip
                      title={`Baixar documento${
                        whiteLabel === 'codata' ? ' e relatório' : ''
                      }`}
                    >
                      <Button
                        className={classes.buttonDownloadSignedDocument}
                        type="button"
                        endIcon={
                          !isLoadingDownloadSignedFile ? (
                            <DownloadIcon fontSize="large" />
                          ) : null
                        }
                        onClick={
                          whiteLabel === 'codata'
                            ? handleDownloadSignedAndReportFile
                            : downloadSignedFile
                        }
                        disabled={
                          signData?.status_documento !== 'finalizado' ||
                          isLoadingDownloadSignedFile
                        }
                      >
                        {!isLoadingDownloadSignedFile ? (
                          `Documento`
                        ) : (
                          <CircularProgress
                            sx={{ color: COLORS.white }}
                            size={24}
                          />
                        )}
                      </Button>
                    </Tooltip>
                  )}

                  {isDocumentOwner() &&
                    signData.status_documento === 'andamento' &&
                    signData.finalizacao === 'manual' && (
                      <Tooltip title="Finalizar documento">
                        <Button
                          disabled={isFinalizingDocument}
                          className={classes.buttonFinishDocument}
                          type="button"
                          endIcon={
                            !isFinalizingDocument ? (
                              <DoneIcon fontSize="large" />
                            ) : null
                          }
                          onClick={finalizeDocumentManually}
                        >
                          {!isFinalizingDocument ? (
                            'Finalizar'
                          ) : (
                            <CircularProgress
                              sx={{ color: COLORS.white }}
                              size={24}
                            />
                          )}
                        </Button>
                      </Tooltip>
                    )}

                  {signData?.status_documento === 'finalizado' &&
                    whiteLabel !== 'codata' && (
                      <Tooltip title="Baixar relatório">
                        <Button
                          className={classes.buttonDownloadReport}
                          type="button"
                          endIcon={
                            !isLoadingDownloadReport ? (
                              <DownloadIcon fontSize="large" />
                            ) : null
                          }
                          onClick={handleDownloadReportFile}
                          disabled={
                            isLoadingDownloadReport ||
                            signData?.status_documento !== 'finalizado'
                          }
                        >
                          {!isLoadingDownloadReport ? (
                            'Relatório'
                          ) : (
                            <CircularProgress
                              sx={{ color: COLORS.white }}
                              size={24}
                            />
                          )}
                        </Button>
                      </Tooltip>
                    )}

                  {isDocumentOwner() /* &&
                    signData?.status_documento !== 'finalizado' */ && (
                    <ModalManageDocumentParticipants
                      existingSignees={signData.signees}
                      signData={signData}
                      id={id || ''}
                    />
                  )}

                  {isDocumentOwner() &&
                    signData?.status_documento !== 'finalizado' && (
                      <ModalResendEmailToSignee signees={signData.signees} />
                    )}

                  {isDocumentOwner() && !isFromFolderPage && (
                    <ModalDeleteDocument id={id || ''} />
                  )}
                </Box>
              </div>
            )}

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                padding: '0 4rem',
              }}
            >
              <ContentTitle
                text="Atividade"
                justify="flex-start"
                margin="0"
                align="start"
              />
            </Box>

            <List
              className={classes.listActivity}
              sx={{
                marginTop: '1.875rem',
                '::-webkit-scrollbar': {
                  width: '10px',
                },
                '::-webkit-scrollbar-track': {
                  background: COLORS.lightGray3,
                  borderRadius: '50px',
                },
                '::-webkit-scrollbar-thumb': {
                  background: COLORS.mediumGray2,
                  borderRadius: '50px',
                },
              }}
            >
              {activities.length ? (
                activities.map(activity => (
                  <div key={activity.id} className={classes.elementList}>
                    <h1>{moment(activity.created_at).format('L, LTS')}</h1>
                    <p>{activity.descricao}</p>
                  </div>
                ))
              ) : (
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <CircularProgress sx={{ alignSelf: 'center' }} />
                </div>
              )}
            </List>
          </div>
        </Grid>
      </PageMainContentBox>

      <LoadingBackdrop color="primary.main" open={isSigning} />

      <TermsOfConditionsToSign
        open={isOpenTermOfConditionsToSign}
        setOpen={setIsOpenTermOfConditionsToSign}
      />
    </Box>
  );
};

export default UserDocumentsSign;
