import { CSSProperties } from 'react';
import Box from '@mui/material/Box';

import PageMainContentBox from '../components/PageMainContentBox';
import DeniedAccess from '../components/DeniedAccess';

import useAuthContext from '../hooks/useAuthContext';
import useWhiteLabel from '../hooks/useWhiteLabel';

import roleBasedRender from '../utils/roleBasedRender';

const styles = {
  textsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  } as CSSProperties,

  paragraphContainer: {
    margin: '1.3rem 0',
    textAlign: 'justify',
  } as CSSProperties,

  subtitleTextContainer: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
    gap: '1rem',
    margin: '.5rem 0',
  } as CSSProperties,

  titleAndSubtitle: {
    fontSize: '1.1rem',
    fontWeight: 700,
  } as CSSProperties,

  subtitleItem: {
    fontSize: '1rem',
    fontWeight: 700,
    marginLeft: '.5rem',
  } as CSSProperties,

  subtitleContainerParagraph: {
    marginBottom: '0',
  },

  contactContainer: {
    margin: '1rem 0 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '.6rem',
  },

  divider: {
    height: '2px',
    backgroundColor: '#F0F1F2',
    width: '100%',
  },
};

const TermsAndConditions = () => {
  return (
    <>
      <p style={{ fontSize: '.925rem' }}>Última atualização: 18/04/2022</p>

      <div style={styles.textsContainer}>
        <p style={styles.paragraphContainer}>
          O DATTASIGN é um serviço digital, e consiste em uma plataforma SaaS
          que disponibiliza uma solução com foco em assinatura e gestão de
          contratos para organizações, acessível por meio do
          portalweb.dattasign.com.br. Para a plena utilização dos serviços do
          DATTASIGN, a leitura deste documento é muito importante para que haja
          compreensão e anuência dos Termos e Condições de Uso e Política de
          Privacidade. A autenticação e utilização da plataforma implicam na
          concordância destes termos e seu conteúdo de forma tácita. Os Termos
          definidos abaixo possuem aplicabilidade no que tange a utilização dos
          serviços disponibilizados por meio do sistema desenvolvido e mantido
          pelo DATTASIGN. Este documento estabelece o pleno acordo e
          entendimento entre as partes, revogando as compreensões e acordos
          prévios. O DATTASIGN tem o escopo de sua atuação focado na área de
          tecnologia da informação. Dessa forma, não presta serviços e/ou
          atividades do âmbito jurídico. Apesar do objetivo da plataforma
          auxiliar no processo de gestão de contratos e documentos, a empresa
          não disponibiliza assessoria para atuação nesse contexto fora do
          sistema.
        </p>

        <h2 style={styles.titleAndSubtitle}>
          1. Sobre as alterações destes termos
        </h2>
        <p style={styles.paragraphContainer}>
          O DATTASIGN poderá alterar periodicamente as Condições de Uso e
          Política de Privacidade, pois envolve questões relacionadas com
          evoluções técnicas do serviço e de suas regras. Essa ação sempre será
          comunicada aos usuários por e-mail e através da página principal. As
          condições comerciais não sofreram alterações, permaneceram conforme
          acordado.
        </p>
        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.titleAndSubtitle}>1.1</h2>
          <p style={styles.subtitleContainerParagraph}>
            Em caso de ausência de manifestações sobre as eventuais alterações
            nas Condições de Uso e Política de Privacidade, será entendido que o
            usuário aceitou tacitamente o novo documento.
          </p>
        </div>
        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.titleAndSubtitle}>1.2</h2>
          <p>Usuários novos e antigos serão impactados.</p>
        </div>

        <h2 style={styles.titleAndSubtitle}>2. Sobre o serviço e obrigações</h2>
        <p style={styles.paragraphContainer}>
          O DATTASIGN consiste em uma Plataforma de Assinaturas que
          disponibiliza ambientes para a realização do envio e assinatura de
          documentos por meio de aceite digital, token por e-mail e assinatura
          com certificado digital no padrão ICP Brasil. Para isto, utiliza como
          respaldo legal o art. 10, parágrafo 2º, da MP 2200/2000. Dessa forma,
          a plataforma objetiva contribuir para o processo de Transformação
          Digital, sendo um dos pilares para a implantação da Gestão Paperless
          de contratos e afins. Possui como diferencial competitivo a
          flexibilidade na integração com os workflows e ferramentas já
          existentes nos setores empresariais (RH, Marketing, Financeiro,
          Jurídico, Compras, etc), para o desenvolvimento de uma solução
          customizada e exclusiva, que atenda cada caso, em suas
          especificidades, proporcionando assim, menos impacto na implantação,
          maior aderência e diminuição da curva de aprendizado para uso. O
          DATTASIGN prestará os melhores esforços para assegurar e desenvolver a
          qualidade do serviço ofertado, respeitando a privacidade do usuário,
          garantindo o não monitoramento ou divulgação de informações relativas
          à sua utilização, a menos que seja obrigado a fazê-lo mediante ordem
          judicial ou por lei.
        </p>

        <h2 style={styles.titleAndSubtitle}>3. Controle de Acesso</h2>
        <p style={styles.paragraphContainer}>
          O usuário se compromete a não divulgar a terceiros seus dados de
          acesso que são pessoais e intransferíveis. Dessa forma, os usuários
          são responsáveis por qualquer atividade realizada por meio da
          utilização de seus dados de acesso, respondendo pelas consequências
          geradas a partir de sua conduta dentro do sistema. O usuário também se
          compromete a apresentar somente informações verdadeiras e precisas.
        </p>
        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.titleAndSubtitle}>3.1</h2>
          <p>
            O controle de acesso/autenticação pode ser realizado da seguinte
            forma:
          </p>
        </div>
        <p>
          Duplo fator - esse modelo possibilita o acesso por meio de login e
          senha, com verificação de código enviado para e-mail;
        </p>
        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.titleAndSubtitle}>3.2</h2>
          <p>
            É proibida a cessão, venda, aluguel ou compartilhamento do seu plano
            e dados de acesso.
          </p>
        </div>
        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.titleAndSubtitle}>3.3</h2>
          <p>Cadastros duplicados não serão permitidos.</p>
        </div>
        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.titleAndSubtitle}>3.4</h2>
          <p>
            Em caso de violação de qualquer prática descrita nessa seção, o
            DATTASIGN poderá excluir todos os cadastros relacionados.
          </p>
        </div>

        <h2 style={styles.titleAndSubtitle}>4. Sobre os tipos de assinatura</h2>
        <p style={styles.paragraphContainer}>
          A assinatura de documentos e contratos pode ser realizada de três
          formas:
        </p>
        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.titleAndSubtitle}>4.1</h2>
          <p>
            Aceite digital - baseado apenas na concordância dos termos, é o
            modelo mais simples de assinatura, no qual o signatário pode acordar
            ou discordar com a informação apresentada no documento eletrônico;
          </p>
        </div>
        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.titleAndSubtitle}>4.2</h2>
          <p>
            Token - possibilita que o documento seja assinado por meio de
            confirmação de Token, gerado e enviado para o e-mail ou SMS do
            signatário. Todo o log da atuação do signatário é registrado e
            apresentado com parte do ato da assinatura;
          </p>
        </div>
        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.titleAndSubtitle}>4.3</h2>
          <p>
            Assinatura digital - possibilita que o documento seja assinado por
            meio do uso de certificados digitais ICP Brasil, dos tipos A1 e A3;
          </p>
        </div>

        <h2 style={styles.titleAndSubtitle}>5. Escopo do serviço</h2>
        <p style={styles.paragraphContainer}>
          O envio de documentos para assinatura pode ser realizado através do
          website do DATTASIGN. Para isto, disponibiliza um conjunto de
          funcionalidades, objetivando permitir uma maior flexibilidade na
          integração, permitindo maior acoplamento e abrangência às necessidades
          do usuário:
        </p>
        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.titleAndSubtitle}>5.1</h2>
          <p>
            Signatários - a definição de quem vai assinar o contrato pode ser
            realizada através do e-mail deste;
          </p>
        </div>
        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.titleAndSubtitle}>5.2</h2>
          <p>
            Ordenação - caso seja necessário é possível a definição da ordem das
            assinaturas, permitindo que algum tipo de sequência seja controlada;
          </p>
        </div>
        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.titleAndSubtitle}>5.3</h2>
          <p>
            Mensageria - as informações importantes do processo são enviadas
            para o e-mail dos participantes, permitindo uma melhor elucidação do
            andamento do processo, além de destacar o seu papel e atividade à
            fazer.
          </p>
        </div>
        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.titleAndSubtitle}>5.4</h2>
          <p>Abaixo seguem outras características da plataforma:</p>
        </div>
        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.subtitleItem}>a.</h2>
          <p>
            O dashboard, que possibilita uma melhoria na gestão e tomada de
            decisão a partir da visualização de indicadores e gráficos sobre
            cada etapa do processo.
          </p>
        </div>
        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.subtitleItem}>b.</h2>
          <p>
            O armazenamento e segurança, que possui alguns destaques: (i)
            storage - utilizamos armazenamento na nuvem juntamente com tags e
            descrições, com replicação para a base de gestão documental do
            usuário e; (ii) segurança - possui conformidade com a LGPD e cyber
            security do ponto de vista da aplicação e criptografia dos dados.
          </p>
        </div>
        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.subtitleItem}>c.</h2>
          <p>
            A organização modular, que possibilita a geração de workflows
            alinhados às nuances e especificidades de cada setor da corporação,
            podendo atuar em diversos cenários, como, por exemplo, na geração de
            tecnologia paperless para os processos de admissão digital (setor de
            RH), para o contexto do setor de compras, marketing, entre outros.
          </p>
        </div>
        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.subtitleItem}>d.</h2>
          <p>
            A flexibilidade e customização, que disponibiliza uma arquitetura
            flexível, permitindo adaptações dos f luxos processuais, gerando
            possibilidades de integração mais robustas, com o potencial de
            oferecer uma melhor experiência para os usuários. Essa
            característica certamente vai auxiliar na implantação da estratégia
            paperless em todos os setores da organização.
          </p>
        </div>
        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.subtitleItem}>e.</h2>
          <p>
            A experiência de usuário (UX), que disponibiliza estratégias de
            navegação e usabilidade, permitindo melhor jornada nos fluxos
            processuais, por meio de feedbacks constantes na interface, wizards,
            confirmações via e-mail, entre outros.
          </p>
        </div>
        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.subtitleItem}>f.</h2>
          <p>Modelo de organização de usuários baseada em permissões.</p>
        </div>

        <h2 style={styles.titleAndSubtitle}>
          6. Sobre as integrações e serviços de parceiros
        </h2>
        <p style={styles.paragraphContainer}>
          A API do DATTASIGN disponibiliza a possibilidade de integração com
          serviços e softwares pré-existentes, além da customização de
          interfaces específicas, desenvolvidos de forma customizada para o
          contexto do usuário.
        </p>

        <h2 style={styles.titleAndSubtitle}>
          7. Sobre as customizações, suporte técnico e atendimento
        </h2>
        <p style={styles.paragraphContainer}>
          Abaixo há descrições de como as customizações e suporte técnico são
          realizadas:
        </p>
        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.titleAndSubtitle}>7.1</h2>
          <p>
            Análise de Negócios: etapa que permite um maior alinhamento em
            relação as regras de negócio, os fluxos processuais e
            especificidades do usuário. Nesse momento, é realizado um
            levantamento de requisitos e processos, que permite uma maior
            aderência aos rituais da empresa. Eventualmente podemos propor
            melhorias em algum workflow em caráter de sugestão e sujeito à
            aprovação.
          </p>
        </div>
        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.titleAndSubtitle}>7.2</h2>
          <p>
            Reuniões: como a estratégia é permitir o desenvolvimento de uma
            solução com maior alinhamento e integração em relação aos processos
            e necessidades dos usuários, o DATTASIGN tem agenda aberta e
            flexível para a realização de reuniões durante o período de
            concepção, implantação e pós-implantação.
          </p>
        </div>
        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.titleAndSubtitle}>7.3</h2>
          <p>
            Treinamentos: finalizada as etapas de validação, implementação e
            implantação, realizamos treinamentos práticos com os usuários, sobre
            todos os itens da plataforma.
          </p>
        </div>
        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.titleAndSubtitle}>7.4</h2>
          <p>
            Suporte técnico: mantemos uma equipe de suporte disponível em
            horário comercial, ou a combinar, para atender os chamados e
            responder com a maior celeridade possível.
          </p>
        </div>
        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.titleAndSubtitle}>7.5</h2>
          <p>
            Interação com os times de desenvolvimento: mantemos contato
            permanente com os times de desenvolvimento, da área de tecnologia da
            informação do usuário, objetivando resolver rapidamente eventuais
            problemas de integração, suporte a API e bugs.
          </p>
        </div>

        <h2 style={styles.titleAndSubtitle}>
          8. Sobre as garantias especiais de nível de serviço (SLA)
        </h2>
        <p style={styles.paragraphContainer}>
          Caso o usuário necessite de Garantias especiais de Nível de Serviço
          (SLA) deverá contatar a equipe comercial para verificar as condições.
        </p>

        <h2 style={styles.titleAndSubtitle}>9. Sobre problemas no sistema</h2>
        <p style={styles.paragraphContainer}>
          Falhas eventuais com impacto nas funcionalidades do sistema serão
          corrigidas com a maior agilidade possível. Apesar de utilizar
          infraestrutura e tecnologia de ponta, o DATTASIGN não oferece
          garantias de funcionamento ininterrupto do seu sistema, banco de dados
          ou infraestrutura, dessa forma, a empresa não se responsabiliza por
          possíveis danos sofridos pelo usuário. O usuário não poderá atribuir
          ao DATTASIGN responsabilidade nem exigir o ressarcimento em virtude de
          prejuízos resultantes de dificuldades técnicas, problemas nos
          sistemas, banco de dados, infraestrutura ou conectividade.
        </p>

        <h2 style={styles.titleAndSubtitle}>
          10. Sobre a cobrança e condições comerciais
        </h2>
        <p style={styles.paragraphContainer}>
          O DATTASIGN cobra pelos serviços prestados aos seus usuários por meio
          da aquisição de planos com créditos pré-pagos. Cada crédito
          corresponde a um documento assinado. O valor de cada crédito pode
          variar de acordo com a quantidade de créditos adquiridos ou com plano
          adiquirido. O número de usuários signatários e operadores são
          ilimitados. Os detalhamentos complementares são apresentados na
          PROPOSTA COMERCIAL acordada. Os pagamentos poderão ser realizados por
          meio de boleto bancário ou cartão de crédito, de acordo com plano
          definido pelo usuário. Em caso de pagamento com cartão a transação
          financeira será realizada com o auxílio de parceiro adquirente. Caso
          específicos e outras condições de pagamentos podem ser negociados
          diretamente no contexto dos planos customizados.
        </p>

        <h2 style={styles.titleAndSubtitle}>11. Sobre a inadimplência</h2>
        <p style={styles.paragraphContainer}>
          Em caso de não realização do pagamento até 15 (quinze) dias
          consecutivos contatos a partir do vencimento da fatura, o DATTASIGN
          poderá suspender a utilização do sistema.
        </p>

        <h2 style={styles.titleAndSubtitle}>12. Sobre a rescisão</h2>
        <p style={styles.paragraphContainer}>
          O Usuário e/ou Empresa poderá finalizar sua Conta Individual a
          qualquer momento. O DATTASIGN também poderá finalizar sua conta, a
          qualquer momento, mediante o envio de notificação com pelo menos 30
          (trinta) dias de antecedência.
        </p>

        <h2 style={styles.titleAndSubtitle}>13. Sobre os serviços gratuitos</h2>
        <p style={styles.paragraphContainer}>
          Não haverá cobrança em relação aos serviços descritos como gratuitos.
          No entanto, o DATTASIGN poderá a qualquer momento finalizar a
          disponibilização de funcionalidades oferecidas sem custo, mediante
          aviso por meio do e-mail, portal ou sistema.
        </p>

        <h2 style={styles.titleAndSubtitle}>14. Sobre os conteúdos e atos</h2>
        <p style={styles.paragraphContainer}>
          É de total responsabilidade dos usuários o teor, as informações e o
          tipo de documentos que estão sendo assinados entre as partes. O
          DATTASIGN não tem controle sobre acordos que estão sendo realizados
          por meio do sistema e não tem responsabilidade sobre nenhum ato
          realizado ou acordado pelo usuário.
        </p>

        <h2 style={styles.titleAndSubtitle}>
          15. Sobre a validade dos documentos
        </h2>
        <p style={styles.paragraphContainer}>
          Apesar de a lei brasileira vigente prever que um Documento Eletrônico
          possui abrangência de validade em medida equivalente a um documento em
          papel, o DATTASIGN não poderá, em nenhuma situação, ser
          responsabilizada caso qualquer documento assinado por meio da
          plataforma de assinaturas, não seja aceito por terceiros com quem o
          usuário venha a transacionar.
        </p>

        <h2 style={styles.titleAndSubtitle}>
          16. Sobre os direitos de propriedade intelectual
        </h2>
        <p style={styles.paragraphContainer}>
          Todas as informações, funcionalidades, interfaces, workflows, imagens,
          textos disponibilizados no portal www.dattasign.com.br e sua
          hierarquia de páginas, incluindo todo o sistema, pertencem ao
          DATTASIGN, estando sujeitos às legislações brasileiras sobre direito
          intelectual.
        </p>

        <h2 style={styles.titleAndSubtitle}>
          17. Sobre o uso da marca do parceiro ou usuário
        </h2>
        <p style={styles.paragraphContainer}>
          O usuário concorda que o DATTASIGN poderá utilizar de forma eventual o
          nome e/ou marca da EMPRESA parceira, objetivando divulgar o seu
          portfólio de clientes.
        </p>

        <h2 style={styles.titleAndSubtitle}>18. Sobre as penalidades</h2>
        <p style={styles.paragraphContainer}>
          O DATTASIGN se coloca no direito de suspender ou cancelar o cadastro
          e/ou acesso do usuário em caso de inadimplência, infração legal ou de
          qualquer das disposições deste documento, sem prejuízo de
          responsabilizá-lo civil e criminalmente pelas consequências de suas
          ações ou omissões.
        </p>

        <h2 style={styles.titleAndSubtitle}>19. Sobre a não exclusividade</h2>
        <p style={styles.paragraphContainer}>
          Tanto o USUÁRIO quanto o DATTASIGN estão autorizados a realizar
          qualquer contrato de mesma natureza com terceiros, dessa forma, não
          existe qualquer tipo de exclusividade entre as partes.
        </p>

        <h2 style={styles.titleAndSubtitle}>20. Sobre a não vinculação</h2>
        <p style={styles.paragraphContainer}>
          As partes estabelecem mutuamente que o presente instrumento não
          decorre nem dá direito a qualquer tipo de participação ou vinculação
          societária.
        </p>

        <h2 style={styles.titleAndSubtitle}>
          21. Sobre informações pessoais e registros
        </h2>
        <p style={styles.paragraphContainer}>
          O DATTASIGN se reserva ao direito de utilizar mecanismos próprios e
          APIs externas para confirmar os dados pessoais informados, tais como:
          nome, telefone, CPF, RG e endereço, podendo inclusive consultar outras
          empresas ou entidades. O sistema também armazena informações de uso
          dos usuários: a data e horário da assinatura, endereços de IPs,
          geolocalização, as ações tomadas, os dados preenchidos, o perfil de
          utilização, dentre outras. Essas informações serão úteis para a
          geração de estatísticas, auditorias e relatórios de acesso, além da
          produção do certificado de assinatura que contém esses dados de forma
          estruturada. Maiores informações sobre os dados coletados podem ser
          acessadas na Política de Privacidade.
        </p>

        <h2 style={styles.titleAndSubtitle}>22. Sobre a segurança</h2>
        <p style={styles.paragraphContainer}>
          Mesma sabendo que nenhuma aplicação é 100% (cem por cento) segura, de
          forma que é muito difícil impedir que terceiros se utilizem de meios
          ilícitos para realizar ataques e recuperar informações, o DATTASIGN,
          de forma permanente, busca utilizar tecnologias, métodos e processos
          avançados de proteção e segurança das informações pessoais dos seus
          usuários e documentos. Dessa forma, o DATTASIGN não será responsável
          por interceptações ilegais ou violação de seus sistemas por parte de
          pessoas não autorizadas, tampouco se responsabilizará pela indevida
          utilização da informação obtida por esses ou quaisquer outros meios
          inidôneos, mas tão somente na hipótese de comprovada culpa.
        </p>

        <h2 style={styles.titleAndSubtitle}>
          23. Sobre as comunicações e notificações formais
        </h2>
        <p style={styles.paragraphContainer}>
          Ficam à disposição do usuário os canais de comunicação e atendimento
          do DATTASIGN disponibilizados no portal e sistema. As comunicações
          podem realizadas por meio do e-mail: contato@dattasign.com.br
        </p>

        <h2 style={styles.titleAndSubtitle}>24. Foro</h2>
        <p style={styles.paragraphContainer}>
          As Partes elegem o foro da comarca da cidade de João Pessoa/PB para
          dirimir qualquer controvérsia oriunda do presente Contrato,
          renunciando expressamente a qualquer outro, por mais privilegiado que
          seja.
        </p>

        <div style={styles.divider} />
        <div style={{ padding: '1rem 0' }}>
          <div style={styles.contactContainer}>
            <p style={{ fontWeight: 700, fontSize: '1rem' }}>E-mail:</p>
            <p style={{ fontWeight: 500, fontSize: '1rem' }}>
              apoio@conlicitacao.com.br
            </p>
          </div>
          <div style={styles.contactContainer}>
            <p style={{ fontWeight: 700, fontSize: '1rem' }}>E-Telefone:</p>
            <p style={{ fontWeight: 500, fontSize: '1rem' }}>+5511387838666</p>
          </div>
        </div>
      </div>
    </>
  );
};

const ConlicitacacaoTermsAndConditions = () => {
  const { userInfo } = useAuthContext();
  const { whiteLabel } = useWhiteLabel();

  const role = userInfo ? userInfo.user.roles[0].nome : '';

  return (
    <Box style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <PageMainContentBox
        title="Termos e Condições do Dattasign"
        page="termos_e_condicoes"
        overflow="auto"
      >
        {roleBasedRender(
          role,
          whiteLabel === 'conlicitacao' ? (
            <TermsAndConditions />
          ) : (
            <DeniedAccess />
          ),
          <DeniedAccess />,
          <DeniedAccess />,
          null,
        )}
      </PageMainContentBox>
    </Box>
  );
};

export default ConlicitacacaoTermsAndConditions;
