/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react';
import { SxProps, Theme, useTheme } from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import styled from '@mui/material/styles/styled';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import AddLocationIcon from '@mui/icons-material/AddLocation';

import useDocumentContext from '../../hooks/useDocumentContext';
import useDialogContext from '../../hooks/useDialogContext';

import { Signee as SigneeType } from '../../types/Signee';
import { Coordinates } from '../../types/Coordinates';
import { DigitalSignatureType } from '../../types/DigitalSignatureType';
import { DocumentStatus } from '../../types/Sign';

import COLORS from '../../utils/colors';
import {
  getPageCenter,
  drawStampAnnotation,
  deleteAnnotationsFromEmail,
  thisUserHaveInitials,
  addInitialOnAllPages,
} from '../../utils/pdfExpressHelpers';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 200,
    color: COLORS.mediumGray5,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',

    '& .MuiMenu-list': {
      padding: '4px 0',
    },

    '& .MuiMenuItem-root': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '8px',

      '& .MuiSvgIcon-root': {
        fontSize: '1.2rem',
        color: COLORS.white,
        marginRight: theme.spacing(1.5),
      },

      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },

      '&:active': {
        backgroundColor: theme.palette.primary.main,
        color: COLORS.white,

        '& .MuiSvgIcon-root': {
          color: COLORS.white,
        },
      },

      '&:selected': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}));

const styles = {
  boxDigitalCertificate: {
    display: 'flex',
    margin: '0 auto',
    padding: '0.125rem .5rem .5rem',
    gap: '.5rem',

    width: '94%',
    borderBottom: `1px solid ${COLORS.lightGray3}`,
  },

  buttonAddSignature: {
    textTransform: 'none',
  } as SxProps<Theme>,

  buttonAddInitials: {
    textTransform: 'none',
  } as SxProps<Theme>,
};

type Props = {
  existingSignees?: Array<SigneeType>;
  signee: SigneeType;
  mySignees: Array<SigneeType>;
  setMySignees(newSignees: Array<SigneeType>): void;
  dropPoint: Coordinates | null;
  setDropPoint: React.Dispatch<React.SetStateAction<Coordinates | null>>;
  isSortingSignees: boolean;
  maxNumberToChooseOrder: number;
  setMaxNumberToChooseOrder: React.Dispatch<React.SetStateAction<number>>;
  handleChangeOrder(email: string, value: number): void;
  documentStatus?: DocumentStatus;
};

const Signee = ({
  existingSignees,
  signee,
  mySignees,
  setMySignees,
  dropPoint,
  setDropPoint,
  isSortingSignees,
  maxNumberToChooseOrder,
  setMaxNumberToChooseOrder,
  handleChangeOrder,
  documentStatus,
}: Props) => {
  const [anchorElSimpleMenu, setAnchorElSimpleMenu] =
    useState<null | HTMLElement>(null);
  const open = Boolean(anchorElSimpleMenu);

  const { webviewInstance } = useDocumentContext();
  const { showDialog } = useDialogContext();

  const theme = useTheme();

  const isDigitalSignature = signee.autenticacao === 'assinatura_digital';
  const isDigitalCertificate = signee.autenticacao === 'certificado_digital';

  const [hasSignStatus, setHasSignStatus] = useState(false);

  const handleOpenSimpleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElSimpleMenu(event.currentTarget);
  };

  const handleCloseSimpleMenu = () => {
    setAnchorElSimpleMenu(null);
  };

  const deleteHandler = () => {
    let orderOfRemovedElement = 0;
    let newSignees = mySignees.filter(element => {
      if (element.email === signee.email) {
        orderOfRemovedElement = element.order;
      }
      return element.email !== signee.email;
    });

    const maxNumber = Math.min(newSignees.length, maxNumberToChooseOrder);

    setMaxNumberToChooseOrder(maxNumber);

    newSignees = newSignees.map(element => {
      if (element.order > orderOfRemovedElement) {
        return {
          ...element,
          order: element.order - 1,
        };
      }

      if (element.order > maxNumber) {
        return {
          ...element,
          order: maxNumber,
        };
      }
      return element;
    });

    setMySignees(newSignees);
    deleteAnnotationsFromEmail(webviewInstance, signee.email);
  };

  const addAnnotation = (type: DigitalSignatureType | 'certificate') => {
    let pageCoordinates = dropPoint;
    if (!pageCoordinates) {
      pageCoordinates = getPageCenter(webviewInstance);
    }

    drawStampAnnotation(
      webviewInstance,
      type,
      theme.palette.primary.main,
      signee.email,
      pageCoordinates.x,
      pageCoordinates.y,
      webviewInstance.Core.documentViewer.getCurrentPage(),
    );
  };

  const dragStart = (e: any) => {
    const copy = e.target.cloneNode(true);
    copy.id = 'form-build-drag-image-copy';
    document.body.appendChild(copy);

    e.dataTransfer.setDragImage(
      copy,
      e.target.clientWidth / 2,
      e.target.clientHeight / 2,
    );
    e.dataTransfer.setData('text', '');
  };

  const dragEnd = (e: any, type: DigitalSignatureType | 'certificate') => {
    addAnnotation(type);

    const element = document.getElementById('form-build-drag-image-copy');
    if (!element) {
      return;
    }

    document.body.removeChild(element);
    setDropPoint(null);
    e.preventDefault();
  };

  const canAddInitialOnAllPages = () => {
    return (
      thisUserHaveInitials(webviewInstance, signee.email) &&
      webviewInstance.Core.documentViewer.getPageCount() > 1
    );
  };

  const handleAddInitialOnAllPages = () => {
    const initialsWasAdded = addInitialOnAllPages(
      webviewInstance,
      signee.email,
    );

    if (initialsWasAdded) {
      showDialog('success', 'As rubricas foram adicionadas!');
    }
  };

  const disabledLocationFieldSignee = () => {
    if (!webviewInstance) {
      return true;
    }

    if (signee.autenticacao !== 'certificado_digital') {
      return false;
    }

    const { Annotations, annotationManager } = webviewInstance.Core;

    const stampAnnotations = annotationManager
      .getAnnotationsList()
      .filter(
        (annot: any) =>
          annot instanceof Annotations.StampAnnotation &&
          annot.getCustomData('email') === signee.email &&
          annot.getCustomData('type') === 'certificate',
      );

    return Boolean(stampAnnotations.length);
  };

  useEffect(() => {
    if (existingSignees) {
      existingSignees.map(existingsignee => {
        if (existingsignee.email === signee.email) {
          setHasSignStatus(true);
        }
      });
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '.5rem auto 0 auto',
          padding: '.5rem',
          borderBottom:
            isDigitalSignature || isDigitalCertificate
              ? ''
              : `1px solid ${COLORS.lightGray3}`,
          overflow: 'auto',
          width: '94%',
        }}
      >
        {isSortingSignees && mySignees.length > 1 && (
          <FormControl
            sx={{
              border: `1px solid ${theme.palette.primary.main}`,
            }}
          >
            <InputLabel id="orderGroup">Grupo</InputLabel>

            <Select
              labelId="orderGroup"
              value={signee.order}
              onChange={event =>
                handleChangeOrder(signee.email, event.target.value as number)
              }
              sx={{
                width: '4rem',
                height: '3rem',
                padding: 0,
              }}
            >
              {Array.from(
                { length: maxNumberToChooseOrder },
                (v, k) => k + 1,
              ).map(value => {
                return (
                  <MenuItem
                    key={value}
                    value={value}
                    defaultValue={1}
                    sx={{
                      '&.Mui-selected': {
                        bgcolor: `${theme.palette.primary.main} !important`,
                        color: `${COLORS.white} !important`,
                        border: `2px solid ${theme.palette.primary.main} !important`,
                      },
                      ':hover': {
                        bgcolor: theme.palette.primary.light,
                      },
                      ':focus': {
                        bgcolor: theme.palette.primary.main,
                        border: `2px solid ${theme.palette.primary.main} !important`,
                        color: COLORS.white,
                      },
                      ':active': {
                        bgcolor: theme.palette.primary.main,
                        border: `2px solid ${theme.palette.primary.main} !important`,
                        color: COLORS.white,
                      },
                    }}
                  >
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '.5rem',
          }}
        >
          <Typography sx={{ textAlign: 'justify' }}>{signee.nome}</Typography>

          <Link
            sx={{
              color: 'primary.main',
              textDecoration: 'none',
              ':hover': { textDecoration: 'underline' },
            }}
            component="button"
            variant="body2"
            onClick={handleOpenSimpleMenu}
          >
            Ver detalhes
          </Link>
        </Box>

        {signee.status !== 'assinado' && documentStatus !== 'finalizado' && (
          <Tooltip title="Remover participante">
            <IconButton
              sx={{
                color: COLORS.redWarning,
                fontSize: '1.3rem',
                cursor: 'pointer',
                '&:hover': { color: COLORS.lightRed },
                '&:disabled': { color: COLORS.lightRed },
              }}
              onClick={deleteHandler}
            >
              <RemoveCircleIcon />
            </IconButton>
          </Tooltip>
        )}

        {open && (
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorElSimpleMenu}
            open={open}
            onClose={handleCloseSimpleMenu}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                '&:hover': { backgroundColor: 'transparent' },
                gap: '8px',
                padding: '12px 16px',
              }}
            >
              <Typography sx={{ fontSize: '.9rem', fontWeight: 'bold' }}>
                Nome:
              </Typography>
              <Typography sx={{ fontSize: '.9rem' }}>{signee.nome}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                '&:hover': { backgroundColor: 'transparent' },
                gap: '8px',
                padding: '12px 16px',
              }}
            >
              <Typography sx={{ fontSize: '.9rem', fontWeight: 'bold' }}>
                Email:
              </Typography>
              <Typography sx={{ fontSize: '.9rem' }}>{signee.email}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                '&:hover': { backgroundColor: 'transparent' },
                gap: '8px',
                padding: '12px 16px',
              }}
            >
              <Typography sx={{ fontSize: '.9rem', fontWeight: 'bold' }}>
                Ação:
              </Typography>
              <Typography
                sx={{ fontSize: '.9rem', textTransform: 'capitalize' }}
              >
                {signee.type === 'cc' ? 'Receber cópia' : 'Assinar'}
              </Typography>
            </Box>
            {signee.type === 'toSign' && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  '&:hover': { backgroundColor: 'transparent' },
                  gap: '8px',
                  padding: '12px 16px',
                }}
              >
                <Typography sx={{ fontSize: '.9rem', fontWeight: 'bold' }}>
                  {hasSignStatus ? 'Status:' : 'Autenticação:'}
                </Typography>
                <Typography
                  sx={{ fontSize: '.9rem', textTransform: 'capitalize' }}
                >
                  {hasSignStatus
                    ? signee.status.replace('_', ' ')
                    : signee.autenticacao?.replace('_', ' ')}
                </Typography>
              </Box>
            )}
            {(signee.autenticacao === 'sms' ||
              signee.autenticacao === 'whatsapp') &&
              signee.type === 'toSign' && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    '&:hover': { backgroundColor: 'transparent' },
                    gap: '8px',
                    padding: '12px 16px',
                  }}
                >
                  <Typography sx={{ fontSize: '.9rem', fontWeight: 'bold' }}>
                    Telefone:
                  </Typography>
                  <Typography sx={{ fontSize: '.9rem' }}>
                    {signee.numero}
                  </Typography>
                </Box>
              )}
            {/* <Divider sx={{ my: 0.5 }} />
            <MenuItem onClick={() => {}} disableRipple>
              <EditIcon />              
              <Typography sx={{ fontSize: '.9rem', fontWeight: 'bold' }}>Editar informações</Typography> 
            </MenuItem> */}
          </StyledMenu>
        )}
      </Box>

      {isDigitalSignature && (
        <Box sx={styles.boxDigitalCertificate}>
          <div
            draggable
            onDragStart={e => dragStart(e)}
            onDragEnd={e => dragEnd(e, 'signature')}
          >
            <Button
              variant="outlined"
              sx={{
                textTransform: 'none',
                color: 'primary.main',
                border: `1px solid ${theme.palette.primary.main}`,
                '&:hover': {
                  backgroundColor: theme.palette.primary.light,
                  border: `1px solid ${theme.palette.primary.main}`,
                },
              }}
              startIcon={<BorderColorOutlinedIcon />}
              onClick={() => addAnnotation('signature')}
            >
              Assinatura
            </Button>
          </div>

          <div
            draggable
            onDragStart={e => dragStart(e)}
            onDragEnd={e => dragEnd(e, 'initials')}
          >
            <Button
              variant="outlined"
              sx={{
                textTransform: 'none',
                color: 'primary.main',
                border: `1px solid ${theme.palette.primary.main}`,
                '&:hover': {
                  backgroundColor: 'primary.light',
                  border: `1px solid ${theme.palette.primary.main}`,
                },
              }}
              startIcon={<VerticalAlignBottomIcon />}
              onClick={() => addAnnotation('initials')}
            >
              Rubrica
            </Button>
          </div>

          {canAddInitialOnAllPages() && (
            <Button
              variant="outlined"
              sx={styles.buttonAddInitials}
              startIcon={<DoneAllIcon />}
              onClick={handleAddInitialOnAllPages}
            >
              Rubrica em todas as páginas
            </Button>
          )}
        </Box>
      )}

      {isDigitalCertificate && (
        <Box sx={styles.boxDigitalCertificate}>
          <div
            draggable
            onDragStart={e => dragStart(e)}
            onDragEnd={e => dragEnd(e, 'certificate')}
          >
            <Button
              variant="outlined"
              sx={styles.buttonAddSignature}
              startIcon={<AddLocationIcon />}
              onClick={() => addAnnotation('certificate')}
              disabled={disabledLocationFieldSignee()}
            >
              Escolher localização
            </Button>
          </div>
        </Box>
      )}
    </>
  );
};

export default Signee;
