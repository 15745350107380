/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import Logo from '../../components/Logo';

import { AuthenticationType } from '../../types/AuthenticationType';
import { OriginToken } from '../../types/OriginToken';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const useStyles = makeStyles({
  container: {
    width: '100vw',
    height: '100vh',
  },

  logo: {
    width: '8rem',
  },
});

const SignHeader = styled('header')(({ theme }) => ({
  height: '100%',
  minHeight: '70px',
  maxHeight: '8vh',
  padding: '.5rem 15%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${COLORS.lightGray3}`,
  backgroundColor: COLORS.white,
  [theme.breakpoints.down(390)]: {
    flexDirection: 'column',
    height: '9vh',
  },
  [theme.breakpoints.down(640)]: {
    padding: '.5rem 5%',
  },
}));

export interface SignDataLoggedOff {
  document: {
    id: string;
    data: string;
    nome: string;
    xfdf: string;
  };
  signee: {
    name: string;
    email: string;
    number?: string;
    signatureType: AuthenticationType;
    token_origem: OriginToken;
  };
  accept: boolean;
  verified_token?: boolean;
}

export default function Sign() {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const { token } = useParams();

  const [signData, setSignData] = useState<SignDataLoggedOff | null>(null);
  const [buttonSignIsDisabled, setButtonSignIsDisabled] = useState(true);

  const loadData = async () => {
    try {
      const response = await api.get(`documento/visualiza/${token}`);

      setSignData(response.data);
      setButtonSignIsDisabled(false);
    } catch (error: any) {
      if (!error.response || typeof error.response.data.message !== 'string') {
        return;
      }

      navigate(`/sign/${token}/error`, {
        state: {
          title: error.response.data.title,
          description: error.response.data.message,
        },
      });
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className={classes.container}>
      <SignHeader>
        <Logo type="logOff" />

        <Typography
          sx={{
            fontSize: '1.3rem',
            color: 'primary.main',
            [theme.breakpoints.down(640)]: {
              fontSize: '1rem',
            },
          }}
        >
          Assinatura do documento
        </Typography>
      </SignHeader>

      <Outlet context={{ signData, setSignData, buttonSignIsDisabled }} />
    </div>
  );
}
