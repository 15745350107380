/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import makeStyles from '@mui/styles/makeStyles';
import LoadingButton from '@mui/lab/LoadingButton';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

import { FolderArrayObject } from '../../types/Folder';

import useAuthContext from '../../hooks/useAuthContext';
import useCostCenterContext from '../../hooks/useCostCenterContext';
import useDialogContext from '../../hooks/useDialogContext';

import { UserCostCenter } from '../../types/CostCenter';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const useStyles = makeStyles({
  dialog: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40rem',
    margin: '0 auto',
  },

  folderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: '18rem',
    minHeight: '70px',
    maxHeight: '70px',
    height: '100%',
    padding: '1rem',
    borderRadius: '10px',
    gap: '10px',
    border: `1px solid rgba(0, 0, 0, 0.12)`,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: COLORS.background,
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '30rem',
    padding: '0 1.5rem 1.5rem',
    gap: '10px',
  },

  text: {
    textAlign: 'justify',
    color: COLORS.black,
    fontWeight: 'bold',
    fontSize: '.925rem',
  },

  dialogActions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '1rem 0 0 1rem',
    alignSelf: 'end',
    marginTop: '1rem',
    gap: '.5rem',
  },
});

interface SubfolderForm {
  nome: string;
}
interface Props {
  folderArray?: FolderArrayObject[] | null;
  isSubfolderPage?: boolean;
  getSubfolderFoldersAndFiles?: (id: number) => Promise<void> | undefined;
  getCostCenterFoldersAndFiles?: (
    costCenterId: string | undefined,
  ) => Promise<void> | undefined;
}

const ModalCreateSubFolder: React.FC<Props> = ({
  folderArray,
  isSubfolderPage,
  getSubfolderFoldersAndFiles,
  getCostCenterFoldersAndFiles,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { costId } = useParams();

  const { showDialog } = useDialogContext();
  const { isLogged } = useAuthContext();
  const { userInformation, getUserInformation } = useCostCenterContext();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [subfolderName, setSubfolderName] = useState('');

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm<SubfolderForm>();

  const handleClose = () => {
    setOpen(false);
    clearErrors();
    reset();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const [currentCostCenter] = useState(
    JSON.parse(localStorage.getItem('current-cost-center') || ''),
  );

  useEffect(() => {
    if (isLogged) {
      getUserInformation();
    }
  }, []);

  const costCenter: UserCostCenter | undefined | null =
    userInformation &&
    userInformation.centros_custo.find(
      c => c.id === Number(currentCostCenter.id),
    );

  const onSubmit: SubmitHandler<SubfolderForm> = async () => {
    setIsSubmitting(true);
    let lastFolder;
    if (folderArray) {
      lastFolder = folderArray[folderArray.length - 1];
    }

    try {
      const data = {
        name: subfolderName,
        folder_id: lastFolder?.id,
      };
      await api.post(`folder/${costCenter?.id}`, data);
      if (lastFolder) {
        console.log('last folder ', lastFolder);
        if (isSubfolderPage) {
          getSubfolderFoldersAndFiles?.(lastFolder.id);
        }
      }
      getCostCenterFoldersAndFiles?.(costId);
      setIsSubmitting(false);
      handleClose();
      setSubfolderName('');
    } catch (error: any) {
      setIsSubmitting(false);
      if (!error.response) {
        showDialog(
          'default',
          'Erro inesperado. Por favor, tente novamente mais tarde!',
        );
        return;
      }
      showDialog('default', error.response.data.message);
    }
  };

  return (
    <>
      <Button
        disableRipple
        onClick={handleOpen}
        color="primary"
        sx={{
          minWidth: 'unset',
          width: 'fit-content',
          textTransform: 'capitalize',
          '& span': {
            marginBottom: '.1rem',
            marginRight: '.2rem',
          },
          '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
          },
        }}
        aria-label="Criar subpasta"
        startIcon={<CreateNewFolderIcon />}
      >
        Criar pasta
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        className={classes.dialog}
        sx={{ width: '30rem' }}
      >
        <DialogTitle>Criar pasta</DialogTitle>

        <form
          noValidate
          method="post"
          className={classes.content}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Stack
            sx={{
              width: '100%',
              gap: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'column',
            }}
          >
            <Typography
              className={classes.text}
              sx={{ margin: '0 auto .5rem 0', fontSize: '.925rem' }}
              variant="subtitle2"
            >
              Informe o nome da pasta.
            </Typography>

            <FormControl sx={{ width: '100%' }}>
              <TextField
                autoFocus
                label="Nome"
                variant="standard"
                color="primary"
                type="text"
                {...register('nome', {
                  required: 'Por favor, informe o nome da pasta',
                  minLength: {
                    value: 1,
                    message: 'O nome deve conter pelo menos 1 caractere',
                  },
                  pattern: {
                    value: /^[A-zÀ-ú 0-9']+$/,
                    message: 'Por favor, informe um nome válido',
                  },
                })}
                value={subfolderName}
                onChange={e => {
                  setSubfolderName(e.target.value);
                  clearErrors();
                }}
                error={Boolean(errors?.nome)}
                helperText={errors?.nome?.message}
              />
            </FormControl>
          </Stack>

          <Box className={classes.dialogActions}>
            <Button
              onClick={handleClose}
              sx={{
                width: '7rem',
                color: COLORS.mediumGray3,
                '&:hover': {
                  backgroundColor: COLORS.lightGray2,
                },
              }}
            >
              Cancelar
            </Button>
            {isSubmitting ? (
              <LoadingButton
                loading
                loadingPosition="center"
                sx={{
                  width: '7rem',
                  height: '2.25rem',
                  color: COLORS.white,
                  backgroundColor: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'primary.light',
                  },
                }}
              />
            ) : (
              <Button
                type="submit"
                endIcon={<CreateNewFolderIcon />}
                sx={{
                  width: '8rem',
                  color: COLORS.white,
                  backgroundColor: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'primary.light',
                  },
                }}
              >
                Criar
              </Button>
            )}
          </Box>
        </form>
      </Dialog>
    </>
  );
};

export default ModalCreateSubFolder;
