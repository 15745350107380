/* eslint-disable no-nested-ternary */
import AuthCode from 'react-auth-code-input';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import COLORS from '../utils/colors';

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    width: '3.5rem',
    height: '3.5rem',
    padding: 0,
    fontSize: '2rem',
    lineHeight: '2rem',
    textAlign: 'center',
    marginRight: '.75rem',
    marginBottom: '.75rem',
    color: COLORS.gray1,
    border: `1px solid ${COLORS.black}80`,
    borderRadius: '.25rem',

    '&:focus': {
      appearance: 'none',
      outline: `2px solid ${theme.palette.primary.light}}`,
    },
  },
}));

interface Props {
  onChange(newValue: string): void;
}

const CustomVerificationCodeInput: React.FC<Props> = ({ onChange }) => {
  const classes = useStyles();

  return (
    <AuthCode autoFocus inputClassName={classes.input} onChange={onChange} />
  );
};

export default CustomVerificationCodeInput;
