import React from 'react';
import { Navigate } from 'react-router-dom';

import useAuthContext from '../hooks/useAuthContext';

interface Props {
  component: React.FC;
}

const PublicRoute: React.FC<Props> = ({ component: Component }) => {
  const { isLogged } = useAuthContext();

  if (isLogged) {
    return <Navigate to="/inicio" />;
  }

  return <Component />;
};

export default PublicRoute;
