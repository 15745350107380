import Grid from '@mui/material/Grid';
import styled from '@mui/styles/styled';

import RegisterCard from './RegisterCard';
import GoBackPageArrow from '../../components/GoBackPageArrow';
import BackgroundImage from '../../components/BackgroundImage';

const OuterGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  overflow: 'auto !important',
});

export default function Register() {
  return (
    <OuterGrid sx={{ width: '100%' }}>
      <GoBackPageArrow title="Voltar à página inicial" link="/landing" />

      <BackgroundImage />

      <RegisterCard />
    </OuterGrid>
  );
}
