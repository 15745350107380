/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ListItemText from '@mui/material/ListItemText';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import CssBaseline from '@mui/material/CssBaseline';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import FolderIcon from '@mui/icons-material/Folder';
import HistoryIcon from '@mui/icons-material/History';
import GavelIcon from '@mui/icons-material/Gavel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import { CSSObject, styled, Theme, useTheme } from '@mui/material/styles';

import ConlicitacaoSidebar from './ConlicitacaoSidebar/ConlicitacaoSidebar';
import Logo from './Logo';

import useAuthContext from '../hooks/useAuthContext';
import useWhiteLabel from '../hooks/useWhiteLabel';

import COLORS from '../utils/colors';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('mobile')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

type UserRole = 'user' | 'operador' | 'admin';

interface Props {
  page:
    | 'inicio'
    | 'minha_conta'
    | 'minhas_pastas'
    | 'documentos'
    | 'planos'
    | 'historico'
    | 'usuarios'
    | 'termos_e_condicoes'
    | 'politica_de_privacidade'
    | 'centros-de-custo';
  setConlicitaAuthNavbarWidth: (conlicitaAuthNavbarWidth: string) => void;
  setDattasignNavbarWidth: (dattasignNavbarWidth: string) => void;
}

const Header: React.FC<Props> = ({
  page,
  setConlicitaAuthNavbarWidth,
  setDattasignNavbarWidth,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { whiteLabel } = useWhiteLabel();

  const { userInfo, costCenter } = useAuthContext();
  const role = userInfo ? userInfo.user.roles[0].nome : '';

  const [open, setOpen] = useState<boolean>(
    JSON.parse(localStorage.getItem('isOpen') || 'true'),
  );

  /* status of conlicitacao menu */
  const [conlicitaOpen, setConlicitaOpen] = useState(false);

  const handleConlicitaDrawerOpen = () => {
    setConlicitaOpen(true);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    localStorage.setItem('isOpen', 'true');
    if (whiteLabel === 'conlicitacao') {
      setConlicitaAuthNavbarWidth('calc(100% - 280px)');
      localStorage.setItem('ConlicitaAuthNavbarWidth', 'calc(100% - 280px)');
    } else {
      setDattasignNavbarWidth('calc(100% - 240px)');
      localStorage.setItem('DattasignNavbarWidth', 'calc(100% - 240px)');
    }
  };

  const handleDrawerClose = () => {
    setOpen(false);
    localStorage.setItem('isOpen', 'false');
    if (whiteLabel === 'conlicitacao') {
      setConlicitaAuthNavbarWidth('calc(100% - 112px)');
      localStorage.setItem('ConlicitaAuthNavbarWidth', 'calc(100% - 112px)');
    } else {
      setDattasignNavbarWidth('calc(100% - 73px)');
      localStorage.setItem('DattasignNavbarWidth', 'calc(100% - 73px)');
    }
  };

  const drawerLinks = {
    user: [
      {
        text: 'Início',
        link: '/inicio',
        icon: <HomeIcon />,
      },
      ...(whiteLabel !== 'conlicitacao'
        ? [
            {
              text: 'Minha Conta',
              link: '/minha_conta',
              icon: <PersonIcon />,
            },
          ]
        : []),
      {
        text: 'Documentos',
        link: '/documentos',
        icon: <FileCopyIcon />,
      },
      {
        text: 'Minhas Pastas',
        link: '/minhas_pastas',
        icon: <FolderIcon />,
      },
      ...(userInfo?.user.id === costCenter?.proprietario.id &&
      whiteLabel !== 'conlicitacao' &&
      whiteLabel !== 'codata' &&
      whiteLabel !== 'prevLaw'
        ? [
            {
              text: 'Planos',
              link: '/planos',
              icon: <LocalMallIcon />,
            },
          ]
        : []),

      {
        text: 'Histórico',
        link: '/historico',
        icon: <HistoryIcon />,
      },
    ],

    operador: [
      {
        text: 'Início',
        link: '/inicio',
        icon: <HomeIcon />,
      },
      {
        text: 'Usuários',
        link: '/usuarios',
        icon: <GroupIcon />,
      },
      {
        text: 'Histórico',
        link: '/historico',
        icon: <HistoryIcon />,
      },
    ],

    admin: [
      {
        text: 'Início',
        link: '/inicio',
        icon: <HomeIcon />,
      },
      {
        text: 'Centros de Custo',
        link: '/centros-de-custo',
        icon: <GroupIcon />,
      },
      {
        text: 'Planos',
        link: '/planos',
        icon: <LocalMallIcon />,
      },
      {
        text: 'Histórico',
        link: '/historico',
        icon: <HistoryIcon />,
      },
    ],
  };

  const links = drawerLinks[role as UserRole] || [];

  const supportListItems = [
    {
      text: 'Política de Privacidade',
      icon: React.createElement(PrivacyTipIcon),
      link:
        whiteLabel === 'conlicitacao'
          ? '/politica_de_privacidade'
          : 'https://dattasign.com.br/politica_de_privacidade.html',
    },
    {
      text: 'Termos e Condições',
      icon: React.createElement(GavelIcon),
      link:
        whiteLabel === 'conlicitacao'
          ? '/termos_e_condicoes'
          : 'https://dattasign.com.br/termos_e_condicoes.html',
    },
    {
      text: 'Ajuda',
      icon: React.createElement(HelpOutlineIcon),
      link:
        whiteLabel === 'conlicitacao'
          ? 'mailto:apoio@conlicitacao.com.br'
          : 'mailto:contato@dattasign.com.br',
    },
  ];

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {whiteLabel === 'conlicitacao' && (
        <ConlicitacaoSidebar
          conlicitaOpen={conlicitaOpen}
          setConlicitaOpen={setConlicitaOpen}
        />
      )}

      <AppBar
        position="fixed"
        open={open}
        style={
          open
            ? { boxShadow: 'none', marginLeft: '4rem' }
            : { boxShadow: 'none' }
        }
      >
        <Tooltip
          title={`Abrir menu ${
            whiteLabel === 'codata' ? 'CodataSign' : 'DattaSign'
          }`}
        >
          <IconButton
            aria-label="Abrir menu"
            onClick={handleDrawerOpen}
            style={{ color: COLORS.gray3 }}
            edge="start"
            sx={{
              position: 'absolute',
              left: whiteLabel === 'conlicitacao' ? '89px' : '30px',
              top: '15px',
              ...(open && { display: 'none' }),
              zIndex: '100',
              '&:hover': {
                backgroundColor: 'primary.light',
              },
            }}
          >
            <MenuIcon />
          </IconButton>
        </Tooltip>
      </AppBar>

      <Drawer
        variant="permanent"
        open={open}
        sx={{
          '& .MuiDrawer-paper': {
            height: '116.5vh',
            left: whiteLabel === 'conlicitacao' ? '59px' : 'unset',
            zIndex: '200',
          },
        }}
      >
        <DrawerHeader sx={{ justifyContent: 'center', gap: '1rem' }}>
          {/* menu icon to open conlicitacao menu */}
          {whiteLabel === 'conlicitacao' && open && (
            <Tooltip title="Abrir menu Conlicitação">
              <IconButton
                aria-label="Abrir menu Conlicitação"
                onClick={handleConlicitaDrawerOpen}
                style={{
                  color: COLORS.gray3,
                  marginTop: '1rem',
                  marginLeft: '.3rem',
                }}
                edge="start"
                sx={{
                  display: open ? 'flex' : 'none',
                  zIndex: '200',
                  '&:hover': {
                    backgroundColor: 'primary.light',
                  },
                }}
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
          )}

          {open && userInfo && <Logo type="logOn" />}

          <IconButton
            onClick={handleDrawerClose}
            color="warning"
            style={{ color: COLORS.gray3 }}
            sx={{
              marginTop: '1rem',
              zIndex: 200,
              ...(!open && { display: 'none' }),
              '&:hover': {
                backgroundColor: 'primary.light',
              },
            }}
          >
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>

        <Divider sx={{ margin: '.6rem 0 .5rem 0' }} />

        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '91.5vh',
          }}
        >
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <List>
              <Stack spacing={0}>
                {!open
                  ? links.map(obj => (
                      <Tooltip title={obj.text} key={obj.text}>
                        <ListItemButton
                          selected={obj.link === `/${page}`}
                          key={obj.text}
                          sx={{
                            fontSize: '.9rem !important',
                            color: COLORS.gray3,
                            transition: 'all .3s ease-out',
                            '&.Mui-selected': {
                              bgcolor: `${theme.palette.secondary.main} !important`,
                              color: `${COLORS.white} !important`,
                              fontWeight: 600,
                              '& .MuiSvgIcon-root': {
                                color: COLORS.white,
                              },
                            },
                            ':hover': {
                              bgcolor: 'primary.light',
                            },
                            ':focus': {
                              bgcolor: 'secondary.main',
                              color: COLORS.white,
                              '& .MuiSvgIcon-root': {
                                color: COLORS.white,
                              },
                            },
                            ':active': {
                              bgcolor: 'secondary.main',
                              color: COLORS.white,
                            },
                          }}
                          onClick={() => {
                            navigate(obj.link);
                          }}
                        >
                          <ListItemIcon
                            style={{ position: 'relative', left: '10px' }}
                          >
                            {obj.icon}
                          </ListItemIcon>
                          <ListItemText
                            primaryTypographyProps={{ fontSize: '.9rem' }}
                            primary={obj.text}
                          />
                        </ListItemButton>
                      </Tooltip>
                    ))
                  : links.map(obj => (
                      <ListItemButton
                        selected={obj.link === `/${page}`}
                        key={obj.text}
                        sx={{
                          fontSize: '.9rem !important',
                          color: COLORS.gray3,
                          transition: 'all .3s ease-out',
                          '&.Mui-selected': {
                            bgcolor: `${theme.palette.secondary.main} !important`,
                            color: `${COLORS.white} !important`,
                            fontWeight: 600,
                            '& .MuiSvgIcon-root': {
                              color: `${COLORS.white} !important`,
                            },
                          },
                          ':hover': {
                            bgcolor: 'primary.light',
                          },
                          ':focus': {
                            bgcolor: 'secondary.main',
                            color: COLORS.white,
                            '& .MuiSvgIcon-root': {
                              color: COLORS.white,
                            },
                          },
                          ':active': {
                            bgcolor: 'secondary.main',
                            color: COLORS.white,
                          },
                        }}
                        onClick={() => {
                          navigate(obj.link);
                        }}
                      >
                        <ListItemIcon
                          style={{ position: 'relative', left: '10px' }}
                        >
                          {obj.icon}
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{ fontSize: '.9rem' }}
                          primary={obj.text}
                        />
                      </ListItemButton>
                    ))}
              </Stack>
            </List>

            <Divider sx={{ margin: '.6rem 0 .5rem 0' }} />
          </Grid>

          <List sx={{ marginBottom: '1rem' }}>
            <Stack spacing={0}>
              {supportListItems.map(obj => (
                <ListItemButton
                  key={obj.text}
                  sx={{
                    backgroundColor: 'unset',
                    fontSize: '.9rem !important',
                    color: COLORS.gray3,
                    transition: 'backgroundColor .5s ease',
                    '&.Mui-selected': {
                      bgcolor: `${theme.palette.secondary.main} !important`,
                      color: `${COLORS.white} !important`,
                      fontWeight: 600,
                      '& .MuiSvgIcon-root': {
                        color: COLORS.white,
                      },
                    },
                    ':hover': {
                      bgcolor: 'primary.light',
                      color: COLORS.gray3,
                    },
                    ':focus': {
                      bgcolor: 'secondary.main',
                      color: COLORS.white,
                      '& .MuiSvgIcon-root': {
                        color: COLORS.white,
                      },
                    },
                    ':active': {
                      bgcolor: 'secondary.main',
                      color: COLORS.white,
                    },
                  }}
                  component="a"
                  href={obj.link}
                  target={whiteLabel === 'conlicitacao' ? '_self' : '_blank'}
                >
                  <ListItemIcon style={{ position: 'relative', left: '10px' }}>
                    {obj.icon}
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ fontSize: '.9rem' }}
                    primary={obj.text}
                  />
                </ListItemButton>
              ))}

              <span
                style={{
                  color: COLORS.mediumGray4,
                  fontSize: '.75rem',
                  textAlign: 'center',
                  margin: '1rem 0',
                }}
              >
                Tecnologia {!open && <br />}{' '}
                {whiteLabel === 'codata' ? 'CodataSign' : 'DattaSign'}
              </span>
            </Stack>
          </List>
        </Grid>
      </Drawer>
      <DrawerHeader />
    </Box>
  );
};

export default Header;
