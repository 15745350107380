import { ThemeProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import useWhiteLabel from '../hooks/useWhiteLabel';

import COLORS from '../utils/colors';
import getTheme from '../themes/getTheme';

import MyFonts from '../fonts';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint ...
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `mobile` breakpoint ...
    tablet: true;
    laptop: true;
    desktop: true;
    largedesktop: true;
  }
}

const useGlobalStyles = makeStyles({
  '@global': {
    '@font-face': MyFonts,
    body: {
      backgroundColor: COLORS.background,
      height: '100vh',
      overflow: 'auto',

      '& .App': {
        display: 'flex',
        width: '100%',
        height: '100vh',
      },
    },
  },
});

const MyThemeProvider: React.FC = ({ children }) => {
  const { whiteLabel } = useWhiteLabel();

  useGlobalStyles();

  return <ThemeProvider theme={getTheme(whiteLabel)}>{children}</ThemeProvider>;
};

export default MyThemeProvider;
