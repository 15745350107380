/* eslint-disable no-nested-ternary */
import { CSSProperties, useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

import ConfirmYourDataImage from '../../components/Images/ConfirmYourDataImage';

import useDialogContext from '../../hooks/useDialogContext';

import { AuthenticationType } from '../../types/AuthenticationType';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    rowGap: '2rem',
    width: '100%',
    height: '93%',
    padding: '2rem',
    marginTop: '1rem',
  } as CSSProperties,

  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '70vh',
    rowGap: '4rem',
  } as CSSProperties,

  confirmYourDataImage: {
    height: '10rem',
  },

  field: {
    width: '60%',
  },
};

interface LocationParams {
  state: {
    name: string;
    authenticationType: AuthenticationType;
    sendTokenTo: string;
  };
}

export default function ConfirmYourData() {
  const navigate = useNavigate();
  const location = useLocation() as LocationParams;
  const { token } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { showDialog } = useDialogContext();

  useEffect(() => {
    if (!location || !location.state) {
      navigate('/');
    }
  }, []);

  if (!location || !location.state) {
    return null;
  }

  const [name, setName] = useState(location.state.name);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      await api.post('gerar-token/assinar', {
        name,
        signatureType: location.state.authenticationType,
        token,
      });

      navigate(`/sign/${token}/verification`, {
        state: {
          name,
          authenticationType: location.state.authenticationType,
          sendTokenTo: location.state.sendTokenTo,
        },
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (!error.response) {
        showDialog(
          'default',
          'Erro inesperado. Por favor, tente novamente mais tarde!',
        );
        setIsSubmitting(false);

        return;
      }

      showDialog('default', error.response.data.message);
    }

    setIsSubmitting(false);
  };

  return (
    <div style={styles.container}>
      <ConfirmYourDataImage style={styles.confirmYourDataImage} />

      <div style={styles.content}>
        <h1 style={{ fontSize: '1.4rem' }}>Confirme seus dados</h1>

        <TextField
          label="Nome completo"
          variant="standard"
          type="text"
          sx={styles.field}
          value={name}
          onChange={e => setName(e.target.value)}
        />

        <Button
          variant="contained"
          disableElevation
          sx={{
            textTransform: 'none',
            padding: 0,
            color: COLORS.white,
            width: '11rem',
            height: '2.5rem',
            backgroundColor: 'primary.main',
            '&:focus': {
              backgroundColor: 'primary.light',
            },
            '&:hover': {
              backgroundColor: 'primary.light',
              opacity: '0.9',
              transition: '0.3s',
            },
          }}
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <CircularProgress size={30} color="inherit" />
          ) : (
            'Avançar'
          )}
        </Button>
      </div>
    </div>
  );
}
