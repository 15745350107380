import { Outlet } from 'react-router-dom';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import CreditCardIcon from '@mui/icons-material/CreditCard';

import PageNextSteps from '../../../components/PageNextSteps';

export default function UserSubscriptionPlans() {
  return (
    <>
      <PageNextSteps
        steps={[
          { title: 'Planos', path: '/planos', icon: <LocalMallIcon /> },
          {
            title: 'Endereço',
            path: '/planos/endereco',
            icon: <EditLocationAltIcon />,
          },
          {
            title: 'Pagamento',
            path: '/planos/pagamento',
            icon: <CreditCardIcon />,
          },
        ]}
      />

      <Outlet />
    </>
  );
}
