import Typography from '@mui/material/Typography';

import COLORS from '../utils/colors';

type Props = {
  margin?: string;
  color?: string;
  text: string;
  align?: string;
  justify?: string;
  fontSize?: string;
};

export default function ContentTitle({
  text,
  color,
  margin,
  align,
  justify,
  fontSize
}: Props) {
  return (
    <Typography
      sx={{
        fontSize,
        fontWeight: 'bold',
        margin,
        display: 'flex',
        justifyContent: justify,
        alignItems: align,
        color: color || COLORS.mediumGray5,
      }}
    >
      {text}
    </Typography>
  );
}

ContentTitle.defaultProps = {
  color: COLORS.mediumGray5,
  margin: '1rem auto',
  align: 'center',
  justify: 'center',
  fontSize: '1.2rem',
};
