/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTheme } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from '@mui/icons-material/Edit';
import LoadingButton from '@mui/lab/LoadingButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckIcon from '@mui/icons-material/Check';

import ActionButton from '../../components/ActionButton';

import useAuthContext from '../../hooks/useAuthContext';
import useDialogContext from '../../hooks/useDialogContext';

import { Client } from '../../types/Client';

import api from '../../services/api';

import COLORS from '../../utils/colors';
import { cepMask } from '../../utils/masks';
import getAddressByCep from '../../utils/getAddressByCep';

const useStyles = makeStyles({
  dialog: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40rem',
    margin: '0 auto',
  },

  text: {
    textAlign: 'left',
    color: COLORS.black,
    fontWeight: 'bold',
  },

  dialogActions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '1rem 0 0 1rem',
    alignSelf: 'end',
    marginTop: '1rem',
    gap: '.5rem',
  },
});

interface FormValues {
  cep: string;
  estado: string;
  cidade: string;
  endereco: string;
  numero: string;
}

interface Data {
  cep: string;
  estado: string;
  cidade: string;
  endereco?: string;
  numero: number;
}

type Fields = 'cep' | 'estado' | 'cidade' | 'endereco' | 'numero';

interface Props {
  client: Client;
  setClient: (client: Client) => void;
}

const EditAddressModal: React.FC<Props> = ({ client, setClient }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesLaptop = useMediaQuery(theme.breakpoints.down('laptop'));

  const { costCenter } = useAuthContext();

  const initialState = {
    cep: client.cep ? cepMask(client.cep) : '',
    estado: client.estado || '',
    cidade: client.cidade || '',
    endereco: client.endereco || '',
    numero: client.numero,
  };

  const [userAddress, setUserAddress] = useState(initialState);

  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { showDialog } = useDialogContext();

  const {
    register,
    handleSubmit,
    unregister,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useForm<FormValues>();

  const handleClose = () => {
    setOpen(false);
    clearErrors();
  };

  const handleOpen = () => {
    setOpen(true);
    setUserAddress(initialState);
  };

  const fillAddressByCep = async (cep: string) => {
    const response = await getAddressByCep(cep);

    if (!response) {
      return;
    }

    setValue('estado', response.state);
    setValue('cidade', response.city);
    setValue('endereco', response.street);
  };

  const handleAddressChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;

    if (e.target.name === 'zipCode') {
      newValue = cepMask(newValue);
      fillAddressByCep(newValue);
    }

    setUserAddress({ ...userAddress, [e.target.name]: newValue });
  };

  const onSubmit: SubmitHandler<FormValues> = async () => {
    setIsSubmitting(true);

    const data: Data = {
      cep: userAddress.cep,
      estado: userAddress.estado,
      cidade: userAddress.cidade,
      endereco: userAddress.endereco,
      numero: userAddress.numero,
    };

    try {
      await api.post(`cliente/editar/${costCenter?.id}`, data);
      setClient({
        ...client,
        ...data,
      });

      setIsSubmitting(false);
      showDialog('success', 'Endereço alterado com sucesso!');

      handleClose();

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setIsSubmitting(false);

      if (!error.response) {
        return;
      }

      if (error.response.status === 500) {
        setError('numero', {
          message:
            'Não foi possível alterar as informações. Tente novamente mais tarde!',
        });
        return;
      }

      if (
        error.response.data.message ===
        `Usu\u00e1rio n\u00e3o possui acesso para alterar esses dados.`
      ) {
        showDialog(
          'default',
          'Você não possui permissão para alterar esses dados.',
        );
        handleClose();
        return;
      }

      const errorMessage = error.response.data.message;

      Object.keys(errorMessage).forEach(key => {
        const field = key as Fields;
        setError(field, {
          message: errorMessage[field][0],
        });
      });
    }
  };

  useEffect(() => {
    if (userAddress) {
      unregister();
    }
  }, [userAddress]);

  return (
    <>
      {client.endereco && (
        <ActionButton
          text="Editar endereço"
          type="button"
          margin="0"
          mobilewidth="12rem"
          tabletwidth="12rem"
          laptopwidth="14rem"
          desktopwidth="16rem"
          icon={<EditIcon sx={{ color: COLORS.white, fontSize: '1.3rem' }} />}
          action={handleOpen}
        />
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        className={classes.dialog}
        sx={{ width: '30rem' }}
      >
        <DialogTitle>
          {client.endereco ? 'Editar endereço' : 'Adicionar endereço'}
        </DialogTitle>

        <form
          noValidate
          id="formulario"
          method="post"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            width: '30rem',
            padding: '1.5rem',
            gap: '10px',
            ...(matchesLaptop
              ? {
                  overflow: 'auto',
                }
              : {}),
          }}
          onSubmit={e => {
            clearErrors();
            handleSubmit(onSubmit)(e);
          }}
        >
          <Stack
            sx={{
              width: '100%',
              gap: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'column',
            }}
          >
            <Typography
              className={classes.text}
              sx={{ margin: '0 auto .5rem 0', fontSize: '.925rem' }}
              variant="subtitle2"
            >
              {client.endereco
                ? 'Digite os novos valores nos campos que desejar alterar'
                : 'Digite as informações do seu endereço nos campos abaixo'}
            </Typography>

            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                gap: '2rem',
                width: '100%',
              }}
            >
              <FormControl sx={{ flex: '1' }}>
                <TextField
                  autoFocus
                  id="register_zipCode"
                  color="primary"
                  label="CEP *"
                  type="text"
                  variant="standard"
                  sx={{
                    '.MuiInput-root': {
                      backgroundColor: 'transparent !important',
                    },
                  }}
                  style={{
                    fontSize: '.9rem',
                    marginTop: '.5rem',
                    width: '100%',
                  }}
                  {...register('cep', {
                    required: 'Por favor, informe seu endereço',
                    pattern: {
                      value: /^\d{5}-?\d{3}$/,
                      message: 'Por favor, informe um CEP válido',
                    },
                  })}
                  value={userAddress.cep}
                  onChange={handleAddressChanges}
                  InputLabelProps={{ shrink: true }}
                  error={Boolean(errors.cep)}
                  helperText={errors.cep?.message}
                />
              </FormControl>

              <FormControl sx={{ flex: '1' }}>
                <TextField
                  id="register_estate"
                  color="primary"
                  label="Estado *"
                  type="text"
                  variant="standard"
                  sx={{
                    '.MuiInput-root': {
                      backgroundColor: 'transparent !important',
                    },
                  }}
                  style={{
                    fontSize: '.9rem',
                    marginTop: '.5rem',
                    width: '100%',
                  }}
                  {...register('estado', {
                    required: 'Por favor, informe seu Estado',
                    pattern: {
                      value: /[A-Za-zÀ-ÖØ-öø-ÿ]/,
                      message: 'Por favor, digite um Estado válido',
                    },
                  })}
                  value={userAddress.estado}
                  onChange={handleAddressChanges}
                  InputLabelProps={{ shrink: true }}
                  error={Boolean(errors.estado)}
                  helperText={errors.estado?.message}
                />
              </FormControl>
            </Grid>

            <FormControl sx={{ width: '100%' }}>
              <TextField
                id="register_city"
                color="primary"
                label="Cidade *"
                type="text"
                variant="standard"
                sx={{
                  '.MuiInput-root': {
                    backgroundColor: 'transparent !important',
                  },
                }}
                style={{
                  fontSize: '.9rem',
                  marginTop: '.5rem',
                  width: '100%',
                }}
                {...register('cidade', {
                  required: 'Por favor, informe sua cidade',
                  pattern: {
                    value: /[A-Za-zÀ-ÖØ-öø-ÿ]/,
                    message: 'Por favor, digite uma cidade válida',
                  },
                })}
                value={userAddress.cidade}
                onChange={handleAddressChanges}
                InputLabelProps={{ shrink: true }}
                error={Boolean(errors.cidade)}
                helperText={errors.cidade?.message}
              />
            </FormControl>

            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                gap: '2rem',
                width: '100%',
              }}
            >
              <FormControl sx={{ flex: '2' }}>
                <TextField
                  id="register_address"
                  color="primary"
                  label="Rua *"
                  type="text"
                  variant="standard"
                  sx={{
                    '.MuiInput-root': {
                      backgroundColor: 'transparent !important',
                    },
                  }}
                  style={{
                    fontSize: '.9rem',
                    marginTop: '.5rem',
                    width: '100%',
                  }}
                  {...register('endereco', {
                    required: 'Por favor, informe sua rua',
                    pattern: {
                      value: /[A-Za-zÀ-ÖØ-öø-ÿ]/,
                      message: 'Por favor, digite um endereço válido',
                    },
                  })}
                  value={userAddress.endereco}
                  onChange={handleAddressChanges}
                  InputLabelProps={{ shrink: true }}
                  error={Boolean(errors.endereco)}
                  helperText={errors.endereco?.message}
                />
              </FormControl>

              <FormControl sx={{ flex: '1' }}>
                <TextField
                  id="register_residenceNumber"
                  color="primary"
                  label="Número *"
                  type="text"
                  variant="standard"
                  sx={{
                    '.MuiInput-root': {
                      backgroundColor: 'transparent !important',
                    },
                  }}
                  style={{
                    fontSize: '.9rem',
                    marginTop: '.5rem',
                    width: '100%',
                  }}
                  {...register('numero', {
                    required: 'Por favor, informe o número da sua residência',
                    pattern: {
                      value: /^[0-9]/,
                      message: 'Digite um número válido',
                    },
                  })}
                  value={userAddress.numero}
                  onChange={handleAddressChanges}
                  InputLabelProps={{ shrink: true }}
                  error={Boolean(errors.numero)}
                  helperText={errors.numero?.message}
                />
              </FormControl>
            </Grid>

            <Box className={classes.dialogActions}>
              <Button
                onClick={handleClose}
                sx={{
                  width: '7rem',
                  color: COLORS.mediumGray3,
                  '&:hover': {
                    backgroundColor: COLORS.lightGray2,
                  },
                }}
              >
                Cancelar
              </Button>

              {isSubmitting ? (
                <LoadingButton
                  loading
                  loadingPosition="center"
                  sx={{
                    width: '7rem',
                    height: '2.25rem',
                    color: COLORS.white,
                    backgroundColor: 'primary.action',
                    '&:hover': {
                      backgroundColor: 'primary.light',
                    },
                  }}
                />
              ) : (
                <Button
                  type="submit"
                  endIcon={<CheckIcon />}
                  sx={{
                    width: '7rem',
                    color: COLORS.white,
                    backgroundColor: 'primary.action',
                    '&:hover': {
                      backgroundColor: 'primary.light',
                    },
                  }}
                >
                  {client.endereco ? 'Alterar' : 'Adicionar'}
                </Button>
              )}
            </Box>
          </Stack>
        </form>
      </Dialog>
    </>
  );
};

export default EditAddressModal;
