/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TablePagination from '@mui/material/TablePagination';
import makeStyles from '@mui/styles/makeStyles';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import RoleBasedMainBox from '../../components/RoleBasedMainBox';
import Breadcrumb from './Breadcrumb';
import ModalCreateSubFolder from './ModalCreateSubFolder';
import Folder from './Folder';
import FolderDocument from './FolderDocument';
import SearchBar from '../../components/SearchBar';

import { Subfolder } from '../../types/Document';

import useCostCenterContext from '../../hooks/useCostCenterContext';

import COLORS from '../../utils/colors';

const useStyles = makeStyles({
  filesContainer: {
    flexGrow: 1,
    backgroundColor: COLORS.white,
    border: `1px solid rgba(0, 0, 0, 0.12)`,
    borderRadius: '10px',
    width: '100%',
    height: '100%',

    padding: '1.5rem',
    minHeight: 'fit-content',
  },

  subfolderContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: '1.2rem',
    marginBottom: '1.5rem',
  },

  filesInnerContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    gap: '1.2rem',
  },

  folderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: '18rem',
    minHeight: '70px',
    maxHeight: '70px',
    height: '100%',
    padding: '1rem',
    borderRadius: '10px',
    gap: '10px',
    border: `1px solid rgba(0, 0, 0, 0.12)`,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: COLORS.background,
    },
  },

  fileContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    height: '100%',
    minWidth: '220px',
    maxWidth: '220px',
    minHeight: '240px',
    maxHeight: '240px',
    padding: '1rem',
    borderRadius: '10px',
    gap: '14px',
    border: `1px solid rgba(0, 0, 0, 0.12)`,
    cursor: 'pointer',

    '& img': {
      width: '164px',
      height: '100%',
      minHeight: '175px',
      maxHeight: '175px',
    },

    '&:hover': {
      backgroundColor: COLORS.background,
    },
  },

  subtitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },

  goBackArrow: {
    cursor: 'pointer',
  },

  pagination: {
    '& p': {
      marginBottom: '0 !important',
    },
  },
});

export default function UserSubFolderFiles() {
  const { costId } = useParams();
  const theme = useTheme();

  const {
    setSelectedFoldersIds,
    selectedDocuments,
    setSelectedDocuments,
    handleFolderSelection,
    handleDocumentSelection,
    innerDocuments,
    innerSubfolders,
    isSubfolderPage,
    setIsSubfolderPage,
    loadingDocuments,
    totalDocuments,
    rowsPerPage,
    page,
    setPage,
    getSubfolderFoldersAndFiles,
    getCostCenterFoldersAndFiles,
    goBackToPreviousFolder,
    folderArray,
    folderNavigation,
    hasMovedFolder,
    isMoving,
  } = useCostCenterContext();

  const classes = useStyles();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const [fileOptions, setFileOptions] = useState([
    ...innerSubfolders,
    ...innerDocuments,
  ]);

  useEffect(() => {
    setSelectedFoldersIds([]);
    setSelectedDocuments([]);
    setIsSubfolderPage(true);
    getCostCenterFoldersAndFiles?.(costId);
  }, []);

  useEffect(() => {
    /* load the content of the last element in the subfolders navigation array */
    if (folderArray.length) {
      getSubfolderFoldersAndFiles(folderArray[folderArray.length - 1].id);
    }
  }, [folderArray.length]);

  useEffect(() => {
    /* change document key 'documento' to 'name' as in the folder */
    const arr = innerDocuments.map(({ documento: name, ...rest }) => ({
      name,
      ...rest,
    }));
    /* create array with folders and documents for the autocomplete options */
    setFileOptions([...innerSubfolders, ...arr]);

    return () => {
      setFileOptions([]);
    };
  }, [innerSubfolders.length, innerDocuments.length]);

  useEffect(() => {
    if (hasMovedFolder) {
      const lastFolder = folderArray[folderArray.length - 1];

      if (lastFolder) {
        if (isSubfolderPage) {
          getSubfolderFoldersAndFiles?.(lastFolder.id);
        }
      }
    }
  }, [hasMovedFolder]);

  return (
    <RoleBasedMainBox gap="0" overflow="unset">
      {fileOptions && (
        <SearchBar
          options={fileOptions}
          page={page}
          rowsPerPage={rowsPerPage}
          type="myfolders"
        />
      )}

      <Box
        className={classes.subtitleContainer}
        sx={{
          [theme.breakpoints.down(1024)]: {
            flexDirection: 'column',
            alignItems: 'flex-end',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            flex: 2,
            marginBottom: '.5rem',
          }}
        >
          <Link
            underline="hover"
            color={COLORS.mediumGray5}
            href="/minhas_pastas"
            sx={{
              fontSize: '.925rem',
              fontWeight: 500,
              margin: '0 .2rem .1rem 0',
              '&:hover': {
                color: `${theme.palette.primary.main} !important`,
              },
            }}
          >
            Pastas
          </Link>
          <NavigateNextIcon
            sx={{ color: COLORS.mediumGray3, fontSize: '1.2rem' }}
          />
          {folderNavigation &&
            folderNavigation.map((folder, index) => (
              <Breadcrumb
                key={folder.id + index}
                folder={folder}
                folderIndex={index}
              />
            ))}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: '1rem',
          }}
        >
          <ModalCreateSubFolder
            folderArray={folderArray}
            isSubfolderPage={isSubfolderPage}
            getSubfolderFoldersAndFiles={getSubfolderFoldersAndFiles}
          />
          <Tooltip title="Voltar para a pasta anterior">
            <Button
              disableRipple
              onClick={goBackToPreviousFolder}
              className={classes.goBackArrow}
              sx={{
                minWidth: 'unset',
                textTransform: 'capitalize',
                '& span': {
                  marginRight: '.2rem',
                },
                '&:hover': {
                  backgroundColor: 'transparent',
                  textDecoration: 'underline',
                },
              }}
              aria-label="Voltar para a pasta principal"
              startIcon={<ArrowBackIcon />}
            >
              Voltar
            </Button>
          </Tooltip>
        </Box>
      </Box>
      {isMoving ? (
        <Box
          sx={{
            height: '100%',
            width: '100%',
            minHeight: '67vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {!loadingDocuments ? (
            <Box
              className={classes.filesContainer}
              sx={{
                '::-webkit-scrollbar': {
                  width: '10px',
                },
                '::-webkit-scrollbar-track': {
                  background: COLORS.lightGray3,
                  borderRadius: '50px',
                },
                '::-webkit-scrollbar-thumb': {
                  background: COLORS.mediumGray2,
                  borderRadius: '50px',
                },
                '::-webkit-scrollbar-thumb:hover': {
                  background: COLORS.mediumGray4,
                },
              }}
            >
              {innerSubfolders && innerSubfolders.length > 0 && (
                <>
                  <Typography
                    sx={{
                      fontSize: '.925rem',
                      fontWeight: '600',
                      margin: '0 0 1rem',
                      color: COLORS.mediumGray5,
                    }}
                  >
                    Pastas
                  </Typography>

                  <Box className={classes.subfolderContainer}>
                    {innerSubfolders &&
                      innerSubfolders.map(
                        (subfolder: Subfolder, index: number) => (
                          <Folder
                            key={subfolder.name + index}
                            tabIndex={index}
                            folderName={subfolder.name}
                            folderId={subfolder.id}
                            folderType={subfolder.type}
                            action={event => {
                              event.stopPropagation();
                              handleFolderSelection(
                                event,
                                subfolder,
                                innerSubfolders,
                              );
                            }}
                          />
                        ),
                      )}
                  </Box>
                </>
              )}

              <Typography
                sx={{
                  fontSize: '.925rem',
                  fontWeight: '600',
                  margin: '0 0 1rem',
                  color: COLORS.mediumGray5,
                }}
              >
                Arquivos
              </Typography>
              <Box className={classes.filesInnerContainer}>
                {innerDocuments && innerDocuments.length > 0 ? (
                  innerDocuments.map(document => (
                    <FolderDocument
                      key={document.id}
                      document={document}
                      selectedDocuments={selectedDocuments}
                      action={event => {
                        event.stopPropagation();
                        handleDocumentSelection(
                          event,
                          document,
                          innerDocuments,
                        );
                      }}
                    />
                  ))
                ) : (
                  <Typography
                    sx={{
                      fontSize: '.925rem',
                      fontWeight: '600',
                      color: COLORS.mediumGray5,
                      textAlign: 'center',
                      width: '100%',
                      margin: '2rem 0',
                    }}
                  >
                    Não há documentos finalizados nesta pasta.
                  </Typography>
                )}
              </Box>
            </Box>
          ) : (
            <Box className={classes.filesContainer}>
              <Box
                sx={{
                  height: '100%',
                  width: '100%',
                  minHeight: '67vh',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            </Box>
          )}

          {!loadingDocuments && innerDocuments && innerDocuments.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <div style={{ alignSelf: 'center' }}>
                <TablePagination
                  className={classes.pagination}
                  component="div"
                  count={totalDocuments}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[]}
                />
              </div>
            </Box>
          )}
        </>
      )}
    </RoleBasedMainBox>
  );
}
