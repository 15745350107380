/* eslint-disable no-nested-ternary */
import React from 'react';
import { SxProps, Theme } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import COLORS from '../../../utils/colors';

interface StyleProps {
  isSelected: boolean;
}

const styles = {
  container: ({ isSelected }: StyleProps) =>
    ({
      width: '60%',
      height: '4rem',
      borderRadius: '10px',
      margin: '.5rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      padding: '2rem',
      cursor: 'pointer',
      backgroundColor: COLORS.white,
      border: theme =>
        `2px solid ${isSelected ? theme.palette.secondary.main : COLORS.white}`,
      transition: 'all .5s ease',

      boxShadow: isSelected
        ? 'none'
        : '0px 2px 1px -1px rgb(0 0 0 / 5%), 0px 1px 1px 0px rgb(0 0 0 / 8%), 0px 1px 3px 0px rgb(0 0 0 / 8%)',

      '&:hover': {
        border: theme => `2px solid ${theme.palette.secondary.main}`,
        boxShadow: 'none',
      },
    } as SxProps<Theme>),

  text: ({ isSelected }: StyleProps) => ({
    fontWeight: isSelected ? 700 : 400,
    color: isSelected ? 'secondary.main' : COLORS.mediumGray5,
    fontSize: '1rem',
  }),
};

type Props = {
  paymentMethod: string;
  selectedPaymentMethod: string;
  action(): void;
};

const PaymentMethodCard: React.FC<Props> = ({
  children,
  selectedPaymentMethod,
  paymentMethod,
  action,
}) => {
  const isSelected = selectedPaymentMethod === paymentMethod;

  return (
    <Card elevation={0} onClick={action} sx={styles.container({ isSelected })}>
      {children}
      <Typography sx={styles.text({ isSelected })}>{paymentMethod}</Typography>
    </Card>
  );
};

export default PaymentMethodCard;
