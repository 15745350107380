/* eslint-disable react/jsx-props-no-spreading */
import { useTheme } from '@mui/material';

export default function SigneesImage(
  props: React.SVGAttributes<SVGSVGElement>,
) {
  const theme = useTheme();

  return (
    <svg
      width="1133"
      height="505"
      viewBox="0 0 1133 505"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M377.126 90.253C371.2 90.253 365.332 89.0858 359.857 86.818C354.382 84.5502 349.407 81.2262 345.217 77.0359C341.027 72.8456 337.703 67.8709 335.435 62.3959C333.167 56.921 332 51.053 332 45.127C332 39.201 333.167 33.3329 335.435 27.858C337.703 22.3831 341.027 17.4084 345.217 13.2181C349.407 9.02774 354.382 5.70377 359.857 3.43597C365.332 1.16818 371.2 0.000976386 377.126 0.000976563H787.84C793.766 0.000976386 799.634 1.16818 805.109 3.43597C810.584 5.70377 815.559 9.02774 819.749 13.2181C823.939 17.4084 827.263 22.3831 829.531 27.858C831.799 33.3329 832.966 39.201 832.966 45.127C832.966 51.053 831.799 56.921 829.531 62.3959C827.263 67.8709 823.939 72.8456 819.749 77.0359C815.559 81.2262 810.584 84.5502 805.109 86.818C799.634 89.0858 793.766 90.253 787.84 90.253H377.126Z"
        fill="#E6E6E6"
      />
      <path
        d="M377.126 5.88672C366.719 5.88672 356.738 10.0209 349.379 17.3799C342.02 24.7388 337.886 34.7196 337.886 45.1267C337.886 55.5338 342.02 65.5147 349.379 72.8736C356.738 80.2325 366.719 84.3667 377.126 84.3667H787.84C798.247 84.3667 808.228 80.2325 815.587 72.8736C822.946 65.5147 827.08 55.5338 827.08 45.1267C827.08 34.7196 822.946 24.7388 815.587 17.3799C808.228 10.0209 798.247 5.88672 787.84 5.88672H377.126Z"
        fill="white"
      />
      <path
        d="M501.714 5.88574H500.406V84.3657H501.714V5.88574Z"
        fill="#E6E6E6"
      />
      <path
        d="M664.561 5.88574H663.253V84.3657H664.561V5.88574Z"
        fill="#E6E6E6"
      />
      <path
        d="M663.253 5.88574H501.714V84.3657H663.253V5.88574Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M582.557 21.1992C577.81 21.1992 573.17 22.6068 569.223 25.2439C565.276 27.8811 562.2 31.6294 560.384 36.0148C558.567 40.4002 558.092 45.2258 559.018 49.8814C559.944 54.5369 562.23 58.8133 565.586 62.1698C568.943 65.5262 573.219 67.812 577.875 68.7381C582.53 69.6641 587.356 69.1888 591.741 67.3723C596.127 65.5558 599.875 62.4797 602.512 58.5329C605.149 54.5861 606.557 49.946 606.557 45.1992C606.557 38.834 604.028 32.7295 599.527 28.2287C595.027 23.7278 588.922 21.1992 582.557 21.1992V21.1992ZM582.557 28.3992C583.981 28.3992 585.373 28.8215 586.557 29.6126C587.741 30.4038 588.664 31.5283 589.209 32.8439C589.754 34.1595 589.896 35.6072 589.619 37.0039C589.341 38.4005 588.655 39.6834 587.648 40.6904C586.641 41.6973 585.358 42.3831 583.962 42.6609C582.565 42.9387 581.117 42.7961 579.802 42.2511C578.486 41.7062 577.361 40.7834 576.57 39.5993C575.779 38.4153 575.357 37.0232 575.357 35.5992C575.357 33.6898 576.116 31.8587 577.466 30.5087C578.816 29.1587 580.648 28.4002 582.557 28.4002V28.3992ZM582.557 63.0612C579.713 63.0482 576.914 62.3414 574.405 61.0022C571.896 59.663 569.751 57.7319 568.157 55.3762C568.272 50.5762 577.757 47.9342 582.557 47.9342C587.357 47.9342 596.842 50.5762 596.957 55.3762C595.361 57.7301 593.216 59.6599 590.707 60.9989C588.198 62.3379 585.401 63.0457 582.557 63.0612V63.0612Z"
        fill="white"
      />
      <path
        d="M419.706 21.1992C414.959 21.1992 410.319 22.6068 406.372 25.2439C402.426 27.8811 399.349 31.6294 397.533 36.0148C395.716 40.4002 395.241 45.2258 396.167 49.8814C397.093 54.5369 399.379 58.8133 402.736 62.1698C406.092 65.5262 410.368 67.812 415.024 68.7381C419.679 69.6641 424.505 69.1888 428.89 67.3723C433.276 65.5558 437.024 62.4797 439.661 58.5329C442.299 54.5861 443.706 49.946 443.706 45.1992C443.706 38.834 441.178 32.7295 436.677 28.2287C432.176 23.7278 426.071 21.1992 419.706 21.1992ZM419.706 28.3992C421.13 28.3992 422.522 28.8215 423.706 29.6126C424.89 30.4038 425.813 31.5283 426.358 32.8439C426.903 34.1595 427.046 35.6072 426.768 37.0039C426.49 38.4005 425.804 39.6834 424.797 40.6904C423.79 41.6973 422.507 42.3831 421.111 42.6609C419.714 42.9387 418.266 42.7961 416.951 42.2511C415.635 41.7062 414.511 40.7834 413.72 39.5993C412.928 38.4153 412.506 37.0232 412.506 35.5992C412.506 33.6897 413.265 31.8583 414.615 30.508C415.965 29.1578 417.797 28.3992 419.706 28.3992V28.3992ZM419.706 63.0612C416.862 63.0482 414.064 62.3414 411.554 61.0022C409.045 59.663 406.9 57.7319 405.306 55.3762C405.421 50.5762 414.906 47.9342 419.706 47.9342C424.506 47.9342 433.991 50.5762 434.106 55.3762C432.51 57.7301 430.365 59.6599 427.856 60.9989C425.347 62.3379 422.55 63.0457 419.706 63.0612V63.0612Z"
        fill="#E6E6E6"
      />
      <path
        d="M745.253 21.1992C740.506 21.1992 735.866 22.6068 731.919 25.2439C727.973 27.8811 724.896 31.6294 723.08 36.0148C721.263 40.4002 720.788 45.2258 721.714 49.8814C722.64 54.5369 724.926 58.8133 728.282 62.1698C731.639 65.5262 735.915 67.812 740.571 68.7381C745.226 69.6641 750.052 69.1888 754.437 67.3723C758.823 65.5558 762.571 62.4797 765.208 58.5329C767.845 54.5861 769.253 49.946 769.253 45.1992C769.253 38.834 766.724 32.7295 762.224 28.2287C757.723 23.7278 751.618 21.1992 745.253 21.1992ZM745.253 28.3992C746.677 28.3992 748.069 28.8215 749.253 29.6126C750.437 30.4038 751.36 31.5283 751.905 32.8439C752.45 34.1595 752.592 35.6072 752.315 37.0039C752.037 38.4005 751.351 39.6834 750.344 40.6904C749.337 41.6973 748.054 42.3831 746.658 42.6609C745.261 42.9387 743.813 42.7961 742.498 42.2511C741.182 41.7062 740.058 40.7834 739.266 39.5993C738.475 38.4153 738.053 37.0232 738.053 35.5992C738.053 33.6897 738.812 31.8583 740.162 30.508C741.512 29.1578 743.343 28.3992 745.253 28.3992V28.3992ZM745.253 63.0612C742.409 63.0482 739.61 62.3414 737.101 61.0022C734.592 59.663 732.447 57.7319 730.853 55.3762C730.968 50.5762 740.453 47.9342 745.253 47.9342C750.053 47.9342 759.538 50.5762 759.653 55.3762C758.057 57.7301 755.912 59.6599 753.403 60.9989C750.894 62.3379 748.097 63.0457 745.253 63.0612V63.0612Z"
        fill="#E6E6E6"
      />
      <path
        d="M604.557 123.143H560.557C557.243 123.143 554.557 125.829 554.557 129.143V173.143C554.557 176.456 557.243 179.143 560.557 179.143H604.557C607.871 179.143 610.557 176.456 610.557 173.143V129.143C610.557 125.829 607.871 123.143 604.557 123.143Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M931.381 503.768C1005.63 503.768 1065.82 495.709 1065.82 485.768C1065.82 475.826 1005.63 467.768 931.381 467.768C857.132 467.768 796.942 475.826 796.942 485.768C796.942 495.709 857.132 503.768 931.381 503.768Z"
        fill="#E6E6E6"
      />
      <path
        d="M767.257 122.963H723.257C719.943 122.963 717.257 125.649 717.257 128.963V172.963C717.257 176.277 719.943 178.963 723.257 178.963H767.257C770.571 178.963 773.257 176.277 773.257 172.963V128.963C773.257 125.649 770.571 122.963 767.257 122.963Z"
        fill="#E6E6E6"
      />
      <path
        d="M766.055 130.744H725.257V171.542H766.055V130.744Z"
        fill="white"
      />
      <path
        d="M579.93 159.838L572.855 150.738L576.969 147.538L580.319 151.845L591.637 139.899L595.422 143.484L579.93 159.838Z"
        fill="white"
      />
      <path
        d="M742.896 159.838L735.821 150.738L739.935 147.538L743.285 151.845L754.602 139.899L758.387 143.484L742.896 159.838Z"
        fill="#E6E6E6"
      />
      <path
        d="M441.71 122.963H397.71C394.396 122.963 391.71 125.649 391.71 128.963V172.963C391.71 176.277 394.396 178.963 397.71 178.963H441.71C445.024 178.963 447.71 176.277 447.71 172.963V128.963C447.71 125.649 445.024 122.963 441.71 122.963Z"
        fill="#E6E6E6"
      />
      <path d="M440.508 130.744H399.71V171.542H440.508V130.744Z" fill="white" />
      <path
        d="M417.349 159.838L410.274 150.738L414.388 147.538L417.738 151.845L429.055 139.899L432.84 143.484L417.349 159.838Z"
        fill="#E6E6E6"
      />
      <path
        d="M1007.92 491.873H994.738L988.47 441.036H1007.92L1007.92 491.873Z"
        fill="#FFB8B8"
      />
      <path
        d="M985.318 488.106H1010.74V504.106H969.318C969.318 499.863 971.004 495.793 974.004 492.793C977.005 489.792 981.074 488.106 985.318 488.106Z"
        fill="#2F2E41"
      />
      <path
        d="M873.268 491.873H860.088L853.82 441.036H873.272L873.268 491.873Z"
        fill="#FFB8B8"
      />
      <path
        d="M850.674 488.106H876.092V504.106H834.67C834.67 499.863 836.355 495.793 839.356 492.793C842.357 489.792 846.426 488.106 850.67 488.106H850.674Z"
        fill="#2F2E41"
      />
      <path
        d="M917.29 114.782V109.017C917.29 99.1562 921.207 89.6993 928.18 82.7267C935.152 75.7541 944.609 71.8369 954.47 71.8369V71.8369C964.331 71.8369 973.787 75.7541 980.76 82.7267C987.733 89.6993 991.65 99.1562 991.65 109.017V114.781C991.65 121.889 988.826 128.705 983.8 133.731C978.774 138.757 971.958 141.581 964.85 141.581H944.094C936.986 141.581 930.169 138.757 925.143 133.731C920.117 128.705 917.294 121.889 917.294 114.781L917.29 114.782Z"
        fill="#2F2E41"
      />
      <path
        d="M954.471 143.57C970.109 143.57 982.787 130.893 982.787 115.254C982.787 99.616 970.109 86.9385 954.471 86.9385C938.832 86.9385 926.155 99.616 926.155 115.254C926.155 130.893 938.832 143.57 954.471 143.57Z"
        fill="#FFB8B8"
      />
      <path
        d="M800.451 212.567C801.6 211.482 802.973 210.662 804.473 210.166C805.973 209.669 807.564 209.507 809.134 209.691C810.703 209.875 812.213 210.402 813.557 211.233C814.902 212.064 816.047 213.179 816.914 214.501L841.187 209.91L847.575 223.98L813.205 229.98C810.888 231.285 808.173 231.696 805.574 231.135C802.975 230.573 800.672 229.079 799.1 226.933C797.529 224.788 796.799 222.141 797.048 219.494C797.297 216.846 798.507 214.382 800.451 212.567V212.567Z"
        fill="#FFB8B8"
      />
      <path
        d="M932.395 161.401L932.712 161.882L892.912 188.103L825.748 209.55C824.857 209.836 824.093 210.422 823.586 211.208C823.079 211.993 822.86 212.931 822.967 213.86L824.432 226.48C824.497 227.038 824.678 227.577 824.963 228.062C825.248 228.546 825.632 228.966 826.089 229.293C826.546 229.621 827.066 229.85 827.617 229.964C828.167 230.079 828.736 230.078 829.286 229.96L892.498 216.411C895.557 215.759 898.451 214.485 900.998 212.669L945.708 180.854C948.162 179.105 949.832 176.463 950.358 173.496C950.884 170.528 950.224 167.473 948.52 164.987C946.817 162.501 944.205 160.784 941.248 160.203C938.291 159.623 935.224 160.227 932.708 161.885L932.395 161.401Z"
        fill="#CCCCCC"
      />
      <path
        d="M994.973 477.447C993.961 477.448 992.971 477.153 992.124 476.6C991.276 476.047 990.609 475.259 990.202 474.332L929.781 325.032C929.64 324.708 929.403 324.436 929.102 324.25C928.801 324.065 928.451 323.976 928.098 323.996C927.746 324.016 927.408 324.143 927.13 324.361C926.851 324.579 926.647 324.877 926.543 325.214L877.248 470.564C877.04 471.241 876.695 471.868 876.236 472.406C875.777 472.944 875.212 473.383 874.577 473.695C873.942 474.008 873.25 474.187 872.543 474.221C871.836 474.256 871.13 474.146 870.467 473.897L853.937 467.697C853.04 467.364 852.252 466.788 851.663 466.034C851.073 465.28 850.704 464.378 850.597 463.426C843.16 398.644 908.01 235.126 908.666 233.48L908.848 233.025L967.964 246.102L968.087 246.236C991.672 271.966 1011.06 434.248 1014.7 466.519C1014.84 467.685 1014.58 468.862 1013.95 469.857C1013.33 470.851 1012.39 471.604 1011.28 471.991L996.689 477.151C996.138 477.347 995.557 477.446 994.973 477.446V477.447Z"
        fill="#2F2E41"
      />
      <path
        d="M944.039 251.37C929.716 251.37 913.748 248.514 908.833 236.728L908.72 236.457L908.873 236.206C912.753 229.84 917.88 218.982 915.124 216.943C909.695 212.929 907.06 206.325 907.294 197.315C907.802 177.756 921.129 160.39 940.457 154.103C945.852 152.377 951.342 150.963 956.9 149.869C960.952 149.052 965.134 149.142 969.147 150.133C973.16 151.124 976.904 152.991 980.11 155.601C983.355 158.228 985.979 161.542 987.792 165.303C989.605 169.064 990.563 173.181 990.596 177.356C990.796 198.256 987.581 227.371 971.096 247.356C970.323 248.284 969.248 248.909 968.06 249.121C960.132 250.556 952.095 251.309 944.039 251.37V251.37Z"
        fill="#CCCCCC"
      />
      <path
        d="M938.711 250.583C939.49 247.787 941.342 245.412 943.863 243.973C946.384 242.534 949.371 242.149 952.175 242.9C952.747 243.06 953.305 243.267 953.844 243.518L972.274 226.745L985.092 235.38L958.735 258.704C957.544 260.85 955.671 262.535 953.412 263.494C951.153 264.452 948.639 264.627 946.269 263.992C944.888 263.614 943.596 262.967 942.467 262.086C941.339 261.206 940.396 260.111 939.693 258.864C938.99 257.617 938.541 256.243 938.373 254.822C938.204 253.401 938.319 251.96 938.711 250.583V250.583Z"
        fill="#FFB8B8"
      />
      <path
        d="M969.355 250.795C968.158 250.796 966.997 250.381 966.072 249.621L958.804 243.682C958.198 243.187 957.712 242.561 957.382 241.852C957.052 241.142 956.887 240.367 956.9 239.585C956.912 238.802 957.101 238.033 957.452 237.334C957.804 236.635 958.309 236.025 958.93 235.548L989.414 212.168C989.775 211.89 990.012 211.48 990.074 211.028C990.135 210.576 990.015 210.118 989.741 209.753L970.926 184.878C968.74 181.998 967.643 178.437 967.831 174.826C968.018 171.215 969.477 167.786 971.949 165.147C974.553 162.335 978.118 160.605 981.938 160.3C985.758 159.994 989.554 161.136 992.571 163.498L992.69 163.624L1012.34 191.757C1014.46 193.423 1016.17 195.557 1017.34 197.994C1018.5 200.43 1019.08 203.102 1019.04 205.802C1019 208.501 1018.34 211.155 1017.1 213.555C1015.87 215.956 1014.1 218.039 1011.92 219.64L972.441 249.774C971.547 250.435 970.464 250.791 969.353 250.791L969.355 250.795Z"
        fill="#CCCCCC"
      />
      <path
        d="M929.389 107.288V93.2392L954.471 82.2832L978.387 93.2352V107.284C978.387 107.587 978.327 107.887 978.211 108.167C978.095 108.446 977.925 108.701 977.711 108.915C977.497 109.129 977.243 109.299 976.963 109.415C976.683 109.531 976.383 109.59 976.081 109.59H931.695C931.392 109.59 931.092 109.531 930.812 109.415C930.532 109.299 930.278 109.129 930.064 108.915C929.85 108.701 929.68 108.446 929.564 108.167C929.448 107.887 929.389 107.587 929.389 107.284V107.288Z"
        fill="#2F2E41"
      />
      <path
        d="M975.928 86.0719C984.611 86.0719 991.65 79.033 991.65 70.3499C991.65 61.6669 984.611 54.6279 975.928 54.6279C967.245 54.6279 960.206 61.6669 960.206 70.3499C960.206 79.033 967.245 86.0719 975.928 86.0719Z"
        fill="#2F2E41"
      />
      <path
        d="M961.416 61.9161C961.415 59.5901 961.93 57.2929 962.924 55.1901C963.919 53.0874 965.367 51.2317 967.166 49.7567C968.964 48.2818 971.068 47.2245 973.325 46.6612C975.581 46.0979 977.935 46.0425 980.216 46.4991C978.203 45.5506 976.011 45.0404 973.787 45.0023C971.562 44.9642 969.354 45.399 967.31 46.2779C965.266 47.1568 963.431 48.4598 961.928 50.1008C960.426 51.7417 959.288 53.6831 958.592 55.7964C957.895 57.9098 957.656 60.147 957.889 62.3599C958.121 64.5729 958.822 66.7111 959.943 68.6332C961.064 70.5553 962.581 72.2173 964.392 73.5094C966.204 74.8014 968.269 75.694 970.452 76.1281C967.753 74.8607 965.47 72.8512 963.871 70.3345C962.271 67.8179 961.421 64.898 961.42 61.9161H961.416Z"
        fill="#2F2E41"
      />
      <path
        d="M350.793 256.127C350.444 254.451 349.731 252.872 348.705 251.501C347.679 250.13 346.365 249.001 344.856 248.193C343.346 247.385 341.678 246.917 339.968 246.824C338.259 246.73 336.549 247.013 334.961 247.651L307.638 216.256L301.835 237.09L328.67 263.71C329.92 266.308 332.079 268.357 334.738 269.469C337.398 270.581 340.373 270.679 343.1 269.745C345.827 268.81 348.116 266.907 349.534 264.398C350.952 261.888 351.4 258.945 350.793 256.127V256.127Z"
        fill="#FFB8B8"
      />
      <path
        d="M200.837 108.798C185.038 108.798 172.231 95.991 172.231 80.1926C172.231 64.3941 185.038 51.5869 200.837 51.5869C216.635 51.5869 229.442 64.3941 229.442 80.1926C229.442 95.991 216.635 108.798 200.837 108.798Z"
        fill="#FFB8B8"
      />
      <path
        d="M234.442 473.938L248.72 473.937L255.514 418.861L234.439 418.862L234.442 473.938Z"
        fill="#FFB8B8"
      />
      <path
        d="M230.801 487.779L276.841 487.778V487.195C276.841 484.842 276.377 482.512 275.477 480.338C274.576 478.163 273.256 476.188 271.592 474.524C269.928 472.86 267.953 471.54 265.778 470.639C263.604 469.739 261.274 469.275 258.921 469.275H258.919L230.8 469.277L230.801 487.779Z"
        fill="#2F2E41"
      />
      <path
        d="M191.35 489.078L205.627 489.077L207.605 435.318L191.346 434.003L191.35 489.078Z"
        fill="#FFB8B8"
      />
      <path
        d="M187.708 502.919L233.748 502.917V502.335C233.748 499.982 233.284 497.651 232.384 495.477C231.483 493.303 230.163 491.328 228.499 489.663C226.835 487.999 224.86 486.68 222.685 485.779C220.511 484.878 218.181 484.415 215.828 484.415H215.826L187.707 484.416L187.708 502.919Z"
        fill="#2F2E41"
      />
      <path
        d="M195.41 479.028L206.529 477.719C207.79 477.568 208.954 476.965 209.805 476.022C210.656 475.079 211.136 473.859 211.158 472.589L213.241 327.775C213.247 327.365 213.397 326.97 213.665 326.66C213.932 326.35 214.3 326.143 214.705 326.077C215.109 326.01 215.524 326.088 215.877 326.297C216.23 326.505 216.498 326.831 216.635 327.217L232.061 370.776L230.958 452.421C230.939 453.769 231.441 455.072 232.358 456.06C233.275 457.047 234.538 457.643 235.883 457.724L250.4 458.34C251.727 458.416 253.035 457.986 254.059 457.139C255.084 456.291 255.751 455.087 255.925 453.769L263.507 361.409L263.512 361.351L252.008 264.894L165.609 271.145L189.593 474.438C189.745 475.711 190.358 476.885 191.316 477.738C192.275 478.59 193.512 479.062 194.795 479.064C195 479.065 195.206 479.053 195.41 479.028Z"
        fill="#2F2E41"
      />
      <path
        d="M251.639 273.445C252.415 273.031 253.073 272.428 253.553 271.691C254.032 270.954 254.317 270.108 254.382 269.231C254.382 269.231 246.612 220.915 243.765 220.009L249.995 168.34C250.741 162.038 250.221 155.65 248.464 149.552C246.708 143.454 243.752 137.768 239.768 132.828C235.932 128.036 231.164 124.071 225.753 121.172C220.342 118.273 214.4 116.5 208.285 115.961C207.414 115.883 206.548 115.823 205.687 115.781C198.316 115.387 190.958 116.778 184.24 119.836C177.521 122.894 171.64 127.529 167.097 133.347C162.384 139.215 159.159 146.135 157.695 153.517C156.231 160.9 156.572 168.526 158.688 175.749L162.182 187.907V272.877C162.186 273.707 162.384 274.524 162.761 275.263C163.137 276.002 163.682 276.642 164.351 277.132C175.23 285.117 187.524 287.94 199.472 287.94C222.079 287.94 243.447 277.831 251.639 273.445Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M318.712 257.895L333.739 246.658C334.329 246.217 334.819 245.657 335.178 245.014C335.536 244.372 335.756 243.66 335.821 242.927C335.887 242.194 335.797 241.455 335.558 240.759C335.319 240.063 334.936 239.425 334.434 238.887L244.914 142.831C242.04 139.774 238.166 137.849 233.994 137.406C229.822 136.962 225.63 138.03 222.178 140.414L222.178 140.414C220.074 141.88 218.32 143.792 217.04 146.014C215.76 148.235 214.985 150.712 214.771 153.267C214.557 155.822 214.908 158.393 215.801 160.797C216.694 163.2 218.105 165.378 219.935 167.174L311.903 257.438C312.711 258.239 313.759 258.753 314.886 258.902C316.014 259.051 317.16 258.827 318.148 258.263C318.344 258.152 318.532 258.029 318.712 257.895V257.895Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M203.702 245.177C203.045 243.595 202.05 242.178 200.786 241.023C199.522 239.868 198.02 239.004 196.386 238.493C194.752 237.981 193.025 237.834 191.328 238.062C189.631 238.29 188.005 238.887 186.564 239.811L153.852 214.079L152.047 235.631L183.388 256.763C185.101 259.081 187.605 260.69 190.426 261.286C193.246 261.881 196.187 261.421 198.691 259.993C201.195 258.565 203.088 256.268 204.012 253.537C204.935 250.806 204.825 247.832 203.702 245.177V245.177Z"
        fill="#FFB8B8"
      />
      <path
        d="M174.423 255.222C175.289 254.953 176.069 254.463 176.687 253.799C177.305 253.135 177.738 252.321 177.944 251.438L181.248 237.56C181.491 236.575 181.438 235.54 181.096 234.585C180.754 233.63 180.138 232.796 179.326 232.188L157.094 208.144L189.796 157.718C192.271 154.67 193.666 150.888 193.764 146.963C193.861 143.038 192.655 139.192 190.334 136.025C188.484 133.502 186 131.512 183.133 130.258C180.266 129.004 177.119 128.53 174.01 128.884C170.901 129.238 167.941 130.408 165.43 132.274C162.919 134.141 160.945 136.638 159.709 139.512L129.307 198.69C126.284 204.57 125.53 211.355 127.189 217.755C128.847 224.154 132.802 229.72 138.299 233.392L169.369 254.146C170.32 254.998 171.552 255.469 172.829 255.47C173.37 255.47 173.907 255.386 174.423 255.222V255.222Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M179.64 105.766C180.314 106.171 181.073 106.414 181.856 106.479C182.64 106.544 183.427 106.427 184.159 106.138C184.89 105.849 185.545 105.396 186.073 104.813C186.6 104.231 186.987 103.535 187.203 102.779L187.221 102.707C187.803 100.327 187.343 97.8362 187.193 95.3905C187.043 92.9447 187.296 90.2602 188.971 88.4715C192.093 85.1372 197.606 86.9431 202.099 86.1198C204.731 85.5471 207.064 84.0348 208.662 81.8659C210.259 79.6971 211.012 77.0204 210.778 74.3368C210.717 73.8283 210.62 73.319 210.51 72.81C210.236 71.5202 210.265 70.1846 210.594 68.9079C210.924 67.6312 211.544 66.4482 212.408 65.4517C213.271 64.4553 214.354 63.6725 215.57 63.1649C216.787 62.6572 218.105 62.4386 219.421 62.526C223.955 62.8096 228.844 64.2101 232.615 60.8705C233.998 59.5527 234.962 57.8568 235.388 55.9944C235.813 54.1321 235.68 52.1857 235.006 50.3982C232.896 44.2013 226.857 40.9308 220.873 39.1186C213.472 36.9372 205.678 36.4324 198.057 37.641C190.437 38.8497 183.181 41.7412 176.818 46.1056C173.018 48.7537 169.465 52.0876 167.828 56.4204C166.191 60.7532 166.94 66.2346 170.563 69.12C167.727 72.0909 165.722 75.7551 164.747 79.7453C163.773 83.7355 163.865 87.9116 165.013 91.8552C166.779 97.6553 175.326 103.253 179.64 105.766Z"
        fill="#2F2E41"
      />
      <path
        d="M8.02279e-05 502.621C0.000928541 502.929 0.123912 503.225 0.342147 503.443C0.560383 503.662 0.856126 503.785 1.16476 503.785H444.907C445.216 503.785 445.512 503.663 445.73 503.444C445.949 503.226 446.071 502.93 446.071 502.621C446.071 502.312 445.949 502.016 445.73 501.797C445.512 501.579 445.216 501.456 444.907 501.456H1.16476C0.856126 501.457 0.560383 501.58 0.342147 501.798C0.123912 502.016 0.000928541 502.312 8.02279e-05 502.621V502.621Z"
        fill="#3F3D56"
      />
      <path
        d="M8.02279e-05 502.621C0.000928541 502.929 0.123912 503.225 0.342147 503.443C0.560383 503.662 0.856126 503.785 1.16476 503.785H444.907C445.216 503.785 445.512 503.663 445.73 503.444C445.949 503.226 446.071 502.93 446.071 502.621C446.071 502.312 445.949 502.016 445.73 501.797C445.512 501.579 445.216 501.456 444.907 501.456H1.16476C0.856126 501.457 0.560383 501.58 0.342147 501.798C0.123912 502.016 0.000928541 502.312 8.02279e-05 502.621V502.621Z"
        fill="#3F3D56"
      />
      <line
        x1="647"
        y1="503"
        x2="1133"
        y2="503"
        stroke="#3F3D56"
        strokeWidth="2"
      />
    </svg>
  );
}
