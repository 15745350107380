/* eslint-disable react/jsx-props-no-spreading */
import { useTheme } from '@mui/material';

export default function DeleteDocumentImage(
  props: React.SVGAttributes<SVGSVGElement>,
) {
  const theme = useTheme();

  return (
    <svg
      width="921"
      height="516"
      viewBox="0 0 921 516"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_943_194)">
        <path
          d="M193.679 513.126H53.0789C53.0789 513.126 50.2289 471.326 67.0909 470.851C83.9529 470.376 82.0529 489.376 103.191 463.251C124.329 437.126 149.978 438.551 153.303 453.989C156.628 469.427 146.891 481.776 164.703 477.976C182.515 474.176 208.166 484.151 193.679 513.126Z"
          fill="#E6E6E6"
        />
        <path
          d="M121.006 513.135L120.531 513.116C121.638 485.596 127.401 467.916 132.042 457.926C137.08 447.082 141.935 442.692 141.984 442.649L142.3 443.004C142.252 443.047 137.454 447.393 132.456 458.164C127.841 468.108 122.109 485.718 121.006 513.135Z"
          fill="white"
        />
        <path
          d="M171.892 513.191L171.436 513.058C174.972 501.395 181.346 490.79 189.987 482.195L190.306 482.547C181.729 491.083 175.402 501.611 171.892 513.191Z"
          fill="white"
        />
        <path
          d="M75.225 513.159L74.754 513.094C76.287 502.776 74.6651 492.236 70.101 482.856C68.23 478.922 65.7532 475.306 62.761 472.14L63.09 471.797C66.1227 475.001 68.6336 478.662 70.531 482.645C75.1362 492.11 76.7724 502.747 75.225 513.159V513.159Z"
          fill="white"
        />
        <path
          d="M212.76 513.761H37.9149L37.8749 513.168C37.7749 511.695 35.5439 476.941 46.8049 464.54C47.9221 463.229 49.3012 462.168 50.8535 461.423C52.4058 460.678 54.0969 460.266 55.8179 460.214C63.1579 460.005 67.7709 462.755 71.4919 464.961C78.5049 469.121 83.1369 471.87 99.9819 451.051C118.229 428.498 137.918 423.646 149.236 426.344C156.549 428.088 161.589 433.014 163.064 439.863C164.464 446.348 164.016 452.442 163.658 457.339C163.275 462.578 162.973 466.714 165.125 468.59C166.925 470.155 170.506 470.298 176.406 469.039C188.406 466.479 205.154 468.668 213.559 479.529C218.081 485.372 221.644 495.993 212.936 513.41L212.76 513.761ZM39.1079 512.49H211.973C218.462 499.325 218.665 488.203 212.554 480.308C204.843 470.345 188.666 467.723 176.671 470.282C170.26 471.65 166.441 471.424 164.29 469.55C161.659 467.258 161.982 462.84 162.39 457.25C162.742 452.435 163.179 446.441 161.821 440.134C160.453 433.783 155.758 429.209 148.94 427.584C137.983 424.97 118.84 429.76 100.969 451.853C83.4349 473.525 78.1519 470.393 70.8429 466.053C67.0759 463.819 62.7999 461.286 55.8529 461.483C54.3035 461.533 52.7816 461.907 51.3852 462.58C49.9888 463.253 48.7488 464.211 47.7449 465.392C37.6269 476.538 38.8609 507.791 39.1089 512.49H39.1079Z"
          fill="#2F2E41"
        />
        <path
          d="M784.06 514.466H611.844L611.811 513.501L603.588 278.321H792.315L784.06 514.466ZM613.776 512.466H782.128L790.245 280.321H605.658L613.776 512.466Z"
          fill="#3F3D56"
        />
        <path
          d="M652.925 321.896H639.826V483.993H652.925V321.896Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M704.501 321.896H691.402V483.993H704.501V321.896Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M756.077 321.896H742.978V483.993H756.077V321.896Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M901.75 347.382L900.904 346.848L686.99 211.665L705.545 182.303L706.391 182.837L920.304 318.02L901.75 347.382ZM689.749 211.042L901.126 344.622L917.544 318.643L706.168 185.062L689.749 211.042Z"
          fill="#3F3D56"
        />
        <path
          d="M850.102 200.77C843.884 196.835 836.668 194.766 829.309 194.809C821.951 194.852 814.76 197.006 808.588 201.014C802.417 205.021 797.525 210.716 794.493 217.42C791.461 224.125 790.416 231.559 791.482 238.84L801.682 245.286C799.119 239.334 798.528 232.718 799.997 226.406C801.466 220.094 804.917 214.418 809.845 210.209C814.772 206.001 820.918 203.48 827.382 203.017C833.846 202.554 840.288 204.172 845.766 207.635C851.244 211.098 855.469 216.223 857.823 222.261C860.177 228.299 860.536 234.932 858.847 241.189C857.159 247.445 853.512 252.997 848.44 257.031C843.368 261.065 837.138 263.37 830.662 263.607L840.862 270.053C847.897 267.893 854.164 263.761 858.92 258.146C863.677 252.532 866.723 245.67 867.696 238.376C868.67 231.082 867.531 223.662 864.414 216.997C861.297 210.331 856.333 204.699 850.111 200.77H850.102Z"
          fill={theme.palette.secondary.main}
        />
        <path d="M909 513.087H0V515.087H909V513.087Z" fill="#3F3D56" />
        <path
          d="M397.037 499.44H382.473L375.541 443.266H397.041L397.037 499.44Z"
          fill="#FEB8B8"
        />
        <path
          d="M354.386 512.961H400.157V495.277H371.825C367.2 495.277 362.764 497.115 359.494 500.385C356.223 503.656 354.386 508.091 354.386 512.716V512.716V512.961Z"
          fill="#2F2E41"
        />
        <path
          d="M391.918 412.394L401.964 422.939L447.416 389.212L432.59 373.649L391.918 412.394Z"
          fill="#FEB8B8"
        />
        <path
          d="M412.313 427.771L392.764 407.259L379.961 419.461L411.543 452.599L411.72 452.43C413.378 450.85 414.709 448.958 415.636 446.864C416.563 444.77 417.069 442.513 417.124 440.223C417.179 437.934 416.782 435.655 415.957 433.519C415.132 431.382 413.893 429.429 412.313 427.771V427.771Z"
          fill="#2F2E41"
        />
        <path
          d="M290.548 258.496C289.626 257.013 289.041 255.346 288.833 253.613C288.625 251.879 288.799 250.121 289.344 248.462C289.889 246.803 290.791 245.284 291.986 244.012C293.182 242.739 294.641 241.744 296.263 241.096L353.442 95.3691L375.73 108.714L312.212 248.514C313.12 251.311 312.97 254.344 311.791 257.037C310.611 259.731 308.485 261.899 305.814 263.129C303.144 264.36 300.114 264.568 297.301 263.714C294.487 262.86 292.084 261.003 290.548 258.496V258.496Z"
          fill="#FEB8B8"
        />
        <path
          d="M507.58 268.941C505.838 268.817 504.144 268.312 502.618 267.462C501.092 266.612 499.772 265.437 498.749 264.021C497.726 262.605 497.026 260.983 496.699 259.267C496.371 257.551 496.424 255.785 496.853 254.091L412.499 122.222L436.39 112.022L512.226 245.545C515.027 246.451 517.397 248.353 518.888 250.891C520.378 253.428 520.885 256.425 520.312 259.312C519.739 262.199 518.126 264.775 515.779 266.551C513.431 268.327 510.514 269.179 507.58 268.945V268.941Z"
          fill="#FEB8B8"
        />
        <path
          d="M354.005 243.904L368.936 465.817L404.618 462.669L411.964 298.947L431.904 369.261L474.932 372.409L457.901 233.409L354.005 243.904Z"
          fill="#2F2E41"
        />
        <path
          d="M438.201 358.768L431.901 369.263L387.828 399.697L419.312 416.489C419.312 416.489 480.181 382.906 474.934 372.411L438.201 358.768Z"
          fill="#2F2E41"
        />
        <path
          d="M322.759 154.121L335.18 119.121C337.943 111.333 342.224 104.171 347.774 98.0486C353.323 91.9262 360.032 86.9652 367.512 83.4528V83.4528C384.324 77.2592 402.609 76.2583 419.996 80.5798L424.516 81.7018C436.569 84.6232 447.862 90.0775 457.644 97.7018C465.298 103.736 472.184 111.376 472.797 119.594C472.8 119.611 472.805 119.628 472.812 119.645C474.932 128.937 475.981 177.212 475.981 177.212H457.281L460.239 242.279L460 241.808C460 241.808 352.144 262.219 352.144 251.724V184.556L349.933 160.236L322.759 154.121Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M423.432 71.4811C439.939 71.4811 453.321 58.0994 453.321 41.5921C453.321 25.0849 439.939 11.7031 423.432 11.7031C406.925 11.7031 393.543 25.0849 393.543 41.5921C393.543 58.0994 406.925 71.4811 423.432 71.4811Z"
          fill="#FEB8B8"
        />
        <path
          d="M427.909 28.1886L451.117 29.1186C454.037 29.1096 457.225 29.0066 459.449 27.1186C462.799 24.2696 462.238 18.8936 460.444 14.8776C455.444 3.69557 444.291 -0.310426 432.044 0.0185744C419.797 0.347574 406.964 4.49856 400.369 14.8186C393.774 25.1386 391.992 38.1706 394.476 50.1626C397.348 44.0648 401.769 38.8269 407.297 34.9718C412.826 31.1167 419.269 28.7791 425.984 28.1926L427.909 28.1886Z"
          fill="#2F2E41"
        />
        <path
          d="M565.723 320.545L481.722 261.672C480.941 261.122 480.41 260.286 480.245 259.346C480.08 258.406 480.294 257.439 480.84 256.657L546.771 162.584C547.32 161.804 548.156 161.273 549.096 161.107C550.036 160.942 551.003 161.156 551.786 161.702L635.787 220.575C636.568 221.124 637.099 221.96 637.264 222.9C637.43 223.84 637.216 224.807 636.669 225.59L570.74 319.66C570.191 320.441 569.355 320.973 568.414 321.139C567.474 321.305 566.506 321.091 565.723 320.545V320.545Z"
          fill="#BABABA"
        />
        <path
          d="M584.614 256.664L535.324 222.118C534.754 221.718 534.268 221.21 533.894 220.623C533.52 220.036 533.266 219.381 533.145 218.695C533.025 218.01 533.04 217.307 533.191 216.628C533.342 215.948 533.626 215.305 534.025 214.735C534.425 214.165 534.932 213.679 535.52 213.305C536.107 212.932 536.762 212.677 537.448 212.557C538.133 212.436 538.836 212.452 539.515 212.603C540.195 212.754 540.838 213.037 541.408 213.436L590.699 247.982C591.85 248.789 592.633 250.02 592.877 251.405C593.121 252.789 592.804 254.214 591.997 255.365C591.19 256.516 589.959 257.3 588.575 257.543C587.19 257.787 585.766 257.47 584.614 256.664V256.664Z"
          fill="white"
        />
        <path
          d="M573.302 272.804L524.011 238.258C523.441 237.859 522.954 237.351 522.58 236.764C522.205 236.177 521.95 235.521 521.83 234.835C521.709 234.149 521.724 233.446 521.875 232.767C522.026 232.087 522.309 231.443 522.709 230.873C523.109 230.302 523.617 229.816 524.204 229.443C524.792 229.069 525.448 228.814 526.134 228.694C526.82 228.574 527.523 228.59 528.202 228.741C528.882 228.893 529.526 229.177 530.096 229.577L579.386 264.122C580.536 264.93 581.318 266.161 581.561 267.545C581.804 268.928 581.487 270.352 580.681 271.503C579.875 272.653 578.644 273.437 577.261 273.681C575.877 273.925 574.453 273.609 573.302 272.804V272.804Z"
          fill="white"
        />
        <path
          d="M561.868 289.119L512.577 254.573C512.007 254.173 511.521 253.666 511.148 253.078C510.774 252.491 510.519 251.836 510.399 251.15C510.278 250.465 510.294 249.762 510.445 249.083C510.596 248.403 510.879 247.76 511.278 247.19C511.678 246.62 512.186 246.134 512.773 245.761C513.36 245.387 514.015 245.132 514.701 245.012C515.387 244.891 516.089 244.907 516.769 245.058C517.448 245.209 518.091 245.492 518.661 245.892L567.952 280.437C569.103 281.244 569.887 282.475 570.13 283.86C570.374 285.244 570.057 286.669 569.251 287.82C568.444 288.971 567.213 289.755 565.828 289.998C564.444 290.242 563.019 289.925 561.868 289.119V289.119Z"
          fill="white"
        />
        <path
          d="M584.476 224.733L565.384 211.353C564.813 210.954 564.327 210.446 563.953 209.859C563.578 209.271 563.323 208.616 563.202 207.93C563.081 207.244 563.097 206.541 563.248 205.861C563.399 205.181 563.682 204.538 564.082 203.967C564.481 203.397 564.99 202.911 565.577 202.537C566.165 202.163 566.821 201.909 567.507 201.789C568.193 201.668 568.896 201.684 569.575 201.836C570.255 201.987 570.898 202.271 571.468 202.672L590.56 216.052C591.711 216.859 592.495 218.09 592.738 219.475C592.982 220.859 592.665 222.284 591.859 223.435C591.052 224.586 589.821 225.37 588.436 225.613C587.052 225.857 585.627 225.54 584.476 224.733V224.733Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_943_194">
          <rect width="920.304" height="515.087" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
