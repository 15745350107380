/* eslint-disable no-useless-return */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import {
  TextField,
  Typography,
  FormControl,
  Card,
  InputAdornment,
  IconButton,
  Tooltip,
  useTheme,
} from '@mui/material';
import styled from '@mui/styles/styled';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import ActionButton from '../../components/ActionButton';
import LoadingBtn from '../../components/LoadingButton';

import useAuthContext from '../../hooks/useAuthContext';
import useDialogContext from '../../hooks/useDialogContext';

import { UserInfo } from '../../types/UserInfo';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const OuterCard = styled(Card)({
  width: '25vw',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2rem',
});

interface LoginValues {
  email: string;
  password: string;
}

export default function LoginCard() {
  const { loginRequest, errorMsg, setErrorMsg, login } = useAuthContext();

  const { showDialog } = useDialogContext();
  const theme = useTheme();

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [, setUserData] = useState({} as UserInfo);

  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginValues>();

  const resendEmail = async () => {
    await api.get('/email/resend');
    showDialog(
      'resendEmail',
      'Para prosseguir, precisamos apenas que você confirme o cadastro através do e-mail que acabamos de te enviar.',
    );
  };

  const renderBtn = () => {
    if (
      hasSubmitted &&
      errorMsg === 'Confirme seu e-mail para realizar o login.'
    ) {
      return (
        <ActionButton
          type="button"
          text="Reenviar e-mail"
          action={resendEmail}
        />
      );
    }
    return <ActionButton text="Entrar" type="submit" />;
  };

  const onSubmit: SubmitHandler<LoginValues> = async (data: LoginValues) => {
    setIsLoading(true);
    setHasSubmitted(true);
    const localUserData = await loginRequest(data);

    setIsLoading(false);

    if (!localUserData) {
      return;
    }

    setUserData(localUserData);

    const role = localUserData.user.roles[0].nome;

    login(localUserData, role);
  };

  const handleLoginFormChanges = () => {
    setHasSubmitted(false);
    setErrorMsg('');
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    setErrorMsg('');
  }, []);

  useEffect(() => {
    const session = JSON.parse(
      localStorage.getItem('@dattasign:session') || 'null',
    );

    if (typeof session === 'boolean' && !session) {
      showDialog(
        'default',
        'Sua sessão expirou. Por favor, faça login novamente.',
      );
      localStorage.removeItem('@dattasign:session');
    }
  }, []);

  return (
    <OuterCard
      elevation={0}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        gap: '2rem',
        alignItems: 'center',
        width: {
          mobile: '90%',
          tablet: '50%',
          laptop: '32%',
        },
        height: {
          mobile: 'calc(100% - 30vh)',
          tablet: '60vh',
          laptop: '100vh',
        },
        padding: {
          mobile: '2rem',
          desktop: '3rem',
          largedesktop: '4rem',
        },
      }}
    >
      <Typography
        style={{
          fontSize: '1.4rem',
          fontWeight: 'bold',
          margin: '0 0 .5rem 0',
          color: COLORS.gray1,
        }}
      >
        Entrar
      </Typography>
      <form
        id="formulario-login"
        noValidate
        method="post"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormControl>
          <TextField
            id="login_email"
            label="Email"
            type="email"
            variant="standard"
            style={{ fontSize: '1rem', margin: '1rem auto', width: '100%' }}
            {...register('email', {
              required: 'Por favor, informe o email',
              pattern: {
                value:
                  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                message: 'Por favor, informe um email válido',
              },
              onChange: handleLoginFormChanges,
            })}
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
          />
        </FormControl>

        <FormControl>
          <TextField
            id="login_password"
            label="Senha"
            type={showPassword ? 'text' : 'password'}
            variant="standard"
            style={{
              fontSize: '.9rem',
              margin: '0 auto 1rem auto',
              width: '100%',
            }}
            {...register('password', {
              required: 'Por favor, forneça uma senha',
              onChange: handleLoginFormChanges,
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={showPassword ? 'Esconder senha' : 'Mostrar senha'}
                  >
                    <IconButton onClick={handleClickShowPassword}>
                      {showPassword ? (
                        <VisibilityIcon sx={{ color: 'primary.main' }} />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            error={Boolean(errors.password)}
            helperText={errors.password?.message}
          />
        </FormControl>

        <Link
          to="/forgot_password"
          style={{
            fontSize: '.825rem',
            margin: '1rem 0',
            alignSelf: 'end',
            textDecoration: 'none',
            color: theme.palette.primary.main,
          }}
        >
          Esqueceu a senha?
        </Link>

        {errorMsg && (
          <div style={{ margin: '1rem auto' }}>
            <Typography
              style={{
                fontSize: '14px',
                color: COLORS.redWarning,
                fontWeight: 'bold',
              }}
            >
              {errorMsg}
            </Typography>
          </div>
        )}

        {isLoading ? <LoadingBtn text="Entrar" /> : renderBtn()}

        <Typography
          style={{
            fontSize: '.825rem',
            alignSelf: 'start',
            margin: '0 auto',
          }}
        >
          Não tem cadastro?{' '}
          <Link
            style={{
              textDecoration: 'underline',
              color: theme.palette.primary.main,
            }}
            to="/register"
          >
            Cadastre-se aqui
          </Link>
        </Typography>
      </form>
    </OuterCard>
  );
}
