import { CSSProperties } from 'react';

import logoPrevLawHorizontal from '../../assets/images/prevLaw/prevLaw-logo-horizontal.svg';

import { LogoType } from '.';

interface LogoPrevLawProps {
  type: LogoType;
}

const styles = {
  logOn: {
    width: '8rem',
    alignSelf: 'center',
    marginLeft: '1rem',
    objectFit: 'contain',
    height: '3rem',
    marginTop: '.5rem',
  } as CSSProperties,

  logOff: {
    width: '8rem',
  },
};

export default function LogoPrevLaw({ type }: LogoPrevLawProps) {
  return (
    <img
      style={styles[type]}
      src={logoPrevLawHorizontal}
      alt="Logo do DattaSign"
    />
  );
}
