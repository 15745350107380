/* eslint-disable no-nested-ternary */
import { SxProps, Theme } from '@mui/material';
import Grid from '@mui/material/Grid';

import ContentSubTitle from './ContentSubTitle';
import SoonImage from './Images/SoonImage';

const styles = {
  emptyUsersContainer: {
    paddingTop: '10%',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '3rem',
    margin: 'unset',
  } as SxProps<Theme>,
};

export default function UnderConstruction() {
  return (
    <Grid sx={styles.emptyUsersContainer}>
      <SoonImage />

      <ContentSubTitle
        text="Em breve ;)"
        fontWeight="bold"
        fontSize=".9rem"
        margin="1rem auto .3rem auto"
        alignSelf="center"
      />
    </Grid>
  );
}
