import RoleBasedMainBox from '../../components/RoleBasedMainBox';
import UnderConstruction from '../../components/UnderConstruction';

export default function AdminHistory() {
  return (
    <RoleBasedMainBox>
      <UnderConstruction />
    </RoleBasedMainBox>
  );
}
