/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/require-default-props */
import { useState, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import FolderIcon from '@mui/icons-material/Folder';

import useCostCenterContext from '../../hooks/useCostCenterContext';
import useOutsideClick from '../../hooks/useOutsideClick';

import ModalEditFolder from './ModalEditFolder';
import ModalMoveFoldersAndFiles from './ModalMoveFoldersAndFiles';
import ModalDeleteFolderOrDocument from './ModalDeleteFolderOrDocument';

import { UserCostCenter } from '../../types/CostCenter';
import { FolderArrayObject, DestinationFolder } from '../../types/Folder';

import COLORS from '../../utils/colors';
import toAbbreviateString from '../../utils/toAbbreviateString';

type Props = {
  folderName: string;
  folderId?: number | string;
  tabIndex?: number;
  folderType?: 'cost_center' | 'folder' | undefined;
  action?: (e: React.MouseEvent<HTMLDivElement>) => void;
  isSubfolderPage?: boolean | undefined;
  selectedCostCenterFolder?: number | null | undefined;
  openFolder?: (costCenterId: number) => void;
};

export default function Folder({
  folderName,
  folderId,
  folderType,
  action,
  tabIndex,
  isSubfolderPage,
  selectedCostCenterFolder,
  openFolder,
}: Props) {
  const {
    userInformation,
    selectedFoldersIds,
    selectedDocuments,
    moveFoldersAndDocuments,
    setUpdateFolder,
    folderArray,
    setSelectedFoldersIds,
    openFolderOnEnterKey,
  } = useCostCenterContext();

  /* used to disable folder action when the modal is opened */
  const [openModalMoveToFolder, setOpenModalMoveToFolder] = useState(false);

  const [currentCostCenterFolder] = useState(
    localStorage.getItem('current-cost-center') || '',
  );

  const [folder, setFolder] = useState<FolderArrayObject>({
    name: folderName,
    id: Number(folderId),
    type: folderType,
  });

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: 'folder',
    item: selectedFoldersIds,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const whereToSend: DestinationFolder = {
    name: folderName,
    id: Number(folderId),
    type: folderType,
  };

  const [{ isActive }, drop] = useDrop(
    () => ({
      accept: ['folder', 'document'],
      drop: () => {
        if (currentCostCenterFolder) {
          /* if the select folder isn't the destination folder */
          if (!!selectedFoldersIds.find(f => f === folderId) === false) {
            moveFoldersAndDocuments(
              selectedFoldersIds,
              selectedDocuments,
              whereToSend,
              JSON.parse(currentCostCenterFolder),
              isSubfolderPage,
            );

            if (isSubfolderPage) {
              setUpdateFolder(true);
            }
          }
        }
      },
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        isActive: monitor.canDrop() && monitor.isOver(),
      }),
    }),
    [selectedFoldersIds, selectedDocuments],
  );

  const initialState = () => {
    if (localStorage.getItem('current-cost-center')) {
      return JSON.parse(localStorage.getItem('current-cost-center') || '[]');
    }
    return JSON.parse(localStorage.getItem('costCenter') || '[]');
  };

  const [currentCostCenter] = useState(initialState);

  const costCenter: UserCostCenter | undefined | null =
    userInformation &&
    userInformation.centros_custo.find(
      c => c.id === Number(currentCostCenter.id),
    );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    if (folder.name !== costCenter?.nome) {
      event.stopPropagation();
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    }
    return false;
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const selectedFolder = selectedFoldersIds?.find(f => f === folder.id);
  const selectedCenter = selectedCostCenterFolder === folderId;

  const handleClickOutside = () => {
    setSelectedFoldersIds([]);
  };

  const handleClickOutsideCenter = () => {
    /* setSelectedCostCenterFolder?.(undefined); */
  };

  const ref = useOutsideClick(handleClickOutside);
  const centerRef = useOutsideClick(handleClickOutsideCenter);

  drag(drop(ref));

  useEffect(() => {
    preview(getEmptyImage(), {
      captureDraggingState: true,
    });
  }, []);

  return (
    <>
      <Tooltip
        title={folder.name.length > 26 ? folder.name : ''}
        placement="top"
        arrow
      >
        <Box
          ref={folder.type === 'folder' ? ref : centerRef}
          tabIndex={tabIndex}
          onContextMenu={handleOpenMenu}
          id="basic-button"
          role="button"
          aria-label="Abrir pasta selecionada"
          onMouseDown={action}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              if (folder.type === 'folder') {
                openFolderOnEnterKey(folder);
              } else {
                openFolder?.(folder.id);
              }
            }
          }}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            maxWidth: '18rem',
            minHeight: '60px',
            maxHeight: '60px',
            height: '100%',
            padding: '1rem',
            borderRadius: '10px',
            gap: '10px',
            cursor: 'pointer !important',
            backgroundColor: isActive ? 'primary.light' : 'transparent',
            border: theme =>
              selectedFolder || selectedCenter || isActive
                ? `2px solid ${theme.palette.primary.main}`
                : '1px solid rgba(0, 0, 0, 0.12)',
            color:
              selectedFolder || selectedCenter
                ? 'primary.main'
                : COLORS.mediumGray5,
            opacity: isDragging ? 0.2 : 1,
            zIndex: 100,
            '&:hover': {
              backgroundColor: COLORS.background,
            },
          }}
        >
          <FolderIcon
            sx={{
              color:
                selectedFolder || selectedCenter
                  ? 'primary.main'
                  : COLORS.mediumGray5,
              userSelect: 'none',
            }}
          />

          <Typography
            sx={{
              color:
                selectedFolder || selectedCenter
                  ? 'primary.main'
                  : COLORS.mediumGray5,
              userSelect: 'none',
              marginTop: '.2rem',
            }}
          >
            {toAbbreviateString(folder.name, 26)}
          </Typography>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={(e: React.MouseEvent<HTMLDivElement>) => {
              e.stopPropagation();
              e.preventDefault();
              handleCloseMenu();
            }}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <ModalEditFolder
              folderArray={folderArray}
              folder={folder}
              setFolder={setFolder}
              costcenter={costCenter}
            />

            <ModalMoveFoldersAndFiles
              folder={folder}
              setFolder={setFolder}
              openModalMoveToFolder={openModalMoveToFolder}
              setOpenModalMoveToFolder={setOpenModalMoveToFolder}
              handleCloseMenu={handleCloseMenu}
            />

            <ModalDeleteFolderOrDocument />
          </Menu>
        </Box>
      </Tooltip>
    </>
  );
}
