/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import RoleBasedMainBox from '../../components/RoleBasedMainBox';
import ContentSubTitle from '../../components/ContentSubTitle';
import Folder from './Folder';

import useAuthContext from '../../hooks/useAuthContext';
import useCostCenterContext from '../../hooks/useCostCenterContext';

import { UserCostCenter } from '../../types/CostCenter';

import COLORS from '../../utils/colors';

const useStyles = makeStyles({
  foldersContainer: {
    flexGrow: 1,
    backgroundColor: COLORS.white,
    border: `1px solid rgba(0, 0, 0, 0.12)`,
    borderRadius: '10px',
    overflow: 'auto',
    width: '100%',
    height: '100%',

    padding: '1.5rem',
    minHeight: '82vh',
    maxHeight: '82vh',
  },

  foldersInnerContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    gap: '1.2rem',
  },

  folderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: '18rem',
    minHeight: '70px',
    maxHeight: '70px',
    height: '100%',
    padding: '1rem',
    borderRadius: '10px',
    gap: '10px',
    border: `1px solid rgba(0, 0, 0, 0.12)`,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: COLORS.background,
    },
  },

  outletBox: {
    width: '40%',
    minHeight: '65vh',
    maxHeight: '77vh',
    height: '77vh',
    margin: '1rem',
    borderRadius: '10px',
    gap: '20px',
  },
});

export default function UserFolders() {
  const classes = useStyles();
  const navigate = useNavigate();

  const { isLogged } = useAuthContext();
  const { userInformation, getUserInformation } = useCostCenterContext();

  const [selectedCostCenterFolder, setSelectedCostCenterFolder] = useState<
    number | null | undefined
  >(null);

  const [, setIsFromFolderPage] = useState(
    JSON.parse(localStorage.getItem('fromFolderPage') || 'false'),
  );

  const openFolder = (costCenterId: number) => {
    /* find which cost center to set by it's id */
    const costcenter: UserCostCenter | undefined | null =
      userInformation &&
      userInformation.centros_custo.find(c => c.id === Number(costCenterId));

    /* add the name to local storage to use in the folder breadcrumbs */
    if (costcenter) {
      const data = {
        nome: costcenter?.nome,
        id: costcenter?.id,
        type: costcenter?.type,
      };
      localStorage.setItem('current-cost-center', JSON.stringify(data));
    }

    // transfer the cost center id to the UserFolderFiles component
    navigate(`/minhas_pastas/${costCenterId}`);
  };

  const handleCostCenterFolderSelection = (
    event: React.MouseEvent<HTMLDivElement>,
    costCenterId: number,
  ) => {
    switch (event.detail) {
      case 1: {
        /* single click */
        if (selectedCostCenterFolder === costCenterId) {
          setSelectedCostCenterFolder(null);
        } else {
          setSelectedCostCenterFolder(costCenterId);
        }
        break;
      }
      case 2: {
        /* double click */
        openFolder(costCenterId);
        break;
      }

      default: {
        break;
      }
    }
  };

  useEffect(() => {
    localStorage.setItem('fromFolderPage', 'false');
    setIsFromFolderPage(false);

    if (isLogged) {
      getUserInformation();
    }
  }, []);

  return (
    <RoleBasedMainBox gap="0" overflow="unset">
      <>
        <Box
          className={classes.foldersContainer}
          sx={{
            '::-webkit-scrollbar': {
              width: '10px',
            },
            '::-webkit-scrollbar-track': {
              background: COLORS.lightGray3,
              borderRadius: '50px',
            },
            '::-webkit-scrollbar-thumb': {
              background: COLORS.mediumGray2,
              borderRadius: '50px',
            },
            '::-webkit-scrollbar-thumb:hover': {
              background: COLORS.mediumGray4,
            },
          }}
        >
          <ContentSubTitle
            text="Visualize aqui os documentos finalizados arquivados em seus correspondentes centros de custo."
            fontWeight="bold"
            fontSize=".9rem"
            color={COLORS.mediumGray5}
            margin=".5rem 0 1rem 0"
            alignSelf="left"
            textAlign="left"
            justify="flex-start"
            width="100%"
          />
          <Box className={classes.foldersInnerContainer}>
            {!userInformation ? (
              <Box
                sx={{
                  height: '100%',
                  width: '100%',
                  minHeight: '20vh',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              userInformation.centros_custo.map((costCenter, index) => (
                <Folder
                  key={costCenter.id}
                  tabIndex={index}
                  folderName={costCenter.nome}
                  folderId={costCenter.id}
                  folderType={costCenter.type}
                  selectedCostCenterFolder={selectedCostCenterFolder}
                  openFolder={openFolder}
                  action={event => {
                    handleCostCenterFolderSelection(event, costCenter.id);
                  }}
                />
              ))
            )}
          </Box>
        </Box>
      </>
    </RoleBasedMainBox>
  );
}
