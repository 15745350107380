import { CSSProperties } from 'react';

import logoCrmPB from '../../assets/images/crmPB/crm_logo_landscape.jpg';

import { LogoType } from '.';

interface LogoCrmPBProps {
  type: LogoType;
}

const styles = {
  logOn: {
    width: '8rem',
    alignSelf: 'center',
    marginLeft: '1rem',
    objectFit: 'contain',
    height: '3rem',
    marginTop: '.5rem',
  } as CSSProperties,

  logOff: {
    width: '8rem',
  },
};

export default function LogoCrmPB({ type }: LogoCrmPBProps) {
  return <img style={styles[type]} src={logoCrmPB} alt="Logo do DattaSign" />;
}
