import React from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import styled from '@mui/styles/styled';

import ActionButton from '../../components/ActionButton';
import ConfirmYourDataImage from '../../components/Images/ConfirmYourDataImage';

import useWhiteLabel from '../../hooks/useWhiteLabel';

import COLORS from '../../utils/colors';

const ContentGrid = styled(Grid)({
  margin: '6rem auto',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

export default function SuccessfullRegisterMailConfirmed() {
  const navigate = useNavigate();
  const { whiteLabel } = useWhiteLabel();

  return (
    <ContentGrid sx={{ flexDirection: 'column', gap: 6, overflow: 'hidden' }}>
      <Typography
        style={{
          fontSize: '1.4rem',
          margin: '.5rem 0',
          fontWeight: 'bold',
          color: COLORS.gray1,
        }}
      >
        Cadastro confirmado!
      </Typography>

      <ConfirmYourDataImage
        style={{ width: '12rem', height: '12rem', marginTop: '1rem' }}
      />

      <Typography
        style={{
          fontSize: '1rem',
          margin: '2rem 0',
          color: COLORS.gray1,
          textAlign: 'center',
        }}
      >
        Pronto, agora você já pode acessar o{' '}
        {whiteLabel === 'codata' ? 'CodataSign' : 'DattaSign'} à vontade, quando
        quiser! :)
      </Typography>

      <ActionButton
        type="submit"
        text="Ir para login"
        action={() => navigate('/')}
      />
    </ContentGrid>
  );
}
