/* eslint-disable no-nested-ternary */
import Box from '@mui/material/Box';

import PageMainContentBox from '../../components/PageMainContentBox';
import UserHome from './UserHome';
import OperatorHome from './OperatorHome';
import AdminHome from './AdminHome';
import LoadingBackdrop from '../../components/LoadingBackdrop';

import useDocumentContext from '../../hooks/useDocumentContext';
import useAuthContext from '../../hooks/useAuthContext';

import roleBasedRender from '../../utils/roleBasedRender';

// role based render = all
export default function Home() {
  const { userInfo } = useAuthContext();
  const { loadingDocument } = useDocumentContext();

  const role = userInfo ? userInfo.user.roles[0].nome : '';

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
      }}
    >
      <PageMainContentBox title="Início" page="inicio">
        {roleBasedRender(
          role,
          <UserHome />,
          <OperatorHome />,
          <AdminHome />,
          null,
        )}
      </PageMainContentBox>

      <LoadingBackdrop open={loadingDocument} color="primary.main" />
    </Box>
  );
}
