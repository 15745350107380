/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import makeStyles from '@mui/styles/makeStyles';
import SettingsIcon from '@mui/icons-material/Settings';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import moment from 'moment';

import ActionButton from '../../../components/ActionButton';
import ContentTitle from '../../../components/ContentTitle';
import ContentSubTitle from '../../../components/ContentSubTitle';

import { CostCenter } from '../../../types/CostCenter';

import useCostCenterContext from '../../../hooks/useCostCenterContext';

import COLORS from '../../../utils/colors';

import { FileSettings } from '../../../types/FileSettings';
import { LocationDocumentContext } from '../../../types/LocationDocumentContext';

const useStyles = makeStyles({
  mainBox: {
    width: '100%',
    minHeight: '62.5vh',
    margin: '0 auto',
    gap: '10px',
    backgroundColor: COLORS.background,
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  selectCostCenter: {
    width: '100%',
    '& .MuiSelect-select': {
      display: 'flex',

      '& span': {
        display: 'none',
      },
    },
  },

  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'initial',
    columnGap: '1rem',

    '& p, & span': {
      width: '100%',
      marginBottom: '0',
    },

    '& span': {
      color: COLORS.mediumGray3,
    },
  },
});

const periodOfDays = [1, 3, 5, 7, 10, 15, 30];

interface FormValues {
  data_limite: string;
  enviar_lembrete: number;
  finalizar_processo: string;
  /* centro_custo: number; */
}

export default function UserDocumentsSettings() {
  const classes = useStyles();
  const navigate = useNavigate();

  const location = useLocation();
  const stateLocation = location.state as LocationDocumentContext;

  const { userInformation, getUserInformation } = useCostCenterContext();

  const {
    register,
    handleSubmit,
    unregister,
    formState: { errors },
  } = useForm<FormValues>();

  const [chosenCostCenter] = useState<CostCenter>(
    JSON.parse(localStorage.getItem('costCenter') || ''),
  );

  const [settings, setSettings] = useState<FileSettings>({
    data_limite: moment().add(1, 'days'),
    enviar_lembrete: 1,
    finalizar_processo: 'automatica',
    centro_custo: chosenCostCenter.id,
  });

  const [frequency, setFrequency] = useState<number[] | null>(
    JSON.parse(localStorage.getItem('settings-period') || 'null'),
  );

  const handleSettingsChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({ ...settings, [e.target.name]: e.target.value });
  };

  const handleDateTimeChange = (newDate: moment.Moment | null) => {
    const newDateInMoment = moment(newDate);

    const diffNewDateToNow = newDateInMoment.diff(moment(), 'days');
    const period = periodOfDays.filter(element => diffNewDateToNow >= element);
    const oneDayIndex = period.indexOf(1);
    if (oneDayIndex > -1) period.splice(oneDayIndex, 1);
    localStorage.setItem('settings-period', JSON.stringify(period));

    const filterNumbers = JSON.parse(
      localStorage.getItem('settings-period') || 'null',
    ).filter((el: number) => el === settings.enviar_lembrete);
    if (filterNumbers) {
      setSettings({
        ...settings,
        data_limite: newDateInMoment,
        enviar_lembrete: 1,
      });
    }
    setFrequency(period);
  };

  function getFocus(id: string) {
    const input = document.getElementById(id) || null;
    if (input) {
      input.focus();
    }
  }

  const saveLocalSettings = () => {
    localStorage.setItem('user-file-settings', JSON.stringify(settings));
  };

  const getLocalSettings = () => {
    if (localStorage.getItem('user-file-settings') === null) {
      localStorage.setItem('user-file-settings', JSON.stringify([]));
      return;
    }

    const settingsLocal = JSON.parse(
      localStorage.getItem('user-file-settings') || '[]',
    );
    setSettings(settingsLocal);
  };

  useEffect(() => {
    getFocus('datetimeSelect');
    getLocalSettings();
    getUserInformation();
  }, []);

  useEffect(() => {
    saveLocalSettings();

    if (settings) {
      unregister();
    }
  }, [settings]);

  const onSubmit: SubmitHandler<FormValues> = () => {
    saveLocalSettings();
    localStorage.removeItem('settings-period');

    navigate('/documentos/mensagem', {
      state: {
        rawFile: stateLocation?.rawFile,
      },
    });
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          marginBottom: '1rem',
        }}
      >
        <ContentTitle
          text="Configurações"
          justify="flex-start"
          margin="0"
          align="start"
        />
        <SettingsIcon sx={{ color: COLORS.mediumGray5 }} />
      </Box>

      <form
        noValidate
        id="formulario"
        method="post"
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: '100%' }}
      >
        <Box className={classes.mainBox}>
          <ContentSubTitle
            justify="flex-start"
            text="Data limite para assinaturas"
            fontWeight="bold"
            alignSelf="flex-start"
            margin="0"
            padding="1rem 0 0 1rem"
          />
          <ContentSubTitle
            justify="flex-start"
            text="Assinaturas não serão mais permitidas após essa data e hora"
            fontSize=".9rem"
            alignSelf="flex-start"
            textAlign="left"
            margin="0"
            padding="0 1rem"
          />

          <FormControl
            sx={{
              width: '94%',
              alignSelf: 'flex-start',
              margin: '.5rem auto 0 auto',
            }}
          >
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DateTimePicker
                renderInput={params => (
                  <TextField
                    id="datetimeSelect"
                    variant="standard"
                    helperText={errors.data_limite?.message}
                    error={Boolean(errors.data_limite)}
                    {...params}
                  />
                )}
                inputFormat="L LT"
                mask="__/__/____ __:__"
                hideTabs
                label="Data e Hora limites"
                value={settings.data_limite}
                onChange={handleDateTimeChange}
                minDate={moment()}
              />
            </LocalizationProvider>
          </FormControl>

          <ContentSubTitle
            justify="flex-start"
            text="Enviar lembretes"
            fontWeight="bold"
            alignSelf="flex-start"
            margin="0"
            padding="1rem 0 0 1rem"
          />
          <ContentSubTitle
            justify="flex-start"
            text="Envio automático de e-mail para signatários que ainda não assinaram"
            fontSize=".9rem"
            alignSelf="flex-start"
            textAlign="left"
            margin="0"
            padding="0 1rem"
          />

          <FormControl
            variant="standard"
            sx={{
              width: '94%',
              alignSelf: 'flex-start',
              margin: '.5rem auto',
            }}
            error={Boolean(errors.enviar_lembrete)}
          >
            <InputLabel id="reminderSelect">
              Enviar lembrete a cada...
            </InputLabel>
            <Select
              label="Enviar lembrete a cada "
              labelId="reminderSelect"
              id="reminderSelect"
              {...register('enviar_lembrete', {
                required: 'Por favor, informe a periodicidade do lembrete',
              })}
              type="text"
              defaultValue={1}
              value={settings.enviar_lembrete}
              onChange={e =>
                setSettings({
                  ...settings,
                  enviar_lembrete: Number(e.target.value),
                })
              }
              inputProps={{
                'aria-label': 'Without label',
              }}
              name="enviar_lembrete"
            >
              <MenuItem disabled value="">
                <em>Periodicidade</em>
              </MenuItem>
              <MenuItem
                value={1}
                sx={{
                  '&.Mui-selected': {
                    bgcolor: theme =>
                      `${theme.palette.primary.main} !important`,
                    color: `${COLORS.white} !important`,
                  },
                  ':hover': {
                    bgcolor: 'primary.light',
                  },
                  ':focus': {
                    bgcolor: 'primary.main',
                    color: COLORS.white,
                  },
                  ':active': {
                    bgcolor: 'primary.main',
                    color: COLORS.white,
                  },
                }}
              >
                1 dia
              </MenuItem>
              {frequency !== null &&
                frequency.map(option => (
                  <MenuItem
                    key={option}
                    value={option}
                    sx={{
                      '&.Mui-selected': {
                        bgcolor: theme =>
                          `${theme.palette.primary.main} !important`,
                        color: `${COLORS.white} !important`,
                      },
                      ':hover': {
                        bgcolor: 'primary.light',
                      },
                      ':focus': {
                        bgcolor: 'primary.main',
                        color: COLORS.white,
                      },
                      ':active': {
                        bgcolor: 'primary.main',
                        color: COLORS.white,
                      },
                    }}
                  >
                    {option} dias
                  </MenuItem>
                ))}
            </Select>
            {errors.enviar_lembrete && (
              <FormHelperText>{errors.enviar_lembrete.message}</FormHelperText>
            )}
          </FormControl>

          <ContentSubTitle
            justify="flex-start"
            text="Finalização do processo"
            fontWeight="bold"
            alignSelf="flex-start"
            margin="0"
            padding="1rem 0 0 1rem"
          />
          <ContentSubTitle
            justify="flex-start"
            text="Quando os documentos enviados são finalizados"
            fontSize=".9rem"
            alignSelf="flex-start"
            textAlign="left"
            margin="0"
            padding="0 1rem"
          />

          <FormControl
            variant="standard"
            sx={{ width: '94%', alignSelf: 'flex-start', margin: '.5rem auto' }}
          >
            <RadioGroup
              aria-labelledby="finalizar_processo"
              name="finalizar_processo"
              value={settings.finalizar_processo}
              onChange={handleSettingsChanges}
            >
              <FormControlLabel
                value="automatica"
                control={<Radio size="small" />}
                label="automaticamente, após a assinatura de todos"
              />
              <FormControlLabel
                value="manual"
                control={<Radio size="small" />}
                label="manualmente ou até a data limite"
              />
            </RadioGroup>
          </FormControl>

          {userInformation && userInformation.centros_custo.length > 1 && (
            <>
              <ContentSubTitle
                justify="flex-start"
                text="Centro de custo"
                fontWeight="bold"
                alignSelf="flex-start"
                margin="0"
                padding="1rem 0 0 1rem"
              />
              <ContentSubTitle
                justify="flex-start"
                text="Centro de custo remetente do documento"
                fontSize=".9rem"
                alignSelf="flex-start"
                textAlign="left"
                margin="0"
                padding="0 1rem"
              />

              <FormControl
                variant="standard"
                sx={{
                  width: '94%',
                  alignSelf: 'flex-start',
                  margin: '.5rem auto',
                }}
              >
                <TextField
                  margin="dense"
                  autoFocus
                  variant="standard"
                  label="Selecione o centro de custo"
                  select
                  className={classes.selectCostCenter}
                  defaultValue={0}
                  value={settings.centro_custo}
                  onChange={e =>
                    setSettings({
                      ...settings,
                      centro_custo: Number(e.target.value),
                    })
                  }
                >
                  <MenuItem disabled value="">
                    <em>Centro de custo</em>
                  </MenuItem>
                  {userInformation &&
                    userInformation.centros_custo.map(costCenter => (
                      <MenuItem
                        key={costCenter.id}
                        value={costCenter.id}
                        className={classes.menuItem}
                        sx={{
                          '&.Mui-selected': {
                            bgcolor: theme =>
                              `${theme.palette.primary.main} !important`,
                            color: `${COLORS.white} !important`,

                            '& span': {
                              color: `${COLORS.white} !important`,
                            },
                          },
                          ':hover': {
                            bgcolor: 'primary.light',
                          },
                          ':focus': {
                            bgcolor: 'primary.main',
                            color: COLORS.white,
                          },
                          ':active': {
                            bgcolor: 'primary.main',
                            color: COLORS.white,
                          },
                        }}
                      >
                        <p style={{ marginBottom: '0' }}>{costCenter.nome}</p>
                      </MenuItem>
                    ))}
                </TextField>
              </FormControl>
            </>
          )}
        </Box>

        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            gap: '8px',
            width: '95%',
            height: '8vh',
            margin: '0',
          }}
        >
          <Box
            sx={{
              width: '25rem !important',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginTop: '3rem',
            }}
          >
            <ActionButton
              color={COLORS.mediumGray2}
              hoverColor={COLORS.mediumGray4}
              type="button"
              text="Voltar"
              action={() => navigate(-1)}
            />
          </Box>

          <Box
            sx={{
              width: '25rem !important',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '3rem',
            }}
          >
            <ActionButton type="submit" text="Avançar" />
          </Box>
        </Grid>
      </form>
    </>
  );
}
