/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { DebounceInput } from 'react-debounce-input';
import { CircularProgress } from '@mui/material';

import { Document, MyDocument, Subfolder } from '../types/Document';
import { CostCenter } from '../types/CostCenter';

import useCostCenterContext from '../hooks/useCostCenterContext';
import useDialogContext from '../hooks/useDialogContext';

import api from '../services/api';

interface DisplayedFilesState {
  documents: MyDocument[];
  folders: Subfolder[];
}

interface Props {
  options: any;
  searchQuery?: string;
  setSearchQuery?: (searchQuery: string) => void;
  type?: 'myfolders' | 'documents' | 'costcenters';
  page?: number;
  rowsPerPage?: number;
  documents?: Document | null | undefined;
  setDocuments?: (documents: Document[] | null | undefined) => void;
  setCostCenters?: (costCenters: CostCenter[]) => void;
  setTotalCostCenters?: (totalCostCenters: number) => void;
  setUpdateCostCenters?: (updateCostCenters: boolean) => void;
  displayedSearchedFiles?: DisplayedFilesState;
  setDisplayedSearchedFiles?: (
    displayedSearchedFiles: DisplayedFilesState,
  ) => void;
}

const SearchBar = ({
  options,
  type,
  page,
  rowsPerPage,
  setCostCenters,
  setTotalCostCenters,
  setUpdateCostCenters,
  displayedSearchedFiles,
  setDisplayedSearchedFiles,
}: Props) => {
  const { costId } = useParams();
  const navigate = useNavigate();
  const { showDialog } = useDialogContext();

  const {
    search,
    setSearch,
    setSearchedContentFromApi,
    setHasChangedDisplayedSearchedFiles,
  } = useCostCenterContext();

  const [, setSearchParams] = useSearchParams({});
  const [existingQuery, setExistingQuery] = useState('');
  const [isLoadingContent, setIsLoadingContent] = useState(false);

  const [myOptions, setMyOptions] = useState(options);

  useEffect(() => {
    return () => {
      if (search) {
        setSearch('');
      }
    };
  }, []);

  useEffect(() => {
    setUpdateCostCenters?.(true);
    setMyOptions(options);
  }, [options]);

  useEffect(() => {
    if (type === 'costcenters' && search === '') {
      api
        .get('/cost_centers', {
          params: {
            page: page! + 1,
            limit: rowsPerPage,
          },
        })
        .then(response => {
          setCostCenters?.(response.data);

          const xTotalCount = response.headers['x-total-count'];
          setTotalCostCenters?.(Number(xTotalCount));
        })
        .catch(error => {
          console.log(error);
          if (!error.response) {
            showDialog(
              'default',
              'Erro inesperado. Por favor, tente novamente mais tarde!',
            );
            return;
          }
          showDialog('default', error.response.data.message);
        });
    }
  }, [page, search]);

  const handleSearch = async () => {
    setHasChangedDisplayedSearchedFiles(false);
    setIsLoadingContent(true);
    try {
      if (search !== '') {
        if (type === 'myfolders') {
          const response = await api.get(
            `/search_documents_folder/${costId}?search=${search}`,
          );
          setSearchedContentFromApi(response.data);
          setSearchParams({ query: search });
          setHasChangedDisplayedSearchedFiles(true);
          setIsLoadingContent(false);
          if (displayedSearchedFiles) {
            setDisplayedSearchedFiles?.({
              documents: response.data.documents,
              folders: response.data.folders,
            });
          }
          navigate(`/minhas_pastas/${costId}/search?query=${search}`, {
            state: {
              searchQueryName: search,
              searchedContentFromApi: response.data,
            },
          });
        }

        if (type === 'costcenters') {
          const response = await api.get(
            `/cost_centers?page=${page}&limit=${rowsPerPage}&search=${search}`,
          );
          setCostCenters?.(response.data);
          setSearchParams({ query: search });
          const xTotalCount = response.headers['x-total-count'];
          setTotalCostCenters?.(Number(xTotalCount));
          setHasChangedDisplayedSearchedFiles(true);
          setIsLoadingContent(false);
        }
      }
    } catch (error: any) {
      console.log(error);
      if (!error.response) {
        showDialog(
          'default',
          'Erro inesperado. Por favor, tente novamente mais tarde!',
        );
        return;
      }
      showDialog('default', error.response.data.message);
    }
  };

  useEffect(() => {
    if (search.length > 2) {
      handleSearch();
    }
  }, [search]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        margin:
          type === 'documents' || type === 'costcenters'
            ? '.5rem 0 0'
            : '0 0 1rem',
        gap: '1rem',
      }}
    >
      <DebounceInput
        minLength={0}
        debounceTimeout={300}
        element={TextField}
        size="medium"
        placeholder={
          type === 'costcenters'
            ? 'Nome ou proprietário do centro de custo'
            : type === 'documents'
            ? 'Nome ou proprietário do documento'
            : 'Nome da pasta ou do documento'
        }
        label="Pesquisar"
        fullWidth
        variant="outlined"
        value={existingQuery}
        onChange={e => {
          setSearch(e.target.value);
          setExistingQuery(e.target.value);
          if (type === 'costcenters') {
            setCostCenters?.(myOptions);
          }
        }}
        sx={{
          width: '100%',
          maxWidth: '37.2rem',
          '& .MuiInputLabel-root': {
            color: search === '' ? 'inherit' : 'primary.main',
          },
        }}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            event.defaultPrevented = true;
            handleSearch();
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ cursor: 'pointer' }}>
              <Tooltip title="Pesquisar" arrow placement="top">
                <SearchIcon
                  onClick={() => {
                    handleSearch();
                  }}
                  color={search === '' ? 'inherit' : 'primary'}
                />
              </Tooltip>
            </InputAdornment>
          ),
          endAdornment: search ? (
            <>
              {isLoadingContent ? (
                <InputAdornment
                  sx={{ marginRight: '1rem' }}
                  position="end"
                  onClick={() => {
                    setSearch('');
                    setExistingQuery('');
                  }}
                >
                  <Tooltip title="Carregando..." arrow placement="top">
                    <CircularProgress size={18} color="primary" />
                  </Tooltip>
                </InputAdornment>
              ) : null}
              <InputAdornment
                sx={{ cursor: 'pointer' }}
                position="end"
                onClick={() => {
                  setSearch('');
                  setExistingQuery('');
                }}
              >
                <Tooltip title="Limpar campo" arrow placement="top">
                  <CloseIcon />
                </Tooltip>
              </InputAdornment>
            </>
          ) : null,
        }}
      />
    </Box>
  );
};

export default SearchBar;
