/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import { useState, useRef } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import makeStyles from '@mui/styles/makeStyles';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';

import ActionButton from '../../components/ActionButton';

import useDialogContext from '../../hooks/useDialogContext';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '30rem',
    padding: '1.5rem',
    gap: '10px',
  },

  dialogActions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '1rem 0 0 1rem',
    alignSelf: 'end',
    marginTop: '1rem',
    gap: '.5rem',
  },
});

interface ChangePassword {
  current_password: string;
  password: string;
  password_confirmation: string;
}

type Fields = 'current_password' | 'password' | 'password_confirmation';

const ModalChangePassword = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const { showDialog } = useDialogContext();

  const [visibilityPassword, setVisibilityPassword] = useState(false);
  const [visibilityNewPassword, setVisibilityNewPassword] = useState(false);
  const [visibilityConfirmNewPassword, setVisibilityConfirmNewPassword] =
    useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
    clearErrors,
    setError,
  } = useForm<ChangePassword>();

  const password = useRef({});
  password.current = watch('password', '');

  const handleClose = () => {
    setOpen(false);
    setVisibilityPassword(false);
    setVisibilityNewPassword(false);
    setVisibilityConfirmNewPassword(false);
    clearErrors();
    reset();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const onSubmit: SubmitHandler<ChangePassword> = async data => {
    setIsSubmitting(true);
    try {
      await api.post('change_password', data);
      setIsSubmitting(false);
      handleClose();
      showDialog('resetPassword', 'Sua senha foi alterada! :)');

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setIsSubmitting(false);
      if (!error.response) {
        return;
      }

      const errorMessage = error.response.data.message;

      Object.keys(errorMessage).forEach(key => {
        const field = key as Fields;
        setError(field, {
          message: errorMessage[field][0],
        });
      });
    }
  };

  return (
    <>
      <ActionButton
        text="Alterar senha"
        type="button"
        margin="0"
        mobilewidth="12rem"
        tabletwidth="12rem"
        laptopwidth="14rem"
        desktopwidth="16rem"
        icon={<LockIcon sx={{ color: COLORS.white, fontSize: '1.3rem' }} />}
        action={handleOpen}
      />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Alterar senha</DialogTitle>

        <form
          noValidate
          method="post"
          className={classes.content}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Stack
            sx={{
              width: '100%',
              gap: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'column',
            }}
          >
            <FormControl sx={{ width: '100%' }}>
              <TextField
                label="Senha atual"
                color="primary"
                variant="standard"
                type={visibilityPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => setVisibilityPassword(!visibilityPassword)}
                    >
                      {visibilityPassword ? (
                        <VisibilityIcon sx={{ color: 'primary.main' }} />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  ),
                }}
                required
                {...register('current_password', {
                  required: 'Por favor, forneça sua senha atual',
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                    message:
                      'A senha deve ter ao menos 8 caracteres, dentre eles uma letra maiúscula e outra minúscula',
                  },
                })}
                error={Boolean(errors?.current_password)}
                helperText={errors?.current_password?.message}
              />
            </FormControl>
          </Stack>

          <FormControl sx={{ width: '100%' }}>
            <TextField
              label="Nova senha"
              variant="standard"
              color="primary"
              type={visibilityNewPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() =>
                      setVisibilityNewPassword(!visibilityNewPassword)
                    }
                  >
                    {visibilityNewPassword ? (
                      <VisibilityIcon sx={{ color: 'primary.main' }} />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                ),
              }}
              required
              {...register('password', {
                required: 'Por favor, forneça sua nova senha',
                pattern: {
                  value: /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                  message:
                    'A senha deve ter ao menos 8 caracteres, dentre eles uma letra maiúscula e outra minúscula',
                },
              })}
              error={Boolean(errors?.password)}
              helperText={errors?.password?.message}
            />
          </FormControl>

          <FormControl sx={{ width: '100%' }}>
            <TextField
              label="Digite a nova senha novamente"
              variant="standard"
              color="primary"
              type={visibilityConfirmNewPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() =>
                      setVisibilityConfirmNewPassword(
                        !visibilityConfirmNewPassword,
                      )
                    }
                  >
                    {visibilityConfirmNewPassword ? (
                      <VisibilityIcon sx={{ color: 'primary.main' }} />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                ),
              }}
              required
              {...register('password_confirmation', {
                required: 'Por favor, confirme sua nova senha',
                validate: value =>
                  value === password.current || 'As novas senhas não coincidem',
              })}
              error={Boolean(errors?.password_confirmation)}
              helperText={errors?.password_confirmation?.message}
            />
          </FormControl>
          <Box className={classes.dialogActions}>
            <Button
              onClick={handleClose}
              sx={{
                width: '7rem',
                color: COLORS.mediumGray3,
                '&:hover': {
                  backgroundColor: COLORS.lightGray2,
                },
              }}
            >
              Cancelar
            </Button>
            {isSubmitting ? (
              <LoadingButton
                loading
                loadingPosition="center"
                sx={{
                  width: '7rem',
                  height: '2.25rem',
                  color: COLORS.white,
                  backgroundColor: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'primary.light',
                  },
                }}
              />
            ) : (
              <Button
                type="submit"
                endIcon={<CheckIcon />}
                sx={{
                  width: '7rem',
                  color: COLORS.white,
                  backgroundColor: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'primary.light',
                  },
                }}
              >
                Alterar
              </Button>
            )}
          </Box>
        </form>
      </Dialog>
    </>
  );
};

export default ModalChangePassword;
