/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';

import DeleteDocumentImage from '../../../components/Images/DeleteDocumentImage';

import useAuthContext from '../../../hooks/useAuthContext';
import useDialogContext from '../../../hooks/useDialogContext';
import useSubscriptionContext from '../../../hooks/useSubscriptionContext';

import api from '../../../services/api';

import COLORS from '../../../utils/colors';

const useStyles = makeStyles({
  buttonDeleteDocument: {
    '&.MuiButtonBase-root': {
      color: COLORS.white,
      fontSize: '0.875rem',
      height: '2.25rem',
      width: '11rem !important',
      padding: 0,
      fontWeight: 700,
      textTransform: 'none',
      backgroundColor: COLORS.redWarning,

      '& .MuiSvgIcon-root ': {
        fontSize: '1.5rem',
      },

      '&:hover': {
        transition: '0.3s',
        backgroundColor: COLORS.lightRedWarning,
      },
    },
  },

  dialog: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
  },

  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& svg': {
      width: '12rem',
      height: '12rem',
      margin: '0 auto',
    },

    '& .MuiTypography-root': {
      margin: '1.5rem 0',
      textAlign: 'center',
      fontWeight: 'bold',
      color: COLORS.black,
      fontSize: '0.925rem',
    },
  },
});

interface Props {
  id: string;
}

const ModalDeleteDocument: React.FC<Props> = ({ id }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { costCenter } = useAuthContext();
  const { showDialog } = useDialogContext();
  const { getSubscription } = useSubscriptionContext();

  const handleClose = () => {
    setOpen(false);
    setIsDeleting(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleDeleteFile = async () => {
    setIsDeleting(true);

    try {
      await api.delete(`documento/excluir/${costCenter?.id}/${id}`);
      setIsDeleting(false);
      handleClose();
      showDialog('success', 'Documento deletado!');
      getSubscription();
      navigate('/documentos');
    } catch (error) {
      console.log(error);

      setIsDeleting(false);
      handleClose();
      showDialog('default', 'Não foi possível deletar o documento.');
    }
  };

  return (
    <>
      <Tooltip title="Excluir documento">
        <Button
          className={classes.buttonDeleteDocument}
          type="button"
          endIcon={<DeleteIcon fontSize="large" />}
          onClick={handleOpen}
        >
          Excluir
        </Button>
      </Tooltip>

      <Dialog
        open={open}
        onClose={!isDeleting ? handleClose : undefined}
        className={classes.dialog}
        sx={{ width: '32rem' }}
      >
        <DialogTitle>Atenção!</DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <DeleteDocumentImage />

          <Typography variant="subtitle2">
            Tem certeza de que deseja deletar este documento? Esta ação será
            irreversível!
          </Typography>
        </DialogContent>

        <DialogActions sx={{ padding: '0 2.3rem 2rem 2rem' }}>
          <Button
            onClick={handleClose}
            sx={{
              width: '7rem',
              color: COLORS.mediumGray3,
              '&:hover': {
                backgroundColor: COLORS.lightGray2,
              },
            }}
          >
            Cancelar
          </Button>

          {isDeleting ? (
            <LoadingButton
              loading
              loadingPosition="center"
              sx={{
                width: '7rem',
                height: '2.25rem',
                color: COLORS.white,
                backgroundColor: COLORS.redWarning,
                '&:hover': {
                  backgroundColor: COLORS.lightRed,
                },
              }}
            />
          ) : (
            <Button
              endIcon={<DeleteIcon fontSize="large" />}
              onClick={handleDeleteFile}
              sx={{
                width: '7rem',
                color: COLORS.white,
                backgroundColor: COLORS.redWarning,
                '&:hover': {
                  backgroundColor: COLORS.lightRed,
                },
              }}
            >
              Excluir
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalDeleteDocument;
