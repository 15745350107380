import Typography from '@mui/material/Typography';

import RoleBasedMainBox from '../../components/RoleBasedMainBox';

export default function OperatorHistory() {
  return (
    <RoleBasedMainBox>
      <Typography>Histórico do operador</Typography>
    </RoleBasedMainBox>
  );
}
