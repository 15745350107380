/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useSearchParams } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';

import Logo from '../../components/Logo';

import useWhiteLabel from '../../hooks/useWhiteLabel';

import api from '../../services/api';

import COLORS from '../../utils/colors';
import { Signee } from '../../types/Signee';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100vw',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2rem 1rem 1rem 1rem',
    gap: '1rem',

    [theme.breakpoints.up('laptop')]: {
      height: '100vh',
    },

    '& header': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },

    '& h1': {
      fontSize: '1.5rem',
      textAlign: 'center',
      margin: 0,
    },
  },

  buttonValidate: {
    '&.MuiButtonBase-root': {
      background: theme.palette.primary.main,
      color: COLORS.white,
      textTransform: 'none',
      width: '12rem',
      height: '100%',
      maxHeight: '2.5rem',

      '&:hover': {
        background: theme.palette.primary.light,
        transition: '0.3s',
      },

      '&.Mui-disabled': {
        background: theme.palette.primary.light,
        color: COLORS.white,
      },
    },
  },

  documentInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
    maxHeight: '100%',

    [theme.breakpoints.up('laptop')]: {
      width: '20%',
    },

    '& span': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
  },

  fileContainer: {
    height: '100%',
    width: '100%',
    borderColor: theme.palette.primary.main,
  },

  copyright: {
    textAlign: 'center',
    paddingBottom: '1rem',

    [theme.breakpoints.up('laptop')]: {
      paddingBottom: 0,
    },
  },
}));

interface ValidatorInfo {
  documentId: string;
}

interface ValidatorResponse {
  document_title: string;
  participants: Signee[];
  signed_document: string | null;
  certified_document: string | null;
}

export default function Validator() {
  const classes = useStyles();

  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [validatorResponse, setValidatorResponse] =
    useState<ValidatorResponse | null>(null);

  const [errorMessage, setErrorMessage] = useState('');

  const { whiteLabel } = useWhiteLabel();

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<ValidatorInfo>({
    defaultValues: {
      documentId: searchParams.get('documentId') || '',
    },
  });

  const onSubmit: SubmitHandler<ValidatorInfo> = async (
    data: ValidatorInfo,
  ) => {
    setIsLoading(true);
    setErrorMessage('');

    const { documentId } = data;

    searchParams.set('documentId', documentId);
    setSearchParams(searchParams);

    try {
      const response = await api.get(`validate_signature/${documentId}`);

      setValidatorResponse(response.data as ValidatorResponse);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setValidatorResponse(null);
      setErrorMessage(
        error.response
          ? error.response.data.message
          : 'Erro desconhecido. Tente novamente mais tarde',
      );
    }

    setIsLoading(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.code === 'Enter') {
      handleSubmit(onSubmit)();
    }
  };

  useEffect(() => {
    const documentId = searchParams.get('documentId');

    if (documentId) {
      handleSubmit(onSubmit)();
    }
  }, []);

  return (
    <div className={classes.container}>
      <header>
        <Logo type="logOff" />

        <h1>Valide seus documentos</h1>

        <div />
      </header>

      <Box
        display="flex"
        justifyContent="center"
        gap={theme => theme.spacing(2)}
        width="100%"
        marginTop=".75rem"
      >
        <TextField
          label="Digite o id do documento assinado"
          fullWidth
          size="small"
          {...register('documentId', { required: true })}
          onKeyDown={handleKeyDown}
          error={Boolean(errors.documentId)}
        />

        <Button
          className={classes.buttonValidate}
          onClick={handleSubmit(onSubmit)}
          disabled={!watch('documentId') || isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Validar'}
        </Button>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        gap={theme => theme.spacing(2)}
        width="100%"
        mt={1}
        sx={{
          height: {
            mobile: 'auto',
            laptop: '82%',
          },
        }}
      >
        {errorMessage && (
          <Alert severity="error" variant="filled">
            {errorMessage}
          </Alert>
        )}

        {validatorResponse && (
          <>
            <Alert severity="success" variant="filled">
              Documentos com assinaturas válidas
            </Alert>

            <Box
              display="flex"
              gap={theme => theme.spacing(2)}
              width="100%"
              sx={{
                height: {
                  mobile: '43rem',
                  laptop: '100%',
                },
                flexDirection: {
                  mobile: 'column',
                  laptop: 'row',
                },
              }}
            >
              <div className={classes.documentInfoContainer}>
                <p>
                  Título do documento:{' '}
                  <span>{validatorResponse.document_title}</span>
                </p>

                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Assinante</TableCell>
                        <TableCell align="center">Data/hora</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {validatorResponse.participants.map(participant => {
                        return (
                          <TableRow
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                            key={participant.id}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {participant.nome}
                            </TableCell>
                            <TableCell align="center">
                              {new Date(participant.updated_at).toLocaleString(
                                'pt-BR',
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>

              {validatorResponse.signed_document && (
                <iframe
                  src={`data:application/pdf;base64,${validatorResponse.signed_document}`}
                  title="_"
                  className={classes.fileContainer}
                >
                  <p>Não foi possível visualizar o arquivo</p>
                </iframe>
              )}

              {validatorResponse.certified_document && (
                <iframe
                  src={`data:application/pdf;base64,${validatorResponse.certified_document}`}
                  title="_"
                  className={classes.fileContainer}
                >
                  <p>Não foi possível visualizar o arquivo</p>
                </iframe>
              )}
            </Box>
          </>
        )}
      </Box>

      <p className={classes.copyright}>
        © {new Date().getFullYear()}{' '}
        {whiteLabel === 'codata' ? 'CodataSign' : 'DattaSign'}. Todos os
        direitos reservados.
      </p>
    </div>
  );
}
