import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import COLORS from '../utils/colors';

type Props = {
  title: string;
  link: string;
};

export default function GoBackPageArrow({ title, link }: Props) {
  const theme = useTheme();

  return (
    <Tooltip title={title}>
      <Box
        sx={{
          width: '3rem',
          height: '3rem',
          borderRadius: '50px',
          backgroundColor: COLORS.white,
          position: 'absolute',
          left: '2rem',
          top: '2rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '1px 1px 1px lightgrey',
          [theme.breakpoints.down(400)]: {
            left: '1rem',
            top: '1rem',
          },

          a: {
            color: theme.palette.primary.main,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
      >
        {link === '/landing' ? (
          <a href="https://dattasign.com.br">
            <ArrowBackIcon />
          </a>
        ) : (
          <Link to={link}>
            <ArrowBackIcon />
          </Link>
        )}
      </Box>
    </Tooltip>
  );
}
