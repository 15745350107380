import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import COLORS from '../utils/colors';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '0.75rem',
  },

  divProgress: {
    display: 'grid',
    borderRadius: '0.5rem',

    height: '0.875rem',
    width: '100%',
    gridTemplateColumns: (props: { total: number }) =>
      `repeat(${props.total}, 1fr)`,
    gap: props => (props.total > 15 ? '' : '2px'),
    padding: 0,
  },

  text: {
    fontSize: '0.75rem',
    color: COLORS.gray2,
  },
});

interface Props {
  totalComplete: number;
  total: number;
}

const CustomProgress: React.FC<Props> = ({ totalComplete, total }) => {
  const classes = useStyles({ total });
  const indexes = Array.from({ length: total }, (v, k) => k);

  const getBorderRadiusOfDiv = (currentIndex: number) => {
    if (total === 1) {
      return '.5rem';
    }

    if (currentIndex === 0) {
      return '.5rem 0 0 .5rem';
    }

    if (currentIndex === total - 1) {
      return '0 .5rem .5rem 0';
    }

    return '';
  };

  return (
    <div className={classes.container}>
      <div className={classes.divProgress}>
        {indexes.map(index => {
          const borderRadius = getBorderRadiusOfDiv(index);

          return (
            <div
              key={index}
              style={{
                borderRadius,
                height: '100%',
                width: '100%',
                backgroundColor:
                  index < totalComplete ? COLORS.lightGreen : COLORS.lightGray2,
              }}
            />
          );
        })}
      </div>
      <span className={classes.text}>
        {totalComplete}/{total}
      </span>
    </div>
  );
};

export default CustomProgress;
