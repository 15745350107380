const simplifyName = (name: string) => {
  if (!name) {
    return '';
  }

  const formattedName = name.split(/(\s).+\s/).join('');
  localStorage.setItem('formattedName', formattedName);

  return formattedName;
};

const getUserInitials = (name: string) => {
  const simplifiedName = simplifyName(name);

  if (!simplifiedName) {
    return '';
  }

  const fullName = simplifiedName.toUpperCase().split(' ');

  const firstLetter = fullName[0][0];

  const lastName = fullName.slice(-1);
  const lastLetter = lastName[0][0];

  return fullName.length <= 1
    ? `${firstLetter}`
    : `${firstLetter}${lastLetter}`;
};

export { simplifyName, getUserInitials };
