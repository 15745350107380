/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { Theme, useTheme } from '@mui/material';

import CustomVerificationCodeInput from '../../components/CustomVerificationCodeInput';
import LoadingBackdrop from '../../components/LoadingBackdrop';

import useDialogContext from '../../hooks/useDialogContext';

import api from '../../services/api';

import COLORS from '../../utils/colors';
import { phoneMask } from '../../utils/masks';

import { AuthenticationType } from '../../types/AuthenticationType';
import { SignDataLoggedOff } from './Sign';
import TokenImage from '../../components/Images/TokenImage';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    rowGap: '2rem',
    width: '100%',
    padding: '2rem',
  },

  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '60vh',
    rowGap: '2rem',
  },

  tokenImage: {
    height: '10rem',
  },

  description: {
    color: COLORS.gray1,
    fontSize: '1rem',
    lineHeight: '2rem',
    textAlign: 'justify',
  },

  bottom: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },

  resendCodeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    color: COLORS.mediumGray4,
  },

  buttonResendCode: {
    color: `${theme.palette.primary.main} !important`,
    padding: 0,

    '&:hover': {
      opacity: 0.9,
      backgroundColor: '#FFFFFF !important',
    },
  },

  buttonFinish: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    textTransform: 'none',
    padding: 0,
    color: COLORS.white,
    width: '7rem',
    height: '2.25rem',

    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      opacity: '0.9',
      transition: '0.3s',
    },
  },
}));

type OutletContext = {
  signData: SignDataLoggedOff | null;
  setSignData: React.Dispatch<React.SetStateAction<SignDataLoggedOff | null>>;
  buttonSignIsDisabled: boolean;
};

interface LocationParams {
  state: {
    sendTokenTo: string;
    name: string;
    authenticationType: AuthenticationType;
  };
}

export default function VerificationCode() {
  const navigate = useNavigate();

  const theme = useTheme();

  const classes = useStyles();

  const location = useLocation() as LocationParams;
  const { token } = useParams();
  const { showDialog } = useDialogContext();

  const [isResendingVerificationCode, setIsResendingVerificationCode] =
    useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [buttonFinishIsDisabled, setButtonFinishIsDisabled] = useState(true);
  const [verificationCode, setVerificationCode] = useState('');

  const [signeeEmail, setSigneeEmail] = useState('');

  const { setSignData } = useOutletContext<OutletContext>();

  const getSigneeEmail = async () => {
    try {
      const response = await api.get(`documento/visualiza/${token}`);
      setSigneeEmail(response.data.signee.email);
    } catch (error: any) {
      console.log(error);
    }
  };

  const hasPhone =
    location.state.authenticationType === 'sms' ||
    location.state.authenticationType === 'whatsapp';

  const sendTokenToFormatted = hasPhone
    ? phoneMask(location.state.sendTokenTo)
    : signeeEmail;

  useEffect(() => {
    if (!location || !location.state) {
      navigate('/');
    }

    getSigneeEmail();
  }, []);

  useEffect(() => {
    if (!location || !location.state) {
      navigate('/');
    }

    getSigneeEmail();
  }, []);

  if (!location || !location.state) {
    return null;
  }

  const handleChange = (newValue: string) => {
    if (newValue.length < 6 && !buttonFinishIsDisabled) {
      setButtonFinishIsDisabled(true);
    }
    if (newValue.length === 6) {
      setButtonFinishIsDisabled(false);
    }
    setVerificationCode(newValue.toUpperCase());
  };

  const resendVerificationCode = async () => {
    setIsResendingVerificationCode(true);

    try {
      await api.post('gerar-token/assinar', {
        name: location.state.name,
        signatureType: location.state.authenticationType,
        token,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (!error.response) {
        showDialog(
          'default',
          'Erro inesperado. Por favor, tente novamente mais tarde!',
        );

        return;
      }

      showDialog('default', error.response.data.message);
    }

    setIsResendingVerificationCode(false);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await api.post('token/assinar', {
        signatureType: location.state.authenticationType,
        token,
        signatureToken: verificationCode,
        name: location.state.name,
      });

      setSignData(signData => {
        return {
          ...signData,
          verified_token: true,
        } as SignDataLoggedOff;
      });

      navigate(
        location.state.authenticationType === 'assinatura_digital'
          ? `/sign/${token}`
          : `/sign/${token}/success`,
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (!error.response) {
        showDialog(
          'default',
          'Erro inesperado. Por favor, tente novamente mais tarde!',
        );
        setIsSubmitting(false);

        return;
      }

      showDialog('default', error.response.data.message);
    }
    setIsSubmitting(false);
  };

  return (
    <div className={classes.container}>
      <TokenImage className={classes.tokenImage} />

      <div className={classes.content}>
        <h1 style={{ fontSize: '1.25rem' }}>Autentique sua assinatura</h1>
        <p className={classes.description}>
          Proteger sua assinatura é nossa maior prioridade. Por favor, confirme
          sua conta digitando o código de autorização enviado para{' '}
          <span style={{ color: theme.palette.primary.main }}>
            {sendTokenToFormatted}
          </span>
        </p>

        <CustomVerificationCodeInput onChange={handleChange} />

        <div className={classes.bottom}>
          <section className={classes.resendCodeContainer}>
            <span style={{ fontSize: '.925rem' }}>
              Pode levar um minuto para receber seu código.
            </span>
            <span style={{ fontSize: '.925rem' }}>
              Não recebeu?{' '}
              <Button
                className={classes.buttonResendCode}
                sx={{
                  textTransform: 'none',
                  fontSize: '.925rem',
                  padding: '0 !important',
                }}
                onClick={resendVerificationCode}
              >
                Reenvie um novo código.
              </Button>
            </span>
          </section>

          <Button
            variant="contained"
            disableElevation
            className={classes.buttonFinish}
            onClick={handleSubmit}
            disabled={buttonFinishIsDisabled}
          >
            {isSubmitting ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              'Finalizar'
            )}
          </Button>
        </div>
      </div>

      <LoadingBackdrop
        color="primary.main"
        open={isResendingVerificationCode}
      />
    </div>
  );
}
