import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import styled from '@mui/styles/styled';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import MailSentImage from '../../components/Images/MailSentImage';

import COLORS from '../../utils/colors';

const OuterGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  overflow: 'auto !important',
});

const ContentGrid = styled(Grid)({
  margin: '6rem auto',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '50vw',
});

export default function SuccessfullRegister() {
  const theme = useTheme();

  return (
    <OuterGrid
      sx={{
        overflow: 'auto !important',
        width: '100%',
        height: { desktop: '100vh' },
      }}
    >
      <ContentGrid sx={{ flexDirection: 'column', gap: 6, overflow: 'hidden' }}>
        <Typography
          style={{
            fontSize: '1.4rem',
            margin: '.5rem 0',
            fontWeight: 'bold',
            color: COLORS.gray1,
          }}
        >
          Cadastro efetuado com sucesso!
        </Typography>

        <MailSentImage
          style={{ width: '8rem', height: '8rem', marginTop: '1rem' }}
        />

        <Typography
          style={{ fontSize: '1rem', margin: '2rem 0', color: COLORS.gray1 }}
        >
          Para prosseguir, precisamos apenas que você confirme o cadastro
          através do e-mail que acabamos de te enviar.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            gap: 3 /*  marginLeft: '5rem' */,
          }}
        >
          <Link
            to="/register"
            style={{
              textDecoration: 'underline',
              color: theme.palette.primary.main,
            }}
          >
            Não recebi o e-mail
          </Link>

          <Link
            to="/register"
            style={{
              textDecoration: 'underline',
              color: theme.palette.primary.main,
            }}
          >
            Estou com problemas
          </Link>
        </Box>

        <Link
          to="/"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 4,
            textDecoration: 'underline',
            color: theme.palette.primary.main,
            marginTop: '3rem',
          }}
        >
          <ArrowBackIcon
            sx={{
              textDecoration: 'underline',
              color: theme.palette.primary.main,
              fontSize: '20px',
            }}
          />
          Voltar à página principal
        </Link>
      </ContentGrid>
    </OuterGrid>
  );
}
