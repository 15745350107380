/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import styled from '@mui/styles/styled';
import AddIcon from '@mui/icons-material/Add';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckIcon from '@mui/icons-material/Check';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import moment from 'moment';
import { useDropzone } from 'react-dropzone';

import RoleBasedMainBox from '../../components/RoleBasedMainBox';
import ActionButton from '../../components/ActionButton';
import DropzoneIndicator from '../Documents/Dropzone/DropzoneIndicator';
import InfoPanel from '../../components/InfoPanel';
import ContentTitle from '../../components/ContentTitle';
import ConfirmDeleteDocumentModal from '../Documents/ConfirmDeleteDocumentModal';
import ItsSoEmptyHere from '../Documents/ItsSoEmptyHere';
import ItsSoQuietHere from '../History/ItsSoQuietHere';

import useDocumentContext from '../../hooks/useDocumentContext';
import useDialogContext from '../../hooks/useDialogContext';
import useAuthContext from '../../hooks/useAuthContext';

import { Document } from '../../types/Document';
import { LastActivity } from '../../types/LastActivity';

import api from '../../services/api';

import COLORS from '../../utils/colors';

import { ReactComponent as MySignIcon } from '../../assets/images/sign.svg';

const useStyles = makeStyles((theme: Theme) => ({
  gridActive: {
    display: 'flex',
    width: '100%',
    height: '70vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.light,
    borderRadius: '10px',
  },

  gridInactive: {
    display: 'flex',
    width: '100%',
    rowGap: '1.75rem',
  },

  panelsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0',
    width: '100%',
    gap: '1.5rem',

    '& .MuiSvgIcon-root': {
      color: COLORS.white,
      fontSize: '2rem',
    },
  },

  actionsPendingDocuments: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    columnGap: '0.75rem',
  },

  buttonSign: {
    '&.MuiButtonBase-root': {
      textTransform: 'none',
      color: COLORS.white,
      fontSize: '0.875rem',
      height: '2rem',
      width: '7rem',
      borderRadius: '.625rem',
      padding: 0,
      fontWeight: 500,
    },
  },

  buttonDelete: {
    '&.MuiButtonBase-root': {
      backgroundColor: COLORS.red,
      color: COLORS.white,
      borderRadius: '.625rem',
      height: '2rem',
      width: '2.25rem',

      '& svg': {
        fontSize: '1.25rem',
      },

      '&:hover': {
        backgroundColor: COLORS.lightRed,
        transition: '0.3s',
      },
    },
  },

  link: {
    marginTop: '1rem',

    '& a': {
      color: theme.palette.primary.main,
      fontSize: '1.125rem',
      fontWeight: 500,

      '&:hover': {
        transition: '0.3s',
        color: theme.palette.primary.light,
      },
    },
  },

  headerTable: {
    '& th': {
      border: 0,
      color: COLORS.black,
      fontSize: '1.175rem',
    },
  },

  row: {
    '& td': {
      fontSize: '1.025rem',
      borderBottom: `1px solid ${COLORS.lightGray5}`,
      color: COLORS.mediumGray5,
      fontWeight: 500,
    },
  },

  containerProgress: {
    width: '100%',
    height: '55vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const CustomBox = styled(Box)({
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flexDirection: 'column',
  backgroundColor: COLORS.white,
  border: `1px solid ${COLORS.pureBlack}1e`,
  borderRadius: '0.625rem',
  padding: '1.25rem',
  width: '100%',
});

interface Panel {
  toSign: number;
  waitingSignees: number;
  signed: number;
}

export default function UserHome() {
  const classes = useStyles();
  const navigate = useNavigate();

  const { costCenter } = useAuthContext();

  const { setCurrentFile, handleFileInput, setHasUploaded, clearDocumentInfo } =
    useDocumentContext();

  const { showDialog } = useDialogContext();

  const [panels, setPanels] = useState({} as Panel);
  const [pendingDocuments, setPendingDocuments] = useState<Document[] | null>(
    null,
  );

  const [lastActivities, setLastActivities] = useState<LastActivity[] | null>(
    null,
  );

  const [isLoading, setIsLoading] = useState(false);

  const openFileDialog = () => {
    const fileUploadDom = document.getElementById('homeFileInput');
    fileUploadDom?.click();
  };

  const { getRootProps, isDragActive } = useDropzone({
    accept: '.pdf',
    maxFiles: 1,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        fileRejections[0].errors.forEach(err => {
          switch (err.message) {
            case 'File type must be .pdf':
              showDialog('mustBePdf', 'O arquivo deve estar no formato pdf');
              break;

            case 'Too many files':
              showDialog('manyFiles', 'Envie apenas um arquivo por vez (pdf)');
              break;

            default:
              showDialog('default', err.message);
              break;
          }
        });
        return;
      }

      setCurrentFile(acceptedFiles[0]);
      setHasUploaded(true);
      handleFileInput(acceptedFiles[0]);
    },
  });

  useEffect(() => {
    clearDocumentInfo();
  }, []);

  const loadData = async () => {
    setIsLoading(true);

    try {
      const response = await api.get(`inicio/cliente/${costCenter?.id}`);

      setPanels({
        toSign: response.data.falta_assinar,
        waitingSignees: response.data.esperando_signatarios,
        signed: response.data.assinados,
      });
      setPendingDocuments(response.data.documentos_pendentes as Document[]);
      setLastActivities(response.data.atividades as LastActivity[]);
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [costCenter]);

  return (
    <RoleBasedMainBox>
      <ActionButton
        mobilewidth="14rem"
        tabletwidth="14rem"
        laptopwidth="16rem"
        desktopwidth="18rem"
        largedesktopwidth="20rem"
        type="button"
        text="Adicionar documento"
        margin="0 0 .5rem 0"
        self="flex-end"
        action={openFileDialog}
        icon={<AddIcon fontSize="medium" />}
      />
      <input
        id="homeFileInput"
        name="file"
        type="file"
        multiple
        style={{ display: 'none' }}
        accept=".pdf"
        onChange={e =>
          handleFileInput(e.target.files ? e.target.files[0] : null)
        }
      />

      <Grid
        {...getRootProps({ onClick: event => event.stopPropagation() })}
        className={isDragActive ? classes.gridActive : classes.gridInactive}
        direction="column"
        container
      >
        {isDragActive ? (
          <DropzoneIndicator />
        ) : !isLoading ? (
          <>
            <div className={classes.panelsContainer}>
              <InfoPanel
                number={panels.toSign}
                text="A assinar"
                color={COLORS.red2}
                link={{
                  to: '/documentos',
                  state: {
                    valueTab: 1,
                  },
                }}
              >
                <MySignIcon />
              </InfoPanel>

              <InfoPanel
                number={panels.waitingSignees}
                text="Esperando signatários"
                color={COLORS.blue}
                link={{
                  to: '/documentos',
                  state: {
                    valueTab: 2,
                  },
                }}
              >
                <AccessTimeIcon />
              </InfoPanel>

              <InfoPanel
                number={panels.signed}
                text="Assinados"
                color={COLORS.green3}
                link={{
                  to: '/documentos',
                  state: {
                    valueTab: 3,
                  },
                }}
              >
                <CheckIcon />
              </InfoPanel>
            </div>

            <CustomBox>
              <ContentTitle text="Documentos Pendentes" margin="0 0 1rem 0" />
              {pendingDocuments && pendingDocuments.length !== 0 ? (
                <>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        {pendingDocuments &&
                          pendingDocuments.map(document => (
                            <TableRow key={document.id} className={classes.row}>
                              <TableCell>{document.nome}</TableCell>

                              <TableCell>
                                <div
                                  className={classes.actionsPendingDocuments}
                                >
                                  <Button
                                    endIcon={<FileOpenOutlinedIcon />}
                                    sx={{
                                      backgroundColor: 'primary.main',
                                      '&:hover': {
                                        backgroundColor: 'primary.light',
                                      },
                                    }}
                                    className={classes.buttonSign}
                                    onClick={() => {
                                      navigate(`/documentos/${document.id}`);
                                    }}
                                  >
                                    Visualizar
                                  </Button>

                                  <ConfirmDeleteDocumentModal
                                    documents={pendingDocuments}
                                    document={document}
                                    updateDocuments={loadData}
                                  />
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <div className={classes.link}>
                    <Link to="/documentos">Ver mais</Link>
                  </div>
                </>
              ) : (
                <ItsSoEmptyHere
                  direction="row"
                  width="70%"
                  height="unset"
                  margin="0 auto"
                  gap="3rem"
                />
              )}
            </CustomBox>

            <CustomBox sx={{ marginBottom: '1.5rem' }}>
              <ContentTitle text="Últimas atividades" margin="0 0 1rem 0" />
              {lastActivities && lastActivities.length !== 0 ? (
                <>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow className={classes.headerTable}>
                          <TableCell>Horário</TableCell>
                          <TableCell>Atividade</TableCell>
                          <TableCell>Nome do documento</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {lastActivities &&
                          lastActivities.map(lastActivity => (
                            <TableRow
                              key={lastActivity.id}
                              className={classes.row}
                            >
                              <TableCell>
                                {moment(lastActivity.created_at).format('LLLL')}
                              </TableCell>
                              <TableCell>{lastActivity.descricao}</TableCell>
                              <TableCell>
                                {lastActivity.documento.nome}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className={classes.link}>
                    <Link to="/historico">Ver mais</Link>
                  </div>
                </>
              ) : (
                <ItsSoQuietHere direction="row" height="unset" gap="10rem" />
              )}
            </CustomBox>
          </>
        ) : (
          <Grid className={classes.containerProgress}>
            <CircularProgress color="primary" />
          </Grid>
        )}
      </Grid>
    </RoleBasedMainBox>
  );
}
