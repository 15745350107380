import { CSSProperties, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import CancelImage from '../../components/Images/CancelImage';

import COLORS from '../../utils/colors';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    rowGap: '2rem',
    width: '100%',
    height: '93%',
    padding: '2rem',
  } as CSSProperties,

  imageFailed: {
    width: '18rem',
    marginBottom: '1rem',
  } as CSSProperties,

  description: {
    color: COLORS.mediumGray3,
    fontSize: '1.25rem',
    lineHeight: '2rem',
    maxWidth: '40%',
    textAlign: 'center',
  } as CSSProperties,
};

interface LocationParams {
  state: {
    title: string;
    description: string;
  };
}

export default function Error() {
  const navigate = useNavigate();
  const location = useLocation() as LocationParams;

  useEffect(() => {
    if (!location || !location.state) {
      navigate('/');
    }
  }, []);

  if (!location || !location.state) {
    return null;
  }

  const { title, description } = location.state;

  return (
    <div style={styles.container}>
      <CancelImage style={styles.imageFailed} />

      <h1 style={{ fontSize: '1.5rem' }}>{title}</h1>

      <p style={styles.description}>{description}</p>
    </div>
  );
}
