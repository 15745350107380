/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SxProps, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import styled from '@mui/styles/styled';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import moment from 'moment';

import PeopleIcon from '@mui/icons-material/People';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CreditScoreIcon from '@mui/icons-material/CreditScore';

import RoleBasedMainBox from '../../components/RoleBasedMainBox';
import InfoPanel from '../../components/InfoPanel';
import ContentTitle from '../../components/ContentTitle';
import ItsSoEmptyHere from '../Documents/ItsSoEmptyHere';
import ModalAddFreePlanInCostCenter from '../CostCenters/ModalAddFreePlanInCostCenter';

import useAuthContext from '../../hooks/useAuthContext';
import useCostCenterContext from '../../hooks/useCostCenterContext';

import COLORS from '../../utils/colors';

const useStyles = makeStyles((theme: Theme) => ({
  panelsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0',
    width: '100%',
    gap: '1.5rem',

    '& .MuiSvgIcon-root': {
      color: COLORS.white,
      fontSize: '2rem',
    },
  },

  noDataLine: {
    maxWidth: '6rem',
  },

  actionsTableCellContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: '1.2rem',
  },

  buttonDelete: {
    '&.MuiButtonBase-root': {
      backgroundColor: COLORS.red,
      color: COLORS.white,
      borderRadius: '.625rem',
      height: '2rem',
      width: '2.25rem',

      '& svg': {
        fontSize: '1.25rem',
      },

      '&:hover': {
        backgroundColor: COLORS.lightRed,
        transition: '0.3s',
      },
    },
  },

  link: {
    marginTop: '1rem',

    '& a': {
      color: theme.palette.primary.main,
      fontSize: '1.125rem',
      fontWeight: 500,

      '&:hover': {
        transition: '0.3s',
        color: theme.palette.primary.light,
      },
    },
  },

  headerTable: {
    '& th': {
      border: 0,
      color: COLORS.black,
      fontSize: '1.175rem',
    },
  },

  row: {
    '& td': {
      fontSize: '1.025rem',
      borderBottom: `1px solid ${COLORS.lightGray5}`,
      color: COLORS.mediumGray5,
      fontWeight: 500,
    },
  },

  containerProgress: {
    width: '100%',
    height: '55vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  loadingContainer: {
    width: '100%',
    height: '65vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const CustomBox = styled(Box)({
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flexDirection: 'column',
  backgroundColor: COLORS.white,
  border: `1px solid ${COLORS.pureBlack}1e`,
  borderRadius: '0.625rem',
  padding: '1.25rem',
  width: '100%',
});

const styles = {
  buttonViewDetails: {
    textTransform: 'none',
    background: 'none',
    textDecoration: 'underline',
    color: 'primary.main',
    fontSize: {
      mobile: '1rem',
      tablet: '.95rem',
      laptop: '.95rem',
      desktop: '1rem',
      largedesktop: '1rem',
    },
    height: '2rem',
    borderRadius: '.625rem',
    padding: 0,
    textAlign: 'left',
    fontWeight: 500,

    '&:hover': {
      background: 'none',
      transition: '0.3s',
      opacity: '0.6',
      textDecoration: 'underline',
    },
  } as SxProps<Theme>,
};

interface Panel {
  buyedPlans: number | undefined;
  courtesyPlans: number | undefined;
  registeredClients: number | undefined;
}

export default function AdminHome() {
  const { totalCostCenters, adminCostCenters, getAdminCostCenters } =
    useCostCenterContext();
  const { isLogged } = useAuthContext();

  const classes = useStyles();

  const [panels, setPanels] = useState({
    buyedPlans: 0,
    courtesyPlans: 0,
    registeredClients: 0,
  } as Panel);

  const [page] = useState(0);
  const rowsPerPage = 3;
  const [isLoading, setIsLoading] = useState(false);
  const [updateCostCenters, setUpdateCostCenters] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    if (isLogged) {
      getAdminCostCenters(page, rowsPerPage);
      setIsLoading(false);
    }
  }, [page, updateCostCenters]);

  useEffect(() => {
    setPanels({
      ...panels,
      buyedPlans: 3,
      courtesyPlans: 5,
      registeredClients: totalCostCenters,
    });
  }, [adminCostCenters]);

  return (
    <RoleBasedMainBox>
      <div className={classes.panelsContainer}>
        <InfoPanel
          number={panels.buyedPlans ? panels.buyedPlans : 0}
          text="Planos contratados"
          color={COLORS.yellow}
          link={{
            to: '/documentos',
            state: {
              valueTab: 1,
            },
          }}
        >
          <CreditScoreIcon />
        </InfoPanel>

        <InfoPanel
          number={panels.courtesyPlans ? panels.courtesyPlans : 0}
          text="Planos cortesia"
          color={COLORS.blue}
          link={{
            to: '/documentos',
            state: {
              valueTab: 3,
            },
          }}
        >
          <ThumbUpIcon />
        </InfoPanel>
        <InfoPanel
          number={panels.registeredClients ? panels.registeredClients : 0}
          text="Centros de custo cadastrados"
          color={COLORS.green3}
          link={{
            to: '/centros-de-custo',
            state: {
              valueTab: 2,
            },
          }}
        >
          <PeopleIcon />
        </InfoPanel>
      </div>
      <CustomBox>
        <ContentTitle
          text="Últimos centros de custo cadastrados"
          margin="0 0 1rem 0"
        />
        {!isLoading ? (
          adminCostCenters && adminCostCenters.length !== 0 ? (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow className={classes.headerTable}>
                      <TableCell>Nome</TableCell>
                      <TableCell>Proprietário</TableCell>
                      <TableCell>Data de criação</TableCell>
                      <TableCell>Plano</TableCell>
                      <TableCell>Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {adminCostCenters &&
                      adminCostCenters.map(costcenter => (
                        <TableRow key={costcenter.id} className={classes.row}>
                          <TableCell>{costcenter.nome}</TableCell>

                          <TableCell>{costcenter.proprietario.name}</TableCell>

                          <TableCell>
                            {`${moment(costcenter.created_at).format(
                              'L',
                            )} às ${moment(costcenter.created_at).format(
                              'LT',
                            )}`}
                          </TableCell>

                          <TableCell>
                            {costcenter.assinatura ? (
                              costcenter.assinatura.plano.nome
                            ) : (
                              <hr className={classes.noDataLine} />
                            )}
                          </TableCell>

                          <TableCell>
                            <div className={classes.actionsTableCellContainer}>
                              <Button
                                sx={styles.buttonViewDetails}
                                onClick={() => {
                                  console.log(`/cost-center/${costcenter.id}`);
                                }}
                              >
                                Ver detalhes
                              </Button>
                              {!costcenter.assinatura ||
                              costcenter.assinatura.status !== 'active' ||
                              (costcenter.assinatura.plano.nome ===
                                'Cortesia' &&
                                costcenter.assinatura.status === 'active') ? (
                                <ModalAddFreePlanInCostCenter
                                  id={costcenter.id}
                                  setUpdateCostCenters={setUpdateCostCenters}
                                />
                              ) : null}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <div className={classes.link}>
                <Link to="/centros-de-custo">Ver mais</Link>
              </div>
            </>
          ) : (
            <ItsSoEmptyHere
              direction="row"
              width="70%"
              height="unset"
              margin="0 auto"
              gap="3rem"
            />
          )
        ) : (
          <Grid className={classes.loadingContainer}>
            <CircularProgress color="warning" />
          </Grid>
        )}
      </CustomBox>
    </RoleBasedMainBox>
  );
}
