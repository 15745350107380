/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/no-explicit-any */
import CanvasToSvg from 'canvas-to-svg';

import { Coordinates } from '../types/Coordinates';
import { DigitalSignatureType } from '../types/DigitalSignatureType';

import COLORS from './colors';

import initialsImg from '../assets/images/initials.svg';
import signatureImg from '../assets/images/sign.svg';

// Credits: https://stackoverflow.com/a/20683311
const observer = new MutationObserver(mutations => {
  mutations.forEach(mutationRecord => {
    const element = mutationRecord.target as HTMLElement;

    if (element.style.display === 'block') {
      element.style.display = 'flex';
    }
  });
});

const getPageCoordinates = (webviewInstance: any, x: number, y: number) => {
  const { documentViewer } = webviewInstance.Core;

  const displayMode = documentViewer.getDisplayModeManager().getDisplayMode();
  const pageNumber = documentViewer.getCurrentPage();
  const pageCoordinates = displayMode.windowToPage({ x, y }, pageNumber);

  return pageCoordinates as Coordinates;
};

const getPageCenter = (webviewInstance: any) => {
  const { documentViewer } = webviewInstance.Core;
  const viewerElement = documentViewer.getScrollViewElement();

  const top = viewerElement.scrollTop + viewerElement.offsetTop;
  const bottom = top + viewerElement.offsetHeight;
  const left = viewerElement.scrollLeft + viewerElement.offsetLeft;
  const right = left + viewerElement.offsetWidth;

  const windowCoordinateCenter = {
    x: (left + right) / 2,
    y: (top + bottom) / 2,
  };

  return getPageCoordinates(
    webviewInstance,
    windowCoordinateCenter.x,
    windowCoordinateCenter.y,
  );
};

const drawStampAnnotation = (
  webviewInstance: any,
  type: DigitalSignatureType | 'certificate',
  color: string,
  email: string,
  x: number,
  y: number,
  page: number,
) => {
  const { annotationManager, Annotations } = webviewInstance.Core;

  const symbol = type === 'initials' ? '⤓' : type === 'signature' ? '✍️' : '🔒';
  const fullText = `${email} ${symbol}`;
  const width = email.length * 11;
  const height = 40;

  const stampAnnot = new Annotations.StampAnnotation();
  stampAnnot.PageNumber = page;
  stampAnnot.Width = width;
  stampAnnot.Height = height;
  stampAnnot.X = x - stampAnnot.Width / 2;
  stampAnnot.Y = y - stampAnnot.Height / 2;
  stampAnnot.disableRotationControl();

  stampAnnot.setCustomData('email', email);
  stampAnnot.setCustomData('type', type);

  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;

  const context = canvas.getContext('2d');

  if (!context) {
    return;
  }

  context.fillStyle = COLORS.white;
  context.fillRect(0, 0, canvas.width, canvas.height);

  const method = type === 'initials' ? 'stroke' : 'fill';
  context[`${method}Style`] = color;
  context[`${method}Rect`](0, 0, canvas.width, canvas.height);

  context.textBaseline = 'middle';
  context.font = `1rem 'Noto Sans', sans-serif`;
  context.textAlign = 'center';
  context.fillStyle = type === 'initials' ? color : COLORS.white;
  context.fillText(fullText, canvas.width / 2, canvas.height / 2);

  stampAnnot.setImageData(canvas.toDataURL());
  annotationManager.addAnnotation(stampAnnot);
  annotationManager.redrawAnnotation(stampAnnot);
};

const changeStampAnnotationsToSignatureWidget = async (
  webviewInstance: any,
) => {
  const { Annotations, annotationManager } = webviewInstance.Core;

  const stampAnnotations = annotationManager
    .getAnnotationsList()
    .filter((annot: any) => annot instanceof Annotations.StampAnnotation);

  stampAnnotations.forEach((annot: any) => {
    const id = Math.random().toString().substring(2);
    const field = new Annotations.Forms.Field(id, {
      type: 'Sig',
    });
    const widgetAnnot = new Annotations.SignatureWidgetAnnotation(field);

    widgetAnnot.setCustomData('type', annot.getCustomData('type'));
    widgetAnnot.setCustomData('email', annot.getCustomData('email'));

    widgetAnnot.PageNumber = annot.PageNumber;
    widgetAnnot.X = annot.X;
    widgetAnnot.Y = annot.Y;
    widgetAnnot.Width = annot.Width;
    widgetAnnot.Height = annot.Height;

    annotationManager.addAnnotation(widgetAnnot);
    annotationManager.getFieldManager().addField(field);
  });

  annotationManager.deleteAnnotations(stampAnnotations);
};

const deleteAnnotationsFromEmail = (webviewInstance: any, email: string) => {
  const { annotationManager } = webviewInstance.Core;

  const annotationsToDelete = annotationManager
    .getAnnotationsList()
    .filter((annot: any) => email === annot.getCustomData('email'));

  annotationManager.deleteAnnotations(annotationsToDelete);
};

const createSignatureWidgetAnnotationHTML = (type: DigitalSignatureType, widgetColor: string) => {
  const div = document.createElement('div');
  div.innerHTML = `${type === 'initials' ? 'Rubrique' : 'Assine'} aqui`;

  div.style.cssText = `
    width: 100%;
    height: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    column-gap: 7%;
    font-size: 165%;
    font-weight: 700;
    border-radius: .25rem;
    background: ${widgetColor};
    color: ${COLORS.white};
    cursor: pointer;
  `;

  const img = document.createElement('img');
  img.src = type === 'initials' ? initialsImg : signatureImg;
  img.alt = type === 'initials' ? 'rubrica' : 'assinatura';
  img.style.cssText = `width: 15%; height: 90%;`;

  div.appendChild(img);

  observer.observe(div, { attributes: true, attributeFilter: ['style'] });

  return div;
};

const distinguishSignatureWidgets = (webviewInstance: any, widgetColor: string) => {
  const { Annotations } = webviewInstance.Core;

  const { createSignHereElement } =
    Annotations.SignatureWidgetAnnotation.prototype;

  Annotations.SignatureWidgetAnnotation.prototype.createSignHereElement =
    function () {
      const signHereElement = createSignHereElement.apply(this, arguments);
      const type = this.getCustomData('type') as DigitalSignatureType;

      if (type !== 'initials' && type !== 'signature') {
        return signHereElement;
      }

      return createSignatureWidgetAnnotationHTML(type, widgetColor);
    };
};

const hideAnnotationsFromOtherUsers = (
  webviewInstance: any,
  annotations: any[],
  email: string,
) => {
  const annotationsToHide = annotations.filter(
    (annot: any) => email !== annot.getCustomData('email'),
  );

  webviewInstance.Core.annotationManager.hideAnnotations(annotationsToHide);
};

const replicateAnnotationInUserWidgets = (
  webviewInstance: any,
  email: string,
  type: DigitalSignatureType,
  annotation: any,
) => {
  const { annotationManager } = webviewInstance.Core;

  const signatureWidgetAnnotationsToSign = annotationManager
    .getAnnotationsList()
    .filter(
      (annot: any) =>
        annot instanceof
          webviewInstance.Core.Annotations.SignatureWidgetAnnotation &&
        annot.getAssociatedSignatureAnnotation()?.Id !== annotation.Id &&
        type === annot.getCustomData('type') &&
        email === annot.getCustomData('email'),
    );

  signatureWidgetAnnotationsToSign.forEach((widget: any) => {
    const annotationCopy = annotationManager.getAnnotationCopy(annotation);

    annotationCopy.X = widget.X;
    annotationCopy.Y = widget.Y;
    annotationCopy.PageNumber = widget.PageNumber;
    annotationCopy.setCustomData('field', widget.getField().name);
    annotationCopy.setCustomData('email', email);
    annotationManager.addAnnotation(annotationCopy);
    annotationManager.redrawAnnotation(annotationCopy);

    if (widget.innerElement) {
      widget.innerElement.style.display = 'none';
    }

    widget.setAssociatedSignatureAnnotation(annotationCopy);
  });
};

const associateSignatureWithAnnotation = (
  webviewInstance: any,
  annotation: any,
) => {
  const { annotationManager, Annotations } = webviewInstance.Core;

  const signatureWidgetSignedNow = annotationManager
    .getAnnotationsList()
    .find(
      (annot: any) =>
        annot instanceof Annotations.SignatureWidgetAnnotation &&
        annot.getAssociatedSignatureAnnotation()?.Id === annotation.Id,
    );

  if (!signatureWidgetSignedNow) return;

  if (signatureWidgetSignedNow.innerElement) {
    signatureWidgetSignedNow.innerElement.style.display = 'none';
  }

  const { name } = signatureWidgetSignedNow.getField();

  const email = signatureWidgetSignedNow.getCustomData('email');
  const type = signatureWidgetSignedNow.getCustomData('type');

  annotation.setCustomData('field', name);
  annotation.setCustomData('email', email);
  annotation.NoMove = true;
  annotation.NoResize = true;

  replicateAnnotationInUserWidgets(webviewInstance, email, type, annotation);
};

const deleteAnnotationsAssociatedWithUserWidget = (
  webviewInstance: any,
  signatureWidget: any,
) => {
  const { annotationManager } = webviewInstance.Core;
  const email = signatureWidget.getCustomData('email');
  const type = signatureWidget.getCustomData('type');

  const widgetAnnotationsToDeleteAssociatedAnnotation = annotationManager
    .getAnnotationsList()
    .filter(
      (annot: any) =>
        annot instanceof
          webviewInstance.Core.Annotations.SignatureWidgetAnnotation &&
        annot.getAssociatedSignatureAnnotation() &&
        type === annot.getCustomData('type') &&
        email === annot.getCustomData('email'),
    ) as [];

  const annotationsToDelete = widgetAnnotationsToDeleteAssociatedAnnotation.map(
    (widget: any) => widget.getAssociatedSignatureAnnotation(),
  );

  widgetAnnotationsToDeleteAssociatedAnnotation.forEach((widget: any) => {
    const associatedSignatureAnnotation =
      widget.getAssociatedSignatureAnnotation();

    associatedSignatureAnnotation.deleteCustomData('field');
    associatedSignatureAnnotation.deleteCustomData('email');

    if (widget.innerElement) {
      widget.innerElement.style.display = 'block';
    }
  });

  annotationManager.deleteAnnotations(annotationsToDelete);
};

const desassociateSignatureWithAnnotation = (
  webviewInstance: any,
  idAnnotation: string,
) => {
  const signatureWidgetAnnotation = webviewInstance.Core.annotationManager
    .getAnnotationsList()
    .find(
      (annot: any) =>
        annot instanceof
          webviewInstance.Core.Annotations.SignatureWidgetAnnotation &&
        annot.getAssociatedSignatureAnnotation()?.Id === idAnnotation,
    );

  if (!signatureWidgetAnnotation) return;

  const associatedSignatureAnnotation =
    signatureWidgetAnnotation.getAssociatedSignatureAnnotation();

  associatedSignatureAnnotation.deleteCustomData('field');
  associatedSignatureAnnotation.deleteCustomData('email');

  if (signatureWidgetAnnotation.innerElement) {
    signatureWidgetAnnotation.innerElement.style.display = 'block';
  }

  deleteAnnotationsAssociatedWithUserWidget(
    webviewInstance,
    signatureWidgetAnnotation,
  );
};

const thisUserHaveInitials = (webviewInstance: any, email: string) => {
  if (!webviewInstance) return false;

  const { Annotations, annotationManager } = webviewInstance.Core;
  const stampAnnotations = annotationManager
    .getAnnotationsList()
    .filter(
      (annot: any) =>
        annot instanceof Annotations.StampAnnotation &&
        annot.getCustomData('email') === email &&
        annot.getCustomData('type') === 'initials',
    );

  return Boolean(stampAnnotations.length);
};

const addInitialOnAllPages = (webviewInstance: any, email: string) => {
  if (!webviewInstance) return false;

  const { Annotations, annotationManager } = webviewInstance.Core;

  const initialsAnnots = annotationManager
    .getAnnotationsList()
    .filter(
      (annot: any) =>
        annot instanceof Annotations.StampAnnotation &&
        annot.getCustomData('email') === email &&
        annot.getCustomData('type') === 'initials',
    );

  if (!initialsAnnots.length) return false;

  const pageCount = webviewInstance.Core.documentViewer.getPageCount();

  const pages = Array.from({ length: pageCount }, (value, key) => key + 1);
  const pagesThatHaveInitials = initialsAnnots.map(
    (annot: any) => annot.PageNumber,
  );

  const pagesToAddInitials = pages.filter(
    page => !pagesThatHaveInitials.includes(page),
  );

  if (!pagesToAddInitials.length) return false;

  pagesToAddInitials.forEach(page => {
    const annotationCopy = annotationManager.getAnnotationCopy(
      initialsAnnots[0],
    );

    annotationCopy.PageNumber = page;

    annotationManager.addAnnotation(annotationCopy);
    annotationManager.redrawAnnotation(annotationCopy);
  });

  return true;
};

function generateAnnotationImage(webviewInstance: any, annotation: any) {
  const context = new CanvasToSvg(annotation.Width, annotation.Height);

  if (!context) {
    return '';
  }
  context.translate(-annotation.X, -annotation.Y);

  const pageMatrix = webviewInstance.Core.documentViewer
    .getDocument()
    .getPageMatrix(annotation.PageNumber);
  annotation.draw(context, pageMatrix);

  return `data:image/svg+xml;base64,${window.btoa(context.getSerializedSvg())}`;
}

const freeHandAnnotationToStampAnnotation = (
  webviewInstance: any,
  annotation: any,
) => {
  const { annotationManager, Annotations } = webviewInstance.Core;

  const signatureWidgetAnnotation = annotationManager
    .getAnnotationsList()
    .find(
      (currentAnnotation: any) =>
        currentAnnotation instanceof
          webviewInstance.Core.Annotations.SignatureWidgetAnnotation &&
        currentAnnotation.getAssociatedSignatureAnnotation()?.Id ===
          annotation.Id,
    );

  if (!signatureWidgetAnnotation) return;

  const image = generateAnnotationImage(webviewInstance, annotation);

  if (!image) return;

  const stampAnnotation = new Annotations.StampAnnotation();
  stampAnnotation.PageNumber = annotation.PageNumber;
  stampAnnotation.Width = annotation.Width;
  stampAnnotation.Height = annotation.Height;
  stampAnnotation.X = annotation.X;
  stampAnnotation.Y = annotation.Y;
  stampAnnotation.disableRotationControl();

  stampAnnotation.setImageData(image, true);
  annotationManager.addAnnotation(stampAnnotation);
  annotationManager.redrawAnnotation(stampAnnotation);
  signatureWidgetAnnotation.setAssociatedSignatureAnnotation(stampAnnotation);
  annotationManager.deleteAnnotation(annotation);
};

export {
  getPageCoordinates,
  getPageCenter,
  drawStampAnnotation,
  changeStampAnnotationsToSignatureWidget,
  deleteAnnotationsFromEmail,
  distinguishSignatureWidgets,
  hideAnnotationsFromOtherUsers,
  associateSignatureWithAnnotation,
  desassociateSignatureWithAnnotation,
  thisUserHaveInitials,
  addInitialOnAllPages,
  freeHandAnnotationToStampAnnotation,
};
