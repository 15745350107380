/* eslint-disable dot-notation */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckIcon from '@mui/icons-material/Check';

import ActionButton from '../../components/ActionButton';

import useDialogContext from '../../hooks/useDialogContext';

import { Client } from '../../types/Client';

import api from '../../services/api';

import COLORS from '../../utils/colors';
import { cpfMask, cnpjMask, phoneMask, digitsMask } from '../../utils/masks';

const useStyles = makeStyles({
  dialog: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40rem',
    margin: '0 auto',
  },

  text: {
    textAlign: 'left',
    color: COLORS.black,
    fontWeight: 'bold',
    fontSize: '.925rem',
  },

  dialogActions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '1rem 0 0 1rem',
    alignSelf: 'end',
    marginTop: '1rem',
    gap: '.5rem',
  },
});

interface FormValues {
  nome: string;
  email: string;
  documento: string;
  data_nascimento: string;
  telefone: string;
  password: string;
}

interface Data {
  name: string;
  password: string;
  telefone: string;
  nascimento?: string;
  cpf?: string;
  cnpj?: string;
}

type Fields =
  | 'nome'
  | 'email'
  | 'documento'
  | 'data_nascimento'
  | 'telefone'
  | 'password';

interface Props {
  client: Client;
  setClient: (client: Client) => void;
}

const EditAccountInformationModal: React.FC<Props> = ({
  client,
  setClient,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesLaptop = useMediaQuery(theme.breakpoints.down('laptop'));

  const masks = {
    cpf: cpfMask,
    cnpj: cnpjMask,
    telefone: phoneMask,
  };

  const initialState = {
    nome: client.nome,
    documento:
      client.documento.length === 11
        ? cpfMask(client.documento)
        : cnpjMask(client.documento),
    telefone: phoneMask(client.telefone),
    email: client.email,
    data_nascimento: client.data_nascimento,
    password: '',
  };

  const [editForm, setEditForm] = useState(initialState);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const [passwordError, setPasswordError] = useState('');

  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { showDialog } = useDialogContext();

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<FormValues>();

  const handleClose = () => {
    setOpen(false);
    clearErrors();
  };

  const handleOpen = () => {
    setOpen(true);
    setEditForm(initialState);
  };

  const handleEditFormChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    const mask = masks[e.target.name as 'telefone'];
    setPasswordError('');

    if (e.target.name === 'documento') {
      setEditForm({
        ...editForm,
        documento:
          client.documento.length === 11
            ? cpfMask(e.target.value)
            : cnpjMask(e.target.value),
      });
      return;
    }

    setEditForm({
      ...editForm,
      [e.target.name]: mask ? mask(e.target.value) : e.target.value,
    });
  };

  const handleDateChange = (newDate: moment.Moment | null) => {
    setEditForm({
      ...editForm,
      data_nascimento: moment(newDate).toString(),
    });
  };

  const onSubmit: SubmitHandler<FormValues> = async values => {
    setIsSubmitting(true);

    const { documento } = values;

    const data: Data = {
      name: editForm.nome,
      telefone: editForm.telefone,
      password: editForm.password,
      ...(documento.length === 14
        ? {
            cpf: editForm.documento,
            nascimento: moment(editForm.data_nascimento).format('YYYY-MM-DD'),
          }
        : {
            cnpj: editForm.documento,
          }),
    };

    try {
      await api.post('usuario/editar', data);

      setClient({
        ...client,
        nome: values.nome,
        telefone: values.telefone,
        documento: digitsMask(values.documento),
        ...(documento.length === 14
          ? {
              data_nascimento: moment(
                values.data_nascimento,
                'DD-MM-YYYY',
              ).format('YYYY-MM-DD'),
            }
          : {}),
      });

      setIsSubmitting(false);

      showDialog('success', 'Informações alteradas com sucesso!');
      handleClose();

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setIsSubmitting(false);
      if (!error.response) {
        return;
      }

      if (error.response.status === 500) {
        setError('password', {
          message:
            'Não foi possível alterar as informações. Tente novamente mais tarde!',
        });
        return;
      }

      if (
        error.response.data.message ===
        `Usu\u00e1rio n\u00e3o possui acesso para alterar esses dados.`
      ) {
        showDialog(
          'default',
          'Você não possui permissão para alterar esses dados.',
        );
        handleClose();
        return;
      }

      const errorMessage = error.response.data.message;

      Object.keys(errorMessage).forEach(key => {
        const field = key as Fields;
        setError(field, {
          message: errorMessage[field][0],
        });

        if (errorMessage === 'Senha do usu\u00e1rio incorreta.') {
          setPasswordError('Senha incorreta.');
        }
      });

      if (errorMessage === 'Senha do usu\u00e1rio incorreta.') {
        setPasswordError('Senha incorreta.');
      }
    }
  };

  useEffect(() => {
    if (editForm) {
      unregister();
    }
  }, [editForm]);

  return (
    <>
      <ActionButton
        text="Editar informações"
        type="button"
        margin="0"
        mobilewidth="12rem"
        tabletwidth="12rem"
        laptopwidth="14rem"
        desktopwidth="16rem"
        icon={<EditIcon sx={{ color: COLORS.white, fontSize: '1.3rem' }} />}
        action={handleOpen}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        className={classes.dialog}
        sx={{ width: '30rem' }}
      >
        <DialogTitle>Editar informações</DialogTitle>

        <form
          noValidate
          id="editForm"
          name="editPersonalInformationForm"
          method="post"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            width: '30rem',
            padding: '1.5rem',
            gap: '10px',
            ...(matchesLaptop
              ? {
                  overflow: 'auto',
                }
              : {}),
          }}
          onSubmit={e => {
            clearErrors();
            handleSubmit(onSubmit)(e);
          }}
        >
          <Stack
            sx={{
              width: '100%',
              gap: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: {
                mobile: 'column',
                tablet: 'column',
              },
            }}
          >
            <Typography
              className={classes.text}
              sx={{ margin: '0 auto .5rem 0', fontSize: '.925rem' }}
              variant="subtitle2"
            >
              Digite os novos valores nos campos que desejar alterar
            </Typography>

            <FormControl sx={{ width: '100%' }}>
              <TextField
                autoFocus
                id="edit_nome"
                color="primary"
                label="Nome"
                type="text"
                variant="standard"
                sx={{
                  '.MuiInput-root': {
                    backgroundColor: 'transparent !important',
                  },
                }}
                style={{ fontSize: '.9rem', marginTop: '.5rem', width: '100%' }}
                {...register('nome', {
                  required: 'Por favor, informe seu nome',
                  minLength: {
                    value: 10,
                    message: 'O nome deve conter pelo menos 10 caracteres',
                  },
                  pattern: {
                    value: /^[A-zÀ-ú ']+$/,
                    message: 'Por favor, informe um nome válido',
                  },
                })}
                value={editForm.nome}
                onChange={handleEditFormChanges}
                error={Boolean(errors.nome)}
                helperText={errors.nome?.message}
              />
            </FormControl>

            <FormControl sx={{ width: '100%' }}>
              <TextField
                id="edit_email"
                color="primary"
                label="Email"
                type="email"
                variant="standard"
                style={{ fontSize: '.9rem', width: '100%' }}
                value={editForm.email}
                disabled
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
              />
            </FormControl>

            <FormControl sx={{ width: '100%' }}>
              <TextField
                id="edit_documento"
                color="primary"
                label={
                  client.documento.length === 11
                    ? 'CPF (digite apenas os números)'
                    : 'CNPJ (digite apenas os números)'
                }
                type="text"
                variant="standard"
                style={{ fontSize: '.9rem', width: '100%' }}
                {...register('documento', {
                  required:
                    client.documento.length === 11
                      ? 'Por favor, informe o CPF'
                      : 'Por favor, informe o CNPJ',
                  minLength: {
                    value: client.documento.length === 11 ? 14 : 18,
                    message:
                      client.documento.length === 11
                        ? 'O CPF deve conter 11 dígitos'
                        : 'O CNPJ deve conter 14 dígitos',
                  },
                })}
                value={editForm.documento}
                onChange={handleEditFormChanges}
                error={Boolean(errors.documento)}
                helperText={errors.documento?.message}
              />
            </FormControl>

            {client.documento.length === 11 && (
              <FormControl sx={{ width: '100%' }}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DatePicker
                    label="Data de Nascimento"
                    value={editForm.data_nascimento}
                    inputFormat="L"
                    mask="__/__/____"
                    disableFuture
                    onChange={handleDateChange}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="standard"
                        color="primary"
                        {...register('data_nascimento', {
                          required: 'Por favor, informe sua data de nascimento',
                        })}
                        error={Boolean(errors.data_nascimento)}
                        helperText={errors.data_nascimento?.message}
                        value={editForm.data_nascimento}
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            )}

            <FormControl sx={{ width: '100%' }}>
              <TextField
                id="edit_telefone"
                color="primary"
                label="Telefone (digite apenas os números)"
                type="text"
                variant="standard"
                style={{ fontSize: '.9rem', width: '100%' }}
                {...register('telefone', {
                  required: 'Por favor, informe o telefone',
                  minLength: {
                    value: 14,
                    message: 'O telefone deve conter ao menos 10 dígitos',
                  },
                  maxLength: {
                    value: 15,
                    message: 'O telefone deve conter até 11 dígitos',
                  },
                })}
                value={editForm.telefone}
                onChange={handleEditFormChanges}
                error={Boolean(errors.telefone)}
                helperText={errors.telefone?.message}
              />
            </FormControl>

            <Typography
              className={classes.text}
              sx={{ margin: '1rem auto 0 0', fontSize: '.925rem' }}
              variant="subtitle2"
            >
              Digite sua senha para prosseguir com a alteração dos dados
            </Typography>
            <FormControl sx={{ width: '100%' }}>
              <TextField
                id="edit_password"
                color="primary"
                label="Senha atual"
                type={showPassword ? 'text' : 'password'}
                variant="standard"
                style={{ fontSize: '.9rem', width: '100%' }}
                {...register('password', {
                  required: 'Por favor, forneça uma senha',
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                    message:
                      'A senha deve ter ao menos 8 caracteres, dentre eles uma letra maiúscula e outra minúscula',
                  },
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title={
                          showPassword ? 'Esconder senha' : 'Mostrar senha'
                        }
                      >
                        <IconButton onClick={handleClickShowPassword}>
                          {showPassword ? (
                            <VisibilityIcon sx={{ color: 'primary.main' }} />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={editForm.password}
                onChange={handleEditFormChanges}
                error={Boolean(errors.password) || Boolean(passwordError)}
                helperText={errors.password?.message}
              />
            </FormControl>
            {passwordError && (
              <div style={{ width: '100%', textAlign: 'left' }}>
                <Typography
                  style={{
                    fontSize: '.7rem',
                    color: COLORS.redWarning,
                  }}
                >
                  {passwordError}
                </Typography>
              </div>
            )}

            <Box className={classes.dialogActions}>
              <Button
                onClick={handleClose}
                sx={{
                  width: '7rem',
                  color: COLORS.mediumGray3,
                  '&:hover': {
                    backgroundColor: COLORS.lightGray2,
                  },
                }}
              >
                Cancelar
              </Button>

              {isSubmitting ? (
                <LoadingButton
                  loading
                  loadingPosition="center"
                  sx={{
                    width: '7rem',
                    height: '2.25rem',
                    color: COLORS.white,
                    backgroundColor: 'primary.main',
                    '&:hover': {
                      backgroundColor: 'primary.light',
                    },
                  }}
                />
              ) : (
                <Button
                  type="submit"
                  name="editPersonalInformationForm"
                  endIcon={<CheckIcon />}
                  sx={{
                    width: '7rem',
                    color: COLORS.white,
                    backgroundColor: 'primary.main',
                    '&:hover': {
                      backgroundColor: 'primary.light',
                    },
                  }}
                >
                  Alterar
                </Button>
              )}
            </Box>
          </Stack>
        </form>
      </Dialog>
    </>
  );
};

export default EditAccountInformationModal;
