import { CSSProperties } from 'react';

import NotoSansRegularFont from './NotoSans-Regular.ttf';
import NotoSansMediumFont from './NotoSans-Medium.ttf';
import NotoSansSemiBoldFont from './NotoSans-SemiBold.ttf';
import NotoSansBoldFont from './NotoSans-Bold.ttf';

const notoSansRegular = {
  fontFamily: 'Noto Sans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('NotoSans'),
    local('NotoSans-Regular'),
    url(${NotoSansRegularFont}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
} as CSSProperties;

const notoSansMedium = {
  fontFamily: 'Noto Sans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `
    local('NotoSans'),
    local('NotoSans-Regular'),
    url(${NotoSansMediumFont}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
} as CSSProperties;

const notoSansSemiBold = {
  fontFamily: 'Noto Sans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
    local('NotoSans'),
    local('NotoSans-Regular'),
    url(${NotoSansSemiBoldFont}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
} as CSSProperties;

const notoSansBold = {
  fontFamily: 'Noto Sans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('NotoSans'),
    local('NotoSans-Regular'),
    url(${NotoSansBoldFont}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
} as CSSProperties;

export default [
  notoSansRegular,
  notoSansMedium,
  notoSansSemiBold,
  notoSansBold,
];
