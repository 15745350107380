import { Link, useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import styled from '@mui/styles/styled';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import MailSentImage from '../../components/Images/MailSentImage';

import COLORS from '../../utils/colors';

const OuterGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  overflow: 'hidden',
  height: '100vh',
  width: '100%',
});

const ContentGrid = styled(Grid)({
  margin: '8rem auto',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '40%',
});

export default function EmailSentForPasswordRecovery() {
  const { state } = useLocation();
  const theme = useTheme();

  return (
    <OuterGrid>
      <ContentGrid sx={{ flexDirection: 'column', gap: 2, overflow: 'hidden' }}>
        <Typography
          style={{
            fontSize: '1.4rem',
            margin: '.5rem 0',
            fontWeight: 'bold',
            color: COLORS.gray1,
          }}
        >
          Email enviado :)
        </Typography>

        <MailSentImage
          style={{ width: '8rem', height: '8rem', marginTop: '1rem' }}
        />

        <Typography
          sx={{
            fontSize: '.9rem',
            margin: '1.5rem 0 1rem 0',
            color: COLORS.gray1,
            textAlign: 'center',
            width: '60%',
          }}
        >
          Acesse seu email <strong>{state as string}</strong> para encontrar o
          link e as instruções para redefinição de senha.
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            gap: 3,
            marginTop: '2.5rem',
            fontSize: '.9rem',
          }}
        >
          <Link
            to="/forgot_password"
            style={{
              textDecoration: 'underline',
              color: theme.palette.primary.main,
              fontSize: '.9rem',
            }}
          >
            Não recebi o e-mail
          </Link>

          <Link
            to="/forgot_password"
            style={{
              textDecoration: 'underline',
              color: theme.palette.primary.main,
            }}
          >
            Estou com problemas
          </Link>
        </Box>

        <Link
          to="/"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 4,
            textDecoration: 'underline',
            color: theme.palette.primary.main,
            marginTop: '3rem',
            fontSize: '.9rem',
          }}
        >
          <ArrowBackIcon
            sx={{
              textDecoration: 'underline',
              color: theme.palette.primary.main,
              fontSize: '.9rem',
            }}
          />
          Voltar ao login
        </Link>
      </ContentGrid>
    </OuterGrid>
  );
}
