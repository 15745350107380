/* eslint-disable no-param-reassign */
/* eslint-disable no-else-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable consistent-return */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDrop } from 'react-dnd';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { FolderArrayObject, DestinationFolder } from '../../types/Folder';

import useCostCenterContext from '../../hooks/useCostCenterContext';

import COLORS from '../../utils/colors';

interface Props {
  folder: FolderArrayObject;
  folderIndex: number;
}

const Breadcrumb = ({ folder, folderIndex }: Props) => {
  const navigate = useNavigate();

  const {
    getSubfolderFoldersAndFiles,
    folderArray,
    setFolderArray,
    folderNavigation,
    setFolderNavigation,
    selectedFoldersIds,
    selectedDocuments,
    isSubfolderPage,
    moveFoldersAndDocuments,
  } = useCostCenterContext();

  const [currentCostCenter] = useState(
    JSON.parse(localStorage.getItem('current-cost-center') || ''),
  );

  const ref = useRef(null);

  const whereToSend: DestinationFolder = {
    name: folder.name,
    id: Number(folder.id),
    type: folder.type,
  };

  const [{ isActive }, drop] = useDrop(
    () => ({
      accept: ['folder', 'document'],
      drop: () => {
        if (currentCostCenter) {
          /* if the select folder isn't the destination folder */
          if (!!selectedFoldersIds.find(f => f === folder.id) === false) {
            moveFoldersAndDocuments(
              selectedFoldersIds,
              selectedDocuments,
              whereToSend,
              currentCostCenter,
              isSubfolderPage,
            );
          }
        }
      },
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        isActive: monitor.canDrop() && monitor.isOver(),
      }),
    }),
    [selectedFoldersIds, selectedDocuments],
  );

  const changeFolderArray = (clickedFolder: FolderArrayObject) => {
    const array = [
      {
        id: currentCostCenter.id,
        name: currentCostCenter.nome,
        type: currentCostCenter.type,
      },
    ];
    if (folderNavigation) {
      const sameFolder = folderNavigation?.filter(
        f => f.id === clickedFolder.id,
      );

      if (sameFolder?.length === 0) {
        /* if first element sets the folder in the array */
        if (folderIndex === 0) {
          array.push(folder);
          localStorage.setItem('folderNavigation', JSON.stringify(array));
          setFolderNavigation?.(
            JSON.parse(localStorage.getItem('folderNavigation') || '[]'),
          );
          setFolderArray?.(
            JSON.parse(localStorage.getItem('folderArray') || '[]'),
          );
        } else {
          /* sets the array up to the clicked element index plus one since it starts from index 1 */
          folderNavigation.length = Number(folderIndex) + 1;
          /* setFolderNavigation?.(folderNavigation); */
          folderNavigation.map(f => array.push(f));
          setFolderNavigation?.(array);
          localStorage.setItem(
            'folderNavigation',
            JSON.stringify(folderNavigation),
          );
        }
      }
      /* remove folders after clicked folder from the breadcrumbs */
      const newLastFolder = folderNavigation.find(
        f => f.id === clickedFolder.id && f.type === 'folder',
      );
      const newLastFolderInModalArray = folderArray.find(
        f => f.id === clickedFolder.id && f.type === 'folder',
      );
      if (newLastFolder && newLastFolderInModalArray) {
        const newLastIndex = folderNavigation.indexOf(newLastFolder);
        const newLastIndexInModalArray = folderArray.indexOf(
          newLastFolderInModalArray,
        );
        folderNavigation.splice(
          newLastIndex + 1,
          folderNavigation.length - (newLastIndex + 1),
        );
        folderArray.splice(
          newLastIndexInModalArray + 1,
          folderArray.length - (newLastIndexInModalArray + 1),
        );
        localStorage.setItem(
          'folderNavigation',
          JSON.stringify(folderNavigation),
        );
        localStorage.setItem('folderArray', JSON.stringify(folderArray));
      }
      /* if the clicked folder is the current cost center one */
      if (
        currentCostCenter.id === clickedFolder.id &&
        clickedFolder.type === 'cost_center'
      ) {
        const currentCostCenterFolder = folderNavigation.find(
          f => f.id === currentCostCenter.id && f.type === 'cost_center',
        );
        if (currentCostCenterFolder) {
          const costCenterFolderIndex = folderNavigation.indexOf(
            currentCostCenterFolder,
          );
          folderNavigation.splice(
            costCenterFolderIndex + 1,
            folderNavigation.length - (costCenterFolderIndex + 1),
          );
          localStorage.setItem(
            'folderNavigation',
            JSON.stringify(folderNavigation),
          );

          if (localStorage.getItem('folderArray')) {
            localStorage.removeItem('folderArray');
          }
          navigate(`/minhas_pastas/${clickedFolder.id}`);
        }
      }
    }
  };

  drop(ref);

  return (
    <>
      {folderNavigation &&
      folderIndex === Number(folderNavigation.length) - 1 ? (
        <Typography
          color="primary.main"
          sx={{
            minWidth: '3rem',
            width: 'fit-content',
            textAlign: 'center',
            padding: '.2rem .5rem',
            fontSize: '.925rem',
            lineHeight: '1.75',
            fontWeight: 600,
            textTransform: 'none',
          }}
        >
          {folder.name}
        </Typography>
      ) : (
        <>
          <Button
            ref={ref}
            id={JSON.stringify(folder.id)}
            disableRipple
            variant="text"
            sx={{
              minWidth: '4rem',
              textDecoration: isActive ? 'underline' : 'none',
              fontSize: '.925rem',
              color: theme =>
                isActive
                  ? `${theme.palette.primary.main} !important`
                  : COLORS.mediumGray5,
              textTransform: 'none',
              width: 'fit-content',
              backgroundColor: isActive ? 'primary.light' : 'transparent',
              padding: '.2rem .5rem',
              borderRadius: '4px',
              '&:hover': {
                color: theme => `${theme.palette.primary.main} !important`,
                backgroundColor: 'transparent',
                textDecoration: 'underline',
              },
            }}
            onClick={() => {
              changeFolderArray(folder);

              if (isSubfolderPage) {
                getSubfolderFoldersAndFiles?.(Number(folder.id));
              }
            }}
          >
            {folder.name}
          </Button>

          <NavigateNextIcon
            sx={{ color: COLORS.mediumGray3, fontSize: '1.2rem' }}
          />
        </>
      )}
    </>
  );
};

export default Breadcrumb;
