import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';

import RoleBasedMainBox from '../../components/RoleBasedMainBox';
import ItsSoEmptyHere from './ItsSoEmptyHere';
import ModalCreatePlan from './ModalCreatePlan';

import { Plan } from '../../types/Plan';

import api from '../../services/api';

import COLORS from '../../utils/colors';
import formatPrice from '../../utils/formatPrice';

const useStyles = makeStyles((theme: Theme) => ({
  containerPlans: {
    display: 'flex',
    width: '100%',
    height: '90%',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    alignItems: 'center',
  },

  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
  },

  headerTable: {
    '& th': {
      border: 0,
      color: COLORS.black,
      fontSize: '1.175rem',
    },
  },

  row: {
    '& td': {
      fontSize: '1.025rem',
      borderBottom: `1px solid ${COLORS.lightGray5}`,
      color: COLORS.mediumGray5,
      fontWeight: 500,
    },
  },

  actions: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '0.75rem',
  },

  buttonViewDetails: {
    '&.MuiButtonBase-root': {
      textTransform: 'none',
      background: 'none',
      textDecoration: 'underline',
      color: theme.palette.primary.main,
      fontSize: '1rem',
      height: '2rem',
      borderRadius: '.625rem',
      padding: 0,
      fontWeight: 500,

      '&:hover': {
        background: 'none',
        transition: '0.3s',
        opacity: '0.6',
        textDecoration: 'underline',
      },
    },
  },
}));

export default function AdminSubscriptionPlans() {
  const classes = useStyles();
  const [plans, setPlans] = useState([] as Plan[]);

  const rowsPerPage = 6;
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPlans, setTotalPlans] = useState(0);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    setIsLoading(true);
    api
      .get('/planos/listar', {
        params: {
          page: page + 1,
          limit: rowsPerPage,
        },
      })
      .then(response => {
        setPlans(response.data);

        const xTotalCount = response.headers['x-total-count'];
        setTotalPlans(Number(xTotalCount));

        setIsLoading(false);
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  return (
    <RoleBasedMainBox>
      <ModalCreatePlan />

      {plans && !isLoading ? (
        <Grid className={classes.containerPlans}>
          <TableContainer
            className={classes.tableContainer}
            sx={{
              '::-webkit-scrollbar': {
                width: '10px',
              },
              '::-webkit-scrollbar-track': {
                background: COLORS.lightGray3,
                borderRadius: '50px',
              },
              '::-webkit-scrollbar-thumb': {
                background: COLORS.mediumGray2,
                borderRadius: '50px',
              },
              '::-webkit-scrollbar-thumb:hover': {
                background: COLORS.mediumGray3,
              },
            }}
          >
            {plans.length > 0 ? (
              <>
                <Table>
                  <TableHead>
                    <TableRow className={classes.headerTable}>
                      <TableCell>Plano</TableCell>
                      <TableCell>Data de Criação</TableCell>
                      <TableCell>Valor</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {plans.map(plan => (
                      <TableRow key={plan.id} className={classes.row}>
                        <TableCell>{plan.nome}</TableCell>
                        <TableCell>
                          {moment(plan.created_at).format('L LT')}
                        </TableCell>
                        <TableCell>R$ {formatPrice(plan.valor)}</TableCell>
                        <TableCell>{plan.status}</TableCell>

                        <TableCell>
                          <div className={classes.actions}>
                            <Button
                              className={classes.buttonViewDetails}
                              component={Link}
                              to={`/planos/${plan.id}`}
                            >
                              Ver detalhes
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <div style={{ alignSelf: 'center' }}>
                  <TablePagination
                    component="div"
                    count={totalPlans}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[]}
                    sx={{
                      '& .css-rprza6-MuiTablePagination-displayedRows': {
                        marginBottom: '0',
                      },
                    }}
                  />
                </div>
              </>
            ) : (
              <Grid
                sx={{
                  width: '100%',
                  height: '90%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '0 auto',
                }}
              >
                <ItsSoEmptyHere />
              </Grid>
            )}
          </TableContainer>
        </Grid>
      ) : (
        <Grid
          sx={{
            width: '100%',
            height: '90%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 auto',
          }}
        >
          <CircularProgress color="warning" />
        </Grid>
      )}
    </RoleBasedMainBox>
  );
}
