import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface Props {
  open: boolean;
  color: string;
}

const LoadingBackdrop: React.FC<Props> = ({ open, color }) => {
  return (
    <Backdrop
      sx={{
        color,
        zIndex: theme => theme.zIndex.appBar + theme.zIndex.drawer,
      }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default LoadingBackdrop;
