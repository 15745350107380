import { CSSProperties } from 'react';

import logoCodata from '../../assets/images/codata/logo-codata.svg';

import { LogoType } from '.';

interface LogoCodataProps {
  type: LogoType;
}

const styles = {
  logOn: {
    width: '8rem',
    alignSelf: 'center',
    marginLeft: '1rem',
    objectFit: 'contain',
    height: '3rem',
    marginTop: '.5rem',
  } as CSSProperties,

  logOff: {
    width: '8rem',
  },
};

export default function LogoCodata({ type }: LogoCodataProps) {
  return (
    <img
      style={styles[type]}
      src={logoCodata}
      alt="Logo do CodataSign"
    />
  );
}
