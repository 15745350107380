import FileSaver from 'file-saver';

import api from '../../../services/api';

const downloadReportFile = async (
  costCenterId: number | undefined,
  documentId: string | number,
  fileName: string,
) => {
  try {
    const fileResponse = await api.get(`report/${costCenterId}/${documentId}`);
    const [newFileName] = fileName.split('.');

    FileSaver.saveAs(
      `data:application/pdf;base64,${fileResponse.data}`,
      `${newFileName}_relatorio.pdf`,
    );
  } catch (error) {
    console.log(error);
  }
};

export default downloadReportFile;
