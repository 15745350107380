/* eslint-disable react/jsx-props-no-spreading */
import { useTheme } from '@mui/material';

export default function PaymentMethodImage(
  props: React.SVGAttributes<SVGSVGElement>,
) {
  const theme = useTheme();

  return (
    <svg
      width="743"
      height="518"
      viewBox="0 0 743 518"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M488.79 47.5668L13.5508 186.032L26.1385 229.235L501.378 90.7704L488.79 47.5668Z"
        fill="#3F3D56"
      />
      <path
        d="M70.3291 384.482L0.28261 144.068C-0.236695 142.286 -0.0266514 140.37 0.866503 138.743C1.75966 137.115 3.26276 135.909 5.04518 135.39L468.763 0.281372C470.546 -0.236189 472.461 -0.0254329 474.088 0.867401C475.715 1.76024 476.921 3.26219 477.442 5.04358L539.498 218.032L537.578 218.592L475.522 5.60315C475.149 4.33126 474.287 3.25904 473.125 2.6214C471.963 1.98376 470.596 1.83268 469.323 2.20128L5.60463 137.31C4.33147 137.681 3.25783 138.542 2.61986 139.705C1.98189 140.867 1.83186 142.236 2.20278 143.509L72.2493 383.922L70.3291 384.482Z"
        fill="#3F3D56"
      />
      <path
        d="M436.862 219.022C435.455 219.02 434.087 218.563 432.961 217.719C431.836 216.875 431.014 215.689 430.619 214.339L418.87 174.015C418.39 172.36 418.586 170.583 419.415 169.072C420.244 167.561 421.638 166.441 423.292 165.957L463.616 154.208C465.271 153.728 467.049 153.924 468.56 154.753C470.071 155.583 471.191 156.977 471.674 158.631L483.423 198.954C483.903 200.609 483.707 202.387 482.878 203.898C482.049 205.409 480.655 206.529 479.001 207.013L438.677 218.762C438.088 218.934 437.477 219.022 436.862 219.022V219.022Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M69.2465 144.812C69.1258 144.398 68.9821 143.99 68.8163 143.592C67.6889 140.856 65.59 138.632 62.9229 137.35C60.2558 136.067 57.2087 135.815 54.3671 136.642C51.5255 137.469 49.0898 139.318 47.5282 141.832C45.9665 144.346 45.389 147.348 45.9067 150.262C45.9794 150.687 46.0762 151.108 46.1967 151.522C46.9269 154.015 48.445 156.204 50.5235 157.761C52.602 159.319 55.1291 160.161 57.7265 160.162C58.8605 160.165 59.9889 160.003 61.0766 159.682C64.1295 158.79 66.7041 156.724 68.2356 153.936C69.7672 151.148 70.1307 147.867 69.2465 144.812V144.812ZM60.5165 157.762C57.9705 158.501 55.2354 158.2 52.9109 156.925C50.5864 155.651 48.8623 153.506 48.1166 150.962C47.9996 150.548 47.906 150.127 47.8364 149.702C47.4613 147.301 47.9735 144.846 49.2773 142.795C50.5811 140.745 52.5872 139.239 54.9204 138.56C57.2537 137.881 59.7543 138.076 61.9548 139.107C64.1552 140.138 65.9048 141.935 66.8763 144.162C67.0574 144.553 67.208 144.958 67.3265 145.372C68.0645 147.918 67.7618 150.653 66.485 152.976C65.2081 155.299 63.0615 157.02 60.5165 157.762V157.762Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M39.8139 153.382C39.1739 151.184 39.2354 148.841 39.9897 146.68C40.7441 144.519 42.1537 142.647 44.0222 141.325C42.2716 141.239 40.526 141.573 38.9307 142.299C37.3354 143.025 35.9367 144.122 34.8511 145.498C33.7654 146.874 33.0243 148.489 32.6894 150.21C32.3546 151.93 32.4357 153.706 32.926 155.389C33.4162 157.071 34.3015 158.612 35.508 159.884C36.7145 161.155 38.2074 162.12 39.8622 162.697C41.517 163.275 43.2858 163.448 45.0214 163.204C46.7569 162.959 48.4089 162.304 49.8398 161.292C47.5535 161.18 45.3589 160.358 43.5618 158.94C41.7647 157.522 40.4545 155.579 39.8139 153.382V153.382Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M350.676 202.942C350.463 202.177 350.102 201.461 349.612 200.836C349.122 200.21 348.514 199.688 347.822 199.297C347.13 198.907 346.368 198.658 345.58 198.562C344.791 198.467 343.991 198.528 343.227 198.742L276.836 217.312L269.687 219.312H314.586L321.736 217.312L346.486 210.392C348.029 209.959 349.337 208.931 350.123 207.534C350.908 206.137 351.107 204.486 350.676 202.942V202.942Z"
        fill="#CCCCCC"
      />
      <path
        d="M303.706 189.072C303.274 187.529 302.246 186.22 300.849 185.434C299.452 184.649 297.8 184.45 296.256 184.882L196.637 212.742C195.244 213.136 194.039 214.015 193.238 215.22C192.437 216.424 192.092 217.876 192.266 219.312H218.056L225.216 217.312L299.516 196.532C301.06 196.097 302.369 195.067 303.154 193.669C303.94 192.27 304.139 190.617 303.706 189.072V189.072Z"
        fill="#CCCCCC"
      />
      <path
        d="M560.17 217.31H77.1699C75.314 217.312 73.5348 218.05 72.2225 219.362C70.9102 220.674 70.172 222.454 70.1699 224.31V510.31C70.1719 512.166 70.9101 513.945 72.2224 515.257C73.5347 516.569 75.314 517.308 77.1699 517.31H560.17C562.026 517.308 563.806 516.569 565.118 515.257C566.43 513.945 567.168 512.165 567.17 510.31V224.31C567.168 222.454 566.43 220.674 565.118 219.362C563.805 218.05 562.026 217.312 560.17 217.31V217.31ZM565.17 510.31C565.17 511.636 564.643 512.907 563.705 513.845C562.768 514.782 561.496 515.309 560.17 515.31H77.1699C75.844 515.309 74.5725 514.782 73.6349 513.845C72.6973 512.907 72.1704 511.636 72.1699 510.31V224.31C72.1705 222.984 72.6975 221.712 73.635 220.775C74.5726 219.837 75.844 219.31 77.1699 219.31H560.17C561.496 219.31 562.768 219.837 563.705 220.775C564.643 221.712 565.17 222.984 565.17 224.31V510.31Z"
        fill="#3F3D56"
      />
      <path
        d="M124.166 256.312C121.793 256.312 119.473 255.608 117.499 254.29C115.526 252.971 113.988 251.097 113.08 248.904C112.171 246.712 111.934 244.299 112.397 241.971C112.86 239.643 114.003 237.505 115.681 235.827C117.359 234.149 119.497 233.006 121.825 232.543C124.153 232.08 126.566 232.317 128.758 233.225C130.951 234.134 132.825 235.672 134.144 237.645C135.462 239.619 136.166 241.939 136.166 244.312C136.163 247.493 134.897 250.544 132.648 252.793C130.398 255.043 127.348 256.308 124.166 256.312V256.312ZM124.166 234.312C122.188 234.312 120.255 234.898 118.611 235.997C116.966 237.096 115.684 238.658 114.927 240.485C114.171 242.312 113.973 244.323 114.358 246.263C114.744 248.203 115.697 249.985 117.095 251.383C118.494 252.782 120.276 253.734 122.215 254.12C124.155 254.506 126.166 254.308 127.993 253.551C129.82 252.794 131.382 251.512 132.481 249.868C133.58 248.223 134.166 246.29 134.166 244.312C134.163 241.661 133.109 239.119 131.234 237.244C129.359 235.37 126.817 234.315 124.166 234.312V234.312Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M105.514 244.312C105.515 242.023 106.229 239.791 107.558 237.927C108.886 236.063 110.763 234.66 112.927 233.913C111.27 233.342 109.501 233.174 107.766 233.425C106.032 233.676 104.382 234.337 102.955 235.355C101.528 236.372 100.364 237.716 99.5614 239.274C98.7587 240.832 98.3398 242.559 98.3398 244.312C98.3398 246.065 98.7587 247.792 99.5614 249.35C100.364 250.908 101.528 252.252 102.955 253.269C104.382 254.287 106.032 254.948 107.766 255.199C109.501 255.45 111.27 255.282 112.927 254.711C110.763 253.964 108.886 252.561 107.558 250.697C106.229 248.833 105.515 246.601 105.514 244.312V244.312Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M172.81 386.09C170.424 386.087 168.136 385.138 166.449 383.451C164.762 381.764 163.813 379.476 163.81 377.09V350.09C163.81 347.703 164.758 345.414 166.446 343.726C168.134 342.038 170.423 341.09 172.81 341.09C175.197 341.09 177.486 342.038 179.174 343.726C180.862 345.414 181.81 347.703 181.81 350.09V377.09C181.807 379.476 180.858 381.764 179.171 383.451C177.484 385.138 175.196 386.087 172.81 386.09V386.09Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M196.81 386.09C194.424 386.087 192.136 385.138 190.449 383.451C188.762 381.764 187.813 379.476 187.81 377.09V350.09C187.81 347.703 188.758 345.414 190.446 343.726C192.134 342.038 194.423 341.09 196.81 341.09C199.197 341.09 201.486 342.038 203.174 343.726C204.862 345.414 205.81 347.703 205.81 350.09V377.09C205.807 379.476 204.858 381.764 203.171 383.451C201.484 385.138 199.196 386.087 196.81 386.09V386.09Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M220.81 386.09C218.424 386.087 216.136 385.138 214.449 383.451C212.762 381.764 211.813 379.476 211.81 377.09V350.09C211.81 347.703 212.758 345.414 214.446 343.726C216.134 342.038 218.423 341.09 220.81 341.09C223.197 341.09 225.486 342.038 227.174 343.726C228.862 345.414 229.81 347.703 229.81 350.09V377.09C229.807 379.476 228.858 381.764 227.171 383.451C225.484 385.138 223.196 386.087 220.81 386.09V386.09Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M257.81 386.09C255.424 386.087 253.136 385.138 251.449 383.451C249.762 381.764 248.813 379.476 248.81 377.09V350.09C248.81 347.703 249.758 345.414 251.446 343.726C253.134 342.038 255.423 341.09 257.81 341.09C260.197 341.09 262.486 342.038 264.174 343.726C265.862 345.414 266.81 347.703 266.81 350.09V377.09C266.807 379.476 265.858 381.764 264.171 383.451C262.484 385.138 260.196 386.087 257.81 386.09V386.09Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M281.81 386.09C279.424 386.087 277.136 385.138 275.449 383.451C273.762 381.764 272.813 379.476 272.81 377.09V350.09C272.81 347.703 273.758 345.414 275.446 343.726C277.134 342.038 279.423 341.09 281.81 341.09C284.197 341.09 286.486 342.038 288.174 343.726C289.862 345.414 290.81 347.703 290.81 350.09V377.09C290.807 379.476 289.858 381.764 288.171 383.451C286.484 385.138 284.196 386.087 281.81 386.09V386.09Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M305.81 386.09C303.424 386.087 301.136 385.138 299.449 383.451C297.762 381.764 296.813 379.476 296.81 377.09V350.09C296.81 347.703 297.758 345.414 299.446 343.726C301.134 342.038 303.423 341.09 305.81 341.09C308.197 341.09 310.486 342.038 312.174 343.726C313.862 345.414 314.81 347.703 314.81 350.09V377.09C314.807 379.476 313.858 381.764 312.171 383.451C310.484 385.138 308.196 386.087 305.81 386.09V386.09Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M342.81 386.09C340.424 386.087 338.136 385.138 336.449 383.451C334.762 381.764 333.813 379.476 333.81 377.09V350.09C333.81 347.703 334.758 345.414 336.446 343.726C338.134 342.038 340.423 341.09 342.81 341.09C345.197 341.09 347.486 342.038 349.174 343.726C350.862 345.414 351.81 347.703 351.81 350.09V377.09C351.807 379.476 350.858 381.764 349.171 383.451C347.484 385.138 345.196 386.087 342.81 386.09V386.09Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M366.81 386.09C364.424 386.087 362.136 385.138 360.449 383.451C358.762 381.764 357.813 379.476 357.81 377.09V350.09C357.81 347.703 358.758 345.414 360.446 343.726C362.134 342.038 364.423 341.09 366.81 341.09C369.197 341.09 371.486 342.038 373.174 343.726C374.862 345.414 375.81 347.703 375.81 350.09V377.09C375.807 379.476 374.858 381.764 373.171 383.451C371.484 385.138 369.196 386.087 366.81 386.09V386.09Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M390.81 386.09C388.424 386.087 386.136 385.138 384.449 383.451C382.762 381.764 381.813 379.476 381.81 377.09V350.09C381.81 347.703 382.758 345.414 384.446 343.726C386.134 342.038 388.423 341.09 390.81 341.09C393.197 341.09 395.486 342.038 397.174 343.726C398.862 345.414 399.81 347.703 399.81 350.09V377.09C399.807 379.476 398.858 381.764 397.171 383.451C395.484 385.138 393.196 386.087 390.81 386.09V386.09Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M427.81 386.09C425.424 386.087 423.136 385.138 421.449 383.451C419.762 381.764 418.813 379.476 418.81 377.09V350.09C418.81 347.703 419.758 345.414 421.446 343.726C423.134 342.038 425.423 341.09 427.81 341.09C430.197 341.09 432.486 342.038 434.174 343.726C435.862 345.414 436.81 347.703 436.81 350.09V377.09C436.807 379.476 435.858 381.764 434.171 383.451C432.484 385.138 430.196 386.087 427.81 386.09V386.09Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M451.81 386.09C449.424 386.087 447.136 385.138 445.449 383.451C443.762 381.764 442.813 379.476 442.81 377.09V350.09C442.81 347.703 443.758 345.414 445.446 343.726C447.134 342.038 449.423 341.09 451.81 341.09C454.197 341.09 456.486 342.038 458.174 343.726C459.862 345.414 460.81 347.703 460.81 350.09V377.09C460.807 379.476 459.858 381.764 458.171 383.451C456.484 385.138 454.196 386.087 451.81 386.09V386.09Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M475.81 386.09C473.424 386.087 471.136 385.138 469.449 383.451C467.762 381.764 466.813 379.476 466.81 377.09V350.09C466.81 347.703 467.758 345.414 469.446 343.726C471.134 342.038 473.423 341.09 475.81 341.09C478.197 341.09 480.486 342.038 482.174 343.726C483.862 345.414 484.81 347.703 484.81 350.09V377.09C484.807 379.476 483.858 381.764 482.171 383.451C480.484 385.138 478.196 386.087 475.81 386.09V386.09Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M233.828 486.257H104.388C102.784 486.257 101.246 485.62 100.112 484.486C98.9775 483.351 98.3403 481.813 98.3403 480.209C98.3403 478.606 98.9775 477.067 100.112 475.933C101.246 474.799 102.784 474.162 104.388 474.162H233.828C235.432 474.162 236.97 474.799 238.104 475.933C239.238 477.067 239.875 478.606 239.875 480.209C239.875 481.813 239.238 483.351 238.104 484.486C236.97 485.62 235.432 486.257 233.828 486.257Z"
        fill="#E6E6E6"
      />
      <path
        d="M192.328 460.257H145.888C144.284 460.257 142.746 459.62 141.612 458.486C140.477 457.351 139.84 455.813 139.84 454.209C139.84 452.606 140.477 451.067 141.612 449.933C142.746 448.799 144.284 448.162 145.888 448.162H192.328C193.932 448.162 195.47 448.799 196.604 449.933C197.738 451.067 198.375 452.606 198.375 454.209C198.375 455.813 197.738 457.351 196.604 458.486C195.47 459.62 193.932 460.257 192.328 460.257V460.257Z"
        fill="#E6E6E6"
      />
      <path d="M564.81 277.09H71.8101V279.09H564.81V277.09Z" fill="#3F3D56" />
      <path
        d="M660.928 176.698L657.176 175.956C653.816 175.287 650.858 173.312 648.952 170.465C647.046 167.618 646.347 164.132 647.008 160.77L647.632 157.62C649.176 149.818 651.947 143.578 655.868 139.074C660.572 133.862 665.79 129.14 671.444 124.978C675.144 122.486 678.611 119.665 681.805 116.551C684.139 114.102 685.703 111.022 686.304 107.693C687.853 99.867 683.507 95.0531 673.018 92.977C667.186 91.8911 661.232 91.6145 655.325 92.1551C653.051 92.3435 650.764 92.0015 648.645 91.156C646.525 90.3106 644.631 88.9848 643.111 87.2829C641.591 85.581 640.486 83.5495 639.885 81.3484C639.283 79.1472 639.2 76.8364 639.643 74.5979L640.905 68.2216C641.436 65.4743 642.753 62.9399 644.695 60.9258C646.638 58.9117 649.123 57.5043 651.849 56.8742C662.539 54.6184 673.584 54.6312 684.269 56.9117C695.495 59.1338 705.257 63.0254 713.282 68.478C721.322 73.9423 727.217 80.5704 730.802 88.1784C734.382 95.7364 735.393 104.257 733.681 112.443C731.938 121.252 728.014 128.449 722.02 133.833C716.09 139.163 708.009 144.683 698.001 150.242C692.741 153.104 687.663 156.289 682.796 159.778C679.647 162.06 677.137 165.112 675.505 168.642C674.422 171.493 672.362 173.866 669.693 175.341C667.024 176.817 663.918 177.297 660.928 176.698V176.698Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M647.042 235.901L646.548 235.803C640.32 234.566 634.838 230.907 631.307 225.629C627.776 220.352 626.485 213.889 627.718 207.66C628.951 201.431 632.606 195.947 637.881 192.413C643.156 188.878 649.619 187.583 655.848 188.812L656.343 188.91C659.43 189.519 662.367 190.73 664.986 192.475C667.605 194.219 669.854 196.462 671.606 199.076C673.357 201.691 674.576 204.624 675.194 207.71C675.811 210.795 675.814 213.972 675.203 217.059C674.592 220.145 673.379 223.081 671.633 225.699C669.887 228.317 667.642 230.565 665.027 232.315C662.412 234.064 659.477 235.282 656.391 235.897C653.306 236.512 650.129 236.514 647.042 235.901Z"
        fill={theme.palette.secondary.main}
      />
    </svg>
  );
}
