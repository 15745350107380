/* eslint-disable react/jsx-props-no-spreading */
import { useTheme } from '@mui/material';

export default function Plan1Image(props: React.SVGAttributes<SVGSVGElement>) {
  const theme = useTheme();

  return (
    <svg
      width="123"
      height="108"
      viewBox="0 0 123 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_266_3431)">
        <path
          d="M106.084 64.6549H3.71865C2.89296 64.6539 2.10136 64.3256 1.51751 63.7418C0.933659 63.1581 0.605241 62.3667 0.604309 61.5412V3.83398H109.199V61.5412C109.198 62.3667 108.869 63.1581 108.286 63.7418C107.702 64.3256 106.91 64.6539 106.084 64.6549Z"
          fill="#E6E6E6"
        />
        <path
          d="M102.011 58.6948H7.79232C7.36696 58.6943 6.95917 58.5252 6.65839 58.2245C6.35762 57.9238 6.18844 57.516 6.18796 57.0908V11.398C6.18844 10.9727 6.35762 10.565 6.65839 10.2643C6.95917 9.96357 7.36696 9.79442 7.79232 9.79395H102.011C102.436 9.79442 102.844 9.96357 103.145 10.2643C103.445 10.565 103.615 10.9727 103.615 11.398V57.0908C103.615 57.516 103.445 57.9238 103.145 58.2245C102.844 58.5252 102.436 58.6943 102.011 58.6948Z"
          fill="white"
        />
        <path
          d="M109.417 5.93736H0.386383V3.43743C0.387316 2.61191 0.715733 1.82047 1.29958 1.23674C1.88343 0.653012 2.67504 0.324663 3.50073 0.32373H106.302C107.128 0.324663 107.92 0.653012 108.503 1.23674C109.087 1.82047 109.416 2.61191 109.417 3.43743V5.93736Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M30.0786 41.1997H13.3092C12.8838 41.1992 12.476 41.03 12.1753 40.7293C11.8745 40.4286 11.7053 40.0209 11.7048 39.5956V28.5738C11.7053 28.1485 11.8745 27.7408 12.1753 27.4401C12.476 27.1394 12.8838 26.9702 13.3092 26.9697H30.0786C30.504 26.9702 30.9118 27.1393 31.2125 27.4401C31.5133 27.7408 31.6825 28.1485 31.683 28.5738V39.5956C31.6825 40.0209 31.5133 40.4286 31.2125 40.7293C30.9118 41.03 30.504 41.1992 30.0786 41.1997Z"
          fill="#E6E6E6"
        />
        <path
          d="M97.2057 28.7541H44.2213C43.9847 28.7541 43.7577 28.6601 43.5903 28.4928C43.423 28.3255 43.329 28.0985 43.329 27.8619C43.329 27.6253 43.423 27.3984 43.5903 27.231C43.7577 27.0637 43.9847 26.9697 44.2213 26.9697H97.2057C97.4424 26.9697 97.6694 27.0637 97.8367 27.231C98.0041 27.3984 98.0981 27.6253 98.0981 27.8619C98.0981 28.0985 98.0041 28.3255 97.8367 28.4928C97.6694 28.6601 97.4424 28.7541 97.2057 28.7541Z"
          fill="#E6E6E6"
        />
        <path
          d="M97.2058 35.1363H44.2213C43.9847 35.1363 43.7577 35.0423 43.5904 34.875C43.423 34.7077 43.329 34.4808 43.329 34.2442C43.329 34.0076 43.423 33.7807 43.5904 33.6134C43.7577 33.446 43.9847 33.3521 44.2213 33.3521H97.2058C97.4424 33.3521 97.6694 33.446 97.8367 33.6134C98.0041 33.7807 98.0981 34.0076 98.0981 34.2442C98.0981 34.4808 98.0041 34.7077 97.8367 34.875C97.6694 35.0423 97.4424 35.1363 97.2058 35.1363Z"
          fill="#E6E6E6"
        />
        <path
          d="M97.2057 41.5187H44.2213C43.9847 41.5187 43.7577 41.4247 43.5903 41.2574C43.423 41.0901 43.329 40.8632 43.329 40.6266C43.329 40.3899 43.423 40.163 43.5903 39.9957C43.7577 39.8284 43.9847 39.7344 44.2213 39.7344H97.2057C97.4424 39.7344 97.6694 39.8284 97.8367 39.9957C98.0041 40.163 98.0981 40.3899 98.0981 40.6266C98.0981 40.8632 98.0041 41.0901 97.8367 41.2574C97.6694 41.4247 97.4424 41.5187 97.2057 41.5187Z"
          fill="#E6E6E6"
        />
        <path
          d="M79.5753 105.305H77.2612L76.1604 96.3809H79.5756L79.5753 105.305Z"
          fill="#A0616A"
        />
        <path
          d="M75.6084 104.644H80.0712V107.453H72.7986C72.7986 107.084 72.8712 106.719 73.0124 106.378C73.1537 106.037 73.3606 105.727 73.6215 105.466C73.8825 105.206 74.1922 104.999 74.5331 104.857C74.874 104.716 75.2394 104.644 75.6084 104.644Z"
          fill="#2F2E41"
        />
        <path
          d="M89.2014 105.305H86.8874L85.7866 96.3809H89.2018L89.2014 105.305Z"
          fill="#A0616A"
        />
        <path
          d="M85.2346 104.644H89.6973V107.453H82.4247C82.4247 107.084 82.4974 106.719 82.6386 106.378C82.7798 106.037 82.9868 105.727 83.2477 105.466C83.5086 105.206 83.8184 104.999 84.1593 104.857C84.5002 104.716 84.8656 104.644 85.2346 104.644Z"
          fill="#2F2E41"
        />
        <path
          d="M88.9068 103.254H86.5513C86.3385 103.252 86.1338 103.172 85.9773 103.028C85.8207 102.884 85.7236 102.686 85.7049 102.475L83.7087 78.4334C83.7029 78.3635 83.6713 78.2982 83.6201 78.2503C83.5689 78.2024 83.5017 78.1751 83.4316 78.1739C83.3614 78.1727 83.2933 78.1975 83.2405 78.2436C83.1876 78.2897 83.1537 78.3538 83.1454 78.4234L80.2785 102.505C80.2538 102.711 80.1544 102.901 79.9989 103.039C79.8435 103.177 79.6429 103.253 79.4351 103.254H76.5427C76.3184 103.253 76.1033 103.164 75.9443 103.006C75.7853 102.848 75.6952 102.633 75.6938 102.409C75.6874 100.896 75.6771 99.3727 75.6667 97.8488C75.575 84.2765 75.48 70.2422 78.3146 64.7441L78.3405 64.6939L88.5692 64.519L88.5949 64.5753C91.6896 71.3367 90.7503 86.4419 89.8419 101.05L89.7545 102.457C89.7408 102.673 89.6455 102.875 89.4881 103.023C89.3307 103.171 89.1229 103.253 88.9068 103.254Z"
          fill="#2F2E41"
        />
        <path
          d="M84.061 46.449C86.6213 46.449 88.6968 44.3739 88.6968 41.8141C88.6968 39.2543 86.6213 37.1792 84.061 37.1792C81.5007 37.1792 79.4251 39.2543 79.4251 41.8141C79.4251 44.3739 81.5007 46.449 84.061 46.449Z"
          fill="#A0616A"
        />
        <path
          d="M77.7408 65.5459L77.8685 60.2318L76.9489 56.0856C76.7166 55.0589 76.7302 53.9918 76.9885 52.9714C77.2468 51.9509 77.7425 51.0059 78.4353 50.2133C79.1094 49.4246 79.9603 48.8064 80.9188 48.4091C81.8772 48.0118 82.916 47.8466 83.9504 47.927H83.9505C84.0173 47.9322 84.0842 47.9378 84.1513 47.9438C85.0352 48.0234 85.894 48.28 86.6765 48.6985C87.459 49.1169 88.1493 49.6886 88.7061 50.3795C89.2745 51.0743 89.6972 51.8763 89.949 52.7379C90.2008 53.5994 90.2764 54.5029 90.1715 55.3943L89.059 65.116L77.7408 65.5459Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M73.8028 75.07C74.0215 74.8993 74.2001 74.6827 74.326 74.4355C74.4519 74.1883 74.5221 73.9165 74.5316 73.6392C74.5411 73.362 74.4897 73.086 74.3809 72.8308C74.2722 72.5755 74.1088 72.3472 73.9023 72.162L76.5124 65.9438L73.0676 66.5907L71.1172 72.3963C70.8289 72.7638 70.6854 73.2243 70.714 73.6905C70.7425 74.1567 70.9411 74.5962 71.2722 74.9258C71.6032 75.2554 72.0437 75.4521 72.5101 75.4787C72.9765 75.5052 73.4364 75.3599 73.8028 75.07Z"
          fill="#A0616A"
        />
        <path
          d="M93.4974 75.8153C93.2888 75.6325 93.1228 75.406 93.0112 75.1521C92.8995 74.8981 92.8449 74.6227 92.8512 74.3454C92.8575 74.0681 92.9246 73.7955 93.0476 73.5468C93.1707 73.2982 93.3468 73.0796 93.5635 72.9063L91.3116 66.5498L94.7139 67.3915L96.3308 73.2988C96.5978 73.6821 96.7148 74.15 96.6598 74.6138C96.6047 75.0776 96.3814 75.5051 96.0322 75.8153C95.6829 76.1255 95.232 76.2968 94.7648 76.2969C94.2976 76.2969 93.8467 76.1255 93.4974 75.8153Z"
          fill="#A0616A"
        />
        <path
          d="M74.2171 72.0232C74.0975 72.0232 73.9792 71.998 73.87 71.9491L71.8842 71.0597C71.6931 70.9745 71.5404 70.8216 71.4552 70.6305C71.3701 70.4394 71.3585 70.2237 71.4229 70.0246L74.7538 59.6797L76.0411 51.8861C76.0869 51.6091 76.1879 51.3443 76.3381 51.1072C76.4883 50.8701 76.6847 50.6656 76.9156 50.5059C77.1465 50.3463 77.4072 50.2346 77.6821 50.1777C77.957 50.1208 78.2405 50.1198 78.5158 50.1747C78.7911 50.2296 79.0526 50.3393 79.2846 50.4972C79.5167 50.6552 79.7146 50.8582 79.8665 51.0942C80.0185 51.3302 80.1215 51.5943 80.1693 51.8709C80.2171 52.1475 80.2087 52.4308 80.1447 52.7041L78.1883 61.0624L75.0296 71.4217C74.9766 71.5958 74.869 71.7483 74.7227 71.8566C74.5764 71.9649 74.3991 72.0233 74.2171 72.0232V72.0232Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M93.1603 72.0233C92.9783 72.0234 92.801 71.965 92.6547 71.8567C92.5084 71.7483 92.4008 71.5959 92.3478 71.4217L89.1908 61.0684L87.2327 52.7042C87.1687 52.4309 87.1604 52.1475 87.2082 51.8709C87.2559 51.5944 87.3589 51.3302 87.5109 51.0942C87.6628 50.8582 87.8607 50.6552 88.0928 50.4972C88.3248 50.3393 88.5863 50.2296 88.8616 50.1747C89.1369 50.1198 89.4205 50.1208 89.6954 50.1777C89.9703 50.2347 90.2309 50.3463 90.4618 50.5059C90.6927 50.6656 90.8891 50.8701 91.0393 51.1072C91.1896 51.3443 91.2906 51.6092 91.3363 51.8861L92.6269 59.6934L95.9546 70.0247C96.0189 70.2237 96.0074 70.4395 95.9222 70.6306C95.8371 70.8217 95.6843 70.9745 95.4932 71.0598L93.5075 71.9492C93.3983 71.998 93.28 72.0233 93.1603 72.0233Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M84.1563 36.6906C82.868 36.1326 81.1849 36.8269 80.6649 38.1309C80.3413 38.9424 80.4401 39.8577 80.6185 40.713C80.7969 41.5683 81.0512 42.4218 81.0239 43.295C80.9966 44.1682 80.6204 45.095 79.8456 45.499C79.3826 45.7404 78.8411 45.7672 78.3392 45.9112C77.8373 46.0551 77.3193 46.3917 77.247 46.9087C77.1377 47.6908 78.0866 48.2865 78.0802 49.0762C78.0735 49.9129 77.0277 50.4098 76.8921 51.2355C76.8688 51.4773 76.9072 51.721 77.0039 51.9438C77.1006 52.1666 77.2524 52.3612 77.445 52.5093C77.8333 52.8009 78.2746 53.0145 78.7443 53.1382C82.3585 54.2931 86.2067 54.5124 89.9287 53.7753C90.6683 53.6278 91.4294 53.4284 92.0041 52.9402C92.5787 52.4519 92.9074 51.5972 92.5771 50.9194C92.0819 49.9032 90.5097 49.8453 89.9617 48.8566C89.6331 48.2635 89.7844 47.5354 89.8536 46.8609C90.0323 45.119 90.4446 43.0841 90.0118 41.3874C89.6208 39.8545 88.7716 37.8736 87.5581 36.8584C86.6873 36.1299 85.0248 36.4618 83.9118 36.6866L84.1563 36.6906Z"
          fill="#2F2E41"
        />
        <path
          d="M121.839 107.71H55.777C55.7269 107.71 55.6789 107.69 55.6435 107.655C55.6081 107.619 55.5882 107.571 55.5882 107.521C55.5882 107.471 55.6081 107.423 55.6435 107.388C55.6789 107.352 55.7269 107.333 55.777 107.333H121.839C121.889 107.333 121.937 107.352 121.972 107.388C122.008 107.423 122.028 107.471 122.028 107.521C122.028 107.571 122.008 107.619 121.972 107.655C121.937 107.69 121.889 107.71 121.839 107.71Z"
          fill="#CCCCCC"
        />
        <path
          d="M114.883 103.413C112.161 107.057 106.511 107.441 106.511 107.441C106.511 107.441 105.274 101.916 107.996 98.2719C110.718 94.6277 116.367 94.2441 116.367 94.2441C116.367 94.2441 117.604 99.7689 114.883 103.413Z"
          fill="#E6E6E6"
        />
        <path
          d="M101.018 104.783C102.814 107.187 106.542 107.441 106.542 107.441C106.542 107.441 107.358 103.795 105.562 101.39C103.766 98.9855 100.038 98.7324 100.038 98.7324C100.038 98.7324 99.222 102.378 101.018 104.783Z"
          fill="#E6E6E6"
        />
      </g>
      <defs>
        <clipPath id="clip0_266_3431">
          <rect
            width="121.641"
            height="107.386"
            fill="white"
            transform="translate(0.386383 0.32373)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
