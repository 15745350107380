import { LogoType } from '.';

import logoConlicitacao from '../../assets/images/conlicitacao/conlicitaLogo.png';

interface LogoConlicitacaoProps {
  type: LogoType;
}

const styles = {
  logOn: {
    width: '8rem',
    height: '2rem',
    alignSelf: 'center',
    marginLeft: '0',
    marginTop: '.7rem',
  },

  logOff: {
    width: '8rem',
    height: '90%',
  },
};

export default function LogoConlicitacao({ type }: LogoConlicitacaoProps) {
  return (
    <img style={styles[type]} src={logoConlicitacao} alt="Logo do DattaSign" />
  );
}
