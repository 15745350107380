/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import SvgIcon from '@mui/material/SvgIcon';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { Theme, useTheme } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';

import CustomVerificationCodeInput from '../../../components/CustomVerificationCodeInput';
import LoadingBackdrop from '../../../components/LoadingBackdrop';
import ConfirmYourDataImage from '../../../components/Images/ConfirmYourDataImage';
import TokenImage from '../../../components/Images/TokenImage';

import useDialogContext from '../../../hooks/useDialogContext';
import useAuthContext from '../../../hooks/useAuthContext';

import { AuthenticationType } from '../../../types/AuthenticationType';

import api from '../../../services/api';

import COLORS from '../../../utils/colors';
import { phoneMask } from '../../../utils/masks';

import { ReactComponent as Sign } from '../../../assets/images/sign.svg';

const useStyles = makeStyles((theme: Theme) => ({
  buttonSign: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: COLORS.white,
    fontSize: '0.875rem',
    height: '2.25rem',
    width: '10rem',
    padding: 0,
    fontWeight: 700,
    textTransform: 'none',

    '& .MuiSvgIcon-root ': {
      fontSize: '1.2rem',
    },

    '&:hover': {
      backgroundColor: `${theme.palette.primary.light} !important`,
      transition: '0.3s',
    },
  },

  titleContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 24px 0',
  },

  title: {
    color: COLORS.black,
    textAlign: 'right',
    '& .MuiSvgIcon-root ': {
      fontSize: '1.25rem',
      cursor: 'pointer',

      '&:hover': {
        color: theme.palette.primary.light,
        transition: '0.3s',
      },
    },
  },

  containerConfirmYourData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    rowGap: '2rem',
    width: '100%',
    maxWidth: '60vh',
    overflowX: 'hidden',
    height: '93%',
    padding: '2rem',
    minWidth: '30rem',
  },

  contentConfirmYourData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    rowGap: '2.5rem',
  },

  confirmYourDataImage: {
    height: '10rem',
  },

  field: {
    width: '100%',
  },

  fieldAndMessage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    gap: '.5rem',
  },

  buttonNext: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    textTransform: 'none',
    padding: 0,
    color: COLORS.white,
    width: '7rem',
    height: '2.5rem',
    marginBottom: '1rem',

    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      opacity: '0.9',
      transition: '0.3s',
    },
  },

  containerVerificationCode: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    rowGap: '2rem',
    width: '100%',
    height: '93%',
    padding: '0 2rem 2rem',
  },

  contentVerificationCode: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '60vh',
    rowGap: '2rem',
  },

  tokenImage: {
    height: '10rem',
  },

  descriptionVerificationCode: {
    color: COLORS.gray1,
    fontSize: '1rem',
    lineHeight: '2rem',
    textAlign: 'justify',
    maxWidth: '90%',
  },

  bottomVerificationCode: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '90%',
  },

  resendCodeContainer: {
    display: 'flex',
    flexDirection: 'column',
    color: COLORS.mediumGray4,
  },

  buttonResendCode: {
    color: `${theme.palette.primary.main} !important`,
    padding: 0,

    '&:hover': {
      opacity: 0.9,
      backgroundColor: '#FFFFFF !important',
    },
  },

  buttonFinish: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    textTransform: 'none',
    padding: 0,
    color: COLORS.white,
    width: '7rem',
    height: '2.25rem',

    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      opacity: '0.9',
      transition: '0.3s',
    },
  },
}));
interface Props {
  fileId: string;
  updateDocument(): void;
  informedName: string;
  authenticationType: AuthenticationType;
  sendTokenTo: string;
}

const ModalSignWithToken: React.FC<Props> = ({
  fileId,
  updateDocument,
  informedName,
  authenticationType,
  sendTokenTo,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { costCenter } = useAuthContext();

  const [open, setOpen] = useState(false);
  const [isConfirmingYourData, setIsConfirmingYourData] = useState(true);
  const [buttonFinishIsDisabled, setButtonFinishIsDisabled] = useState(true);
  const [isFinishing, setIsFinishing] = useState(false);
  const [isSendingVerificationCode, setIsSendingVerificationCode] =
    useState(false);

  const [name, setName] = useState(informedName);
  const [verificationCode, setVerificationCode] = useState('');

  const hasPhone =
    authenticationType === 'sms' || authenticationType === 'whatsapp';
  const sendTokenToFormatted = hasPhone ? phoneMask(sendTokenTo) : sendTokenTo;

  const { showDialog } = useDialogContext();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setIsConfirmingYourData(true);
    }, 300);
  };

  const handleTransitionBetweenConfirmAndFinish = () => {
    setOpen(false);

    setTimeout(() => {
      setIsConfirmingYourData(false);
      setOpen(true);
    }, 500);
  };

  const handleChange = (newValue: string) => {
    if (newValue.length < 6 && !buttonFinishIsDisabled) {
      setButtonFinishIsDisabled(true);
    }

    if (newValue.length === 6) {
      setButtonFinishIsDisabled(false);
    }
    setVerificationCode(newValue.toUpperCase());
  };

  const sendVerificationCode = async (isNexting: boolean) => {
    setIsSendingVerificationCode(true);

    try {
      await api.post(`documento/assinar/token/${costCenter?.id}`, {
        name,
        documentId: fileId,
      });

      setIsSendingVerificationCode(false);

      if (isNexting) {
        handleTransitionBetweenConfirmAndFinish();
      }

      if (!isNexting) {
        showDialog(
          'success',
          `Token foi enviado para o ${
            hasPhone ? 'telefone' : 'e-mail'
          } ${sendTokenToFormatted} `,
        );
      }
    } catch (error: any) {
      setIsSendingVerificationCode(false);
      if (!error.response) {
        showDialog(
          'default',
          'Erro inesperado. Por favor, tente novamente mais tarde!',
        );

        return;
      }

      showDialog('default', error.response.data.message);
    }
  };

  const handleFinish = async () => {
    setIsFinishing(true);

    try {
      await api.post(`documento/assinar/token/finalizar/${costCenter?.id}`, {
        signatureToken: verificationCode,
        name,
        documentId: fileId,
        signatureType: authenticationType,
      });

      showDialog(
        authenticationType === 'assinatura_digital'
          ? 'success'
          : 'signedDocument',
        authenticationType === 'assinatura_digital'
          ? 'O token foi confirmado!'
          : 'O documento foi assinado com sucesso!',
      );
      handleClose();
      updateDocument();
    } catch (error: any) {
      if (!error.response) {
        showDialog(
          'default',
          'Erro inesperado. Por favor, tente novamente mais tarde!',
        );
        setIsFinishing(false);

        return;
      }

      showDialog(
        'default',
        typeof error.response.data.message === 'string'
          ? error.response.data.message
          : JSON.stringify(error.response.data.message),
      );
    }
    setIsFinishing(false);
  };

  return (
    <>
      <Tooltip title="Assinar documento" placement="top">
        <Button
          className={classes.buttonSign}
          type="button"
          endIcon={<SvgIcon component={Sign} />}
          onClick={() => {
            handleOpen();
          }}
        >
          Assinar
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        onClose={
          !isConfirmingYourData || !isSendingVerificationCode
            ? handleClose
            : undefined
        }
      >
        <div className={classes.titleContainer}>
          <DialogTitle className={classes.title} sx={{ padding: '0' }}>
            Confirme seus dados
          </DialogTitle>
          <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
        </div>

        {isConfirmingYourData ? (
          <div className={classes.containerConfirmYourData}>
            <ConfirmYourDataImage className={classes.confirmYourDataImage} />

            <div className={classes.contentConfirmYourData}>
              <div className={classes.fieldAndMessage}>
                <TextField
                  autoFocus
                  label="Nome completo"
                  variant="standard"
                  type="text"
                  className={classes.field}
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
                {!name && (
                  <span style={{ fontSize: '.8rem', color: COLORS.redWarning }}>
                    Preencha seu nome completo para prosseguir com a assinatura.
                  </span>
                )}
              </div>
              {isSendingVerificationCode ? (
                <LoadingButton
                  sx={{ marginBottom: '1.5rem', color: COLORS.white }}
                  className={classes.buttonNext}
                  loading
                  loadingIndicator={
                    <CircularProgress size={20} sx={{ color: COLORS.white }} />
                  }
                  loadingPosition="center"
                />
              ) : (
                <Button
                  variant="contained"
                  disableElevation
                  endIcon={<ArrowForwardIcon />}
                  disabled={!name}
                  sx={{ marginBottom: '1.5rem' }}
                  className={classes.buttonNext}
                  onClick={() => {
                    sendVerificationCode(true);
                  }}
                >
                  Avançar
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div className={classes.containerVerificationCode}>
            <TokenImage className={classes.tokenImage} />

            <div className={classes.contentVerificationCode}>
              <h1 style={{ fontSize: '1.25rem' }}>Autentique sua assinatura</h1>
              <p className={classes.descriptionVerificationCode}>
                Proteger sua assinatura é nossa maior prioridade. Por favor,
                confirme sua conta digitando o código de autorização enviado
                para{' '}
                <span
                  style={{
                    color: theme.palette.primary.main,
                  }}
                >
                  {sendTokenToFormatted}
                </span>
              </p>

              <CustomVerificationCodeInput onChange={handleChange} />

              <div className={classes.bottomVerificationCode}>
                <section className={classes.resendCodeContainer}>
                  <span style={{ fontSize: '.925rem' }}>
                    Pode levar um minuto para receber seu código.
                  </span>
                  <span style={{ fontSize: '.925rem' }}>
                    Não recebeu?{' '}
                    <Button
                      className={classes.buttonResendCode}
                      sx={{ textTransform: 'none', fontSize: '.925rem' }}
                      onClick={() => {
                        sendVerificationCode(false);
                      }}
                    >
                      Reenvie um novo código.
                    </Button>
                  </span>
                </section>

                {isFinishing ? (
                  <LoadingButton
                    sx={{ color: COLORS.white }}
                    className={classes.buttonFinish}
                    loading
                    loadingIndicator={
                      <CircularProgress
                        size={20}
                        sx={{ color: COLORS.white }}
                      />
                    }
                    loadingPosition="center"
                  />
                ) : (
                  <Button
                    variant="contained"
                    disableElevation
                    endIcon={<CheckIcon />}
                    className={classes.buttonFinish}
                    onClick={handleFinish}
                    disabled={buttonFinishIsDisabled}
                  >
                    Finalizar
                  </Button>
                )}
              </div>
            </div>

            <LoadingBackdrop
              color="primary.main"
              open={isSendingVerificationCode}
            />
          </div>
        )}
      </Dialog>
    </>
  );
};

export default ModalSignWithToken;
