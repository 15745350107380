/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import styled from '@mui/styles/styled';
import PersonIcon from '@mui/icons-material/Person';
import EngineeringIcon from '@mui/icons-material/Engineering';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';

import LoadingBtn from '../../components/LoadingButton';
import ActionButton from '../../components/ActionButton';

import useWhiteLabel from '../../hooks/useWhiteLabel';

import api from '../../services/api';

import COLORS from '../../utils/colors';
import { cpfMask, cnpjMask, phoneMask } from '../../utils/masks';

const OuterCard = styled(Card)({
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  overflow: 'auto !important',
});

const masks = {
  cpf: cpfMask,
  cnpj: cnpjMask,
  telephone: phoneMask,
};

interface FormValues {
  email: string;
  name: string;
  cpf?: string;
  cnpj?: string;
  birthday?: string;
  telephone: string;
  password: string;
  password_confirmation?: string;
  acceptance?: boolean;
}

type Fields =
  | 'email'
  | 'password'
  | 'name'
  | 'password_confirmation'
  | 'cpf'
  | 'cnpj'
  | 'telephone'
  | 'birthday'
  | 'acceptance';

type RegisterType =
  | 'normal'
  | 'create_password'
  | 'courtesy'
  | 'trafego_pago'
  | 'mail_marketing';

export default function RegisterCard() {
  const theme = useTheme();
  const navigate = useNavigate();

  const { token } = useParams();
  const { whiteLabel } = useWhiteLabel();

  const [personType, setPersonType] = useState<'pf' | 'pj'>('pf');

  const [isLoading, setIsLoading] = useState(false);

  const [registerForm, setRegisterForm] = useState({
    name: '',
    cpf: '',
    cnpj: '',
    telephone: '',
    email: '',
    password: '',
    password_confirmation: '',
    birthday: '',
    acceptance: false,
  });

  const [registerType, setRegisterType] = useState<RegisterType>('normal');
  const [registerToken, setRegisterToken] = useState('');

  const {
    register,
    handleSubmit,
    watch,
    unregister,
    clearErrors,
    formState: { errors },
    setValue,
    setError,
  } = useForm<FormValues>();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  /* for repeat password */
  const password = useRef({});
  password.current = watch('password', '');

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const onSubmit: SubmitHandler<FormValues> = async () => {
    setIsLoading(true);

    const registerData: FormValues = { ...registerForm };

    const fieldsToHide = personType === 'pf' ? ['cnpj'] : ['cpf', 'birthday'];

    fieldsToHide.forEach(field => {
      const key = field as 'cpf' | 'cnpj' | 'birthday';
      delete registerData[key];
    });

    delete registerData.acceptance;

    if (registerData.birthday) {
      registerData.birthday = moment(registerData.birthday).format(
        'YYYY-MM-DD',
      );
    }

    try {
      await api.post(
        '/register',
        {
          ...registerData,
          ...(registerType === 'create_password' ||
          registerType.startsWith('courtesy') ||
          registerType === 'trafego_pago' ||
          registerType === 'mail_marketing'
            ? { token: registerToken }
            : {}),
        },
        {
          ...(whiteLabel === 'codata' && process.env.REACT_APP_CODATA_TOKEN
            ? {
                headers: {
                  Token: process.env.REACT_APP_CODATA_TOKEN,
                },
              }
            : {}),
        },
      );

      navigate('/register_success');
      localStorage.removeItem('@dattasign:registerToken');
      setIsLoading(false);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setIsLoading(false);
      if (!error.response) {
        return;
      }

      if (error.response.status === 500) {
        setError('acceptance', {
          message:
            'Não foi possível alterar as informações. Tente novamente mais tarde!',
        });
        return;
      }

      const errorMessage = error.response.data.message;

      Object.keys(errorMessage).forEach(key => {
        const field = key as Fields;
        setError(field, {
          message: errorMessage[field][0],
        });
      });
    }
  };

  const handleRegisterFormChanges = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const mask = masks[e.target.name as 'cpf' | 'cnpj' | 'telephone'];

    setRegisterForm({
      ...registerForm,
      [e.target.name]: mask ? mask(e.target.value) : e.target.value,
    });
  };

  const handleAcceptanceChange = () => {
    setRegisterForm({ ...registerForm, acceptance: true });
  };

  const handleDateChange = (newDate: moment.Moment | null) => {
    setRegisterForm({
      ...registerForm,
      birthday: moment(newDate).toString(),
    });
  };

  function handlePersonType(person: 'pf' | 'pj') {
    setPersonType(person);

    setRegisterForm({
      name: '',
      cpf: '',
      cnpj: '',
      telephone: '',
      email: '',
      password: '',
      password_confirmation: '',
      birthday: '',
      acceptance: false,
    });
  }

  function specificFormRender() {
    if (personType === 'pf') {
      return (
        <>
          <FormControl>
            <TextField
              id="register_cpf"
              color="warning"
              label="CPF (digite apenas os números)"
              type="text"
              variant="standard"
              style={{ fontSize: '.9rem', width: '100%' }}
              {...register('cpf', {
                required: 'Por favor, informe o CPF',
                minLength: {
                  value: 14,
                  message: 'O CPF deve conter 11 dígitos',
                },
                pattern: {
                  value: /^[\d./-]{14}$/,
                  message: 'CPF inválido',
                },
              })}
              value={registerForm.cpf}
              onChange={handleRegisterFormChanges}
              error={Boolean(errors.cpf)}
              helperText={errors.cpf?.message}
            />
          </FormControl>
          <FormControl>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                label="Data de nascimento"
                value={registerForm.birthday}
                inputFormat="L"
                mask="__/__/____"
                disableFuture
                onChange={handleDateChange}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    color="warning"
                    {...register('birthday', {
                      required: 'Por favor, informe sua data de nascimento',
                    })}
                    value={registerForm.birthday}
                    error={Boolean(errors.birthday)}
                    helperText={errors.birthday?.message}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </>
      );
    }

    if (personType === 'pj') {
      return (
        <FormControl>
          <TextField
            id="register_cnpj"
            label="CNPJ (Digite apenas os números)"
            type="text"
            variant="standard"
            style={{ fontSize: '.9rem', width: '100%' }}
            {...register('cnpj', {
              required: 'Por favor, informe o CNPJ',
              minLength: {
                value: 18,
                message: 'O CNPJ deve conter 14 números',
              },
            })}
            value={registerForm.cnpj}
            onChange={handleRegisterFormChanges}
            error={Boolean(errors.cnpj)}
            helperText={errors.cnpj?.message}
          />
        </FormControl>
      );
    }

    return null;
  }

  // clean errors when react hook form can't see state value change (browser autofill and mui datepicker)
  useEffect(() => {
    if (registerForm.name) {
      unregister('name');
    }
    if (
      registerForm.email &&
      registerType !== 'create_password' &&
      registerType.startsWith('courtesy')
    ) {
      unregister('email');
    }
    if (registerForm.birthday) {
      unregister('birthday');
    }
  }, [registerForm.name, registerForm.email, registerForm.birthday]);

  useEffect(() => {
    if (token) {
      localStorage.setItem('@dattasign:registerToken', token);
      setRegisterToken(token);
      return;
    }

    const localToken = localStorage.getItem('@dattasign:registerToken');
    if (localToken) {
      setRegisterToken(localToken);
    }
  }, []);

  useEffect(() => {
    if (registerToken) {
      api
        .get(`register_token/${registerToken}`)
        .then(response => {
          const { name, email, type } = response.data;

          setRegisterType(type);

          setRegisterForm({
            ...registerForm,
            name,
            email,
          });

          setValue('email', email);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [registerToken]);

  return (
    <OuterCard
      elevation={0}
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        gap: '2rem',
        alignItems: 'center',
        height: '100%',
        margin: {
          mobile: '0',
          tablet: '1rem auto',
          laptop: '0',
        },
        overflow: 'auto !important',
        width: {
          mobile: '100%',
          tablet: '70%',
          laptop: '50%',
          largedesktop: '35%',
        },
        padding: {
          mobile: '1.5rem',
          tablet: '2.5rem',
          laptop: '0 2.5rem',
          desktop: '0 3rem',
        },
        [theme.breakpoints.between(400, 640)]: {
          padding: '2.5rem',
        },
        [theme.breakpoints.between(640, 900)]: {
          padding: '2.5rem 3.5rem',
        },
        [theme.breakpoints.between(900, 1023)]: {
          padding: '2.5rem 6rem',
        },
        [theme.breakpoints.between(1380, 1680)]: {
          padding: '0 6rem',
        },
      }}
    >
      <Typography
        sx={{
          fontSize: '1.4rem',
          margin: {
            laptop: '1.5rem 0 0 0',
            desktop: '2rem 0 0 0',
          },
          fontWeight: 'bold',
          color: COLORS.gray1,
        }}
      >
        Cadastrar
      </Typography>

      <form
        noValidate
        id="formulario"
        method="post"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          width: '100%',
          marginTop: '1rem',
          gap: '10px',
          [theme.breakpoints.down('laptop')]: {
            overflow: 'auto',
          },
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack
          sx={{
            width: '100%',
            gap: '5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: {
              mobile: 'column',
              tablet: 'row',
            },
          }}
        >
          <FormControl
            sx={{
              width: {
                mobile: '100%',
                tablet: '50% ',
              },
              flexDirection: 'column',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              disabled={registerType === 'create_password'}
              onClick={() => {
                handlePersonType('pf');
                clearErrors();
              }}
              color="warning"
              sx={{
                textTransform: 'capitalize',
                width: {
                  mobile: '100%',
                },
                [theme.breakpoints.down('desktop')]: {
                  padding: '6px 3px',
                },
                [theme.breakpoints.between(1280, 1470)]: {
                  padding: '6px 8px',
                },
                ...(personType === 'pf'
                  ? {
                      backgroundColor: 'primary.main',
                      '&:hover': { backgroundColor: 'primary.main' },
                    }
                  : { backgroundColor: 'unset' }),
              }}
            >
              <Card
                sx={{
                  margin: '-.2rem -.3rem',
                  width: {
                    mobile: '100%',
                    desktop: '16rem',
                  },
                  height: {
                    tablet: 'auto',
                    desktop: '6rem',
                  },
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  ...(personType === 'pf'
                    ? {
                        color: 'primary.main',
                        boxShadow: 'none',
                      }
                    : { color: COLORS.mediumGray4 }),
                }}
              >
                <PersonIcon
                  sx={{
                    width: '3rem',
                    height: '3rem',
                    marginTop: '1rem',
                    color:
                      personType === 'pf' ? 'primary.main' : COLORS.mediumGray4,
                  }}
                />
                <Typography
                  style={{ fontSize: '.9rem', margin: '.5rem 0 1rem 0' }}
                >
                  Pessoa Física
                </Typography>
              </Card>
            </Button>
          </FormControl>

          <FormControl
            sx={{
              width: {
                mobile: '100%',
                tablet: '50% ',
              },
              flexDirection: 'column',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              disabled={registerType === 'create_password'}
              onClick={() => {
                handlePersonType('pj');
                clearErrors();
              }}
              color="warning"
              sx={{
                textTransform: 'capitalize',
                width: {
                  mobile: '100%',
                },
                [theme.breakpoints.down('desktop')]: {
                  padding: '6px 3px',
                },
                [theme.breakpoints.between(1280, 1470)]: {
                  padding: '6px 8px',
                },
                [theme.breakpoints.up('largedesktop')]: {
                  padding: '6px 8px 6px 7px',
                },
                ...(personType === 'pj'
                  ? {
                      backgroundColor: 'primary.main',
                      '&:hover': { backgroundColor: 'primary.main' },
                    }
                  : { backgroundColor: 'unset' }),
              }}
            >
              <Card
                sx={{
                  margin: '-.2rem -.3rem',
                  padding: '.4rem',
                  width: {
                    mobile: '100%',
                    desktop: '16rem',
                  },
                  height: {
                    tablet: 'auto',
                    desktop: '6rem',
                  },
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  ...(personType === 'pj'
                    ? { color: 'primary.main', boxShadow: 'none' }
                    : { color: COLORS.mediumGray4 }),
                }}
              >
                <EngineeringIcon
                  sx={{
                    width: '3rem',
                    height: '3rem',
                    marginTop: '1rem',
                    color:
                      personType === 'pj' ? 'primary.main' : COLORS.mediumGray4,
                  }}
                />

                <Typography
                  style={{ fontSize: '.9rem', margin: '.5rem 0 1rem 0' }}
                >
                  Pessoa Jurídica
                </Typography>
              </Card>
            </Button>
          </FormControl>
        </Stack>

        <FormControl>
          <TextField
            autoFocus
            id="register_name"
            label="Nome"
            type="text"
            variant="standard"
            sx={{
              '.MuiInput-root': {
                backgroundColor: 'transparent !important',
              },
            }}
            style={{ fontSize: '.9rem', marginTop: '.5rem', width: '100%' }}
            {...register('name', {
              required: 'Por favor, informe seu nome',
              minLength: {
                value: 10,
                message: 'O nome deve conter pelo menos 10 caracteres',
              },
              pattern: {
                value: /^[A-zÀ-ú ']+$/,
                message: 'Por favor, informe um nome válido',
              },
            })}
            value={registerForm.name}
            onChange={handleRegisterFormChanges}
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
          />
        </FormControl>

        <FormControl>
          <TextField
            disabled={registerType === 'create_password'}
            id="register_email"
            label="Email"
            type="email"
            variant="standard"
            style={{ fontSize: '.9rem', width: '100%' }}
            {...register('email', {
              required: 'Por favor, informe o email',
              pattern: {
                value:
                  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                message: 'Por favor, informe um email válido',
              },
            })}
            value={registerForm.email}
            onChange={handleRegisterFormChanges}
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
          />
        </FormControl>

        {specificFormRender()}

        <FormControl>
          <TextField
            id="register_telefone"
            label="Telefone (digite apenas os números)"
            type="text"
            variant="standard"
            style={{ fontSize: '.9rem', width: '100%' }}
            {...register('telephone', {
              required: 'Por favor, informe o telefone',
              minLength: {
                value: 14,
                message: 'O telefone deve conter ao menos 10 dígitos',
              },
              maxLength: {
                value: 15,
                message: 'O telefone deve conter 11 dígitos',
              },
            })}
            value={registerForm.telephone}
            onChange={handleRegisterFormChanges}
            error={Boolean(errors.telephone)}
            helperText={errors.telephone?.message}
          />
        </FormControl>

        <FormControl>
          <TextField
            id="register_password"
            label="Senha"
            type={showPassword ? 'text' : 'password'}
            variant="standard"
            style={{ fontSize: '.9rem', width: '100%' }}
            {...register('password', {
              required: 'Por favor, forneça uma senha',
              pattern: {
                value: /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                message:
                  'A senha deve ter ao menos 8 caracteres, dentre eles uma letra maiúscula e outra minúscula',
              },
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={showPassword ? 'Esconder senha' : 'Mostrar senha'}
                  >
                    <IconButton onClick={handleClickShowPassword}>
                      {showPassword ? (
                        <VisibilityIcon sx={{ color: 'primary.main' }} />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            value={registerForm.password}
            onChange={handleRegisterFormChanges}
            error={Boolean(errors.password)}
            helperText={errors.password?.message}
          />
        </FormControl>

        <FormControl>
          <TextField
            id="registerFormInput"
            variant="standard"
            type={showConfirmPassword ? 'text' : 'password'}
            {...register('password_confirmation', {
              required: 'Por favor, confirme sua senha',
              validate: value =>
                value === password.current || 'As senhas não coincidem',
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={
                      showConfirmPassword ? 'Esconder senha' : 'Mostrar senha'
                    }
                  >
                    <IconButton onClick={handleClickShowConfirmPassword}>
                      {showConfirmPassword ? (
                        <VisibilityIcon sx={{ color: 'primary.main' }} />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            value={registerForm.password_confirmation}
            onChange={handleRegisterFormChanges}
            label="Confirme a senha"
            error={Boolean(errors.password_confirmation)}
            helperText={errors.password_confirmation?.message}
          />
        </FormControl>

        <FormControl>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: '6px',
              marginTop: '.5rem',
            }}
          >
            <Checkbox
              {...register('acceptance', {
                required: 'É preciso concordar para continuar',
              })}
              value={registerForm.acceptance}
              onChange={handleAcceptanceChange}
              sx={{
                color: errors.acceptance ? COLORS.redWarning : 'primary.main',
                padding: 0,
                margin: 0,
                '&.Mui-checked': {
                  color: 'primary.main',
                },
                '& .MuiSvgIcon-root': {
                  fontSize: 16,
                },
              }}
            />
            <FormHelperText
              sx={{
                color: errors.acceptance ? COLORS.redWarning : null,
                margin: '0',
              }}
            >
              Concordar com{' '}
              <a
                href="https://dattasign.com.br/termos_e_condicoes.html"
                target="_blank"
                rel="noreferrer"
                style={{ color: theme.palette.primary.main }}
              >
                Termos de Serviço
              </a>{' '}
              e{' '}
              <a
                href="https://dattasign.com.br/politica_de_privacidade.html"
                target="_blank"
                rel="noreferrer"
                style={{ color: theme.palette.primary.main }}
              >
                Política de Privacidade
              </a>
            </FormHelperText>
          </div>
          <div>
            {errors.acceptance && (
              <FormHelperText error>{errors.acceptance.message}</FormHelperText>
            )}
          </div>
        </FormControl>

        {isLoading ? (
          <LoadingBtn text="Cadastrar" />
        ) : (
          <ActionButton type="submit" text="Cadastrar" />
        )}

        <Typography
          style={{
            fontSize: '.825rem',
            margin: '-.7rem auto 0 auto',
            alignSelf: 'start',
          }}
        >
          Já tem cadastro?{' '}
          <Link
            to="/"
            style={{
              textDecoration: 'underline',
              color: theme.palette.primary.main,
            }}
          >
            Entre aqui
          </Link>
        </Typography>
      </form>
    </OuterCard>
  );
}
