import axios from 'axios';

interface BrasilAPIResponse {
  cep: string;
  state: string;
  city: string;
  neighborhood: string;
  street: string;
}

const MIN_CEP_LENGTH = 9;
const getAddressByCep = async (cep: string) => {
  if (cep.length < MIN_CEP_LENGTH) {
    return null;
  }

  try {
    const response = await axios.get(
      `https://brasilapi.com.br/api/cep/v1/${cep}`,
    );

    return response.data as BrasilAPIResponse;
  } catch (error) {
    return null;
  }
};

export default getAddressByCep;
