/* eslint-disable react/jsx-props-no-spreading */
import { useTheme } from '@mui/material';

export default function OnlyOneImage(
  props: React.SVGAttributes<SVGSVGElement>,
) {
  const theme = useTheme();

  return (
    <svg
      width="660"
      height="559"
      viewBox="0 0 660 559"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M582.659 0.991699H206.34C193.581 1.00607 181.349 6.081 172.327 15.1031C163.305 24.1252 158.23 36.3577 158.215 49.1168V490.993C158.23 503.752 163.305 515.984 172.327 525.006C181.349 534.028 193.581 539.103 206.34 539.118H582.659C595.418 539.103 607.65 534.028 616.672 525.006C625.694 515.984 630.769 503.752 630.784 490.993V49.1168C630.769 36.3577 625.694 24.1252 616.672 15.1031C607.65 6.081 595.418 1.00607 582.659 0.991699V0.991699Z"
        fill="#E6E6E6"
      />
      <path
        d="M582.659 14.8459H206.34C197.254 14.8561 188.543 18.47 182.118 24.8949C175.693 31.3197 172.08 40.0307 172.069 49.1168V490.993C172.08 500.079 175.693 508.79 182.118 515.215C188.543 521.639 197.254 525.253 206.34 525.263H582.659C591.745 525.253 600.456 521.639 606.881 515.214C613.306 508.79 616.92 500.079 616.93 490.993V49.1168C616.92 40.0308 613.306 31.3198 606.881 24.895C600.456 18.4702 591.745 14.8562 582.659 14.8459V14.8459Z"
        fill="white"
      />
      <path
        d="M63.5 327C98.5701 327 127 298.57 127 263.5C127 228.43 98.5701 200 63.5 200C28.4299 200 0 228.43 0 263.5C0 298.57 28.4299 327 63.5 327Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M526.023 257.656H335.721C334.668 257.657 333.625 257.45 332.651 257.048C331.678 256.646 330.794 256.056 330.048 255.311C329.303 254.567 328.712 253.683 328.309 252.71C327.906 251.737 327.698 250.695 327.698 249.641C327.698 248.588 327.906 247.545 328.309 246.572C328.712 245.6 329.303 244.716 330.048 243.971C330.794 243.227 331.678 242.637 332.651 242.235C333.625 241.833 334.668 241.626 335.721 241.627H526.023C528.147 241.63 530.183 242.475 531.684 243.978C533.185 245.48 534.028 247.517 534.028 249.641C534.028 251.765 533.185 253.802 531.684 255.305C530.183 256.808 528.147 257.653 526.023 257.656V257.656Z"
        fill="#E6E6E6"
      />
      <path
        d="M559.081 284.703H335.721C334.668 284.704 333.625 284.498 332.651 284.096C331.678 283.693 330.794 283.103 330.048 282.359C329.303 281.615 328.712 280.731 328.309 279.758C327.906 278.785 327.698 277.742 327.698 276.689C327.698 275.636 327.906 274.593 328.309 273.62C328.712 272.647 329.303 271.763 330.048 271.019C330.794 270.275 331.678 269.685 332.651 269.282C333.625 268.88 334.668 268.674 335.721 268.675H559.081C561.206 268.675 563.245 269.519 564.748 271.022C566.251 272.525 567.095 274.564 567.095 276.689C567.095 278.815 566.251 280.853 564.748 282.356C563.245 283.859 561.206 284.703 559.081 284.703Z"
        fill="#E6E6E6"
      />
      <path
        d="M526.023 378.869H335.721C334.668 378.87 333.625 378.664 332.651 378.262C331.678 377.859 330.794 377.269 330.048 376.525C329.303 375.781 328.712 374.897 328.309 373.924C327.906 372.951 327.698 371.908 327.698 370.855C327.698 369.802 327.906 368.759 328.309 367.786C328.712 366.813 329.303 365.929 330.048 365.185C330.794 364.441 331.678 363.85 332.651 363.448C333.625 363.046 334.668 362.84 335.721 362.841H526.023C528.148 362.841 530.187 363.685 531.69 365.188C533.193 366.691 534.037 368.729 534.037 370.855C534.037 372.98 533.193 375.019 531.69 376.522C530.187 378.025 528.148 378.869 526.023 378.869V378.869Z"
        fill="#E6E6E6"
      />
      <path
        d="M559.081 405.917H335.721C334.668 405.918 333.625 405.711 332.651 405.309C331.678 404.907 330.794 404.317 330.048 403.573C329.303 402.828 328.712 401.944 328.309 400.971C327.906 399.999 327.698 398.956 327.698 397.903C327.698 396.849 327.906 395.806 328.309 394.834C328.712 393.861 329.303 392.977 330.048 392.232C330.794 391.488 331.678 390.898 332.651 390.496C333.625 390.094 334.668 389.887 335.721 389.888H559.081C560.134 389.887 561.177 390.094 562.151 390.496C563.124 390.898 564.008 391.488 564.754 392.232C565.499 392.977 566.09 393.861 566.493 394.834C566.896 395.806 567.104 396.849 567.104 397.903C567.104 398.956 566.896 399.999 566.493 400.971C566.09 401.944 565.499 402.828 564.754 403.573C564.008 404.317 563.124 404.907 562.151 405.309C561.177 405.711 560.134 405.918 559.081 405.917Z"
        fill="#E6E6E6"
      />
      <path
        d="M293.092 296.982H225.747C224.728 296.981 223.751 296.575 223.031 295.855C222.31 295.134 221.905 294.158 221.904 293.139V233.192C221.905 232.173 222.31 231.196 223.031 230.476C223.751 229.755 224.728 229.35 225.747 229.349H293.092C294.111 229.35 295.088 229.755 295.809 230.476C296.529 231.196 296.934 232.173 296.935 233.192V293.139C296.934 294.158 296.529 295.134 295.809 295.855C295.088 296.575 294.111 296.981 293.092 296.982V296.982Z"
        fill="#E6E6E6"
      />
      <path
        d="M293.092 418.195H225.747C224.728 418.194 223.751 417.789 223.031 417.068C222.31 416.348 221.905 415.371 221.904 414.352V354.405C221.905 353.386 222.31 352.409 223.031 351.689C223.751 350.969 224.728 350.563 225.747 350.562H293.092C294.111 350.563 295.088 350.969 295.809 351.689C296.529 352.409 296.934 353.386 296.935 354.405V414.352C296.934 415.371 296.529 416.348 295.809 417.068C295.088 417.789 294.111 418.194 293.092 418.195V418.195Z"
        fill="#E6E6E6"
      />
      <path
        d="M526.234 122.721H385.932C383.807 122.721 381.768 121.877 380.266 120.374C378.763 118.871 377.918 116.832 377.918 114.707C377.918 112.581 378.763 110.543 380.266 109.04C381.768 107.537 383.807 106.693 385.932 106.693H526.234C528.359 106.693 530.398 107.537 531.901 109.04C533.404 110.543 534.248 112.581 534.248 114.707C534.248 116.832 533.404 118.871 531.901 120.374C530.398 121.877 528.359 122.721 526.234 122.721Z"
        fill="#CCCCCC"
      />
      <path
        d="M559.292 149.769H385.932C384.88 149.769 383.838 149.561 382.865 149.159C381.893 148.756 381.01 148.166 380.266 147.421C379.521 146.677 378.931 145.794 378.528 144.821C378.126 143.849 377.918 142.807 377.918 141.755C377.918 140.702 378.126 139.66 378.528 138.688C378.931 137.715 379.521 136.832 380.266 136.088C381.01 135.343 381.893 134.753 382.865 134.35C383.838 133.948 384.88 133.74 385.932 133.74H559.292C561.418 133.74 563.456 134.585 564.959 136.088C566.462 137.591 567.306 139.629 567.306 141.755C567.306 143.88 566.462 145.918 564.959 147.421C563.456 148.924 561.418 149.769 559.292 149.769V149.769Z"
        fill="#CCCCCC"
      />
      <path
        d="M354.881 182.547H225.535C224.517 182.546 223.54 182.141 222.819 181.42C222.099 180.7 221.694 179.723 221.693 178.704V77.757C221.694 76.7382 222.099 75.7615 222.819 75.041C223.54 74.3206 224.517 73.9154 225.535 73.9143H354.881C355.9 73.9154 356.877 74.3206 357.597 75.041C358.318 75.7615 358.723 76.7382 358.724 77.757V178.704C358.723 179.723 358.318 180.7 357.597 181.42C356.877 182.141 355.9 182.546 354.881 182.547V182.547Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M75.04 295H62.96V261.96C62.96 261.053 62.96 259.933 62.96 258.6C63.0133 257.267 63.0667 255.88 63.12 254.44C63.1733 253 63.2267 251.72 63.28 250.6C63.0133 250.92 62.4267 251.507 61.52 252.36C60.6667 253.16 59.8667 253.88 59.12 254.52L52.56 259.8L46.72 252.52L65.12 237.88H75.04V295Z"
        fill="white"
      />
      <path
        d="M655.92 457H562.08C559.823 457 558 458.823 558 461.08V554.92C558 557.177 559.823 559 562.08 559H655.92C658.177 559 660 557.177 660 554.92V461.08C660 458.823 658.177 457 655.92 457ZM632.396 489.347L605.545 526.577C605.169 527.101 604.675 527.527 604.102 527.822C603.528 528.116 602.893 528.27 602.249 528.27C601.604 528.27 600.969 528.116 600.396 527.822C599.823 527.527 599.328 527.101 598.953 526.577L583.054 504.545C582.569 503.869 583.054 502.926 583.882 502.926H589.862C591.163 502.926 592.399 503.55 593.164 504.621L602.242 517.218L622.286 489.423C623.051 488.365 624.274 487.727 625.588 487.727H631.567C632.396 487.727 632.881 488.671 632.396 489.347V489.347Z"
        fill={theme.palette.secondary.main}
      />
    </svg>
  );
}
