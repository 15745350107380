/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';

import ContentSubTitle from '../../components/ContentSubTitle';
import SoQuietImage from '../../components/Images/SoQuietImage';

const useStyles = makeStyles({
  stack: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

type Props = {
  direction?: 'column' | 'row';
  height?: string;
  gap?: string;
};
const ItsSoQuietHere = ({ direction, height, gap }: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grid
      sx={{ height, width: '100%', display: 'flex', justifyContent: 'center' }}
    >
      <Stack
        className={classes.stack}
        sx={{
          flexDirection: direction,
          gap,
          [theme.breakpoints.down(1540)]: {
            gap: '7rem',
          },
        }}
      >
        <SoQuietImage style={{ width: '12rem', height: '10rem' }} />

        <Stack className={classes.stack}>
          <ContentSubTitle
            text="Está tão parado aqui..."
            fontWeight="bold"
            fontSize=".9rem"
            margin="1rem auto .3rem auto"
            alignSelf="center"
          />

          <ContentSubTitle
            text="Adicione documentos e assine-os :)"
            fontWeight="bold"
            fontSize=".9rem"
            margin="1rem auto .3rem auto"
            alignSelf="center"
          />
        </Stack>
      </Stack>
    </Grid>
  );
};

export default ItsSoQuietHere;

ItsSoQuietHere.defaultProps = {
  direction: 'column',
  height: '70vh',
  gap: '2rem',
};
