/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/anchor-is-valid */
import 'open-iconic/font/css/open-iconic-bootstrap.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap';
import './balloons.css';

import CloseIcon from '@mui/icons-material/Close';

import { useState } from 'react';
import LogoConlicitaBranca from './logo-conlicita-branca.svg';
import Panel from './menu-icons/panel.svg';
import Bulletins from './menu-icons/boletins.svg';
import GraphIcon from './menu-icons/graph-webpage-landing-page.svg';
import DatabaseIcon from './menu-icons/database.svg';
import StarRateIcon from './menu-icons/star-rate.svg';
import DocumentIcon from './menu-icons/document.svg';
import MonitoringIcon from './menu-icons/monitoring.svg';
import MarketIcon from './menu-icons/market-analysis.svg';
import ContractsIcon from './menu-icons/contracts-icon.svg';
import ConcurrentIcon from './menu-icons/concurrent.svg';
import EasyLegalFrom from './menu-icons/easy-legal.svg';
import ApiIcon from './menu-icons/api-menu-icon.svg';
import CourseIcon from './menu-icons/conlicitago.svg';
import CadastralAdvisoryIcon from './menu-icons/cadastral-advisory.svg';
import ToolsStatusIcon from './menu-icons/tools_status.svg';
import MoreFrom from './menu-icons/more.svg';

import { Container } from './styles';

const ConlicitacaoSidebar = ({conlicitaOpen, setConlicitaOpen}) => {
  const [opened, setOpened] = useState(false);
  const [juridicoOpened, setJuridicoOpened] = useState(false);
  const [moreOpened, setMoreOpened] = useState(false);
  return (
    <Container className="d-flex align-items-start">
      <div className={`sidebar-menu ${opened || conlicitaOpen ? 'open-menu' : ''} `}>
        <div className="box-logo d-flex justify-content-between align-items-center">
          <a
            title="Logo"
            className="icon-logo"
            href="https://consulteonline.conlicitacao.com.br/painel"
          >
            <img
              alt="Tudo sobre Licitações Públicas e Pregão - Conlicitação"
              src={LogoConlicitaBranca}
            />
          </a>
          <button className="close-menu btn btn-sm btn-link" 
            type="button"
            onClick={() => {
              setOpened(false);
              setMoreOpened(false);
              setJuridicoOpened(false);
              setConlicitaOpen(false);
            }}
          >
            <CloseIcon style={{ fontSize: '18px' }} />
          </button>
        </div>
        <div className="scroll-menu">
          <ul className="nav flex-column" id="accordion-menu">
            <li className="nav-item">
              <a
                className="nav-link databalloon tour-boletins-menu"
                aria-label="Painel"
                data-balloon-pos="right"
                href="https://consulteonline.conlicitacao.com.br/painel"
              >
                <span className="nav-icon">
                  <img alt="Painel" src={Panel} />
                </span>
                <span className="nav-name">Painel</span>
              </a>
            </li>
          </ul>

          <li className="nav-item">
            <a
              className="nav-link databalloon tour-boletins-menu"
              aria-label="Boletins"
              data-balloon-pos="right"
              href="https://consultaonline.conlicitacao.com.br//boletim_web/public/boletins"
            >
              <span className="nav-icon">
                <img alt="Boletins" src={Bulletins} />
              </span>
              <span className="nav-name">Boletins</span>
            </a>
          </li>

          <li className="nav-item">
            <a
              className="nav-link databalloon tour-boletins-menu"
              aria-label="Encontrar Licitações"
              data-balloon-pos="right"
              href="https://consultaonline.conlicitacao.com.br/banco_de_dados"
            >
              <span className="nav-icon">
                <img alt="Encontrar Licitações" src={GraphIcon} />
              </span>
              <span className="nav-name">Encontrar Licitações</span>
            </a>
          </li>

          <li className="nav-item">
            <a
              className="nav-link databalloon tour-boletins-menu"
              aria-label="Encontrar Acompanhamentos"
              data-balloon-pos="right"
              href="https://consulteonline.conlicitacao.com.br/banco_de_dados/acompanhamentos"
            >
              <span className="nav-icon">
                <img alt="Encontrar Acompanhamentos" src={DatabaseIcon} />
              </span>
              <span className="nav-name">Encontrar Acompanhamentos</span>
            </a>
          </li>

          <li className="nav-item">
            <a
              className="nav-link databalloon tour-boletins-menu"
              aria-label="Gerenciar Licitações"
              data-balloon-pos="right"
              href="https://consultaonline.conlicitacao.com.br/minhas_licitacoes/management"
            >
              <span className="nav-icon">
                <img alt="Gerenciar Licitações" src={StarRateIcon} />
              </span>
              <span className="nav-name">Gerenciar Licitações</span>
            </a>
          </li>

          <li className="nav-item">
            <a
              className="nav-link databalloon tour-boletins-menu"
              aria-label="Gerenciar Documentos"
              data-balloon-pos="right"
              href="https://consultaonline.conlicitacao.com.br/documentos"
            >
              <span className="nav-icon">
                <img alt="Gerenciar Documentos" src={DocumentIcon} />
              </span>
              <span className="nav-name">Gerenciar Documentos</span>
            </a>
          </li>

          <li className="nav-item">
            <a
              className="nav-link databalloon tour-boletins-menu"
              aria-label="Monitorar Chat"
              data-balloon-pos="right"
              href="https://consulteonline.conlicitacao.com.br/facilitadores/monitoramento"
            >
              <span className="nav-icon">
                <img alt="Monitorar Chat" src={MonitoringIcon} />
              </span>
              <span className="nav-name">Monitorar Chat</span>
            </a>
          </li>

          <li className="nav-item">
            <a
              className="nav-link databalloon tour-boletins-menu"
              aria-label="Análise de Mercado"
              data-balloon-pos="right"
              href="https://consultaonline.conlicitacao.com.br/mercado/dashboard"
            >
              <span className="nav-icon">
                <img alt="Análise de Mercado" src={MarketIcon} />
              </span>
              <span className="nav-name">Análise de Mercado</span>
            </a>
          </li>

          <li className="nav-item">
            <a
              className="nav-link databalloon tour-boletins-menu"
              aria-label="Contratos"
              data-balloon-pos="right"
              href="https://consulteonline.conlicitacao.com.br/contratos"
            >
              <span className="nav-icon">
                <img alt="Contratos" src={ContractsIcon} />
              </span>
              <span className="nav-name">Contratos</span>
            </a>
          </li>

          <li className="nav-item">
            <a
              className="nav-link databalloon tour-boletins-menu"
              aria-label="Concorrentes"
              data-balloon-pos="right"
              href="https://consulteonline.conlicitacao.com.br/concorrentes"
            >
              <span className="nav-icon">
                <img alt="Concorrentes" src={ConcurrentIcon} />
              </span>
              <span className="nav-name">Concorrentes</span>
            </a>
          </li>

          <li
            className="nav-item"
            role="button"
            aria-label="Abrir menu Jurídico"
            onKeyDown={() => {
              setJuridicoOpened(!juridicoOpened);
            }}
            onClick={() => {
              setJuridicoOpened(!juridicoOpened);
            }}
          >
            <a
              aria-label="Jurídico"
              className={`link-accordion nav-link databalloon tour-legal-menu ${
                juridicoOpened ? '' : 'collapsed'
              }`}
              data-balloon-pos="right"
              data-target="#Jurídico"
              data-toggle="collapse"
              href="#"
              role="button"
              aria-expanded={juridicoOpened}
            >
              <span className="nav-icon">
                <img alt="Jurídico" src={EasyLegalFrom} />
              </span>
              <span className="nav-name">Jurídico</span>
              <span className="oi oi-chevron-bottom ml-auto nav-arrow mr-2" />
            </a>
            <div
              aria-labelledby="dLabel"
              className={juridicoOpened ? 'collapse show' : 'collapse'}
              data-parent="#accordion-menu"
              id="Jurídico"
              role="menu"
            >
              <ul className="nav flex-column second-menu">
                <li className="nav-item">
                  <a
                    className="nav-link databalloon tour-easy-legal-menu"
                    href="https://consultaonline.conlicitacao.com.br/juridico_facil"
                  >
                    Jurídico Fácil
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link databalloon tour-easy-legal-menu"
                    href="https://consultaonline.conlicitacao.com.br/legislacoes"
                  >
                    Legislações
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link databalloon tour-easy-legal-menu"
                    href="https://conlicitacao.com.br/artigos-juridicos/"
                  >
                    Artigos Jurídicos
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link databalloon tour-easy-legal-menu"
                    href="https://consultaonline.conlicitacao.com.br/consultoria_juridica/new"
                  >
                    Envie sua Dúvida
                  </a>
                </li>
              </ul>
            </div>
          </li>

          <li className="nav-item">
            <a
              className="nav-link databalloon tour-boletins-menu"
              aria-label="API ConLicitação"
              data-balloon-pos="right"
              href="https://consultaonline.conlicitacao.com.br/facilitadores/conlicitacao_api"
            >
              <span className="nav-icon">
                <img alt="API ConLicitação" src={ApiIcon} />
              </span>
              <span className="nav-name">API ConLicitação</span>
            </a>
          </li>

          <li className="nav-item">
            <a
              className="nav-link databalloon tour-boletins-menu"
              aria-label="Conlicita Go"
              data-balloon-pos="right"
              href="https://consultaonline.conlicitacao.com.br/facilitadores/conlicita_go"
            >
              <span className="nav-icon">
                <img alt="Conlicita Go" src={CourseIcon} />
              </span>
              <span className="nav-name">Conlicita Go</span>
            </a>
          </li>

          <li className="nav-item">
            <a
              className="nav-link databalloon tour-boletins-menu"
              aria-label="Assessoria Cadastral"
              data-balloon-pos="right"
              href="https://consultaonline.conlicitacao.com.br/facilitadores/assessoria_cadastral"
            >
              <span className="nav-icon">
                <img alt="Assessoria Cadastral" src={CadastralAdvisoryIcon} />
              </span>
              <span className="nav-name">Assessoria Cadastral</span>
            </a>
          </li>

          <li className="nav-item">
            <a
              className="nav-link databalloon tour-boletins-menu"
              aria-label="Status Ferramentas"
              data-balloon-pos="right"
              href="https://consultaonline.conlicitacao.com.br/tools_status"
            >
              <span className="nav-icon">
                <img alt="Status Ferramentas" src={ToolsStatusIcon} />
              </span>
              <span className="nav-name">Status Ferramentas</span>
            </a>
          </li>

          <li
            className="nav-item"
            role="button"
            aria-label="Abrir menu Mais"
            onKeyDown={() => {
              setMoreOpened(!moreOpened);
            }}
            onClick={() => {
              setMoreOpened(!moreOpened);
            }}
          >
            <a
              aria-label="Mais"
              className={`link-accordion nav-link databalloon tour-legal-menu ${
                moreOpened ? '' : 'collapsed'
              }`}
              data-balloon-pos="right"
              data-target="#Mais"
              data-toggle="collapse"
              href="#"
              role="button"
              aria-expanded={moreOpened}
            >
              <span className="nav-icon">
                <img alt="Mais" src={MoreFrom} />
              </span>
              <span className="nav-name">Mais</span>
              <span className="oi oi-chevron-bottom ml-auto nav-arrow mr-2" />
            </a>
            <div
              aria-labelledby="dLabel"
              className={moreOpened ? 'collapse show' : 'collapse'}
              data-parent="#accordion-menu"
              id="Mais"
              role="menu"
            >
              <ul className="nav flex-column second-menu">
                <li className="nav-item">
                  <a
                    className="nav-link databalloon tour-easy-legal-menu"
                    href="https://consultaonline.conlicitacao.com.br/conteudo_exclusivo/bolsa_de_empregos"
                  >
                    Bolsa de Empregos
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link databalloon tour-easy-legal-menu"
                    href="https://consultaonline.conlicitacao.com.br/conteudo_exclusivo/clipping_policia_federal"
                  >
                    Clipping Polícia Federal
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </div>
      </div>
      <button
        className="toggle-menu open-menu btn btn-sm btn-link p-0"
        type="button"
        onClick={() => {
          setOpened(!opened);
          setConlicitaOpen(false);
        }}
      >
        <span className="oi oi-menu" />
      </button>
    </Container>
  );
};

export default ConlicitacaoSidebar;
