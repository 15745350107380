/* eslint-disable react/jsx-props-no-spreading */
import { useTheme } from '@mui/material';

export default function AttentionImage(
  props: React.SVGAttributes<SVGSVGElement>,
) {
  const theme = useTheme();

  return (
    <svg
      width="790"
      height="513"
      viewBox="0 0 790 513"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_601_86)">
        <path
          d="M720.563 510.693L698 442.602C698 442.602 722.818 467.421 722.818 487.784L718.364 440.693C718.364 440.693 731.091 457.875 729.818 483.966C728.545 510.057 720.563 510.693 720.563 510.693Z"
          fill="#E6E6E6"
        />
        <path
          d="M236.021 448.693L214 382.239C214 382.239 238.222 406.461 238.222 426.335L233.874 380.376C233.874 380.376 246.295 397.145 245.053 422.608C243.811 448.072 236.021 448.693 236.021 448.693Z"
          fill="#E6E6E6"
        />
        <path
          d="M579.725 479.359C579.763 523.074 493.06 509.627 386.916 509.719C280.772 509.81 195.381 523.405 195.343 479.691C195.305 435.976 281.976 426.394 388.12 426.302C494.265 426.21 579.688 435.644 579.725 479.359Z"
          fill="#BABABA"
        />
        <path d="M790 509.693H0V511.693H790V509.693Z" fill="#3F3D56" />
        <path
          d="M505.336 420.322H491.459L484.855 366.797H505.336V420.322Z"
          fill="#A0616A"
        />
        <path
          d="M480.006 416.357H508.31V433.208H464.697V431.667C464.697 427.606 466.31 423.712 469.181 420.841C472.052 417.97 475.946 416.357 480.006 416.357V416.357Z"
          fill="#2F2E41"
        />
        <path
          d="M607.336 499.322H593.459L586.855 445.797H607.336V499.322Z"
          fill="#A0616A"
        />
        <path
          d="M582.006 495.357H610.31V512.208H566.697V510.667C566.697 506.606 568.31 502.712 571.181 499.841C574.052 496.97 577.946 495.357 582.006 495.357V495.357Z"
          fill="#2F2E41"
        />
        <path
          d="M671.345 340.309C672.304 339.146 672.997 337.786 673.374 336.326C673.751 334.865 673.804 333.34 673.528 331.858C673.251 330.375 672.654 328.971 671.776 327.744C670.899 326.518 669.763 325.498 668.449 324.758L636.219 193.465L615.611 202.332L653.947 329.281C652.849 331.571 652.63 334.183 653.331 336.624C654.031 339.064 655.603 341.163 657.747 342.523C659.892 343.882 662.461 344.408 664.967 344C667.473 343.592 669.742 342.279 671.345 340.309V340.309Z"
          fill="#A0616A"
        />
        <path
          d="M646.208 74.9636C645.058 73.7611 643.658 72.8255 642.107 72.2226C640.556 71.6197 638.892 71.3642 637.231 71.4741C635.571 71.5839 633.955 72.0565 632.497 72.8585C631.039 73.6605 629.774 74.7725 628.793 76.116L578.907 81.8431L586.496 101.085L631.864 92.5937C634.23 94.0934 637.064 94.6729 639.829 94.2225C642.595 93.7721 645.098 92.323 646.867 90.1499C648.635 87.9767 649.544 85.2305 649.423 82.4316C649.301 79.6326 648.157 76.9755 646.208 74.9636V74.9636Z"
          fill="#A0616A"
        />
        <path
          d="M564 326.693L585.768 490.068L612.862 484.49C612.862 484.49 608.877 365.508 622.424 351.164C635.971 336.821 605 311.693 605 311.693L564 326.693Z"
          fill="#2F2E41"
        />
        <path
          d="M573 281.693L563 296.693C563 296.693 486 264.694 486 315.693C486 366.692 481.594 401.302 480 403.693C478.406 406.083 498.438 412.287 508 410.693C508 410.693 519.797 328.474 519 323.693C519 323.693 594.533 360.726 608.877 357.539C623.221 354.351 626.609 343.068 629 336.693C631.39 330.318 628 279.693 628 279.693L580.19 265.103L573 281.693Z"
          fill="#2F2E41"
        />
        <path
          d="M574.349 191.633L571.499 188.212C571.499 188.212 539.575 116.384 552.117 97.0022C564.658 77.62 619.384 74.7696 621.095 75.9097C622.805 77.0499 617.01 91.8525 621 98.6933C621 98.6933 578.606 107.885 575.756 109.025C572.905 110.165 597.722 152.298 597.722 152.298L594.872 177.951L574.349 191.633Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M630.216 156.289C630.216 156.289 600.572 159.709 599.432 159.709C598.292 159.709 597.722 152.298 597.722 152.298L571.499 188.212C571.499 188.212 558.58 292.403 562 290.693C565.421 288.983 628.506 298.805 629.076 294.244C629.646 289.684 628.506 266.881 630.216 265.741C631.926 264.601 659.859 193.913 659.859 193.913C659.859 193.913 657.009 179.091 647.318 173.961C637.627 168.83 630.216 156.289 630.216 156.289Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M650.738 184.222L659.859 193.913C659.859 193.913 673.411 305.273 666 308.693C658.589 312.113 644 311.693 644 311.693L629.646 258.9L650.738 184.222Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M601.73 149.236C616.221 149.236 627.968 137.489 627.968 122.998C627.968 108.506 616.221 96.7588 601.73 96.7588C587.238 96.7588 575.491 108.506 575.491 122.998C575.491 137.489 587.238 149.236 601.73 149.236Z"
          fill="#A0616A"
        />
        <path
          d="M595.573 127.092C595.218 121.647 588.35 121.461 582.894 121.409C577.438 121.358 570.915 121.553 567.828 117.054C565.788 114.081 566.178 109.954 567.863 106.766C569.549 103.579 572.321 101.127 575.049 98.7687C582.09 92.6798 589.347 86.6393 597.801 82.7421C606.255 78.8448 616.161 77.2701 624.929 80.3986C635.699 84.2416 650.258 104.024 651.515 115.39C652.772 126.756 648.23 138.343 640.573 146.836C632.916 155.33 615.391 151.903 604.362 154.924C611.067 145.435 606.648 128.192 595.907 123.76L595.573 127.092Z"
          fill="#2F2E41"
        />
        <path
          d="M278.5 308C297.002 308 312 293.002 312 274.5C312 255.998 297.002 241 278.5 241C259.998 241 245 255.998 245 274.5C245 293.002 259.998 308 278.5 308Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M256.001 239C251.348 239 246.795 237.653 242.893 235.12C238.991 232.587 235.906 228.978 234.014 224.73L180.744 104.811C178.979 100.839 178.046 96.5476 178.002 92.2015C177.957 87.8554 178.802 83.5461 180.485 79.5385C182.168 75.5308 184.653 71.9096 187.787 68.8973C190.921 65.8849 194.639 63.5452 198.711 62.0219C202.783 60.4986 207.124 59.8238 211.466 60.0391C215.809 60.2545 220.062 61.3553 223.963 63.274C227.864 65.1927 231.332 67.8887 234.153 71.1963C236.973 74.5039 239.087 78.3533 240.365 82.5077L278.939 207.924C280.043 211.514 280.29 215.313 279.659 219.015C279.028 222.718 277.538 226.221 275.307 229.243C273.076 232.265 270.168 234.722 266.815 236.417C263.462 238.111 259.758 238.996 256.001 239H256.001Z"
          fill={theme.palette.secondary.main}
        />
      </g>
      <defs>
        <clipPath id="clip0_601_86">
          <rect width="790" height="512.208" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
