/* eslint-disable react/jsx-props-no-spreading */
import { useTheme } from '@mui/material';

export default function Plan2Image(props: React.SVGAttributes<SVGSVGElement>) {
  const theme = useTheme();

  return (
    <svg
      width="119"
      height="110"
      viewBox="0 0 119 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_266_3358)">
        <path
          d="M59.1242 109.958C70.4728 109.958 79.6726 109.484 79.6726 108.9C79.6726 108.316 70.4728 107.842 59.1242 107.842C47.7756 107.842 38.5758 108.316 38.5758 108.9C38.5758 109.484 47.7756 109.958 59.1242 109.958Z"
          fill="#E6E6E6"
        />
        <path
          d="M75.586 108.022C78.163 105.39 90.2982 92.4377 81.6731 90.3024C75.195 88.6986 74.4733 97.5221 74.6907 103.443C73.6534 99.454 71.6402 94.7938 68.1033 96.85C63.0059 99.8133 72.5572 106.371 75.0522 107.981C75.0932 108.308 75.1202 108.49 75.1202 108.49C75.1202 108.49 75.2226 108.39 75.405 108.206C75.5475 108.295 75.6268 108.343 75.6268 108.343C75.6268 108.343 75.6135 108.227 75.586 108.022Z"
          fill="#E6E6E6"
        />
        <path
          d="M118.044 1.89746H0.254517V69.6036H118.044V1.89746Z"
          fill="#E6E6E6"
        />
        <path
          d="M114.676 10.3892H3.62267V65.5977H114.676V10.3892Z"
          fill="white"
        />
        <path
          d="M107.483 16.0469H83.5498V28.1296H107.483V16.0469Z"
          fill="#E6E6E6"
        />
        <path
          d="M107.483 31.9521H83.5498V44.0349H107.483V31.9521Z"
          fill="#E6E6E6"
        />
        <path
          d="M107.483 47.8574H83.5498V59.9402H107.483V47.8574Z"
          fill="#E6E6E6"
        />
        <path
          d="M34.7488 16.0469H10.8154V28.1296H34.7488V16.0469Z"
          fill="#E6E6E6"
        />
        <path
          d="M34.7488 31.9521H10.8154V44.0349H34.7488V31.9521Z"
          fill="#E6E6E6"
        />
        <path
          d="M34.7488 47.8574H10.8154V59.9402H34.7488V47.8574Z"
          fill="#E6E6E6"
        />
        <path
          d="M60.4876 23.2037C63.1754 23.2037 65.3543 21.2481 65.3543 18.8357C65.3543 16.4234 63.1754 14.4678 60.4876 14.4678C57.7997 14.4678 55.6208 16.4234 55.6208 18.8357C55.6208 21.2481 57.7997 23.2037 60.4876 23.2037Z"
          fill="#2F2E41"
        />
        <path
          d="M77.8319 4.94208L79.4579 3.71811C79.6603 3.56575 79.8256 3.37757 79.9433 3.16532C80.061 2.95308 80.1287 2.72132 80.1422 2.48454C80.1557 2.24775 80.1146 2.01101 80.0215 1.78911C79.9285 1.56721 79.7855 1.36491 79.6014 1.19484C79.3773 0.987816 79.0989 0.834569 78.7918 0.749145C78.4846 0.663722 78.1584 0.648852 77.8432 0.7059C77.5279 0.762947 77.2337 0.890087 76.9874 1.07567C76.7411 1.26125 76.5506 1.49934 76.4334 1.76813L75.6574 3.54804L69.4445 13.1204L66.2863 20.8805L68.8232 23.6222L72.2403 14.1427L77.8319 4.94208Z"
          fill="#A0616A"
        />
        <path
          d="M43.2467 4.94208L41.6208 3.71811C41.4183 3.56575 41.2531 3.37757 41.1354 3.16532C41.0176 2.95308 40.9499 2.72132 40.9365 2.48453C40.923 2.24775 40.9641 2.01101 41.0571 1.78911C41.1502 1.56721 41.2932 1.36491 41.4773 1.19484C41.7013 0.987815 41.9797 0.83457 42.2869 0.749146C42.5941 0.663722 42.9202 0.648852 43.2355 0.705899C43.5507 0.762946 43.845 0.890087 44.0913 1.07567C44.3376 1.26125 44.528 1.49934 44.6452 1.76812L45.4213 3.54804L51.6342 13.1204L55.103 20.9735L52.2555 23.6222L48.8384 14.1427L43.2467 4.94208Z"
          fill="#A0616A"
        />
        <path
          d="M60.6947 16.6982C62.0672 16.6982 63.1798 15.6996 63.1798 14.4678C63.1798 13.2359 62.0672 12.2373 60.6947 12.2373C59.3221 12.2373 58.2095 13.2359 58.2095 14.4678C58.2095 15.6996 59.3221 16.6982 60.6947 16.6982Z"
          fill="#2F2E41"
        />
        <path
          d="M60.9017 84.2631L58.6754 84.1238L58.779 56.8936L64.2671 58.1017L60.9017 84.2631Z"
          fill="#A0616A"
        />
        <path
          d="M58.4045 91.0327C57.9639 91.0239 57.5368 90.8947 57.1803 90.6622C56.8238 90.4297 56.5549 90.1051 56.4095 89.7318C55.9252 88.4549 56.1755 87.24 57.0187 86.0752L56.7432 84.3444C56.6997 84.0709 56.7222 83.7922 56.8094 83.5272C56.8965 83.2622 57.0461 83.0172 57.2481 82.8087L58.6755 81.3354V83.1942C59.7203 82.374 60.3644 82.3444 61.2642 83.0083L60.4358 86.8186L60.6381 88.9062C60.6647 89.1811 60.6263 89.458 60.5253 89.7187C60.4243 89.9793 60.2631 90.2179 60.0522 90.4187C59.8413 90.6195 59.5854 90.778 59.3015 90.8838C59.0175 90.9896 58.7118 91.0404 58.4045 91.0327Z"
          fill="#2F2E41"
        />
        <path
          d="M64.4742 29.0125L55.7761 28.8266C57.0086 27.7513 58.7737 23.6283 58.4684 22.4141H62.7139C62.2042 23.6636 63.1906 27.9083 64.4742 29.0125Z"
          fill="#A0616A"
        />
        <path
          d="M66.7523 43.3243L54.7406 40.1645L55.1548 35.5177L54.1193 30.778C54.225 30.2638 51.491 23.9124 51.6342 23.4361C52.2061 21.5333 53.7038 21.7962 54.7924 20.4157C54.9692 20.1915 56.3487 21.768 57.2409 22.834C57.3468 22.9982 57.4195 23.1095 57.4697 23.1849C57.8053 23.6935 57.9513 24.2851 57.8862 24.8733L57.8851 24.8836C57.3963 26.4023 58.5194 25.7796 60.2805 25.8059C61.7745 25.8283 63.1281 26.8908 63.1281 25.5497C63.1256 25.4736 63.1219 25.3942 63.1179 25.3126C63.0882 24.6263 63.2698 23.9458 63.6425 23.346C63.6656 23.3084 63.5555 23.4653 64.119 22.6031C65.1296 21.3206 66.5211 19.6165 66.5969 19.8581C66.9161 20.8749 68.5587 21.7292 69.1339 23.529C69.2224 23.806 68.6889 25.4876 68.7197 25.7595C68.9503 27.7977 68.5126 28.9193 68.3055 30.4992C67.0187 34.6708 66.4941 39.002 66.7523 43.3243Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M65.0954 59.2167L58.2612 57.358L58.1577 51.7819L52.7732 42.4883L54.8441 39.4214L66.6487 42.2095C67.4541 46.8418 66.5664 52.8008 65.0954 59.2167Z"
          fill="#2F2E41"
        />
        <path
          d="M61.8855 51.7817H58.1577L51.2201 43.0458L53.7052 41.187L61.8855 51.7817Z"
          fill="#2F2E41"
        />
        <path
          d="M63.6458 51.6889H57.4516C55.9823 51.691 54.5461 51.2971 53.3291 50.5582C50.6139 48.9109 49.7446 45.6724 51.22 43.0459L63.6458 43.6035V51.6889Z"
          fill="#2F2E41"
        />
        <path
          d="M58.2261 45.4245L52.7732 45.2762C52.7528 45.2298 52.7334 45.1831 52.715 45.1362C51.9898 43.2825 53.0803 41.2673 55.0981 40.5054C55.3012 40.4287 55.5075 40.3578 55.7183 40.2925C56.2048 40.1402 56.7245 40.0937 57.2348 40.1566C57.7452 40.2196 58.2316 40.3904 58.6542 40.6548C59.0768 40.9193 59.4234 41.2699 59.6657 41.678C59.9079 42.086 60.0389 42.5399 60.0478 43.0021C60.0534 43.3745 60.0276 43.7467 59.9705 44.1156C59.9101 44.4881 59.6987 44.8276 59.3769 45.0691C59.0551 45.3106 58.6454 45.4372 58.2261 45.4245Z"
          fill="#A0616A"
        />
        <path
          d="M55.2583 60.3315H53.0839L52.7731 44.5324L58.8826 44.4395L55.2583 60.3315Z"
          fill="#A0616A"
        />
        <path
          d="M52.8128 67.2744C52.3722 67.2656 51.9451 67.1364 51.5886 66.9039C51.2321 66.6714 50.9632 66.3468 50.8178 65.9734C50.3335 64.6966 50.5838 63.4817 51.427 62.3169L51.1515 60.5861C51.108 60.3126 51.1305 60.0339 51.2177 59.7689C51.3048 59.5039 51.4544 59.2589 51.6564 59.0504L53.0838 57.5771V59.4359C54.1286 58.6157 54.7727 58.5861 55.6725 59.25L54.8441 63.0603L55.0464 65.1479C55.073 65.4228 55.0346 65.6997 54.9336 65.9604C54.8326 66.221 54.6714 66.4596 54.4605 66.6604C54.2496 66.8612 53.9938 67.0197 53.7098 67.1255C53.4258 67.2313 53.1201 67.2821 52.8128 67.2744Z"
          fill="#2F2E41"
        />
        <path
          d="M60.6429 23.8082C62.9876 23.8082 64.8883 22.1023 64.8883 19.9979C64.8883 17.8935 62.9876 16.1875 60.6429 16.1875C58.2981 16.1875 56.3974 17.8935 56.3974 19.9979C56.3974 22.1023 58.2981 23.8082 60.6429 23.8082Z"
          fill="#A0616A"
        />
        <path
          d="M64.3707 18.325C64.3707 20.2754 62.4019 17.4886 60.2287 17.4886C58.0556 17.4886 56.501 20.2754 56.501 18.325C56.501 17.3884 56.9155 16.4901 57.6535 15.8278C58.3914 15.1655 59.3922 14.7935 60.4358 14.7935C61.4794 14.7935 62.4802 15.1655 63.2182 15.8278C63.9561 16.4901 64.3707 17.3884 64.3707 18.325Z"
          fill="#2F2E41"
        />
        <path
          d="M117.994 1.8374H0.204231V6.32866H117.994V1.8374Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M3.92291 4.96611C4.43514 4.96611 4.85039 4.59342 4.85039 4.13369C4.85039 3.67396 4.43514 3.30127 3.92291 3.30127C3.41068 3.30127 2.99543 3.67396 2.99543 4.13369C2.99543 4.59342 3.41068 4.96611 3.92291 4.96611Z"
          fill="white"
        />
        <path
          d="M7.44337 4.96611C7.9556 4.96611 8.37085 4.59342 8.37085 4.13369C8.37085 3.67396 7.9556 3.30127 7.44337 3.30127C6.93114 3.30127 6.51589 3.67396 6.51589 4.13369C6.51589 4.59342 6.93114 4.96611 7.44337 4.96611Z"
          fill="white"
        />
        <path
          d="M10.9638 4.96611C11.4761 4.96611 11.8913 4.59342 11.8913 4.13369C11.8913 3.67396 11.4761 3.30127 10.9638 3.30127C10.4516 3.30127 10.0364 3.67396 10.0364 4.13369C10.0364 4.59342 10.4516 4.96611 10.9638 4.96611Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_266_3358">
          <rect
            width="117.84"
            height="109.287"
            fill="white"
            transform="translate(0.204231 0.671387)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
