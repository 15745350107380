import Avatar from '@mui/material/Avatar';
import { SxProps, Theme } from '@mui/material';

import useAuthContext from '../hooks/useAuthContext';

import { getUserInitials } from '../utils/formatName';

interface Props {
  style: SxProps<Theme>;
}

const UserAvatar: React.FC<Props> = ({ style }) => {
  const { userInfo } = useAuthContext();
  const name = userInfo?.user.name ?? '';

  return <Avatar sx={style}>{getUserInitials(name)}</Avatar>;
};

export default UserAvatar;
