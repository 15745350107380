/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useState, useEffect } from 'react';

import useAuthContext from '../hooks/useAuthContext';

import { Subscription, Status } from '../types/Subscription';

import api from '../services/api';

type StateSubscription = 'loading' | 'not_purchased' | 'purchased';

interface ContextProps {
  subscription: Subscription | null;
  stateSubscription: StateSubscription;
  subscriptionDetailsLink: string;
  getSubscription: () => void;
  getSubscriptionDetailsLink: () => void;
  subscriptionLinkLoading: boolean;
  statusInPtBr: string;
}

type Props = {
  children: React.ReactNode;
};

type StatusSubscriptionEnToPtBr = Record<Status, string>;

const statusSubscriptionEnToPtBr = {
  active: 'Ativo',
  suspended: 'Suspenso',
  ended: 'Finalizado',
  incomplete: 'Incompleto',
  canceled: 'Cancelado',
  unpaid: 'Não pago',
  incomplete_expired: 'Expirado',
} as StatusSubscriptionEnToPtBr;

const SubscriptionContext = createContext<ContextProps>({} as ContextProps);

function SubscriptionProvider({ children }: Props) {
  const [subscription, setSubscription] = useState<Subscription | null>(null);

  const [stateSubscription, setStateSubscription] =
    useState<StateSubscription>('loading');

  const [subscriptionLinkLoading, setSubscriptionLinkLoading] = useState(false);

  const [subscriptionDetailsLink, setSubscriptionDetailsLink] = useState('');

  const { costCenter } = useAuthContext();

  const getSubscription = () => {
    setSubscriptionLinkLoading(true);
    if (!costCenter) {
      setSubscription(null);
      setSubscriptionLinkLoading(false);
      setStateSubscription('loading');
      return;
    }

    api
      .get(`/assinatura/${costCenter.id}`)
      .then(response => {
        setStateSubscription('purchased');
        setSubscriptionLinkLoading(false);
        setSubscription(response.data);
      })
      .catch(() => {
        setSubscription(null);
        setSubscriptionLinkLoading(false);
        setStateSubscription('not_purchased');
      });
  };

  const getSubscriptionDetailsLink = () => {
    api
      .get(`billing-portal/${costCenter?.id}`)
      .then(response => {
        setSubscriptionDetailsLink(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const statusInPtBr = subscription
    ? statusSubscriptionEnToPtBr[subscription.status as Status]
    : '';

  useEffect(() => {
    getSubscription();
  }, [costCenter]);

  return (
    <SubscriptionContext.Provider
      value={{
        subscription,
        stateSubscription,
        subscriptionDetailsLink,
        getSubscription,
        getSubscriptionDetailsLink,
        subscriptionLinkLoading,
        statusInPtBr,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
}

export { SubscriptionContext, SubscriptionProvider };
