import Box from '@mui/material/Box';

import PageMainContentBox from '../../components/PageMainContentBox';
import UserHistory from './UserHistory';
import OperatorHistory from './OperatorHistory';
import AdminHistory from './AdminHistory';

import useAuthContext from '../../hooks/useAuthContext';

import roleBasedRender from '../../utils/roleBasedRender';

// role based render: all
export default function History() {
  const { userInfo } = useAuthContext();
  const role = userInfo ? userInfo.user.roles[0].nome : '';

  return (
    <Box
      style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}
    >
      <PageMainContentBox title="Histórico" page="historico">
        {roleBasedRender(
          role,
          <UserHistory />,
          <OperatorHistory />,
          <AdminHistory />,
          null,
        )}
      </PageMainContentBox>
    </Box>
  );
}
