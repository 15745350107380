/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect, CSSProperties } from 'react';
import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Header from './Header';
import NavBar from './NavBar';

import useWhiteLabel from '../hooks/useWhiteLabel';

import COLORS from '../utils/colors';

type Props = {
  title: string;
  page:
    | 'inicio'
    | 'minha_conta'
    | 'minhas_pastas'
    | 'documentos'
    | 'planos'
    | 'historico'
    | 'usuarios'
    | 'termos_e_condicoes'
    | 'politica_de_privacidade'
    | 'centros-de-custo';
  overflow?: string;
};

const PageMainContentBox: React.FC<Props> = ({
  children,
  title,
  page,
  overflow,
}) => {
  const { whiteLabel } = useWhiteLabel();

  /* status of the header component */
  const [open] = useState<boolean>(
    JSON.parse(localStorage.getItem('isOpen') || 'true'),
  );

  const [conlicitaAuthNavbarWidth, setConlicitaAuthNavbarWidth] =
    useState<string>(
      localStorage.getItem('ConlicitaAuthNavbarWidth') || 'calc(100% - 280px)',
    );

  const [dattasignNavbarWidth, setDattasignNavbarWidth] = useState<string>(
    localStorage.getItem('DattasignNavbarWidth') || 'calc(100% - 240px)',
  );

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginLeft: '59px',
    } as CSSProperties,

    box: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 1,
      p: '12px 24px 0 24px',
      marginTop: '4rem',
      overflow: 'hidden',
      width: '100%',
      height: '95vh',
      backgroundColor: 'background.default',
    } as SxProps<Theme>,

    header: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },

    title: {
      fontSize: '1.6rem',
      color: 'primary.main',
      fontWeight: 'bold',
      alignSelf: 'baseline',
      marginBottom: '.5rem',
    },

    costCenter: {
      padding: '0.5rem',

      h2: {
        fontWeight: 500,
        color: COLORS.mediumGray5,
        fontSize: '0.875rem',
      },

      p: {
        fontWeight: 500,
        color: COLORS.black,
        fontSize: '1.25rem',
      },
    } as SxProps<Theme>,

    contentBox: {
      flexGrow: 1,
      backgroundColor: COLORS.white,
      border: `1px solid rgba(0, 0, 0, 0.12)`,
      borderRadius: '10px',
      padding: '24px 24px 0 24px',
      overflow: overflow ?? 'auto',
      width: '100%',
      height: '95vh',
      '::-webkit-scrollbar': {
        width: '10px',
      },
      '::-webkit-scrollbar-track': {
        background: COLORS.lightGray3,
        borderRadius: '50px',
      },
      '::-webkit-scrollbar-thumb': {
        background: COLORS.mediumGray2,
        borderRadius: '50px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: COLORS.mediumGray4,
      },
    },
  };

  useEffect(() => {
    if (whiteLabel === 'conlicitacao') {
      if (open) {
        localStorage.setItem('ConlicitaAuthNavbarWidth', 'calc(100% - 280px)');
      } else {
        localStorage.setItem('ConlicitaAuthNavbarWidth', 'calc(100% - 112px)');
      }

      if (open === null) {
        localStorage.setItem('ConlicitaAuthNavbarWidth', 'calc(100% - 280px)');
      }
    } else {
      if (open) {
        localStorage.setItem('DattasignNavbarWidth', 'calc(100% - 240px)');
      } else {
        localStorage.setItem('DattasignNavbarWidth', 'calc(100% - 73px)');
      }

      if (open === null) {
        localStorage.setItem('DattasignNavbarWidth', 'calc(100% - 240px)');
      }
    }
  }, [open]);

  return (
    <>
      <Header
        page={page}
        setConlicitaAuthNavbarWidth={setConlicitaAuthNavbarWidth}
        setDattasignNavbarWidth={setDattasignNavbarWidth}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginLeft: whiteLabel === 'conlicitacao' ? '59px' : '',
        }}
      >
        <NavBar
          navBarWidth={
            whiteLabel === 'conlicitacao'
              ? conlicitaAuthNavbarWidth
              : dattasignNavbarWidth
          }
        />

        <Box component="main" sx={styles.box}>
          <div style={styles.header}>
            <Typography sx={styles.title}>{title}</Typography>
          </div>

          <Box component="main" sx={styles.contentBox}>
            {children}
          </Box>
        </Box>
      </div>
    </>
  );
};

export default PageMainContentBox;
