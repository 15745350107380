import React from 'react';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';

import ContentSubTitle from '../../components/ContentSubTitle';
import VoidImage from '../../components/Images/VoidImage';

const useStyles = makeStyles({
  stack: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  imageLaptop: {
    width: '15rem',
  },

  imageDesktop: {
    width: '18rem',
  },
});

type Props = {
  direction?: 'column' | 'row';
  width?: string;
  gap?: string;
};

const ItsSoEmptyHere = ({ direction, width, gap }: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grid sx={{ width: '100%' }}>
      <Stack
        className={classes.stack}
        sx={{
          flexDirection: direction,
          width,
          gap,
          [theme.breakpoints.down(1300)]: {
            width: '100%',
          },
        }}
      >
        <VoidImage
          className={classes.imageDesktop}
          style={{
            width: '18rem',
            height: '10rem',
          }}
        />

        <Stack className={classes.stack}>
          <ContentSubTitle
            text="Está tão vazio aqui..."
            fontWeight="bold"
            fontSize=".9rem"
            margin="1rem auto .3rem auto"
            alignSelf="center"
          />
          <ContentSubTitle
            text="Crie um novo plano clicando no botão acima :)"
            fontWeight="bold"
            fontSize=".9rem"
            margin="1rem 0 .3rem 0"
            alignSelf="center"
          />
        </Stack>
      </Stack>
    </Grid>
  );
};

export default ItsSoEmptyHere;

ItsSoEmptyHere.defaultProps = {
  direction: 'column',
  width: '100%',
  gap: '2rem',
};
