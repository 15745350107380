import { createTheme } from '@mui/material/styles';
import { ptBR } from '@mui/material/locale';

import COLORS from '../utils/colors';

export default createTheme(
  {
    breakpoints: {
      values: {
        mobile: 0,
        tablet: 640,
        laptop: 1024,
        desktop: 1280,
        largedesktop: 1680,
      },
    },

    typography: {
      fontFamily: ['Lato', ' sans-serif'].join(','),
    },

    palette: {
      primary: {
        main: COLORS.prevLawDarkBlue,
        light: COLORS.prevLawBlue,
      },
      secondary: {
        main: COLORS.prevLawBlue,
      },
      error: {
        main: COLORS.redWarning,
      },
      background: {
        default: COLORS.background,
      },
    },
  },

  ptBR,
);
