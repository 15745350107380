/* eslint-disable react/require-default-props */
import React from 'react';
import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';

import COLORS from '../utils/colors';

interface Props {
  gap?: string;
  overflow?: string;
}

const RoleBasedMainBox: React.FC<Props> = ({ children, gap, overflow }) => {
  const styles = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: gap ?? '1.5rem',
    overflow: overflow ?? 'auto',
    marginBottom: '1.5rem',

    '::-webkit-scrollbar': {
      width: '10px',
    },
    '::-webkit-scrollbar-track': {
      background: COLORS.lightGray3,
      borderRadius: '50px',
    },
    '::-webkit-scrollbar-thumb': {
      background: COLORS.mediumGray2,
      borderRadius: '50px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: COLORS.mediumGray4,
    },
  } as SxProps<Theme>;

  return <Box sx={styles}>{children}</Box>;
};

export default RoleBasedMainBox;
