/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupIcon from '@mui/icons-material/Group';
import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import Signee from '../Signee';
import ActionButton from '../../../components/ActionButton';
import ContentTitle from '../../../components/ContentTitle';
import SigneesImage from '../../../components/Images/SigneesImage';

import useDialogContext from '../../../hooks/useDialogContext';
import useAuthContext from '../../../hooks/useAuthContext';
import useCostCenterContext from '../../../hooks/useCostCenterContext';
import useDocumentContext from '../../../hooks/useDocumentContext';

import { Signee as ISignee } from '../../../types/Signee';
import { LocationDocumentContext } from '../../../types/LocationDocumentContext';
import { AuthenticationType } from '../../../types/AuthenticationType';
import { Coordinates } from '../../../types/Coordinates';

import api from '../../../services/api';

import COLORS from '../../../utils/colors';
import { phoneMask } from '../../../utils/masks';
import { getPageCoordinates } from '../../../utils/pdfExpressHelpers';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 32,
  height: 18,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(14px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 14,
    height: 14,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

interface Authentication {
  label: string;
  value: string;
}

interface FormValues {
  nome: string;
  email: string;
  autenticacao: '' | AuthenticationType;
  numero: string;
}

export default function UserDocumentsAddSignee() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { showDialog } = useDialogContext();
  const { costCenter } = useAuthContext();
  const { userInformation } = useCostCenterContext();

  const location = useLocation();
  const stateLocation = location.state as LocationDocumentContext;

  const [authentications, setAuthentications] = useState<Authentication[]>([]);
  const [mySignees, setMySignees] = useState<ISignee[]>([]);

  const [signee, setSignee] = useState({
    nome: '',
    email: '',
    type: '',
    autenticacao: '' as '' | AuthenticationType,
    numero: '',
    order: 1,
  });

  const same = mySignees?.find(s => s.email === costCenter?.proprietario.email);

  useEffect(() => {
    if (costCenter) {
      setSignee({
        nome: same ? '' : costCenter?.proprietario.name,
        email: same ? '' : costCenter?.proprietario.email,
        type: '',
        autenticacao: '' as '' | AuthenticationType,
        numero: '',
        order: 1,
      });
    }
  }, [userInformation]);

  useEffect(() => {
    if (same) {
      setSignee({ ...signee, nome: '', email: '' });
    }
  }, [mySignees]);

  const [isSortingSignees, setIsSortingSignees] = useState(false);
  const [maxNumberToChooseOrder, setMaxNumberToChooseOrder] = useState(0);

  const [dropPoint, setDropPoint] = useState<Coordinates | null>(null);

  const [, forceUpdate] = useState({});

  const { webviewInstance, setXfdf } = useDocumentContext();

  const {
    register,
    handleSubmit,
    unregister,
    formState: { errors },
    setError,
    setFocus,
  } = useForm<FormValues>();

  const handleMaxNumberToChooseOrder = (
    currentSignees: ISignee[],
    valueChoosed?: number,
  ) => {
    if (maxNumberToChooseOrder <= 1) {
      setMaxNumberToChooseOrder(maxNumberToChooseOrder + 1);
      return;
    }

    if (valueChoosed && valueChoosed === maxNumberToChooseOrder) {
      setMaxNumberToChooseOrder(
        Math.min(currentSignees.length, maxNumberToChooseOrder + 1),
      );
      return;
    }
    const maxNumber = Math.max(...currentSignees.map(element => element.order));

    setMaxNumberToChooseOrder(Math.min(currentSignees.length, maxNumber + 1));
  };

  const handleChangeOrder = (currentEmail: string, value: number) => {
    const newSignees = [...mySignees];

    const indexSignee = newSignees.findIndex(
      element => element.email === currentEmail,
    );
    if (indexSignee !== -1) {
      newSignees[indexSignee].order = value;
    }

    setMySignees(newSignees);
    handleMaxNumberToChooseOrder(newSignees, value);
  };

  const getAuthenticationMethodsList = async () => {
    try {
      const response = await api.get(
        `/signatario/autenticacoes/${costCenter?.id}`,
      );

      const { value_autenticacoes, label_autenticacoes } = response.data;

      const autenticacoes = label_autenticacoes.map(
        (label: string, index: number) => ({
          label,
          value: value_autenticacoes[index],
        }),
      );

      setAuthentications(autenticacoes);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSigneeChanges = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setSignee({ ...signee, [e.target.name]: e.target.value });
  };

  const saveLocalSignees = () => {
    localStorage.setItem('user-file-signees', JSON.stringify(mySignees || []));
  };

  const getLocalSignees = () => {
    if (localStorage.getItem('user-file-signees') === null) {
      localStorage.setItem('user-file-signees', JSON.stringify([]));
      return;
    }

    const signeesLocal = JSON.parse(
      localStorage.getItem('user-file-signees') || '[]',
    );
    setMySignees(signeesLocal);
  };

  const getSortSettings = () => {
    const sortSettings = JSON.parse(
      localStorage.getItem('sort-settings') ||
        '{"isSortingSignees": false, "maxNumberToChooseOrder": 0}',
    );

    setMaxNumberToChooseOrder(sortSettings.maxNumberToChooseOrder);
    setIsSortingSignees(sortSettings.isSortingSignees);
  };

  const hasSigneeAlreadyBeenAdded = () => {
    const sameSignee = mySignees.filter(el => el.email === signee.email);
    return Boolean(sameSignee.length);
  };

  const onSubmit: SubmitHandler<FormValues> = () => {
    if (hasSigneeAlreadyBeenAdded()) {
      setError(
        'email',
        { type: 'focus', message: 'Este participante já foi adicionado' },
        { shouldFocus: true },
      );
      return;
    }

    setFocus('nome');

    const newSignees = [...mySignees, signee as ISignee];
    setMySignees([...mySignees, signee as ISignee]);

    handleMaxNumberToChooseOrder(newSignees);

    setSignee({
      nome: '',
      email: '',
      type: '',
      autenticacao: '',
      numero: '',
      order: 1,
    });
  };

  const validateAddSigneeForm = () => {
    if (signee.type === 'cc') {
      return signee.nome && signee.email && signee.type;
    }

    if (signee.autenticacao === 'whatsapp' || signee.autenticacao === 'sms') {
      return (
        signee.nome && signee.email && signee.autenticacao && signee.numero
      );
    }

    return signee.nome && signee.email && signee.autenticacao;
  };

  const canGoToNextStep = () => {
    if (!webviewInstance) {
      return false;
    }

    if (!mySignees.length) {
      return false;
    }

    const signeesUsingAnnotations = mySignees.filter(
      element =>
        element.autenticacao === 'assinatura_digital' ||
        element.autenticacao === 'certificado_digital',
    );

    if (!signeesUsingAnnotations) {
      return true;
    }

    const { Annotations, annotationManager } = webviewInstance.Core;

    let haveAllAnnotationsBeenAdded = true;
    signeesUsingAnnotations.forEach(signeeUsingAnnotations => {
      const stampAnnotations = annotationManager
        .getAnnotationsList()
        .filter(
          (annot: any) =>
            annot instanceof Annotations.StampAnnotation &&
            annot.getCustomData('email') === signeeUsingAnnotations.email,
        );

      if (!stampAnnotations.length) {
        haveAllAnnotationsBeenAdded = false;
      }
    });

    return haveAllAnnotationsBeenAdded;
  };

  const saveXfdf = () => {
    if (!canGoToNextStep()) return;

    const { annotationManager } = webviewInstance.Core;

    annotationManager
      .exportAnnotations({
        widgets: true,
        fields: true,
        links: false,
        useDisplayAuthor: false,
        generateInlineAppearances: false,
      })
      .then((xfdfData: string) => {
        setXfdf(xfdfData);
        localStorage.setItem('user-file-xfdf', xfdfData);
      });
  };

  const areSigneesSortedCorrectly = () => {
    const sortedSignees = mySignees.sort((a, b) => a.order - b.order);
    const auxSortedSignees = [{ order: 0 }, ...sortedSignees];

    for (let i = 0; i < auxSortedSignees.length - 1; i += 1) {
      if (auxSortedSignees[i + 1].order - auxSortedSignees[i].order > 1) {
        return false;
      }
    }

    return true;
  };

  const next = () => {
    saveXfdf();

    if (isSortingSignees && !areSigneesSortedCorrectly()) {
      showDialog('default', 'O ordenamento dos participantes está incorreto.');
      return;
    }

    navigate('/documentos/configuracoes', {
      state: {
        rawFile: stateLocation?.rawFile,
      },
    });
  };

  const dragOver = (e: any) => {
    e.preventDefault();
    return false;
  };

  const drop = (e: any) => {
    const { documentViewer } = webviewInstance.Core;
    const scrollElement = documentViewer.getScrollViewElement();
    const scrollLeft = scrollElement.scrollLeft || 0;
    const scrollTop = scrollElement.scrollTop || 0;

    const pageCoordinates = getPageCoordinates(
      webviewInstance,
      e.pageX + scrollLeft,
      e.pageY + scrollTop,
    );

    setDropPoint({ ...pageCoordinates });
    e.preventDefault();
    return false;
  };

  window.addEventListener('beforeunload', saveXfdf);

  useEffect(() => {
    getAuthenticationMethodsList();
    getLocalSignees();
    getSortSettings();

    return () => {
      window.removeEventListener('beforeunload', saveXfdf);
    };
  }, []);

  useEffect(() => {
    saveLocalSignees();
  }, [mySignees]);

  useEffect(() => {
    localStorage.setItem(
      'sort-settings',
      JSON.stringify({
        isSortingSignees,
        maxNumberToChooseOrder,
      }),
    );
  }, [isSortingSignees, maxNumberToChooseOrder]);

  useEffect(() => {
    if (signee) {
      unregister();
    }
  }, [signee]);

  useEffect(() => {
    if (webviewInstance) {
      const { annotationManager } = webviewInstance.Core;

      annotationManager.addEventListener(
        'annotationChanged',
        (annotations: any, action: string) => {
          if (action === 'add' || action === 'delete') {
            forceUpdate({});
          }
        },
      );

      const iframeDoc = webviewInstance.UI.iframeWindow.document;
      iframeDoc.addEventListener('dragover', dragOver);
      iframeDoc.addEventListener('drop', drop);
    }
  }, [webviewInstance]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          marginBottom: '1rem',
        }}
      >
        <ContentTitle
          text="Adicionar Participante"
          justify="flex-start"
          margin="0"
          align="start"
        />
        <GroupAddIcon sx={{ color: COLORS.mediumGray5 }} />
      </Box>

      <form
        noValidate
        id="formulario"
        method="post"
        onSubmit={handleSubmit(onSubmit)}
        style={{
          width: '100%',
          marginBottom: '1rem',
          backgroundColor: COLORS.background,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: `calc(100% - 30vh)`,
            margin: '0 auto',
            gap: '10px',
            backgroundColor: COLORS.background,
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <FormControl
            sx={{
              width: '94%',
              alignSelf: 'flex-start',
              margin: '1rem auto 0 auto',
            }}
          >
            <TextField
              autoFocus
              id="signee_name"
              label="Nome do participante"
              type="text"
              variant="standard"
              sx={{
                '.MuiInput-root': {
                  backgroundColor: 'transparent !important',
                },
              }}
              style={{ fontSize: '.9rem', width: '100%' }}
              {...register('nome', {
                required: 'Por favor, informe o nome do participante',
                minLength: {
                  value: 8,
                  message: 'O nome deve conter pelo menos 8 caracteres',
                },
                pattern: {
                  value: /^[A-zÀ-ú ']+$/,
                  message: 'Por favor, informe um nome válido',
                },
              })}
              value={signee.nome}
              onChange={handleSigneeChanges}
              error={Boolean(errors.nome)}
              helperText={errors.nome?.message}
            />
          </FormControl>

          <FormControl
            sx={{ width: '94%', alignSelf: 'flex-start', margin: '.2rem auto' }}
          >
            <TextField
              id="signee_email"
              label="Email do participante"
              type="email"
              variant="standard"
              style={{ fontSize: '.9rem', width: '100%' }}
              {...register('email', {
                required: 'Por favor, informe o email do participante',
                pattern: {
                  value:
                    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                  message: 'Por favor, informe um email válido',
                },
              })}
              value={signee.email}
              onChange={handleSigneeChanges}
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
            />
          </FormControl>

          <FormControl
            variant="standard"
            sx={{
              width: '94%',
              alignSelf: 'flex-start',
              margin: '.2rem auto',
            }}
          >
            <InputLabel id="selectLabel">O participante irá</InputLabel>
            <Select
              label="O participante irá"
              labelId="selectLabel"
              id="selectLabel"
              type="text"
              value={signee.type}
              onChange={e =>
                setSignee({
                  ...signee,
                  type: e.target.value,
                })
              }
              inputProps={{
                'aria-label': 'Without label',
              }}
            >
              <MenuItem disabled value="">
                <em>Selecione...</em>
              </MenuItem>
              <MenuItem
                key="toSign"
                value="toSign"
                sx={{
                  '&.Mui-selected': {
                    bgcolor: `${theme.palette.primary.main} !important`,
                    color: `${COLORS.white} !important`,
                  },
                  ':hover': {
                    bgcolor: 'primary.light',
                  },
                  ':focus': {
                    bgcolor: 'primary.main',
                    color: COLORS.white,
                  },
                  ':active': {
                    bgcolor: 'primary.main',
                    color: COLORS.white,
                  },
                }}
              >
                Assinar
              </MenuItem>
              <MenuItem
                key="cc"
                value="cc"
                sx={{
                  '&.Mui-selected': {
                    bgcolor: `${theme.palette.primary.main} !important`,
                    color: `${COLORS.white} !important`,
                  },
                  ':hover': {
                    bgcolor: 'primary.light',
                  },
                  ':focus': {
                    bgcolor: 'primary.main',
                    color: COLORS.white,
                  },
                  ':active': {
                    bgcolor: 'primary.main',
                    color: COLORS.white,
                  },
                }}
              >
                Receber uma cópia
              </MenuItem>
            </Select>
            {errors.autenticacao && (
              <FormHelperText error>
                {errors.autenticacao.message}
              </FormHelperText>
            )}
          </FormControl>

          {signee.type === 'toSign' && (
            <FormControl
              variant="standard"
              sx={{
                width: '94%',
                alignSelf: 'flex-start',
                margin: '.2rem auto',
              }}
              error={Boolean(errors.autenticacao)}
            >
              <InputLabel id="selectLabel">
                O participante será autenticado através de
              </InputLabel>
              <Select
                label="Como o participante será autenticado"
                labelId="selectLabel"
                id="selectLabel"
                {...register('autenticacao', {
                  required: 'Por favor, informe o nome do participante',
                })}
                type="text"
                value={signee.autenticacao}
                onChange={e =>
                  setSignee({
                    ...signee,
                    autenticacao: e.target.value as AuthenticationType,
                  })
                }
                inputProps={{
                  'aria-label': 'Without label',
                }}
              >
                <MenuItem disabled value="">
                  <em>Selecione...</em>
                </MenuItem>
                {authentications.map(authentication => (
                  <MenuItem
                    key={authentication.value}
                    value={authentication.value}
                    sx={{
                      '&.Mui-selected': {
                        bgcolor: `${theme.palette.primary.main} !important`,
                        color: `${COLORS.white} !important`,
                      },
                      ':hover': {
                        bgcolor: 'primary.light',
                      },
                      ':focus': {
                        bgcolor: 'primary.main',
                        color: COLORS.white,
                      },
                      ':active': {
                        bgcolor: 'primary.main',
                        color: COLORS.white,
                      },
                    }}
                  >
                    {authentication.label}
                  </MenuItem>
                ))}
              </Select>
              {errors.autenticacao && (
                <FormHelperText error>
                  {errors.autenticacao.message}
                </FormHelperText>
              )}
            </FormControl>
          )}

          {(signee.autenticacao === 'sms' ||
            signee.autenticacao === 'whatsapp') &&
            signee.type === 'toSign' && (
              <FormControl
                sx={{
                  width: '94%',
                  alignSelf: 'flex-start',
                  margin: '.2rem auto',
                }}
              >
                <TextField
                  label="Telefone do participante (digite apenas os números)"
                  variant="standard"
                  style={{ fontSize: '.9rem', width: '100%' }}
                  {...register('numero', {
                    required: {
                      value:
                        signee.autenticacao === 'whatsapp' ||
                        signee.autenticacao === 'sms',
                      message: 'Por favor, informe o telefone',
                    },
                    minLength: {
                      value: 14,
                      message: 'O telefone deve conter ao menos 10 dígitos',
                    },
                    maxLength: {
                      value: 15,
                      message: 'O telefone deve conter 11 dígitos',
                    },
                  })}
                  value={signee.numero}
                  onChange={e =>
                    setSignee({
                      ...signee,
                      numero: phoneMask(e.target.value),
                    })
                  }
                  error={Boolean(errors.numero)}
                  helperText={errors.numero?.message}
                />
              </FormControl>
            )}

          <Tooltip
            title={!validateAddSigneeForm() ? 'Preencha os campos' : ''}
            placement="bottom"
          >
            <Box
              sx={{
                width: '17.3rem !important',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '1rem 0 1rem 0',
                height: '4vh',
              }}
            >
              <ActionButton
                disabled={!validateAddSigneeForm()}
                type="submit"
                text="Adicionar"
              />
            </Box>
          </Tooltip>
        </Box>
      </form>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          marginBottom: '1rem',
          justifyContent: 'space-between',
          gap: '1rem',
          width: '100%',
          [theme.breakpoints.between(640, 1240)]: {
            flexDirection: 'row',
            alignItems: 'center',
          },
        }}
      >
        <Stack
          direction="row"
          sx={{
            gap: '0.625rem',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <ContentTitle
            text="Participantes adicionados"
            justify="flex-start"
            margin="0"
            align="start"
          />
          <GroupIcon sx={{ color: COLORS.mediumGray5 }} />
        </Stack>

        {mySignees.length > 1 && (
          <Stack direction="row" spacing={1} alignItems="center">
            <AntSwitch
              checked={isSortingSignees}
              theme={theme}
              onChange={() => setIsSortingSignees(prevValue => !prevValue)}
            />
            <Typography style={{ fontSize: '.9rem' }}>
              Ordenar participantes
            </Typography>
          </Stack>
        )}
      </Box>

      {mySignees.length ? (
        <Grid
          sx={{
            height:
              signee.autenticacao === 'sms' ||
              signee.autenticacao === 'whatsapp'
                ? '22vh'
                : '29vh',
            overflow: 'auto',
            backgroundColor: COLORS.background,
            '::-webkit-scrollbar': {
              width: '10px',
            },
            '::-webkit-scrollbar-track': {
              background: COLORS.lightGray3,
              borderRadius: '50px',
            },
            '::-webkit-scrollbar-thumb': {
              background: COLORS.mediumGray2,
              borderRadius: '50px',
            },
          }}
        >
          {mySignees.map(thisSignee => (
            <Signee
              signee={thisSignee}
              mySignees={mySignees}
              setMySignees={setMySignees}
              key={thisSignee.email}
              dropPoint={dropPoint}
              setDropPoint={setDropPoint}
              isSortingSignees={isSortingSignees}
              maxNumberToChooseOrder={maxNumberToChooseOrder}
              setMaxNumberToChooseOrder={setMaxNumberToChooseOrder}
              handleChangeOrder={handleChangeOrder}
            />
          ))}
        </Grid>
      ) : (
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height:
              signee.autenticacao === 'sms' ||
              signee.autenticacao === 'whatsapp'
                ? '22vh'
                : '29vh',
            backgroundColor: COLORS.background,
          }}
        >
          <SigneesImage style={{ width: '14rem', margin: '1rem auto' }} />

          <Box
            sx={{
              width: '100%',
              margin: '0 auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: COLORS.mediumGray5,
                fontWeight: 'bold',
                margin: '.3rem',
              }}
            >
              Adicione as pessoas que participarão deste documento, inclusive
              você.
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                color: COLORS.mediumGray5,
                fontWeight: 'bold',
                margin: '.3rem',
              }}
            >
              Para avançar, você deve adicionar pelo menos um participante.
            </Typography>
          </Box>
        </Grid>
      )}

      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          gap: '8px',
          width: '95%',
          height: '8vh',
          margin: '0',
        }}
      >
        <Box
          sx={{
            width: '25rem !important',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginTop: '2rem',
          }}
        >
          <ActionButton
            color={COLORS.mediumGray2}
            hoverColor={COLORS.mediumGray4}
            type="button"
            text="Cancelar"
            action={() => {
              showDialog(
                'cancelFlowDocument',
                'Tem certeza que quer cancelar? Seu trabalho neste documento será perdido.',
              );
            }}
          />
        </Box>

        <Box
          sx={{
            width: '25rem !important',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '2rem',
          }}
        >
          <ActionButton
            color={canGoToNextStep() ? 'primary.main' : 'primary.light'}
            type="button"
            text="Avançar"
            disabled={!canGoToNextStep()}
            action={next}
          />
        </Box>
      </Grid>
    </>
  );
}
