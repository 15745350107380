import Typography from '@mui/material/Typography';

import RoleBasedMainBox from '../../components/RoleBasedMainBox';

export default function OperatorAndAdminUsers() {
  return (
    <RoleBasedMainBox>
      <Typography>Componente usuarios do operator ou admin</Typography>
    </RoleBasedMainBox>
  );
}
