/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Container = styled.div`
  .monitoring-notify-balloon {
    position: absolute;
    right: .8rem;
    top: .3rem;
    font-size: 0.8em;
    padding: 0.1em 0.3em;
    font-weight: 400;
    z-index: 11;
  }
  
  .toggle-menu {
    position: absolute;
    margin-left: 70px;
    margin-top: 18px;
  }

  .open-menu {
    color: #354153;
  }

  .header-toolbar {
    height: 56px;
    margin-left: 56px;
    width: calc(100% - 56px);
    padding: 0.5em 1em;
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.07);

    .nav-link {
      color: #354153;
    }

    @media only screen and (max-width: 768px) {
      margin-left: 0;
      width: 100%;
    }
  }

  .ml-auto, .mx-auto {
      margin-left: auto!important;
  }

  .mr-2, .mx-2 {
    margin-right: 0.5rem!important;
  }

  .sidebar-menu {
    list-style-type: none;
    width: 59px;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1040;
    color: #fff;
    transition: width 0.3s;
    white-space: nowrap;
    display: block;
    background-color: #354153;

    @media only screen and (max-width: 768px) {
      left: -59px;
    }

    .box-logo {
      width: 100%;
      padding: 7.2px;
      height: 59px;
      display: inline-block;
      background: #2A3442;

      .close-menu {
        display: none;
        color: #fff;
      }

      .icon-logo {
        display: inline-block;
        overflow: hidden;

        img {
          max-height: 30px;
        }
      }
    }

    .nav-link {
      color: #fff;
      border-left: 5px solid transparent;
      padding: 0.5em 0 0.5em 0;
      display: flex;
      align-items: center;
      width: 59px;

      i {
        font-size: 18px;
      }

      &:hover {
        background: #435167;
      }

      .nav-icon {
        height: 25px;
        width: 59px;
        display: block;
        text-align: center;
        margin-left: -5px;

        img {
          height: 25px;
        }
      }

      .nav-name,
      .nav-arrow {
        opacity: 0;
        display: none;
        transition: opacity 0.5s;
      }

      .nav-arrow {
        transform: rotate(180deg);
        transition: transform 0.5s;
      }

      .oi-chevron-bottom {
        font-size: 0.6rem;
      }

      &.collapsed {
        .nav-arrow {
          transform: rotate(0deg);
        }
      }
    }

    .second-menu {
      background: #3d495d;
      .nav-link {
        padding-left: 25px;
      }
    }

    .nav-current {
      .nav-link {
        border-color: #03D6A5;
      }
    }

    &.open-menu {
      width: 300px;
      box-shadow: 0.25rem 0 0.75rem rgba(0, 0, 0, 0.3);

      .scroll-menu {
        list-style-type: none;
        overflow-y: scroll;
        scrollbar-width: thin;
        height: calc(100vh - 59px);
        scrollbar-color: #435167 #354153;

        &::-webkit-scrollbar {
          width: 12px;
        }

        &::-webkit-scrollbar-track {
          background: #354153;
        }

        &::-webkit-scrollbar-thumb {
          background: #435167;
          border-radius: 10px;
          border: 3px solid #354153;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #2A3442;
        }
      }

      @media only screen and (max-width: 768px) {
        left: 0;
      }

      .monitoring-notify.badge {
        right: 0.7rem;
        top: 0.6rem;
      }

      .box-logo {
        .icon-logo {
          overflow: auto;
        }

        .close-menu {
          display: flex;
          &:hover {
            text-decoration: none;
          }
        }
      }

      .nav-link {
        width: 100%;

        &:after,
        &:before {
          display: none;
        }

        .nav-name,
        .nav-arrow {
          opacity: 1;
          display: inline;
        }
      }
    }

`;
