/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';

import ActionButton from '../../components/ActionButton';
import VoidImage from '../../components/Images/VoidImage';
import ErrorImage from '../../components/Images/ErrorImage';
import SuccessImage from '../../components/Images/SuccessImage';

import useAuthContext from '../../hooks/useAuthContext';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const useStyles = makeStyles({
  dialog: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
  },

  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  text: {
    textAlign: 'justify',
    fontWeight: 'bold',
    fontSize: '.925rem',
  },

  buttonDelete: {
    '&.MuiButtonBase-root': {
      backgroundColor: COLORS.red,
      color: COLORS.white,
      borderRadius: '.625rem',
      height: '2rem',
      width: '2.25rem',

      '& svg': {
        fontSize: '1.25rem',
      },

      '&:hover': {
        backgroundColor: COLORS.lightRed,
        transition: '0.3s',
      },
    },
  },

  dialogActions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '1rem 0 0 1rem',
    alignSelf: 'end',
    marginTop: '1rem',
    gap: '.5rem',
  },
});

const DeleteAccountModal: React.FC = () => {
  const classes = useStyles();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSuccessfulModalOpen, setIsSuccessfulModalOpen] = useState(false);
  const [image, setImage] = useState('void');
  const [message, setMessage] = useState(
    'Tem certeza de que deseja excluir sua conta? Esta ação será irreversível! Você perderá todos os documentos e dados associados a ela!',
  );
  const [isDeleting, setIsDeleting] = useState(false);

  const { handleLogout } = useAuthContext();

  const handleDeleteAccount = async () => {
    setIsSuccessfulModalOpen(false);
    setIsDeleting(true);

    try {
      await api.delete(`usuario/excluir`);
      setIsDeleting(false);
      setIsSuccessfulModalOpen(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setIsDeleting(false);
      setImage('error');
      setMessage('Não foi possível excluir a conta.');

      if (error.message) console.log(error);
    }
  };

  return (
    <>
      <ActionButton
        text="Excluir conta"
        type="button"
        margin="0"
        mobilewidth="12rem"
        tabletwidth="12rem"
        laptopwidth="14rem"
        desktopwidth="16rem"
        color={COLORS.red}
        hoverColor={COLORS.lightRed}
        icon={<DeleteIcon sx={{ color: COLORS.white, fontSize: '1.3rem' }} />}
        action={() => setIsDeleteModalOpen(true)}
      />

      <Dialog
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        className={classes.dialog}
        sx={{ width: '30rem' }}
      >
        <DialogTitle>
          {image === 'void' ? 'Excluir conta' : 'Atenção!'}
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          {image === 'void' ? (
            <VoidImage
              style={{
                width: '12rem',
                height: '12rem',
                margin: '0 auto',
              }}
            />
          ) : (
            <ErrorImage
              style={{
                width: '12rem',
                height: '12rem',
                margin: '0 auto',
              }}
            />
          )}

          <div
            style={{
              width: '24rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              className={classes.text}
              color={COLORS.black}
              sx={{ marginTop: '2rem', fontSize: '.925rem' }}
              variant="subtitle2"
            >
              {message}
            </Typography>
          </div>
        </DialogContent>

        <DialogActions sx={{ padding: '1rem 2rem 2rem' }}>
          {message !== 'Não foi possível excluir a conta.' ? (
            <>
              <Button
                onClick={() => setIsDeleteModalOpen(false)}
                sx={{
                  width: '7rem',
                  color: COLORS.mediumGray3,
                  '&:hover': {
                    backgroundColor: COLORS.lightGray2,
                  },
                }}
              >
                Cancelar
              </Button>

              {isDeleting ? (
                <LoadingButton
                  loading
                  loadingPosition="center"
                  sx={{
                    width: '7rem',
                    height: '2.25rem',
                    color: COLORS.white,
                    backgroundColor: COLORS.redWarning,
                    '&:hover': {
                      backgroundColor: COLORS.lightRed,
                    },
                  }}
                />
              ) : (
                <Button
                  endIcon={<DeleteIcon fontSize="large" />}
                  onClick={handleDeleteAccount}
                  sx={{
                    width: '7rem',
                    color: COLORS.white,
                    backgroundColor: COLORS.redWarning,
                    '&:hover': {
                      backgroundColor: COLORS.lightRed,
                    },
                  }}
                >
                  Excluir
                </Button>
              )}
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                marginBottom: '1rem',
              }}
            >
              <Button
                onClick={() => {
                  setIsSuccessfulModalOpen(false);
                  setIsDeleteModalOpen(false);
                }}
                sx={{
                  color: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'primary.light',
                  },
                }}
              >
                Ok
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>

      {isSuccessfulModalOpen && (
        <Dialog
          open={isSuccessfulModalOpen}
          onClose={() => setIsSuccessfulModalOpen(false)}
          className={classes.dialog}
          sx={{ width: '30rem' }}
        >
          <DialogTitle>Conta excluída!</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <SuccessImage
              style={{ width: '12rem', height: '12rem', margin: '0 auto' }}
            />

            <div
              style={{
                width: '24rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                className={classes.text}
                sx={{ marginTop: '.5rem', color: 'primary.main' }}
                variant="subtitle2"
              >
                Conta excluída com sucesso!
              </Typography>

              <Typography
                className={classes.text}
                sx={{ marginTop: '.5rem', color: 'primary.main' }}
                variant="subtitle2"
              >
                Todas as suas informações foram deletadas.
              </Typography>

              <Typography
                className={classes.text}
                sx={{ marginTop: '.5rem', color: 'primary.main' }}
                variant="subtitle2"
              >
                Você será redirecionado.
              </Typography>
            </div>
          </DialogContent>

          <DialogActions sx={{ padding: '1rem', margin: '1rem auto' }}>
            <Button
              onClick={() => {
                handleLogout();
              }}
              sx={{
                color: 'primary.main',
                '&:hover': {
                  backgroundColor: 'primary.light',
                },
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default DeleteAccountModal;
