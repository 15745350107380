/* eslint-disable camelcase */
import FileSaver from 'file-saver';

import api from '../../../services/api';

type SignedAndReportFileResponse = {
  signed_document: string | null;
  report: string | null;
  certified_document: string | null;
};

const downloadSignedAndReportFile = async (
  documentId: string | number,
  fileName: string,
) => {
  try {
    const response = await api.get(`document_and_report/${documentId}`);
    const { certified_document, report, signed_document } =
      response.data as SignedAndReportFileResponse;

    const [newFileName] = fileName.split('.');

    if (certified_document) {
      FileSaver.saveAs(
        `data:application/pdf;base64,${certified_document}`,
        `${newFileName}_certificado.pdf`,
      );
    }

    if (signed_document) {
      FileSaver.saveAs(
        `data:application/pdf;base64,${signed_document}`,
        `${newFileName}_assinado.pdf`,
      );
    }

    if (report) {
      FileSaver.saveAs(
        `data:application/pdf;base64,${report}`,
        `${newFileName}_relatorio.pdf`,
      );
    }
  } catch (error) {
    console.log(error);
  }
};

export default downloadSignedAndReportFile;
