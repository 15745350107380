import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import TableContainer from '@mui/material/TableContainer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import makeStyles from '@mui/styles/makeStyles';

import TabPanel from './TabPanel/TabPanel';

import useAuthContext from '../../hooks/useAuthContext';
import useDocumentContext from '../../hooks/useDocumentContext';

import { Document } from '../../types/Document';

import api from '../../services/api';

import COLORS from '../../utils/colors';
import downloadSignedAndReportFile from './User/downloadSignedAndReportFile';

const useStyles = makeStyles({
  headerTable: {
    '& th': {
      border: 0,
      color: COLORS.black,
      fontSize: '1.175rem',
    },
  },

  row: {
    '& td': {
      fontSize: '1.025rem',
      borderBottom: `1px solid ${COLORS.lightGray5}`,
      color: COLORS.mediumGray5,
      fontWeight: 500,
    },
  },

  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    columnGap: '0.75rem',
  },

  buttonDownload: {
    '&.MuiButtonBase-root': {
      backgroundColor: COLORS.blue,
      color: COLORS.white,
      borderRadius: '.625rem',
      height: '2rem',
      width: '2.25rem',

      '& svg': {
        fontSize: '1.25rem',
      },

      '&:hover': {
        backgroundColor: COLORS.lighterBlue,
        transition: '0.3s',
      },

      '&.Mui-disabled': {
        backgroundColor: COLORS.lighterBlue,
        color: COLORS.white,
      },
    },
  },

  buttonDelete: {
    '&.MuiButtonBase-root': {
      backgroundColor: COLORS.red,
      color: COLORS.white,
      borderRadius: '.625rem',
      height: '2rem',
      width: '2.25rem',

      '& svg': {
        fontSize: '1.25rem',
      },

      '&:hover': {
        backgroundColor: COLORS.lightRed,
        transition: '0.3s',
      },
    },
  },

  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
  },

  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
  },

  stack: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  imageDesktop: {
    width: '18rem',
  },
});

interface Props {
  isDragActive: boolean;
  page: number;
  setPage: (page: number) => void;
  rowsPerPage: number;
  documents: Document[] | null | undefined;
  setDocuments: (documents: Document[] | null) => void;
  totalDocuments: number;
  search: string;
}

export interface LoadingSignedAndReportFile {
  [key: number]: boolean;
}

interface LocationState {
  valueTab: number;
}

const filter = ['', 'toSign', 'waitingSignees', 'signed'];

const DocumentGrid: React.FC<Props> = ({
  isDragActive,
  page,
  setPage,
  rowsPerPage,
  documents,
  setDocuments,
  totalDocuments,
  search,
}) => {
  const classes = useStyles();

  const location = useLocation();
  const locationState = location.state as LocationState;

  const [valueTab, setValueTab] = useState(locationState?.valueTab || 0);

  const [isLoading, setIsLoading] = useState(false);

  const { setTotalDocuments } = useDocumentContext();

  const [
    isLoadingDownloadSignedAndReportFile,
    setIsLoadingDownloadSignedAndReportFile,
  ] = useState({} as LoadingSignedAndReportFile);

  const { costCenter } = useAuthContext();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleTabChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValueTab: number,
  ) => {
    setValueTab(newValueTab);
    setPage(0);
  };

  const loadDocuments = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`documentos/${costCenter?.id}`, {
        params: {
          pagina: page + 1,
          limite: rowsPerPage,
          filtro: filter[valueTab] || '',
          search,
        },
      });
      setDocuments(response.data as Document[]);

      const xTotalCount = response.headers['x-total-count'];
      setTotalDocuments(Number(xTotalCount));
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadDocuments();
  }, [page, valueTab, search]);

  const handleDownloadSignedAndReportFile: (
    documentId: number,
    fileName: string,
  ) => void = async (documentId: number, fileName: string) => {
    setIsLoadingDownloadSignedAndReportFile({
      [documentId]: true,
    });

    await downloadSignedAndReportFile(documentId, fileName);

    delete isLoadingDownloadSignedAndReportFile[documentId];
    setIsLoadingDownloadSignedAndReportFile(
      isLoadingDownloadSignedAndReportFile,
    );
  };

  return (
    <Grid
      className={classes.container}
      sx={{ height: '100%', opacity: isDragActive ? '0.1' : 1 }}
    >
      <TableContainer
        className={classes.tableContainer}
        sx={{
          '::-webkit-scrollbar': {
            width: '10px',
          },
          '::-webkit-scrollbar-track': {
            background: COLORS.lightGray3,
            borderRadius: '50px',
          },
          '::-webkit-scrollbar-thumb': {
            background: COLORS.mediumGray2,
            borderRadius: '50px',
          },
          '::-webkit-scrollbar-thumb:hover': {
            background: COLORS.mediumGray3,
          },
        }}
      >
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
          width="100%"
        >
          <Box sx={{ maxWidth: 600 }}>
            <Tabs
              variant="scrollable"
              scrollButtons={false}
              aria-label="Trocar contextos dos documentos"
              onChange={handleTabChange}
              value={valueTab}
              sx={{
                '& .MuiTab-root': {
                  color: COLORS.mediumGray5,

                  '&.Mui-selected': {
                    color: 'primary.main',
                  },
                },
              }}
            >
              <Tab label="Todos" />
              <Tab label="A assinar" />
              <Tab label="Esperando signatários" />
              <Tab label="Finalizados" />
            </Tabs>
          </Box>
        </Stack>

        <Divider
          orientation="horizontal"
          style={{ marginTop: '.2rem', width: '100%' }}
        />

        <TabPanel
          isLoading={isLoading}
          page={page}
          handleChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          isLoadingDownloadSignedAndReportFile={
            isLoadingDownloadSignedAndReportFile
          }
          handleDownloadSignedAndReportFile={handleDownloadSignedAndReportFile}
          updateDocuments={loadDocuments}
          documents={documents}
          totalDocuments={totalDocuments}
        />
      </TableContainer>
    </Grid>
  );
};

export default DocumentGrid;
