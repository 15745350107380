import { Routes, Route } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import Home from '../pages/Home/Home';
import Login from '../pages/Login/Login';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import RedefinePassword from '../pages/ForgotPassword/RedefinePassword';
import EmailSentForPasswordRecovery from '../pages/ForgotPassword/EmailSentForPasswordRecovery';
import Register from '../pages/Register/Register';
import MyAccount from '../pages/MyAccount/MyAccount';
import Documents from '../pages/Documents/Documents';
import ConlicitacaoTermsAndConditions from '../pages/ConlicitacacaoTermsAndConditions';
import ConlicitacaoPrivacyPolicy from '../pages/ConlicitacaoPrivacyPolicy';
import UserDocumentsFirstScreen from '../pages/Documents/User/UserDocumentsFirstScreen';
import UserDocumentsAddSignee from '../pages/Documents/User/UserDocumentsAddSignee';
import UserDocumentsSettings from '../pages/Documents/User/UserDocumentsSettings';
import UserDocumentsMessage from '../pages/Documents/User/UserDocumentsMessage';
import UserDocumentsSuccess from '../pages/Documents/User/UserDocumentsSuccess';
import UserDocumentsSign from '../pages/Documents/User/UserDocumentsSign';
import Folders from '../pages/MyFolders/Folders';
import UserFolders from '../pages/MyFolders/UserFolders';
import UserFolderFiles from '../pages/MyFolders/UserFolderFiles';
import UserSubFolderFiles from '../pages/MyFolders/UserSubfolderFiles';
import SearchQueryFilesPage from '../pages/MyFolders/SearchQueryFilesPage';
import SubscriptionPlans from '../pages/SubscriptionPlans/SubscriptionPlans';
import History from '../pages/History/History';
import Users from '../pages/Users/Users';
import NotFound from '../pages/NotFound';
import AddressForm from '../pages/SubscriptionPlans/AddressForm';
import Payment from '../pages/SubscriptionPlans/Payment';
import SuccessfullRegisterMailConfirmed from '../pages/Register/SuccessfullRegisterMailConfirmed';
import SuccessfullRegister from '../pages/Register/SuccessfullRegister';
import ChooseSubscriptionPlan from '../pages/SubscriptionPlans/ChooseSubscriptionPlan';
import PublicSign from '../pages/Sign/Sign';
import ViewDocumentInPublicSign from '../pages/Sign/ViewDocument';
import ConfirmYourDataInPublicSign from '../pages/Sign/ConfirmYourData';
import VerificationCodeInPublicSign from '../pages/Sign/VerificationCode';
import SuccessInPublicSign from '../pages/Sign/Success';
import ErrorInPublicSign from '../pages/Sign/Error';
import CostCenters from '../pages/CostCenters/CostCenters';
import Validator from '../pages/Validator';

import DeniedAccess from '../components/DeniedAccess';

import useWhiteLabel from '../hooks/useWhiteLabel';

export default function MyRoutes() {
  const { whiteLabel } = useWhiteLabel();

  return (
    <Routes>
      <Route path="/" element={<PublicRoute component={Login} />} />
      <Route path="/login/:token" element={<PublicRoute component={Login} />} />

      <Route path="/forgot_password" element={<ForgotPassword />} />
      <Route
        path="/forgot_password/sent"
        element={<EmailSentForPasswordRecovery />}
      />
      <Route
        path="/forgot_password/redefinir_senha"
        element={<RedefinePassword />}
      />

      <Route path="/register" element={<PublicRoute component={Register} />} />
      <Route
        path="/register_success"
        element={<PublicRoute component={SuccessfullRegister} />}
      />
      <Route
        path="/register/:token"
        element={<PublicRoute component={Register} />}
      />

      <Route path="/sign/:token" element={<PublicSign />}>
        <Route index element={<ViewDocumentInPublicSign />} />
        <Route path="confirmation" element={<ConfirmYourDataInPublicSign />} />
        <Route path="verification" element={<VerificationCodeInPublicSign />} />
        <Route path="success" element={<SuccessInPublicSign />} />
        <Route path="error" element={<ErrorInPublicSign />} />
      </Route>

      <Route
        path="/email_confirmado"
        element={<SuccessfullRegisterMailConfirmed />}
      />

      <Route path="/inicio" element={<ProtectedRoute component={Home} />} />

      <Route
        path="/minha_conta"
        element={
          <ProtectedRoute
            component={whiteLabel === 'conlicitacao' ? DeniedAccess : MyAccount}
          />
        }
      />

      <Route
        path="/historico"
        element={<ProtectedRoute component={History} />}
      />

      {/* user only */}
      <Route
        path="/documentos"
        element={<ProtectedRoute component={Documents} />}
      >
        <Route index element={<UserDocumentsFirstScreen />} />
        <Route path="signatarios" element={<UserDocumentsAddSignee />} />
        <Route path="configuracoes" element={<UserDocumentsSettings />} />
        <Route path="mensagem" element={<UserDocumentsMessage />} />
        <Route path="documento_enviado" element={<UserDocumentsSuccess />} />
      </Route>

      <Route
        path="/documentos/:id"
        element={<ProtectedRoute component={UserDocumentsSign} />}
      />

      <Route
        path="/minhas_pastas"
        element={<ProtectedRoute component={Folders} />}
      >
        <Route index element={<UserFolders />} />
        <Route path="/minhas_pastas/:costId" element={<UserFolderFiles />} />
        <Route
          path="/minhas_pastas/:costId/search"
          element={<SearchQueryFilesPage />}
        />
        <Route
          path="/minhas_pastas/:costId/content"
          element={<UserSubFolderFiles />}
        />
      </Route>

      {/* user | admin */}
      {whiteLabel === 'conlicitacao' ||
      whiteLabel === 'codata' ||
      whiteLabel === 'prevLaw' ? (
        <Route
          path="/planos"
          element={<ProtectedRoute component={DeniedAccess} />}
        >
          <Route index element={<DeniedAccess />} />
          <Route path="endereco" element={<DeniedAccess />} />
          <Route path="pagamento" element={<DeniedAccess />} />
        </Route>
      ) : (
        <Route
          path="/planos"
          element={<ProtectedRoute component={SubscriptionPlans} />}
        >
          <Route index element={<ChooseSubscriptionPlan />} />
          <Route path="endereco" element={<AddressForm />} />
          <Route path="pagamento" element={<Payment />} />
        </Route>
      )}

      {whiteLabel === 'conlicitacao' && (
        <Route
          path="/termos_e_condicoes"
          element={
            <ProtectedRoute component={ConlicitacaoTermsAndConditions} />
          }
        />
      )}
      {whiteLabel === 'conlicitacao' && (
        <Route
          path="/politica_de_privacidade"
          element={<ProtectedRoute component={ConlicitacaoPrivacyPolicy} />}
        />
      )}

      {/* operator | admin */}
      <Route path="/usuarios" element={<ProtectedRoute component={Users} />} />
      <Route
        path="/centros-de-custo"
        element={<ProtectedRoute component={CostCenters} />}
      />

      <Route path="/validador" element={<Validator />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
