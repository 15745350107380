/* eslint-disable @typescript-eslint/no-explicit-any */
import { Outlet } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import FolderAndDocumentDragLayer from './FolderAndDocumentDragLayer';
import RoleBasedMainBox from '../../components/RoleBasedMainBox';

export default function UserFoldersPage() {
  return (
    <DndProvider backend={HTML5Backend}>
      <RoleBasedMainBox gap="0" overflow="unset">
        <Grid
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            margin: '0 auto',
          }}
      >
        <FolderAndDocumentDragLayer />
        <Outlet />
      </Grid>
    </RoleBasedMainBox>
    </DndProvider>
  );
}
