/* eslint-disable no-nested-ternary */
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import DragFileAnimation from './DragFileAnimation';

const useStyles = makeStyles((theme: Theme) => ({
  outerGrid: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
    margin: '0 auto',
    zIndex: '100',
    backgroundColor: 'none',
  },

  text: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '1rem',
    alignSelf: 'center',
  },
}));

export default function DropzoneIndicator() {
  const classes = useStyles();

  return (
    <Grid className={classes.outerGrid} sx={{ flexDirection: 'column' }}>
      <DragFileAnimation />

      <Typography className={classes.text} sx={{ fontWeight: 'bold' }}>
        Solte o documento nesta área
      </Typography>
    </Grid>
  );
}
