/* eslint-disable no-nested-ternary */
import { Link } from 'react-router-dom';
import { SxProps, Theme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import ContentSubTitle from '../components/ContentSubTitle';
import NotFoundImage from '../components/Images/NotFoundImage';

import useAuthContext from '../hooks/useAuthContext';

import COLORS from '../utils/colors';

const styles = {
  container: {
    width: '100%',
    height: '100vh',
    backgroundColor: COLORS.white,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8rem',
  } as SxProps<Theme>,

  content: {
    color: COLORS.mediumGray5,
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2rem',
  } as SxProps<Theme>,

  imgNotFound: {
    width: '20rem',
    height: '18rem',
  },

  textNotFound: {
    color: COLORS.mediumGray5,
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },

  link: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 4,
    marginTop: '3rem',
    textDecoration: 'none',
  },

  iconBack: {
    fontSize: '20px',
    color: 'primary.main',
  },

  descriptionLink: {
    mt: -0.25,
    color: 'primary.main',

    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: 'primary.main',
    },
  },
};

export default function NotFound() {
  const { isLogged } = useAuthContext();

  return (
    <Grid sx={styles.container}>
      <Box sx={styles.content}>
        <NotFoundImage style={styles.imgNotFound} />

        <ContentSubTitle
          text="Página não encontrada"
          fontWeight="bold"
          fontSize="1.2rem"
          margin="1rem auto .3rem auto"
          alignSelf="center"
        />
      </Box>

      <Link to={isLogged ? '/inicio' : '/'} style={styles.link}>
        <ArrowBackIcon sx={styles.iconBack} />

        <Typography sx={styles.descriptionLink}>
          Voltar à página principal
        </Typography>
      </Link>
    </Grid>
  );
}
