/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useState, useEffect } from 'react';

import { OriginToken } from '../types/OriginToken';

const { hostname } = document.location;

interface ContextProps {
  whiteLabel: OriginToken;
  setWhiteLabel: React.Dispatch<React.SetStateAction<OriginToken>>;
}

type Props = {
  children: React.ReactNode;
};

const WhiteLabelContext = createContext<ContextProps>({} as ContextProps);

function WhiteLabelProvider({ children }: Props) {
  const [whiteLabel, setWhiteLabel] = useState<OriginToken>(
    localStorage.getItem('@dattasign-whiteLabel') as OriginToken,
  );

  useEffect(() => {
    if (hostname === process.env.REACT_APP_CONLICITACAO_HOSTNAME) {
      setWhiteLabel('conlicitacao');
      localStorage.setItem('@dattasign-whiteLabel', 'conlicitacao');
    }

    if (hostname === process.env.REACT_APP_PREV_LAW_HOSTNAME) {
      setWhiteLabel('prevLaw');
      localStorage.setItem('@dattasign-whiteLabel', 'prevLaw');
    }

    if (hostname === process.env.REACT_APP_CODATA_HOSTNAME) {
      setWhiteLabel('codata');
      localStorage.setItem('@dattasign-whiteLabel', 'codata');
    }

    if (hostname === process.env.REACT_APP_CRM_PB_HOSTNAME) {
      setWhiteLabel('crmpb');
      localStorage.setItem('@dattasign-whiteLabel', 'crmpb');
    }

    return () => {
      localStorage.removeItem('@dattasign-whiteLabel');
      setWhiteLabel(null);
    };
  }, [hostname]);

  useEffect(() => {
    if (whiteLabel === 'codata') {
      document.title = 'CodataSign';
    }
  }, [whiteLabel]);

  return (
    <WhiteLabelContext.Provider
      value={{
        whiteLabel,
        setWhiteLabel,
      }}
    >
      {children}
    </WhiteLabelContext.Provider>
  );
}

export { WhiteLabelContext, WhiteLabelProvider };
