/* eslint-disable no-nested-ternary */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import CircularProgress from '@mui/material/CircularProgress';
import EmailIcon from '@mui/icons-material/Email';
import SendIcon from '@mui/icons-material/Send';

import ActionButton from '../../../components/ActionButton';
import ContentTitle from '../../../components/ContentTitle';
import ContentSubTitle from '../../../components/ContentSubTitle';

import useAuthContext from '../../../hooks/useAuthContext';
import useDocumentContext from '../../../hooks/useDocumentContext';

import { Signee as SigneeType } from '../../../types/Signee';
import { FileSettings } from '../../../types/FileSettings';

import COLORS from '../../../utils/colors';

interface FormValues {
  userMessage: string;
}

interface SortSettings {
  isSortingSignees: boolean;
  maxNumberToChooseOrder: number;
}

export default function UserDocumentsMessage() {
  const navigate = useNavigate();

  const signees = JSON.parse(
    localStorage.getItem('user-file-signees') || '[]',
  ) as SigneeType[];

  const settings = JSON.parse(
    localStorage.getItem('user-file-settings') || '{}',
  ) as FileSettings;

  const sortSettings = JSON.parse(
    localStorage.getItem('sort-settings') || '{}',
  ) as SortSettings;

  const { userInfo } = useAuthContext();
  const name = userInfo ? userInfo.user.name : '';

  const { currentFile, sendFilePayload, loadingDocument } =
    useDocumentContext();

  const { handleSubmit } = useForm<FormValues>();

  const [userMessage, setUserMessage] = useState(
    `Olá! Preciso da sua assinatura neste documento. Atenciosamente, ${name}`,
  );

  const handleUserMessageChanges = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setUserMessage(e.target.value);
  };

  const saveLocalMessage = () => {
    localStorage.setItem('user-file-message', JSON.stringify(userMessage));
  };

  const getLocalMessage = () => {
    if (localStorage.getItem('user-file-message') === null) {
      localStorage.setItem(
        'user-file-message',
        JSON.stringify(
          `Olá! Preciso da sua assinatura neste documento. Atenciosamente, ${name}`,
        ),
      );

      return;
    }

    const userMessageLocal = JSON.parse(
      localStorage.getItem('user-file-message') || '',
    );
    setUserMessage(userMessageLocal);
  };

  function getFocus(id: string) {
    const input = document.getElementById(id) || null;
    if (input) {
      input.focus();
    }
  }
  useEffect(() => {
    getLocalMessage();
    getFocus('textoaqui');
  }, []);

  useEffect(() => {
    saveLocalMessage();
  }, [userMessage]);

  const onSubmit: SubmitHandler<FormValues> = () => {
    saveLocalMessage();

    const signeesFormatted = sortSettings.isSortingSignees
      ? signees.sort((a, b) => a.order - b.order)
      : signees;

    const payload = {
      signatarios: signeesFormatted.map(signee => {
        const { autenticacao, email, nome, numero, order, type } = signee;

        return {
          autenticacao,
          email,
          nome,
          type,
          ...(autenticacao === 'sms' || autenticacao === 'whatsapp'
            ? { numero }
            : {}),
          ...(sortSettings.isSortingSignees ? { ordem: order } : {}),
        };
      }) as SigneeType[],
      configuracoes: {
        ...settings,
        ordenado: sortSettings.isSortingSignees,
      },
      mensagem: userMessage,
      documento: {
        nome: localStorage.getItem('file-name'),
        arquivo: currentFile,
      },
    };

    sendFilePayload(payload);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          marginBottom: '1rem',
        }}
      >
        <ContentTitle
          text="Mensagem"
          justify="flex-start"
          margin="0"
          align="start"
        />
        <EmailIcon sx={{ color: COLORS.mediumGray5 }} />
      </Box>

      <form
        noValidate
        id="formulario"
        method="post"
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: '100%', marginBottom: '1.5rem' }}
      >
        <Box
          sx={{
            width: '100%',
            height: '62.5vh',
            margin: '0 auto',
            gap: '5px',
            backgroundColor: COLORS.background,
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <ContentSubTitle
            justify="flex-start"
            text="Digite a mensagem que deseja enviar para os signatários"
            fontWeight="bold"
            alignSelf="flex-start"
            margin="0"
            padding="2rem 0 0 1.7rem"
          />

          <TextareaAutosize
            id="textoaqui"
            aria-label="Digite aqui a mensagem"
            minRows={5}
            maxRows={22}
            value={userMessage}
            onChange={handleUserMessageChanges}
            placeholder={`Olá! Preciso da sua assinatura neste documento. Atenciosamente, ${name}`}
            style={{
              width: '94%',
              padding: '1rem',
              margin: '1.5rem 0 0 0',
              borderRadius: '10px',
            }}
          />
        </Box>

        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            gap: '8px',
            width: '95%',
            height: '8vh',
            margin: '0',
          }}
        >
          <Box
            sx={{
              width: '25rem !important',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginTop: '3rem',
            }}
          >
            <ActionButton
              color={COLORS.mediumGray2}
              hoverColor={COLORS.mediumGray4}
              type="button"
              text="Voltar"
              action={() => navigate(-1)}
            />
          </Box>

          <Box
            sx={{
              width: '25rem !important',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '3rem',
            }}
          >
            <ActionButton
              type="submit"
              text={loadingDocument ? '' : 'Enviar'}
              icon={
                loadingDocument ? (
                  <CircularProgress
                    style={{ color: COLORS.white, margin: '.1rem 0' }}
                    size={20}
                  />
                ) : (
                  <SendIcon
                    sx={{
                      color: COLORS.white,
                      marginLeft: '.5rem',
                      fontSize: '1rem',
                    }}
                  />
                )
              }
              disabled={loadingDocument}
            />
          </Box>
        </Grid>
      </form>
    </>
  );
}
