/* eslint-disable react/jsx-props-no-spreading */
import { useTheme } from '@mui/material';

export default function ConfirmYourDataImage(
  props: React.SVGAttributes<SVGSVGElement>,
) {
  const theme = useTheme();

  return (
    <svg
      width="320"
      height="230"
      viewBox="0 0 320 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M310.295 67.1464H135.324C132.861 67.1486 130.5 68.1289 128.758 69.872C127.017 71.6152 126.037 73.9787 126.035 76.4439V104.826C126.037 107.291 127.017 109.654 128.758 111.398C130.5 113.141 132.861 114.121 135.324 114.123H310.295C312.758 114.121 315.12 113.141 316.861 111.398C318.603 109.654 319.582 107.291 319.584 104.826V76.4439C319.582 73.9787 318.603 71.6152 316.861 69.872C315.12 68.1289 312.758 67.1486 310.295 67.1464Z"
        fill="#F2F2F2"
      />
      <path
        d="M70.168 229.136H0.556889C0.409193 229.136 0.267537 229.078 0.1631 228.973C0.0586632 228.869 0 228.727 0 228.579C0 228.431 0.0586632 228.289 0.1631 228.185C0.267537 228.08 0.409193 228.022 0.556889 228.022H70.168C70.3157 228.022 70.4574 228.08 70.5618 228.185C70.6662 228.289 70.7249 228.431 70.7249 228.579C70.7249 228.727 70.6662 228.869 70.5618 228.973C70.4574 229.078 70.3157 229.136 70.168 229.136Z"
        fill="#3F3D56"
      />
      <path
        d="M59.947 54.6127C59.3015 55.0206 58.7531 55.5651 58.3405 56.2081C57.928 56.851 57.6613 57.5766 57.5592 58.3339C57.4572 59.0911 57.5223 59.8615 57.7499 60.5909C57.9775 61.3202 58.3621 61.9908 58.8766 62.5553L53.8159 73.3718L59.6331 78.0532L66.5654 62.6776C67.4556 61.7496 67.9747 60.527 68.0244 59.2415C68.0742 57.956 67.651 56.6969 66.8352 55.7028C66.0194 54.7087 64.8676 54.0486 63.5981 53.8477C62.3285 53.6468 61.0294 53.919 59.947 54.6127V54.6127Z"
        fill="#FFB8B8"
      />
      <path
        d="M30.8658 59.0021C32.4674 58.0392 34.3779 57.7302 36.201 58.1393C38.0241 58.5484 39.6199 59.6441 40.6576 61.1992L51.0128 76.7183L54.6078 68.7858C54.8323 68.2905 55.2299 67.8943 55.7259 67.672C56.2218 67.4497 56.7819 67.4165 57.3006 67.5787L62.7265 69.2751C63.01 69.3638 63.2729 69.5082 63.4998 69.7C63.7267 69.8919 63.9129 70.1271 64.0476 70.3921C64.1823 70.657 64.2627 70.9462 64.2841 71.2427C64.3055 71.5391 64.2675 71.8369 64.1722 72.1184L60.458 83.096C59.9881 84.4846 59.2031 85.7453 58.1646 86.7792C57.126 87.813 55.8622 88.5919 54.4723 89.0546C53.0824 89.5173 51.6043 89.6513 50.154 89.446C48.7036 89.2407 47.3206 88.7018 46.1134 87.8715C45.5492 87.4835 45.0288 87.0355 44.5611 86.5353L29.3009 70.2151C28.5599 69.4226 28.0053 68.4742 27.6778 67.4394C27.3504 66.4046 27.2583 65.3096 27.4083 64.2346C27.5583 63.1596 27.9467 62.1318 28.5449 61.2264C29.1432 60.321 29.9362 59.5611 30.8658 59.0021Z"
        fill="#CCCCCC"
      />
      <path
        d="M64.3638 42.9669C64.6417 42.9738 64.9055 43.0909 65.0972 43.2925C65.2889 43.4941 65.3927 43.7637 65.3858 44.0419L65.2964 47.6441L65.4768 47.6606L65.4121 50.1072L65.2358 50.0852L64.9155 62.9902C64.9046 63.4319 64.7334 63.8545 64.434 64.1792C64.1346 64.5039 63.7275 64.7084 63.2885 64.7547L55.09 65.6186C54.8904 65.6396 54.6886 65.6167 54.4988 65.5514C54.309 65.4861 54.1357 65.3801 53.9912 65.2407C53.8466 65.1013 53.7343 64.932 53.6621 64.7446C53.5898 64.5571 53.5594 64.3561 53.573 64.1557L54.8164 45.7798C54.8425 45.3939 54.999 45.0283 55.2603 44.7432C55.5215 44.4581 55.8718 44.2704 56.2538 44.211L64.1682 42.9804C64.2329 42.9697 64.2983 42.9652 64.3638 42.9669Z"
        fill="#3F3D56"
      />
      <path
        d="M58.0193 44.8793L62.18 44.2324C62.2549 44.2207 62.3239 44.1847 62.3762 44.1299C62.4286 44.075 62.4615 44.0044 62.4697 43.929C62.479 43.8436 62.5162 43.7637 62.5755 43.7015C62.6348 43.6394 62.7129 43.5986 62.7978 43.5854L63.6742 43.4491C63.7938 43.4305 63.916 43.4385 64.0322 43.4723C64.1484 43.5061 64.2558 43.5651 64.3467 43.645C64.4377 43.7249 64.51 43.8238 64.5586 43.9348C64.6072 44.0457 64.6309 44.166 64.628 44.2871L64.1775 62.9972C64.1719 63.2294 64.0811 63.4514 63.9224 63.6208C63.7637 63.7902 63.5483 63.8953 63.3171 63.9158L55.1247 64.6449C55.0202 64.6542 54.9149 64.6408 54.8161 64.6056C54.7172 64.5705 54.6271 64.5144 54.5519 64.4411C54.4766 64.3679 54.4181 64.2793 54.3802 64.1814C54.3423 64.0835 54.326 63.9785 54.3324 63.8737L55.4378 45.6676C55.4541 45.3994 55.5615 45.1448 55.7423 44.9461C55.9231 44.7474 56.1663 44.6165 56.4317 44.5753L57.3555 44.4316C57.3895 44.5762 57.4767 44.7027 57.5998 44.7857C57.7228 44.8687 57.8727 44.9021 58.0193 44.8793Z"
        fill="white"
      />
      <path
        d="M38.7161 222.37L45.0824 222.37L48.1103 197.791L38.7139 197.792L38.7161 222.37Z"
        fill="#FFB8B8"
      />
      <path
        d="M57.3608 228.286L37.352 228.288L37.3513 220.55L49.6294 220.549C50.6447 220.549 51.6499 220.749 52.5879 221.138C53.5259 221.527 54.3781 222.096 55.0961 222.815C55.814 223.533 56.3835 224.386 56.7721 225.325C57.1607 226.264 57.3607 227.27 57.3608 228.286L57.3608 228.286Z"
        fill="#2F2E41"
      />
      <path
        d="M19.1487 222.37L25.5151 222.37L28.5429 197.791L19.1465 197.792L19.1487 222.37Z"
        fill="#FFB8B8"
      />
      <path
        d="M37.7944 228.286L17.7855 228.288L17.7848 220.55L30.063 220.549C32.1133 220.549 34.0798 221.364 35.5297 222.815C36.9796 224.266 37.7942 226.234 37.7944 228.286L37.7944 228.286Z"
        fill="#2F2E41"
      />
      <path
        d="M37.7945 50.6486C45.3485 50.6486 51.4722 44.5193 51.4722 36.9584C51.4722 29.3975 45.3485 23.2682 37.7945 23.2682C30.2404 23.2682 24.1167 29.3975 24.1167 36.9584C24.1167 44.5193 30.2404 50.6486 37.7945 50.6486Z"
        fill="#FFB8B8"
      />
      <path
        d="M44.8338 73.5862C44.7221 70.0665 43.7321 66.6307 41.9541 63.5919C40.176 60.5532 37.6665 58.0081 34.6541 56.1887C31.1205 54.1046 27.2526 53.2918 24.1754 56.3717C22.3277 58.2675 20.848 60.4905 19.8117 62.9273C16.9652 69.568 16.7789 77.05 19.2915 83.8243L26.6155 104.077L43.5667 105.915C43.8851 105.949 44.2072 105.915 44.5111 105.813C44.8149 105.712 45.0933 105.546 45.3273 105.327C45.5613 105.108 45.7455 104.842 45.8672 104.545C45.9888 104.249 46.0452 103.929 46.0324 103.609L44.8338 73.5862Z"
        fill="#2F2E41"
      />
      <path
        d="M27.6901 101.583C27.6901 101.583 15.0257 106.315 18.3367 126.353C21.3079 144.333 18.9468 204.014 18.4307 216.078C18.4061 216.653 18.6049 217.215 18.9853 217.647C19.3657 218.079 19.8982 218.346 20.4713 218.393L27.864 219.01C28.443 219.058 29.018 218.878 29.4662 218.508C29.9145 218.138 30.2006 217.608 30.2634 217.029L34.0814 181.928C34.0964 181.791 34.1621 181.663 34.2657 181.571C34.3693 181.48 34.5034 181.43 34.6418 181.431C34.7803 181.433 34.9131 181.486 35.0144 181.581C35.1158 181.675 35.1784 181.804 35.19 181.942L38.035 215.717C38.0829 216.286 38.3472 216.815 38.7733 217.195C39.1994 217.575 39.7548 217.776 40.3251 217.758L46.5345 217.562C46.8271 217.553 47.115 217.486 47.3818 217.365C47.6486 217.244 47.8889 217.072 48.0892 216.858C48.2894 216.644 48.4455 216.393 48.5487 216.119C48.6519 215.845 48.7 215.553 48.6904 215.26L45.0327 103.923L27.6901 101.583Z"
        fill="#2F2E41"
      />
      <path
        d="M40.5394 36.0565C45.1139 37.9343 50.9557 35.7407 53.1673 31.3147C55.379 26.8887 53.6287 20.8944 49.3837 18.3572C45.1388 15.8199 39.0362 17.1205 36.1921 21.1685C33.8785 16.9966 27.8176 15.933 23.6563 18.2608C19.495 20.5887 17.2284 25.4646 16.9595 30.2284C16.6907 34.9923 18.1377 39.6854 19.9029 44.1177C22.7581 51.2872 31.9501 55.1476 39.0617 52.1639C35.806 47.3105 36.4487 40.208 40.5394 36.0565Z"
        fill="#2F2E41"
      />
      <path
        d="M58.9734 56.2725C58.8517 56.2727 58.7332 56.2333 58.6358 56.1602L58.6297 56.1556L57.358 55.1819C57.2991 55.1367 57.2497 55.0804 57.2126 55.016C57.1754 54.9517 57.1513 54.8807 57.1416 54.8071C57.1319 54.7334 57.1367 54.6586 57.1559 54.5868C57.1751 54.5151 57.2082 54.4478 57.2534 54.3889C57.2985 54.3299 57.3548 54.2804 57.4191 54.2433C57.4833 54.2061 57.5543 54.182 57.6279 54.1722C57.7014 54.1625 57.7762 54.1674 57.8479 54.1866C57.9196 54.2058 57.9868 54.2389 58.0457 54.2841L58.8694 54.9163L60.8159 52.3746C60.861 52.3157 60.9173 52.2662 60.9815 52.2291C61.0458 52.1919 61.1167 52.1678 61.1902 52.1581C61.2638 52.1483 61.3385 52.1532 61.4102 52.1724C61.4818 52.1916 61.549 52.2247 61.6079 52.2699L61.6081 52.27L61.596 52.2868L61.6084 52.27C61.7272 52.3614 61.8049 52.4962 61.8245 52.6448C61.8441 52.7935 61.8039 52.9438 61.7129 53.0629L59.4234 56.0512C59.3704 56.12 59.3023 56.1757 59.2244 56.214C59.1464 56.2523 59.0607 56.272 58.9739 56.2718L58.9734 56.2725Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M310.46 0H135.489C133.026 0.00223301 130.665 0.982514 128.923 2.72565C127.182 4.46879 126.202 6.83234 126.2 9.29751V37.6794C126.202 40.1446 127.182 42.5081 128.923 44.2513C130.665 45.9944 133.026 46.9747 135.489 46.9769H310.46C312.923 46.9747 315.285 45.9944 317.026 44.2513C318.768 42.5081 319.747 40.1446 319.749 37.6794V9.29751C319.747 6.83234 318.768 4.46878 317.026 2.72564C315.285 0.982506 312.923 0.00223301 310.46 0ZM318.772 37.6794C318.768 39.8847 317.892 41.9988 316.334 43.5582C314.776 45.1176 312.664 45.9951 310.46 45.9982H135.489C133.286 45.9951 131.174 45.1176 129.616 43.5582C128.058 41.9988 127.181 39.8847 127.178 37.6794V9.29751C127.181 7.09218 128.058 4.97807 129.616 3.41866C131.174 1.85925 133.286 0.981803 135.489 0.978681H310.46C312.664 0.981803 314.776 1.85925 316.334 3.41866C317.892 4.97807 318.768 7.09218 318.772 9.29751V37.6794Z"
        fill="#3F3D56"
      />
      <path
        d="M171.096 39.6709C167.898 39.6709 164.773 38.7218 162.114 36.9437C159.455 35.1656 157.383 32.6382 156.159 29.6813C154.935 26.7243 154.615 23.4706 155.239 20.3315C155.863 17.1924 157.403 14.309 159.664 12.0458C161.925 9.78263 164.806 8.2414 167.942 7.61699C171.078 6.99257 174.329 7.31304 177.283 8.53784C180.237 9.76265 182.762 11.8368 184.539 14.498C186.315 17.1592 187.264 20.2879 187.264 23.4884C187.259 27.7788 185.554 31.892 182.523 34.9257C179.492 37.9595 175.383 39.666 171.096 39.6709Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M286.588 36.7007H202.552C201.514 36.7007 200.52 36.2883 199.786 35.5541C199.053 34.8199 198.641 33.8242 198.641 32.786C198.641 31.7477 199.053 30.752 199.786 30.0178C200.52 29.2837 201.514 28.8712 202.552 28.8712H286.588C287.625 28.8712 288.62 29.2837 289.353 30.0178C290.087 30.752 290.499 31.7477 290.499 32.786C290.499 33.8242 290.087 34.8199 289.353 35.5541C288.62 36.2883 287.625 36.7007 286.588 36.7007Z"
        fill="#CCCCCC"
      />
      <path
        d="M230.364 20.063H202.552C201.514 20.063 200.52 19.6506 199.786 18.9164C199.053 18.1823 198.641 17.1865 198.641 16.1483C198.641 15.11 199.053 14.1143 199.786 13.3801C200.52 12.646 201.514 12.2335 202.552 12.2335H230.364C231.402 12.2335 232.396 12.646 233.13 13.3801C233.863 14.1143 234.275 15.11 234.275 16.1483C234.275 17.1865 233.863 18.1823 233.13 18.9164C232.396 19.6506 231.402 20.063 230.364 20.063Z"
        fill="#CCCCCC"
      />
      <path
        d="M170.271 29.1805C169.87 29.1812 169.479 29.0513 169.158 28.8104L169.138 28.7955L164.948 25.587C164.753 25.4382 164.59 25.2525 164.467 25.0405C164.345 24.8286 164.265 24.5944 164.233 24.3515C164.2 24.1086 164.216 23.8618 164.279 23.625C164.343 23.3883 164.452 23.1664 164.601 22.9719C164.75 22.7775 164.935 22.6143 165.147 22.4918C165.359 22.3693 165.593 22.2898 165.836 22.2579C166.079 22.226 166.325 22.2424 166.562 22.3059C166.798 22.3695 167.02 22.4792 167.214 22.6286L169.928 24.7119L176.342 16.3366C176.491 16.1425 176.676 15.9795 176.888 15.8571C177.1 15.7347 177.333 15.6551 177.576 15.6231C177.818 15.5911 178.064 15.6071 178.3 15.6703C178.536 15.7336 178.758 15.8427 178.952 15.9916L178.952 15.992L178.913 16.0473L178.953 15.992C179.345 16.2931 179.601 16.7372 179.665 17.2271C179.73 17.7169 179.598 18.2123 179.298 18.6047L171.754 28.4514C171.579 28.6783 171.355 28.8619 171.098 28.9879C170.841 29.114 170.559 29.1791 170.272 29.1783L170.271 29.1805Z"
        fill="white"
      />
      <path
        d="M235.281 76.363H60.3096C57.8467 76.3653 55.4852 77.3456 53.7437 79.0887C52.0021 80.8318 51.0227 83.1954 51.0205 85.6605V114.042C51.0227 116.508 52.0021 118.871 53.7437 120.614C55.4852 122.357 57.8467 123.338 60.3096 123.34H235.281C237.743 123.338 240.105 122.357 241.846 120.614C243.588 118.871 244.567 116.508 244.57 114.042V85.6605C244.567 83.1954 243.588 80.8318 241.846 79.0887C240.105 77.3456 237.743 76.3653 235.281 76.363Z"
        fill="white"
      />
      <path
        d="M235.281 76.363H60.3096C57.8467 76.3653 55.4852 77.3456 53.7437 79.0887C52.0021 80.8318 51.0227 83.1954 51.0205 85.6605V114.042C51.0227 116.508 52.0021 118.871 53.7437 120.614C55.4852 122.357 57.8467 123.338 60.3096 123.34H235.281C237.743 123.338 240.105 122.357 241.846 120.614C243.588 118.871 244.567 116.508 244.57 114.042V85.6605C244.567 83.1954 243.588 80.8318 241.846 79.0887C240.105 77.3456 237.743 76.3653 235.281 76.363ZM243.592 114.042C243.589 116.248 242.712 118.362 241.154 119.921C239.596 121.481 237.484 122.358 235.281 122.361H60.3096C58.1062 122.358 55.9941 121.481 54.4361 119.921C52.8781 118.362 52.0014 116.248 51.9983 114.042V85.6605C52.0014 83.4552 52.878 81.3411 54.436 79.7817C55.994 78.2223 58.1062 77.3448 60.3096 77.3417H235.281C237.484 77.3448 239.596 78.2223 241.154 79.7817C242.712 81.3411 243.589 83.4552 243.592 85.6605V114.042Z"
        fill="#3F3D56"
      />
      <path
        d="M95.9159 116.034C92.7182 116.034 89.5924 115.085 86.9336 113.307C84.2748 111.529 82.2025 109.001 80.9788 106.044C79.7551 103.087 79.4349 99.8336 80.0587 96.6945C80.6825 93.5554 82.2223 90.672 84.4834 88.4088C86.7445 86.1457 89.6253 84.6044 92.7616 83.98C95.8978 83.3556 99.1486 83.6761 102.103 84.9009C105.057 86.1257 107.582 88.1998 109.359 90.861C111.135 93.5222 112.084 96.6509 112.084 99.8515C112.079 104.142 110.374 108.255 107.343 111.289C104.312 114.323 100.202 116.029 95.9159 116.034Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M211.407 113.064H127.371C126.334 113.064 125.339 112.651 124.606 111.917C123.872 111.183 123.46 110.187 123.46 109.149C123.46 108.111 123.872 107.115 124.606 106.381C125.339 105.647 126.334 105.234 127.371 105.234H211.407C212.444 105.234 213.439 105.647 214.173 106.381C214.906 107.115 215.318 108.111 215.318 109.149C215.318 110.187 214.906 111.183 214.173 111.917C213.439 112.651 212.444 113.064 211.407 113.064Z"
        fill="#CCCCCC"
      />
      <path
        d="M155.184 96.426H127.371C126.858 96.426 126.349 96.3248 125.874 96.1281C125.4 95.9313 124.969 95.643 124.606 95.2794C124.242 94.9159 123.954 94.4844 123.758 94.0094C123.561 93.5345 123.46 93.0254 123.46 92.5113C123.46 91.9972 123.561 91.4882 123.758 91.0132C123.954 90.5382 124.242 90.1067 124.606 89.7432C124.969 89.3797 125.4 89.0913 125.874 88.8946C126.349 88.6978 126.858 88.5966 127.371 88.5966H155.184C156.221 88.5966 157.216 89.009 157.949 89.7432C158.683 90.4773 159.095 91.473 159.095 92.5113C159.095 93.5496 158.683 94.5453 157.949 95.2794C157.216 96.0136 156.221 96.426 155.184 96.426Z"
        fill="#CCCCCC"
      />
      <path
        d="M95.0906 105.544C94.6895 105.544 94.299 105.414 93.978 105.174L93.9581 105.159L89.7677 101.95C89.5732 101.801 89.4099 101.616 89.2871 101.404C89.1644 101.192 89.0846 100.958 89.0524 100.715C89.0201 100.472 89.036 100.225 89.0992 99.9882C89.1623 99.7515 89.2715 99.5295 89.4205 99.3351C89.5694 99.1406 89.7552 98.9775 89.9672 98.855C90.1792 98.7324 90.4132 98.653 90.656 98.6211C90.8987 98.5892 91.1453 98.6055 91.3817 98.6691C91.6181 98.7327 91.8397 98.8423 92.0337 98.9917L94.748 101.075L101.162 92.6998C101.311 92.5056 101.496 92.3427 101.708 92.2202C101.919 92.0978 102.153 92.0183 102.395 91.9863C102.638 91.9542 102.884 91.9703 103.12 92.0335C103.356 92.0967 103.578 92.2059 103.772 92.3548L103.772 92.3552L103.732 92.4105L103.773 92.3552C104.165 92.6562 104.421 93.1004 104.485 93.5902C104.55 94.0801 104.418 94.5755 104.118 94.9678L96.5735 104.815C96.3989 105.041 96.1745 105.225 95.9176 105.351C95.6608 105.477 95.3784 105.542 95.0923 105.541L95.0906 105.544Z"
        fill="white"
      />
      <path
        d="M54.6356 82.7653C53.9325 82.4676 53.1727 82.3279 52.4098 82.3559C51.6469 82.384 50.8994 82.5791 50.22 82.9276C49.5406 83.276 48.9458 83.7694 48.4775 84.3728C48.0092 84.9762 47.6788 85.6751 47.5095 86.4202L35.7478 88.4369L35.229 95.8899L51.7988 92.8084C53.0682 93.01 54.3674 92.7386 55.4503 92.0455C56.5331 91.3525 57.3243 90.2859 57.674 89.048C58.0236 87.81 57.9075 86.4867 57.3475 85.3288C56.7875 84.1708 55.8226 83.2587 54.6356 82.7653L54.6356 82.7653Z"
        fill="#FFB8B8"
      />
      <path
        d="M34.4321 61.3734C36.1363 62.1398 37.4805 63.5334 38.1858 65.265C38.8911 66.9967 38.9034 68.9336 38.2202 70.6742L31.4024 88.0438L39.9616 86.4691C40.496 86.3708 41.048 86.4714 41.5135 86.752C41.979 87.0327 42.326 87.4739 42.4891 87.9928L44.1953 93.4201C44.2845 93.7036 44.3161 94.0022 44.2883 94.2981C44.2604 94.594 44.1738 94.8814 44.0334 95.1434C43.893 95.4053 43.7017 95.6365 43.4707 95.8233C43.2397 96.0102 42.9737 96.1489 42.6884 96.2314L31.5635 99.4464C30.1562 99.8531 28.6739 99.9278 27.2329 99.6646C25.792 99.4014 24.4315 98.8076 23.2585 97.9296C22.0854 97.0516 21.1316 95.9134 20.472 94.6044C19.8124 93.2954 19.4648 91.8512 19.4567 90.3851C19.4529 89.7001 19.5232 89.0166 19.6665 88.3467L24.341 66.4892C24.568 65.4277 25.0293 64.4306 25.6912 63.5707C26.353 62.7108 27.1986 62.01 28.1661 61.5195C29.1336 61.029 30.1984 60.7614 31.2825 60.7361C32.3667 60.7108 33.4428 60.9286 34.4321 61.3734Z"
        fill="#CCCCCC"
      />
    </svg>
  );
}
