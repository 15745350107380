/* eslint-disable react/require-default-props */
import { SxProps, Theme } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import COLORS from '../utils/colors';

type Props = {
  text: string;
  icon?: React.ReactNode;
  type: 'button' | 'submit' | 'reset';
  color?: string;
  hoverColor?: string;
  tabletwidth?: string;
  mobilewidth?: string;
  laptopwidth?: string;
  desktopwidth?: string;
  largedesktopwidth?: string;
  margin?: string;
  align?: string;
  self?: string;
  disabled?: boolean;
  action?: () => void;
};

function ActionButton({
  text,
  icon,
  type,
  color,
  hoverColor,
  mobilewidth,
  tabletwidth,
  laptopwidth,
  desktopwidth,
  largedesktopwidth,
  margin,
  align,
  self,
  disabled,
  action,
}: Props) {
  const styles = {
    textTransform: 'unset',
    color: COLORS.white,
    fontWeight: 'bold',
    margin,
    display: 'flex',
    justifyContent: 'center',
    alignItems: align,
    alignSelf: self,
    flexDirection: 'row-reverse',
    gap: '5px',
    fontSize: {
      mobile: '.8rem',
      tablet: '.9rem',
    },
    width: {
      mobile: mobilewidth,
      tablet: tabletwidth,
      laptop: laptopwidth,
      desktop: desktopwidth,
      largedesktop: largedesktopwidth,
    },
    backgroundColor: color || 'primary.main',
    '&:hover': {
      backgroundColor: hoverColor || 'primary.light',
    },
    '&:disabled': {
      backgroundColor: hoverColor || 'primary.light',
    },
  } as SxProps<Theme>;

  return (
    <Button
      disabled={disabled}
      id="actionButton"
      type={type}
      onClick={action}
      sx={styles}
    >
      {icon}
      <Typography>{text}</Typography>
    </Button>
  );
}

ActionButton.defaultProps = {
  mobilewidth: '40%',
  tabletwidth: '40%',
  laptopwidth: '50%',
  desktopwidth: '50%',
  largedesktopwidth: '50%',
  margin: '1rem auto',
  align: 'center',
  disabled: false,
  icon: null,
};

export default ActionButton;
