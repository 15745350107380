/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Theme } from '@mui/material';

import DeleteDocumentImage from '../../components/Images/DeleteDocumentImage';

import useAuthContext from '../../hooks/useAuthContext';
import useDialogContext from '../../hooks/useDialogContext';
import useCostCenterContext from '../../hooks/useCostCenterContext';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const useStyles = makeStyles((theme: Theme) => ({
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    gap: '.5rem',
    transition: 'all .3s ease-out',
    '&.MuiMenuItem-root': {
      '&:selected': {
        backgroundColor: `${theme.palette.secondary.main} !important`,
        '& .MuiSvgIcon-root': {
          color: COLORS.white,
        },
      },
      '&:active': {
        backgroundColor: theme.palette.secondary.main,
        color: COLORS.white,
        '& .MuiSvgIcon-root': {
          color: COLORS.white,
        },
      },
    },
  },

  dialog: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
  },

  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& svg': {
      width: '12rem',
      height: '12rem',
      margin: '0 auto',
    },

    '& .MuiTypography-root': {
      margin: '1.5rem 0',
      textAlign: 'center',
      color: COLORS.black,
      fontSize: '0.925rem',
      fontWeight: 'bold',
    },
  },
}));

const ModalDeleteFolderOrDocument = () => {
  const { costCenter } = useAuthContext();
  const { showDialog } = useDialogContext();

  const { selectedFoldersIds, selectedDocuments } = useCostCenterContext();

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setIsDeleting(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleDeleteFile = async () => {
    setIsDeleting(true);
    const data = {
      folders_id: selectedFoldersIds,
      documents_id: selectedDocuments,
    };
    try {
      await api.delete(`/documents_folder/${costCenter?.id}`, { data });

      setIsDeleting(false);
      handleClose();
      showDialog(
        'success',
        selectedFoldersIds.length === 1 && selectedDocuments.length === 0
          ? 'A pasta foi excluída com sucesso!'
          : selectedFoldersIds.length > 1 && selectedDocuments.length === 0
          ? 'As pastas foram excluídas com sucesso!'
          : selectedDocuments.length === 1 && selectedFoldersIds.length === 0
          ? 'O documento foi excluído com sucesso!'
          : selectedDocuments.length > 1 && selectedFoldersIds.length === 0
          ? 'Os documentos foram excluídos com sucesso!'
          : 'Os arquivos foram excluídos com sucesso!',
      );
    } catch (error) {
      console.log(error);

      setIsDeleting(false);
      handleClose();
      showDialog(
        'default',
        `Não foi possível excluir ${
          selectedFoldersIds.length === 1 && selectedDocuments.length === 0
            ? 'a pasta'
            : selectedFoldersIds.length > 1 && selectedDocuments.length === 0
            ? ' as pastas'
            : selectedDocuments.length === 1 && selectedFoldersIds.length === 0
            ? ' o documento'
            : selectedDocuments.length > 1 && selectedFoldersIds.length === 0
            ? ' os documentos'
            : ' os arquivos'
        }.`,
      );
    }
  };

  return (
    <>
      <MenuItem onClick={handleOpen} className={classes.menuItem}>
        <DeleteIcon sx={{ color: COLORS.mediumGray5 }} />
        Excluir{' '}
        {selectedFoldersIds.length === 1 && selectedDocuments.length === 0
          ? 'a pasta'
          : selectedFoldersIds.length > 1 && selectedDocuments.length === 0
          ? ' as pastas'
          : selectedDocuments.length === 1 && selectedFoldersIds.length === 0
          ? ' o documento'
          : selectedDocuments.length > 1 && selectedFoldersIds.length === 0
          ? ' os documentos'
          : ' os arquivos'}
      </MenuItem>

      <Dialog
        open={open}
        onClose={!isDeleting ? handleClose : undefined}
        className={classes.dialog}
        sx={{ width: '32rem' }}
      >
        <DialogTitle>Atenção!</DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <DeleteDocumentImage />
          <Typography variant="subtitle2">
            Tem certeza de que deseja excluir{' '}
            {selectedFoldersIds.length === 1 && selectedDocuments.length === 0
              ? 'a pasta'
              : selectedFoldersIds.length > 1 && selectedDocuments.length === 0
              ? ' as pastas'
              : selectedDocuments.length === 1 &&
                selectedFoldersIds.length === 0
              ? ' o documento'
              : selectedDocuments.length > 1 && selectedFoldersIds.length === 0
              ? ' os documentos'
              : ' os arquivos'}
            ? Esta ação será irreversível!
          </Typography>
        </DialogContent>

        <DialogActions sx={{ padding: '0 2.3rem 2rem 2rem' }}>
          <Button
            onClick={handleClose}
            sx={{
              width: '7rem',
              color: COLORS.mediumGray3,
              '&:hover': {
                backgroundColor: COLORS.lightGray2,
              },
            }}
          >
            Cancelar
          </Button>

          {isDeleting ? (
            <LoadingButton
              loading
              loadingPosition="center"
              sx={{
                width: '7rem',
                height: '2.25rem',
                color: COLORS.white,
                backgroundColor: COLORS.redWarning,
                '&:hover': {
                  backgroundColor: COLORS.lightRed,
                },
              }}
            />
          ) : (
            <Button
              endIcon={<DeleteIcon fontSize="large" />}
              onClick={handleDeleteFile}
              sx={{
                width: '7rem',
                color: COLORS.white,
                backgroundColor: COLORS.redWarning,
                '&:hover': {
                  backgroundColor: COLORS.lightRed,
                },
              }}
            >
              Excluir
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalDeleteFolderOrDocument;
