/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';

import useAuthContext from '../hooks/useAuthContext';
import useDialogContext from '../hooks/useDialogContext';
import useWhiteLabel from '../hooks/useWhiteLabel';

import api from '../services/api';

import COLORS from '../utils/colors';
import { SignDataLoggedOff } from '../pages/Sign/Sign';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSignData?: React.Dispatch<React.SetStateAction<SignDataLoggedOff | null>>;
}

type AxiosParams = [url: string, data?: unknown];

const TermsOfConditionsToSign: React.FC<Props> = ({
  open,
  setOpen,
  setSignData,
}) => {
  const navigate = useNavigate();
  const { userInfo } = useAuthContext();
  const { showDialog } = useDialogContext();
  const { id, token } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const { whiteLabel } = useWhiteLabel();

  const isLogged = id && userInfo;

  const handleNavigate = () => {
    if (isLogged) {
      navigate('/documentos');
      return;
    }

    if (whiteLabel === 'conlicitacao') {
      window.location.href =
        'https://consulteonline.conlicitacao.com.br/digital_signature_documents';
      return;
    }

    navigate('/');
  };

  const handleSubmit = async (accept: boolean) => {
    if (!accept) {
      handleNavigate();
      return;
    }

    setIsLoading(true);

    try {
      const url = `/confirm_term${id ? '' : '_off'}/${id || token}`;
      const args = (
        isLogged ? [url, { email: userInfo.user.email }] : [url]
      ) as AxiosParams;

      await api.post.apply(this, args);

      setOpen(false);

      if (setSignData) {
        setSignData(signData => {
          return {
            ...signData,
            accept: true,
          } as SignDataLoggedOff;
        });
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error);

      if (error.response && typeof error.response.data.message === 'string') {
        showDialog('default', error.response.data.message);
        return;
      }

      showDialog(
        'default',
        'Erro desconhecido! Por favor, tente novamente mais tarde!',
      );
    }

    setIsLoading(false);
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Termo de condições para assinar</DialogTitle>

      <DialogContent>
        Concorda em usar assinaturas e registros eletrônicos?
      </DialogContent>

      <DialogActions sx={{ padding: '1rem' }}>
        <Button
          onClick={() => handleSubmit(false)}
          disabled={isLoading}
          sx={{
            width: '7rem',
            color: COLORS.mediumGray5,
            '&:hover': {
              backgroundColor: COLORS.mediumGray4,
            },
          }}
        >
          Discordo
        </Button>

        {isLoading ? (
          <LoadingButton
            loading
            loadingPosition="center"
            sx={{
              width: '7rem',
              height: '2.25rem',
              backgroundColor: 'primary.light',
            }}
          />
        ) : (
          <Button
            onClick={() => handleSubmit(true)}
            disabled={isLoading}
            sx={{
              width: '7rem',
              color: 'primary.main',
              '&:hover': {
                backgroundColor: 'primary.light',
              },
            }}
          >
            Concordo
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default TermsOfConditionsToSign;
