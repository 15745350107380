/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';

import DeleteDocumentImage from '../../components/Images/DeleteDocumentImage';

import useAuthContext from '../../hooks/useAuthContext';
import useDialogContext from '../../hooks/useDialogContext';
import useSubscriptionContext from '../../hooks/useSubscriptionContext';

import { Document } from '../../types/Document';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const useStyles = makeStyles({
  dialog: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
  },

  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  text: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '0.925rem',
    marginTop: '.5rem',
  },

  buttonDelete: {
    '&.MuiButtonBase-root': {
      backgroundColor: COLORS.red,
      color: COLORS.white,
      borderRadius: '.625rem',
      height: '2rem',
      width: '2.25rem',

      '& svg': {
        fontSize: '1.25rem',
      },

      '&:hover': {
        backgroundColor: COLORS.lightRed,
        transition: '0.3s',
      },
    },
  },
});

interface Props {
  documents: Document[];
  document: Document;
  updateDocuments: () => void;
}

const ConfirmDeleteDocumentModal: React.FC<Props> = ({
  documents,
  document,
  updateDocuments,
}) => {
  const classes = useStyles();
  const { costCenter } = useAuthContext();

  const [open, setOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { showDialog } = useDialogContext();
  const { getSubscription } = useSubscriptionContext();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteDocument = async () => {
    if (!documents || !costCenter) return;

    setIsDeleting(true);

    try {
      await api.delete(`documento/excluir/${costCenter.id}/${document.id}`);
      updateDocuments();

      handleClose();
      showDialog('success', 'Documento deletado!');
      getSubscription();
    } catch (error) {
      console.log(error);
      handleClose();
      showDialog('default', 'Não foi possível deletar o documento!');
    }

    setIsDeleting(false);
  };

  return (
    <>
      <Tooltip title="Excluir documento">
        <IconButton className={classes.buttonDelete} onClick={handleOpen}>
          <DeleteOutlinedIcon />
        </IconButton>
      </Tooltip>

      <Dialog
        open={open}
        onClose={handleClose}
        className={classes.dialog}
        sx={{ width: '30rem' }}
      >
        <DialogTitle>Atenção!</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DeleteDocumentImage
            style={{
              width: '12rem',
              height: '12rem',
              margin: '0 auto',
            }}
          />
          <div
            style={{
              width: '24rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '4rem',
            }}
          >
            <Typography
              className={classes.text}
              sx={{
                color: COLORS.black,
              }}
              variant="subtitle2"
            >
              Tem certeza de que deseja deletar este documento? Esta ação será
              irreversível!
            </Typography>
          </div>
        </DialogContent>
        <DialogActions sx={{ padding: '0 1.5rem 2rem' }}>
          <Button
            onClick={handleClose}
            sx={{
              width: '7rem',
              color: COLORS.mediumGray3,
              '&:hover': {
                backgroundColor: COLORS.lightGray2,
              },
            }}
          >
            Cancelar
          </Button>

          {isDeleting ? (
            <LoadingButton
              loading
              loadingPosition="center"
              sx={{
                width: '7rem',
                height: '2.25rem',
                color: COLORS.white,
                backgroundColor: COLORS.redWarning,
                '&:hover': {
                  backgroundColor: COLORS.lightRed,
                },
              }}
            />
          ) : (
            <Button
              sx={{
                width: '7rem',
                color: COLORS.white,
                backgroundColor: COLORS.redWarning,
                '&:hover': {
                  backgroundColor: COLORS.lightRed,
                },
              }}
              endIcon={<DeleteIcon fontSize="large" />}
              onClick={handleDeleteDocument}
            >
              Excluir
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmDeleteDocumentModal;
