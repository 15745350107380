import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles({
  documentContainer: {
    width: (props: { width: string; height: string }) => props.width,
    height: (props: { width: string; height: string }) => props.height,
    position: 'relative',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  webviewer: {
    width: '100%',
    height: '100%',
  },

  loadingDocument: {
    position: 'absolute',
    zIndex: 1,
  },
});
interface Props {
  isLoading: boolean;
  viewer: React.MutableRefObject<HTMLDivElement | null>;
  width: string;
  height: string;
}

const CustomWebViewer: React.FC<Props> = ({
  isLoading,
  viewer,
  width,
  height,
}) => {
  const classes = useStyles({ width, height });

  return (
    <div className={classes.documentContainer}>
      {isLoading && (
        <CircularProgress color="primary" className={classes.loadingDocument} />
      )}

      <div className={classes.webviewer} ref={viewer} />
    </div>
  );
};

export default CustomWebViewer;
