/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LoadingButton from '@mui/lab/LoadingButton';
import MoveIcon from '@mui/icons-material/DriveFileMove';
import FolderIcon from '@mui/icons-material/Folder';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import ForwardIcon from '@mui/icons-material/ArrowForwardIos';
import DescriptionIcon from '@mui/icons-material/Description';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';

import useCostCenterContext from '../../hooks/useCostCenterContext';
import useDialogContext from '../../hooks/useDialogContext';

import { Subfolder, MyDocument } from '../../types/Document';
import { FolderArrayObject, DestinationFolder } from '../../types/Folder';

import api from '../../services/api';

import COLORS from '../../utils/colors';
import toAbbreviateString from '../../utils/toAbbreviateString';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%rem',
    minWidth: '42rem',
    margin: '0 auto',
  },

  foldersContainer: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'flex-start',
    width: '100%',
    minWidth: '24rem',
    height: 'auto',
    overflow: 'auto',
    gap: '.3rem',
    marginTop: '1rem',
  },

  folderContainer: {
    width: '100%',
    minHeight: '34px',
    maxHeight: '34px',
    height: '100%',
    gap: '10px',
    cursor: 'pointer',
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '30rem',
    padding: '0 1.5rem 1.5rem',
    gap: '10px',
  },

  text: {
    textAlign: 'justify',
    color: COLORS.black,
    fontWeight: 'bold',
    fontSize: '.925rem',
  },

  menuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    gap: '.5rem',
    transition: 'all .3s ease-out',
    '&.MuiMenuItem-root': {
      '&:selected': {
        backgroundColor: `${theme.palette.secondary.main} !important`,
        '& .MuiSvgIcon-root': {
          color: COLORS.white,
        },
      },
      '&:active': {
        backgroundColor: theme.palette.secondary.main,
        color: COLORS.white,
        '& .MuiSvgIcon-root': {
          color: COLORS.white,
        },
      },
    },
  },

  dialogActions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '2rem',
    alignSelf: 'end',
    marginTop: '1rem',
    gap: '.5rem',
  },
}));

interface Props {
  folder?: FolderArrayObject;
  setFolder?: (folder: FolderArrayObject) => void;
  folderDocument?: MyDocument;
  setFolderDocument?: (folderDocument: MyDocument) => void;
  isDocument?: boolean;
  openModalMoveToFolder?: boolean;
  setOpenModalMoveToFolder?: (openModalMoveToFolder: boolean) => void;
  handleCloseMenu?: () => void;
}

const ModalMoveFoldersAndFiles: React.FC<Props> = ({
  isDocument,
  setOpenModalMoveToFolder,
  handleCloseMenu,
}) => {
  const { costId } = useParams();
  const { showDialog } = useDialogContext();
  const [open, setOpen] = useState(false);

  const {
    innerSubfolders,
    innerDocuments,
    selectedFoldersIds,
    selectedDocuments,
    folderArray,
    page,
    rowsPerPage,
    isMoving,
    isSubfolderPage,
    mainSubfolders,
    mainDocuments,
    moveFoldersAndDocuments,
    folderNavigation,
  } = useCostCenterContext();

  const [isSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpen = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    setOpenModalMoveToFolder?.(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpenModalMoveToFolder?.(false);
    handleCloseMenu?.();
    setOpen(false);
  };

  const [currentCostCenter] = useState(
    JSON.parse(localStorage.getItem('current-cost-center') || 'null'),
  );

  /* current folder opened in the modal */
  const [displayedFolder, setDisplayedFolder] = useState({
    name:
      folderArray.length > 0
        ? folderArray[folderArray.length - 1].name
        : currentCostCenter.nome,
    id:
      folderArray.length > 0
        ? folderArray[folderArray.length - 1].id
        : currentCostCenter.id,
    type:
      folderArray.length > 0
        ? folderArray[folderArray.length - 1].type
        : currentCostCenter.type,
  });

  /* current folders' list from the displayed folder */
  const [displayedFolderList, setDisplayedFolderList] =
    useState(innerSubfolders);

  /* current documents' list from the displayed folder */
  const [displayedDocumentList, setDisplayedDocumentList] =
    useState(innerDocuments);

  const [selectedDestinationFolder, setSelectedDestinationFolder] = useState(
    {} as Subfolder,
  );

  /* forward button to be displayed when the folder is selected */
  const [displayEnterFolderButton, setDisplayEnterFolderButton] =
    useState(false);

  /* get selected folders from array of ids */
  const [selectedFolders, setSelectedFolders] = useState<Subfolder[]>(
    [] as Subfolder[],
  );

  const [disableMoveButton, setDisableMoveButton] = useState(false);

  const classes = useStyles();

  const [folderArrayModalCopy, setFolderArrayModalCopy] = useState(
    JSON.parse(localStorage.getItem('folderArray') || '[]'),
  );

  const [isSubfolder, setIsSubfolder] = useState(false);

  const changeFolders = async (direction: 'back' | 'forward') => {
    setIsLoading(true);
    if (direction === 'back') {
      if (folderArrayModalCopy.length > 1) {
        if (!isSubfolder) {
          folderArrayModalCopy.pop();
          setFolderArrayModalCopy(folderArrayModalCopy);
        }
        setDisplayedFolder({
          name: folderArrayModalCopy[folderArrayModalCopy.length - 1].name,
          id: folderArrayModalCopy[folderArrayModalCopy.length - 1].id,
          type: folderArrayModalCopy[folderArrayModalCopy.length - 1].type,
        });
        try {
          if (folderArrayModalCopy[folderArrayModalCopy.length - 1].id) {
            const response = await api.get(
              `documents_folder/${costId}?page=${page}&limit=${rowsPerPage}&folder=${
                folderArrayModalCopy[folderArrayModalCopy.length - 1].id
              }`,
            );
            setDisplayedFolderList(response.data.folders);
            setDisplayedDocumentList(response.data.documents);
          }
          setIsLoading(false);
          setIsSubfolder(false);
        } catch (error: any) {
          console.log(error);
          if (!error.response) {
            showDialog(
              'default',
              'Erro inesperado. Por favor, tente novamente mais tarde!',
            );
            return;
          }
          setIsLoading(false);
          setIsSubfolder(false);
          showDialog('default', error.response.data.message);
        }
      } else {
        try {
          setFolderArrayModalCopy([]);
          const response = await api.get(
            `documents_folder/${costId}?page=${page}&limit=${rowsPerPage}`,
          );
          setDisplayedFolder({
            name: currentCostCenter.nome,
            id: currentCostCenter.id,
            type: currentCostCenter.type,
          });
          setDisplayedFolderList(response.data.folders);
          setDisplayedDocumentList(response.data.documents);
          setIsLoading(false);
        } catch (error: any) {
          console.log(error);
          if (!error.response) {
            showDialog(
              'default',
              'Erro inesperado. Por favor, tente novamente mais tarde!',
            );
            return;
          }
          setIsLoading(false);
          showDialog('default', error.response.data.message);
        }
      }
    }

    /* enter subfolder */
    if (direction === 'forward') {
      setIsSubfolder(true);
      try {
        const response = await api.get(
          `documents_folder/${costId}?page=${page}&limit=${rowsPerPage}&folder=${selectedDestinationFolder.id}`,
        );
        setDisplayedFolder({
          name: selectedDestinationFolder.name,
          id: selectedDestinationFolder.id,
          type: selectedDestinationFolder.type,
        });
        setDisplayedFolderList(response.data.folders);
        setDisplayedDocumentList(response.data.documents);
        setFolderArrayModalCopy([
          ...folderArrayModalCopy,
          {
            name: selectedDestinationFolder.name,
            id: selectedDestinationFolder.id,
          },
        ]);
        setIsSubfolder(false);
        setIsLoading(false);
      } catch (error: any) {
        console.log(error);
        if (!error.response) {
          showDialog(
            'default',
            'Erro inesperado. Por favor, tente novamente mais tarde!',
          );
          return;
        }
        setIsLoading(false);
        setIsSubfolder(false);
        showDialog('default', error.response.data.message);
      }
    }
  };

  const selectFolderToMoveItens = (
    event: React.MouseEvent,
    subfolder: Subfolder,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (selectedDestinationFolder !== ({} as Subfolder)) {
      setSelectedDestinationFolder({} as Subfolder);
      setDisplayEnterFolderButton(false);
    }
    setSelectedDestinationFolder(subfolder);

    switch (event.detail) {
      case 1:
        setDisplayEnterFolderButton(true);
        break;

      case 2:
        changeFolders('forward');
        break;

      default:
        break;
    }
  };

  const isEmpty = Object.keys(selectedDestinationFolder).length === 0;
  const whereToSend = () => {
    if (isEmpty || !selectedDestinationFolder) {
      return displayedFolder as DestinationFolder;
    }
    return selectedDestinationFolder as DestinationFolder;
  };

  const moveItensToNewFolder = () => {
    /* if there is no selected destination folder (is empty), send to it's parent folder (current displayed folder) */
    moveFoldersAndDocuments(
      selectedFoldersIds,
      selectedDocuments,
      whereToSend(),
      currentCostCenter,
      isSubfolderPage,
    );
  };

  const chooseFolderFromList = (
    e: React.MouseEvent<
      HTMLButtonElement | HTMLDivElement | SVGSVGElement | HTMLSpanElement,
      MouseEvent
    >,
    subFolder: Subfolder,
  ) => {
    setDisableMoveButton(false);
    if (folderArray) {
      if (subFolder.id === folderArray[folderArray.length - 1]?.id) {
        setDisableMoveButton(true);
      }
    }

    if (subFolder.id === currentCostCenter?.id) {
      setDisableMoveButton(true);
    }
    selectFolderToMoveItens(e, subFolder);
  };

  useEffect(() => {
    return () => {
      setSelectedDestinationFolder({} as Subfolder);
      setDisplayEnterFolderButton(false);
    };
  }, []);

  useEffect(() => {
    setFolderArrayModalCopy(
      JSON.parse(localStorage.getItem('folderArray') || '[]'),
    );

    /* if it is a subfolder page, open last folder in the array; else, open the cost center folder */
    setDisplayedFolder({
      name:
        folderArray.length > 0
          ? folderArray[folderArray.length - 1].name
          : currentCostCenter.nome,
      id:
        folderArray.length > 0
          ? folderArray[folderArray.length - 1].id
          : currentCostCenter.id,
      type:
        folderArray.length > 0
          ? folderArray[folderArray.length - 1].type
          : currentCostCenter.type,
    });

    /* reload content from current folder after closing modal */
    if (displayedFolder.id === currentCostCenter.id) {
      setDisplayedFolderList(mainSubfolders);
      setDisplayedDocumentList(mainDocuments);
    } else {
      setDisplayedFolderList(innerSubfolders);
      setDisplayedDocumentList(innerDocuments);
    }

    /* bring selected folders to the modal */
    const aux: Subfolder[] = [];
    displayedFolderList?.map(displayedF => {
      selectedFoldersIds.map(selectedF => {
        if (displayedF.id === selectedF) {
          aux.push(displayedF);
          setSelectedFolders(aux);
        }
      });
    });
  }, [open]);

  useEffect(() => {
    /* disable move button if the folder contains the selected folders or documents */
    let hasSelectedFolders;
    selectedFoldersIds.map(selectedF => {
      hasSelectedFolders = displayedFolderList?.find(
        displayedF => selectedF === displayedF.id,
      );
      return !!hasSelectedFolders;
    });

    let hasSelectedDocuments;

    selectedDocuments.map(selectedD => {
      hasSelectedDocuments = displayedDocumentList?.find(
        displayedD => selectedD === displayedD.id,
      );
      return !!hasSelectedDocuments;
    });

    if (hasSelectedFolders || hasSelectedDocuments) {
      setDisableMoveButton(true);
    }

    return () => {
      setDisableMoveButton(false);
    };
  }, [displayedFolderList, displayedDocumentList]);

  return (
    <>
      <MenuItem
        /* disabled if all folders are selected to move in the main folder (nowhere available to send) */
        disabled={
          !isSubfolderPage &&
          selectedFoldersIds.length === displayedFolderList?.length
        }
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          handleOpen(e);
        }}
        className={classes.menuItem}
      >
        <MoveIcon sx={{ color: COLORS.mediumGray5 }} />
        Mover{' '}
        {selectedDocuments.length > 0 && selectedFolders.length > 0
          ? ' itens'
          : isDocument
          ? selectedDocuments.length > 1
            ? ' documentos '
            : ' documento '
          : selectedFolders.length > 1
          ? ' pastas'
          : ' pasta'}
      </MenuItem>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className={classes.dialog}
        sx={{
          minWidth: '30rem',
          width: '100%',
          minHeight: '40vh',
          height: '100%',
        }}
        onClick={() => {
          setSelectedDestinationFolder({} as Subfolder);
          setDisplayEnterFolderButton(false);
        }}
      >
        <DialogTitle
          style={{ cursor: 'move' }}
          id="draggable-dialog-title"
          sx={{
            userSelect: 'none',
            padding: '1.3rem .8rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: isLoading ? 'flex-end' : 'space-between',
          }}
        >
          {!isLoading ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '.5rem',
                marginLeft:
                  displayedFolder.name === currentCostCenter.nome
                    ? '.6rem'
                    : '0',
              }}
            >
              {displayedFolder.name !== currentCostCenter.nome ? (
                <IconButton
                  aria-label="Voltar"
                  component="label"
                  sx={{ color: COLORS.mediumGray5 }}
                  onClick={() => changeFolders('back')}
                >
                  <ArrowBackIcon />
                </IconButton>
              ) : null}
              {displayedFolder.name}
            </Box>
          ) : null}
          <IconButton
            aria-label="Fechar"
            component="label"
            sx={{ color: COLORS.mediumGray5 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              minWidth: '24rem',
              minHeight: '8rem',
            }}
          >
            <CircularProgress size={28} color="primary" />
          </Box>
        ) : (
          <Grid
            className={classes.foldersContainer}
            sx={{
              flexDirection: 'column',
              '::-webkit-scrollbar': {
                width: '10px',
              },
              '::-webkit-scrollbar-track': {
                background: COLORS.lightGray3,
                borderRadius: '50px',
              },
              '::-webkit-scrollbar-thumb': {
                background: COLORS.mediumGray2,
                borderRadius: '50px',
              },
            }}
          >
            {isMoving ? (
              <Box
                sx={{
                  width: '100%',
                  minHeight: '10rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress size={28} />
              </Box>
            ) : displayedFolderList && displayedFolderList.length > 0 ? (
              displayedFolderList.map(subfolder => (
                <Tooltip
                  key={subfolder.name}
                  title={
                    selectedFolders &&
                    folderNavigation &&
                    (subfolder.name ===
                      folderNavigation[folderNavigation.length - 1]?.name ||
                      subfolder.name === currentCostCenter.nome)
                      ? 'Os itens já se encontram nesta pasta'
                      : selectedFolders.find(f => f?.id === subfolder.id) ||
                        selectedFoldersIds.find(f => f === subfolder.id)
                      ? `Não é possível mover a pasta ${subfolder.name} para ela mesma`
                      : 'Mover para cá'
                  }
                >
                  {(selectedFolders &&
                    selectedFolders.find(f => f?.id === subfolder.id)) ||
                  selectedFoldersIds.find(f => f === subfolder.id) ? (
                    <Box
                      className={classes.folderContainer}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '.8rem',
                        color: COLORS.lightGray4,
                        padding: '0 1.5rem',
                        cursor: 'unset',
                        backgroundColor: COLORS.lightGray6,
                      }}
                    >
                      <FolderIcon />
                      <Typography>
                        {toAbbreviateString(subfolder.name, 26)}
                      </Typography>
                    </Box>
                  ) : (
                    <Button
                      className={classes.folderContainer}
                      type="button"
                      aria-label="Clique para selecionar a pasta"
                      sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '0 1.5rem',
                        textTransform: 'none',
                        backgroundColor:
                          selectedDestinationFolder.id === subfolder.id
                            ? 'primary.main'
                            : 'transparent',
                        color:
                          selectedDestinationFolder.id === subfolder.id
                            ? COLORS.white
                            : COLORS.mediumGray5,
                        '&:hover': {
                          opacity: 0.8,
                          backgroundColor:
                            selectedDestinationFolder.id === subfolder.id
                              ? 'primary.main'
                              : COLORS.background,
                        },
                      }}
                      onClick={e => {
                        chooseFolderFromList(e, subfolder);
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '.8rem',
                        }}
                      >
                        <FolderIcon />
                        {toAbbreviateString(subfolder.name, 26)}
                      </Box>
                      {displayEnterFolderButton
                        ? selectedDestinationFolder.id === subfolder.id && (
                            <IconButton
                              aria-label="Acessar a pasta"
                              component="label"
                              sx={{ color: COLORS.white }}
                              onClick={() => {
                                setSelectedDestinationFolder(subfolder);
                                changeFolders('forward');
                              }}
                            >
                              <ForwardIcon sx={{ fontSize: '1.2rem' }} />
                            </IconButton>
                          )
                        : null}
                    </Button>
                  )}
                </Tooltip>
              ))
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Typography>Pasta vazia.</Typography>
              </Box>
            )}
            {!isMoving &&
            displayedDocumentList &&
            displayedDocumentList.length > 0
              ? displayedDocumentList?.map(document => (
                  <Tooltip
                    key={document.id}
                    title={`Não é possível mover para ${document.documento} pois este é um documento.`}
                    sx={{ textAlign: 'center' }}
                  >
                    <Box
                      className={classes.folderContainer}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '.8rem',
                        color: COLORS.lightGray4,
                        padding: '0 1.5rem',
                        cursor: 'unset',
                      }}
                    >
                      <DescriptionIcon />
                      <Typography>
                        {toAbbreviateString(document.documento, 26)}
                      </Typography>
                    </Box>
                  </Tooltip>
                ))
              : null}
          </Grid>
        )}

        <Box className={classes.dialogActions}>
          <Button
            onClick={handleClose}
            sx={{
              width: '7rem',
              color: COLORS.mediumGray3,
              '&:hover': {
                backgroundColor: COLORS.lightGray2,
              },
            }}
          >
            Cancelar
          </Button>

          {isSubmitting ? (
            <LoadingButton
              loading
              loadingPosition="center"
              sx={{
                width: '7rem',
                height: '2.25rem',
                color: COLORS.white,
                backgroundColor: 'primary.main',
                '&:hover': {
                  backgroundColor: 'primary.light',
                },
              }}
            />
          ) : (
            <Button
              disabled={
                isLoading ||
                /* no selected destination folder and the displayed folder is the current folder */
                (Object.keys(selectedDestinationFolder).length === 0 &&
                  (isSubfolderPage
                    ? displayedFolder.id ===
                      folderArray[folderArray.length - 1].id
                    : displayedFolder.id === currentCostCenter.id)) ||
                !!selectedFoldersIds.find(
                  f => f === selectedDestinationFolder.id,
                ) ||
                disableMoveButton
              }
              type="button"
              endIcon={<MoveIcon />}
              sx={{
                width: '8rem',
                color: COLORS.white,
                backgroundColor: 'primary.main',
                '&:hover': {
                  backgroundColor: 'primary.light',
                },

                '&.Mui-disabled': {
                  backgroundColor: 'primary.light',
                  color: COLORS.white,
                },
              }}
              onClick={moveItensToNewFolder}
            >
              Mover
            </Button>
          )}
        </Box>
      </Dialog>
    </>
  );
};

export default ModalMoveFoldersAndFiles;
