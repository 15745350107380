/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from '@mui/icons-material/Edit';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import moment from 'moment';

import RoleBasedMainBox from '../../components/RoleBasedMainBox';
import ContentTitle from '../../components/ContentTitle';
import ContentSubTitle from '../../components/ContentSubTitle';
import ActionButton from '../../components/ActionButton';
import UsersInCostCenter from './UsersInCostCenter';
import ModalChangePassword from './ModalChangePassword';
import EditAccountInformationModal from './EditAccountInformationModal';
import EditAddressModal from './EditAddressModal';
import AddSingleCreditsModal from '../SubscriptionPlans/AddSingleCreditsModal';
import DeleteAccountModal from './DeleteAccountModal';

import useAuthContext from '../../hooks/useAuthContext';
import useSubscriptionContext from '../../hooks/useSubscriptionContext';
import useWhiteLabel from '../../hooks/useWhiteLabel';

import { Client } from '../../types/Client';

import api from '../../services/api';

import COLORS from '../../utils/colors';
import { cpfMask, cnpjMask, cepMask, phoneMask } from '../../utils/masks';
import formatPrice from '../../utils/formatPrice';

const useStyles = makeStyles({
  titleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    margin: '0 0 1rem 0',
  },

  titleAndInformationBox: {
    display: 'flex',
    flex: '2',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  textsAndButtonsBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  informationBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  textBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    minWidth: '12rem',
  },

  buttonsBox: {
    flex: '1',
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
});

const InnerContentBox: React.FC = ({ children }) => {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column',
        backgroundColor: COLORS.white,
        border: `1px solid rgba(0, 0, 0, 0.12)`,
        borderRadius: '10px',
        p: 3,
        overflow: 'hidden',
        width: '100%',
        height: '100%',
      }}
    >
      {children}
    </Box>
  );
};

const periodicitiesTranslate = {
  day: 'dia',
  week: 'semana',
  month: 'mês',
  two_months: 'bimestral',
  quarter: 'trimestral',
  semester: 'semestral',
  year: 'ano',
  two_years: 'bienal',
  three_years: 'trienal',
};

export default function UserMyAccount() {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const [client, setClient] = useState<Client>({} as Client);

  const { costCenter, userInfo } = useAuthContext();
  const { whiteLabel } = useWhiteLabel();

  const { subscription, subscriptionDetailsLink, getSubscriptionDetailsLink } =
    useSubscriptionContext();

  useEffect(() => {
    api
      .get(`/cliente/${costCenter?.id}`)
      .then(response => {
        setClient(response.data);
      })
      .catch(error => {
        console.log(error);
      });

    getSubscriptionDetailsLink();
  }, []);

  return (
    <RoleBasedMainBox>
      {client.id ? (
        <>
          <InnerContentBox>
            <Box className={classes.textsAndButtonsBox}>
              <Box className={classes.titleAndInformationBox}>
                <ContentTitle text="Informações Pessoais" margin="0 0 1rem 0" />

                <Box
                  className={classes.informationBox}
                  sx={{
                    [theme.breakpoints.between(1000, 1100)]: {
                      gap: '1rem',
                    },
                    [theme.breakpoints.between(1100, 1200)]: {
                      gap: '2rem',
                    },
                    [theme.breakpoints.between(1200, 1300)]: {
                      gap: '8rem',
                    },
                    [theme.breakpoints.up(1300)]: {
                      gap: '10rem',
                    },
                  }}
                >
                  <Box className={classes.textBox}>
                    <ContentSubTitle
                      justify="flex-start"
                      text="Nome"
                      fontWeight="bold"
                      textAlign="left"
                      margin=".5rem 0"
                      alignSelf="start"
                    />
                    {client.id && client.documento.length === 11 && (
                      <ContentSubTitle
                        justify="flex-start"
                        text="Data de Nascimento"
                        fontWeight="bold"
                        textAlign="left"
                        margin=".5rem 0"
                        alignSelf="start"
                      />
                    )}
                    {client.id && client.documento.length === 11 ? (
                      <ContentSubTitle
                        justify="flex-start"
                        text="CPF"
                        fontWeight="bold"
                        textAlign="left"
                        margin=".5rem 0"
                        alignSelf="start"
                      />
                    ) : (
                      <ContentSubTitle
                        justify="flex-start"
                        text="CNPJ"
                        fontWeight="bold"
                        textAlign="left"
                        margin=".5rem 0"
                        alignSelf="start"
                      />
                    )}
                    <ContentSubTitle
                      justify="flex-start"
                      text="Telefone"
                      fontWeight="bold"
                      textAlign="left"
                      margin=".5rem 0"
                      alignSelf="start"
                    />
                    <ContentSubTitle
                      justify="flex-start"
                      text="E-mail"
                      fontWeight="bold"
                      textAlign="left"
                      margin=".5rem 0"
                      alignSelf="start"
                    />
                  </Box>

                  <Box className={classes.textBox}>
                    <ContentSubTitle
                      justify="flex-start"
                      text={client.nome}
                      margin=".5rem 0"
                      alignSelf="start"
                    />
                    {client.documento.length === 11 && (
                      <ContentSubTitle
                        justify="flex-start"
                        text={moment(client.data_nascimento).format('L')}
                        margin=".5rem 0"
                        alignSelf="start"
                      />
                    )}
                    <ContentSubTitle
                      justify="flex-start"
                      text={
                        client.documento.length === 11
                          ? cpfMask(client.documento)
                          : cnpjMask(client.documento)
                      }
                      margin=".5rem 0"
                      alignSelf="start"
                    />
                    <ContentSubTitle
                      justify="flex-start"
                      text={phoneMask(client.telefone || '')}
                      margin=".5rem 0"
                      alignSelf="start"
                    />
                    <ContentSubTitle
                      justify="flex-start"
                      text={client.email}
                      margin=".5rem 0"
                      alignSelf="start"
                    />
                  </Box>
                </Box>
              </Box>
              {userInfo && userInfo.isOwner && whiteLabel !== 'conlicitacao' && (
                <Box className={classes.buttonsBox} sx={{ gap: '1.5rem' }}>
                  <EditAccountInformationModal
                    client={client}
                    setClient={setClient}
                  />
                </Box>
              )}
            </Box>
          </InnerContentBox>

          {client.endereco && (
            <InnerContentBox>
              <Box className={classes.textsAndButtonsBox}>
                <Box className={classes.titleAndInformationBox}>
                  <ContentTitle text="Endereço" margin="0 0 1rem 0" />
                  <Box
                    className={classes.informationBox}
                    sx={{
                      [theme.breakpoints.between(1000, 1100)]: {
                        gap: '1rem',
                      },
                      [theme.breakpoints.between(1100, 1200)]: {
                        gap: '2rem',
                      },
                      [theme.breakpoints.between(1200, 1300)]: {
                        gap: '8rem',
                      },
                      [theme.breakpoints.up(1300)]: {
                        gap: '10rem',
                      },
                    }}
                  >
                    <Box className={classes.textBox}>
                      <ContentSubTitle
                        justify="flex-start"
                        text="Endereço"
                        fontWeight="bold"
                        textAlign="left"
                        margin=".5rem 0"
                        alignSelf="start"
                      />
                      <ContentSubTitle
                        justify="flex-start"
                        text="CEP"
                        fontWeight="bold"
                        textAlign="left"
                        margin=".5rem 0"
                        alignSelf="start"
                      />
                      <ContentSubTitle
                        justify="flex-start"
                        text="Cidade/Estado"
                        fontWeight="bold"
                        textAlign="left"
                        margin=".5rem 0"
                        alignSelf="start"
                      />
                    </Box>
                    <Box className={classes.textBox}>
                      <ContentSubTitle
                        justify="flex-start"
                        text={`${client.endereco}, ${client.numero}`}
                        margin=".5rem 0"
                        alignSelf="start"
                      />
                      <ContentSubTitle
                        justify="flex-start"
                        text={cepMask(client.cep)}
                        margin=".5rem 0"
                        alignSelf="start"
                      />
                      <ContentSubTitle
                        justify="flex-start"
                        text={`${client.cidade}/${client.estado}`}
                        margin=".5rem 0"
                        alignSelf="start"
                      />
                    </Box>
                  </Box>
                </Box>
                {userInfo && userInfo.isOwner && whiteLabel !== 'conlicitacao' && (
                  <Box className={classes.buttonsBox} sx={{ gap: '1.5rem' }}>
                    <EditAddressModal client={client} setClient={setClient} />
                  </Box>
                )}
              </Box>
            </InnerContentBox>
          )}

          {whiteLabel !== 'conlicitacao' &&
            whiteLabel !== 'codata' &&
            whiteLabel !== 'prevLaw' &&
            costCenter && (
              <InnerContentBox>
                <Box className={classes.textsAndButtonsBox}>
                  <Box className={classes.titleAndInformationBox}>
                    <ContentTitle text="Centro de Custo" margin="0 0 1rem 0" />
                    <Box
                      className={classes.informationBox}
                      sx={{
                        [theme.breakpoints.between(1000, 1100)]: {
                          gap: '1rem',
                        },
                        [theme.breakpoints.between(1100, 1200)]: {
                          gap: '2rem',
                        },
                        [theme.breakpoints.between(1200, 1300)]: {
                          gap: '8rem',
                        },
                        [theme.breakpoints.up(1300)]: {
                          gap: '10rem',
                        },
                      }}
                    >
                      <Box className={classes.textBox}>
                        <ContentSubTitle
                          text="Centro de custo atual"
                          fontWeight="bold"
                          textAlign="left"
                          margin=".5rem 0"
                          alignSelf="start"
                          justify="flex-start"
                        />
                        <ContentSubTitle
                          text="Proprietário"
                          fontWeight="bold"
                          textAlign="left"
                          margin=".5rem 0"
                          alignSelf="start"
                          justify="flex-start"
                        />
                        <ContentSubTitle
                          text="Email cadastrado"
                          fontWeight="bold"
                          textAlign="left"
                          margin=".5rem 0"
                          alignSelf="start"
                          justify="flex-start"
                        />
                        <ContentSubTitle
                          text="Criado em"
                          fontWeight="bold"
                          textAlign="left"
                          margin=".5rem 0"
                          alignSelf="start"
                          justify="flex-start"
                        />
                        <ContentSubTitle
                          text="Atualizado em"
                          fontWeight="bold"
                          textAlign="left"
                          margin=".5rem 0"
                          alignSelf="start"
                          justify="flex-start"
                        />
                      </Box>

                      <Box className={classes.textBox}>
                        <ContentSubTitle
                          text={costCenter.nome}
                          margin=".5rem 0"
                          alignSelf="start"
                          justify="flex-start"
                        />
                        <ContentSubTitle
                          text={costCenter.proprietario.name}
                          margin=".5rem 0"
                          alignSelf="start"
                          justify="flex-start"
                        />
                        <ContentSubTitle
                          text={costCenter.proprietario.email}
                          margin=".5rem 0"
                          alignSelf="start"
                          justify="flex-start"
                        />
                        <ContentSubTitle
                          text={moment(costCenter.created_at).format('L LT')}
                          margin=".5rem 0"
                          alignSelf="start"
                          justify="flex-start"
                        />
                        <ContentSubTitle
                          text={moment(costCenter.updated_at).format('L LT')}
                          margin=".5rem 0"
                          alignSelf="start"
                          justify="flex-start"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <UsersInCostCenter />
              </InnerContentBox>
            )}

          {whiteLabel !== 'conlicitacao' &&
            whiteLabel !== 'codata' &&
            whiteLabel !== 'prevLaw' && (
              <InnerContentBox>
                <Box className={classes.textsAndButtonsBox}>
                  <Box className={classes.titleAndInformationBox}>
                    <ContentTitle
                      text="Assinatura de Plano"
                      margin="0 0 1rem 0"
                    />
                    {subscription ? (
                      <Box
                        className={classes.informationBox}
                        sx={{
                          [theme.breakpoints.between(1000, 1100)]: {
                            gap: '1rem',
                          },
                          [theme.breakpoints.between(1100, 1200)]: {
                            gap: '2rem',
                          },
                          [theme.breakpoints.between(1200, 1300)]: {
                            gap: '8rem',
                          },
                          [theme.breakpoints.up(1300)]: {
                            gap: '10rem',
                          },
                        }}
                      >
                        <Box className={classes.textBox}>
                          <ContentSubTitle
                            justify="flex-start"
                            text="Plano atual"
                            fontWeight="bold"
                            textAlign="left"
                            margin=".5rem 0"
                            alignSelf="start"
                          />
                          <ContentSubTitle
                            justify="flex-start"
                            text="Descrição"
                            fontWeight="bold"
                            textAlign="left"
                            margin=".5rem 0"
                            alignSelf="start"
                          />
                          <ContentSubTitle
                            justify="flex-start"
                            text="Valor / Período"
                            fontWeight="bold"
                            textAlign="left"
                            margin=".5rem 0"
                            alignSelf="start"
                          />
                          <ContentSubTitle
                            justify="flex-start"
                            text="Total de documentos"
                            fontWeight="bold"
                            textAlign="left"
                            margin=".5rem 0"
                            alignSelf="start"
                          />
                          <ContentSubTitle
                            justify="flex-start"
                            text="Validade"
                            fontWeight="bold"
                            textAlign="left"
                            margin=".5rem 0"
                            alignSelf="start"
                          />
                          <ContentSubTitle
                            justify="flex-start"
                            text="Criada em"
                            fontWeight="bold"
                            textAlign="left"
                            margin=".5rem 0"
                            alignSelf="start"
                          />
                          <ContentSubTitle
                            justify="flex-start"
                            text="Atualizada em"
                            fontWeight="bold"
                            textAlign="left"
                            margin=".5rem 0"
                            alignSelf="start"
                          />
                        </Box>
                        <Box className={classes.textBox}>
                          <ContentSubTitle
                            justify="flex-start"
                            text={subscription.plano.nome}
                            margin=".5rem 0"
                            alignSelf="start"
                          />
                          <ContentSubTitle
                            justify="flex-start"
                            text={subscription?.plano.descricao}
                            margin=".5rem 0"
                            alignSelf="start"
                          />
                          <ContentSubTitle
                            justify="flex-start"
                            text={`R$ ${formatPrice(
                              subscription?.plano.valor,
                            )} / ${
                              periodicitiesTranslate[
                                subscription.plano.periodicidade
                              ]
                            }`}
                            margin=".5rem 0"
                            alignSelf="start"
                          />
                          <ContentSubTitle
                            justify="flex-start"
                            text={
                              subscription?.type === 'limited'
                                ? JSON.stringify(
                                    subscription.quantidade_documentos,
                                  )
                                : 'Ilimitado'
                            }
                            margin=".5rem 0"
                            alignSelf="start"
                          />
                          <ContentSubTitle
                            justify="flex-start"
                            text={`${moment(subscription.valido_de).format(
                              'L',
                            )} até ${moment(subscription.valido_ate).format(
                              'L',
                            )}`}
                            margin=".5rem 0"
                            alignSelf="start"
                            color={
                              moment(subscription.valido_ate).diff(moment()) < 0
                                ? COLORS.redWarning
                                : undefined
                            }
                          />
                          <ContentSubTitle
                            justify="flex-start"
                            text={moment(subscription.created_at).format(
                              'L LT',
                            )}
                            margin=".5rem 0"
                            alignSelf="start"
                          />
                          <ContentSubTitle
                            justify="flex-start"
                            text={moment(subscription.updated_at).format(
                              'L LT',
                            )}
                            margin=".5rem 0"
                            alignSelf="start"
                          />
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        className={classes.informationBox}
                        sx={{
                          [theme.breakpoints.between(1000, 1100)]: {
                            gap: '1rem',
                          },
                          [theme.breakpoints.between(1100, 1200)]: {
                            gap: '2rem',
                          },
                          [theme.breakpoints.between(1200, 1300)]: {
                            gap: '8rem',
                          },
                          [theme.breakpoints.up(1300)]: {
                            gap: '10rem',
                          },
                        }}
                      >
                        <ContentSubTitle
                          justify="flex-start"
                          text="Você não tem assinatura de plano ativa no momento."
                          margin=".5rem 0"
                          alignSelf="start"
                        />
                      </Box>
                    )}
                  </Box>

                  {userInfo?.user.id === costCenter?.proprietario.id &&
                    subscription?.plano.tipo !== 'cortesia' && (
                      <Box
                        className={classes.buttonsBox}
                        sx={{ gap: '1.5rem' }}
                      >
                        {!subscription ? (
                          <ActionButton
                            text="Adicionar Assinatura"
                            type="button"
                            margin="0"
                            desktopwidth="16rem"
                            icon={
                              <EditIcon
                                sx={{ color: COLORS.white, fontSize: '1.3rem' }}
                              />
                            }
                            action={() => navigate('/planos')}
                          />
                        ) : (
                          <ActionButton
                            text="Gerencie seu plano"
                            type="button"
                            margin="0"
                            mobilewidth="12rem"
                            tabletwidth="12rem"
                            laptopwidth="14rem"
                            desktopwidth="16rem"
                            icon={
                              <AttachMoneyIcon
                                sx={{ color: COLORS.white, fontSize: '1.3rem' }}
                              />
                            }
                            action={() => {
                              window.open(subscriptionDetailsLink, '_blank');
                            }}
                          />
                        )}
                        <AddSingleCreditsModal addCreditsButton />
                      </Box>
                    )}
                </Box>
              </InnerContentBox>
            )}

          <InnerContentBox>
            <Box className={classes.textsAndButtonsBox}>
              <Box className={classes.titleAndInformationBox}>
                <ContentTitle text="Informações da Conta" margin="0 0 1rem 0" />

                <Box
                  className={classes.informationBox}
                  sx={{
                    [theme.breakpoints.between(1000, 1100)]: {
                      gap: '1rem',
                    },
                    [theme.breakpoints.between(1100, 1200)]: {
                      gap: '2rem',
                    },
                    [theme.breakpoints.between(1200, 1300)]: {
                      gap: '8rem',
                    },
                    [theme.breakpoints.up(1300)]: {
                      gap: '10rem',
                    },
                  }}
                >
                  <Box className={classes.textBox}>
                    <ContentSubTitle
                      justify="flex-start"
                      text="Criada em"
                      fontWeight="bold"
                      textAlign="left"
                      margin=".5rem 0"
                      alignSelf="start"
                    />

                    <ContentSubTitle
                      justify="flex-start"
                      text="Atualizada em"
                      fontWeight="bold"
                      textAlign="left"
                      margin=".5rem 0"
                      alignSelf="start"
                    />
                  </Box>

                  <Box className={classes.textBox}>
                    <ContentSubTitle
                      justify="flex-start"
                      text={moment(client.created_at).format('L LT')}
                      margin=".5rem 0"
                      alignSelf="start"
                    />
                    <ContentSubTitle
                      justify="flex-start"
                      text={moment(client.updated_at).format('L LT')}
                      margin=".5rem 0"
                      alignSelf="start"
                    />
                  </Box>
                </Box>
              </Box>

              <Box className={classes.buttonsBox} sx={{ gap: '1.5rem' }}>
                <ModalChangePassword />

                <DeleteAccountModal />
              </Box>
            </Box>
          </InnerContentBox>
        </>
      ) : (
        <Grid
          sx={{
            width: '100%',
            height: '70vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 auto',
          }}
        >
          <CircularProgress color="primary" />
        </Grid>
      )}
    </RoleBasedMainBox>
  );
}
