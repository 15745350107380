import Grid from '@mui/material/Grid';

import useWhiteLabel from '../hooks/useWhiteLabel';

import dattasignLaptop from '../assets/images/dattasign_telas.png';
import crmPBBackgroundLogin from '../assets/images/crmPB/crmPB_background_login.png';
import backgroundCodata from '../assets/images/codata/background_codata.png';

export default function BackgroundImage() {
  const { whiteLabel } = useWhiteLabel();

  let backgroundImage = dattasignLaptop;

  if (whiteLabel === 'codata') {
    backgroundImage = backgroundCodata;
  }

  if (whiteLabel === 'crmpb') {
    backgroundImage = crmPBBackgroundLogin;
  }

  return (
    <Grid
      data-testid="image-grid"
      item
      sx={{
        width: '100%',
        backgroundImage: `url( ${backgroundImage})`,
        overflow: 'hidden',
        backgroundPosition: 'center',
        minHeight: '100vh',
        display: {
          mobile: 'none',
          laptop: 'flex',
        },
        backgroundSize: {
          tablet: 'contain',
          desktop: 'cover',
        },
        backgroundRepeat: {
          tablet: 'no-repeat',
        },
      }}
    />
  );
}
