/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Box, Typography, CircularProgress } from '@mui/material';
import styled from '@mui/styles/styled';
import CheckIcon from '@mui/icons-material/Check';
import CreditCardIcon from '@mui/icons-material/CreditCard';

import ContentTitle from '../../components/ContentTitle';
import ContentSubTitle from '../../components/ContentSubTitle';
import ActionButton from '../../components/ActionButton';
import SubscriptionPlan from '../../components/SubscriptionPlan';
import PaymentMethodCard from './UserSubscriptionPlans/PaymentMethodCard';
import StripeImage from '../../components/Images/StripeImage';
import Plan1Image from '../../components/Images/Plan1Image';

import useDialogContext from '../../hooks/useDialogContext';
import useAuthContext from '../../hooks/useAuthContext';

import { Plan } from '../../types/Plan';

import api from '../../services/api';

import COLORS from '../../utils/colors';
import formatPrice from '../../utils/formatPrice';

const CardsGrid = styled(Grid)({
  width: '100%',
  height: '70%',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

interface Address {
  zipCode: string;
  estate: string;
  city: string;
  district: string;
  address: string;
  residenceNumber: number;
  complement: string;
}

export default function Payment() {
  const navigate = useNavigate();

  const { showDialog } = useDialogContext();
  const { costCenter } = useAuthContext();

  const selectedPlan = JSON.parse(
    localStorage.getItem('selected-plan') || '{}',
  ) as Plan;

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    localStorage.getItem('selected-payment-method'),
  );

  const [isLoadingPay, setIsLoadingPay] = useState(false);

  const choosePaymentMethod = (method: string) => {
    setSelectedPaymentMethod(method);
    localStorage.setItem('selected-payment-method', method);
  };

  const handlePay = async () => {
    setIsLoadingPay(true);
    const fullAddress = JSON.parse(
      localStorage.getItem('user-address') || 'null',
    ) as Address | null;

    if (!fullAddress) {
      return;
    }

    try {
      const response = await api.post(
        `/checkout-subscription/${costCenter?.id}`,
        {
          priceStripe: selectedPlan.stripe_price,
          endereco: fullAddress.address,
          cep: fullAddress.zipCode,
          cidade: fullAddress.city,
          estado: fullAddress.estate,
          numero: fullAddress.residenceNumber,
        },
      );

      window.open(response.data, '_blank');
    } catch (error) {
      showDialog(
        'default',
        'Erro ao tenhar assinar um plano. Tente novamente mais tarde',
      );
      console.log(error);
    }
    setIsLoadingPay(false);
  };

  return (
    <>
      <ContentTitle text="Escolha a forma de pagamento" />

      <Grid
        sx={{
          width: '80%',
          height: '70%',
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'flex-start',
        }}
      >
        {/* payment methods */}
        <Box
          sx={{
            width: '100%',
            height: '55vh',
            margin: '2rem auto',
            backgroundColor: COLORS.background,
            borderRadius: '10px 0 0px 10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          {/* <ContentSubTitle fontWeight="bold" text="Como prefere pagar?" /> */}
          <ContentSubTitle fontWeight="bold" text="COMUNICADO" />

          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              alignItems: 'center',
            }}
          >
            <StripeImage style={{ width: '12rem', height: '12rem' }} />

            <ContentSubTitle
              text="Prezado cliente, informamos que no momento a plataforma aceita apenas pagamentos em cartão de crédito, que são processados de forma segura pelo nosso parceiro Stripe."
              fontWeight="bold"
              width="60%"
              textAlign="justify"
              fontSize=".9rem"
              margin="1rem auto .3rem auto"
              alignSelf="center"
            />
          </Grid>

          <Box
            sx={{
              width: '100%',
              padding: '1rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <PaymentMethodCard
              paymentMethod="Cartão de Crédito"
              selectedPaymentMethod={selectedPaymentMethod || ''}
              action={() => choosePaymentMethod('Cartão de Crédito')}
            >
              <CreditCardIcon
                sx={{
                  color:
                    selectedPaymentMethod === 'Cartão de Crédito'
                      ? 'secondary.main'
                      : COLORS.mediumGray5,
                }}
              />
            </PaymentMethodCard>
          </Box>
        </Box>

        <Grid
          sx={{
            width: '60%',
            height: '55vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginTop: '2rem',
            borderRadius: '0 10px 10px 0',
            backgroundColor: 'secondary.main',
          }}
        >
          <ContentSubTitle
            color={COLORS.white}
            fontWeight="bold"
            text="Plano selecionado"
          />
          <CardsGrid>
            <SubscriptionPlan
              title={selectedPlan?.nome}
              ImageComponent={<Plan1Image />}
              price={selectedPlan?.valor}
              selectedPlan={selectedPlan}
            >
              <CheckIcon
                sx={{
                  fontSize: '16px',
                  display: 'flex',
                  color: 'secondary.main',
                }}
              />

              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  color: 'secondary.main',
                  width: '65%',
                  textAlign: 'center',
                }}
              >
                {selectedPlan?.descricao}
              </Typography>
            </SubscriptionPlan>
          </CardsGrid>
        </Grid>
      </Grid>

      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          gap: '8px',
          width: '50%',
          margin: '0 auto',
        }}
      >
        <Box
          sx={{
            width: '25rem !important',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '2rem',
          }}
        >
          <ActionButton
            color={COLORS.mediumGray2}
            hoverColor={COLORS.mediumGray4}
            type="button"
            text="Voltar"
            action={() => {
              navigate(-1);
            }}
          />
        </Box>

        <Box
          sx={{
            width: '25rem !important',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '2rem',
          }}
        >
          <ActionButton
            type="submit"
            text={
              isLoadingPay ? '' : `Pagar R$ ${formatPrice(selectedPlan?.valor)}`
            }
            action={
              selectedPaymentMethod
                ? handlePay
                : () => {
                    showDialog(
                      'chooseMethodPayment',
                      'Escolha um método de pagamento para poder continuar.',
                    );
                  }
            }
            icon={
              isLoadingPay ? (
                <CircularProgress style={{ color: COLORS.white }} size={24} />
              ) : null
            }
            disabled={isLoadingPay}
          />
        </Box>
      </Grid>
    </>
  );
}
