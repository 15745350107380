/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import CssBaseline from '@mui/material/CssBaseline';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import { Theme } from '@mui/material';
import styled from '@mui/styles/styled';
import LogoutIcon from '@mui/icons-material/Logout';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import BadgeIcon from '@mui/icons-material/Badge';

import Credits from './Credits';
import UserAvatar from './UserAvatar';

import useAuthContext from '../hooks/useAuthContext';
import useCostCenterContext from '../hooks/useCostCenterContext';
import useWhiteLabel from '../hooks/useWhiteLabel';

import COLORS from '../utils/colors';
import { simplifyName } from '../utils/formatName';

const useStyles = makeStyles((theme: Theme) => ({
  innerHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '1rem',
  },
  roleContainer: {
    padding: '.2rem .5rem',
    borderRadius: '4px',
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '.3rem',
  },
  roleText: {
    fontWeight: '600 !important',
    textTransform: 'capitalize',
    marginTop: '.4rem !important',
  },
  myMenuItem: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '.5rem',
    height: '48px',
    transition: 'all .3s ease-out',
    '& .Mui-selected': {
      bgcolor: `${theme.palette.secondary.main} !important`,
      fontWeight: 600,
      '& .MuiSvgIcon-root': {
        color: COLORS.white,
      },
    },
    '& .MuiMenuItem-root': {
      '&:selected': {
        backgroundColor: `${theme.palette.secondary.main} !important`,
        '& .MuiSvgIcon-root': {
          color: COLORS.white,
        },
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
      '&:focus': {
        backgroundColor: theme.palette.secondary.main,
        color: COLORS.white,
        '& .MuiSvgIcon-root': {
          color: COLORS.white,
        },
      },
      '&:active': {
        backgroundColor: theme.palette.secondary.main,
        color: COLORS.white,
        '& .MuiSvgIcon-root': {
          color: COLORS.white,
        },
      },
    },
  },

  listButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },

  selectedCenterIcon: {
    minWidth: 'fit-content !important',
    width: 'fit-content',
  },

  myToolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    paddingTop: '0',
    paddingBottom: '0',
  },
}));

const ToolbarGrid = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const NameBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 0.5rem',
  minWidth: '8rem',
});

const AvatarStack = styled(Stack)({
  textAlign: 'center',
  display: 'flex',
  margin: '1rem auto',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

interface Props {
  navBarWidth: string;
}

export default function NavBar({ navBarWidth }: Props) {
  const { isLogged, userInfo, handleLogout, costCenter } = useAuthContext();
  const { whiteLabel } = useWhiteLabel();
  const {
    userCostCenters,
    hasChangedCurrentCostCenter,
    getUserInformation,
    changeCurrentCostCenter,
  } = useCostCenterContext();

  const navigate = useNavigate();

  const name = userInfo?.user.name ?? '';
  const email = userInfo?.user.email ?? '';
  const role = userInfo?.user.roles[0].nome ?? '';

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (role === 'user') {
      getUserInformation();
    }
  }, [hasChangedCurrentCostCenter]);

  return (
    <>
      <Box
        width={navBarWidth}
        sx={{
          flexGrow: 1,
          position: 'fixed',
          right: '0',
          paddingLeft: whiteLabel === 'conlicitacao' ? '1.2rem' : '0',
          overflow: 'hidden',
        }}
      >
        <AppBar
          position="static"
          elevation={0}
          style={{
            backgroundColor: COLORS.white,
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            width: '100%',
            height: '67px',
          }}
        >
          <CssBaseline />

          <Toolbar className={classes.myToolbar} sx={{ paddingRight: '16px' }}>
            <ToolbarGrid sx={{ width: '100%' }}>
              {role === 'user' ? (
                <Box className={classes.innerHeaderContainer}>
                  <Credits />
                </Box>
              ) : (
                <Box className={classes.innerHeaderContainer}>
                  <Box className={classes.roleContainer}>
                    <BadgeIcon sx={{ fontSize: '1.4rem' }} />

                    <Typography className={classes.roleText}>{role}</Typography>
                  </Box>
                </Box>
              )}
              <Tooltip title="Configurações da Conta">
                <Button
                  variant="text"
                  color="inherit"
                  onClick={handleOpenUserMenu}
                >
                  <Stack
                    direction="row"
                    spacing={0}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <UserAvatar
                      style={{
                        width: 46,
                        height: 46,
                        backgroundColor: 'primary.main',
                      }}
                    />
                    <NameBox>
                      <Typography
                        sx={{
                          textTransform: 'capitalize',
                          color: 'primary.main',
                          fontSize: '1.125rem',
                        }}
                      >
                        {isLogged && simplifyName(name)}
                      </Typography>
                      {role === 'user' && (
                        <span
                          style={{
                            textTransform: 'none',
                            color: COLORS.mediumGray4,
                            fontSize: '0.75rem',
                          }}
                        >
                          Centro de custo: {costCenter?.nome}
                        </span>
                      )}
                    </NameBox>

                    <KeyboardArrowDownIcon sx={{ color: 'primary.main' }} />
                  </Stack>
                </Button>
              </Tooltip>
            </ToolbarGrid>
          </Toolbar>
        </AppBar>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleUserMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            margin: '0 auto',
            alignSelf: 'center',
            minWidth: '18rem',
            overflow: 'visible',
            filter: 'drop-shadow(0px 1px 4px rgba(0,0,0,0.32))',
            mt: 0.7,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <AvatarStack spacing={2}>
          <UserAvatar
            style={{
              width: 62,
              height: 62,
              margin: '0 auto',
              backgroundColor: 'primary.main',
            }}
          />

          <Typography sx={{ color: 'primary.main' }}>{name}</Typography>

          <Typography>{email}</Typography>
        </AvatarStack>

        {whiteLabel !== 'conlicitacao' && <Divider sx={{ margin: '0' }} />}

        {role === 'user' && whiteLabel !== 'conlicitacao' && (
          <MenuItem
            className={classes.myMenuItem}
            sx={{
              justifyContent: 'center',
              marginTop: '.5rem',
            }}
            onClick={() => {
              navigate('/minha_conta');
            }}
          >
            <Avatar sx={{ margin: '1rem 0', bgcolor: 'primary.light' }} /> Minha
            Conta
            <Divider sx={{ margin: '0' }} />
          </MenuItem>
        )}

        {whiteLabel !== 'conlicitacao' &&
          whiteLabel !== 'codata' &&
          whiteLabel !== 'prevLaw' && <Divider sx={{ margin: '0' }} />}

        {role === 'user' &&
          whiteLabel !== 'conlicitacao' &&
          whiteLabel !== 'codata' &&
          whiteLabel !== 'prevLaw' && (
            <div>
              <Box
                sx={{
                  justifyContent: 'center',
                  margin: '1rem 0 .5rem',
                  position: 'relative',
                  cursor: 'default',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center ',
                  }}
                >
                  <SwitchAccountIcon
                    sx={{ margin: '0 .5rem 0 0', color: COLORS.mediumGray3 }}
                  />
                  {userCostCenters && userCostCenters?.length > 1
                    ? 'Alterar centro de custo ativo'
                    : 'Centro de custo ativo'}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  padding: '0 .5rem',
                  width: '100%',
                }}
              >
                <List
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: '100%',
                    marginBottom: '1.2rem',
                    maxHeight: '190px',
                    overflow: 'auto',
                    gap: '.2rem',
                    '::-webkit-scrollbar': {
                      width: '10px',
                    },
                    '::-webkit-scrollbar-track': {
                      background: COLORS.lightGray3,
                      borderRadius: '50px',
                    },
                    '::-webkit-scrollbar-thumb': {
                      background: COLORS.mediumGray2,
                      borderRadius: '50px',
                    },
                    '::-webkit-scrollbar-thumb:hover': {
                      background: COLORS.mediumGray3,
                    },
                  }}
                >
                  {userCostCenters?.map(costcenter => (
                    <ListItem
                      key={costcenter?.id}
                      className={classes.myMenuItem}
                      sx={{
                        borderLeft: `6px solid ${
                          costcenter?.id === costCenter?.id
                            ? COLORS.green
                            : COLORS.lightGray3
                        }`,
                        paddingLeft: '0',
                        paddingRight: '0',
                        cursor:
                          costcenter?.id === costCenter?.id
                            ? 'default'
                            : 'pointer',
                        '&:hover': {
                          backgroundColor: `${COLORS.hoverGreen2} !important`,
                          borderColor: COLORS.lightGreen,
                        },
                        '&:selected': {
                          backgroundColor: `${COLORS.hoverGreen2} !important`,
                        },
                        '&:active': {
                          backgroundColor: `${COLORS.hoverGreen2} !important`,
                          borderColor: `${COLORS.green} !important`,
                        },
                        '&:focus': {
                          backgroundColor: `${COLORS.hoverGreen2} !important`,
                          borderColor: `${COLORS.green} !important`,
                        },
                      }}
                    >
                      <ListItemButton
                        selected={costcenter?.id === costCenter?.id}
                        onClick={() => {
                          if (costcenter?.id !== costCenter?.id) {
                            changeCurrentCostCenter(costcenter);
                            handleUserMenuClose();
                          }
                        }}
                        className={classes.listButton}
                        sx={{
                          cursor:
                            costcenter?.id === costCenter?.id
                              ? 'default'
                              : 'pointer',
                          backgroundColor:
                            costcenter?.id === costCenter?.id
                              ? `${COLORS.white} !important`
                              : 'transparent',
                          '&:selected': {
                            backgroundColor: `${COLORS.hoverGreen2} !important`,
                          },
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }}
                      >
                        <ListItemText primary={costcenter?.nome} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </div>
          )}
        {whiteLabel !== 'conlicitacao' && <Divider sx={{ margin: '0' }} />}

        {whiteLabel !== 'conlicitacao' && (
          <MenuItem
            className={classes.myMenuItem}
            sx={{ justifyContent: 'center', marginTop: '.5rem' }}
            onClick={() => {
              handleLogout();
              navigate('/');
            }}
          >
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            Sair
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
