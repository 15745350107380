/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import moment from 'moment';

import CustomProgress from '../../../components/CustomProgress';
import ConfirmDeleteDocumentModal from '../ConfirmDeleteDocumentModal';
import ItsSoEmptyHere from '../ItsSoEmptyHere';
import SigneesAvatarGroup from '../../../components/SigneesAvatarGroup';

import useAuthContext from '../../../hooks/useAuthContext';
import useCostCenterContext from '../../../hooks/useCostCenterContext';

import { Document } from '../../../types/Document';
import { LoadingSignedAndReportFile } from '../DocumentGrid';

import { getUserInitials } from '../../../utils/formatName';
import toAbbreviateString from '../../../utils/toAbbreviateString';
import COLORS from '../../../utils/colors';

const useStyles = makeStyles((theme: Theme) => ({
  headerTable: {
    '& th': {
      border: 0,
      color: COLORS.black,
      fontSize: '1.175rem',
    },
  },

  row: {
    '& td': {
      fontSize: '1.025rem',
      borderBottom: `1px solid ${COLORS.lightGray5}`,
      color: COLORS.mediumGray5,
      fontWeight: 500,
    },
  },

  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    columnGap: '0.75rem',
  },

  buttonSign: {
    '&.MuiButtonBase-root': {
      textTransform: 'none',
      backgroundColor: theme.palette.primary.main,
      color: COLORS.white,
      fontSize: '0.875rem',
      height: '2rem',
      width: '7rem',
      borderRadius: '.625rem',
      padding: 0,
      fontWeight: 500,

      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        transition: '0.3s',
      },
    },
  },

  buttonDownload: {
    '&.MuiButtonBase-root': {
      backgroundColor: COLORS.green,
      color: COLORS.white,
      borderRadius: '.625rem',
      height: '2rem',
      width: '2.25rem',

      '& svg': {
        fontSize: '1.25rem',
      },

      '&:hover': {
        backgroundColor: COLORS.hoverGreen,
        transition: '0.3s',
      },

      '&.Mui-disabled': {
        backgroundColor: COLORS.hoverGreen,
        color: COLORS.white,
      },
    },
  },

  buttonDelete: {
    '&.MuiButtonBase-root': {
      backgroundColor: COLORS.red,
      color: COLORS.white,
      borderRadius: '.625rem',
      height: '2rem',
      width: '2.25rem',

      '& svg': {
        fontSize: '1.25rem',
      },

      '&:hover': {
        backgroundColor: COLORS.lightRed,
        transition: '0.3s',
      },
    },
  },

  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
  },

  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
  },

  stack: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  imageDesktop: {
    width: '18rem',
  },
}));

type Props = {
  isLoading: boolean;
  isLoadingDownloadSignedAndReportFile: LoadingSignedAndReportFile;
  page: number;
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => void;
  rowsPerPage: number;
  handleDownloadSignedAndReportFile: (
    documentId: number,
    fileName: string,
  ) => void;
  updateDocuments: () => void;
  documents: Document[] | null | undefined;
  totalDocuments: number;
};

const TabPanel = ({
  isLoading,
  isLoadingDownloadSignedAndReportFile,
  page,
  handleChangePage,
  rowsPerPage,
  handleDownloadSignedAndReportFile,
  updateDocuments,
  documents,
  totalDocuments,
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { userInfo } = useAuthContext();

  const { getUserInformation } = useCostCenterContext();

  useEffect(() => {
    getUserInformation();
  }, []);

  const renderCostCenterName = (
    costcenterName: string,
    proprietorName: string,
  ) => {
    if (costcenterName.length > 24) {
      return (
        <Tooltip title={costcenterName} placement="bottom">
          <p style={{ marginBottom: '0' }}>
            {/* check if the document owner is the logged in user */}
            {proprietorName === userInfo?.user.name
              ? toAbbreviateString(costcenterName, 24)
              : 'A definir ao assinar'}
          </p>
        </Tooltip>
      );
    }
    return (
      <p style={{ marginBottom: '0' }}>
        {/* check if the document owner is the logged in user */}
        {proprietorName === userInfo?.user.name
          ? costcenterName
          : 'A definir ao assinar'}
      </p>
    );
  };

  return (
    <div style={{ height: '100%', width: '100%', padding: '.5rem 0 .5rem 0' }}>
      {documents && !isLoading ? (
        documents.length > 0 ? (
          <>
            <Table>
              <TableHead>
                <TableRow className={classes.headerTable}>
                  <TableCell>Nome</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Proprietário</TableCell>
                  <TableCell>Participantes</TableCell>
                  <TableCell>Progresso</TableCell>
                  <TableCell>Centro de custo</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {documents.length > 0 &&
                  documents.map(document => (
                    <TableRow key={document.id} className={classes.row}>
                      <TableCell>{document.nome}</TableCell>
                      <TableCell>
                        {moment(document.created_at).format('L')}
                      </TableCell>
                      <TableCell>{document.proprietario.name}</TableCell>
                      <TableCell>
                        <SigneesAvatarGroup
                          max={2}
                          total={document.total_signatarios}
                          signees={document.signatarios}
                        >
                          {document.signatarios.map(signee => {
                            const alreadySigned = signee.status === 'assinado';

                            return (
                              <Tooltip
                                key={signee.id}
                                title={
                                  alreadySigned
                                    ? `${signee.nome} assinou`
                                    : `${signee.nome} não assinou`
                                }
                                placement="bottom"
                              >
                                <Avatar
                                  style={{
                                    color: COLORS.gray2,
                                    backgroundColor: alreadySigned
                                      ? COLORS.green
                                      : COLORS.lightGray4,
                                    fontSize: '0.75rem',
                                  }}
                                >
                                  {getUserInitials(signee.nome)}
                                </Avatar>
                              </Tooltip>
                            );
                          })}
                        </SigneesAvatarGroup>
                      </TableCell>
                      <TableCell>
                        <CustomProgress
                          totalComplete={document.progresso_signatarios}
                          total={document.total_signatarios}
                        />
                      </TableCell>
                      <TableCell>
                        {renderCostCenterName(
                          document.centro_custo.nome,
                          document.proprietario.name,
                        )}
                      </TableCell>
                      <TableCell>
                        <div className={classes.actions}>
                          {document.status === 'finalizado' && (
                            <Tooltip title="Baixar documento e relatório">
                              <IconButton
                                className={classes.buttonDownload}
                                disabled={
                                  document.status !== 'finalizado' ||
                                  isLoadingDownloadSignedAndReportFile[
                                    document.id
                                  ]
                                }
                                onClick={() => {
                                  handleDownloadSignedAndReportFile(
                                    document.id,
                                    document.nome,
                                  );
                                }}
                              >
                                {!isLoadingDownloadSignedAndReportFile[
                                  document.id
                                ] ? (
                                  <DownloadIcon />
                                ) : (
                                  <CircularProgress
                                    sx={{ color: COLORS.white }}
                                    size="1.25rem"
                                  />
                                )}
                              </IconButton>
                            </Tooltip>
                          )}
                          <Tooltip title="Ver documento">
                            <Button
                              endIcon={<FileOpenOutlinedIcon />}
                              className={classes.buttonSign}
                              onClick={() =>
                                navigate(`/documentos/${document.id}`)
                              }
                            >
                              Visualizar
                            </Button>
                          </Tooltip>

                          <ConfirmDeleteDocumentModal
                            documents={documents}
                            document={document}
                            updateDocuments={updateDocuments}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <div style={{ alignSelf: 'center' }}>
              <TablePagination
                component="div"
                count={totalDocuments}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[]}
                sx={{
                  '& .css-rprza6-MuiTablePagination-displayedRows': {
                    marginBottom: '0',
                  },
                }}
              />
            </div>
          </>
        ) : (
          <div style={{ paddingTop: '1rem' }}>
            <ItsSoEmptyHere height="50vh" />
          </div>
        )
      ) : (
        <Grid
          sx={{
            width: '100%',
            height: '64vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress color="primary" />
        </Grid>
      )}
    </div>
  );
};

export default TabPanel;
