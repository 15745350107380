/* eslint-disable consistent-return */
/* eslint-disable no-case-declarations */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { UserCostCenter, CostCenter } from '../types/CostCenter';
import { UserInformation } from '../types/UserInfo';
import { MyDocument, Subfolder } from '../types/Document';
import { FolderArrayObject, DestinationFolder } from '../types/Folder';

import useDialogContext from '../hooks/useDialogContext';

import api from '../services/api';

interface SearchedFiles {
  documents: MyDocument[];
  folders: Subfolder[];
}

interface ContextProps {
  totalCostCenters: number;
  setTotalCostCenters: (totalCostCenters: number) => void;
  adminCostCenters: CostCenter[];
  setAdminCostCenters: (adminCostCenter: CostCenter[]) => void;
  getAdminCostCenters: (page: number, rowsPerPage: number) => void;

  userCostCenters: UserCostCenter[] | null;
  setUserCostCenters: (costCenters: UserCostCenter[] | null) => void;
  userInformation: UserInformation | null;
  setUserInformation: (userInformation: UserInformation | null) => void;
  hasChangedCurrentCostCenter: boolean;
  setHasChangedCurrentCostCenter: (
    hasChangedCurrentCostCenter: boolean,
  ) => void;
  getUserInformation: () => void;

  /* UserFolderFiles */
  mainDocuments: MyDocument[];
  setMainDocuments: (mainDocuments: MyDocument[]) => void;
  mainSubfolders: Subfolder[];
  setMainSubfolders: (mainSubfolders: Subfolder[]) => void;
  /* ----  */

  /* UserSubfolderFiles */
  innerDocuments: MyDocument[];
  setInnerDocuments: (innerDocuments: MyDocument[]) => void;
  innerSubfolders: Subfolder[];
  setInnerSubfolders: (innerSubfolders: Subfolder[]) => void;
  /* ----  */

  folderArray: FolderArrayObject[];
  setFolderArray: (folderArray: FolderArrayObject[]) => void;
  folderNavigation?: FolderArrayObject[];
  setFolderNavigation?: (folderArray: FolderArrayObject[]) => void;
  selectedFoldersIds: number[];
  setSelectedFoldersIds: (selectedFolders: number[]) => void;
  selectedDocuments: number[];
  setSelectedDocuments: (selectedDocuments: number[]) => void;

  loadingDocuments: boolean;
  setLoadingDocuments: (loadingDocuments: boolean) => void;
  totalDocuments: number;
  setTotalDocuments: (totalDocuments: number) => void;
  page: number;
  setPage: (page: number) => void;
  rowsPerPage: number;

  isSubfolderPage: boolean;
  setIsSubfolderPage: (isSubfolderPage: boolean) => void;
  updateFolder: boolean;
  setUpdateFolder: (updateFolder: boolean) => void;
  hasMovedFolder: boolean;
  setHasMovedFolder: (hasMovedFolder: boolean) => void;
  isMoving: boolean;
  setIsMoving: (isMoving: boolean) => void;
  search: string;
  setSearch: (search: string) => void;
  searchedContentFromApi: any;
  setSearchedContentFromApi: (searchedContentFromApi: any) => void;
  displayedSearchedFiles: SearchedFiles;
  setDisplayedSearchedFiles: (displayedSearchedFiles: SearchedFiles) => void;
  hasChangedDisplayedSearchedFiles: boolean;
  setHasChangedDisplayedSearchedFiles: (
    hasChangedDisplayedSearchedFiles: boolean,
  ) => void;
  isFromFolderPage: boolean;
  setIsFromFolderPage: (hasMovedFolder: boolean) => void;

  getSubfolderContent: (subfolder: Subfolder) => void;
  /* UserSubfolderFiles */
  getSubfolderFoldersAndFiles: (id: number) => Promise<void> | undefined;
  /* UserFolderFiles */
  getCostCenterFoldersAndFiles: (
    costCenterId: string | undefined,
  ) => Promise<void> | undefined;
  goToSubfolderFilesPage: (subfolder: Subfolder) => void;
  handleFolderSelection: (
    event: any,
    subfolder: Subfolder,
    pageFolders: Subfolder[],
  ) => void;
  handleDocumentSelection: (
    event: any,
    document: MyDocument,
    pageDocuments: MyDocument[],
  ) => void;
  moveFoldersAndDocuments: (
    folders: number[],
    documents: number[],
    whereTo: DestinationFolder | undefined,
    currentCostCenter: FolderArrayObject | undefined,
    source: boolean | undefined,
  ) => void;
  goBackToPreviousFolder: () => void;
  openFolderOnEnterKey: (subfolder: Subfolder) => void;
  changeCurrentCostCenter: (costcenter: UserCostCenter) => void;
}

type Props = {
  children: React.ReactNode;
};

const CostCenterContext = createContext<ContextProps>({} as ContextProps);

function CostCenterProvider({ children }: Props) {
  const [userCostCenters, setUserCostCenters] = useState<
    UserCostCenter[] | null
  >(null);

  /* admin count of registered cost centers */
  const [totalCostCenters, setTotalCostCenters] = useState(0);

  const [adminCostCenters, setAdminCostCenters] = useState([] as CostCenter[]);

  const getAdminCostCenters = (page: number, rowsPerPage: number) => {
    api
      .get('/cost_centers', {
        params: {
          page: page + 1,
          limit: rowsPerPage,
        },
      })
      .then(response => {
        setAdminCostCenters(response.data);

        const xTotalCount = response.headers['x-total-count'];
        setTotalCostCenters(Number(xTotalCount));
      })
      .catch(error => {
        console.log(error);
      });
  };

  const [userInformation, setUserInformation] =
    useState<UserInformation | null>(null);

  const [hasChangedCurrentCostCenter, setHasChangedCurrentCostCenter] =
    useState(false);

  const getUserInformation = async () => {
    try {
      const response = await api.get('/cost_centers_user');
      setUserCostCenters(response.data.centros_custo);
      setUserInformation(response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  /* Cost center finnished document folders */
  const navigate = useNavigate();

  /* folders' array used by move folders' modal */
  const [folderArray, setFolderArray] = useState(
    JSON.parse(localStorage.getItem('folderArray') || '[]'),
  );

  /* folders' array used by the breadcrumbs */
  const [folderNavigation, setFolderNavigation] = useState(
    JSON.parse(localStorage.getItem('folderNavigation') || '[]'),
  );

  const costCenter = JSON.parse(
    localStorage.getItem('current-cost-center') || '{}',
  );

  const { showDialog } = useDialogContext();

  /* used in UserFolderFiles */
  const [mainDocuments, setMainDocuments] = useState<MyDocument[]>([]);
  const [mainSubfolders, setMainSubfolders] = useState<Subfolder[]>([]);

  /* used in UserSubfolderFiles */
  const [innerDocuments, setInnerDocuments] = useState<MyDocument[]>([]);
  const [innerSubfolders, setInnerSubfolders] = useState<Subfolder[]>([]);

  /* selected folders and files */
  const [selectedFoldersIds, setSelectedFoldersIds] = useState<number[]>([]);
  const [selectedDocuments, setSelectedDocuments] = useState<number[]>([]);

  /* if true, UserSubfolderFiles (/content url) */
  const [isSubfolderPage, setIsSubfolderPage] = useState(false);
  const rowsPerPage = 10;
  const [page, setPage] = useState(0);

  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [totalDocuments, setTotalDocuments] = useState(0);

  const [updateFolder, setUpdateFolder] = useState(false);
  const [hasMovedFolder, setHasMovedFolder] = useState(false);
  const [isMoving, setIsMoving] = useState(false);

  const [search, setSearch] = useState('');

  const [searchedContentFromApi, setSearchedContentFromApi] = useState({});

  const [displayedSearchedFiles, setDisplayedSearchedFiles] = useState(
    {} as SearchedFiles,
  );
  const [
    hasChangedDisplayedSearchedFiles,
    setHasChangedDisplayedSearchedFiles,
  ] = useState(false);

  const [searchParams] = useSearchParams({});

  const [isFromFolderPage, setIsFromFolderPage] = useState(
    JSON.parse(localStorage.getItem('fromFolderPage') || 'false'),
  );

  const changeCurrentCostCenter = async (costcenter: UserCostCenter) => {
    setHasChangedCurrentCostCenter(!hasChangedCurrentCostCenter);
    if (costcenter.id !== costCenter.id) {
      try {
        /* const response = await api.post(
          `cost_centers/${costcenter.id}`,
          ); */
        localStorage.setItem('costCenter', JSON.stringify(costcenter));
        /* setUserCostCenters(response.data.centros_custo);
        setUserInformation(response.data); */
      } catch (error: any) {
        console.log(error);
        if (!error.response) {
          showDialog(
            'default',
            'Erro inesperado. Por favor, tente novamente mais tarde!',
          );
          return;
        }
        showDialog('default', error.response.data.message);
      }
    }
  };

  const goToSubfolderFilesPage = (subfolder: Subfolder) => {
    const array = [
      {
        name: subfolder.name,
        id: subfolder.id,
        type: subfolder.type,
      },
    ];
    setIsSubfolderPage(true);
    setFolderArray(array);
    setFolderNavigation?.([
      ...folderNavigation,
      {
        name: subfolder.name,
        id: subfolder.id,
        type: subfolder.type,
      },
    ]);
    localStorage.setItem('folderArray', JSON.stringify(array));
    localStorage.setItem(
      'folderNavigation',
      JSON.stringify([
        ...folderNavigation,
        {
          name: subfolder.name,
          id: subfolder.id,
          type: subfolder.type,
        },
      ]),
    );
    navigate(`/minhas_pastas/${costCenter.id}/content`, {
      state: {
        subfolderId: subfolder.id,
        mainSubfolders,
      },
    });
  };

  const getSubfolderFoldersAndFiles = async (id: number) => {
    setLoadingDocuments(true);
    try {
      if (costCenter) {
        if (id) {
          const response = await api.get(
            `documents_folder/${costCenter.id}?page=${page}&limit=${rowsPerPage}&folder=${id}`,
          );
          const xTotalCount = response.headers['x-total-count'];
          setTotalDocuments(Number(xTotalCount));
          setInnerDocuments(response.data.documents);
          setInnerSubfolders(response.data.folders);
          if (folderNavigation && response.data.folderNavigation) {
            /* get folderNavigation from api and add them to array */
            const arrayToShow: FolderArrayObject[] = [
              {
                id: costCenter.id,
                name: costCenter.nome,
                type: costCenter.type,
              },
            ];
            response.data.folderNavigation.map((obj: FolderArrayObject) => {
              arrayToShow.push(obj);
            });
            localStorage.setItem(
              'folderNavigation',
              JSON.stringify(arrayToShow),
            );
            setFolderNavigation(arrayToShow);

            /* remove cost center from array that goes to folderArray */
            const newFolderArray = response.data.folderNavigation;
            setFolderArray(newFolderArray);
            localStorage.setItem('folderArray', JSON.stringify(newFolderArray));
          }
        }
      }
      setLoadingDocuments(false);
    } catch (error: any) {
      console.log(error);
      setLoadingDocuments(false);
      if (!error.response) {
        showDialog(
          'default',
          'Erro inesperado. Por favor, tente novamente mais tarde!',
        );
        return;
      }
      showDialog('default', error.response.data.message);
    }
  };

  const getSubfolderContent = (subfolder: Subfolder) => {
    const folder = {
      name: subfolder.name,
      id: subfolder.id,
      type: subfolder.type,
    };
    setIsSubfolderPage(true);

    let foldersList;
    if (folderArray) {
      foldersList = Array.from(folderArray);
      foldersList.push(folder);
      localStorage.setItem('folderArray', JSON.stringify(foldersList));
      setFolderArray(foldersList);
    }
    getSubfolderFoldersAndFiles(Number(subfolder.id));
  };

  const openFolderOnEnterKey = (subfolder: Subfolder) => {
    if (folderNavigation) {
      const sameFolder = folderNavigation.filter(
        (el: { id: number }) => el.id === subfolder.id,
      );
      if (sameFolder.length === 0 || subfolder.id !== costCenter.id) {
        setFolderNavigation?.([
          ...folderNavigation,
          {
            id: subfolder.id,
            name: subfolder.name,
            type: subfolder.type,
          } as FolderArrayObject,
        ]);
        localStorage.setItem(
          'folderNavigation',
          JSON.stringify([
            ...folderNavigation,
            {
              id: subfolder.id,
              name: subfolder.name,
              type: subfolder.type,
            },
          ]),
        );
      }
    }

    if (isSubfolderPage) {
      setSelectedFoldersIds([]);
      setSelectedDocuments([]);
      getSubfolderContent(subfolder);
    }
    goToSubfolderFilesPage(subfolder);
  };

  const handleFolderSelection = (
    event: any,
    subfolder: Subfolder,
    pageFolders: Subfolder[],
  ) => {
    switch (event.detail) {
      /* single click */
      case 1:
        const firstFolderClicked = pageFolders.find(
          f => f.id === selectedFoldersIds[0],
        );

        if (subfolder.id) {
          let repeatedFolderId = selectedFoldersIds.filter(
            f => f === subfolder.id,
          );
          const itemIndex = selectedFoldersIds.indexOf(subfolder.id);

          if (event.ctrlKey) {
            /* remove selected folder when clicking it again */
            if (repeatedFolderId.length > 0) {
              if (itemIndex > -1) {
                setSelectedFoldersIds(
                  selectedFoldersIds.filter(f => f !== subfolder.id),
                );
              }
              repeatedFolderId = [];
            } else {
              setSelectedFoldersIds([
                ...selectedFoldersIds,
                Number(subfolder.id),
              ]);
            }
            return;
          }

          if (event.shiftKey) {
            setSelectedFoldersIds([...selectedFoldersIds, subfolder.id]);
            if (pageFolders && subfolder.id) {
              const folder = pageFolders.find(f => f.id === subfolder.id);
              if (folder && firstFolderClicked) {
                const firstIndex = pageFolders.indexOf(firstFolderClicked);
                const lastIndex = pageFolders.indexOf(folder);

                /* auxiliary array with the selected folders */
                let alteredFolderArray;
                if (firstIndex < lastIndex) {
                  /* forwards selection */
                  alteredFolderArray = pageFolders.slice(
                    firstIndex,
                    lastIndex + 1,
                  );
                } else {
                  /* backwards selection */
                  alteredFolderArray = pageFolders
                    .slice(lastIndex, firstIndex + 1)
                    .reverse();
                }
                const selectedArray: number[] = [];
                if (alteredFolderArray) {
                  /* map through the selected folders and get their ids */
                  alteredFolderArray.map(f => {
                    selectedArray.push(Number(f.id));
                  });
                  setSelectedFoldersIds(selectedArray);
                }
              }
            }
            return;
          }

          if (
            ((!event.ctrlKey || !event.shiftKey) &&
              selectedFoldersIds.length === 0) ||
            selectedDocuments.length === 0
          ) {
            setSelectedDocuments([]);
            if (repeatedFolderId.length === 0) {
              setSelectedFoldersIds([subfolder.id]);
              repeatedFolderId = [];
            }
          }
        }
        break;

      /* double click */
      case 2:
        if (searchParams.get('query')) {
          navigate(`/minhas_pastas/${costCenter.id}/content`, {
            state: {
              subfolderId: subfolder.id,
              innerSubfolders,
              innerDocuments,
            },
          });
        }
        if (folderNavigation) {
          const sameFolder = folderNavigation.filter(
            (el: { id: number }) => el.id === subfolder.id,
          );
          if (sameFolder.length === 0 || subfolder.id !== costCenter.id) {
            setFolderNavigation?.([
              ...folderNavigation,
              {
                id: subfolder.id,
                name: subfolder.name,
                type: subfolder.type,
              } as FolderArrayObject,
            ]);
            localStorage.setItem(
              'folderNavigation',
              JSON.stringify([
                ...folderNavigation,
                {
                  id: subfolder.id,
                  name: subfolder.name,
                  type: subfolder.type,
                },
              ]),
            );
          }
        }

        if (isSubfolderPage) {
          setSelectedFoldersIds([]);
          setSelectedDocuments([]);
          getSubfolderContent(subfolder);
          break;
        }
        goToSubfolderFilesPage(subfolder);
        break;

      default:
        break;
    }
  };

  const handleDocumentSelection = (
    event: any,
    document: MyDocument,
    pageDocuments: MyDocument[],
  ) => {
    switch (event.detail) {
      /* single click */
      case 1: {
        const firstDocumentClicked = pageDocuments.find(
          f => f.id === selectedDocuments[0],
        );
        if (document.id) {
          let repeatedDocumentId = selectedDocuments.filter(
            f => f === document.id,
          );
          const itemIndex = selectedDocuments.indexOf(document.id);

          if (event.ctrlKey) {
            /* remove selected folder when clicking it again */
            if (repeatedDocumentId.length > 0) {
              if (itemIndex > -1) {
                setSelectedDocuments(
                  selectedDocuments.filter(f => f !== document.id),
                );
              }
              repeatedDocumentId = [];
            } else {
              setSelectedDocuments([...selectedDocuments, Number(document.id)]);
            }
            return;
          }

          if (event.shiftKey) {
            setSelectedDocuments([...selectedDocuments, document.id]);

            if (pageDocuments && document.id) {
              const folder = pageDocuments.find(f => f.id === document.id);
              if (folder && firstDocumentClicked) {
                const firstIndex = pageDocuments.indexOf(firstDocumentClicked);
                const lastIndex = pageDocuments.indexOf(folder);

                /* auxiliary array with the selected documents */
                let alteredDocumentArray;
                if (firstIndex < lastIndex) {
                  /* forwards selection */
                  alteredDocumentArray = pageDocuments.slice(
                    firstIndex,
                    lastIndex + 1,
                  );
                } else {
                  /* backwards selection */
                  alteredDocumentArray = pageDocuments
                    .slice(lastIndex, firstIndex + 1)
                    .reverse();
                }

                const selectedArray: number[] = [];
                if (alteredDocumentArray) {
                  /* map through the selected folders and get their ids */
                  alteredDocumentArray.map(f => {
                    selectedArray.push(Number(f.id));
                  });
                  setSelectedDocuments(selectedArray);
                }
              }
            }
            return;
          }

          if (
            ((!event.ctrlKey || !event.shiftKey) &&
              selectedFoldersIds.length === 0) ||
            selectedDocuments.length === 0
          ) {
            setSelectedFoldersIds([]);
            if (repeatedDocumentId.length === 0) {
              setSelectedDocuments([document.id]);
              repeatedDocumentId = [];
            }
          }
        }
        break;
      }
      /* double click */
      case 2: {
        localStorage.setItem('fromFolderPage', 'true');
        navigate(`/documentos/${document.id}`);
        break;
      }

      default: {
        break;
      }
    }
  };

  const getCostCenterFoldersAndFiles = async (
    costCenterId: string | undefined,
  ) => {
    setLoadingDocuments(true);
    try {
      const response = await api.get(
        `documents_folder/${costCenterId}?page=${page}&limit=${rowsPerPage}`,
      );
      const xTotalCount = response.headers['x-total-count'];
      setTotalDocuments(Number(xTotalCount));
      setMainDocuments(response.data.documents);
      setMainSubfolders(response.data.folders);
      setLoadingDocuments(false);
    } catch (error: any) {
      console.log(error);
      setLoadingDocuments(false);
      if (!error.response) {
        showDialog(
          'default',
          'Erro inesperado. Por favor, tente novamente mais tarde!',
        );

        return;
      }
      showDialog('default', error.response.data.message);
    }
  };

  const moveFoldersAndDocuments = async (
    folders: number[],
    documents: number[],
    whereTo: DestinationFolder | undefined,
    currentCostCenter: FolderArrayObject | undefined,
    source: boolean | undefined,
  ) => {
    const returnedCurrentCostCenterData = () => {
      if (folders.length > 0 && documents.length > 0) {
        return {
          folders_id: folders,
          documents_id: documents,
        };
      }
      if (folders.length > 0 && documents.length === 0) {
        return {
          folders_id: folders,
        };
      }
      if (folders.length === 0 && documents.length > 0) {
        return {
          documents_id: documents,
        };
      }
    };

    const returnedData = () => {
      if (folders.length > 0 && documents.length > 0) {
        return {
          folders_id: folders,
          documents_id: documents,
          destination_folder_id: whereTo?.id,
        };
      }
      if (folders.length > 0 && documents.length === 0) {
        return {
          folders_id: folders,
          destination_folder_id: whereTo?.id,
        };
      }
      if (folders.length === 0 && documents.length > 0) {
        return {
          documents_id: documents,
          destination_folder_id: whereTo?.id,
        };
      }
    };

    try {
      setHasMovedFolder(false);
      setIsMoving(true);
      const data =
        whereTo?.type === 'cost_center'
          ? returnedCurrentCostCenterData()
          : returnedData();
      await api.put(`/documents_folder/${currentCostCenter?.id}`, data);
      setHasMovedFolder(true);

      const innerSubfoldersCopy = innerSubfolders || [];
      const mainSubfoldersCopy = mainSubfolders;
      /* source: if it's subfolder page or cost center folder page */
      if (source === true) {
        folders.map(folder => {
          innerSubfoldersCopy.filter(f => f.id !== folder);
        });
        setInnerSubfolders(innerSubfoldersCopy);
      } else {
        folders.map(folder => {
          mainSubfoldersCopy.filter(f => f.id !== folder);
        });
        setMainSubfolders(mainSubfoldersCopy);
      }
      setIsMoving(false);
    } catch (error: any) {
      setIsMoving(false);
      console.log(error);
      showDialog(
        'default',
        'Erro inesperado. Por favor, tente novamente mais tarde!',
      );
    }
  };

  const goBackToPreviousFolder = () => {
    if (folderArray && folderNavigation) {
      if (folderArray.length === 1) {
        navigate(-1);
      }
      folderArray.pop();
      setFolderArray(folderArray);
      localStorage.setItem('folderArray', JSON.stringify(folderArray));
      folderNavigation.pop();
      setFolderNavigation(folderNavigation);
      localStorage.setItem(
        'folderNavigation',
        JSON.stringify(folderNavigation),
      );
      getSubfolderFoldersAndFiles(folderArray[folderArray.length - 1]?.id);
    }
  };

  return (
    <CostCenterContext.Provider
      value={{
        adminCostCenters,
        setAdminCostCenters,
        getAdminCostCenters,
        userCostCenters,
        setUserCostCenters,
        totalCostCenters,
        setTotalCostCenters,
        userInformation,
        setUserInformation,
        hasChangedCurrentCostCenter,
        setHasChangedCurrentCostCenter,
        getUserInformation,

        mainDocuments,
        setMainDocuments,
        mainSubfolders,
        setMainSubfolders,
        innerDocuments,
        setInnerDocuments,
        innerSubfolders,
        setInnerSubfolders,

        folderArray,
        setFolderArray,
        folderNavigation,
        setFolderNavigation,
        selectedFoldersIds,
        setSelectedFoldersIds,
        selectedDocuments,
        setSelectedDocuments,

        loadingDocuments,
        setLoadingDocuments,
        totalDocuments,
        setTotalDocuments,
        rowsPerPage,
        page,
        setPage,

        isSubfolderPage,
        setIsSubfolderPage,
        updateFolder,
        setUpdateFolder,
        hasMovedFolder,
        setHasMovedFolder,
        isMoving,
        setIsMoving,
        search,
        setSearch,
        searchedContentFromApi,
        setSearchedContentFromApi,
        displayedSearchedFiles,
        setDisplayedSearchedFiles,
        hasChangedDisplayedSearchedFiles,
        setHasChangedDisplayedSearchedFiles,
        isFromFolderPage,
        setIsFromFolderPage,

        changeCurrentCostCenter,
        getSubfolderContent,
        getSubfolderFoldersAndFiles,
        getCostCenterFoldersAndFiles,
        goToSubfolderFilesPage,
        handleFolderSelection,
        handleDocumentSelection,
        moveFoldersAndDocuments,
        goBackToPreviousFolder,
        openFolderOnEnterKey,
      }}
    >
      {children}
    </CostCenterContext.Provider>
  );
}

export { CostCenterContext, CostCenterProvider };
