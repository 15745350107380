import Typography from '@mui/material/Typography';

import COLORS from '../utils/colors';

type Props = {
  width?: string;
  text: string;
  color?: string;
  fontSize?: string;
  fontWeight?: string;
  margin?: string;
  padding?: string;
  textAlign?:
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | 'center'
    | 'end'
    | 'justify'
    | 'left'
    | 'match-parent'
    | 'right'
    | 'start';

  align?: string;
  alignSelf?: string;
  justify?: string;
};

export default function ContentSubTitle({
  width,
  text,
  color,
  fontSize,
  fontWeight,
  margin,
  textAlign,
  padding,
  align,
  justify,
  alignSelf,
}: Props) {
  return (
    <Typography
      sx={{
        width,
        fontSize,
        margin,
        textAlign,
        padding,
        display: 'flex',
        justifyContent: justify,
        alignItems: align,
        alignSelf,
        color: color || COLORS.mediumGray5,
        fontWeight: fontWeight || 'medium',
      }}
    >
      {text}
    </Typography>
  );
}

ContentSubTitle.defaultProps = {
  width: '100%',
  fontSize: '1rem',
  fontWeight: 'medium',
  color: COLORS.mediumGray5,
  textAlign: 'center',
  margin: '1rem auto',
  padding: '0',
  align: 'center',
  justify: 'center',
  alignSelf: 'center',
};
