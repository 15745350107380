/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TablePagination from '@mui/material/TablePagination';
import makeStyles from '@mui/styles/makeStyles';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import RoleBasedMainBox from '../../components/RoleBasedMainBox';
import ModalCreateSubFolder from './ModalCreateSubFolder';
import Breadcrumb from './Breadcrumb';
import Folder from './Folder';
import FolderDocument from './FolderDocument';
import SearchBar from '../../components/SearchBar';

import { Subfolder } from '../../types/Document';

import useCostCenterContext from '../../hooks/useCostCenterContext';

import COLORS from '../../utils/colors';

const useStyles = makeStyles({
  filesContainer: {
    flexGrow: 1,
    backgroundColor: COLORS.white,
    border: `1px solid rgba(0, 0, 0, 0.12)`,
    borderRadius: '10px',
    width: '100%',
    height: '100%',

    padding: '1.5rem',
    minHeight: 'fit-content',
  },

  createSubfolderButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '.3rem',

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  subfolderContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: '1.2rem',
    marginBottom: '1.5rem',
  },

  filesInnerContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    gap: '1.2rem',
  },

  folderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: '18rem',
    minHeight: '70px',
    maxHeight: '70px',
    height: '100%',
    padding: '1rem',
    borderRadius: '10px',
    gap: '10px',
    border: `1px solid rgba(0, 0, 0, 0.12)`,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: COLORS.background,
    },
  },

  fileContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    height: '100%',
    minWidth: '220px',
    maxWidth: '220px',
    minHeight: '240px',
    maxHeight: '240px',
    padding: '1rem',
    borderRadius: '10px',
    gap: '14px',
    cursor: 'pointer',

    '& img': {
      width: '164px',
      height: '100%',
      minHeight: '175px',
      maxHeight: '175px',
    },

    '&:hover': {
      backgroundColor: COLORS.background,
    },
  },

  subtitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },

  goBackArrow: {
    cursor: 'pointer',
  },

  pagination: {
    '& p': {
      marginBottom: '0 !important',
    },
  },
});

export default function UserFolderFiles() {
  const {
    mainSubfolders,
    mainDocuments,
    setSelectedFoldersIds,
    setSelectedDocuments,
    isSubfolderPage,
    setIsSubfolderPage,
    selectedDocuments,
    handleFolderSelection,
    handleDocumentSelection,
    setFolderArray,
    rowsPerPage,
    page,
    setPage,
    totalDocuments,
    loadingDocuments,
    hasMovedFolder,
    folderNavigation,
    setFolderNavigation,
    getCostCenterFoldersAndFiles,
  } = useCostCenterContext();

  const classes = useStyles();
  const theme = useTheme();

  const navigate = useNavigate();
  const { costId } = useParams();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const getPathNameToSetMainFolderPage = () => {
    const pathnameCostCenterId = window.location.href.substring(
      window.location.href.lastIndexOf('/') + 1,
    );

    if (window.location.pathname === pathnameCostCenterId) {
      setIsSubfolderPage(false);
    }
  };

  const [fileOptions, setFileOptions] = useState([
    ...mainSubfolders,
    ...mainDocuments,
  ]);

  useEffect(() => {
    getPathNameToSetMainFolderPage();
    getCostCenterFoldersAndFiles(costId);
  }, [page]);

  useEffect(() => {
    if (hasMovedFolder) {
      if (costId) {
        getCostCenterFoldersAndFiles?.(costId);
      }
    }
  }, [hasMovedFolder]);

  const costCenter = JSON.parse(
    localStorage.getItem('current-cost-center') || '{}',
  );

  useEffect(() => {
    setFolderNavigation?.([
      { id: costCenter.id, name: costCenter.nome, type: costCenter.type },
    ]);
    localStorage.setItem(
      'folderNavigation',
      JSON.stringify([
        { id: costCenter.id, name: costCenter.nome, type: costCenter.type },
      ]),
    );
    setSelectedFoldersIds([]);
    setSelectedDocuments([]);
    setIsSubfolderPage(false);
    getPathNameToSetMainFolderPage();

    if (localStorage.getItem('folderArray')) {
      localStorage.removeItem('folderArray');
    }

    setFolderArray([]);
  }, []);

  useEffect(() => {
    /* change document key 'documento' to 'name' as in the folder */
    const arr = mainDocuments.map(({ documento: name, ...rest }) => ({
      name,
      ...rest,
    }));
    /* create array with folders and documents for the autocomplete options */
    setFileOptions([...mainSubfolders, ...arr]);

    return () => {
      setFileOptions([]);
    };
  }, [mainSubfolders.length, mainDocuments.length]);

  return (
    <RoleBasedMainBox gap="0" overflow="unset">
      {fileOptions && (
        <SearchBar
          options={fileOptions}
          page={page}
          rowsPerPage={rowsPerPage}
          type="myfolders"
        />
      )}

      <Box
        className={classes.subtitleContainer}
        sx={{
          [theme.breakpoints.down(1024)]: {
            flexDirection: 'column',
            alignItems: 'flex-end',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            flex: 2,
            marginBottom: '.5rem',
          }}
        >
          <Link
            underline="hover"
            color={COLORS.mediumGray5}
            href="/minhas_pastas"
            sx={{
              fontWeight: 500,
              fontSize: '.925rem',
              margin: '0 .2rem .1rem 0',
              '&:hover': {
                color: `${theme.palette.primary.main} !important`,
              },
            }}
          >
            Pastas
          </Link>
          <NavigateNextIcon
            sx={{ color: COLORS.mediumGray3, fontSize: '1.2rem' }}
          />
          {folderNavigation &&
            folderNavigation.map((folder, index) => (
              <Breadcrumb
                key={folder.id + index}
                folder={folder}
                folderIndex={index}
              />
            ))}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: '1rem',
          }}
        >
          <ModalCreateSubFolder
            isSubfolderPage={isSubfolderPage}
            getCostCenterFoldersAndFiles={getCostCenterFoldersAndFiles}
          />

          <Tooltip title="Voltar para a página anterior">
            <Button
              disableRipple
              onClick={() => navigate(-1)}
              className={classes.goBackArrow}
              sx={{
                minWidth: 'unset',
                textTransform: 'capitalize',
                '& span': {
                  marginRight: '.2rem',
                },
                '&:hover': {
                  backgroundColor: 'transparent',
                  textDecoration: 'underline',
                },
              }}
              aria-label="Voltar para as pastas"
              startIcon={<ArrowBackIcon />}
            >
              Voltar
            </Button>
          </Tooltip>
        </Box>
      </Box>
      {!loadingDocuments ? (
        <Box
          className={classes.filesContainer}
          id="filesContainer"
          sx={{
            '::-webkit-scrollbar': {
              width: '10px',
            },
            '::-webkit-scrollbar-track': {
              background: COLORS.lightGray3,
              borderRadius: '50px',
            },
            '::-webkit-scrollbar-thumb': {
              background: COLORS.mediumGray2,
              borderRadius: '50px',
            },
            '::-webkit-scrollbar-thumb:hover': {
              background: COLORS.mediumGray4,
            },
          }}
        >
          {mainSubfolders && mainSubfolders.length > 0 && (
            <>
              <Typography
                sx={{
                  fontSize: '.925rem',
                  fontWeight: '600',
                  margin: '0 0 1rem',
                  color: COLORS.mediumGray5,
                  userSelect: 'none',
                }}
              >
                Pastas
              </Typography>
              <Box className={classes.subfolderContainer}>
                {mainSubfolders &&
                  mainSubfolders.map((subfolder: Subfolder, index: number) => (
                    <Folder
                      key={subfolder.name}
                      tabIndex={index}
                      folderName={subfolder.name}
                      folderId={subfolder.id}
                      folderType={subfolder.type}
                      action={event => {
                        event.stopPropagation();
                        handleFolderSelection(event, subfolder, mainSubfolders);
                      }}
                      isSubfolderPage={isSubfolderPage}
                    />
                  ))}
              </Box>
            </>
          )}

          <Typography
            sx={{
              fontSize: '.925rem',
              fontWeight: '600',
              margin: '0 0 1rem',
              color: COLORS.mediumGray5,
              userSelect: 'none',
            }}
          >
            Arquivos
          </Typography>
          <Box className={classes.filesInnerContainer}>
            {mainDocuments && mainDocuments.length > 0 ? (
              mainDocuments.map(document => (
                <FolderDocument
                  key={document.id}
                  document={document}
                  selectedDocuments={selectedDocuments}
                  action={event => {
                    event.stopPropagation();
                    handleDocumentSelection(event, document, mainDocuments);
                  }}
                />
              ))
            ) : (
              <Typography
                sx={{
                  fontSize: '.925rem',
                  fontWeight: '600',
                  color: COLORS.mediumGray5,
                  textAlign: 'center',
                  width: '100%',
                  margin: '2rem 0',
                }}
              >
                Não há documentos finalizados nesta pasta.
              </Typography>
            )}
          </Box>
        </Box>
      ) : (
        <Box className={classes.filesContainer}>
          <Box
            sx={{
              height: '100%',
              width: '100%',
              minHeight: '67vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        </Box>
      )}
      {!loadingDocuments && mainDocuments && mainDocuments.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div style={{ alignSelf: 'center' }}>
            <TablePagination
              className={classes.pagination}
              component="div"
              count={totalDocuments}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[]}
            />
          </div>
        </Box>
      )}
    </RoleBasedMainBox>
  );
}
