/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import styled from '@mui/styles/styled';

import ContentTitle from '../../components/ContentTitle';
import ContentSubTitle from '../../components/ContentSubTitle';
import ActionButton from '../../components/ActionButton';

import { cepMask } from '../../utils/masks';
import COLORS from '../../utils/colors';
import getAddressByCep from '../../utils/getAddressByCep';

const FormGrid = styled(Grid)({
  width: '100%',
  height: '80%',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export default function UserSubscriptionPlans() {
  const navigate = useNavigate();

  // selectedPlan from ChooseSubscriptionPlan
  const { state } = useLocation();
  interface FormValues {
    zipCode: string;
    estate: string;
    city: string;
    district: string;
    address: string;
    residenceNumber: string;
    complement: string;
  }

  const {
    register,
    handleSubmit,
    unregister,
    formState: { errors },
  } = useForm<FormValues>();

  const [userAddress, setUserAddress] = useState(
    JSON.parse(
      localStorage.getItem('user-address') ||
        JSON.stringify({
          zipCode: '',
          estate: '',
          city: '',
          district: '',
          address: '',
          residenceNumber: '',
          complement: '',
        }),
    ),
  );

  const fillAddressByCep = async (cep: string) => {
    const response = await getAddressByCep(cep);

    if (!response) {
      return;
    }

    setUserAddress({
      ...userAddress,
      address: response.street,
      district: response.neighborhood,
      zipCode: cep,
      estate: response.state,
      city: response.city,
    });
  };

  const handleAddressChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;

    if (e.target.name === 'zipCode') {
      newValue = cepMask(newValue);
      fillAddressByCep(newValue);
    }

    setUserAddress({ ...userAddress, [e.target.name]: newValue });
  };

  const onSubmit: SubmitHandler<FormValues> = () => {
    const addressData: FormValues = userAddress;

    localStorage.setItem('user-address', JSON.stringify(addressData));
    navigate('/planos/pagamento', { state }); // selectedPlan from ChooseSubscriptionPlan
  };

  useEffect(() => {
    if (userAddress) {
      unregister();
    }
  }, [userAddress]);

  return (
    <>
      <ContentTitle text="Preencha as informações de endereço" />
      <ContentSubTitle text="Informe o endereço para o qual prefere receber a cobrança" />
      <FormGrid>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80%',
          }}
        >
          <form
            noValidate
            id="formulario"
            method="post"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: '100%',
              marginTop: '1rem',
              gap: '15px',
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box
              sx={{
                width: '100%',
                height: '45vh',
                margin: '0 auto',
                backgroundColor: COLORS.background,
                gap: '20px',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  gap: '2rem',
                  width: '100%',
                }}
              >
                <FormControl sx={{ width: '30%' }}>
                  <TextField
                    autoFocus
                    id="register_zipCode"
                    label="CEP *"
                    type="text"
                    variant="standard"
                    sx={{
                      '.MuiInput-root': {
                        backgroundColor: 'transparent !important',
                      },
                    }}
                    style={{
                      fontSize: '.9rem',
                      marginTop: '.5rem',
                      width: '100%',
                    }}
                    {...register('zipCode', {
                      required: 'Por favor, informe seu endereço',
                      pattern: {
                        value: /^\d{5}-?\d{3}$/,
                        message: 'Por favor, informe um CEP válido',
                      },
                    })}
                    value={userAddress.zipCode}
                    onChange={handleAddressChanges}
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(errors.zipCode)}
                    helperText={errors.zipCode?.message}
                  />
                </FormControl>

                <FormControl sx={{ width: '30%' }}>
                  <TextField
                    id="register_estate"
                    label="Estado *"
                    type="text"
                    variant="standard"
                    sx={{
                      '.MuiInput-root': {
                        backgroundColor: 'transparent !important',
                      },
                    }}
                    style={{
                      fontSize: '.9rem',
                      marginTop: '.5rem',
                      width: '100%',
                    }}
                    {...register('estate', {
                      required: 'Por favor, informe seu Estado',
                      pattern: {
                        value: /[A-Za-zÀ-ÖØ-öø-ÿ]/,
                        message: 'Por favor, digite um Estado válido',
                      },
                    })}
                    value={userAddress.estate}
                    onChange={handleAddressChanges}
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(errors.estate)}
                    helperText={errors.estate?.message}
                  />
                </FormControl>
              </Grid>

              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  gap: '2rem',
                  width: '100%',
                }}
              >
                <FormControl sx={{ width: '30%' }}>
                  <TextField
                    id="register_city"
                    label="Cidade *"
                    type="text"
                    variant="standard"
                    sx={{
                      '.MuiInput-root': {
                        backgroundColor: 'transparent !important',
                      },
                    }}
                    style={{
                      fontSize: '.9rem',
                      marginTop: '.5rem',
                      width: '100%',
                    }}
                    {...register('city', {
                      required: 'Por favor, informe sua cidade',
                      pattern: {
                        value: /[A-Za-zÀ-ÖØ-öø-ÿ]/,
                        message: 'Por favor, digite uma cidade válida',
                      },
                    })}
                    value={userAddress.city}
                    onChange={handleAddressChanges}
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(errors.city)}
                    helperText={errors.city?.message}
                  />
                </FormControl>

                <FormControl sx={{ width: '30%' }}>
                  <TextField
                    id="register_disctrict"
                    label="Bairro *"
                    type="text"
                    variant="standard"
                    sx={{
                      '.MuiInput-root': {
                        backgroundColor: 'transparent !important',
                      },
                    }}
                    style={{
                      fontSize: '.9rem',
                      marginTop: '.5rem',
                      width: '100%',
                    }}
                    {...register('district', {
                      required: 'Por favor, informe seu bairro',
                      pattern: {
                        value: /[A-Za-zÀ-ÖØ-öø-ÿ]/,
                        message: 'Por favor, digite um bairro válido',
                      },
                    })}
                    value={userAddress.district}
                    onChange={handleAddressChanges}
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(errors.district)}
                    helperText={errors.district?.message}
                  />
                </FormControl>
              </Grid>

              <FormControl sx={{ width: '64%' }}>
                <TextField
                  id="register_address"
                  label="Rua *"
                  type="text"
                  variant="standard"
                  sx={{
                    '.MuiInput-root': {
                      backgroundColor: 'transparent !important',
                    },
                  }}
                  style={{
                    fontSize: '.9rem',
                    marginTop: '.5rem',
                    width: '100%',
                  }}
                  {...register('address', {
                    required: 'Por favor, informe sua rua',
                    pattern: {
                      value: /[A-Za-zÀ-ÖØ-öø-ÿ]/,
                      message: 'Por favor, digite um endereço válido',
                    },
                  })}
                  value={userAddress.address}
                  onChange={handleAddressChanges}
                  InputLabelProps={{ shrink: true }}
                  error={Boolean(errors.address)}
                  helperText={errors.address?.message}
                />
              </FormControl>

              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  gap: '2rem',
                  width: '100%',
                }}
              >
                <FormControl sx={{ width: '30%' }}>
                  <TextField
                    id="register_residenceNumber"
                    label="Número *"
                    type="text"
                    variant="standard"
                    sx={{
                      '.MuiInput-root': {
                        backgroundColor: 'transparent !important',
                      },
                    }}
                    style={{
                      fontSize: '.9rem',
                      marginTop: '.5rem',
                      width: '100%',
                    }}
                    {...register('residenceNumber', {
                      required: 'Por favor, informe o número da sua residência',
                      pattern: {
                        value: /^[0-9]/,
                        message: 'Digite um número válido',
                      },
                    })}
                    value={userAddress.residenceNumber}
                    onChange={handleAddressChanges}
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(errors.residenceNumber)}
                    helperText={errors.residenceNumber?.message}
                  />
                </FormControl>

                <FormControl sx={{ width: '30%' }}>
                  <TextField
                    id="register_complement"
                    label="Complemento (opcional)"
                    type="text"
                    variant="standard"
                    sx={{
                      '.MuiInput-root': {
                        backgroundColor: 'transparent !important',
                      },
                    }}
                    style={{
                      fontSize: '.9rem',
                      marginTop: '.5rem',
                      width: '100%',
                    }}
                    {...register('complement')}
                    value={userAddress.complement}
                    onChange={handleAddressChanges}
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(errors.complement)}
                    helperText={errors.complement?.message}
                  />
                </FormControl>
              </Grid>
            </Box>

            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
                gap: '8px',
                width: '95%',
                marginTop: '2rem',
              }}
            >
              <Box
                sx={{
                  width: '25rem !important',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '2rem',
                }}
              >
                <ActionButton
                  color={COLORS.mediumGray2}
                  hoverColor={COLORS.mediumGray4}
                  type="button"
                  text="Voltar"
                  action={() => {
                    navigate(-1);
                  }}
                />
              </Box>

              <Box
                sx={{
                  width: '25rem !important',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '2rem',
                }}
              >
                <ActionButton type="submit" text="Avançar" />
              </Box>
            </Grid>
          </form>
        </Grid>
      </FormGrid>
    </>
  );
}
