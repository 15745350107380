/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';

import RoleBasedMainBox from '../../components/RoleBasedMainBox';
import ItsSoQuietHere from './ItsSoQuietHere';

import useAuthContext from '../../hooks/useAuthContext';

import { LastActivity } from '../../types/LastActivity';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
  },

  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
  },

  headerTable: {
    '& th': {
      border: 0,
      color: COLORS.black,
      fontSize: '1.175rem',
    },
  },

  row: {
    '& td': {
      fontSize: '1.025rem',
      borderBottom: `1px solid ${COLORS.lightGray5}`,
      color: COLORS.mediumGray5,
      fontWeight: 500,
    },
  },
});

export default function UserHistory() {
  const classes = useStyles();

  const [lastActivities, setLastActivities] = useState<LastActivity[] | null>(
    null,
  );
  const [totalActivities, setTotalActivities] = useState(0);
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;

  const [isLoading, setIsLoading] = useState(false);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const { costCenter } = useAuthContext();

  useEffect(() => {
    setIsLoading(true);

    api
      .get(`atividades/${costCenter?.id}/${page + 1}/${rowsPerPage}`)
      .then(response => {
        setLastActivities(response.data as LastActivity[]);

        const xTotalCount = response.headers['x-total-count'];
        setTotalActivities(Number(xTotalCount));
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.log(error);
      });
  }, [page]);

  return (
    <RoleBasedMainBox>
      <Grid className={classes.container}>
        {!isLoading ? (
          lastActivities && lastActivities.length ? (
            <TableContainer className={classes.tableContainer}>
              <Table>
                <TableHead>
                  <TableRow className={classes.headerTable}>
                    <TableCell>Horário</TableCell>
                    <TableCell>Atividade</TableCell>
                    <TableCell>Nome do documento</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {lastActivities.map(lastActivity => (
                    <TableRow key={lastActivity.id} className={classes.row}>
                      <TableCell>
                        {moment(lastActivity.created_at).format('LLLL')}
                      </TableCell>
                      <TableCell>{lastActivity.descricao}</TableCell>
                      <TableCell>{lastActivity.documento.nome}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <div style={{ alignSelf: 'center', width: '100%' }}>
                <TablePagination
                  component="div"
                  count={totalActivities}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[]}
                />
              </div>
            </TableContainer>
          ) : (
            <ItsSoQuietHere gap="4rem" />
          )
        ) : (
          <Grid
            sx={{
              width: '100%',
              height: '70vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0 auto',
            }}
          >
            <CircularProgress color="primary" />
          </Grid>
        )}
      </Grid>
    </RoleBasedMainBox>
  );
}
