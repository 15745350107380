import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import COLORS from '../utils/colors';

const useStyles = makeStyles({
  infoCard: {
    flex: 1,
    padding: '1.5rem',
    height: '15vh',
    minHeight: '94px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  circleBox: {
    filter: 'brightness(1.10) contrast(1)',
    borderRadius: '50%',
    width: '4rem',
    height: '4rem',
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

interface Props {
  children: React.ReactNode;
  color: string;
  text: string;
  number: number;
  link: LinkProps;
}

const InfoPanel: React.FC<Props> = ({
  children,
  color,
  text,
  link,
  number,
}) => {
  const classes = useStyles();

  return (
    <Card
      className={classes.infoCard}
      elevation={0}
      sx={{
        backgroundColor: color,
        textDecoration: 'none !important',
        '&:hover': {
          opacity: 0.9,
          transition: '0.3s',
          textDecoration: 'none !important',
        },
      }}
      component={Link}
      to={link.to}
      state={link.state}
    >
      <Box className={classes.infoBox}>
        <Typography
          sx={{ color: COLORS.white, fontWeight: 'bold', fontSize: '2rem', }}
        >
          {number}
        </Typography>
        <Typography sx={{ color: COLORS.white, fontWeight: 'bold', }}>
          {text}
        </Typography>
      </Box>
      <Box className={classes.circleBox} sx={{ backgroundColor: color }}>
        {children}
      </Box>
    </Card>
  );
};

export default InfoPanel;
