/* eslint-disable react/jsx-no-target-blank */
import { CSSProperties } from 'react';
import Box from '@mui/material/Box';

import PageMainContentBox from '../components/PageMainContentBox';
import DeniedAccess from '../components/DeniedAccess';

import useAuthContext from '../hooks/useAuthContext';
import useWhiteLabel from '../hooks/useWhiteLabel';

import roleBasedRender from '../utils/roleBasedRender';

const styles = {
  textsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  } as CSSProperties,

  paragraphContainer: {
    margin: '1.3rem 0',
    textAlign: 'justify',
  } as CSSProperties,

  subtitleTextContainer: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
    gap: '1rem',
    margin: '0',
  } as CSSProperties,

  contactContainer: {
    margin: '1rem 0 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '.6rem',
  },

  titleAndSubtitle: {
    fontSize: '1.1rem',
    fontWeight: 700,
  } as CSSProperties,

  subtitleItem: {
    fontSize: '1rem',
    fontWeight: 700,
    marginLeft: '.5rem',
  } as CSSProperties,

  subItem: {
    fontSize: '1rem',
    marginLeft: '.5rem',
  } as CSSProperties,

  subtitleContainerParagraph: {
    marginBottom: '0',
  },

  listStyle: {
    margin: '0 0 1.5rem 1.1rem',
  },

  link: {
    color: 'inherit',
  },

  divider: {
    height: '2px',
    backgroundColor: '#F0F1F2',
    width: '100%',
  },
};

const PrivacyPolicy = () => {
  return (
    <div>
      <p style={{ fontSize: '.925rem' }}>Última atualização: 19/04/2022</p>

      <div style={styles.textsContainer}>
        <p style={styles.paragraphContainer}>
          Nós coletamos e utilizamos alguns dados pessoais que pertencem àqueles
          que utilizam nosso site. Ao fazê-lo, agimos na qualidade de
          controlador desses dados e estamos sujeitos às disposições da Lei
          Federal n. 13.709/2018 (Lei Geral de Proteção de Dados Pessoais -
          LGPD). Nós cuidamos da proteção de seus dados pessoais e, por isso,
          disponibilizamos esta política de privacidade, que contém informações
          importantes sobre:
        </p>

        <ul style={styles.listStyle}>
          <li>Quem deve utilizar nosso site;</li>
          <li>Quais dados coletamos e o que fazemos com eles;</li>
          <li>Seus direitos em relação aos seus dados pessoais; e</li>
          <li>Como entrar em contato conosco.</li>
        </ul>

        <h2 style={styles.titleAndSubtitle}>
          1. Quem deve utilizar nosso site
        </h2>
        <p style={styles.paragraphContainer}>
          Nosso site só deve ser utilizado por pessoas com mais de dezoito anos
          de idade. Sendo assim, crianças e adolescentes não devem utilizá-lo.
        </p>

        <h2 style={styles.titleAndSubtitle}>
          2. Dados que coletamos e motivos da coleta
        </h2>
        <p style={styles.paragraphContainer}>
          Nosso site coleta e utiliza alguns dados pessoais de nossos usuários,
          de acordo com o disposto nesta seção.
        </p>

        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.titleAndSubtitle}>2.1</h2>
          <p>Dados pessoais fornecidos expressamente pelo usuário</p>
        </div>
        <p style={styles.paragraphContainer}>
          Nós coletamos os seguintes dados pessoais que nossos usuários nos
          fornecem expressamente ao utilizar nosso site:
        </p>

        <ul style={styles.listStyle}>
          <li>Nome Completo;</li>
          <li>Data de Nascimento;</li>
          <li>Sexo;</li>
          <li>CPF;</li>
          <li>RG;</li>
          <li>Endereço eletrônico (E-mail);</li>
          <li>Endereço Residencial;</li>
          <li>Telefone de Contato;</li>
          <li>Segmento de Mercado;</li>
          <li>
            Se representante legal:
            <ul style={{ marginLeft: '1rem' }}>
              <li>Razão Social;</li>
              <li>CNPJ;</li>
              <li>Endereço da Empresa.</li>
            </ul>
          </li>
        </ul>

        <p style={styles.paragraphContainer}>
          A coleta destes dados ocorre nos seguintes momentos:
        </p>

        <ul style={styles.listStyle}>
          <li>Quando o usuário faz o cadastro inicial no Dattasign;</li>
          <li>
            Quando o usuário faz ou complementa o cadastro para fins de operação
            e assinaturas de documentos.
          </li>
        </ul>

        <p style={styles.paragraphContainer}>
          Nós coletamos os seguintes dados pessoais de nossos usuários:
        </p>

        <ul style={styles.listStyle}>
          <li>
            Para que possam adquirir os créditos e assinar documentos por meio
            do Dattasign.
          </li>
        </ul>

        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.titleAndSubtitle}>2.2</h2>
          <p>Dados pessoais obtidos de outras formas</p>
        </div>

        <p style={styles.paragraphContainer}>
          Nós coletamos os seguintes dados pessoais de nossos usuários:
        </p>

        <ul style={styles.listStyle}>
          <li>Endereço IP;</li>
          <li>Dados de Geolocalização;</li>
          <li>
            Dados de transações realizados por meio de utilização do Dattasign.
          </li>
        </ul>
        <p>A coleta destes dados ocorre nos seguintes momentos:</p>
        <ul style={styles.listStyle}>
          <li>Quando o usuário utiliza os módulos do Dattasign;</li>
          <li>Quando o usuário assina documentos no Dattasign;</li>
          <li>Quando o usuário adquire créditos no Dattasign.</li>
        </ul>
        <p>
          Os dados fornecidos por nossos usuários são coletados com as seguintes
          finalidades:
        </p>
        <ul style={styles.listStyle}>
          <li>
            Para que possam adquirir os créditos e assinar documentos por meio
            do Dattasign.
          </li>
        </ul>

        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.titleAndSubtitle}>2.3</h2>
          <p>Dados sensíveis</p>
        </div>

        <p style={styles.paragraphContainer}>
          Não serão coletados dados sensíveis de nossos usuários, assim
          entendidos aqueles definidos nos arts. 11 e seguintes da Lei de
          Proteção de Dados Pessoais. Assim, não haverá coleta de dados sobre
          origem racial ou étnica, convicção religiosa, opinião política,
          filiação a sindicato ou a organização de caráter religioso, filosófico
          ou político, dado referente à saúde ou à vida sexual, dado genético ou
          biométrico, quando vinculado a uma pessoa natural.
        </p>

        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.titleAndSubtitle}>2.4</h2>
          <p>Cookies</p>
        </div>

        <p style={styles.paragraphContainer}>
          Cookies são pequenos arquivos de texto baixados automaticamente em seu
          dispositivo quando você acessa e navega por um site. Eles servem,
          basicamente, para seja possível identificar dispositivos, atividades e
          preferências de usuários. Os cookies não permitem que qualquer arquivo
          ou informação sejam extraídos do disco rígido do usuário, não sendo
          possível, ainda que, por meio deles, se tenha acesso a informações
          pessoais que não tenham partido do usuário ou da forma como utiliza os
          recursos do site.
        </p>

        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.subtitleItem}>a.</h2>
          <p>Cookies do site</p>
        </div>
        <p style={styles.subItem}>
          Os cookies do site são aqueles enviados ao computador ou dispositivo
          do usuário e administrador exclusivamente pelo site. As informações
          coletadas por meio destes cookies são utilizadas para melhorar e
          personalizar a experiência do usuário, sendo que alguns cookies podem,
          por exemplo, ser utilizados para lembrar as preferências e escolhas do
          usuário, bem como para o oferecimento de conteúdo personalizado.
        </p>

        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.subtitleItem}>b.</h2>
          <p>Cookies de terceiros</p>
        </div>
        <p style={styles.subItem}>
          Alguns de nossos parceiros podem configurar cookies nos dispositivos
          dos usuários que acessam nosso site. Estes cookies, em geral, visam
          possibilitar que nossos parceiros possam oferecer seu conteúdo e seus
          serviços ao usuário que acessa nosso site de forma personalizada, por
          meio da obtenção de dados de navegação extraídos a partir de sua
          interação com o site. O usuário poderá obter mais informações sobre os
          cookies de terceiro e sobre a forma como os dados obtidos a partir
          dele são tratados, além de ter acesso à descrição dos cookies
          utilizados e de suas características, acessando o seguinte link:
        </p>
        <ul style={styles.listStyle}>
          <li style={styles.subItem}>
            Google Analytics:
            <a
              href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=pt-BR"
              target="_blank"
              style={styles.subItem}
            >
              https://developers.google
              <wbr />
              .com/
              <wbr />
              analytics/
              <wbr />
              devguides/
              <wbr />
              collection/analyticsjs/cookie-usage?hl=pt-BR
            </a>
          </li>
        </ul>

        <p style={styles.subItem}>
          As entidades encarregadas da coleta dos cookies poderão ceder as
          informações obtidas a terceiros.
        </p>

        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.subtitleItem}>c.</h2>
          <p>Gestão de Cookies</p>
        </div>
        <p style={styles.subItem}>
          O usuário poderá se opor ao registro de cookies pelo site, bastando
          que desative esta opção no seu próprio navegador. Mais informações
          sobre como fazer isso em alguns dos principais navegadores utilizados
          hoje podem ser acessadas a partir dos seguintes links:
        </p>

        <ul style={styles.listStyle}>
          <li style={styles.subItem}>
            Internet Explorer:
            <a
              href="https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies"
              target="_blank"
              style={styles.link}
            >
              https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies
            </a>
          </li>
          <li style={styles.subItem}>
            Safari:
            <a
              href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac"
              target="_blank"
              style={styles.link}
            >
              https://support.apple.com/pt-br/guide/safari/sfri11471/mac
            </a>
          </li>
          <li style={styles.subItem}>
            Google Chrome:
            <a
              href="https://support.google.com/chrome/answer/95647?hl=pt-BR&hlrm=pt"
              target="_blank"
              style={styles.link}
            >
              https://support.google.com/
              <wbr />
              chrome/answer/95647?hl=pt-BR&hlrm=pt
            </a>
          </li>
          <li style={styles.subItem}>
            Internet Explorer:
            <a
              href="https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam"
              target="_blank"
              style={styles.link}
            >
              https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam
            </a>
          </li>
          <li style={styles.subItem}>
            Opera:
            <a
              href="https://www.opera.com/help/tutorials/security/privacy/"
              target="_blank"
              style={styles.link}
            >
              https://www.opera.com/help/
              <wbr />
              tutorials/security/privacy/
            </a>
          </li>
        </ul>

        <p style={styles.subItem}>
          A desativação dos cookies, no entanto, pode afetar a disponibilidade
          de algumas ferramentas e funcionalidades do site, comprometendo seu
          correto e esperado funcionamento. Outra consequência possível é
          remoção das preferências do usuário que eventualmente tiverem sido
          salvas, prejudicando sua experiência.
        </p>

        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.titleAndSubtitle}>2.5</h2>
          <p>Coleta de dados não previstos expressamente</p>
        </div>

        <p style={styles.paragraphContainer}>
          Eventualmente, outros tipos de dados não previstos expressamente nesta
          Política de Privacidade poderão ser coletados, desde que sejam
          fornecidos com o consentimento do usuário, ou, ainda, que a coleta
          seja permitida com fundamento em outra base legal prevista em lei.
        </p>
        <p style={styles.paragraphContainer}>
          Em qualquer caso, a coleta de dados e as atividades de tratamento dela
          decorrentes serão informadas aos usuários do site.
        </p>

        <h2 style={styles.titleAndSubtitle}>
          3. Compartilhamento de dados pessoais com terceiros
        </h2>
        <p style={styles.paragraphContainer}>
          Nós não compartilhamos seus dados pessoais com terceiros. No entanto,
          poderá haver exceções, como a necessidade em cumprir alguma
          determinação legal ou regulatória, ou, ainda, para cumprir alguma
          ordem expedida por autoridade pública.
        </p>

        <h2 style={styles.titleAndSubtitle}>
          4. Por quanto tempo seus dados pessoais serão armazenados
        </h2>
        <p style={styles.paragraphContainer}>
          Os dados pessoais coletados pelo site são armazenados e utilizados por
          período de tempo que corresponda ao necessário para atingir as
          finalidades elencadas neste documento e que considere os direitos de
          seus titulares, os direitos do controlador do site e as disposições
          legais ou regulatórias aplicáveis.
        </p>
        <p style={styles.paragraphContainer}>
          Uma vez expirados os períodos de armazenamento dos dados pessoais,
          eles são removidos de nossas bases de dados ou anonimizados, salvo nos
          casos em que houver a possibilidade ou a necessidade de armazenamento
          em virtude de disposição legal ou regulatória.
        </p>

        <h2 style={styles.titleAndSubtitle}>
          5. Bases legais para o tratamento de dados pessoais
        </h2>
        <p style={styles.paragraphContainer}>
          A geração dos contratos e termos pode ser realizada de forma dinâmica
          e baseada em cada tipo de documento. Para isto, disponibiliza um
          conjunto de funcionalidades, objetivando uma maior flexibilidade na
          integração, permitindo maior acoplamento e abrangência às necessidades
          do usuário. Uma base legal para o tratamento de dados pessoais nada
          mais é que um fundamento jurídico, previsto em lei, que o justifica.
          Assim, cada operação de tratamento de dados pessoais precisa ter uma
          base legal a ela correspondente.
        </p>
        <p style={styles.paragraphContainer}>
          Nós tratamos os dados pessoais de nossos usuários nas seguintes
          hipóteses:
        </p>
        <ul style={styles.listStyle}>
          <li>Mediante o consentimento do titular dos dados pessoais;</li>
          <li>
            Para o exercício regular de direitos em processo judicial,
            administrativo ou arbitral;
          </li>
          <li>
            Para a execução de contrato ou de procedimentos preliminares
            relacionados a contrato do qual seja parte o titular, a pedido do
            titular dos dados pessoais;
          </li>
          <li>
            Quando necessário para atender aos interesses legítimos do
            controlador ou de terceiro.
          </li>
        </ul>

        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.titleAndSubtitle}>5.1</h2>
          <p>Consentimento</p>
        </div>
        <p style={styles.paragraphContainer}>
          Determinadas operações de tratamento de dados pessoais realizadas em
          nosso site dependerão da prévia concordância do usuário, que deverá
          manifestá-la de forma livre, informada e inequívoca.
        </p>
        <p style={styles.paragraphContainer}>
          O usuário poderá revogar seu consentimento a qualquer momento, sendo
          que, não havendo hipótese legal que permita ou que demande o
          armazenamento dos dados, os dados fornecidos mediante consentimento
          serão excluídos.
        </p>
        <p style={styles.paragraphContainer}>
          Além disso, se desejar, o usuário poderá não concordar com alguma
          operação de tratamento de dados pessoais baseada no consentimento.
          Nestes casos, porém, é possível que não possa utilizar alguma
          funcionalidade do site que dependa daquela operação. As consequências
          da falta de consentimento para uma atividade específica são informadas
          previamente ao tratamento.
        </p>

        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.titleAndSubtitle}>5.2</h2>
          <p>Execução de contrato</p>
        </div>
        <p style={styles.paragraphContainer}>
          Para a execução de contrato de compra e venda ou de prestação de
          serviços eventualmente firmado entre o site e o usuário, poderão ser
          coletados e armazenados outros dados relacionados ou necessários a sua
          execução, incluindo o teor de eventuais comunicações tidas com o
          usuário.
        </p>

        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.titleAndSubtitle}>5.3</h2>
          <p>Legítimo interesse</p>
        </div>

        <p style={styles.paragraphContainer}>
          Para determinadas operações de tratamento de dados pessoais, nos
          baseamos exclusivamente em nosso interesse legítimo. Para saber mais
          sobre em quais casos, especificamente, nos valemos desta base legal,
          ou para obter mais informações sobre os testes que fazemos para termos
          certeza de que podemos utilizá-la, entre em contato com nosso
          Encarregado de Proteção de Dados Pessoais por algum dos canais
          informados nesta Política de Privacidade, na seção "Como entrar em
          contato conosco".
        </p>

        <h2 style={styles.titleAndSubtitle}>6. Direitos do usuário</h2>
        <p style={styles.paragraphContainer}>
          O usuário do site possui os seguintes direitos, conferidos pela Lei de
          Proteção de Dados Pessoais:
        </p>
        <ul style={styles.listStyle}>
          <li>Confirmação da existência de tratamento;</li>
          <li>Acesso aos dados;</li>
          <li>Correção de dados incompletos, inexatos ou desatualizados;</li>
          <li>
            Anonimização, bloqueio ou eliminação de dados desnecessários,
            excessivos ou tratados em desconformidade com o disposto na lei;
          </li>
          <li>
            Portabilidade dos dados a outro fornecedor de serviço ou produto,
            mediante requisição expressa, de acordo com a regulamentação da
            autoridade nacional, observados os segredos comercial e industrial;
          </li>
          <li>
            Eliminação dos dados pessoais tratados com o consentimento do
            titular, exceto nos casos previstos em lei;
          </li>
          <li>
            Informação das entidades públicas e privadas com as quais o
            controlador realizou uso compartilhado de dados;
          </li>
          <li>
            Informação sobre a possibilidade de não fornecer consentimento e
            sobre as consequências da negativa;
          </li>
          <li>Revogação do consentimento.</li>
        </ul>

        <p style={styles.paragraphContainer}>
          É importante destacar que, nos termos da LGPD, não existe um direito
          de eliminação de dados tratados com fundamento em bases legais
          distintas do consentimento, a menos que os dados sejam desnecessários,
          excessivos ou tratados em desconformidade com o previsto na lei.
        </p>

        <div style={styles.subtitleTextContainer}>
          <h2 style={styles.titleAndSubtitle}>6.1</h2>
          <p>Como o titular pode exercer seus direitos</p>
        </div>
        <p style={styles.paragraphContainer}>
          Os titulares de dados pessoais tratados por nós poderão exercer seus
          direitos por meio do formulário disponibilizado no seguinte caminho:{' '}
          <a href="https://dattasign.com.br/" target="_blank">
            https://dattasign.com.br/
          </a>
          . Alternativamente, se desejar, o titular poderá enviar um e-mail ou
          uma correspondência ao nosso Encarregado de Proteção de Dados
          Pessoais. As informações necessárias para isso estão na seção "Como
          entrar em contato conosco" desta Política de Privacidade.
        </p>
        <p style={styles.paragraphContainer}>
          Os titulares de dados pessoais tratados por nós poderão exercer seus
          direitos a partir do envio de mensagem ao nosso Encarregado de
          Proteção de Dados Pessoais, seja por e-mail ou por correspondência. As
          informações necessárias para isso estão na seção "Como entrar em
          contato conosco" desta Política de Privacidade.
        </p>
        <p style={styles.paragraphContainer}>
          Para garantir que o usuário que pretende exercer seus direitos é, de
          fato, o titular dos dados pessoais objeto da requisição, poderemos
          solicitar documentos ou outras informações que possam auxiliar em sua
          correta identificaçãoo, a fim de resguardar nossos direitos e os
          direitos de terceiros. Isto somente será feito, porém, se for
          absolutamente necessário, e o requerente receberá todas as informações
          relacionadas.
        </p>

        <h2 style={styles.titleAndSubtitle}>
          7. Medidas de segurança no tratamento de dados pessoais
        </h2>
        <p style={styles.paragraphContainer}>
          Empregamos medidas técnicas e organizativas aptas a proteger os dados
          pessoais de acessos não autorizados e de situações de destruição,
          perda, extravio ou alteração desses dados.
        </p>
        <p style={styles.paragraphContainer}>
          As medidas que utilizamos levam em consideração a natureza dos dados,
          o contexto e a finalidade do tratamento, os riscos que uma eventual
          violação gerariam para os direitos e liberdades do usuário, e os
          padrões atualmente empregados no mercado por empresas semelhantes à
          nossa.
        </p>
        <p style={styles.paragraphContainer}>
          Entre as medidas de segurança adotadas por nós, destacamos as
          seguintes:
        </p>
        <ul style={styles.listStyle}>
          <li>Critérios fortes para definição de senhas;</li>
          <li>
            Implementação de autenticação duplo-fator para todos os usuários;
          </li>
          <li>Armazenamento de senhas utilizando hashes criptográficos;</li>
          <li>Restrições de acessos a banco de dados e URLs;</li>
          <li>Restrição de acesso baseadas em perfis de usuário;</li>
          <li>Monitoramento de acessos físicos a servidores.</li>
        </ul>
        <p style={styles.paragraphContainer}>
          Ainda que adote tudo o que está ao seu alcance para evitar incidentes
          de segurança, é possível que ocorra algum problema motivado
          exclusivamente por um terceiro - como em caso de ataques de hackers ou
          crackers ou, ainda, em caso de culpa exclusiva do usuário, que ocorre,
          por exemplo, quando ele mesmo transfere seus dados a terceiro. Assim,
          embora sejamos, em geral, responsáveis pelos dados pessoais que
          tratamos, nos eximimos de responsabilidade caso ocorra uma situação
          excepcional como essas, sobre as quais não temos nenhum tipo de
          controle.
        </p>
        <p style={styles.paragraphContainer}>
          De qualquer forma, caso ocorra qualquer tipo de incidente de segurança
          que possa gerar risco ou dano relevante para qualquer de nossos
          usuários, comunicaremos os afetados e a Autoridade Nacional de
          Proteção de Dados acerca do ocorrido, em conformidade com o disposto
          na Lei Geral de Proteção de Dados.
        </p>

        <h2 style={styles.titleAndSubtitle}>
          8. Reclamação a uma autoridade de controle
        </h2>
        <p style={styles.paragraphContainer}>
          Sem prejuízo de qualquer outra via de recurso administrativo ou
          judicial, os titulares de dados pessoais que se sentirem, de qualquer
          forma, lesados em seus direitos, podem apresentar reclamação à
          Autoridade Nacional de Proteção de Dados.
        </p>

        <h2 style={styles.titleAndSubtitle}>9. Alterações nesta política</h2>
        <p style={styles.paragraphContainer}>
          A presente versão desta Política de Privacidade foi atualizada pela
          última vez em: 19/04/2022.
        </p>
        <p style={styles.paragraphContainer}>
          Nos reservamos o direito de modificar, a qualquer momento, as
          presentes normas, especialmente para adaptá-las às eventuais
          alterações feitas em nosso site, seja pela disponibilização de novas
          funcionalidades, seja pela supressão ou modificação daquelas já
          existentes.
        </p>
        <p style={styles.paragraphContainer}>
          Sempre que houver uma modifição, nossos usuários serão notificados
          acerca da mudança.
        </p>

        <h2 style={styles.titleAndSubtitle}>
          10. Como entrar em contato conosco
        </h2>
        <p style={styles.paragraphContainer}>
          Para esclarecer quaisquer dúvidas sobre esta Política de Privacidade
          ou sobre os dados pessoais que tratamos, entre em contato com nosso
          Encarregado de Proteção de Dados Pessoais, por algum dos canais
          mencionados abaixo:
        </p>

        <div style={styles.divider} />
        <div style={{ padding: '1rem 0' }}>
          <div style={styles.contactContainer}>
            <p style={{ fontWeight: 700, fontSize: '1rem' }}>E-mail:</p>
            <p style={{ fontWeight: 500, fontSize: '1rem' }}>
              apoio@conlicitacao.com.br
            </p>
          </div>
          <div style={styles.contactContainer}>
            <p style={{ fontWeight: 700, fontSize: '1rem' }}>E-Telefone:</p>
            <p style={{ fontWeight: 500, fontSize: '1rem' }}>+5511387838666</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const ConlicitacaoPrivacyPolicy = () => {
  const { userInfo } = useAuthContext();
  const { whiteLabel } = useWhiteLabel();

  const role = userInfo ? userInfo.user.roles[0].nome : '';

  return (
    <Box style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <PageMainContentBox
        title="Política de Privacidade do Dattasign"
        page="politica_de_privacidade"
        overflow="auto"
      >
        {roleBasedRender(
          role,
          whiteLabel === 'conlicitacao' ? <PrivacyPolicy /> : <DeniedAccess />,
          <DeniedAccess />,
          <DeniedAccess />,
          null,
        )}
      </PageMainContentBox>
    </Box>
  );
};

export default ConlicitacaoPrivacyPolicy;
