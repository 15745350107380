/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';
import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';

import ModalCreateUserInCostCenter from './ModalCreateUserInCostCenter';

import useAuthContext from '../../hooks/useAuthContext';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: '#fafafa',
    borderRadius: '10px',

    div: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '1rem .9rem 0',

      h1: {
        fontSize: '1.25rem',
        color: COLORS.mediumGray5,
      },
    },
  } as SxProps<Theme>,

  table: {
    width: '100%',
    th: {
      fontWeight: 700,
      color: COLORS.mediumGray5,
      fontSize: '1rem',
    },

    td: {
      color: COLORS.mediumGray5,
      fontSize: '1rem',
      border: 0,
    },
  },
};

interface UserInCostCenter {
  id: number;
  nome: string;
  email: string;
  status: string;
  created_at: string;
  updated_at: string;
}

export default function UsersInCostCenter() {
  const [usersInCostCenters, setUsersInCostCenters] = useState(
    [] as UserInCostCenter[],
  );

  const { costCenter, userInfo } = useAuthContext();

  useEffect(() => {
    api
      .get(`center_users/${costCenter?.id}`)
      .then(response => {
        setUsersInCostCenters(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <Box sx={styles.container}>
      <div>
        <h1>Usuários</h1>
        {costCenter?.proprietario.id === userInfo?.user.id ? (
          <ModalCreateUserInCostCenter
            setUsersInCostCenters={setUsersInCostCenters}
          />
        ) : (
          <div />
        )}
      </div>

      <TableContainer sx={{ width: '98%' }}>
        <Table sx={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>E-mail</TableCell>
              <TableCell>Criado em</TableCell>
              <TableCell>Status do cadastro</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {usersInCostCenters.length ? (
              usersInCostCenters.map((user, index) => (
                <TableRow key={user.id + index}>
                  <TableCell>{user.nome}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    {moment(user.created_at).format('L LT')}
                  </TableCell>
                  {user.status === 'registered' && (
                    <TableCell>completo</TableCell>
                  )}
                  {user.status === 'not_registered' && (
                    <TableCell>aguardando</TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell />
                <TableCell align="center">
                  <CircularProgress color="primary" />
                </TableCell>
                <TableCell />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
