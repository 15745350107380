import { BrowserRouter as Router } from 'react-router-dom';
import moment from 'moment';

import 'moment/locale/pt-br';

import { AuthProvider } from './contexts/AuthContext';
import { CostCenterProvider } from './contexts/CostCenterContext';
import { DialogProvider } from './contexts/DialogContext';
import { DocumentProvider } from './contexts/DocumentContext';
import { SubscriptionProvider } from './contexts/SubscriptionContext';
import { WhiteLabelProvider } from './contexts/WhiteLabelContext';

import MyRoutes from './routes';

import MyThemeProvider from './components/MyThemeProvider';

moment.locale('pt-br');

const App = () => {
  return (
    <Router>
      <WhiteLabelProvider>
        <MyThemeProvider>
          <DialogProvider>
            <AuthProvider>
              <CostCenterProvider>
                <SubscriptionProvider>
                  <DocumentProvider>
                    <main className="App">
                      <MyRoutes />
                    </main>
                  </DocumentProvider>
                </SubscriptionProvider>
              </CostCenterProvider>
            </AuthProvider>
          </DialogProvider>
        </MyThemeProvider>
      </WhiteLabelProvider>
    </Router>
  );
};

export default App;
