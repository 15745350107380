/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@mui/styles/styled';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import CheckIcon from '@mui/icons-material/Check';

import ContentTitle from '../../components/ContentTitle';
import ContentSubTitle from '../../components/ContentSubTitle';
import ActionButton from '../../components/ActionButton';
import SubscriptionPlan from '../../components/SubscriptionPlan';

import useDialogContext from '../../hooks/useDialogContext';
import useAuthContext from '../../hooks/useAuthContext';

import { Plan } from '../../types/Plan';

import api from '../../services/api';

import COLORS from '../../utils/colors';

import Plan1Image from '../../components/Images/Plan1Image';
import Plan2Image from '../../components/Images/Plan2Image';
import Plan3Image from '../../components/Images/Plan3Image';

const CardsGrid = styled(Grid)({
  width: '100%',
  height: '70%',
  margin: '1rem auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const imagesPlan = [<Plan1Image />, <Plan2Image />, <Plan3Image />];

export default function ChooseSubscriptionPlan() {
  const navigate = useNavigate();
  const { showDialog } = useDialogContext();
  const { costCenter } = useAuthContext();

  const [plans, setPlans] = useState([] as Plan[]);

  const [selectedPlan, setSelectedPlan] = useState(
    JSON.parse(localStorage.getItem('selected-plan') || '{}') as Plan,
  );

  const [, setHasSubmittedPlan] = useState(false);

  const choosePlan = (plan: Plan) => {
    setSelectedPlan(plan);
    localStorage.setItem('selected-plan', JSON.stringify(plan));
  };

  const advanceStep = () => {
    setHasSubmittedPlan(true);
    navigate('endereco', { state: selectedPlan });
  };

  useEffect(() => {
    api
      .get(`/plans/${costCenter?.id}`)
      .then(response => {
        setPlans(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <ContentTitle text="Você não possui nenhum plano ativo no momento" />
      <ContentSubTitle text="Escolha o plano que mais se encaixa com as suas necessidades" />
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '77%',
        }}
      >
        <CardsGrid
          sx={{
            backgroundColor: COLORS.background,
            borderRadius: '10px',
            height: '80vh',
            width: '80%',
          }}
        >
          {plans.length ? (
            plans.map((plan, index) => (
              <SubscriptionPlan
                key={plan.id}
                title={plan.nome}
                ImageComponent={imagesPlan[index]}
                price={plan.valor}
                selectedPlan={selectedPlan}
                action={() => choosePlan(plan)}
              >
                <CheckIcon
                  sx={{
                    fontSize: '16px',
                    display: 'flex',
                    color:
                      selectedPlan?.nome === plan.nome
                        ? 'secondary.main'
                        : COLORS.mediumGray2,
                  }}
                />

                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight:
                      selectedPlan?.nome === plan.nome ? 'bold' : null,
                    color:
                      selectedPlan?.nome === plan.nome
                        ? 'secondary.main'
                        : COLORS.mediumGray2,
                    width: '65%',
                    textAlign: 'center',
                  }}
                >
                  {plan.descricao}
                </Typography>
              </SubscriptionPlan>
            ))
          ) : (
            <Box sx={{ width: '50%' }}>
              <LinearProgress />
            </Box>
          )}
        </CardsGrid>

        <Box
          sx={{
            width: '25rem !important',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '1rem',
          }}
        >
          <ActionButton
            type="button"
            text="Avançar"
            action={
              Object.keys(selectedPlan).length
                ? advanceStep
                : () => {
                    showDialog(
                      'choosePlan',
                      'Escolha um dos planos ofertados para poder continuar.',
                    );
                  }
            }
          />
        </Box>
      </Grid>
    </>
  );
}
