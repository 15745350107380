/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import SvgIcon from '@mui/material/SvgIcon';
import { Theme } from '@mui/material';

import useDialogContext from '../hooks/useDialogContext';

import api from '../services/api';

import fortifyHelpers from '../utils/fortifyHelpers';
import COLORS from '../utils/colors';

import { ReactComponent as Sign } from '../assets/images/sign.svg';

const useStyles = makeStyles((theme: Theme) => ({
  buttonSign: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${COLORS.white} !important`,
    fontSize: '0.875rem',
    height: '2.25rem',
    width: '10rem',
    padding: 0,
    fontWeight: 700,
    textTransform: 'none',

    '& .MuiSvgIcon-root ': {
      fontSize: '1.3rem',
    },

    '&:hover': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      transition: '0.3s',
    },
  },
}));

interface Props {
  fileId?: string;
  token?: string;
  disabled: boolean;
  updateDocument(): void;
  setIsSigning(newValue: boolean): void;
  isLogged: boolean;
}

const ModalFortify: React.FC<Props> = ({
  fileId,
  token,
  disabled,
  updateDocument,
  setIsSigning,
  isLogged,
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const fortifyRef = useRef(null);
  const navigate = useNavigate();

  const [isRendered, setIsRendered] = useState(false);

  const { showDialog } = useDialogContext();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const signWithCertificate = async (
    provider: any,
    certificatePem: any,
    privateKey: any,
  ) => {
    try {
      // action start
      const responseStart = await api.post(
        isLogged
          ? 'documento/assinar/certificado_digital'
          : 'documento/assinar/inicio_fortify',
        {
          [isLogged ? 'documentId' : 'token']: isLogged ? fileId : token,
          certificate: {
            certPem: certificatePem,
          },
        },
      );
      const {
        dataToSign,
        module,
        documentTemp,
        name_certificate,
        extra_certificate_information,
        valid_until,
      } = responseStart.data;

      // action complete
      const message = fortifyHelpers.fromHex(dataToSign);
      const alg = {
        name: privateKey.algorithm.name,
        hash: 'SHA-256',
      };
      const signature = fortifyHelpers.toHex(
        await provider.subtle.sign(alg, privateKey, message),
      );

      await api.post(
        isLogged
          ? 'documento/assinar/finalizar_certificado_digital'
          : 'documento/assinar/fim_fortify',

        {
          ...(isLogged ? { documentId: fileId } : { token }),
          certificate: {
            signature,
            certPem: certificatePem,
            name: name_certificate,
            extra_certificate_information,
            valid_until,
          },
          module,
          documentTemp,
        },
        {
          responseType: 'arraybuffer',
        },
      );

      (isLogged
        ? () => {
            showDialog(
              'signedDocument',
              'O documento foi assinado com sucesso!',
            );
          }
        : () => {
            navigate(`/sign/${token}/success`);
          })();

      updateDocument();
    } catch (error) {
      showDialog(
        'default',
        'Erro ao tentar assinar o documento. Por favor, tente novamente mais tarde!',
      );
      console.log(error);
    }
  };

  const handleFortify = (fortify: HTMLElement | null) => {
    if (!fortify) {
      return;
    }

    // fortifyapp.com/docs/webcomponents/fortify-certificates/readme#properties
    const el = document.createElement('peculiar-fortify-certificates') as any;
    el.debug = true;
    el.filters = {};
    el.language = 'pt';

    el.addEventListener('selectionCancel', handleClose);
    el.addEventListener('selectionSuccess', async (event: any) => {
      setIsSigning(true);
      try {
        const provider = await event.detail.socketProvider.getCrypto(
          event.detail.providerId,
        );

        const certificate = await provider.certStorage.getItem(
          event.detail.certificateId,
        );

        const certificatePem = await provider.certStorage.exportCert(
          'pem',
          certificate,
        );

        const privateKey = await provider.keyStorage.getItem(
          event.detail.privateKeyId,
        );

        handleClose();

        await signWithCertificate(provider, certificatePem, privateKey);
      } catch (error) {
        console.info(error);
      }

      setIsSigning(false);
    });
    fortify.appendChild(el);
    setIsRendered(true);
  };

  useEffect(() => {
    if (open && !isRendered) {
      handleFortify(fortifyRef.current);
    }
  }, [open]);

  return (
    <>
      <Tooltip title="Assinar documento">
        <Button
          className={classes.buttonSign}
          onClick={handleOpen}
          type="button"
          endIcon={
            !disabled && (
              <SvgIcon component={Sign} sx={{ fontSize: '1.2rem' }} />
            )
          }
          disabled={disabled}
        >
          Assinar
        </Button>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} keepMounted>
        <div ref={fortifyRef} />
      </Dialog>
    </>
  );
};

export default ModalFortify;
