const roleBasedRender = (
  role: string,
  caseUser: JSX.Element | null,
  caseOperator: JSX.Element | null,
  caseAdmin: JSX.Element | null,
  caseDefault: JSX.Element | null,
) => {
  switch (role) {
    case 'user':
      return caseUser;

    case 'operador':
      return caseOperator;

    case 'admin':
      return caseAdmin;

    default:
      return caseDefault;
  }
};

export default roleBasedRender;
