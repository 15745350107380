/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useState } from 'react';

import MyDialog from '../components/MyDialog';

import { DialogType } from '../types/DialogType';

interface ContextProps {
  showDialog(dialogType: DialogType, message: string): void;
}

type Props = {
  children: React.ReactNode;
};

const DialogContext = createContext<ContextProps>({} as ContextProps);

function DialogProvider({ children }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState<DialogType>('default');
  const [messageDialog, setMessageDialog] = useState('');

  const handleClose = () => {
    setIsOpen(false);
  };

  const showDialog = (dialogType: DialogType, message: string) => {
    setType(dialogType);
    setIsOpen(true);
    setMessageDialog(message);
  };

  return (
    <DialogContext.Provider value={{ showDialog }}>
      <MyDialog
        isOpen={isOpen}
        handleClose={handleClose}
        type={type}
        message={messageDialog}
      />
      {children}
    </DialogContext.Provider>
  );
}

export { DialogContext, DialogProvider };
