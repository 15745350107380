import axios from 'axios';
import { OriginToken } from '../types/OriginToken';

const whiteLabel: OriginToken = localStorage.getItem(
  '@dattasign-whiteLabel',
) as OriginToken;

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(config => {
  const localConfig = config;

  const token = localStorage.getItem('user-token');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  localConfig.headers!.Authorization = token
    ? `Bearer ${JSON.parse(token)}`
    : '';

  return localConfig;
});

api.interceptors.response.use(
  async response => {
    return response;
  },

  async error => {
    if (error.response.data.message === 'Unauthenticated.') {
      localStorage.clear();
      localStorage.setItem('@dattasign:session', JSON.stringify(false));

      if (whiteLabel === 'conlicitacao') {
        window.location.href =
          'https://consulteonline.conlicitacao.com.br/digital_signature_documents';
        return;
      }
      window.location.reload();
    }
    throw error;
  },
);

export default api;
