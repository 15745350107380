/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';

import { Theme } from '@mui/material';
import ModalMoveFoldersAndFiles from './ModalMoveFoldersAndFiles';
import ModalDeleteFolderOrDocument from './ModalDeleteFolderOrDocument';

import useCostCenterContext from '../../hooks/useCostCenterContext';
import useOutsideClick from '../../hooks/useOutsideClick';

import { MyDocument } from '../../types/Document';

import toAbbreviateString from '../../utils/toAbbreviateString';
import COLORS from '../../utils/colors';

import myDocumentImg from '../../assets/images/documento_imagem.jpg';

interface Props {
  document: MyDocument;
  selectedDocuments: number[];
  action?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  fileContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    height: '100%',
    minWidth: '164px',
    maxWidth: '164px',
    minHeight: '200px',
    maxHeight: '214px',
    padding: '1rem',
    borderRadius: '10px',
    gap: '14px',
    cursor: 'pointer',

    '& img': {
      width: '140px',
      height: '100%',
      minHeight: '152px',
      maxHeight: '152px',
    },

    '&:hover': {
      backgroundColor: COLORS.background,
    },
  },

  menuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    gap: '.5rem',
    transition: 'all .3s ease-out',
    '&.MuiMenuItem-root': {
      '&:selected': {
        backgroundColor: `${theme.palette.secondary.main} !important`,
        '& .MuiSvgIcon-root': {
          color: COLORS.white,
        },
      },
      '&:active': {
        backgroundColor: theme.palette.secondary.light,
        color: COLORS.white,
        '& .MuiSvgIcon-root': {
          color: COLORS.white,
        },
      },
    },
  },
}));

const FolderDocument = ({ document, selectedDocuments, action }: Props) => {
  const navigate = useNavigate();

  const { setSelectedDocuments, setIsFromFolderPage } = useCostCenterContext();

  const handleClickOutside = () => {
    setSelectedDocuments([]);
  };

  const docRef = useOutsideClick(handleClickOutside);

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [isDocument] = useState(true);

  const handleOpenMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    return false;
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [folderDocument, setFolderDocument] = useState(document);

  const [, drag, preview] = useDrag(() => ({
    type: 'document',
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  drag(docRef);

  useEffect(() => {
    localStorage.setItem('fromFolderPage', 'false');
    setIsFromFolderPage(true);
    preview(getEmptyImage(), {
      captureDraggingState: true,
    });
  }, []);

  const handleOpenDocument = () => {
    localStorage.setItem('fromFolderPage', 'true');
    setIsFromFolderPage(true);
    navigate(`/documentos/${document.id}`);
    handleCloseMenu();
  };

  return (
    <Tooltip key={document.id} title={document.documento} placement="top">
      <Box
        ref={docRef}
        onContextMenu={handleOpenMenu}
        className={classes.fileContainer}
        role="button"
        aria-label="Abrir arquivo"
        onMouseDown={action}
        sx={
          selectedDocuments?.find(d => d === document.id)
            ? {
                border: theme => `2px solid ${theme.palette.primary.main}`,
                color: 'primary.main',
              }
            : {
                border: `1px solid rgba(0, 0, 0, 0.12)`,
              }
        }
      >
        <img
          src={document.thumb ? document.thumb : myDocumentImg}
          alt="Imagem do documento"
          style={{ userSelect: 'none' }}
        />
        <Typography style={{ userSelect: 'none' }}>
          {toAbbreviateString(document.documento, 20)}
        </Typography>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={(e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            e.preventDefault();
            handleCloseMenu();
          }}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleOpenDocument} className={classes.menuItem}>
            <FileOpenOutlinedIcon sx={{ color: COLORS.mediumGray5 }} />
            Ver documento
          </MenuItem>
          <ModalMoveFoldersAndFiles
            folderDocument={folderDocument}
            setFolderDocument={setFolderDocument}
            isDocument={isDocument}
          />
          <ModalDeleteFolderOrDocument />
        </Menu>
      </Box>
    </Tooltip>
  );
};

export default FolderDocument;
