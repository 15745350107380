/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import React from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from '@mui/styles/styled';

import { Plan } from '../types/Plan';

import COLORS from '../utils/colors';
import formatPrice from '../utils/formatPrice';

const PlanCard = styled(Card)({
  width: '18rem',
  height: '24rem',
  border: `1px solid ${COLORS.lightGray3}`,
  borderRadius: '10px !important',
  margin: '0 1rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  padding: '2rem',
  cursor: 'pointer',
  transition: 'all .5s ease',
  '&:hover': {
    transform: 'scale(1.02)',
  },
});

const CardTitle = styled(Typography)({
  fontSize: '18px',
  color: COLORS.mediumGray2,
});

type Props = {
  title: string;
  ImageComponent: JSX.Element;
  price: string;
  selectedPlan?: Plan;
  children: React.ReactNode;
  action?: () => void;
};

export default function SubscriptionPlan({
  ImageComponent,
  title,
  price,
  children,
  selectedPlan,
  action,
}: Props) {
  return (
    <PlanCard
      elevation={0}
      onClick={action}
      sx={{
        border: theme =>
          selectedPlan?.nome === title
            ? `2px solid ${theme.palette.secondary.main}`
            : `2px solid ${COLORS.mediumGray2}`,
        backgroundColor: theme =>
          selectedPlan?.nome === title ? theme.palette.secondary.light : 'none',
        transform: selectedPlan?.nome === title ? 'scale(1.02)' : 'none',
      }}
    >
      {ImageComponent}

      <CardTitle
        sx={{
          fontWeight: selectedPlan?.nome === title ? 'bold' : null,
          color: theme =>
            selectedPlan?.nome === title ? theme.palette.secondary.main : '',
        }}
      >
        {title}
      </CardTitle>

      <CardTitle
        sx={{
          fontWeight: selectedPlan?.nome === title ? 'bold' : null,
          color: theme =>
            selectedPlan?.nome === title ? theme.palette.secondary.main : '',
        }}
      >
        {`R$ ${formatPrice(price)} / mês`}
      </CardTitle>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {children}
      </Box>
    </PlanCard>
  );
}
