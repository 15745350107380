/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import api from '../../services/api';

import COLORS from '../../utils/colors';
import useDialogContext from '../../hooks/useDialogContext';

const styles = {
  buttonAddPlanInCostCenter: {
    backgroundColor: COLORS.green,
    padding: '0.2rem 0',
    borderRadius: '4px',
    minWidth: '48px',

    '.MuiSvgIcon-root ': {
      fontSize: '1.6rem',
      color: COLORS.white,
    },

    ':hover': {
      backgroundColor: COLORS.hoverGreen,
      transition: '0.3s',
    },
  } as SxProps<Theme>,

  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '30vw',

    color: COLORS.black,
    fontSize: '1.25rem',
    fontWeight: 500,

    '.MuiSvgIcon-root ': {
      fontSize: '1.75rem',
      cursor: 'pointer',

      '&:hover': {
        color: 'primary.main',
        transition: '0.3s',
      },
    },
  } as SxProps<Theme>,

  content: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    paddingLeft: '10%',
  } as SxProps<Theme>,

  groupContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1.25rem',

    h2: {
      fontSize: '1rem',
      fontWeight: 500,
    },

    div: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      columnGap: '2rem',

      '.MuiButtonBase-root': {
        ':hover': { backgroundColor: 'transparent' },
        ':active': { backgroundColor: 'transparent' },
      },

      '.MuiInputBase-root': {
        fontSize: '1.125rem',
        borderRadius: '10px',
        backgroundColor: 'transparent',
        opacity: '.8',
        borderColor: 'primary.main',
        color: COLORS.mediumGray5,
        alignSelf: 'center',

        textAlign: 'center',
        width: '5rem',
        '& .MuiOutlinedInput-input': {
          padding: '4px 0 5px',
          textAlign: 'center',
        },
      },

      '.MuiButton-root': {
        backgroundColor: 'primary.main',
        borderRadius: '8px',
        color: COLORS.white,
        fontWeight: 700,
        marginTop: '.5rem',

        ':hover': {
          backgroundColor: 'primary.light',
        },
      },
    },
  } as SxProps<Theme>,

  buttonSubmit: {
    textTransform: 'none',
    padding: 0,
    color: COLORS.white,
    backgroundColor: theme => `${theme.palette.primary.main} !important`,
    height: '2.25rem',
    fontSize: '.9rem',
    fontWeight: 500,
    borderRadius: '4px',
    alignSelf: 'center',
    width: '15rem',

    margin: '4rem 0 1rem 0',

    '&:hover': {
      backgroundColor: 'primary.light',
      opacity: '0.9',
      transition: '0.3s',
      color: COLORS.white,
    },
  } as SxProps<Theme>,
};

interface Params {
  id: number;
  setUpdateCostCenters: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalAddFreePlanInCostCenter: React.FC<Params> = ({
  id,
  setUpdateCostCenters,
}) => {
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [numberOfDocuments, setNumberOfDocuments] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');

  const { showDialog } = useDialogContext();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setErrorMessage('');
      setNumberOfDocuments(1);
    }, 300);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      if (numberOfDocuments < 2) {
        throw Error('O número de documentos tem de ser maior que 1');
      }

      const response = await api.post('/subscription', {
        costCenter: id,
        quantity: numberOfDocuments,
      });

      setUpdateCostCenters(prevValue => !prevValue);
      showDialog('success', response.data.message);

      handleClose();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setErrorMessage(
        error.response ? error.response.data.message : error.message,
      );
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <Tooltip title="Adicionar Plano Cortesia no Centro de Custo">
        <Button onClick={handleOpen} sx={styles.buttonAddPlanInCostCenter}>
          <AttachMoneyIcon />
        </Button>
      </Tooltip>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={styles.titleContainer}>
          Adicionar plano cortesia no centro de custo
          <CloseIcon onClick={handleClose} />
        </DialogTitle>

        <DialogContent sx={styles.content}>
          <Box sx={styles.groupContainer}>
            <h2>Quantidade de documentos de cortesia</h2>

            <div>
              <IconButton
                disabled={numberOfDocuments <= 1}
                onClick={() => setNumberOfDocuments(prevValue => prevValue - 1)}
              >
                <IndeterminateCheckBoxIcon
                  sx={{
                    color:
                      numberOfDocuments <= 1 ? 'primary.light' : 'primary.main',
                    fontSize: '2.5rem',
                  }}
                />
              </IconButton>

              <TextField
                autoFocus
                type="number"
                variant="outlined"
                color="warning"
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  min: 1,
                  step: '1',
                }}
                value={numberOfDocuments}
                onChange={e => setNumberOfDocuments(Number(e.target.value))}
                error={Boolean(errorMessage)}
              />

              <IconButton
                disabled={Number(numberOfDocuments) >= 100}
                onClick={() => setNumberOfDocuments(prevValue => prevValue + 1)}
              >
                <AddBoxIcon
                  sx={{
                    color:
                      numberOfDocuments >= 100
                        ? 'primary.light'
                        : 'primary.main',
                    fontSize: '2.5rem',
                  }}
                />
              </IconButton>
            </div>

            {errorMessage && (
              <div>
                <FormHelperText error>{errorMessage}</FormHelperText>
              </div>
            )}
          </Box>

          <Box sx={styles.groupContainer}>
            <h2>Escolha rápida</h2>

            <div>
              {[3, 5, 10, 25].map(amount => (
                <Button
                  key={amount}
                  sx={{
                    backgroundColor: theme =>
                      `${theme.palette.primary.main} !important`,
                    '&:hover': {
                      backgroundColor: theme =>
                        `${theme.palette.primary.light} !important`,
                    },
                  }}
                  onClick={() => setNumberOfDocuments(amount)}
                  endIcon={<FileCopyIcon />}
                >
                  {amount}
                </Button>
              ))}
            </div>
          </Box>

          <Button
            variant="contained"
            disableElevation
            sx={styles.buttonSubmit}
            endIcon={!isSubmitting ? <AddIcon fontSize="medium" /> : null}
            onClick={handleSubmit}
          >
            {isSubmitting ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Adicionar Plano Cortesia'
            )}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalAddFreePlanInCostCenter;
