/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import HomeIcon from '@mui/icons-material/Home';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import RoleBasedMainBox from '../../../components/RoleBasedMainBox';
import ContentTitle from '../../../components/ContentTitle';
import ContentSubTitle from '../../../components/ContentSubTitle';
import OptionsButton from './OptionsButton';
import MailSentImage from '../../../components/Images/MailSentImage';

import useDocumentContext from '../../../hooks/useDocumentContext';
import useSubscriptionContext from '../../../hooks/useSubscriptionContext';

import COLORS from '../../../utils/colors';

interface Location {
  documentId: string;
}

export default function UserDocumentsSuccess() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { documentId } = state as Location;

  const { handleFileInput, loadingDocument } = useDocumentContext();
  const { getSubscription } = useSubscriptionContext();

  const { getInputProps } = useDropzone({
    accept: ['.pdf'],
    maxFiles: 1,
  });

  const openFileDialog = () => {
    const fileUploadDom = document.getElementById('fileInputInSuccessPage');
    fileUploadDom?.click();
  };

  useEffect(() => {
    getSubscription();
  }, []);

  return (
    <RoleBasedMainBox>
      {loadingDocument ? (
        <Grid
          sx={{
            width: '100%',
            height: '70vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 auto',
          }}
        >
          <CircularProgress
            color="primary"
            sx={{ margin: '15rem auto 0 auto' }}
          />
        </Grid>
      ) : (
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '40px',
            width: '100%',
          }}
        >
          <Box
            sx={{
              width: '100%',
              minHeight: '65vh',
              maxHeight: '82vh',
              height: '82vh',
              margin: '1rem',
              borderRadius: '10px',
              gap: '20px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
                margin: '2rem auto',
                width: '80%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '10px',
                  width: '100%',
                }}
              >
                <ContentTitle
                  text="Seu documento foi enviado!"
                  justify="center"
                  align="center"
                />
                <Tooltip title="Fechar">
                  <CloseIcon
                    sx={{ color: 'primary.main', cursor: 'pointer' }}
                    fontSize="large"
                    onClick={() => navigate('/documentos')}
                  />
                </Tooltip>
              </Box>

              <MailSentImage
                style={{ width: '10rem', height: '10rem', marginTop: '1rem' }}
              />

              <ContentSubTitle
                text="Você será avisado quando o documento for assinado pelos signatários."
                fontWeight="bold"
                fontSize=".9rem"
                margin="1rem auto .3rem auto"
                alignSelf="center"
              />

              <ContentSubTitle
                text="Caso seja signatário e quiser assinar agora, basta clicar no botão 'Assinar documento' logo abaixo."
                fontWeight="bold"
                fontSize=".9rem"
                margin=".3rem auto"
                alignSelf="center"
              />

              <ContentSubTitle
                text="Acompanhe o progresso através da página Início ou em Documentos."
                fontWeight="bold"
                fontSize=".9rem"
                margin=".3rem auto .5rem auto"
                alignSelf="center"
              />
            </Box>

            <Stack
              sx={{
                width: '50%',
                gap: '10px',
                display: 'flex',
                alignItems: 'center',
                margin: '2rem auto',
                justifyContent: 'space-between',
                flexDirection: {
                  mobile: 'column',
                  tablet: 'row',
                },
              }}
            >
              <OptionsButton
                icon={
                  <BorderColorIcon
                    sx={{
                      fontSize: '2rem',
                      color: COLORS.mediumGray5,
                      marginBottom: '.4rem',
                    }}
                    fontSize="medium"
                  />
                }
                text="Assinar documento"
                action={() => navigate(`/documentos/${documentId}`)}
              />

              <OptionsButton
                icon={
                  <HomeIcon
                    sx={{
                      fontSize: '2.5rem',
                      color: COLORS.mediumGray5,
                      marginTop: '1.3rem',
                    }}
                    fontSize="medium"
                  />
                }
                text="Ir para Início"
                action={() => navigate('/inicio')}
              />

              <OptionsButton
                icon={
                  <InsertDriveFileIcon
                    sx={{ fontSize: '2.1rem', color: COLORS.mediumGray5 }}
                    fontSize="medium"
                  />
                }
                text="Enviar outro documento"
                action={openFileDialog}
              />

              <input
                id="fileInputInSuccessPage"
                name="file"
                type="file"
                multiple
                style={{ display: 'none' }}
                accept=".pdf"
                {...getInputProps()}
                onChange={e =>
                  handleFileInput(e.target.files ? e.target.files[0] : null)
                }
              />
            </Stack>
          </Box>
        </Grid>
      )}
    </RoleBasedMainBox>
  );
}
