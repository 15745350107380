import React, { useState } from 'react';
import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import DialogTitle from '@mui/material/DialogTitle';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FormHelperText from '@mui/material/FormHelperText';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import ActionButton from '../../components/ActionButton';

import { CostCenter } from '../../types/CostCenter';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const styles = {
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    color: COLORS.black,
    fontSize: '1.5rem',
    fontWeight: 500,

    '.MuiSvgIcon-root ': {
      fontSize: '2rem',
      cursor: 'pointer',

      '&:hover': {
        color: 'primary.main',
        transition: '0.3s',
      },
    },
  } as SxProps<Theme>,

  dialogContent: {
    width: '100%',
    minWidth: '40vw',
    maxWidth: '60rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '2rem',

    '> .MuiBox-root': {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      width: '100%',
      alignItems: 'flex-start',
    },
  } as SxProps<Theme>,

  costCenter: {
    div: {
      display: 'flex',
      width: '100%',
      columnGap: '.5rem',
    },
  },

  tableRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  tableCellText: {
    fontSize: '1rem !important',
  },

  addUser: {
    marginTop: '2rem',

    div: {
      display: 'flex',
      width: '100%',
      columnGap: '.5rem',
    },

    p: {
      fontWeight: 500,
      fontSize: '1.25rem',
    },
  } as SxProps<Theme>,

  addedUsers: {
    p: {
      fontWeight: 500,
      fontSize: '1.125rem',
    },

    '.MuiTableContainer-root': {
      overflow: 'auto',
      maxHeight: '10rem',
    },

    td: {
      border: 0,
      color: COLORS.mediumGray5,
      fontSize: '1.125rem',
    },
  } as SxProps<Theme>,

  buttonAddUser: {
    textTransform: 'none',
    padding: '.5rem 1.5rem',
    color: COLORS.white,
    width: 'fit-content',
    height: '2.5rem',
    fontWeight: 500,
    borderRadius: '4px',
    marginTop: '1rem',
    backgroundColor: 'primary.main',
    fontSize: {
      mobile: '.8rem',
      tablet: '1rem',
    },

    '&:hover': {
      backgroundColor: 'primary.light',
      opacity: '0.9',
      transition: '0.3s',
    },
    '&:disabled': {
      backgroundColor: 'primary.light',
    },
  } as SxProps<Theme>,

  buttonDelete: {
    backgroundColor: COLORS.red,
    padding: '.4rem',
    minWidth: 'min-content',
    borderRadius: '4px',

    '.MuiSvgIcon-root': {
      color: COLORS.white,
      fontSize: '1.4rem',
    },

    ':hover': {
      backgroundColor: COLORS.lightRed,
      transition: '0.3s',
    },
  } as SxProps<Theme>,

  buttonSubmit: {
    textTransform: 'none',
    padding: '.5rem 1.5rem',
    color: COLORS.white,
    width: 'fit-content',
    height: '2.5rem',
    fontWeight: 500,
    borderRadius: '4px',
    marginTop: '1rem',
    marginBottom: '1rem',
    backgroundColor: 'primary.main',
    fontSize: {
      mobile: '.8rem',
      tablet: '1rem',
    },

    '&:hover': {
      backgroundColor: 'primary.light',
      opacity: '0.9',
      transition: '0.3s',
    },
  } as SxProps<Theme>,
};

interface User {
  name: string;
  email: string;
}

interface Params {
  totalCostCenters: number;
  setTotalCostCenters: (totalCostCenters: number) => void;
  setCostCenters: React.Dispatch<React.SetStateAction<CostCenter[]>>;
  rowsPerPage: number;
}

const ModalCreateCostCenter: React.FC<Params> = ({
  totalCostCenters,
  setTotalCostCenters,
  setCostCenters,
  rowsPerPage,
}) => {
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [costCenter, setCostCenter] = useState({
    name: '',
    owner: {
      name: '',
      email: '',
    },
  });

  const [user, setUser] = useState({
    name: '',
    email: '',
  });
  const [users, setUsers] = useState([] as User[]);

  const [error, setError] = useState({
    name: '',
    owner: {
      name: '',
      email: '',
    },
    users: '',
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setCostCenter({
        name: '',
        owner: {
          name: '',
          email: '',
        },
      });

      setUser({
        name: '',
        email: '',
      });

      setUsers([] as User[]);

      setError({
        name: '',
        owner: {
          name: '',
          email: '',
        },
        users: '',
      });
    }, 300);
  };

  const addUser = () => {
    if (!user.name || !user.email) {
      return;
    }

    setError({
      name: '',
      owner: {
        name: '',
        email: '',
      },
      users: '',
    });

    const found = users.find(element => element.email === user.email);
    if (found) {
      setError({
        ...error,
        users: 'Esse e-mail já foi adicionado à lista',
      });
      return;
    }

    setUsers([...users, user]);
    setUser({
      name: '',
      email: '',
    });
  };

  const deleteUser = (email: string) => {
    setUsers(users.filter(element => element.email !== email));
  };

  const verifyFields = () => {
    let hasError = false;
    const auxError = {
      name: '',
      owner: {
        name: '',
        email: '',
      },
      users: '',
    };

    if (!costCenter.name) {
      auxError.name = 'Por favor, informe o nome do centro de custo';
      hasError = true;
    }

    if (!costCenter.owner.name) {
      auxError.owner.name = 'Por favor, informe o nome do proprietário';
      hasError = true;
    }

    if (!costCenter.owner.email) {
      auxError.owner.email = 'Por favor, informe o e-mail do proprietário';
      hasError = true;
    }

    setError(auxError);

    if (hasError) {
      throw new Error('Some field has not been filled.');
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      verifyFields();

      const response = await api.post('/cost_centers', {
        ...costCenter,
        users,
      });

      setTotalCostCenters(totalCostCenters + 1);
      setCostCenters(costCenters => [
        response.data.cost_center,
        ...costCenters.slice(0, rowsPerPage - 1),
      ]);

      handleClose();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.log(err);
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <ActionButton
        mobilewidth="100%"
        tabletwidth="35%"
        laptopwidth="50%"
        desktopwidth="30%"
        largedesktopwidth="18%"
        type="button"
        text="Adicionar centro de custo"
        margin="0 0 .5rem 0"
        self="flex-end"
        action={handleOpen}
        icon={<GroupAddIcon fontSize="medium" sx={{ marginLeft: '.5rem' }} />}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={styles.titleContainer}>
          Adicionar Centro de Custo
          <CloseIcon onClick={handleClose} />
        </DialogTitle>

        <DialogContent sx={styles.dialogContent}>
          <Box sx={styles.costCenter}>
            <TextField
              autoFocus
              label="Nome do centro de custo"
              variant="standard"
              type="text"
              color="warning"
              value={costCenter.name}
              onChange={e =>
                setCostCenter({
                  ...costCenter,
                  name: e.target.value,
                })
              }
              error={Boolean(error.name)}
              helperText={error.name}
            />

            <div>
              <TextField
                label="Nome do proprietário"
                variant="standard"
                color="warning"
                type="text"
                value={costCenter.owner.name}
                onChange={e =>
                  setCostCenter({
                    ...costCenter,
                    owner: {
                      ...costCenter.owner,
                      name: e.target.value,
                    },
                  })
                }
                error={Boolean(error.owner.name)}
                helperText={error.owner.name}
              />

              <TextField
                label="E-mail do proprietário"
                variant="standard"
                color="warning"
                type="text"
                value={costCenter.owner.email}
                onChange={e =>
                  setCostCenter({
                    ...costCenter,
                    owner: {
                      ...costCenter.owner,
                      email: e.target.value,
                    },
                  })
                }
                error={Boolean(error.owner.email)}
                helperText={error.owner.email}
              />
            </div>
          </Box>

          <Box sx={styles.addUser}>
            <p>Adicionar usuário ao Centro de Custo</p>

            <div>
              <TextField
                name="name"
                label="Nome"
                color="warning"
                variant="standard"
                type="text"
                value={user.name}
                onChange={e =>
                  setUser({ ...user, [e.target.name]: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
              />

              <TextField
                name="email"
                label="E-mail"
                color="warning"
                variant="standard"
                type="text"
                value={user.email}
                onChange={e =>
                  setUser({ ...user, [e.target.name]: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
              />
            </div>

            {user.name && user.email && (
              <Button
                variant="contained"
                disableElevation
                disabled={!user.name && !user.email}
                sx={styles.buttonAddUser}
                onClick={addUser}
                endIcon={<AddIcon fontSize="medium" />}
              >
                Adicionar usuário
              </Button>
            )}
          </Box>

          {users && users.length > 0 && (
            <Box sx={styles.addedUsers}>
              <p>Usuários adicionados</p>

              {error.users && (
                <FormHelperText error>{error.users}</FormHelperText>
              )}

              <TableContainer
                sx={{
                  '::-webkit-scrollbar': {
                    width: '10px',
                  },
                  '::-webkit-scrollbar-track': {
                    background: COLORS.lightGray3,
                    borderRadius: '50px',
                  },
                  '::-webkit-scrollbar-thumb': {
                    background: COLORS.mediumGray2,
                    borderRadius: '50px',
                  },
                  '::-webkit-scrollbar-thumb:hover': {
                    background: COLORS.mediumGray3,
                  },
                }}
              >
                <Table>
                  <TableBody>
                    {users.map(row => (
                      <TableRow key={row.email} sx={styles.tableRow}>
                        <TableCell sx={styles.tableCellText}>
                          {row.name}
                        </TableCell>
                        <TableCell sx={styles.tableCellText}>
                          {row.email}
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Remover usuário">
                            <RemoveCircleIcon
                              sx={{
                                color: COLORS.redWarning,
                                fontSize: '1.5rem',
                                cursor: 'pointer',
                                '&:hover': { color: COLORS.lightRed },
                              }}
                              onClick={() => deleteUser(row.email)}
                            />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}

          <Button
            variant="contained"
            disableElevation
            sx={styles.buttonSubmit}
            onClick={handleSubmit}
            endIcon={!isSubmitting ? <AddIcon fontSize="medium" /> : null}
          >
            {isSubmitting ? (
              <CircularProgress size={24} color="warning" />
            ) : (
              'Adicionar Centro de Custo'
            )}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalCreateCostCenter;
