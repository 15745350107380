/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import styled from '@mui/styles/styled';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import AddSingleCreditsModal from '../pages/SubscriptionPlans/AddSingleCreditsModal';

import useAuthContext from '../hooks/useAuthContext';
import useSubscriptionContext from '../hooks/useSubscriptionContext';
import useCostCenterContext from '../hooks/useCostCenterContext';

import COLORS from '../utils/colors';
import toAbbreviateString from '../utils/toAbbreviateString';
import useWhiteLabel from '../hooks/useWhiteLabel';

const CreditsGrid = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '0 0.5rem',
});

const CreditsBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '2fr 1fr',
  alignItems: 'center',

  marginBottom: '.2rem',
  borderRadius: '.3rem',
  width: '9rem',
});

export default function Credits() {
  const { isLogged, userInfo, costCenter } = useAuthContext();
  const { subscription, stateSubscription } = useSubscriptionContext();
  const { userInformation, getUserInformation } = useCostCenterContext();
  const { whiteLabel } = useWhiteLabel();

  const isOwner = userInfo?.user.id === costCenter?.proprietario.id;

  const getCostCenterList = async () => {
    try {
      if (isLogged) {
        getUserInformation();
      }
      return;
    } catch (error: any) {
      console.log(error);
    }
  };

  /* status of the header component */
  const [open] = useState<boolean>(
    JSON.parse(localStorage.getItem('isOpen') || 'true'),
  );

  const [creditsAnchorEl, setCreditsAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const handleOpenCreditsMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (whiteLabel === 'codata' || whiteLabel === 'prevLaw') {
      return;
    }

    setCreditsAnchorEl(event.currentTarget);
    getCostCenterList();
  };

  const handleCreditsMenuClose = () => {
    setCreditsAnchorEl(null);
  };

  return (
    <CreditsGrid
      style={
        open
          ? { marginLeft: '0', padding: '.6rem 0 .5rem .5rem' }
          : {
              padding: '0.6rem 0 0.5rem .5rem',
              height: '100%',
            }
      }
    >
      <CreditsBox
        sx={{
          border: '1px solid',
          borderColor: 'primary.main',
        }}
      >
        <Button
          sx={{
            width: '100%',
            height: '100%',
            cursor:
              whiteLabel !== 'codata' && whiteLabel !== 'prevLaw'
                ? 'pointer !important'
                : 'unset !important',
            padding: '0 .5rem !important',
            color: 'red',
          }}
          variant="text"
          onClick={handleOpenCreditsMenu}
        >
          <Tooltip
            title={
              subscription?.type === 'limited' &&
              whiteLabel !== 'codata' &&
              whiteLabel !== 'prevLaw'
                ? 'Clique para ver os créditos nos demais centros'
                : ''
            }
          >
            {subscription ? (
              <Typography
                sx={{
                  color: COLORS.gray1,
                  justifySelf: 'center',
                  fontSize: '.925rem',
                  fontWeight: '600',
                }}
              >
                {subscription.type === 'unlimited' ? (
                  <span style={{ color: COLORS.green2 }}>Ilimitado</span>
                ) : (
                  <>
                    <span
                      style={{
                        color:
                          subscription.saldo <
                          0.25 * subscription.quantidade_documentos
                            ? COLORS.redWarning
                            : COLORS.green2,
                        fontWeight: '600',
                      }}
                    >
                      {subscription.saldo}
                    </span>
                    {' / '} {subscription.quantidade_documentos}
                  </>
                )}
              </Typography>
            ) : stateSubscription !== 'loading' ? (
              <span
                style={{
                  color: COLORS.black,
                  justifySelf: 'center',
                  fontSize: '.925rem',
                  fontWeight: 600,
                }}
              >
                0 / 0
              </span>
            ) : (
              <CircularProgress
                color="primary"
                size={16}
                sx={{ justifySelf: 'center' }}
              />
            )}
          </Tooltip>
        </Button>

        <Menu
          anchorEl={creditsAnchorEl}
          open={Boolean(creditsAnchorEl)}
          onClose={handleCreditsMenuClose}
          onClick={handleCreditsMenuClose}
          PaperProps={{
            elevation: 0,
            sx: {
              margin: '0 auto',
              alignSelf: 'center',
              minWidth: '18rem',
              overflow: 'visible',
              filter: 'drop-shadow(0px 1px 4px rgba(0,0,0,0.32))',
              mt: 0.7,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
        >
          <MenuItem disabled>
            <Typography
              sx={{
                fontSize: '1rem',
                fontStyle: 'italic',
                cursor: 'default',
              }}
            >
              Centro de custo / Créditos disponíveis
            </Typography>
          </MenuItem>
          {!userInformation ? (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '.5rem 0 1rem',
              }}
            >
              <CircularProgress size={26} color="primary" />
            </Box>
          ) : userInformation && userInformation.centros_custo.length > 1 ? (
            userInformation.centros_custo.map(userCostCenter => (
              <Tooltip key={userCostCenter.id} title={userCostCenter.nome}>
                <MenuItem
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '.5rem',
                    width: '100%',
                    cursor: 'default',
                    '&.MuiMenuItem-root': {
                      '&:hover': {
                        backgroundColor: 'primary.light',
                      },
                      '&:selected': {
                        backgroundColor: 'primary',
                        '& .MuiSvgIcon-root': {
                          color: 'success',
                        },
                      },
                      '&:focus': {
                        backgroundColor: 'primary',
                        color: COLORS.white,
                        '& .MuiSvgIcon-root': {
                          color: 'success',
                        },
                      },
                      '&:active': {
                        backgroundColor: 'primary',
                        color: COLORS.white,
                        '& .MuiSvgIcon-root': {
                          color: 'success',
                        },
                      },
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color:
                        userCostCenter.id === userInformation.id
                          ? 'primary.main'
                          : COLORS.black,
                      flex: 2,
                    }}
                  >
                    {toAbbreviateString(userCostCenter.nome, 26)}
                  </Typography>

                  <Typography
                    sx={{
                      color:
                        userCostCenter.id === userInformation.id
                          ? 'primary.main'
                          : COLORS.black,
                    }}
                  >
                    20
                  </Typography>
                  <Divider sx={{ margin: '0' }} />
                </MenuItem>
              </Tooltip>
            ))
          ) : (
            <Typography
              sx={{
                fontSize: '.925rem',
                fontWeight: '600',
                color: COLORS.mediumGray5,
                textAlign: 'center',
                width: '100%',
                margin: '.5rem 0 1rem',
              }}
            >
              Não há outros centros de custo nesta conta.
            </Typography>
          )}
        </Menu>

        {stateSubscription === 'not_purchased' ||
        stateSubscription === 'loading' ? (
          isOwner ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: isOwner ? 'pointer' : 'default',
                backgroundColor: 'primary.main',
                height: '1.6rem',
              }}
            >
              <FileCopyIcon sx={{ color: COLORS.white }} fontSize="small" />
            </Box>
          ) : (
            <Link to="/planos" />
          )
        ) : (
          isOwner && (
            <Tooltip
              title={
                isOwner &&
                subscription?.type === 'limited' &&
                whiteLabel !== 'prevLaw'
                  ? 'Adicionar créditos'
                  : ''
              }
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: isOwner ? 'pointer' : 'default',
                  backgroundColor: 'primary.main',
                  height: '1.6rem',
                }}
              >
                {subscription?.type === 'limited' &&
                whiteLabel !== 'prevLaw' ? (
                  <AddSingleCreditsModal addCreditsButton={false} />
                ) : (
                  <FileCopyIcon sx={{ color: COLORS.white }} fontSize="small" />
                )}
              </Box>
            </Tooltip>
          )
        )}
      </CreditsBox>

      <span
        style={{
          textTransform: 'none',
          color: COLORS.mediumGray4,
          fontSize: '0.72rem',
        }}
      >
        Créditos disponíveis / Total
      </span>
    </CreditsGrid>
  );
}
