/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import makeStyles from '@mui/styles/makeStyles';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import AddBoxIcon from '@mui/icons-material/AddBox';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import ActionButton from '../../components/ActionButton';
import ContentSubTitle from '../../components/ContentSubTitle';

import useAuthContext from '../../hooks/useAuthContext';
import useDialogContext from '../../hooks/useDialogContext';
import useSubscriptionContext from '../../hooks/useSubscriptionContext';

import api from '../../services/api';

import COLORS from '../../utils/colors';
import formatPrice from '../../utils/formatPrice';

const useStyles = makeStyles({
  dialog: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
  },

  numberInput: {
    fontSize: '2rem',
    width: '4rem',
    borderRadius: '10px',
    backgroundColor: 'transparent',
    opacity: '.8',
    color: COLORS.mediumGray5,
    alignSelf: 'center',
  },

  columnStart: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },

  rowCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },

  rowBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },

  fastAmount: {
    width: '4.5rem',
    height: '2rem',
    borderRadius: '8px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  dialogActions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '1rem 0 1.5rem',
    alignSelf: 'end',
    marginTop: '1rem',
    gap: '.5rem',
  },
});

const fastAmount = [25, 50, 75, 100];
interface FormValues {
  documentAmount: number;
}

interface Props {
  addCreditsButton: boolean;
}

const AddSingleCreditsModal: React.FC<Props> = ({ addCreditsButton }) => {
  const classes = useStyles();

  const { costCenter } = useAuthContext();
  const { showDialog } = useDialogContext();
  const { subscription } = useSubscriptionContext();

  const [documentsAmount, setDocumentsAmount] = useState(1);

  const [showAdd, setShowAdd] = useState(false);
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const totalValue =
    Number(documentsAmount) *
    Number(subscription?.single_credit_purchase_price);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const {
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<FormValues>();

  const handleDocumentAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const auxDocumentsAmount = +e.target.value;

    if (auxDocumentsAmount > 100) {
      setDocumentsAmount(100);
      return;
    }

    setDocumentsAmount(auxDocumentsAmount);
  };
  const onSubmit: SubmitHandler<FormValues> = async () => {
    setIsSubmitting(true);

    try {
      const response = await api.post(`checkout-single/${costCenter?.id}`, {
        quantity: documentsAmount,
      });

      setIsSubmitting(false);
      window.open(response.data, '_blank');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setIsSubmitting(false);
      if (!error.response) {
        return;
      }

      setError('documentAmount', {
        message:
          error.response.status === 500
            ? 'Não foi possível realizar a operação. Tente novamente mais tarde!'
            : error.response.data.message.documentAmount[0],
      });

      if (error.response.status === 500) {
        showDialog(
          'default',
          'Não foi possível realizar a operação. Tente novamente mais tarde!',
        );
        handleClose();
      }
    }
  };

  return (
    <>
      {addCreditsButton ? (
        <ActionButton
          text="Adicionar Créditos"
          type="button"
          margin="0"
          mobilewidth="12rem"
          tabletwidth="12rem"
          laptopwidth="14rem"
          desktopwidth="16rem"
          icon={<NoteAddIcon sx={{ color: COLORS.white }} />}
          action={handleOpen}
        />
      ) : (
        <IconButton
          onClick={handleOpen}
          onMouseEnter={() => setShowAdd(true)}
          onMouseLeave={() => setShowAdd(false)}
        >
          {showAdd ? (
            <NoteAddIcon sx={{ color: COLORS.white }} />
          ) : (
            <FileCopyIcon sx={{ color: COLORS.white }} fontSize="small" />
          )}
        </IconButton>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        className={classes.dialog}
        sx={{ width: '30rem' }}
      >
        <DialogTitle>Adicionar créditos</DialogTitle>

        <ContentSubTitle
          text={`Valor do documento: R$ ${formatPrice(
            subscription?.single_credit_purchase_price,
          )}`}
          fontWeight="bold"
          fontSize="1rem"
          margin="1rem 0 .3rem 1.5rem"
          alignSelf="flex-start"
          color={COLORS.black}
          width="unset"
        />

        <form
          noValidate
          id="formulario"
          method="post"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            width: '30rem',
            padding: '.5rem 1.5rem',
            overflow: 'auto',
          }}
          onSubmit={e => {
            clearErrors();
            handleSubmit(onSubmit)(e);
          }}
        >
          <Stack
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: {
                mobile: 'column',
                tablet: 'column',
              },
            }}
          >
            <ContentSubTitle
              text="Quantidade"
              fontWeight="bold"
              fontSize="1rem"
              margin="1rem 0 .3rem 0"
              alignSelf="flex-start"
              color={COLORS.black}
              width="unset"
            />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <IconButton
                disabled={documentsAmount <= 1}
                onClick={() => setDocumentsAmount(Number(documentsAmount) - 1)}
                sx={{
                  '&:hover': { backgroundColor: 'transparent' },
                }}
              >
                <IndeterminateCheckBoxIcon
                  sx={{
                    color:
                      documentsAmount <= 1 ? 'primary.light' : 'primary.main',
                    fontSize: '2.5rem',
                  }}
                />
              </IconButton>
              <FormControl sx={{ width: '100%', marginBottom: '1rem' }}>
                <TextField
                  autoFocus
                  id="amount-input"
                  type="number"
                  className={classes.numberInput}
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    min: 0,
                    max: 100,
                    step: '1',
                  }}
                  sx={{
                    textAlign: 'center',
                    height: '1rem',
                    width: '5rem',
                    '& .MuiOutlinedInput-input': {
                      padding: '4px 0 5px',
                      textAlign: 'center',
                    },
                  }}
                  value={documentsAmount}
                  onChange={handleDocumentAmount}
                  error={Boolean(errors.documentAmount)}
                />
              </FormControl>

              <IconButton
                disabled={Number(documentsAmount) === 100}
                onClick={() => setDocumentsAmount(Number(documentsAmount) + 1)}
                sx={{
                  '&:hover': { backgroundColor: 'transparent' },
                  '&:active': { backgroundColor: 'transparent' },
                }}
              >
                <AddBoxIcon
                  sx={{
                    color:
                      documentsAmount >= 100
                        ? 'primary.light'
                        : 'primary.main',
                    fontSize: '2.5rem',
                  }}
                />
              </IconButton>
            </Box>

            <Box className={classes.columnStart}>
              <ContentSubTitle
                text="Compra rápida"
                fontWeight="bold"
                fontSize="1rem"
                margin="1rem 0 .3rem 0"
                alignSelf="flex-start"
                color={COLORS.black}
                width="unset"
              />
              <Box
                className={classes.rowCenter}
                sx={{ height: '5rem', gap: '1rem' }}
              >
                {fastAmount.map(amount => {
                  return (
                    <Box
                      className={classes.fastAmount}
                      sx={{
                        backgroundColor: 'primary.main',
                      }}
                      role="button"
                      onClick={() => setDocumentsAmount(amount)}
                      key={amount}
                    >
                      <ContentSubTitle
                        text={JSON.stringify(amount)}
                        fontWeight="bold"
                        fontSize=".9rem"
                        margin="auto"
                        alignSelf="flex-start"
                        color={COLORS.white}
                        width="unset"
                      />
                      <FileCopyIcon
                        sx={{ color: COLORS.white, marginRight: '.5rem' }}
                        fontSize="small"
                      />
                    </Box>
                  );
                })}
              </Box>
            </Box>

            <Divider sx={{ width: '90%', margin: '.5rem 0' }} />
            <Box className={classes.columnStart}>
              <ContentSubTitle
                text="Total"
                fontWeight="bold"
                fontSize="1rem"
                margin="1rem 0 .3rem 0"
                alignSelf="flex-start"
                color={COLORS.black}
                width="unset"
              />
              <Box className={classes.rowBetween}>
                <ContentSubTitle
                  text={`${String(documentsAmount)} documento${
                    documentsAmount > 1 ? 's' : ''
                  }`}
                  fontWeight="bold"
                  fontSize="1rem"
                  margin="1rem 0 .3rem 0"
                  alignSelf="flex-start"
                  color={COLORS.black}
                  width="unset"
                />
                <ContentSubTitle
                  text={`R$ ${formatPrice(totalValue)}`}
                  fontWeight="bold"
                  fontSize="1rem"
                  margin="1rem 0 .3rem 0"
                  alignSelf="flex-start"
                  color={COLORS.black}
                  width="unset"
                />
              </Box>
            </Box>

            <Box className={classes.dialogActions}>
              <Button
                onClick={handleClose}
                sx={{
                  width: '7rem',
                  color: COLORS.mediumGray3,
                  '&:hover': {
                    backgroundColor: COLORS.lightGray2,
                  },
                }}
              >
                Cancelar
              </Button>
              {isSubmitting ? (
                <LoadingButton
                  loading
                  loadingPosition="center"
                  sx={{
                    width: '7rem',
                    height: '2.25rem',
                    color: COLORS.white,
                    backgroundColor: 'primary.main',
                    '&:hover': {
                      backgroundColor: 'primary.light',
                    },
                  }}
                />
              ) : (
                <Button
                  type="submit"
                  endIcon={<AttachMoneyIcon />}
                  disabled={documentsAmount === 0}
                  sx={{
                    width: '7rem',
                    color: COLORS.white,
                    backgroundColor: 'primary.main',
                    '&:hover': {
                      backgroundColor: 'primary.light',
                    },
                  }}
                >
                  Comprar
                </Button>
              )}
            </Box>
          </Stack>
        </form>
      </Dialog>
    </>
  );
};

export default AddSingleCreditsModal;
