/* eslint-disable no-nested-ternary */
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

import ActionButton from './ActionButton';
import AttentionImage from './Images/AttentionImage';
import ErrorImage from './Images/ErrorImage';
import MailSentImage from './Images/MailSentImage';
import OnlyOneImage from './Images/OnlyOneImage';
import PaymentMethodImage from './Images/PaymentMethodImage';
import PdfOnlyImage from './Images/PdfOnlyImage';
import SignatureSuccessImage from './Images/SignatureSuccessImage';
import SuccessImage from './Images/SuccessImage';
import TokenImage from './Images/TokenImage';

import { DialogType } from '../types/DialogType';

import COLORS from '../utils/colors';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    minWidth: 400,
    maxWidth: 540,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

type Props = {
  isOpen: boolean;
  handleClose(): void;
  type: DialogType;
  message: string;
};

export default function MyDialog({
  isOpen,
  handleClose,
  type,
  message,
}: Props) {
  const navigate = useNavigate();

  const dataDialog = {
    default: {
      title: 'Atenção!',
      Image: ErrorImage,
      action: handleClose,
    },

    resendEmail: {
      title: 'Email reenviado!',
      Image: MailSentImage,
      action: () => {
        handleClose();
        document.location.reload();
      },
    },

    cancelFlowDocument: {
      title: 'Confirmação',
      Image: AttentionImage,
      action: handleClose,
    },

    mustBePdf: {
      title: 'Atenção!',
      Image: PdfOnlyImage,
      action: handleClose,
    },

    manyFiles: {
      title: 'Atenção!',
      Image: OnlyOneImage,
      action: handleClose,
    },

    resetPassword: {
      title: 'Senha redefinida!',
      Image: TokenImage,
      action: handleClose,
    },

    choosePlan: {
      title: 'Escolha um plano!',
      Image: AttentionImage,
      action: handleClose,
    },

    chooseMethodPayment: {
      title: 'Escolha método de pagamento!',
      Image: PaymentMethodImage,
      action: handleClose,
    },

    signedDocument: {
      title: 'Documento assinado!',
      Image: SignatureSuccessImage,
      action: handleClose,
    },

    success: {
      title: 'Sucesso!',
      Image: SuccessImage,
      action: handleClose,
    },
  };

  const { title, Image, action } = dataDialog[type];

  return (
    <BootstrapDialog open={isOpen} onClose={handleClose}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {title}

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Image
          style={{ width: '12rem', height: '12rem', marginBottom: '1rem' }}
        />

        <Typography
          variant="subtitle2"
          style={{
            color: COLORS.black,
            fontWeight: 'bold',
            margin: '.3rem',
            textAlign: 'center',
          }}
        >
          {message}
        </Typography>
        {type !== 'cancelFlowDocument' ? (
          <ActionButton type="button" text="Ok" action={action} />
        ) : (
          <Box
            sx={{
              width: '20rem !important',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '2rem',
              gap: '10px',
            }}
          >
            <ActionButton
              color="primary.main"
              hoverColor="primary.light"
              type="button"
              text="Não, voltar"
              action={handleClose}
            />

            <ActionButton
              color={COLORS.mediumGray2}
              hoverColor={COLORS.mediumGray4}
              type="button"
              text="Sim, cancelar"
              action={() => {
                handleClose();
                navigate(-1);
              }}
            />
          </Box>
        )}
      </DialogContent>
    </BootstrapDialog>
  );
}
