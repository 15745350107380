import defaultTheme from './defaultTheme';
import conlicitacaoTheme from './conlicitacaoTheme';
import prevLawTheme from './prevLawTheme';
import codataTheme from './codataTheme';

import { OriginToken } from '../types/OriginToken';

export default function getTheme(whiteLabel: OriginToken) {
  if (whiteLabel === 'conlicitacao') {
    return conlicitacaoTheme;
  }

  if (whiteLabel === 'prevLaw') {
    return prevLawTheme;
  }

  if (whiteLabel === 'codata') {
    return codataTheme;
  }

  return defaultTheme;
}
