/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LoadingButton from '@mui/lab/LoadingButton';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';

import { FolderArrayObject } from '../../types/Folder';

import useDialogContext from '../../hooks/useDialogContext';

import { UserCostCenter } from '../../types/CostCenter';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40rem',
    margin: '0 auto',
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '30rem',
    padding: '0 1.5rem 1.5rem',
    gap: '10px',
  },

  text: {
    textAlign: 'justify',
    color: COLORS.black,
    fontWeight: 'bold',
    fontSize: '.925rem',
  },

  dialogActions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '1rem 0 0 1rem',
    alignSelf: 'end',
    marginTop: '1rem',
    gap: '.5rem',
  },

  menuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    gap: '.5rem',
    transition: 'all .3s ease-out',
    '&.MuiMenuItem-root': {
      '&:selected': {
        backgroundColor: `${theme.palette.secondary.main} !important`,
        '& .MuiSvgIcon-root': {
          color: COLORS.white,
        },
      },
      '&:focus': {
        backgroundColor: theme.palette.secondary.main,
        color: COLORS.white,
        '& .MuiSvgIcon-root': {
          color: COLORS.white,
        },
      },
      '&:active': {
        backgroundColor: theme.palette.secondary.main,
        color: COLORS.white,
        '& .MuiSvgIcon-root': {
          color: COLORS.white,
        },
      },
    },
  },
}));

interface SubfolderForm {
  nome: string;
}

interface Props {
  folder?: FolderArrayObject;
  setFolder?: (folder: FolderArrayObject) => void;
  folderArray?: FolderArrayObject[] | null;
  costcenter?: UserCostCenter | undefined | null;
  handleCloseMenu?: () => void;
}

const ModalEditFolder: React.FC<Props> = ({
  folder,
  setFolder,
  costcenter,
  handleCloseMenu,
}) => {
  const { showDialog } = useDialogContext();

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [subfolderName, setSubfolderName] = useState(folder?.name);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm<SubfolderForm>();

  const handleClose = () => {
    setOpen(false);
    clearErrors();
    reset();
    handleCloseMenu?.();
  };

  const handleOpen = (e: any) => {
    setOpen(true);
    e.stopPropagation();
    e.preventDefault();
  };

  const onSubmit: SubmitHandler<SubfolderForm> = async () => {
    setIsSubmitting(true);
    handleCloseMenu?.();
    try {
      const data = {
        name: subfolderName,
        folder_id: folder?.id,
      };
      await api.put(
        `folder/${costcenter?.id}?folder_id=${data.folder_id}&name=${data.name}`,
      );

      if (data.name && data.folder_id) {
        setFolder?.({
          name: data.name,
          id: data.folder_id,
          type: folder?.type,
        });
      }
      setIsSubmitting(false);
      setSubfolderName('');
      handleClose();
    } catch (error: any) {
      setIsSubmitting(false);
      if (!error.response) {
        showDialog(
          'default',
          'Erro inesperado. Por favor, tente novamente mais tarde!',
        );
        return;
      }
      showDialog('default', error.response.data.message);
    }
  };

  return (
    <>
      <MenuItem onClick={handleOpen} className={classes.menuItem}>
        <EditIcon sx={{ color: COLORS.mediumGray5 }} />
        Renomear pasta
      </MenuItem>

      <Dialog
        open={open}
        onClose={handleClose}
        className={classes.dialog}
        sx={{ width: '30rem' }}
      >
        <DialogTitle>Editar pasta</DialogTitle>

        <form
          noValidate
          method="post"
          className={classes.content}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Stack
            sx={{
              width: '100%',
              gap: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'column',
            }}
          >
            <Typography
              className={classes.text}
              sx={{ margin: '0 auto .5rem 0', fontSize: '.925rem' }}
              variant="subtitle2"
            >
              Informe o novo nome da pasta.
            </Typography>

            <FormControl sx={{ width: '100%' }}>
              <TextField
                autoFocus
                label="Nome"
                variant="standard"
                color="primary"
                type="text"
                {...register('nome', {
                  required: 'Por favor, informe o nome da pasta',
                  minLength: {
                    value: 1,
                    message: 'O nome deve conter pelo menos 1 caractere',
                  },
                  pattern: {
                    value: /^[A-zÀ-ú 0-9']+$/,
                    message: 'Por favor, informe um nome válido',
                  },
                })}
                value={subfolderName}
                onChange={e => {
                  setSubfolderName(e.target.value);
                  if (errors) {
                    clearErrors();
                  }
                }}
                error={Boolean(errors?.nome)}
                helperText={errors?.nome?.message}
              />
            </FormControl>
          </Stack>

          <Box className={classes.dialogActions}>
            <Button
              onClick={e => {
                e.stopPropagation();
                handleClose();
              }}
              sx={{
                width: '7rem',
                color: COLORS.mediumGray3,
                '&:hover': {
                  backgroundColor: COLORS.lightGray2,
                },
              }}
            >
              Cancelar
            </Button>

            {isSubmitting ? (
              <LoadingButton
                loading
                loadingPosition="center"
                sx={{
                  width: '7rem',
                  height: '2.25rem',
                  color: COLORS.white,
                  backgroundColor: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'primary.light',
                  },
                }}
              />
            ) : (
              <Button
                type="submit"
                endIcon={<CheckIcon />}
                sx={{
                  width: '8rem',
                  color: COLORS.white,
                  backgroundColor: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'primary.light',
                  },
                }}
              >
                Alterar
              </Button>
            )}
          </Box>
        </form>
      </Dialog>
    </>
  );
};

export default ModalEditFolder;
