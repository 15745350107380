/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Checkbox from '@mui/material/Checkbox';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

import SigneesImage from '../../../components/Images/SigneesImage';

import useAuthContext from '../../../hooks/useAuthContext';
import useDialogContext from '../../../hooks/useDialogContext';

import { Signee } from '../../../types/Signee';

import api from '../../../services/api';

import COLORS from '../../../utils/colors';

const useStyles = makeStyles((theme: Theme) => ({
  buttonResendEmail: {
    '&.MuiButtonBase-root': {
      width: '11rem !important',
      backgroundColor: COLORS.green,

      '&:hover': {
        backgroundColor: COLORS.hoverGreen,
      },
    },
  },

  dialog: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
  },

  titleContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 24px 0',
  },

  title: {
    color: COLORS.black,
    textAlign: 'right',
    '& .MuiSvgIcon-root ': {
      fontSize: '1.25rem',
      cursor: 'pointer',

      '&:hover': {
        color: theme.palette.primary.light,
        transition: '0.3s',
      },
    },
  },

  contentText: {
    color: COLORS.black,
    fontSize: '0.925rem',
    textAlign: 'justify',
    padding: '0 0 2rem',
  },

  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& svg': {
      width: '18rem',
      margin: '2rem auto',
    },

    '& .MuiTypography-root': {
      margin: '0',
      textAlign: 'center',
      color: COLORS.black,
      fontSize: '0.875rem',
      fontWeight: 'bold',
    },
  },

  sendButton: {
    width: '7rem',
    color: COLORS.white,

    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

interface Props {
  signees: Signee[];
}

const ModalResendEmailToSignee: React.FC<Props> = ({ signees }) => {
  const classes = useStyles();

  const signatoryFilterAwaitingSignature = signees.filter(
    signee => signee.status === 'aguardando_assinatura',
  );

  const [open, setOpen] = useState(false);
  const [, setIsProcessing] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [checked, setChecked] = useState(
    signatoryFilterAwaitingSignature.map(signee => {
      return {
        ...signee,
        checked: false,
      };
    }),
  );

  const { costCenter } = useAuthContext();
  const { showDialog } = useDialogContext();
  const { id } = useParams();

  const handleClose = () => {
    setOpen(false);
    setIsProcessing(false);
    setChecked(
      signatoryFilterAwaitingSignature.map(signee => {
        return {
          ...signee,
          checked: false,
        };
      }),
    );
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const chooseSigneeToResendEmail = (index: number) => {
    const localChecked = [...checked];
    localChecked[index].checked = !localChecked[index].checked;
    setChecked(localChecked);
  };

  const resendEmailToSignDocument = async () => {
    setIsSendingEmail(true);
    try {
      const filterSignees = checked
        .filter(signee => signee.checked)
        .map(signee => signee.email);

      if (filterSignees.length === 0) {
        showDialog(
          'default',
          'Para continuar é necessário escolher no mínimo um signatário.',
        );

        return;
      }

      const response = await api.post(`send_emails/${costCenter?.id}/${id}`, {
        signees: filterSignees,
      });

      if (response.status === 200) {
        setIsSendingEmail(false);
        handleClose();
        showDialog(
          'success',
          'Emails enviados para os signatários assinarem o documento.',
        );
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setIsSendingEmail(false);
      if (!error.response) {
        showDialog(
          'default',
          'Erro inesperado. Por favor, tente novamente mais tarde!',
        );

        return;
      }

      showDialog('default', error.response.data.message);
    }
  };

  return (
    <>
      <Tooltip title="Reenviar e-mail para os signatários que ainda não assinaram">
        <Button
          className={classes.buttonResendEmail}
          type="button"
          endIcon={<EmailIcon fontSize="large" />}
          onClick={handleOpen}
        >
          Reenviar e-mail
        </Button>
      </Tooltip>

      <Dialog
        open={open}
        onClose={!isSendingEmail ? handleClose : undefined}
        className={classes.dialog}
        sx={{ width: '32rem' }}
      >
        <div className={classes.titleContainer}>
          <DialogTitle className={classes.title} sx={{ padding: '0' }}>
            Reenviar e-mail para os signatários
          </DialogTitle>
          <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
        </div>

        <DialogContent className={classes.dialogContent}>
          <Stack
            direction="column"
            sx={{ gap: '0.625rem', alignItems: 'center' }}
          >
            <SigneesImage style={{ height: '25vh' }} />

            <h6 className={classes.contentText}>
              Selecione os signatários para os quais gostaria de reenviar o
              email.
            </h6>
          </Stack>

          <Grid
            sx={{
              height: '29vh',
              width: '25vw',
              overflow: 'auto',
              borderRadius: '8px',
              backgroundColor: COLORS.background,
              '::-webkit-scrollbar': {
                width: '10px',
              },
              '::-webkit-scrollbar-track': {
                background: COLORS.lightGray3,
                borderRadius: '50px',
              },
              '::-webkit-scrollbar-thumb': {
                background: COLORS.mediumGray2,
                borderRadius: '50px',
              },
            }}
          >
            {signatoryFilterAwaitingSignature.map((signee, index) => (
              <Box
                key={signee.id}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  margin: '.5rem auto 0 auto',
                  padding: '.5rem',
                  borderBottom: `1px solid ${COLORS.lightGray3}`,
                  overflow: 'auto',
                  gap: '.5rem',
                  width: '94%',
                }}
              >
                <Checkbox
                  inputProps={{ 'aria-label': 'controlled' }}
                  onClick={() => {
                    chooseSigneeToResendEmail(index);
                  }}
                  sx={{
                    width: '2rem',
                    height: '2rem',
                    '&.Mui-checked': {
                      color: 'primary.main',
                    },
                  }}
                />

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                  }}
                >
                  <Typography style={{ fontSize: '.925rem' }}>
                    {signee.nome}
                  </Typography>

                  <Typography
                    style={{
                      color: COLORS.mediumGray4,
                      fontSize: '.8rem',
                      fontWeight: 500,
                    }}
                  >
                    {signee.email}
                  </Typography>
                </div>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: '20px',
                  }}
                >
                  {}
                </Box>
              </Box>
            ))}
          </Grid>
        </DialogContent>

        <DialogActions sx={{ padding: '1.5rem' }}>
          <Button
            onClick={handleClose}
            sx={{
              width: '7rem',
              color: COLORS.mediumGray3,
              '&:hover': {
                backgroundColor: COLORS.lightGray2,
              },
            }}
          >
            Cancelar
          </Button>

          {isSendingEmail ? (
            <LoadingButton
              sx={{
                height: '2.25rem',
                color: COLORS.white,
                backgroundColor: 'primary.light',
              }}
              className={classes.sendButton}
              loading
              loadingIndicator={
                <CircularProgress size={20} sx={{ color: COLORS.white }} />
              }
              loadingPosition="center"
            />
          ) : (
            <Button
              type="button"
              endIcon={<EmailIcon fontSize="large" />}
              onClick={resendEmailToSignDocument}
              className={classes.sendButton}
              sx={{
                color: COLORS.white,
                backgroundColor: 'primary.main',
                '&:hover': {
                  backgroundColor: 'primary.light',
                },
              }}
            >
              Enviar
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalResendEmailToSignee;
