import Box from '@mui/material/Box';

import PageMainContentBox from '../../components/PageMainContentBox';
import UserFoldersPage from './UserFoldersPage';
import DeniedAccess from '../../components/DeniedAccess';

import useAuthContext from '../../hooks/useAuthContext';

import roleBasedRender from '../../utils/roleBasedRender';

// role based render = user only
export default function Folders() {
  const { userInfo } = useAuthContext();

  const role = userInfo ? userInfo.user.roles[0].nome : '';

  return (
    <Box style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <PageMainContentBox
        title="Minhas Pastas"
        page="minhas_pastas"
        overflow="auto"
      >
        {roleBasedRender(
          role,
          <UserFoldersPage />,
          <DeniedAccess />,
          <DeniedAccess />,
          null,
        )}
      </PageMainContentBox>
    </Box>
  );
}
