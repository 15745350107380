/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import makeStyles from '@mui/styles/makeStyles';
import LoadingButton from '@mui/lab/LoadingButton';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import ActionButton from '../../components/ActionButton';

import useDialogContext from '../../hooks/useDialogContext';
import useAuthContext from '../../hooks/useAuthContext';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const useStyles = makeStyles({
  dialog: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40rem',
    margin: '0 auto',
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '30rem',
    padding: '1.5rem',
    gap: '10px',
  },

  text: {
    textAlign: 'justify',
    color: COLORS.black,
    fontWeight: 'bold',
    fontSize: '.925rem',
  },

  dialogActions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '1rem 0 0 1rem',
    alignSelf: 'end',
    marginTop: '1rem',
    gap: '.5rem',
  },
});

interface UserInCostCenter {
  id: number;
  nome: string;
  email: string;
  status: string;
  created_at: string;
  updated_at: string;
}

interface UserInCostCenterForm {
  name: string;
  email: string;
}

type Fields = 'name' | 'email';

interface Props {
  setUsersInCostCenters: React.Dispatch<
    React.SetStateAction<UserInCostCenter[]>
  >;
}

const ModalCreateUserInCostCenter: React.FC<Props> = ({
  setUsersInCostCenters,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const { showDialog } = useDialogContext();
  const { costCenter } = useAuthContext();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
  } = useForm<UserInCostCenterForm>();

  const handleClose = () => {
    setOpen(false);
    clearErrors();
    reset();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const onSubmit: SubmitHandler<UserInCostCenterForm> = async data => {
    setIsSubmitting(true);
    try {
      const response = await api.post(`center_users/${costCenter?.id}`, data);
      setIsSubmitting(false);
      handleClose();

      if (response.data.user) {
        setUsersInCostCenters(prevUsers => [
          ...prevUsers,
          {
            ...response.data.user,
            nome: response.data.user.name,
          },
        ]);
        showDialog('success', 'Usuário foi adicionado ao centro de custo! :)');
        return;
      }

      showDialog(
        'success',
        'Foi enviado um email para o usuário. Ele deve preencher as informações para ser adicionado ao seu centro de custo! :)',
      );

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setIsSubmitting(false);
      if (!error.response) {
        return;
      }

      const errorMessage = error.response.data.message;

      Object.keys(errorMessage).forEach(key => {
        const field = key as Fields;
        setError(field, {
          message: errorMessage[field][0],
        });
      });

      showDialog(
        'default',
        `${errorMessage} Por favor, tente novamente mais tarde.`,
      );

      handleClose();
    }
  };

  return (
    <>
      <ActionButton
        type="button"
        text="Adicionar usuário"
        action={handleOpen}
        mobilewidth="12rem"
        tabletwidth="12rem"
        laptopwidth="14rem"
        desktopwidth="16rem"
        largedesktopwidth="16rem"
        margin="0"
        icon={
          <PersonAddIcon sx={{ color: COLORS.white, fontSize: '1.3rem' }} />
        }
      />

      <Dialog
        open={open}
        onClose={handleClose}
        className={classes.dialog}
        sx={{ width: '30rem' }}
      >
        <DialogTitle>Adicionar usuário no Centro de Custo</DialogTitle>

        <form
          noValidate
          method="post"
          className={classes.content}
          onSubmit={e => {
            clearErrors();
            handleSubmit(onSubmit)(e);
          }}
        >
          <Stack
            sx={{
              width: '100%',
              gap: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'column',
            }}
          >
            <Typography
              className={classes.text}
              sx={{ margin: '0 auto .5rem 0', fontSize: '.925rem' }}
              variant="subtitle2"
            >
              Digite os dados do usuário que deseja adicionar.
            </Typography>

            <FormControl sx={{ width: '100%' }}>
              <TextField
                label="Nome"
                variant="standard"
                type="text"
                color="primary"
                required
                {...register('name', {
                  required: 'Por favor, informe um nome',
                  pattern: {
                    value: /^[A-zÀ-ú ']+$/,
                    message: 'Por favor, informe um nome válido',
                  },
                })}
                error={Boolean(errors?.name)}
                helperText={errors?.name?.message}
              />
            </FormControl>
          </Stack>

          <FormControl sx={{ width: '100%' }}>
            <TextField
              label="E-mail"
              color="primary"
              variant="standard"
              type="text"
              required
              {...register('email', {
                required: 'Por favor, informe o e-mail',
                pattern: {
                  value:
                    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                  message: 'Por favor, informe um e-mail válido',
                },
              })}
              error={Boolean(errors?.email)}
              helperText={errors?.email?.message}
            />
          </FormControl>

          <Box className={classes.dialogActions}>
            <Button
              onClick={handleClose}
              sx={{
                width: '7rem',
                color: COLORS.mediumGray3,
                '&:hover': {
                  backgroundColor: COLORS.lightGray2,
                },
              }}
            >
              Cancelar
            </Button>

            {isSubmitting ? (
              <LoadingButton
                loading
                loadingPosition="center"
                sx={{
                  width: '7rem',
                  height: '2.25rem',
                  color: COLORS.white,
                  backgroundColor: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'primary.light',
                  },
                }}
              />
            ) : (
              <Button
                type="submit"
                endIcon={<PersonAddIcon />}
                sx={{
                  width: '8rem',
                  color: COLORS.white,
                  backgroundColor: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'primary.light',
                  },
                }}
              >
                Adicionar
              </Button>
            )}
          </Box>
        </form>
      </Dialog>
    </>
  );
};

export default ModalCreateUserInCostCenter;
