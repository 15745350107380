import Box from '@mui/material/Box';

import PageMainContentBox from '../../components/PageMainContentBox';
import UserMyAccount from './UserMyAccount';
import DeniedAccess from '../../components/DeniedAccess';

import useAuthContext from '../../hooks/useAuthContext';

import roleBasedRender from '../../utils/roleBasedRender';

// role based render = all
export default function MyAccount() {
  const { userInfo } = useAuthContext();
  const role = userInfo ? userInfo.user.roles[0].nome : '';

  return (
    <Box style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <PageMainContentBox title="Minha Conta" page="minha_conta">
        {roleBasedRender(
          role,
          <UserMyAccount />,
          <DeniedAccess />,
          <DeniedAccess />,
          null,
        )}
      </PageMainContentBox>
    </Box>
  );
}
