/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';

import ActionButton from '../../components/ActionButton';
import Plan1Image from '../../components/Images/Plan1Image';
import Plan2Image from '../../components/Images/Plan2Image';
import Plan3Image from '../../components/Images/Plan3Image';

import useDialogContext from '../../hooks/useDialogContext';

import api from '../../services/api';

import COLORS from '../../utils/colors';

const styles = {
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    color: COLORS.black,
    fontSize: '1.5rem',
    fontWeight: 500,

    '.MuiSvgIcon-root ': {
      fontSize: '2rem',
      cursor: 'pointer',

      '&:hover': {
        color: 'primary.main',
        transition: '0.3s',
      },
    },
  } as SxProps<Theme>,

  dialogContent: {
    width: '60vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '2rem 0',
    padding: '2rem',
    '::-webkit-scrollbar': {
      width: '10px',
    },
    '::-webkit-scrollbar-track': {
      background: COLORS.lightGray3,
      borderRadius: '50px',
    },
    '::-webkit-scrollbar-thumb': {
      background: COLORS.mediumGray2,
      borderRadius: '50px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: COLORS.mediumGray3,
    },
  } as SxProps<Theme>,

  fieldsBox: {
    display: 'grid',
    gap: '2rem',
    gridTemplateColumns: 'repeat(2, 1fr)',
    width: '100%',

    gridTemplateAreas: `"planName planName planName"
    "periodicity value numberDocuments"
    "quantitySmsAndWhatsapp quantityUsers checkUnlimite"
    "description description description"
    "validFrom validUntil createProductInStripe"
    "type type type"`,
  } as SxProps<Theme>,

  containerRadioGroup: {
    marginTop: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: '0.5rem',
    gridArea: 'type',
  } as SxProps<Theme>,

  radioGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: '2rem',
    columnGap: '1rem',

    '& > *': {
      textAlign: 'center',
      alignItems: 'center',

      '.card': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: theme => `1px solid ${theme.palette.primary.main}`,
        borderRadius: '0.625rem',
        padding: '1rem',
        height: '42vh',
        width: '100%',
        maxWidth: '14rem',
        minHeight: '16rem',

        h2: {
          color: 'primary.main',
          fontSize: '1.125rem',
          fontWeight: 500,
        },

        p: {
          color: COLORS.mediumGray5,
          fontSize: '1.125rem',
          fontWeight: 500,
        },

        ':hover': {
          cursor: 'pointer',
          outline: theme => `1px solid ${theme.palette.primary.main}`,
          transition: '0.3s',
        },
      },

      '.card-selected': {
        outline: theme => `1px solid ${theme.palette.primary.main}`,
      },
    },
  } as SxProps<Theme>,

  buttonSubmit: {
    textTransform: 'none',
    padding: '.3rem 0',
    color: COLORS.white,
    width: '30%',
    height: '3rem',
    fontSize: '1rem',
    fontWeight: 500,

    margin: '3rem 0 1rem 0',

    '&:hover': {
      backgroundColor: 'primary.main',
      opacity: '0.9',
      transition: '0.3s',
    },
  } as SxProps<Theme>,
};

type PlanFields =
  | 'name'
  | 'periodicity'
  | 'value'
  | 'numberDocuments'
  | 'quantitySmsAndWhatsapp'
  | 'quantityUsers'
  | 'description'
  | 'validFrom'
  | 'validUntil'
  | 'type';

type PlanFieldsInBr =
  | 'nome'
  | 'descricao'
  | 'valor'
  | 'quantidade_documentos'
  | 'quantidade_sms_whatsapp'
  | 'usuarios'
  | 'periodicidade'
  | 'tipo'
  | 'valido_de'
  | 'valido_ate';

const errorMessage = {
  name: 'Por favor, informe o nome do plano',
  periodicity: 'Por favor, informe a periodicidade',
  value: 'Por favor, informe o valor',
  numberDocuments: 'Por favor, informe a quantidade de documentos',
  quantitySmsAndWhatsapp: 'Por favor, informe a quantidade de sms e whatsapp',
  quantityUsers: 'Por favor, informe a quantidade de usuários',
  description: 'Por favor, informe a descrição',
  validFrom: 'Por favor, informe o válido de',
  validUntil: 'Por favor, informe o válido até',
  type: 'Por favor, informe o tipo',
};

const fieldsBrToEn = {
  nome: 'name',
  descricao: 'periodicity',
  valor: 'value',
  quantidade_documentos: 'numberDocuments',
  quantidade_sms_whatsapp: 'quantitySmsAndWhatsapp',
  usuarios: 'quantityUsers',
  periodicidade: 'description',
  tipo: 'validFrom',
  valido_de: 'validUntil',
  valido_ate: 'type',
};

const ModalCreatePlan: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCreateProductInStripe, setCreateProductInStripe] = useState(true);
  const [isCheckUnlimite, setCheckUnlimite] = useState(false);
  const { showDialog } = useDialogContext();

  const [plan, setPlan] = useState({
    name: '',
    periodicity: '',
    value: '',
    numberDocuments: '',
    quantitySmsAndWhatsapp: '',
    quantityUsers: '',
    description: '',
    validFrom: moment(),
    validUntil: moment().add(1, 'month'),
    type: '',
  });

  const [error, setError] = useState({
    name: '',
    periodicity: '',
    value: '',
    numberDocuments: '',
    quantitySmsAndWhatsapp: '',
    quantityUsers: '',
    description: '',
    validFrom: '',
    validUntil: '',
    type: '',
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCreateProductInStripe(true);
    setCheckUnlimite(false);

    setTimeout(() => {
      setPlan({
        name: '',
        periodicity: '',
        value: '',
        numberDocuments: '',
        quantitySmsAndWhatsapp: '',
        quantityUsers: '',
        description: '',
        validFrom: moment(),
        validUntil: moment().add(1, 'month'),
        type: '',
      });

      setError({
        name: '',
        periodicity: '',
        value: '',
        numberDocuments: '',
        quantitySmsAndWhatsapp: '',
        quantityUsers: '',
        description: '',
        validFrom: '',
        validUntil: '',
        type: '',
      });
    }, 300);
  };

  const verifyFields = () => {
    let hasError = false;
    let auxError = {};
    const emptyError = {
      name: '',
      periodicity: '',
      value: '',
      numberDocuments: '',
      quantitySmsAndWhatsapp: '',
      quantityUsers: '',
      description: '',
      validFrom: '',
      validUntil: '',
      type: '',
    };

    Object.keys(plan).forEach(key => {
      const currentKey = key as PlanFields;

      if (!plan[currentKey]) {
        auxError = {
          ...auxError,
          [currentKey]: errorMessage[currentKey],
        };

        hasError = true;
      }
    });

    setError({
      ...emptyError,
      ...auxError,
    });

    if (hasError) {
      throw new Error('Some field has not been filled.');
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    // isCheckUnlimite === true ? 1 : 0
    // isCreateProductInStripe === true ? 1 : 0
    try {
      verifyFields();
      await api.post('/planos', {
        nome: plan.name,
        descricao: plan.description,
        valor: plan.value,
        quantidade_documentos: plan.numberDocuments,
        quantidade_sms_whatsapp: plan.quantitySmsAndWhatsapp,
        ilimitado: isCheckUnlimite,
        usuarios: plan.quantityUsers,
        periodicidade: plan.periodicity,
        tipo: plan.type,
        valido_de: plan.validFrom.format('YYYY-MM-DD'),
        valido_ate: plan.validUntil.format('YYYY-MM-DD'),
        stripe: isCreateProductInStripe,
      });

      showDialog('success', 'Plano criado!');
      handleClose();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.log(err);

      if (err.response && err.response.status === 400) {
        showDialog('default', err.response.data.message);
        setIsSubmitting(false);
        return;
      }

      showDialog('default', 'Erro ao criar plano');

      if (err.response && err.response.status === 422) {
        const errors = err.response.data.message;
        let auxError = {};

        Object.keys(errors).forEach(key => {
          const currentKey = key as PlanFieldsInBr;
          auxError = {
            ...auxError,
            [fieldsBrToEn[currentKey]]: errors[currentKey],
          };
        });

        setError({
          ...error,
          ...auxError,
        });
      }
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <ActionButton
        mobilewidth="100%"
        tabletwidth="35%"
        laptopwidth="25%"
        desktopwidth="25%"
        largedesktopwidth="18%"
        type="button"
        text="Criar novo plano"
        margin="0 0 .5rem 0"
        self="flex-end"
        action={handleOpen}
        icon={<AddIcon fontSize="medium" />}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={styles.titleContainer}>
          Criar novo plano
          <CloseIcon onClick={handleClose} />
        </DialogTitle>

        <DialogContent sx={styles.dialogContent}>
          <Box sx={styles.fieldsBox}>
            <TextField
              autoFocus
              name="name"
              label="Nome do plano"
              variant="standard"
              color="warning"
              type="text"
              value={plan.name}
              onChange={e =>
                setPlan({ ...plan, [e.target.name]: e.target.value })
              }
              error={Boolean(error.name)}
              helperText={error.name}
              sx={{ gridArea: 'planName' }}
            />
            <TextField
              name="periodicity"
              label="Periodicidade"
              variant="standard"
              color="warning"
              type="text"
              select
              value={plan.periodicity}
              onChange={e =>
                setPlan({ ...plan, [e.target.name]: e.target.value })
              }
              error={Boolean(error.periodicity)}
              helperText={error.periodicity}
              sx={{ gridArea: 'periodicity' }}
            >
              <MenuItem value="" />
              <MenuItem value="day">Diário</MenuItem>
              <MenuItem value="week">Semanal</MenuItem>
              <MenuItem value="month">Mensal</MenuItem>
              <MenuItem value="two_months">Bimestral</MenuItem>
              <MenuItem value="quarter">Trimestral</MenuItem>
              <MenuItem value="semester">Semestral</MenuItem>
              <MenuItem value="year">Anual</MenuItem>
              <MenuItem value="two_years">Bienal</MenuItem>
              <MenuItem value="three_years">Trienal</MenuItem>
            </TextField>
            <TextField
              name="value"
              label="Valor"
              variant="standard"
              color="warning"
              type="text"
              value={plan.value}
              onChange={e =>
                setPlan({ ...plan, [e.target.name]: e.target.value })
              }
              error={Boolean(error.value)}
              helperText={error.value}
              sx={{ gridArea: 'value' }}
            />
            <TextField
              name="numberDocuments"
              label="Quantidade de documentos"
              variant="standard"
              color="warning"
              type="text"
              value={plan.numberDocuments}
              onChange={e =>
                setPlan({ ...plan, [e.target.name]: e.target.value })
              }
              error={Boolean(error.numberDocuments)}
              helperText={error.numberDocuments}
              sx={{ gridArea: 'numberDocuments ' }}
            />
            <TextField
              name="quantitySmsAndWhatsapp"
              label="Quantidade de sms e whatsapp"
              variant="standard"
              color="warning"
              type="text"
              value={plan.quantitySmsAndWhatsapp}
              onChange={e =>
                setPlan({ ...plan, [e.target.name]: e.target.value })
              }
              error={Boolean(error.quantitySmsAndWhatsapp)}
              helperText={error.quantitySmsAndWhatsapp}
              sx={{ gridArea: 'quantitySmsAndWhatsapp ' }}
            />
            <TextField
              name="quantityUsers"
              label="Quantidade Usuários"
              variant="standard"
              color="warning"
              type="text"
              value={plan.quantityUsers}
              onChange={e =>
                setPlan({ ...plan, [e.target.name]: e.target.value })
              }
              error={Boolean(error.quantityUsers)}
              helperText={error.quantityUsers}
              sx={{ gridArea: 'quantityUsers ' }}
            />
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{ gridArea: 'checkUnlimite' }}
            >
              <Typography>Plano ilimitado?</Typography>
              <Switch
                color="warning"
                onChange={() => setCheckUnlimite(prevValue => !prevValue)}
              />
            </Stack>

            <TextField
              name="description"
              label="Descrição"
              variant="standard"
              color="warning"
              type="text"
              multiline
              rows={4}
              value={plan.description}
              onChange={e =>
                setPlan({ ...plan, [e.target.name]: e.target.value })
              }
              error={Boolean(error.description)}
              helperText={error.description}
              sx={{ gridArea: 'description', marginBottom: '2rem' }}
            />

            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                label="Válido de"
                value={plan.validFrom}
                inputFormat="L"
                mask="__/__/____"
                disablePast
                onChange={newDate =>
                  setPlan({ ...plan, validFrom: moment(newDate) })
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    color="warning"
                    error={Boolean(error.validFrom)}
                    helperText={error.validFrom}
                    sx={{ gridArea: 'validFrom' }}
                  />
                )}
              />

              <DatePicker
                label="Válido até"
                value={plan.validUntil}
                inputFormat="L"
                mask="__/__/____"
                disablePast
                onChange={newDate =>
                  setPlan({ ...plan, validUntil: moment(newDate) })
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    color="warning"
                    error={Boolean(error.validUntil)}
                    helperText={error.validUntil}
                    sx={{ gridArea: 'validUntil' }}
                  />
                )}
              />
            </LocalizationProvider>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{ gridArea: 'createProductInStripe' }}
            >
              <Typography>Criar produto no stripe?</Typography>
              <Switch
                color="warning"
                defaultChecked
                onChange={() =>
                  setCreateProductInStripe(prevValue => !prevValue)
                }
              />
            </Stack>

            <FormControl
              error={Boolean(error.type)}
              variant="standard"
              sx={styles.containerRadioGroup}
            >
              <RadioGroup
                name="type"
                row
                value={plan.type}
                color="warning"
                onChange={e =>
                  setPlan({ ...plan, [e.target.name]: e.target.value })
                }
                sx={styles.radioGroup}
              >
                <Stack direction="row">
                  <Radio
                    value="site"
                    color="warning"
                    sx={{ gridArea: 'site' }}
                  />
                  <div
                    className={`card ${
                      plan.type === 'site' ? 'card-selected' : ''
                    }`}
                    onClick={() => setPlan({ ...plan, type: 'site' })}
                  >
                    <Plan1Image />

                    <h2>Site</h2>
                    <p style={{ fontSize: '.925rem' }}>
                      Crie planos que serão exibidos em nosso site!
                    </p>
                  </div>
                </Stack>

                <Stack direction="row" sx={{ gridArea: 'sales' }}>
                  <Radio color="warning" value="vendas" />
                  <div
                    className={`card ${
                      plan.type === 'vendas' ? 'card-selected' : ''
                    }`}
                    onClick={() => setPlan({ ...plan, type: 'vendas' })}
                  >
                    <Plan2Image />

                    <h2>Central de vendas</h2>
                    <p style={{ fontSize: '.925rem' }}>
                      Crie planos que serão disponibilizados apenas pela nossa
                      central de vendas!
                    </p>
                  </div>
                </Stack>

                <Stack direction="row" sx={{ gridArea: 'custom' }}>
                  <Radio color="warning" value="personalizado" />
                  <div
                    className={`card ${
                      plan.type === 'personalizado' ? 'card-selected' : ''
                    }`}
                    onClick={() => setPlan({ ...plan, type: 'personalizado' })}
                  >
                    <Plan3Image />

                    <h2>Personalizado</h2>
                    <p style={{ fontSize: '.925rem' }}>
                      Crie um plano personalizado para um cliente específico!
                    </p>
                  </div>
                </Stack>
              </RadioGroup>
              <FormHelperText>{error.type}</FormHelperText>
            </FormControl>
          </Box>

          <Button
            variant="contained"
            disableElevation
            color="warning"
            sx={styles.buttonSubmit}
            onClick={handleSubmit}
            endIcon={!isSubmitting ? <AddIcon fontSize="medium" /> : null}
          >
            {isSubmitting ? (
              <CircularProgress size={30} color="inherit" />
            ) : (
              'Criar novo plano'
            )}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalCreatePlan;
