/* eslint-disable react/require-default-props */
import React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

import COLORS from '../utils/colors';

import { Signee } from '../types/Signee';

interface Props {
  total: number;
  max: number;
  signees: Signee[];
}

const styles = {
  container: {
    display: 'flex',

    '& :not(:first-child)': {
      marginLeft: '-.625rem',
    },
  },

  additionalAvatar: (status: boolean) => ({
    color: COLORS.gray2,
    backgroundColor: status ? COLORS.green : COLORS.lightGray4,
    fontSize: '0.75rem',
  }),

  tooltipText: (status: boolean) => ({
    color: status ? COLORS.lightGreen : 'inherit',
  }),
};

const SigneesAvatarGroup: React.FC<Props> = ({
  children,
  total,
  max,
  signees,
}) => {
  const additionalAvatarTooltip = signees.slice(max - total - 1).map(signee => (
    <span style={styles.tooltipText(signee.status === 'assinado')}>
      {signee.nome}
      {signee.status === 'assinado' ? ' assinou' : ' não assinou'}
      <br />
    </span>
  ));

  const additionalSigneesStatus =
    signees
      .slice(max - total - 1)
      .find(signee => signee.status === 'aguardando_assinatura') === undefined;

  return (
    <Box sx={styles.container}>
      {children &&
        React.Children.toArray(children).slice(0, total > max ? max - 1 : max)}

      {total > max && (
        <Tooltip title={additionalAvatarTooltip} placement="bottom">
          <Avatar sx={styles.additionalAvatar(additionalSigneesStatus)}>
            +{total - max + 1}
          </Avatar>
        </Tooltip>
      )}
    </Box>
  );
};

export default SigneesAvatarGroup;
