/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import GroupIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';
import EmailIcon from '@mui/icons-material/Email';
import WebViewer from '@pdftron/pdfjs-express';

import PageNextSteps from '../../../components/PageNextSteps';
import ContentSubTitle from '../../../components/ContentSubTitle';
import RoleBasedMainBox from '../../../components/RoleBasedMainBox';
import CustomWebViewer from '../../../components/CustomWebViewer';

import useDocumentContext from '../../../hooks/useDocumentContext';
import useCostCenterContext from '../../../hooks/useCostCenterContext';

import base64ToBlob from '../../../utils/base64ToBlob';

const useStyles = makeStyles({
  documentGrid: {
    height: '77vh',
    marginTop: '1rem',
    display: 'flex',
    gap: '2.5rem',

    justifyContent: 'center',
    alignItems: 'center',

    width: '50%',
  },

  outletBox: {
    width: '40%',
    minHeight: '65vh',
    maxHeight: '77vh',
    height: '77vh',
    margin: '1rem',
    borderRadius: '10px',
    gap: '20px',
  },
});

export default function UserDocumentsPage() {
  const classes = useStyles();
  const theme = useTheme();
  const viewer = useRef(null);

  const [isLoadingFile, setIsLoadingFile] = useState(true);

  const hasDocumentViewer =
    window.location.pathname === '/documentos/signatarios' ||
    window.location.pathname === '/documentos/configuracoes' ||
    window.location.pathname === '/documentos/mensagem';

  const {
    sendingDocument,
    currentFile,
    fileName,
    webviewInstance,
    setWebviewInstance,
    xfdf,
  } = useDocumentContext();

  const { setIsFromFolderPage } = useCostCenterContext();

  useEffect(() => {
    if (hasDocumentViewer && !webviewInstance) {
      WebViewer(
        {
          path: '/webviewer/lib',
          disabledElements: ['annotationStyleEditButton', 'toolsHeader'],
          licenseKey: process.env.REACT_APP_PDF_EXPRESS_JS_KEY_VIEWER,
        },
        viewer.current,
      )
        .then((instance: any) => {
          setWebviewInstance(instance);

          instance.UI.disableFeatures([
            instance.UI.Feature.Print,
            instance.UI.Feature.Annotations,
          ]);
          instance.UI.setLanguage('pt_br');

          instance.Core.annotationManager.setRotationOptions({
            isEnabled: false,
          });

          instance.Core.documentViewer.addEventListener('pageComplete', () => {
            setIsLoadingFile(false);
          });

          if (xfdf) {
            instance.Core.documentViewer.addEventListener(
              'documentLoaded',
              async () => {
                await instance.Core.annotationManager.importAnnotations(xfdf);
              },
            );
          }
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }, [window.location.pathname, sendingDocument]);

  useEffect(() => {
    if (webviewInstance && currentFile) {
      const fileBlob = base64ToBlob(`${currentFile}`, 'application/pdf');

      webviewInstance.UI.loadDocument(fileBlob, {
        filename: fileName,
        extension: 'pdf',
      });
    }
  }, [webviewInstance, currentFile]);

  useEffect(() => {
    setIsFromFolderPage(false);
  }, []);

  return (
    <RoleBasedMainBox gap="0" overflow="unset">
      {sendingDocument ? (
        <Grid
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 auto',
          }}
        >
          <CircularProgress
            size={24}
            color="primary"
            sx={{
              margin: '16rem auto 0 auto',
            }}
          />
          <ContentSubTitle
            text="Enviando documento..."
            fontWeight="bold"
            fontSize=".9rem"
            color="primary.main"
            margin="2rem 0 .3rem 0"
            alignSelf="center"
          />
        </Grid>
      ) : (
        <>
          {hasDocumentViewer && (
            <PageNextSteps
              steps={[
                {
                  title: 'Signatários',
                  path: '/documentos/signatarios',
                  icon: <GroupIcon />,
                },
                {
                  title: 'Configurações',
                  path: '/documentos/configuracoes',
                  icon: <SettingsIcon />,
                },
                {
                  title: 'Mensagem',
                  path: '/documentos/mensagem',
                  icon: <EmailIcon />,
                },
              ]}
            />
          )}

          {hasDocumentViewer ? (
            <Grid
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
                margin: '0 auto',
                gap: '3rem',
                [theme.breakpoints.down(1240)]: {
                  flexDirection: 'column',
                },
                [theme.breakpoints.between(1240, 1340)]: {
                  maxWidth: '900px',
                },
                [theme.breakpoints.between(1340, 1440)]: {
                  maxWidth: '1000px',
                },
                [theme.breakpoints.between(1440, 1540)]: {
                  maxWidth: '1100px',
                },
                [theme.breakpoints.between(1540, 1640)]: {
                  maxWidth: '1200px',
                },
                [theme.breakpoints.between(1640, 1740)]: {
                  maxWidth: '1300px',
                },
                [theme.breakpoints.between(1740, 1840)]: {
                  maxWidth: '1400px',
                },
              }}
            >
              <Grid
                className={classes.documentGrid}
                sx={{
                  [theme.breakpoints.down(1240)]: {
                    width: '97%',
                    margin: '2rem auto 0',
                  },
                }}
              >
                <CustomWebViewer
                  isLoading={isLoadingFile}
                  viewer={viewer}
                  width="100%"
                  height="100%"
                />
              </Grid>

              <Box
                className={classes.outletBox}
                sx={{
                  [theme.breakpoints.down(1240)]: {
                    width: '97%',
                  },
                }}
              >
                <Outlet />
              </Box>
            </Grid>
          ) : (
            <Outlet />
          )}
        </>
      )}
    </RoleBasedMainBox>
  );
}
