/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { useDropzone } from 'react-dropzone';
import { DebounceInput } from 'react-debounce-input';
import { SxProps, Theme } from '@mui/material';

import RoleBasedMainBox from '../../../components/RoleBasedMainBox';
import ActionButton from '../../../components/ActionButton';
import DocumentGrid from '../DocumentGrid';
import DropzoneIndicator from '../Dropzone/DropzoneIndicator';
import LoadingBackdrop from '../../../components/LoadingBackdrop';

import useDocumentContext from '../../../hooks/useDocumentContext';
import useDialogContext from '../../../hooks/useDialogContext';

import COLORS from '../../../utils/colors';

const useStyles = makeStyles((theme: Theme) => ({
  gridActive: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '70vh',
    backgroundColor: theme.palette.primary.light,
  },

  gridInactive: {
    display: 'flex',
    width: '100%',
    height: '90%',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    alignItems: 'center',
  },

  closeIcon: {
    fontSize: '1.625rem',
    marginRight: '0.625rem',
    color: COLORS.mediumGray5,
    cursor: 'pointer',
  },
}));

const styles = {
  boxHeaderActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },

  search: {
    backgroundColor: COLORS.white,
    borderRadius: '0.5rem',
    paddingLeft: '1rem',
    display: 'flex',
    justifyContent: 'center',
    border: `1px solid ${COLORS.lightGray1}`,
    width: '50%',
    marginLeft: '.25rem',

    '&:hover': {
      transition: '0.1s',
      outline: theme => `0.25rem solid ${theme.palette.primary.light}`,
    },

    '& input': {
      fontSize: '1.125rem',
      marginBottom: '0.125rem',
      lineHeight: '1.5rem',
      color: COLORS.gray3,
    },

    '& svg': {
      fill: COLORS.gray5,
      fontSize: '1.5rem',
    },

    '& .Mui-focused': {
      '& svg': {
        fill: COLORS.gray2,
      },
    },
  } as SxProps<Theme>,
};

export default function UserDocumentsFirstScreen() {
  const classes = useStyles();

  const [search, setSearch] = useState('');

  const {
    setCurrentFile,
    handleFileInput,
    loadingDocument,
    clearDocumentInfo,
    documents,
    setDocuments,
    totalDocuments,
  } = useDocumentContext();

  const { showDialog } = useDialogContext();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: '.pdf',
    maxFiles: 1,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        fileRejections[0].errors.forEach(err => {
          switch (err.message) {
            case 'File type must be .pdf':
              showDialog('mustBePdf', 'O arquivo deve estar no formato pdf');
              break;

            case 'Too many files':
              showDialog('manyFiles', 'Envie apenas um arquivo por vez (pdf)');
              break;

            default:
              showDialog('default', err.message);
              break;
          }
        });
        return;
      }

      setCurrentFile(acceptedFiles[0]);
      handleFileInput(acceptedFiles[0]);
    },
  });

  const openFileDialog = () => {
    const fileUploadDom = document.getElementById('fileInput');
    fileUploadDom?.click();
  };

  useEffect(() => {
    clearDocumentInfo();
  }, []);

  const rowsPerPage = 6;
  const [page, setPage] = useState(0);

  const handleSearch = (value: string) => {
    setPage(0);
    setSearch(value);
  };

  return (
    <RoleBasedMainBox>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={styles.boxHeaderActions}>
          <DebounceInput
            minLength={0}
            debounceTimeout={300}
            element={TextField}
            size="medium"
            placeholder="Pesquisar documentos"
            fullWidth
            variant="standard"
            value={search}
            onChange={e => {
              handleSearch(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: search ? (
                <InputAdornment
                  position="end"
                  onClick={() => {
                    setSearch('');
                  }}
                >
                  <CloseIcon className={classes.closeIcon} />
                </InputAdornment>
              ) : null,
              disableUnderline: true,
            }}
            sx={styles.search}
          />

          <ActionButton
            mobilewidth="100%"
            tabletwidth="35%"
            laptopwidth="35%" /* "50%" */
            desktopwidth="30%" /* "40%" */
            largedesktopwidth="18%"
            type="button"
            text="Adicionar documento"
            margin="0 0 .5rem 0"
            self="flex-end"
            action={openFileDialog}
            icon={<AddIcon fontSize="medium" />}
          />
        </div>

        <input
          id="fileInput"
          name="file"
          type="file"
          multiple
          style={{ display: 'none' }}
          accept=".pdf"
          {...getInputProps()}
          onChange={e =>
            handleFileInput(e.target.files ? e.target.files[0] : null)
          }
        />
      </Box>

      <Grid
        {...getRootProps({ onClick: event => event.stopPropagation() })}
        className={isDragActive ? classes.gridActive : classes.gridInactive}
      >
        {isDragActive ? (
          <DropzoneIndicator />
        ) : (
          <DocumentGrid
            isDragActive={isDragActive}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            documents={documents}
            setDocuments={setDocuments}
            totalDocuments={totalDocuments}
            search={search}
          />
        )}
      </Grid>

      <LoadingBackdrop color="primary.main" open={loadingDocument} />
    </RoleBasedMainBox>
  );
}
