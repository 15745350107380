const colors = {
  white: '#FFFFFF',
  background: '#FBFBFF',

  lightGray1: '#E7E7E7',
  lightGray2: '#EFEFEF',
  lightGray3: '#DEDEDE',
  lightGray4: '#D4D4D4',
  lightGray5: '#E4E5E9',
  lightGray6: '#FBFBFB',

  mediumGray2: '#B8B8B8',
  mediumGray3: '#A7A7A7',
  mediumGray4: '#A7A3A3',
  mediumGray5: '#858585',

  gray1: '#535353',
  gray2: '#484848',
  gray3: '#3A3A3A',
  gray5: '#333333',

  black: '#222222',
  pureBlack: '#000000',

  green: '#60D456',
  green2: '#129C31',
  green3: '#25b619',
  lightGreen: '#97f787',
  hoverGreen: '#b6f9ab',
  hoverGreen2: '#ddffe7',

  red: '#F94848',
  red2: '#ef2727',
  lightRed: '#FF8A8A',
  redWarning: '#ff5757',
  lightRedWarning: '#ffa4a4',

  actionOrange: '#FF670F',
  lightOrange: '#FF840F',
  hoverOrange: '#FFD7B1',
  hoverOrange2: '#FEF3E5',

  blue: '#3488FF',
  lighterBlue: '#BCDDF8',
  yellow: '#e7c10a',

  conlicitaGreen: '#02A781',
  conlicitaLightGreen: '#E6F7F3',
  conlicitaBlue: '#007BFF',
  conlicitaLightBlue: '#9ecdff',
  conlicitaRed: '#FF2B2B',

  prevLawBlue: '#283B63',
  prevLawDarkBlue: '#233450',

  codataBlue1: '#0066B4',
  codataBlue2: '#007cc2',
};

export default colors;
