import Typography from '@mui/material/Typography';

import RoleBasedMainBox from '../../components/RoleBasedMainBox';

export default function OperatorHome() {
  return (
    <RoleBasedMainBox>
      <Typography>Componente do operator</Typography>
    </RoleBasedMainBox>
  );
}
