/* eslint-disable no-nested-ternary */
import styled from '@mui/styles/styled';
import Box from '@mui/material/Box';

import './DragFileAnimation.css';

const CircleBox = styled(Box)({
  background: '#f8f4f4',
  width: '14rem',
  height: '14rem',
  borderRadius: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const TabBar = styled('ul')({
  margin: '0',
  padding: '0',
  listStyle: 'none',
  display: 'flex',
  alignItems: 'center',
});

const TabBarItem = styled('li')({
  margin: '0 11px',
});

const FileAddBox = styled(Box)({
  width: '32px',
  height: '24px',
  background: 'primary.main',
  borderRadius: '2px',
  position: 'relative',
  margin: '80px 0 0 0',
  perspective: '160px',
  transform: 'scale(2.5)',
  '&:before': {
    content: '',
    borderRadius: '2px 2px 0 0',
    background: 'inherit',
    height: '2px',
    width: '20px',
    display: 'block',
    position: 'absolute',
    top: '-2px',
    left: '0',
  },
});

const FileAddInnerList = styled('ul')({
  margin: '0',
  padding: '0',
  listStyle: 'none',
  position: 'absolute',
  bottom: '0',
  left: '0',
  right: '0',
  perspective: '300px',
});

const FileAddInnerListItem = styled('li')({
  bottom: '1px',
  left: '6px',
  position: 'absolute',
  width: '20px',
  height: '28px',
  transition: 'transform 0.45s ease',
  animation: 'liftpaper 3s infinite',
  color: 'primary.main',
  fontSize: '8px',
  paddingTop: '.4rem',
  background: '#fff',
  boxShadow: '0.5px 0.5px rgba(0, 0, 0, 0.2)',

  '&:before': {
    content: '',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    borderRadius: '2px',
    backfaceVisibility: 'hidden',
    transformStyle: 'preserve-3d',
    transition: 'transform 0.4s ease',

    background: '#fff',
    color: 'primary.main',
  },

  '&:after': {
    content: '',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    borderRadius: '2px',
    backfaceVisibility: 'hidden',
    transformStyle: 'preserve-3d',
    transition: 'transform 0.4s ease',
    zIndex: '1',
    textAlign: 'center',
    fontSize: '6px',
    fontWeight: '600',
    color: 'primary.main',
    lineHeight: '26px',
    fontFamily: 'Mukta Malar',
  },
});

const FileAddInnerBox = styled(Box)({
  position: 'absolute',
  left: '0',
  right: '0',
  bottom: '0',
  height: '24px',
  background: 'primary.main',
  borderRadius: '2px 2px 3px 3px',
  zIndex: '2',
  filter: 'drop-shadow(0 -0.5px 0px rgba(0, 0, 0, 0.1))',
  boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.2)',
  transition: 'all 0.25s ease',
  transformOrigin: '50% 100%',
  animation: 'openfolder 3s infinite',
});

export default function DragFileAnimation() {
  return (
    <CircleBox>
      <TabBar>
        <TabBarItem>
          <FileAddBox>
            <FileAddInnerList>
              <FileAddInnerListItem>PDF</FileAddInnerListItem>
            </FileAddInnerList>

            <FileAddInnerBox>
              <span />
            </FileAddInnerBox>
          </FileAddBox>
        </TabBarItem>
      </TabBar>
    </CircleBox>
  );
}
