/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useDragLayer, XYCoord } from 'react-dnd';
import { useTheme } from '@mui/material';
import { CSSProperties } from '@mui/styles';

import useCostCenterContext from '../../hooks/useCostCenterContext';

const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 1000,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

const getFieldStyle = (isDragging: boolean) => {
  const style = {
    width: 300,
    maxWidth: 300,
    zIndex: 1000,
    opacity: isDragging ? 0.8 : 1,
  };
  return style;
};

const getItemStyles = (currentOffset: XYCoord | null) => {
  if (!currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
};

export default function FolderAndDocumentDragLayer() {
  const { selectedFoldersIds, selectedDocuments } = useCostCenterContext();

  const theme = useTheme();

  const { itemType, isDragging, currentOffset } = useDragLayer(monitor => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const containerStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: '14rem',
    minHeight: '50px',
    maxHeight: '50px',
    height: '100%',
    padding: '1rem',
    borderRadius: '10px',
    gap: '10px',
    opacity: 0.6,
    zIndex: 1000,
    cursor: 'grab',
    boxShadow: '2px 2px 4px rgba(0, 0, 0, .4)',
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.light,
  };

  const bothContainerStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: '14rem',
    minHeight: '150px',
    maxHeight: '150px',
    height: '100%',
    padding: '1rem',
    borderRadius: '10px',
    gap: '10px',
    opacity: 0.6,
    zIndex: 1000,
    cursor: 'grab',
    boxShadow: '2px 2px 4px rgba(0, 0, 0, .4)',
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.light,
  };

  const documentContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    height: '100%',
    minWidth: '140px',
    maxWidth: '140px',
    minHeight: '150px',
    maxHeight: '150px',
    padding: '1rem',
    borderRadius: '10px',
    gap: '14px',
    opacity: 0.6,
    zIndex: 1000,
    cursor: 'grab',
    boxShadow: '2px 2px 4px rgba(0, 0, 0, .4)',
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.light,

    '& img': {
      width: '164px',
      height: '100%',
      minHeight: '175px',
      maxHeight: '175px',
      opacity: 0.4,
    },
  };

  const renderItem = (type: any) => {
    switch (type) {
      case 'folder':
        return (
          <div style={containerStyle}>
            {`Mover ${selectedFoldersIds.length} ${
              selectedFoldersIds.length > 1 ? ' pastas' : ' pasta'
            }`}
          </div>
        );

      case 'document':
        return (
          <div style={documentContainerStyle}>
            {`Mover ${selectedDocuments.length} ${
              selectedDocuments.length > 1 ? ' documentos' : ' documento'
            }`}
          </div>
        );

      default:
        return null;
    }
  };

  if (!isDragging) {
    return null;
  }

  return (
    <div style={layerStyles}>
      <div style={getItemStyles(currentOffset)}>
        <div style={getFieldStyle(isDragging)}>
          {selectedFoldersIds.length > 0 && selectedDocuments.length > 0 ? (
            <div style={bothContainerStyle}>
              {`Mover ${
                selectedFoldersIds.length + selectedDocuments.length
              } itens`}
            </div>
          ) : (
            renderItem(itemType)
          )}
        </div>
      </div>
    </div>
  );
}
