import LogoConlicitacao from './LogoConlicitacao';
import LogoCrmPB from './LogoCrmPB';
import LogoPrevLaw from './LogoPrevLaw';
import LogoCodata from './LogoCodata';

import useWhiteLabel from '../../hooks/useWhiteLabel';

import dattasignLogo from '../../assets/images/dattasign_logo_56.png';

export type LogoType = 'logOn' | 'logOff';

interface LogoProps {
  type: LogoType;
}

const styles = {
  logOn: {
    width: '8rem',
    height: '1.8rem',
    alignSelf: 'center',
    marginLeft: '1rem',
    marginTop: '1rem',
  },

  logOff: {
    width: '8rem',
  },
};

export default function Logo({ type }: LogoProps) {
  const { whiteLabel } = useWhiteLabel();

  if (whiteLabel === 'conlicitacao') {
    return <LogoConlicitacao type={type} />;
  }

  if (whiteLabel === 'prevLaw') {
    return <LogoPrevLaw type={type} />;
  }

  if (whiteLabel === 'codata') {
    return <LogoCodata type={type} />;
  }

  if (whiteLabel === 'crmpb') {
    return <LogoCrmPB type={type} />;
  }

  return (
    <img style={styles[type]} src={dattasignLogo} alt="Logo do DattaSign" />
  );
}
