/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SxProps, Theme } from '@mui/material';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';
import Table from '@mui/material/Table';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import moment from 'moment';

import PageMainContentBox from '../../components/PageMainContentBox';
import RoleBasedMainBox from '../../components/RoleBasedMainBox';
import ContentSubTitle from '../../components/ContentSubTitle';
import SearchBar from '../../components/SearchBar';
import VoidImage from '../../components/Images/VoidImage';

import useAuthContext from '../../hooks/useAuthContext';
import useCostCenterContext from '../../hooks/useCostCenterContext';

import { CostCenter } from '../../types/CostCenter';

import COLORS from '../../utils/colors';

import ModalCreateCostCenter from './ModalCreateCostCenter';
import ModalAddFreePlanInCostCenter from './ModalAddFreePlanInCostCenter';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },

  content: {
    display: 'flex',
    width: '100%',
    height: '90%',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    alignItems: 'center',
  } as SxProps<Theme>,

  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    '::-webkit-scrollbar': {
      width: '10px',
    },
    '::-webkit-scrollbar-track': {
      background: COLORS.lightGray3,
      borderRadius: '50px',
    },
    '::-webkit-scrollbar-thumb': {
      background: COLORS.mediumGray2,
      borderRadius: '50px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: COLORS.mediumGray3,
    },
  } as SxProps<Theme>,

  headerTable: {
    '& th': {
      border: 0,
      color: COLORS.black,
      fontSize: '1.175rem',
    },
  },

  rowTable: {
    '& td': {
      fontSize: '1.025rem',
      borderBottom: `1px solid ${COLORS.lightGray5}`,
      color: COLORS.mediumGray5,
      fontWeight: 500,
    },

    hr: {
      width: '50%',
    },
  },

  actions: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: {
      mobile: '1rem',
      tablet: '1.2rem',
      laptop: '1.3rem',
      desktop: '1.8rem',
      largedesktop: '2rem',
    },
  },

  buttonViewDetails: {
    textTransform: 'none',
    background: 'none',
    textDecoration: 'underline',
    color: "primary.main",
    fontSize: {
      mobile: '1rem',
      tablet: '.95rem',
      laptop: '.95rem',
      desktop: '1rem',
      largedesktop: '1rem',
    },
    height: '2rem',
    borderRadius: '.625rem',
    padding: 0,
    textAlign: 'left',
    fontWeight: 500,

    '&:hover': {
      background: 'none',
      transition: '0.3s',
      opacity: '0.6',
      textDecoration: 'underline',
    },
  } as SxProps<Theme>,

  divider: {
    marginTop: '.2rem',
    width: '100%',
  } as SxProps<Theme>,

  emptyUsersContainer: {
    paddingTop: '10%',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
    margin: 'unset',

    svg: {
      width: '18rem',
      height: '10rem',
    },
  } as SxProps<Theme>,

  loadingContainer: {
    width: '100%',
    height: '65vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const planStatusEnToBr = {
  active: 'Ativo',
  suspended: 'Suspenso',
  ended: 'Finalizado',
  incomplete: 'Incompleto',
  canceled: 'Cancelado',
  unpaid: 'Não pago',
  incomplete_expired: 'Expirado',
};

export default function CostCenters() {
  const [searchParams] = useSearchParams({});
  const { totalCostCenters, setTotalCostCenters, getAdminCostCenters } =
    useCostCenterContext();

  const [costCenters, setCostCenters] = useState<CostCenter[]>([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [updateCostCenters, setUpdateCostCenters] = useState(false);

  const { isLogged } = useAuthContext();

  const rowsPerPage = 7;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    setIsLoading(true);

    if (isLogged) {
      getAdminCostCenters(page, rowsPerPage);
      setIsLoading(false);
    }
  }, [page, updateCostCenters]);

  return (
    <Box style={styles.container}>
      <PageMainContentBox title="Centros de custo" page="centros-de-custo">
        <RoleBasedMainBox>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: costCenters ? 'space-between' : 'flex-end',
              flexDirection: {
                mobile: 'column',
                tablet: 'row',
                laptop: 'row',
                desktop: 'row',
                largedesktop: 'row',
              },
              gap: '1rem',
            }}
          >
            {costCenters && (
              <SearchBar
                options={costCenters}
                page={page}
                rowsPerPage={rowsPerPage}
                setCostCenters={setCostCenters}
                setUpdateCostCenters={setUpdateCostCenters}
                setTotalCostCenters={setTotalCostCenters}
                type="costcenters"
              />
            )}

            <ModalCreateCostCenter
              totalCostCenters={totalCostCenters}
              setTotalCostCenters={setTotalCostCenters}
              setCostCenters={setCostCenters}
              rowsPerPage={rowsPerPage}
            />
          </Box>

          <Grid sx={styles.content}>
            <TableContainer sx={styles.tableContainer}>
              {!isLoading ? (
                costCenters.length > 0 ? (
                  <>
                    <Table>
                      <TableHead>
                        <TableRow sx={styles.headerTable}>
                          <TableCell>Nome</TableCell>
                          <TableCell>Proprietário</TableCell>
                          <TableCell>Data de Criação</TableCell>
                          <TableCell>Plano</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Ações</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {costCenters.map(costCenter => (
                          <TableRow key={costCenter.id} sx={styles.rowTable}>
                            <TableCell>{costCenter.nome}</TableCell>
                            <TableCell>
                              {costCenter.proprietario.name}
                            </TableCell>
                            <TableCell>
                              {moment(costCenter.created_at).format('L LT')}
                            </TableCell>
                            <TableCell>
                              {costCenter.assinatura ? (
                                costCenter.assinatura.plano.nome
                              ) : (
                                <hr />
                              )}
                            </TableCell>
                            <TableCell>
                              {costCenter.assinatura ? (
                                planStatusEnToBr[costCenter.assinatura.status]
                              ) : (
                                <hr />
                              )}
                            </TableCell>

                            <TableCell>
                              <Box sx={styles.actions}>
                                <Button sx={styles.buttonViewDetails}>
                                  Ver detalhes
                                </Button>

                                {(!costCenter.assinatura ||
                                  costCenter.assinatura.status !== 'active' ||
                                  (costCenter.assinatura.plano.nome ===
                                    'Cortesia' &&
                                    costCenter.assinatura.status ===
                                      'active')) && (
                                  <ModalAddFreePlanInCostCenter
                                    id={costCenter.id}
                                    setUpdateCostCenters={setUpdateCostCenters}
                                  />
                                )}
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <div style={{ alignSelf: 'center' }}>
                      <TablePagination
                        component="div"
                        count={totalCostCenters}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[]}
                        sx={{
                          '& .css-rprza6-MuiTablePagination-displayedRows': {
                            marginBottom: '0 !important',
                          },
                        }}
                      />
                    </div>
                  </>
                ) : searchParams.get('query') ? (
                  <Grid sx={styles.emptyUsersContainer}>
                    <VoidImage />

                    <ContentSubTitle
                      text="Não foram encontrados registros com este nome."
                      fontWeight="bold"
                      fontSize=".9rem"
                      margin="1rem auto .3rem auto"
                      alignSelf="center"
                    />
                    <ContentSubTitle
                      text="Altere o termo pesquisado ou limpe a pesquisa."
                      fontWeight="bold"
                      fontSize=".9rem"
                      margin=".5rem 0 .3rem 0"
                      alignSelf="center"
                    />
                  </Grid>
                ) : (
                  <Grid sx={styles.loadingContainer}>
                    <CircularProgress color="warning" />
                  </Grid>
                )
              ) : (
                <Grid sx={styles.emptyUsersContainer}>
                  <VoidImage />

                  <ContentSubTitle
                    text="Está tão vazio aqui..."
                    fontWeight="bold"
                    fontSize=".9rem"
                    margin="1rem auto .3rem auto"
                    alignSelf="center"
                  />
                  <ContentSubTitle
                    text="Adicione um usuário clicando no botão acima :)"
                    fontWeight="bold"
                    fontSize=".9rem"
                    margin="1rem 0 .3rem 0"
                    alignSelf="center"
                  />
                </Grid>
              )}
            </TableContainer>
          </Grid>
        </RoleBasedMainBox>
      </PageMainContentBox>
    </Box>
  );
}
