/* eslint-disable no-nested-ternary */
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';

import COLORS from '../../../utils/colors';

type Props = {
  icon: React.ReactNode;
  text: string;
  // eslint-disable-next-line react/require-default-props
  action?: () => void;
};

export default function OptionsButton({ icon, text, action }: Props) {
  const theme = useTheme();

  return (
    <Button
      onClick={action}
      color="primary"
      sx={{
        textTransform: 'unset',
        width: {
          mobile: '100%',
          desktop: '14rem',
        },
        ':hover': {
          backgroundColor: 'primary.light',
        },
        [theme.breakpoints.down('desktop')]: {
          padding: '6px 3px',
        },
        [theme.breakpoints.between(1280, 1470)]: {
          padding: '6px 8px',
        },
      }}
    >
      <Card
        elevation={0}
        sx={{
          border: `1px solid ${COLORS.lightGray5}`,
          margin: '-.2rem -.3rem',
          gap: '10px',
          width: {
            mobile: '100%',
            desktop: '14rem',
          },
          height: {
            tablet: 'auto',
            desktop: '16rem',
          },
          display: 'flex',
          flexDirection: 'column-reverse',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {icon}
        <Typography
          style={{
            fontWeight: 'bold',
            fontSize: '1rem',
            margin: '.5rem 0 1rem 0',
            color: COLORS.mediumGray5,
            width: '60%',
          }}
        >
          {text}
        </Typography>
      </Card>
    </Button>
  );
}
